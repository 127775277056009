// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import IconButton from '../../component/icon-button'
import useInputState from '../../lib/use-input-state'
import useKeyStepper from '../../lib/use-key-stepper'
import { Presence } from '../../service/model'
import { Command } from './common-v2'
import Content from './common/content'
import Footer from './common/footer'
import FooterButton from './common/footer-button'
import Header from './common/header'
import Item from './common/item'
import { EmojiIcon } from '../../component/icons/Tint/20/General'

type PresenceStatus = { symbol: string; text: string }

export interface MemberStatusCommandProps {
  presence: Presence
}

const defaultItems: PresenceStatus[] = [
  {
    symbol: '🗓',
    text: 'In a meeting',
  },
  {
    symbol: '📞',
    text: 'On a call',
  },
  {
    symbol: '🍕',
    text: 'Out for lunch',
  },
  {
    symbol: '🏝',
    text: 'On vacation',
  },
  {
    symbol: '🤒',
    text: 'Out sick',
  },
  {
    symbol: '🎮',
    text: 'Taking a break',
  },
]

type ItemType = { type: 'input' } | { type: 'item'; data: PresenceStatus }

const MemberStatusCommand: React.FC<MemberStatusCommandProps> = function ({ presence }) {
  const styles = useStyles({})
  const { command, service, toast } = useAppStore()
  const [emoji, setEmoji] = useInputState(presence?.symbol || '')
  const [text, setText] = useInputState(presence?.text || '')
  const [items, setItems] = useState<ItemType[]>([])

  useEffect(() => {
    setItems([
      { type: 'input' },
      ...defaultItems.map((item): ItemType => ({ type: 'item', data: item })),
    ])
  }, [])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'command/member-status',
    handleSelect: (item) => {
      if (item.type === 'item') {
        presence.setStatus(item.data.symbol, item.data.text).catch(toast.showError)
        command.hide()
      } else {
        handleSave()
      }
    },
    deps: [text, emoji],
  })

  const handleSave = () => {
    presence.setStatus(emoji, text).catch(toast.showError)
    command.hide()
  }

  const handleClear = () => {
    presence.setStatus(null, null).catch(toast.showError)
    command.hide()
  }

  return (
    <Command>
      <Header
        title="Set your status"
        subtitle="This will be visible to other members in your workspace"
      />
      <Content>
        {items.map((item, index) =>
          item.type === 'input' ? (
            <StatusInput
              key="input"
              status={{ symbol: emoji, text }}
              setSymbol={setEmoji}
              setText={setText}
              highlighted={index === selectedIndex}
              {...getItemProps(index)}
            />
          ) : (
            <Item
              key={`${item.data.symbol}${item.data.text}`}
              highlighted={selectedIndex === index}
              {...getItemProps(index)}
            >
              <div className={styles.symbol}>{item.data.symbol}</div>
              <div className={styles.text}>{item.data.text}</div>
            </Item>
          ),
        )}
      </Content>
      <Footer
        actions={
          <>
            {presence?.symbol && (
              <FooterButton
                variant="text"
                onClick={handleClear}
                style={{ marginRight: 15 }}
              >
                Clear Status
              </FooterButton>
            )}
            <FooterButton variant="contained" onClick={handleSave}>
              Save
            </FooterButton>
          </>
        }
      />
    </Command>
  )
}

interface StatusInputProps extends React.HTMLProps<HTMLDivElement> {
  status: PresenceStatus
  highlighted: boolean
  setSymbol: (symbol: string) => void
  setText: (text: string) => void
}

const StatusInput: React.FC<StatusInputProps> = function ({
  highlighted,
  setSymbol,
  setText,
  status,
  ...props
}) {
  const styles = useStyles({})
  const { showEmojiPicker } = useAppStore()
  const emojiRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (highlighted && document.activeElement !== inputRef.current) {
      inputRef.current.focus()
    } else if (!highlighted && document.activeElement === inputRef.current) {
      inputRef.current.blur()
    }
  }, [highlighted])

  const handleTextChange = (event) => {
    setText(event.target.value)
  }

  const handleEmojiPicker = (event) => {
    showEmojiPicker({
      anchorEl: emojiRef.current,
      onEmojiSelected: handleEmojiSelected,
    })
  }

  const handleEmojiSelected = (emoji: string) => {
    setSymbol(emoji)
  }

  const handleClear = () => {
    setSymbol('')
    setText('')
  }

  return (
    <div {...props} className={styles.inputItem} onClick={null}>
      <div className={cx(styles.inputWrapper, highlighted && styles.inputHighlighted)}>
        <div
          ref={emojiRef}
          className={cx(styles.symbol, styles.symbolSelect)}
          onClick={handleEmojiPicker}
        >
          {status.symbol || <EmojiIcon />}
        </div>
        <input
          autoFocus
          ref={inputRef}
          className={styles.input}
          placeholder="Write a status..."
          value={status.text}
          onChange={handleTextChange}
        />
        {(status.symbol || status.text) && (
          <IconButton size="medium" onClick={handleClear} icon={<CancelIcon />} />
        )}
      </div>
    </div>
  )
}

export default observer(MemberStatusCommand)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inputItem: {
    padding: '1rem 2rem',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.op.match({
      dark: `rgba(0, 0, 0, 0.15)`,
      light: `transparent`,
    }),
    boxShadow: `0 0 0 1px ${theme.palette.op.background.highlight(0.2)}`,
    borderRadius: 5,
    overflow: 'hidden',
    paddingRight: 10,
  },
  inputHighlighted: {
    boxShadow: `0 0 0 1px ${theme.palette.op.border.focus}, 0 0 0 3px rgb(167 102 255 / 29%)`,
  },
  symbol: {
    width: 45,
    height: 45,
    borderRadius: 5,
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  symbolSelect: {
    cursor: 'pointer',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    background: theme.palette.op.match({
      dark: `rgba(0, 0, 0, 0.15)`,
      light: `rgba(0, 0, 0, 0.03)`,
    }),
    transition: theme.transitions.create(['background'], {
      duration: 256,
      easing: 'ease',
    }),

    '&:hover': {
      background: theme.palette.op.match({
        dark: `rgba(0, 0, 0, 0.4)`,
        light: `rgba(0, 0, 0, 0.06)`,
      }),
    },
  },
  text: {
    marginLeft: 16,
  },
  input: {
    flex: 1,
    outline: 'none',
    border: 'none',
    background: 'none',
    color: theme.palette.text.primary,
    marginLeft: 16,
    fontSize: '1rem',
  },
}))
