// @ts-strict-ignore
import CreditCardIcon from '@material-ui/icons/CreditCard'
import React from 'react'
import { useAppStore } from '@src/app/context'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'
import Footer from '../common/footer'
import Button from '../common/footer-button'
import Header from '../common/header'
import Textfield from '../common/textfield'

export interface BillingAutoChargeCommandProps {
  defaultAmount?: number
}

const BillingAutoChargeCommand: React.FC<BillingAutoChargeCommandProps> = function ({
  defaultAmount,
}) {
  const { command, service, toast } = useAppStore()
  const [amount, setAmount] = useInputState(
    defaultAmount ? `${defaultAmount / 100}` : '',
    (s) => {
      if (!s) return s
      const amount = parseInt(s)
      if (isNaN(amount)) {
        return ''
      }
      return `${amount}`
    },
  )

  const handleSet = () => {
    command.hide()
    const credits = parseInt(amount)
    service.billing.autoCharge(credits * 100).catch(toast.showError)
  }

  const handleTurnOff = () => {
    command.hide()
    service.billing.autoCharge(0).catch(toast.showError)
  }

  return (
    <Command>
      <Header
        title="Set Auto-Charge"
        subtitle="Charge your credit card automatically when your credits drop below $0."
      />
      <Textfield
        autoFocus
        placeholder="Enter the amount (e.g. $10.00)"
        value={amount}
        onChange={setAmount}
      />
      <Footer
        actions={
          <>
            {defaultAmount > 0 && (
              <Button style={{ marginRight: 20 }} onClick={handleTurnOff} variant="text">
                Turn off
              </Button>
            )}
            <Button
              onClick={handleSet}
              startIcon={<CreditCardIcon />}
              variant="contained"
            >
              Set Auto-Charge
            </Button>
          </>
        }
      />
    </Command>
  )
}

export default BillingAutoChargeCommand
