import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Environment } from '../../config'
import useKeyStepper from '../../lib/use-key-stepper'
import Header from './common/header'
import Item from './common/item'
import Content from './common/content'
import { useAppStore } from '@src/app/context'
import { Command } from './common-v2'

export interface ChangeEnvCommandProps {}

const items: { name: string; value: Environment }[] = [
  { name: 'Development', value: 'development' },
  { name: 'Production', value: 'production' },
]

const ChangeEnvCommand: React.FC<ChangeEnvCommandProps> = function ({}) {
  const app = useAppStore()

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'command/change-env',
    handleSelect: (item) => {
      app.command.hide()
      app.signOut(item.value)
    },
  })

  return (
    <Command>
      <Header title="Change Environment" subtitle="Sup fam" />
      <Content>
        {items.map((item, index) => (
          <Item
            {...getItemProps(index)}
            key={item.value}
            highlighted={index === selectedIndex}
          >
            <Typography variant="body1" color="textPrimary">
              {item.name}
            </Typography>
          </Item>
        ))}
      </Content>
    </Command>
  )
}

export default observer(ChangeEnvCommand)
