// @ts-strict-ignore
import cx from 'classnames'
import React, { useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppStore } from '@src/app/context'
import { Content, IconItem } from '../common-v2'
import GroupAvatar from '../../../component/group-avatar'
import Typography from '@ui/Typography'
import useKeyStepper from '../../../lib/use-key-stepper'
import { MessageIcon } from '../../../component/icons/Tint/20/General'
import { formatted } from '../../../lib/phone-number'
import PopoverMenu, { MenuItem } from '../../../component/popover-menu'
import { Member, PhoneNumber } from '../../../service/model'
import Tooltip from '../../../component/tooltip'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import { AppAvatar } from '@src/app/components'

interface IdentityResultProps {}

const IdentityResult: React.FC<IdentityResultProps> = function ({}) {
  const styles = useStyles({})
  const anchorEl = useRef<HTMLDivElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const { service, inboxes, search, command } = useAppStore()
  const [selectedNumber, setSelectedNumber] = useState(inboxes.selectedPhoneNumber)
  const identity = search.identity
  const phoneNumbers = service.user.phoneNumbers.list

  const items = useMemo(
    () => [
      ...(identity instanceof Member
        ? [{ type: 'direct', number: identity.directNumber }]
        : []),
      ...identity.phones.map((p) => ({ type: 'phone-number', number: p.number })),
    ],
    [identity],
  )

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'command/search/identity',
    handleSelect: (item) => {
      if (item.type === 'phone-number') {
        const inbox = inboxes.all.get(selectedNumber.id)
        inbox.newConversation(item.number)
        inboxes.setSelected(inbox)
      } else if (item.type === 'direct') {
        inboxes.openDirectMessage(item.number)
      }
      search.clearSearch()
      command.hide()
    },
  })

  useKeyboardShortcuts({
    node: document,
    name: 'IdentitySearchResult',
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        search.setIdentity(null)
        event.preventDefault()
      }
    },
  })

  const handleSelect = (pn: PhoneNumber) => {
    setPopoverOpen(false)
    setSelectedNumber(pn)
  }

  return (
    <>
      <div className={styles.header}>
        <Tooltip title="Change phone number">
          <Item
            ref={anchorEl}
            icon={<GroupAvatar size={40} group={selectedNumber} />}
            onClick={() => setPopoverOpen(true)}
            className={styles.phoneNumber}
          >
            <Typography variant="callout">{selectedNumber.name}</Typography>
            <Typography variant="footnote" color="textSecondary">
              {selectedNumber.formattedNumber}
            </Typography>
          </Item>
        </Tooltip>
        <PopoverMenu
          anchorEl={anchorEl.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={popoverOpen}
          onClose={() => setPopoverOpen(false)}
          size="medium"
        >
          {phoneNumbers.map((pn) => (
            <MenuItem
              height="large"
              key={pn.id}
              selected={pn.id === selectedNumber.id}
              onClick={() => handleSelect(pn)}
              icon={<GroupAvatar size={34} group={pn} />}
            >
              <Typography
                variant="footnote"
                fontWeight="medium"
                color={pn.id === selectedNumber.id ? 'inherit' : 'textPrimary'}
              >
                {pn.name}
              </Typography>
              <Typography
                variant="caption2"
                color={pn.id === selectedNumber.id ? 'inherit' : 'textSecondary'}
              >
                {pn.formattedNumber}
              </Typography>
            </MenuItem>
          ))}
        </PopoverMenu>
      </div>
      <Content>
        <div className={styles.contact}>
          <Item icon={<AppAvatar size={40} identity={identity} />}>
            <Typography variant="callout">{identity.name}</Typography>
            <Typography variant="footnote" color="textSecondary">
              {identity.phones.length} phone{' '}
              {identity.phones.length === 1 ? 'number' : 'numbers'}
            </Typography>
          </Item>
        </div>
        {items.map((item, index) => (
          <IconItem
            key={item.number}
            {...getItemProps(index)}
            selected={index === selectedIndex}
            icon={<MessageIcon />}
          >
            {item.type == 'phone-number' ? formatted(item.number) : 'Direct message'}
          </IconItem>
        ))}
      </Content>
    </>
  )
}

const Item: React.FC<{ icon: React.ReactNode } & React.HTMLProps<HTMLDivElement>> =
  React.forwardRef(({ icon, children, className, ...props }, ref) => {
    const styles = useStyles({})
    return (
      <div {...props} ref={ref} className={cx(styles.item, className)}>
        <div className={styles.icon}>{icon}</div>
        <div>{children}</div>
      </div>
    )
  })

export default observer(IdentityResult)

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '12px 16px',
  },
  contact: {
    padding: '8px 16px 18px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderRadius: 5,
  },
  icon: {
    marginRight: 16,
  },
  phoneNumber: {
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
}))
