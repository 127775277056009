import { makeStyles, Theme } from '@material-ui/core/styles'
import Call from '@material-ui/icons/Call'
import SettingsIcon from '@material-ui/icons/Settings'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import FromPhoneNumber from '@src/component/from-phone-number'
import IconButton from '@src/component/icon-button'
import { DeepPopover as Popover } from '@src/component/menu'
import { PhoneNumber } from '@src/service/model'
import AudioSettingsModal from '@src/app/voice/audio-settings'
import { Command } from '../common-v2'
import Header from '../common/header'
import PhoneNumberSelector from '../phone-number-selector'

export interface DialerCommandProps {
  phoneNumber?: string
}

const DialerCommand: React.FC<DialerCommandProps> = function ({ phoneNumber }) {
  const styles = useStyles({})
  const { service, voice, command, inboxes } = useAppStore()
  const phoneNumbers = service.user.phoneNumbers.list
  const phoneNumberId = inboxes.selected?.id
  const [settingsEl, setSettingsEl] = useState<Element | null>(null)
  const [fromNumber, setFromNumber] = useState<PhoneNumber | null>(null)

  useEffect(() => {
    if (!phoneNumberId && !fromNumber) {
      setFromNumber(phoneNumbers[0])
      return
    }
    const pn = phoneNumbers.find((p) => p.id === phoneNumberId)
    if (pn) {
      setFromNumber(pn)
    }
  }, [phoneNumberId, phoneNumbers])

  const handleSettings = (event) => {
    setSettingsEl(event.target)
  }

  const handleSettingsClose = () => {
    setSettingsEl(null)
  }

  const handleCall = (number: string) => {
    if (number === 'greedisgood') {
      command.present({ name: 'change env' })
    } else {
      if (!fromNumber) return
      voice.startCall(fromNumber, number)
      command.hide()
    }
  }

  return (
    <Command>
      <Header
        title="Make a call"
        subtitle={
          <FromPhoneNumber
            color="secondary"
            selectedId={fromNumber?.id}
            onNumberSelected={setFromNumber}
            className={styles.fromNumber}
          />
        }
        trailingIcon={
          <IconButton
            size="medium"
            onClick={handleSettings}
            title="Audio Settings"
            icon={<SettingsIcon />}
          />
        }
      />
      <Popover
        open={Boolean(settingsEl)}
        anchorEl={settingsEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSettingsClose}
      >
        <AudioSettingsModal />
      </Popover>
      <PhoneNumberSelector
        actionTitle="Call"
        actionIcon={<Call />}
        actionColor="secondary"
        defaultValue={phoneNumber}
        onSelect={handleCall}
      />
    </Command>
  )
}

export default observer(DialerCommand)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fromNumber: {},
}))
