// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import BusinessIcon from '@material-ui/icons/Business'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useCallback } from 'react'
import { useAppStore } from '@src/app/context'
import Checkbox from '../../component/checkbox-v2'
import IconButton from '../../component/icon-button'
import { CancelIcon } from '../../component/icons/custom'
import Typography from '@ui/Typography'
import { formatted } from '../../lib/phone-number'
import { highlight } from '../../lib/string'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { Contact } from '../../service/model'
import { colors } from '../../theme'
import { AppAvatar } from '@src/app/components'

interface ContactRowProps extends React.HTMLProps<HTMLDivElement> {
  contact: Contact
  secondaryLabel?: string
  query?: string
  selected?: boolean
}

const ContactRow: React.FC<ContactRowProps> = function ({
  secondaryLabel,
  query,
  contact,
  selected,
  ...props
}) {
  const styles = useStyles({})
  const { contacts } = useAppStore()
  const checked = contacts.checked.has(contact.id)
  const name = contact.name || formatted(contact.phones[0].number)
  const brief = contact.job

  const handleSelectChanged = (event, checked: boolean) => {
    contacts.toggleChecked(contact.id)
    event.target.blur()
  }

  const handleDiscard = useCallback(() => {
    contact.delete()
  }, [contact.delete])

  // TODO: move it outside of a row
  useKeyboardShortcuts({
    name: 'contact-row',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape' && contact.local) {
        event.preventDefault()
        handleDiscard()
      }
    },
    dep: [handleDiscard],
  })

  return (
    <div {...props}>
      <div
        className={cx(
          styles.root,
          checked && styles.checked,
          selected && styles.selected,
        )}
      >
        <Checkbox checked={checked} onChange={handleSelectChanged} />
        <AppAvatar identity={contact} size={22} className={styles.avatar} />
        {contact.local && <div className={styles.draft}>Draft:</div>}
        <Typography
          nowrap
          variant="callout"
          color="textPrimary"
          dangerouslySetInnerHTML={{ __html: highlight(name || 'Add a name...', query) }}
        />
        {brief && (
          <>
            <BusinessIcon
              className={styles.companyIcon}
              fontSize="inherit"
              color="inherit"
            />
            <Typography
              variant="caption1"
              color="textSecondary"
              fontWeight="regular"
              dangerouslySetInnerHTML={{ __html: highlight(brief, query) }}
            />
          </>
        )}
        <div className={styles.spacer} />
        {secondaryLabel && (
          <Typography
            variant="caption1"
            color="textSecondary"
            fontWeight="regular"
            dangerouslySetInnerHTML={{ __html: highlight(secondaryLabel, query) }}
          />
        )}
        {contact.local && (
          <IconButton
            tabIndex={-1}
            title="Discard"
            shortcut="Esc"
            size="small"
            onClick={handleDiscard}
            icon={<CancelIcon />}
          />
        )}
      </div>
    </div>
  )
}

export default observer(ContactRow)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'flex-start',
    padding: '0 12px',
    position: 'relative',
    margin: '0 10px 2px',
    borderRadius: 5,

    '&:before': {
      content: '""',
      background: 'transparent',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: -1,
      opacity: 1,
      borderRadius: 'inherit',
      pointerEvents: 'none',
    },

    '&:hover:before': {
      background: theme.palette.op.hover.primary,
    },

    '&$selected': {
      '&:before': {
        borderRadius: 0,
        background: theme.palette.op.primary[3],
      },
    },

    '&$topSelected': {
      '&:before': {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      },
    },

    '&$bottomSelected': {
      '&:before': {
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
      },
    },

    '& em': {
      color: theme.palette.op.match({
        dark: theme.palette.op.primary[3],
        light: theme.palette.op.primary[1],
      }),
      fontStyle: 'normal',
    },
  },
  selected: {},
  topSelected: {},
  bottomSelected: {},
  avatar: {
    marginLeft: 14,
    marginRight: 10,
  },
  companyIcon: {
    marginLeft: 15,
    marginRight: 5,
    color: colors.gray900,
  },
  spacer: {
    flex: 1,
  },
  draft: {
    fontSize: '0.875rem',
    marginRight: 5,
    color: theme.palette.op.legacy.draftGreen,
    fontWeight: 500,
  },
  checked: {
    background: theme.palette.op.hover.selected,
  },
}))
