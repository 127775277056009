// @ts-strict-ignore
import LRU from 'lru-cache'
import { v4 as uuid } from 'uuid'
import AppStore from './store'

interface NotificationHandlers {
  onShow?: () => void
  onFailed?: (error: string) => void
  onClose?: () => void
  onClick?: () => void
  onReply?: (reply: string) => void
  onAction?: (index: number) => void
}

interface NotificationOptions extends NotificationHandlers {
  id?: string
  title?: string
  body?: string
  silent?: boolean
  hasReply?: boolean
  replyPlaceholder?: string
  sound?: 'missed-call' | 'new-message' | 'default'
}

export class NotificationController {
  private nativeHandlers = new LRU<string, NotificationHandlers>({
    max: 10,
    maxAge: 60_000,
  })

  constructor(private app: AppStore) {
    this.registerListeners()
  }

  get permission() {
    return window.Notification?.permission
  }

  get playNotificationSounds() {
    return this.app.service.user.current?.settings?.web.playNotificationSounds ?? true
  }

  requestPermission(): Promise<NotificationPermission> {
    return window.Notification?.requestPermission() ?? Promise.resolve('denied')
  }

  show(opts: NotificationOptions) {
    this.playSound(opts)
    if (this.app.isElectron) {
      this.showNative(opts)
    } else {
      this.showWeb(opts)
    }
  }

  private async showNative(opts: NotificationOptions) {
    let { id, onClick, onReply, onAction, onFailed, onClose, onShow, ...attrs } = opts
    id ??= uuid()
    await this.app.electron.notification.show({ id, ...attrs, silent: true })
    this.nativeHandlers.set(id, { onClick, onReply, onAction, onFailed, onClose, onShow })
  }

  private showWeb(opts: NotificationOptions) {
    let { id, onClick, onClose, onShow, title, body, silent } = opts
    id ??= uuid()
    const notification = new Notification(title, {
      body,
      tag: id,
      silent: true,
    })
    notification.onclick = onClick
    notification.onclose = onClose
    notification.onshow = onShow
  }

  private playSound(opts: NotificationOptions) {
    const { sound, silent } = opts

    if (silent || !this.playNotificationSounds) {
      return
    }

    switch (sound) {
      case 'default':
        return this.app.sound.play('secondaryNotification')
      case 'missed-call':
        return this.app.sound.play('missedCall')
      case 'new-message':
        return this.app.sound.play('newMessage')
      default:
        return
    }
  }
  private registerListeners() {
    if (!this.app.isElectron) return
    this.app.electron.on('notification', (event) => {
      switch (event.type) {
        case 'click':
          return this.nativeHandlers.get(event.id)?.onClick?.()
        case 'action':
          return this.nativeHandlers.get(event.id)?.onAction?.(event.index)
        case 'close':
          return this.nativeHandlers.get(event.id)?.onClose?.()
        case 'failed':
          return this.nativeHandlers.get(event.id)?.onFailed?.(event.error)
        case 'reply':
          return this.nativeHandlers.get(event.id)?.onReply?.(event.reply)
        case 'show':
          return this.nativeHandlers.get(event.id)?.onShow?.()
      }
    })
  }
}
