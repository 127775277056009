import React, { useCallback, useState } from 'react'

/**
 * A more advanced `useState` custom hook to track the pristineness
 * of data. So, if `setState` is called the data won't be pristne
 * anymore. This hook could also be enhanced to hold history of
 * changes, count mutations, etc.
 */
export default function useTrackableState<T>(
  initialState: T | (() => T),
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [state, _setState] = useState<T>(initialState)
  const [isPristine, setIsPristine] = useState(true)

  const setState = useCallback((value: React.SetStateAction<T>) => {
    setIsPristine(false)
    _setState(value)
  }, [])

  return [state, setState, isPristine]
}
