// @ts-strict-ignore
import { action, flow, makeAutoObservable } from 'mobx'
import Service from '.'
import { last } from '../lib'
import { ById, PageInfo } from '../types'
import { Activity, Conversation } from './conversation-store'
import { PersistedCollection, Comment, Reaction, CodableMessageMedia } from './model'
import { makePersistable } from './storage/persistable'
import { ActivityRepository } from './worker/repository'
import {
  CommentUpdateMessage,
  CommentDeleteMessage,
  ReactionUpdateMessage,
  ReactionDeleteMessage,
} from './transport/websocket'

export default class ActivityStore {
  readonly collection: PersistedCollection<Activity, ActivityRepository> = null

  private lastFetchedAt: { [key: string]: number } = {}

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table('activity'),
      classConstructor: () => new Activity(this.root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ActivityStore', {
      lastFetchedAt: root.storage.async(),
      pageInfos: root.storage.async(),
    })

    this.handleWebsocket()
    this.buildRelationships()
  }

  get(id: string) {
    return this.collection.get(id)
  }

  /**
   * Tries to get an activity from memory first, then the underlying storage
   */
  getById = async (id: string): Promise<Activity> => {
    let activity: Activity
    if ((activity = this.collection.get(id, { skipStorage: true }))) {
      return activity
    }
    if ((activity = await this.collection.performQuery((repo) => repo.get(id)))) {
      return activity
    }
    return null
  }

  sendRaw(params: {
    to: string
    phoneNumberId: string
    body: string
    media?: CodableMessageMedia[]
  }) {
    return this.root.transport.communication.activities.send(params)
  }

  loadByIds(ids: string[]) {
    ids = ids.filter((id) => !this.collection.isInMemory(id))
    return this.collection.performQuery((repo) => repo.getBulk(ids))
  }

  send(activity: Activity) {
    return this.root.transport.communication.activities
      .send({
        body: activity.body,
        media: activity.media,
        id: activity.id,
        conversationId: activity.conversation.id,
        phoneNumberId: activity.conversation.phoneNumberId,
        directNumberId: activity.conversation.directNumberId,
        to: activity.conversation.phoneNumber,
      })
      .then(
        action(({ conversation: conversationJson, activity: activityJson }) => {
          this.root.conversation.collection.load(conversationJson)
          return this.collection.load(activityJson)[0]
        }),
      )
  }

  /**
   * Loads cached activities for a given conversation. The results are paginated.
   * @param conversation
   * @param beforeId
   */
  load = (conversation: Conversation, beforeId?: string, searchActivityId?: string) => {
    this.collection.performQuery((repo) =>
      repo.forConversation(conversation.id, beforeId, searchActivityId),
    )
  }

  addReaction(reaction: Reaction) {
    this.collection.put(reaction.activity)
    return this.root.transport.communication.reactions.post(reaction.toJSON())
  }

  deleteReaction(reaction: Reaction) {
    this.collection.put(reaction.activity)
    return this.root.transport.communication.reactions.delete(reaction.id)
  }

  addComment(comment: Comment) {
    this.collection.put(comment.activity)
    return this.root.transport.communication.comments.post(comment.toJSON())
  }

  deleteComment(comment: Comment) {
    this.collection.put(comment.activity)
    return this.root.transport.communication.comments.delete(comment.id)
  }

  resolve(activity: Activity) {
    return this.root.transport.communication.activities.resolve(activity.id)
  }

  unresolve(activity: Activity) {
    return this.root.transport.communication.activities.unresolve(activity.id)
  }

  /**
   * Fetches from the API, changes to activities since a certain time
   * @param conversation
   * @returns
   */
  fetchRecent = async (conversation: Conversation) => {
    const self = this
    const lastFetchedAt = this.lastFetchedAt[conversation.id]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : null
    if (conversation.isNew) return
    return this.root.transport.communication.activities
      .list({ id: conversation.id, since })
      .then(
        flow(function* (res) {
          const activities = yield self.collection.load(res.result)
          self.lastFetchedAt[conversation.id] = Math.max(
            lastFetchedAt || 0,
            ...activities.map((c) => c.updatedAt),
          )
          if (!since) {
            self.savePreviousPage(res.pageInfo, activities)
          }
        }),
      )
  }

  fetchAround = (conversationId: string, activityId: string) => {
    const self = this
    return this.root.transport.communication.activities
      .list({
        id: conversationId,
        before: activityId,
        last: 100,
        next: 100,
        inclusive: true,
      })
      .then(
        flow(function* (res) {
          const activities = yield self.collection.load(res.result)
          self.saveNextPage(res.pageInfo, activities)
          self.savePreviousPage(res.pageInfo, activities)
        }),
      )
  }

  fetchBefore = (activity: Activity) => {
    const self = this
    return this.root.transport.communication.activities
      .list({ id: activity.conversationId, before: activity.beforeId, last: 200 })
      .then(
        flow(function* (res) {
          const activities = yield self.collection.load(res.result)
          if (activity.type == 'loading') {
            self.collection.delete(activity)
          }
          self.savePreviousPage(res.pageInfo, activities)
        }),
      )
  }

  fetchAfter = (activity: Activity) => {
    const self = this
    return this.root.transport.communication.activities
      .list({ id: activity.conversationId, before: activity.afterId, next: 200 })
      .then(
        flow(function* (res) {
          const activities = yield self.collection.load(res.result)
          if (activity.type == 'loading') {
            self.collection.delete(activity)
          }
          self.saveNextPage(res.pageInfo, activities)
        }),
      )
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'reaction-update':
          return this.handleReactionUpdate(msg)
        case 'reaction-delete':
          return this.handleReactionDelete(msg)
        case 'comment-update':
          return this.handleCommentUpdate(msg)
        case 'comment-delete':
          return this.handleCommentDelete(msg)
      }
    })
  }

  private handleReactionUpdate(msg: ReactionUpdateMessage) {
    const activity = this.collection.get(msg.reaction.activityId)
    if (!activity) return
    const object = msg.reaction.commentId
      ? activity.comments.find((comment) => comment.id === msg.reaction.commentId)
      : activity
    if (!object) return
    const reaction = object.reactions.find((reaction) => reaction.id == msg.reaction.id)
    if (reaction) {
      reaction.deserialize(msg.reaction)
    } else {
      object.reactions.push(new Reaction(this.root, object, msg.reaction))
    }
  }

  private handleReactionDelete(msg: ReactionDeleteMessage) {
    const activity = this.collection.get(msg.reaction.activityId)
    if (!activity) return
    const reaction = activity.reactions.find((reaction) => reaction.id == msg.reaction.id)
    if (reaction) activity.deleteReaction(reaction)
  }

  private handleCommentUpdate(msg: CommentUpdateMessage) {
    const activity = this.collection.get(msg.comment.activityId)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) {
      comment.deserialize(msg.comment)
    } else {
      activity.comments.push(new Comment(this.root, activity, msg.comment))
    }
  }

  private handleCommentDelete(msg: CommentDeleteMessage) {
    const activity = this.collection.get(msg.comment.activityId)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) activity.deleteComment(comment)
  }

  private buildRelationships() {
    this.collection.observe(
      action(({ type, objects }) => {
        const byConversation: ById<Activity[]> = objects.reduce((final, activity) => {
          final[activity.conversationId] ??= []
          final[activity.conversationId].push(activity)
          return final
        }, {})

        if (type === 'put') {
          Object.keys(byConversation).forEach((conversationId) => {
            const conversation = this.root.conversation.collection.get(conversationId)
            const activities = byConversation[conversationId]
            activities.forEach((activity) => {
              if (activity.conversation?.id !== activity.conversationId) {
                activity.conversation?.activities.delete(activity.id)
                activity.conversation = conversation
              }
            })
            conversation?.activities.putBulk(activities)
          })
        } else {
          Object.keys(byConversation).forEach((conversationId) => {
            const conversation = this.root.conversation.collection.get(conversationId)
            const activities = byConversation[conversationId]
            conversation?.activities.deleteBulk(activities)
          })
        }
      }),
    )
  }

  private saveNextPage(pageInfo: PageInfo, activities: Activity[]) {
    if (pageInfo.hasNextPage) {
      const lastItem = last(activities.sort((a, b) => a.createdAt - b.createdAt))
      this.collection.load(
        new Activity(this.root, {
          id: `${lastItem.id}-after`,
          type: 'loading',
          conversationId: lastItem.conversationId,
          createdAt: lastItem.createdAt + 1,
          afterId: lastItem.id,
        }),
      )
    }
  }

  private savePreviousPage(pageInfo: PageInfo, activities: Activity[]) {
    if (pageInfo.hasPreviousPage) {
      const firstItem = activities.sort((a, b) => a.createdAt - b.createdAt)[0]
      this.collection.load(
        new Activity(this.root, {
          id: `${firstItem.id}-before`,
          type: 'loading',
          conversationId: firstItem.conversationId,
          createdAt: firstItem.createdAt - 1,
          beforeId: firstItem.id,
        }),
      )
    }
  }
}
