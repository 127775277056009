// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { NativeSelect } from '../../../component/select'
import Switch from '../../../component/switch'
import { timezones } from '../../../lib/constants'
import { useStore } from '../../../lib/hooks'
import { NotificationUnreadCount } from '../../../service/model'
import { Header, Item, Page } from '../common'
import Schedule from '../schedule'
import { NotificationSettingsController } from './controller'

interface NotificationSettingsProps {}

const NotificationSettings: React.FC<NotificationSettingsProps> = function ({}) {
  const styles = useStyles({})
  const app = useAppStore()
  const controller = useStore(() => new NotificationSettingsController(app), [])

  const handleAppIconBadgeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    controller.setAppIconBadge(event.target.value as NotificationUnreadCount)
  }

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    controller.setTimezone(event.target.value)
  }

  return (
    <Page>
      <Header title="Notifications" subtitle="Manage your notification preferences" />
      <Item
        title="Include message preview"
        description="Turn this off for extra privacy"
        input={
          <Switch
            checked={controller.includePreview}
            onChange={controller.toggleIncludePreview}
          />
        }
      />
      <Item
        title="Play notification sounds"
        description="Turn this off if you would like to disable all notification sounds (not including ringtones)"
        input={
          <Switch
            checked={controller.playNotificationSounds}
            onChange={controller.togglePlayNotificationSounds}
          />
        }
      />
      <Item
        title="Send notifications while I’m off"
        description="Turn this off to completely disable notifications during DND mode or outside business hours"
        input={
          <Switch
            checked={controller.sendNotificationsWhenUnavailable}
            onChange={controller.toggleSendNotificationsWhenUnavailable}
          />
        }
      />
      {/* <Item
        title="App icon badge"
        description="Define what to display in the app icon badge"
        input={
          <NativeSelect
            value={controller.appIconBadge}
            onChange={handleAppIconBadgeChange}
          >
            <option value="activities">Total unread count</option>
            <option value="conversations">Unread conversations</option>
          </NativeSelect>
        }
      /> */}
      <Header
        title="Work schedule"
        subtitle="Define your schedule for when (and when not) to receive calls and notifications"
        style={{ marginTop: 64 }}
      />
      <Item
        title="Enable work schedule"
        description="Control when you would like to receive calls and notifications"
        input={
          <Switch
            checked={controller.scheduleEnabled}
            onChange={controller.toggleScheduleEnabled}
          />
        }
      />

      {controller.scheduleEnabled && (
        <>
          <Item
            title="Timezone"
            description="Set your timezone"
            input={
              <NativeSelect
                value={controller.schedule?.timezone}
                style={{ width: '100%' }}
                onChange={handleTimezoneChange}
              >
                {timezones.map((tz) => (
                  <option value={tz.name} key={tz.name}>
                    {tz.name}
                  </option>
                ))}
              </NativeSelect>
            }
          />
          <Item
            vertical
            title="Default schedule"
            description="Define your weekly working schedule"
          >
            <Schedule
              availability={controller.schedule}
              onChange={controller.setSchedule}
            />
          </Item>
        </>
      )}
    </Page>
  )
}

export default observer(NotificationSettings)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
