// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { v4 as uuid } from 'uuid'
import { removeAtIndex } from '../../lib/collections'
import { parseDate } from '../../lib/date'
import { stripSkinVariation } from '../../lib/emoji'
import { Model } from './base'
import { Activity, Enrichment } from './activity'
import { CodableMessageMedia, MessageMedia } from './media'
import { CodableReaction, Reaction } from './reaction'

export interface CodableComment {
  id: string
  body: string
  enrichment: Enrichment
  media: CodableMessageMedia[]
  reactions: CodableReaction[]
  createdAt: number
  updatedAt: number
  userId: string
}

export interface EncodableComment {
  id: string
  activityId: string
  body: string
  media?: CodableMessageMedia[]
}

export interface DecodableComment extends CodableComment {
  activityId?: string
  conversationId?: string
}

export class Comment implements Model, CodableComment {
  readonly type = 'message'

  id: string = `CM${uuid()}`.replace(/-/g, '')
  body: string = null
  enrichment: Enrichment = null
  media: MessageMedia[] = []
  createdAt: number = Date.now()
  updatedAt: number = Date.now()
  userId: string = null

  // Relations
  reactions: Reaction[] = []

  constructor(
    private root: Service,
    readonly activity: Activity,
    attrs: Partial<CodableComment> | Partial<DecodableComment> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  addReaction(reaction: Reaction) {
    this.reactions.push(reaction)
    return this.root.activity.addReaction(reaction)
  }

  deleteReaction(reaction: Reaction) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.root.activity.deleteReaction(reaction)
  }

  toggleReaction(emoji: string) {
    const body = this.root.emoji.getEmojiWithSkinTone(emoji)
    const reaction = this.reactions.find(
      (r) =>
        stripSkinVariation(r.body) === stripSkinVariation(body) &&
        r.userId === this.root.user.current.id,
    )

    if (reaction) {
      return this.deleteReaction(reaction)
    } else {
      return this.addReaction(
        new Reaction(this.root, this, { body, userId: this.root.user.current.id }),
      )
    }
  }

  deserialize(attrs?: Partial<CodableComment> | Partial<DecodableComment>) {
    if (attrs) {
      const { media, reactions, ...json } = attrs
      Object.assign(this, json)
      this.media = media?.map((m) => new MessageMedia(this.root).deserialize(m)) ?? []
      this.reactions = reactions?.map?.((r) => new Reaction(this.root, this, r)) ?? []
      this.createdAt = parseDate(json.createdAt || this.createdAt)
      this.updatedAt = parseDate(json.updatedAt || this.updatedAt)
    }
    return this
  }

  serialize(): CodableComment {
    return {
      id: this.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
      reactions: this.reactions?.map((r) => r.serialize()),
      enrichment: toJS(this.enrichment),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  toJSON(): EncodableComment {
    return {
      id: this.id,
      activityId: this.activity.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
    }
  }
}
