// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Tooltip from '../../../component/tooltip'
import { ItemProps } from '.'
import Row from './row'
import { Item } from '../controller'

export type StatusProps<I extends Item> = ItemProps<I>

const Status = function <I extends Item>({ controller, item }: StatusProps<I>) {
  const styles = useStyles({ item })

  return (
    <Row controller={controller} item={item} className={styles.root}>
      <div className={styles.status}>
        {item.status.type === 'error' ? (
          <>
            <Tooltip title={item.status.details}>
              <span className={styles.error}>{item.status.text}</span>
            </Tooltip>
            {item.status.canRetry ? (
              <>
                <span className={styles.action} onClick={item.handleRetrySend}>
                  Retry
                </span>
                <span className={styles.action} onClick={item.handleDelete}>
                  Delete
                </span>
              </>
            ) : null}
          </>
        ) : (
          <span>{item.status.text}</span>
        )}
      </div>
    </Row>
  )
}

export default observer(Status)

const useStyles = makeStyles<Theme, Partial<StatusProps<Item>>>(
  (theme) => ({
    root: ({ item }) => ({
      marginTop: item.display.dimensions.statusMarginTop,
      marginBottom: item.display.dimensions.statusMarginBottom,
    }),
    status: {
      margin: ({ item }) => `0 ${item.display.dimensions.statusMarginHorizontal}px`,
      fontSize: 11,
      color: theme.palette.op.gray[3],
    },
    error: {
      cursor: 'help',
      color: theme.palette.error.main,
      marginLeft: 8,
    },
    action: {
      cursor: 'pointer',
      padding: '0 5px',

      '&:hover': {
        color: theme.palette.text.secondary,
      },
    },
  }),
  { name: Status.name },
)
