import Debug from 'debug'
import { action, computed, makeObservable, observable, override } from 'mobx'
import { uniqueBy } from '../../lib/collections'
import { isNonNull } from '../../lib/rx-operators'
import shortId from '../../lib/short-id'
import { Identity, ImmutableCollection, MessageMedia } from '../../service/model'
import { EmojiPickerProps } from '../emoji-picker'
import { CallItemPart, Controller as FeedController, Item, ItemDisplay } from '../feed'
import { Controller as InputBarController } from '../input-bar'
import { itemDimensions } from './dimensions'

export class CommentFeedItemDisplay extends ItemDisplay {
  override readonly dimensions = itemDimensions
  override readonly fullWidth = true
  override readonly showTimestampAsPopover = true

  constructor(item: CommentFeedItem) {
    super(item)
    makeObservable(this, {
      showTimestampHeader: override,
    })
  }

  override get showTimestampHeader() {
    return false
  }
}

export abstract class CommentFeedItem extends Item {
  readonly isOutgoing = false
  readonly isInternal = true
  readonly status = null

  constructor() {
    super()
    makeObservable(this, {
      display: computed,
      handleThreadOpen: action.bound,
      handleRetrySend: action.bound,
      handleDeleteCallRecording: action.bound,
    })
  }

  get display() {
    return new CommentFeedItemDisplay(this)
  }

  handleThreadOpen() {
    // ignore
  }

  handleRetrySend() {
    // ignore
  }

  handleDeleteCallRecording() {
    // ignore
  }

  createCallParts(): CallItemPart[] {
    return []
  }
}

export default abstract class Controller<I extends CommentFeedItem> {
  abstract items: ImmutableCollection<I>
  abstract resolvedAt: number
  abstract resolvedBy: Identity
  abstract inputBarController: InputBarController

  readonly id = shortId()
  readonly debug = Debug(`op:comments:controller:@${this.id}`)
  readonly feedController = new CommentsFeedController<I>(this)
  readonly menu = new CommentsMenu<I>(this)

  _collapsed: boolean | null = null
  _previousCommentCount: number | null = null

  /**
   * Set once, this value stores what the original comment count was when the
   * thread first loaded. It is used to help maintain the number of previous
   * comments that are collapsed.
   */
  initialCommentCount: number | null = null

  constructor() {
    makeObservable(this, {
      _collapsed: observable.ref,
      _previousCommentCount: observable.ref,
      collapsed: computed,
      resolved: computed,
      participants: computed,
      previousCommentCount: computed,
      collapse: action.bound,
      expand: action.bound,
      handleShowPreviousCommentsClick: action.bound,
      handleShowAllCommentsClick: action.bound,
      handleCollapseOlderCommentsClick: action.bound,
      handleCollapseClick: action.bound,
      handleExpandClick: action.bound,
      handleResolveClick: action.bound,
      handleUnresolveClick: action.bound,
      handleHeaderClick: action.bound,
      handleMenuOpenClick: action.bound,
    })
  }

  get collapsed() {
    const { _collapsed: collapsed, resolved } = this
    return typeof collapsed === 'boolean' ? collapsed : resolved
  }

  get resolved() {
    return Boolean(this.resolvedBy && this.resolvedAt)
  }

  /**
   * Get an array of unique thread participants.
   */
  get participants(): Identity[] {
    return uniqueBy(
      this.items.list.map((comment) => comment.sender).filter(isNonNull),
      (sender) => sender.id,
    )
  }

  get previousCommentCount() {
    if (typeof this.initialCommentCount !== 'number') {
      this.debug('setting initial comment count (items.length: %O)', this.items.length)
      this.initialCommentCount = this.items.length
    }

    return this._previousCommentCount ?? this.initialCommentCount - 2
  }

  abstract handleCancel(): void
  abstract resolve(): void
  abstract unresolve(): void
  abstract openMedia(media: MessageMedia): void

  collapse() {
    this._collapsed = true
  }

  expand() {
    this._collapsed = false
  }

  handleShowPreviousCommentsClick(event: React.MouseEvent) {
    this._previousCommentCount = Math.max(0, this.previousCommentCount - 10)
  }

  handleShowAllCommentsClick(event: React.MouseEvent) {
    event.stopPropagation()
    this._previousCommentCount = 0
    this.menu.close()
  }

  handleCollapseOlderCommentsClick(event: React.MouseEvent) {
    event.stopPropagation()
    this._previousCommentCount = null
    this.initialCommentCount = null
    this.menu.close()
  }

  handleCollapseClick(event: React.MouseEvent) {
    event.stopPropagation()
    this.collapse()
    this.menu.close()
  }

  handleExpandClick(event: React.MouseEvent) {
    event.stopPropagation()
    this.expand()
    this.menu.close()
  }

  handleResolveClick(event: React.MouseEvent) {
    event.stopPropagation()
    this.resolve()
    this.menu.close()
  }

  handleUnresolveClick(event: React.MouseEvent) {
    event.stopPropagation()
    this.unresolve()
    this.menu.close()
  }

  handleHeaderClick() {
    if (this.collapsed) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  handleMenuOpenClick(event: React.MouseEvent) {
    event.stopPropagation()
    this.menu.open = true
  }
}

export class CommentsFeedController<I extends CommentFeedItem> extends FeedController<I> {
  readonly features = { threads: false }
  readonly strategy = 'static'

  constructor(readonly controller: Controller<I>) {
    super()
    makeObservable(this, {
      items: computed,
      openEmojiPicker: action.bound,
      openMedia: action.bound,
      deleteCallRecording: action.bound,
      fetchBefore: action.bound,
      fetchAfter: action.bound,
      loadMore: action.bound,
    })
  }

  get items() {
    const comments = this.controller.items.list.slice(
      Math.max(0, this.controller.previousCommentCount),
    )

    return this.controller.items.clone(comments)
  }

  openEmojiPicker(props: Omit<EmojiPickerProps, 'open'>) {
    this.controller.inputBarController.openEmojiPicker(props)
  }

  openMedia(media: MessageMedia) {
    this.controller.openMedia(media)
  }

  deleteCallRecording() {
    // ignore
  }

  async fetchBefore() {
    // ignore
  }

  async fetchAfter() {
    // ignore
  }

  loadMore() {
    // ignore
  }
}

export class CommentsMenu<I extends CommentFeedItem> {
  open = false

  constructor(readonly controller: Controller<I>) {
    makeObservable(this, {
      open: observable.ref,
      close: action.bound,
      handleClose: action.bound,
    })
  }

  close() {
    this.open = false
  }

  handleClose() {
    this.close()
  }
}
