import { Theme, makeStyles, alpha } from '@material-ui/core'
import React from 'react'
import Typography from '@ui/Typography'
import { GroupModel } from '../../../service/model'
import { useAppStore } from '@src/app/context'
import { observer } from 'mobx-react-lite'
import { AppAvatarRow } from '@src/app/components'

const GroupRow = ({ group }: { group: GroupModel }) => {
  const styles = useStyles()
  const { service, command } = useAppStore()

  const identities = service.member.collection.list.filter((member) =>
    group.membersIdentities.includes(member),
  )

  return (
    <div
      className={styles.root}
      onClick={() => command.present({ name: 'edit group', id: group.id })}
    >
      <div className={styles.leftContainer}>
        <span className={styles.emoji}>{group.symbol}</span>
        <div>
          <Typography variant="callout" color="textPrimary" className={styles.name}>
            {group.name}
          </Typography>
          <Typography variant="footnote" color="textTertiary">
            {`${group.members.length} ` +
              `${group.members.length === 1 ? 'member' : 'members'}`}
          </Typography>
        </div>
      </div>
      <AppAvatarRow identities={identities} maxAvatars={5} />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: `0 2px 8px 0 ${alpha(
        theme.palette.op.border.common,
        0.03,
      )}, 0 0 0 1.5px ${alpha(theme.palette.op.border.common, 0.06)}`,
      height: 80,
      borderRadius: 12,
      marginTop: 16,
      padding: 20,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.op.hover.primary,
      },
    },
    name: {
      marginBottom: 4,
    },
    leftContainer: {
      display: 'flex',
      flex: 1,
    },
    emoji: {
      marginRight: 18,
      height: 40,
      width: 40,
      fontSize: 22,
      boxShadow: `inset 0 0 0 1.5px ${alpha(theme.palette.op.border.common, 0.08)}`,
      borderRadius: 9,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: GroupRow.name },
)
export default observer(GroupRow)
