// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import {
  Identity,
  Member,
  Organization,
  PhoneNumberGroup,
  GroupModel,
} from '../../../../service/model'
import Avatar from '../../../avatar'
import GroupAvatar from '../../../group-avatar'
import { MenuItem, MenuItemProps } from '../../../menu-v2'
import Typography from '@ui/Typography'

export interface MentionsItemProps extends MenuItemProps {
  target: Identity
}

const MentionsItem: React.FC<MentionsItemProps> = function ({ target, ...props }) {
  const styles = useStyles({})

  const renderMember = useCallback((member: Member) => {
    return (
      <>
        <Typography nowrap variant="footnote">
          {member.name} {member.presence.symbol}
        </Typography>
        <Typography
          nowrap
          variant="caption2"
          color="textSecondary"
          style={{ marginTop: 2 }}
        >
          {member.presence.text}
        </Typography>
      </>
    )
  }, [])

  const renderOrg = useCallback((org: Organization) => {
    return (
      <>
        <Typography nowrap variant="footnote">
          {org.name}
        </Typography>
        <Typography
          nowrap
          variant="caption2"
          color="textSecondary"
          style={{ marginTop: 2 }}
        >
          Notify everyone in your organization
        </Typography>
      </>
    )
  }, [])

  const renderGroup = useCallback((group: PhoneNumberGroup) => {
    return (
      <>
        <Typography nowrap variant="footnote">
          {group.name}
        </Typography>
        <Typography
          nowrap
          variant="caption2"
          color="textSecondary"
          style={{ marginTop: 2 }}
        >
          Notify everyone in this inbox
        </Typography>
      </>
    )
  }, [])

  const renderUserGroup = useCallback((group: GroupModel) => {
    return (
      <>
        <Typography nowrap variant="footnote">
          {group.name}
        </Typography>
        <Typography
          nowrap
          variant="caption2"
          color="textSecondary"
          style={{ marginTop: 2 }}
        >
          Notify everyone in this group
        </Typography>
      </>
    )
  }, [])

  const renderTargetIcon = useCallback((t: Identity) => {
    if (t instanceof Member || t instanceof Organization) {
      return <Avatar identity={t} size={34} />
    } else if (t instanceof PhoneNumberGroup) {
      return <GroupAvatar group={t.phoneNumber} size={34} />
    } else if (t instanceof GroupModel) {
      return <GroupAvatar group={{ symbol: t.symbol }} size={34} />
    }
  }, [])

  const renderTarget = useCallback(
    (t: Identity) => {
      if (t instanceof Member) {
        return renderMember(t)
      } else if (t instanceof Organization) {
        return renderOrg(t)
      } else if (t instanceof PhoneNumberGroup) {
        return renderGroup(t)
      } else if (t instanceof GroupModel) {
        return renderUserGroup(t)
      }
    },
    [renderMember, renderOrg, renderGroup],
  )

  return (
    <MenuItem icon={renderTargetIcon(target)} {...props}>
      {renderTarget(target)}
    </MenuItem>
  )
}

export default observer(MentionsItem)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
  }),
  { name: MentionsItem.name },
)
