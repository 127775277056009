// @ts-strict-ignore
import { action, flow, makeAutoObservable, remove } from 'mobx'
import { Subject, Subscription } from 'rxjs'
import Service from '.'
import { NotFoundError } from '../lib/api/error-handler'
import log from '../lib/log'
import { isNonNull } from '../lib/rx-operators'
import { ConversationParticipantStatus, PageInfo } from '../types'
import {
  Activity,
  Conversation,
  IActivity,
  IConversation,
  Participant,
  PersistedCollection,
} from './model'
import { makePersistable } from './storage/persistable'
import { ConversationRepository } from './worker/repository/conversation'
import { ActivitySearchParams } from './transport/communication'
import {
  ActivityUpdateMessage,
  ConversationUpdateMessage,
  ParticipantStatusMessage,
} from './transport/websocket'
import MainWorker from './worker/main'

export type ConversationQuery = {
  phoneNumberId?: string
  directNumberId?: string
  phoneNumber: string
  canBeNew?: boolean
}

export type ConversationsQuery = {
  phoneNumberId?: string
  directNumberId?: string
  snoozed?: boolean
  read?: boolean
  last?: number
}

export default class ConversationStore {
  readonly collection: PersistedCollection<Conversation, ConversationRepository>

  private lastFetchedAt: { [key: string]: number } = {}
  private pageInfos: { [key: string]: PageInfo } = {}

  unreadCount: { [key: string]: number } = {}
  private unreadCountSubscription: Subscription

  presence: {
    [key: string]: {
      [key: string]: { status: ConversationParticipantStatus; timestamp: number }
    }
  } = {}

  constructor(private root: Service, private worker: MainWorker) {
    this.collection = new PersistedCollection({
      table: root.storage.table('conversation'),
      classConstructor: () => new Conversation(root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ConversationStore', {
      lastFetchedAt: root.storage.sync(),
      pageInfos: root.storage.async(),
    })

    this.handleWebsocket()

    setInterval(this.cleanUpLastPresenceTimes, 10000)
  }

  fetchRecent = (params: ConversationsQuery) => {
    const self = this
    const key = this.keyForQuery(params)
    const lastFetchedAt = this.lastFetchedAt[key]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : null
    const filters = since
      ? { directNumberId: params.directNumberId, phoneNumberId: params.phoneNumberId }
      : params
    return this.root.transport.communication.conversations
      .list({
        ...filters,
        since,
        includeDeleted: Boolean(since),
      })
      .then(
        flow(function* (response) {
          const convos = yield self.load(response.result)
          self.lastFetchedAt[key] = Math.max(
            lastFetchedAt || 0,
            ...convos.map((c) => c.updatedAt),
          )
          if (!since) {
            self.pageInfos[key] = response.pageInfo
          }
        }),
      )
  }

  hasMore = (params: ConversationsQuery): boolean => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    return !pageInfo || pageInfo?.hasPreviousPage == true
  }

  /**
   * Tries to get a conversation from memory first, then the underlying storage and then
   * if not found, from the API.
   * @param id
   */
  getById = async (id: string): Promise<Conversation> => {
    let conv: Conversation
    if ((conv = this.collection.get(id, { skipStorage: true }))) {
      return conv
    }
    if ((conv = await this.collection.performQuery((repo) => repo.get(id)))) {
      return conv
    }
    if (
      (conv = await this.root.transport.communication.activities
        .list({ id })
        .then((res) => this.load([res.conversation], res.result)[0]))
    ) {
      return conv
    }
    return null
  }

  findAll = async (query: ConversationQuery): Promise<Conversation[]> => {
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')
    this.collection.performQuery((table) => table.find(query))
    return this.root.transport.communication.conversations
      .list(query)
      .then((res) => this.load(res.result))
  }

  findOne = async (query: ConversationQuery): Promise<Conversation> => {
    let conv: Conversation
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')

    const filter = (c: IConversation): boolean => {
      return (
        (query.canBeNew ? true : !c.isNew) &&
        !c.deletedAt &&
        c.phoneNumber === query.phoneNumber &&
        (!query.directNumberId || query.directNumberId === c.directNumberId) &&
        (!query.phoneNumberId || query.phoneNumberId === c.phoneNumberId)
      )
    }

    /**
     * Check memory
     */
    if ((conv = this.collection.list.find(filter))) {
      return conv
    }

    /**
     * Check database
     */
    if (
      (conv = await this.collection
        .performQuery((repo) => repo.find(query))
        .then((a) => a[0]))
    ) {
      return conv
    }

    /**
     * Ask the API
     */
    return this.root.transport.communication.activities
      .list(query)
      .then((res) => this.load([res.conversation], res.result)[0])
      .catch((e) => {
        if (e instanceof NotFoundError) {
          return null
        }
        log.error(e)
      })
  }

  findList = (params: ConversationsQuery) => {
    return this.collection.performQuery((repo) =>
      repo
        .find(params)
        .then((conversations) =>
          Promise.all([
            this.root.activity.loadByIds(conversations.map((c) => c.lastActivityId)),
            this.root.contact.loadByNumbers(
              conversations.flatMap((c) => c.phoneNumber.split(',')),
            ),
          ]).then(() => conversations),
        ),
    )
  }

  fetchMore = (params: ConversationsQuery) => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    if (!this.hasMore(params)) return
    return this.root.transport.communication.conversations
      .list({ ...params, before: pageInfo?.startId, includeDeleted: false })
      .then(
        action((response) => {
          this.load(response.result)
          this.pageInfos[key] = response.pageInfo
        }),
      )
  }

  search = (params: ActivitySearchParams) => {
    return this.root.transport.communication.conversations.search(params)
  }

  snooze = (id: string, duration: number = 525949200) => {
    return this.root.transport.communication.conversations.snooze(id, duration)
  }

  unsnooze = (id: string) => {
    return this.root.transport.communication.conversations.unsnooze(id)
  }

  markAsRead = (id: string) => {
    return this.root.transport.communication.conversations.markAsRead(id)
  }

  markAsUnread = (id: string) => {
    return this.root.transport.communication.conversations.markAsUnread(id)
  }

  archive = async (id: string) => {
    const conversation = this.collection.get(id)
    this.collection.delete(conversation)
    if (!conversation.isNew) {
      return this.root.transport.communication.conversations.archive(id)
    }
  }

  participantStatus = (conversationId: string, status: ConversationParticipantStatus) => {
    const userId = this.root.user.current.id
    this.presence[conversationId] ??= {}
    if (status === 'exited') {
      remove(this.presence[conversationId], userId)
    } else {
      this.presence[conversationId][userId] = { timestamp: Date.now(), status }
    }
    return this.root.transport.communication.conversations.participantStatus(
      conversationId,
      status,
    )
  }

  upload(
    file: File,
    onProgress?: (progress: number, total: number) => void,
  ): Promise<string> {
    return this.root.transport.communication.upload(file, onProgress)
  }

  loadUnreadCounts() {
    this.worker.service.conversation.unread().then(
      action((unread) => {
        this.unreadCount = unread
      }),
    )
    this.unreadCountSubscription?.unsubscribe()
    this.unreadCountSubscription = this.worker.onEvent.subscribe((event) => {
      if (event.type === 'unread changed') {
        this.unreadCount[event.phoneNumberId] = event.unreadCount
      }
    })
  }

  private keyForQuery(params: ConversationsQuery) {
    return Object.keys(params)
      .sort()
      .map((k) => `${k}=${params[k]}`)
      .join('&')
  }

  async load(
    conversations: IConversation[],
    activities: IActivity[] = [],
  ): Promise<Conversation[]> {
    const convos = await this.collection.load(conversations)
    await this.root.activity.collection.load([
      ...conversations.map((c) => c.lastActivity).filter(isNonNull),
      ...activities,
    ])
    return convos
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'activity-update':
        case 'conversation-update':
          return this.handleConversationUpdate(msg)
        case 'participant-status':
          return this.handleParticipantStatus(msg)
      }
    })
  }

  private handleParticipantStatus(message: ParticipantStatusMessage) {
    if (message.status.effectiveAt && message.user.id) {
      this.presence[message.conversationId] ??= {}
      const conversation = this.presence[message.conversationId]
      if (message.status.name === 'exited') {
        remove(conversation, message.user.id)
        return
      }
      const lastStatus = conversation[message.user.id]
      const timestamp = Date.parse(message.status.effectiveAt)
      if (lastStatus && lastStatus.timestamp && lastStatus.timestamp > timestamp) {
        return
      } else {
        this.presence[message.conversationId][message.user.id] = {
          status: message.status.name,
          timestamp,
        }
      }
    }
  }

  private cleanUpLastPresenceTimes = () => {
    const threshold = Date.now() - 90000
    for (const conversationId in this.presence) {
      for (const userId in this.presence[conversationId]) {
        if (this.presence[conversationId][userId].timestamp < threshold) {
          remove(this.presence[conversationId], userId)
        }
      }
    }
  }

  private async handleConversationUpdate(
    msg: ActivityUpdateMessage | ConversationUpdateMessage,
  ) {
    if (msg.conversation?.deletedAt) {
      return this.collection.delete(msg.conversation.id)
    }
    await this.collection.load(msg.conversation)
    if (!msg.activity) return
    this.root.activity.collection.load(msg.activity)
  }
}

export { Conversation, Activity, Participant }
