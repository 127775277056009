import React from 'react'

export default {
  onEnter: (handler: Function) => {
    return (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handler(event)
      }
    }
  },
  onEnterOrTab: (handler: Function) => {
    return (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        handler(event)
      }
    }
  },
}

export const isAlphaNumericKey = (shortcut: string): boolean => {
  return Boolean(keys[shortcut])
}

const keys = {
  Backspace: true,
  BracketLeft: true,
  BracketRight: true,
  Comma: true,
  Digit0: true,
  Digit1: true,
  Digit2: true,
  Digit3: true,
  Digit4: true,
  Digit5: true,
  Digit6: true,
  Digit7: true,
  Digit8: true,
  Digit9: true,
  Equal: true,
  IntlBackslash: true,
  IntlRo: true,
  IntlYen: true,
  KeyA: true,
  KeyB: true,
  KeyC: true,
  KeyD: true,
  KeyE: true,
  KeyF: true,
  KeyG: true,
  KeyH: true,
  KeyI: true,
  KeyJ: true,
  KeyK: true,
  KeyL: true,
  KeyM: true,
  KeyN: true,
  KeyO: true,
  KeyP: true,
  KeyQ: true,
  KeyR: true,
  KeyS: true,
  KeyT: true,
  KeyU: true,
  KeyV: true,
  KeyW: true,
  KeyX: true,
  KeyY: true,
  KeyZ: true,
  Minus: true,
  Period: true,
  Quote: true,
  Semicolon: true,
  Slash: true,
}
