import { makeStyles, Theme } from '@material-ui/core/styles'
import AutoIcon from '@material-ui/icons/FlashOn'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import TextareaAutosize from '../../../component/textarea-autoresize'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'
import { EntityPhoneNumber } from '../../../service/model'
import { Header, Item, Section } from '../common'
import { CardProps } from '../settings-card'

interface AutoRepliesProps extends CardProps {
  phoneNumber: EntityPhoneNumber
}

const AutoReplies: React.FC<AutoRepliesProps> = function ({ phoneNumber, ...props }) {
  const { toast } = useAppStore()

  const [messages, setMessages] = useState({
    afterHoursMessage: '',
    afterHoursMissedCallWithoutVoicemail: '',
    afterHoursMissedCallWithVoicemail: '',
    businessHoursMessage: '',
    missedCallWithoutVoicemail: '',
    missedCallWithVoicemail: '',
  })

  useEffect(() => {
    if (!phoneNumber?.settings?.autoRespond) return
    const {
      afterHoursMessage,
      afterHoursMissedCallWithoutVoicemail,
      afterHoursMissedCallWithVoicemail,
      businessHoursMessage,
      missedCallWithoutVoicemail,
      missedCallWithVoicemail,
    } = phoneNumber.settings.autoRespond
    setMessages({
      afterHoursMessage,
      afterHoursMissedCallWithoutVoicemail,
      afterHoursMissedCallWithVoicemail,
      businessHoursMessage,
      missedCallWithoutVoicemail,
      missedCallWithVoicemail,
    })
  }, [phoneNumber?.id])

  const handleSave = (key: string) => {
    return (value: string) => {
      const updatedMessages = { ...messages, [key]: value }

      if (
        updatedMessages.afterHoursMissedCallWithoutVoicemail &&
        !updatedMessages.afterHoursMissedCallWithVoicemail
      ) {
        updatedMessages.afterHoursMissedCallWithVoicemail =
          updatedMessages.afterHoursMissedCallWithoutVoicemail
      }
      if (
        updatedMessages.missedCallWithoutVoicemail &&
        !updatedMessages.missedCallWithVoicemail
      ) {
        updatedMessages.missedCallWithVoicemail =
          updatedMessages.missedCallWithoutVoicemail
      }

      phoneNumber
        .updateSettings({
          autoRespond: updatedMessages,
        })
        .catch(toast.showError)
      setMessages(updatedMessages)
    }
  }

  return (
    <Section>
      <Header
        title="Auto-Replies"
        subtitle="Set up automatic text message replies based on different triggers on your phone number"
      />

      <Item
        title="During business hours"
        description="Set your auto-replies for events during business hours"
      >
        <AutoReply
          title="On incoming message"
          actionTitle="Set incoming message auto-reply"
          defaultMessage={messages.businessHoursMessage}
          placeholder="Hey! Thanks for your message, we will get back to you shortly."
          onSave={handleSave('businessHoursMessage')}
        />
        <AutoReply
          title="On missed call without voicemail"
          actionTitle="Set missed call (no voicemail) auto reply"
          defaultMessage={messages.missedCallWithoutVoicemail}
          placeholder="Hey! Sorry to miss your call. How can we help?"
          onSave={handleSave('missedCallWithoutVoicemail')}
        />
        <AutoReply
          title="On missed call with voicemail"
          actionTitle="Set missed call (with voicemail) auto reply"
          defaultMessage={messages.missedCallWithVoicemail}
          placeholder="Hey, we got your voicemail! We'll get back to you as soon as possible."
          onSave={handleSave('missedCallWithVoicemail')}
        />
      </Item>

      <Item
        title="After business hours"
        description="Set your auto-replies for events after business hours"
      >
        <AutoReply
          title="On incoming message"
          actionTitle="Set incoming message auto-reply"
          defaultMessage={messages.afterHoursMessage}
          placeholder="Hey! Thanks for your message. We're off for the day and will get back to you tomorrow."
          onSave={handleSave('afterHoursMessage')}
        />
        <AutoReply
          title="On missed call without voicemail"
          actionTitle="Set missed call (no voicemail) auto reply"
          defaultMessage={messages.afterHoursMissedCallWithoutVoicemail}
          placeholder="Hey! Thanks for your call. We're off until tomorrow and will get back to you then."
          onSave={handleSave('afterHoursMissedCallWithoutVoicemail')}
        />
        <AutoReply
          title="On missed call with voicemail"
          actionTitle="Set missed call (with voicemail) auto reply"
          defaultMessage={messages.afterHoursMissedCallWithVoicemail}
          placeholder="Hey! Thanks for leaving a message. We're off until tomorrow and will get back to you then."
          onSave={handleSave('afterHoursMissedCallWithVoicemail')}
        />
      </Item>
    </Section>
  )
}

interface AutoReplyProps {
  title: string
  actionTitle: string
  defaultMessage: string
  placeholder: string
  onSave: (message: string) => void
}

const AutoReply: React.FC<AutoReplyProps> = ({
  title,
  actionTitle,
  defaultMessage,
  placeholder,
  onSave,
}) => {
  const styles = useStyles({})
  const [message, setMessage] = useInputState(defaultMessage || placeholder)
  const [editing, setEditing] = useState<boolean>(false)

  useEffect(() => {
    setMessage(defaultMessage || placeholder)
  }, [defaultMessage])

  const handleSave = () => {
    onSave(message)
    setEditing(false)
  }

  const handleCancel = () => {
    setMessage(defaultMessage)
    setEditing(false)
  }

  const handleDelete = () => {
    onSave('')
    setEditing(false)
  }

  return (
    <div className={styles.row}>
      {editing ? (
        <div className={styles.autoReply}>
          <Typography
            variant="caption1"
            color="textSecondary"
            style={{ marginBottom: 6 }}
          >
            {title}
          </Typography>
          <TextareaAutosize
            autoFocus
            className={styles.input}
            value={message}
            onChange={setMessage}
            rowsMin={3}
            placeholder="Write a message to send..."
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div>
              <Button
                height={30}
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                height={30}
                variant="outlined"
                color="textPrimary"
                style={{ marginLeft: 5 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
            {defaultMessage && (
              <Button
                height={30}
                variant="outlined"
                color="danger"
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      ) : defaultMessage ? (
        <Button
          className={styles.defaultMessageButton}
          fullWidth
          variant="ghost"
          color="secondaryGreen"
          align="start"
          onClick={() => setEditing(true)}
        >
          <div style={{ padding: '10px 0', flex: 1, textAlign: 'left' }}>
            <Typography variant="caption1" color="textSecondary">
              {title}:
            </Typography>
            <Typography
              variant="body"
              color="textPrimary"
              style={{ wordBreak: 'break-all', marginTop: 6 }}
            >
              {defaultMessage?.split('\n').map((item, key) => (
                <React.Fragment key={key}>
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </div>
        </Button>
      ) : (
        <Button
          fullWidth
          height={40}
          variant="ghost"
          color="primary"
          align="start"
          startIcon={<AutoIcon />}
          onClick={() => setEditing(true)}
        >
          {actionTitle}
        </Button>
      )}
    </div>
  )
}

export default AutoReplies

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 50,
  },
  input: {
    width: '100%',
    fontSize: 13,
    marginTop: 4,
  },
  secondaryLabel: {
    display: 'block',
    margin: '10px 0 5px',
  },
  row: {
    paddingTop: 10,
  },
  autoReply: {
    padding: 16,
    background: theme.palette.op.hover.primary,
    borderRadius: 9,
    border: `1.5px solid transparent`,
  },
  editable: {
    cursor: 'pointer',

    '&:hover': {
      border: `1.5px solid ${theme.palette.op.primary[2]}`,
    },
  },
  defaultMessageButton: {
    height: 'unset',
  },
}))
