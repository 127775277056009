// @ts-strict-ignore
import { Theme } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import Confetti from 'react-confetti'
import { DialogBus, DialogBusContext } from '@openphone/desktop-client'
import useWindowSize from '../lib/use-window-size'
import { mediaStyles, useAppTheme } from '../theme'
import AlertDialog from './alert'
import AppEmojiPicker from './emoji-picker'
import ErrorBoundary from './error'
import MediaViewer from './media-viewer'
import { ModalProvider } from './modal'
import AppStore from './store'
import { Toast } from './toast'
import UpdateProvider from './update'
import WindowsTitleBar from './windows-title-bar'
import { GlobalStyles } from './global-styles'
import { AppContext } from './context'
import { Router } from './router'

declare global {
  interface Window {
    app: AppStore
    dataLayer: any[]
  }
}

interface RootProps {
  store: AppStore
}

const AppProvider: React.FC<RootProps> = function ({ store }) {
  const styles = useStyles({})
  const { width, height } = useWindowSize()
  const theme = useAppTheme(store.themeKey)
  useMetaThemeColor(theme)

  const dialogBus = useMemo(() => {
    return store.isElectron ? new DialogBus() : null
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <UpdateProvider app={store}>
        <DialogBusContext.Provider value={dialogBus}>
          <div className={styles.root}>
            {store.is('windows') && <WindowsTitleBar app={store} />}
            <div className={styles.body}>
              <GlobalStyles />
              <ErrorBoundary app={store}>
                <AppContext.Provider value={store}>
                  <MediaViewer>
                    <ModalProvider>
                      <AlertDialog />
                      <Toast />
                      <AppEmojiPicker />
                      <Router />
                    </ModalProvider>
                  </MediaViewer>
                  {store.confetti && (
                    <Confetti
                      recycle={false}
                      width={width}
                      height={height}
                      numberOfPieces={500}
                      tweenDuration={7000}
                    />
                  )}
                </AppContext.Provider>
              </ErrorBoundary>
            </div>
          </div>
        </DialogBusContext.Provider>
      </UpdateProvider>
    </ThemeProvider>
  )
}

export default observer(AppProvider)

const useStyles = makeStyles(() => ({
  root: mediaStyles({
    desktop: {
      display: 'flex',
      height: '100vh',
      flexDirection: 'column',
    },
  }),
  body: {
    flex: 1,
    minHeight: 0,
  },
}))

/**
 * Maintains a `<meta name="theme-color">` tag in the document `<head>` based
 * on the app's current theme.
 */
function useMetaThemeColor(theme: Theme) {
  useEffect(() => {
    let metaThemeColor = document.head.querySelector<HTMLMetaElement>(
      'meta[name="theme-color"]',
    )
    const color = theme.palette.op.gray[6]

    if (metaThemeColor) {
      metaThemeColor.content = color
    } else {
      metaThemeColor = document.createElement('meta')
      metaThemeColor.setAttribute('name', 'theme-color')
      metaThemeColor.content = color
      document.head.appendChild(metaThemeColor)
    }

    return () => {
      metaThemeColor.remove()
    }
  }, [theme])
}
