import { CSSProperties } from '@material-ui/styles/withStyles'

export default function mediaStyles(params: {
  phone?: CSSProperties
  desktop?: CSSProperties
  common?: CSSProperties
}): CSSProperties {
  return {
    '@media (min-width: 520px)': params.desktop,
    '@media (max-width: 520px)': params.phone,
    ...params.common,
  }
}
