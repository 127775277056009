import { action, makeAutoObservable } from 'mobx'
import { filter } from 'rxjs/operators'
import Service from '.'
import { PersistedCollection, PhoneNumber } from './model'
import { NotificationData, WebSocketMessage } from './transport/websocket'
import { PhoneNumberRepository } from './worker/repository'

export default class PhoneNumberStore {
  readonly collection = new PersistedCollection<PhoneNumber, PhoneNumberRepository>({
    table: this.root.storage.table('phoneNumber'),
    classConstructor: () => new PhoneNumber(this.root),
  })

  loaded = false

  constructor(private root: Service) {
    makeAutoObservable(this, {})
    this.subscribeToWebSocket()
  }

  fetch() {
    this.collection
      .performQuery((repo) => repo.all())
      .then(
        action((data) => {
          // TODO: See if this can be a characteristic of the PersistedCollection
          if (data.length > 0) this.loaded = true
        }),
      )

    return this.root.transport.account.workspace.phoneNumbers
      .list()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
      .then(action(() => (this.loaded = true)))
  }

  buy(phoneNumber: string, temporary: boolean): Promise<PhoneNumber> {
    return this.root.transport.account.phoneNumbers
      .buy(phoneNumber, temporary)
      .then(this.collection.load)
      .then((a) => a[0])
  }

  verify(phoneNumber: string): Promise<void> {
    return this.root.transport.account.verifyPhoneNumber(phoneNumber)
  }

  checkCode(phoneNumber: string, code: string): Promise<void> {
    return this.root.transport.account
      .checkPhoneNumberCode(phoneNumber, code)
      .then((user) => {
        this.root.user.current.deserialize(user)
      })
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((message) => {
      switch (message.type) {
        case 'phone-number-update':
          return this.collection.load(message.phoneNumber)
        case 'phone-number-delete':
          return this.collection.delete(message.phoneNumberId)
      }
    })
  }
}
