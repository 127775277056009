// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import { compareString } from '../../../lib'
import { fuzzySearch } from '../../../lib/search'
import useInputState from '../../../lib/use-input-state'
import { Member } from '../../../service/model'
import { Header, Page } from '../common'
import { DomainForm } from './domain'

import MemberRow from './row'

interface MembersProps {}

const Members: React.FC<MembersProps> = function ({}) {
  const styles = useStyles({})
  const { command, showAlert, history, service, toast } = useAppStore()
  const [search, setSearch] = useInputState('')
  const subscription = service.billing.subscription
  const user = service.user.current.asMember
  const members = service.member.orgMember.list

  const filteredMembers: Member[] = useMemo(() => {
    if (!search || !members) return members
    return fuzzySearch(members, search, ['name', 'email'])
  }, [search, members])

  const sortedMembers =
    filteredMembers?.sort((a, b) => compareString(a.name, b.name)) ?? []

  const handleInvite = () => {
    if (subscription.store !== 'stripe') {
      return showAlert({
        title: 'Switch to credit card',
        body: `To add members to your organization, we need a credit card on your account. Right now your subscription is managed by ${
          subscription.store === 'android' ? 'Play Store' : 'Apple Store'
        }.`,
        actions: [
          {
            title: 'Add a credit card',
            type: 'primary',
            onClick: () => {
              history.push('/settings/billing')
            },
          },
          {
            title: 'Cancel',
          },
        ],
      })
    }
    command.present({ name: 'invite member' })
  }

  useEffect(() => {
    service.member.fetchAdmin().catch(toast.showError)
  }, [])

  return (
    <Page>
      <DomainForm />
      <Header
        title="Members"
        subtitle="Manage all the members in your organization"
        actions={
          user?.isAdmin && (
            <Button variant="contained" color="primary" onClick={handleInvite}>
              Add a new member
            </Button>
          )
        }
      />
      <TextInput
        fullWidth
        size={35}
        value={search}
        onChange={setSearch}
        placeholder="Search by name or email"
        style={{ marginTop: 16 }}
      />

      <div style={{ margin: '16px 0' }}>
        {sortedMembers.length === 0 && search ? (
          <Typography
            style={{ padding: '1rem 0' }}
            variant="footnote"
            color="textSecondary"
          >
            No one found matching "{search}"
          </Typography>
        ) : (
          sortedMembers.map((member) => <MemberRow key={member.id} member={member} />)
        )}
      </div>
    </Page>
  )
}

export default observer(Members)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  searchBar: {
    padding: '2rem 2rem 20px',
  },
  header: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2rem 2rem 1rem calc(2rem + 40px + 1rem)',
    textTransform: 'uppercase',
  },
}))
