// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { useAppStore } from '@src/app/context'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import useTitle from '../../../lib/use-title'
import { colors } from '../../../theme'
import { InboxState } from './store'

export interface InboxesProps {
  compressed: boolean
}

const Inboxes: React.FC<InboxesProps> = function ({ compressed }) {
  const styles = useStyles({ compressed })
  const app = useAppStore()
  const inbox = app.inboxes.selected
  const states: InboxState[] = ['open', 'done']

  useKeyboardShortcuts({
    name: 'inbox/phone-number/inboxes',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Backquote') {
        switchInbox(1, event)
      } else if (shortcut === 'Shift+Backquote') {
        switchInbox(-1, event)
      }
    },
    dep: [],
  })

  useTitle(inbox.phoneNumber.formattedName)

  const switchInbox = (direction: -1 | 1, event: Event) => {
    event.preventDefault()
    const currentIndex = states.indexOf(inbox as any)
    inbox.setState(states[(currentIndex + direction) % states.length])
  }

  return (
    <div className={styles.root}>
      <InboxButton
        id="inbox-main"
        label="Open"
        compressed={compressed}
        badge={inbox.unread}
        isSelected={inbox.state === 'open'}
        onClick={() => inbox.setState('open')}
      />
      <InboxButton
        id="inbox-done"
        label="Done"
        compressed={compressed}
        isSelected={inbox.state === 'done'}
        onClick={() => inbox.setState('done')}
      />
      <FilterButton
        compressed={compressed}
        active={inbox.filters.includes('is:unread')}
        onClick={inbox.toggleUnreadFilter}
      />
    </div>
  )
}

export interface InboxButton
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: React.ReactNode
  badge?: number
  isSelected?: boolean
  compressed?: boolean
  onClick?: () => void
}

export const InboxButton: React.FC<InboxButton> = function ({
  label,
  badge,
  isSelected,
  compressed,
  onClick,
  className,
  ...props
}) {
  const styles = useStyles({ compressed })
  return (
    <Tooltip placement="bottom" title={`Filter by ${label}`} disableHoverListener>
      <button
        {...props}
        tabIndex={-1}
        className={cx(styles.button, isSelected && styles.selected, className)}
        onClick={onClick}
      >
        <Typography variant="inherit" nowrap>
          {label}
          {badge > 0 ? <span className={styles.badge}>{badge}</span> : null}
        </Typography>
      </button>
    </Tooltip>
  )
}

interface FilterButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  active: boolean
  compressed: boolean
}

export const FilterButton: React.FC<FilterButtonProps> = function ({
  active,
  compressed,
  ...props
}) {
  const styles = useStyles({ compressed })
  return (
    <>
      <Tooltip placement="bottom" title="Filter conversations" disableHoverListener>
        <button
          tabIndex={-1}
          {...props}
          className={cx(styles.filter, active && styles.active)}
        >
          <Typography variant="inherit" nowrap>
            Unread
          </Typography>
        </button>
      </Tooltip>
    </>
  )
}

export default observer(Inboxes)

const useStyles = makeStyles<Theme, Partial<InboxesProps>>((theme: Theme) => ({
  root: {
    display: 'flex',
    outline: 'none',
  },
  button: ({ compressed }) => ({
    borderRadius: 5,
    background: 'none',
    outline: 'none',
    fontSize: compressed ? 12 : 14,
    fontWeight: 450,
    color: theme.palette.text.secondary,
    border: '1.5px solid transparent',
    padding: compressed ? '0 7px' : '0 10px',
    position: 'relative',
    cursor: 'pointer',
    marginRight: 5,
    height: 30,

    '&::before, &::after': {
      content: '""',
      background: theme.palette.op.hover.primary,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: 5,
      opacity: 0,
    },

    '&:hover, &:focus, &$selected': {
      color: theme.palette.op.match({
        dark: colors.white,
        light: colors.spaceGray900,
      }),

      '&::after': {
        opacity: 1,
      },
    },
  }),
  badge: {
    fontSize: '0.8rem',
    marginLeft: 7,
    fontWeight: 'bold',
  },
  selected: () => ({}),
  filter: ({ compressed }) => ({
    outline: 'none',
    cursor: 'pointer',
    background: 'none',
    border: `1.5px dashed ${theme.palette.op.gray[3]}`,
    color: theme.palette.op.gray[2],
    fontSize: compressed ? 12 : 14,
    borderRadius: 5,
    padding: compressed ? '0 6px' : '0 8px',
    transition: 'all 0.3s ease',
    marginLeft: 5,
    height: 30,

    '&:hover, &:focus': {
      borderColor: theme.palette.op.background.highlight(0.4),
      color: theme.palette.op.background.highlight(0.8),
      background: theme.palette.op.hover.primary,
    },
  }),
  active: () => ({
    border: `1.5px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,

    '&:hover, &:focus': {
      borderColor: theme.palette.primary.light,
      background: alpha(theme.palette.primary.light, 0.1),
      color: theme.palette.primary.light,
    },
  }),
}))
