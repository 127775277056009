import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { ForwardRefRenderFunction } from 'react'
import Typography from '@ui/Typography'
import { SelectItem } from '../../common-v2'
import CompanyFilterController, { CompanyView } from './controller'

export interface CompanyItemProps {
  controller: CompanyFilterController
  company: CompanyView
  index: number
}

const CompanyItem: ForwardRefRenderFunction<HTMLDivElement, CompanyItemProps> = (
  { controller, company, index },
  ref,
) => {
  const styles = useStyles({})

  const handleItemToggle = () => controller.handleItemToggle(company)

  return (
    <SelectItem
      selected={false}
      onChecked={handleItemToggle}
      checked={company.checked}
      onClick={handleItemToggle}
      ref={ref}
    >
      <div className={styles.root}>
        <Typography nowrap variant="footnote">
          {company.name}
        </Typography>
        <Typography
          nowrap
          variant="caption1"
          color="textSecondary"
          fontWeight="regular"
          className={styles.count}
        >
          {company.count}
        </Typography>
      </div>
    </SelectItem>
  )
}

export default observer(CompanyItem, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    count: {
      marginLeft: 20,
      flex: '0 0 auto',
    },
  }),
  { name: CompanyItem.name },
)
