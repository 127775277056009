// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { PhoneNumber } from '../../types'
import { Command } from './common-v2'
import Header from './common/header'
import OpPhoneNumberSelector from './op-phone-number-selector'

export interface PickOrgPhoneNumberProps {
  title: string
  subtitle?: string
  multi?: boolean
  excludeIds?: string[]
  initialSelectedIds?: string[]
  onSelect: (phoneNumbers: PhoneNumber[]) => void
  onAdd: () => void
}

const PickOrgPhoneNumber: React.FC<PickOrgPhoneNumberProps> = function ({
  title,
  subtitle,
  multi,
  initialSelectedIds,
  excludeIds,
  onSelect,
  onAdd,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const phoneNumbers = service.organization.phoneNumber.collection.list

  return (
    <Command>
      <Header title={title} subtitle={subtitle} />
      <OpPhoneNumberSelector
        multi={multi}
        phoneNumbers={phoneNumbers.filter((p) => !excludeIds?.includes(p.id))}
        initialSelectedIds={initialSelectedIds}
        onAdd={onAdd}
        onSelect={onSelect}
      />
    </Command>
  )
}

export default observer(PickOrgPhoneNumber)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
