import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { uniqueAndNonNull } from '../../../lib'
import { Contact, isMember, isParticipant } from '../../../service/model'
import ContactDetails, { ContactType } from '../../contact'

const ParticipantDetails = () => {
  const { service, inboxes } = useAppStore()

  const identity = inboxes.selected?.details
  const defaultContact: ContactType = isMember(identity)
    ? identity
    : identity.contact || identity.integrationContacts[0]

  const [selectedContact, setContact] = useState<ContactType>(defaultContact)

  const duplicates: ContactType[] = isMember(identity)
    ? []
    : uniqueAndNonNull([
        identity.member,
        ...identity.contacts,
        ...identity.integrationContacts,
      ])

  useEffect(() => {
    if (!isParticipant(identity)) return

    const cards: ContactType[] = uniqueAndNonNull([
      identity.member,
      ...identity.contacts,
      ...identity.integrationContacts,
    ])

    if (cards.includes(selectedContact)) return

    let newContact: Contact

    if (cards[0]) {
      setContact(cards[0])
    } else {
      newContact = new Contact(service, { local: true })
      newContact.addItem({ type: 'phone-number', value: identity.phoneNumber })
      const groupId = inboxes.selected?.phoneNumber?.groupId
      if (groupId) {
        newContact.sharedWith = [groupId]
      }
      setContact(newContact)
    }

    return () => {
      if (newContact?.local) {
        newContact.delete()
      }
    }
  }, [identity['contacts'], identity['integrationContacts']])

  if (selectedContact) {
    return (
      <ContactDetails
        contact={selectedContact}
        matchingContacts={duplicates}
        onSelect={setContact}
      />
    )
  }

  return null
}

export default observer(ParticipantDetails)
