import { makeStyles } from '@material-ui/core'
import React from 'react'

const Banners: React.FC<{}> = function ({ children }) {
  const styles = useStyles({})
  return <div className={styles.root}>{children}</div>
}

export default Banners

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 30,
    overflow: 'hidden',
  },
}))
