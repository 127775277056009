// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { formatted } from '../../../lib/phone-number'
import { Participant } from '../../../service/model'
import { AppAvatar } from '@src/app/components'
import { AppAvatarGroup } from '@src/app/components'

const Recipients = function () {
  const styles = useStyles({})
  const { inboxes, conversation: conversationStore } = useAppStore()
  const conversation = conversationStore.current

  const handleSelect = (p: Participant) => {
    inboxes.selected.setDetails(p)
  }

  if (!conversation) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AppAvatarGroup identities={conversation.participants} size={80} />
        <Typography color="textPrimary" variant="title2" className={styles.name}>
          {conversation.participants.length}{' '}
          {conversation.participants.length === 1 ? 'recipient' : 'recipients'}
        </Typography>
      </div>
      <div className={styles.list}>
        <Typography
          variant="caption1"
          color="textSecondary"
          fontWeight="regular"
          style={{ margin: '0 16px 8px' }}
        >
          Recipients
        </Typography>
        {conversation.participants.map((p) => (
          <div
            key={p.phoneNumber}
            className={styles.participant}
            onClick={() => handleSelect(p)}
          >
            <AppAvatar identity={p} size={34} />
            <div className={styles.body}>
              <Typography variant="footnote" fontWeight="medium">
                {p.name}
              </Typography>
              {p.id && (
                <Typography
                  variant="caption2"
                  color="textSecondary"
                  style={{ marginTop: 2 }}
                >
                  {formatted(p.phoneNumber)}
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(Recipients)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 24px',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  name: {
    alignSelf: 'stretch',
    height: 30,
    marginTop: 14,
    textAlign: 'center',
  },
  list: {
    padding: '20px 8px',
  },
  participant: {
    cursor: 'pointer',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    borderRadius: 6,
    marginBottom: 2,

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  body: {
    marginLeft: 12,
  },
}))
