// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import React, { useCallback, useRef } from 'react'
import Controller, { Item as ControllerItem, ItemPart } from '../controller'
import AudioMedia from './audio'
import Call from './call'
import Comments from './comments'
import ContactMedia from './contact'
import FileMedia from './file'
import Header from './header'
import Images from './images'
import LoadMore from './load-more'
import Message from './message'
import Reactions from './reactions'
import Status from './status'
import Video from './video'
import Row from './row'

export * from './audio'
export * from './bubble'
export * from './call'
export * from './contact'
export * from './file'
export * from './header'
export * from './images'
export * from './message'
export * from './video'

export interface ItemProps<I extends ControllerItem> {
  item: I
  controller: Controller<I>
  renderComments?(item: I): React.ReactNode
  renderMention?(mention: string, item: I): React.ReactNode
}

export type ItemPartProps<I extends ControllerItem, P extends ItemPart> = ItemProps<I> & P

const Item = function <I extends ControllerItem>({
  controller,
  item,
  renderComments,
  renderMention,
}: ItemProps<I>) {
  if (item.source.type === 'loading') {
    return <LoadMore controller={controller} item={item} />
  }

  const styles = useStyles({ item })
  const shouldShowComments =
    controller.features.threads && item.hasThread && renderComments

  const renderPart = useCallback(
    ({ key, ...part }: ItemPart) => {
      const props = { controller, item, renderComments, renderMention }

      switch (part.type) {
        case 'text':
          return <Message key={key} {...props} {...part} />
        case 'call':
          return <Call key={key} {...props} {...part} />
        case 'images':
          return <Images key={key} {...props} {...part} />
        case 'videos':
          return <Video key={key} {...props} {...part} />
        case 'file':
          return <FileMedia key={key} {...props} {...part} />
        case 'audio':
          return <AudioMedia key={key} {...props} {...part} />
        case 'contact':
          return <ContactMedia key={key} {...props} {...part} />
      }
    },
    [controller, item, renderComments, renderMention],
  )

  return (
    <>
      {item.display.showTimestampHeader && <Header timestamp={item.source.createdAt} />}
      {item.parts.map(renderPart)}
      <div className={styles.partFooter}>
        {shouldShowComments && (
          <Row item={item} controller={controller} className={styles.arrowRow}>
            <div
              className={cx(styles.arrow, item.isOutgoing ? styles.arrowOutgoing : null)}
            />
          </Row>
        )}
        {item.hasReactions ? <Reactions controller={controller} item={item} /> : null}
        {item.display.showStatus ? <Status controller={controller} item={item} /> : null}
      </div>
      {shouldShowComments ? (
        <Comments controller={controller} item={item} renderComments={renderComments} />
      ) : null}
    </>
  )
}

export default observer(Item)

const useStyles = makeStyles<Theme, Partial<ItemProps<ControllerItem>>>((theme) => ({
  partFooter: {
    position: 'relative',
  },
  arrowRow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  arrow: ({ item }) => ({
    position: 'absolute',
    top: 0,
    bottom: -20,
    left: item.display.dimensions.threadMarginHorizontal - 22,
    width: item.display.dimensions.threadArrowWidth,
    borderWidth: 0,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.op.gray[4],
    borderBottomLeftRadius: 8,
    opacity: 0.4,
  }),
  arrowOutgoing: ({ item }) => ({
    right: item.display.dimensions.threadMarginHorizontal - 22,
    left: 'unset',
    borderLeftWidth: 0,
    borderRightWidth: 2,
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 8,
  }),
}))
