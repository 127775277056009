import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { MessageMedia } from '../../service/model'

interface PdfViewerProps {
  media: MessageMedia
}

const PdfViewer: React.FC<PdfViewerProps> = function ({ media }) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file) {
    return null
  }

  return (
    <div ref={rootRef} className={styles.root}>
      <iframe frameBorder={0} className={styles.iframe} src={media.url} />
    </div>
  )
}

export default observer(PdfViewer)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: 'calc(100% - 175px)',
    height: '100%',
  },
}))
