import { makeStyles, Theme, Radio as MuiRadio, RadioProps } from '@material-ui/core'
import React from 'react'
import cx from 'classnames'

const RadioButton = (props: RadioProps) => {
  const classes = useStyles()

  return (
    <MuiRadio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow: `inset 0 0 0 1px ${theme.palette.op.gray[4]}, inset 0 -1px 0 ${theme.palette.op.gray[4]}`,
    backgroundColor: theme.palette.op.white,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: `2px auto ${theme.palette.op.primary[1]}`,
      outlineOffset: 2,
    },
    'input:hover ~ &': {},
    'input:disabled ~ &': {
      opacity: '0.45',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.op.primary[2],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'none',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: `radial-gradient(${theme.palette.op.white},${theme.palette.op.white} 20%,transparent 32%)`,
      content: '""',
    },
    'input:hover ~ &': {},
  },
}))

export default RadioButton
