export type FeatureValue =
  | string
  | number
  | boolean
  | { type: 'link'; url: string; label: string }
  | { type: 'with-tooltip'; tooltip: string; value: FeatureValue }

export interface Feature {
  title: string
  tooltip?: string
  values: {
    standard: FeatureValue
    premium: FeatureValue
    enterprise: FeatureValue
  }
  isBeta?: boolean
  isAddon?: boolean
  isComingSoon?: boolean
}

export interface FeatureGroup {
  title: string
  features: Feature[]
}

const data: FeatureGroup[] = [
  {
    title: 'Core Features',
    features: [
      {
        title: 'Calling & messaging (US & Canada)',
        tooltip:
          'As long as you have an internet connection, you can call and text US and Canadian numbers from any country without incurring additional charges (subject to our fair use policy).',
        values: {
          standard: {
            type: 'with-tooltip',
            tooltip: 'Unlimited subject to Fair Usage Policy.',
            value: 'Unlimited*',
          },
          premium: {
            type: 'with-tooltip',
            tooltip: 'Unlimited subject to Fair Usage Policy.',
            value: 'Unlimited*',
          },
          enterprise: {
            type: 'with-tooltip',
            tooltip: 'Unlimited subject to Fair Usage Policy.',
            value: 'Unlimited*',
          },
        },
      },
      {
        title: 'Calling & messaging (International)',
        tooltip:
          'Call and text virtually any country in the world. US & Canadian numbers are included, but for other countries, per-minute and per-message rates apply.',
        isAddon: true,
        values: {
          standard: {
            type: 'link',
            url: 'https://www.openphone.co/rates',
            label: 'See rates',
          },
          premium: {
            type: 'link',
            url: 'https://www.openphone.co/rates',
            label: 'See rates',
          },
          enterprise: {
            type: 'link',
            url: 'https://www.openphone.co/rates',
            label: 'See rates',
          },
        },
      },
      {
        title: 'Phone numbers included (per user)',
        tooltip:
          'Every user added to your OpenPhone workspace gets one local or toll-free phone number to themselves, but you can remove or reassign them as needed.',
        values: {
          standard: 1,
          premium: 1,
          enterprise: 1,
        },
      },
      {
        title: 'Additional phone numbers',
        tooltip:
          'Every user gets one new local or toll-free phone number, but you can always add more. Create numbers for locations, teams, or anything else—each with           their own inbox and settings.',
        values: {
          standard: '$5/month each',
          premium: '$5/month each',
          enterprise: '$5/month each',
        },
        isAddon: true,
      },
      {
        title: 'Port phone numbers from other carriers',
        tooltip:
          "Our team will help you transfer your existing phone number into OpenPhone for free. Don't worry—you shouldn't experience any service disruptions during the           porting process.",
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
    ],
  },
  {
    title: 'Productivity & collaboration',
    features: [
      {
        title: 'Snippets',
        tooltip:
          'Save frequently sent messages as snippets to send complete replies with a single click.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Internal threads',
        tooltip:
          'Chat with your teammates behind the scenes, right in the context of the conversation.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Shared contacts',
        tooltip:
          'Contacts added to a shared phone number are visible to everyone sharing that number. You can also share contacts to teammates individually',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Contact notes',
        tooltip:
          'Jot down important details about any contact that you and your team should remember.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Business hours',
        tooltip:
          'Automatically mute notifications from specific phone numbers after business hours to avoid disruptions after work.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Voicemail to text',
        tooltip:
          "Every voicemail is automatically transcribed to text so they're easier to review",
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Call recording',
        tooltip:
          'Record any call, or enable auto-recording on any phone number for a permanent record of every call.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Auto-replies',
        tooltip:
          'Automatically respond to texts, missed calls, or voicemails with pre-defined messages.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'IVR (auto-attendant)',
        tooltip:
          'Greet callers with a message and let them select which team or person to connect with.',
        values: {
          standard: 'Basic',
          premium: 'Advanced',
          enterprise: 'Advanced',
        },
      },
      {
        title: 'Shared phone numbers',
        tooltip:
          'Share call and text histories with others in your workspace, see when your team is talking or typing, and even ring multiple teammates during incoming calls.',
        values: {
          standard: 'Max 10 users',
          premium: 'Unlimited',
          enterprise: 'Unlimited',
        },
      },
      {
        title: 'User groups',
        tooltip: 'Organize teammates into distinct groups you can forward calls to.',
        values: {
          standard: 'Unlimited',
          premium: 'Unlimited',
          enterprise: 'Unlimited',
        },
      },
      {
        title: 'Group messaging',
        tooltip:
          'Bring multiple people into a single message thread to keep everyone in the know.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Caller ID',
        tooltip:
          'Let customers know you mean business with a customized caller ID for every number.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Group calling',
        tooltip: 'Include multiple active participants on any call.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
        isComingSoon: true,
      },
      {
        title: 'Call monitoring',
        tooltip:
          'Listen-in on calls happening through shared phone numbers without outside participants knowing.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
        isComingSoon: true,
      },
      {
        title: 'Call whispering',
        tooltip:
          'Speak to one team member in a group call while remaining silent to everyone else.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
        isComingSoon: true,
      },
      {
        title: 'Call transfers',
        tooltip:
          'Redirect callers to any team, group, or person in your workspace, or externally.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Round-robin',
        tooltip:
          'Ring teammates on shared phone numbers in batches, instead of all at once.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
    ],
  },
  {
    title: 'Integrations',
    features: [
      {
        title: 'Email',
        tooltip:
          'Get notified by email when your numbers receive a call, message, or voicemail',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Slack',
        tooltip:
          'Instantly share calls, texts, and voicemails with any Slack user or channel.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Zapier',
        tooltip:
          'Trigger actions in thousands of other tools when specific events happen in OpenPhone.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'SMS via Zapier',
        tooltip:
          'Send an SMS through OpenPhone based on actions that take place in other apps.',
        values: {
          standard: '1c per message',
          premium: '1c per message',
          enterprise: '1c per message',
        },
        isAddon: true,
      },
      {
        title: 'Google Contacts',
        tooltip:
          'Keep your contact list neat, organized, and synced with all your devices.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'HubSpot CRM',
        tooltip:
          'Sync your phone activity with Hubspot to spend less time typing and more time closing.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Webhooks',
        tooltip: 'Listen for changes in OpenPhone and send alerts with an HTTP request.',
        isBeta: true,
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Pipedrive',
        tooltip:
          'Enrich your leads and close more deals with call recording, text logs, voicemail, and more.',
        isComingSoon: true,
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Salesforce',
        tooltip:
          'Create a single source of truth for every lead by connecting your phone to Salesforce.',
        isComingSoon: true,
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Gong',
        tooltip:
          'Bring your OpenPhone calls into Gong to track sales call performance and analytics.',
        isComingSoon: true,
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
    ],
  },
  {
    title: 'Admin and security',
    features: [
      {
        title: 'User roles and management',
        tooltip:
          'Full, granular control over all users, phone numbers, and billing in your workspace.',
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Supervisor roles',
        isComingSoon: true,
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Analytics and reporting',
        tooltip:
          'Get insight and visibility into how every phone number in your account is being used.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'SAML SSO',
        isComingSoon: true,
        values: {
          standard: false,
          premium: false,
          enterprise: true,
        },
      },
      {
        title: 'Audit log',
        tooltip:
          'Review a log of all account-related activities for complete transparency and oversight.',
        values: {
          standard: false,
          premium: false,
          enterprise: true,
        },
      },
    ],
  },
  {
    title: 'Support',
    features: [
      {
        title: 'Email and SMS support',
        tooltip:
          "We're standing by to answer your questions (wherever you feel like asking them).",
        values: {
          standard: true,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Priority support',
        tooltip:
          'Support requests are prioritized to ensure you get the help you need fast.',
        values: {
          standard: false,
          premium: true,
          enterprise: true,
        },
      },
      {
        title: 'Dedicated account manager',
        tooltip:
          'Get a dedicated point-of-contact within OpenPhone you can turn to for help or advice.',
        values: {
          standard: false,
          premium: false,
          enterprise: true,
        },
      },
    ],
  },
]

export default data
