// @ts-strict-ignore
import React from 'react'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import { Feature, FeatureValue } from '@src/data/plans'
import VisuallyHidden from '@src/component/visually-hidden'
import Tooltip from '@src/component/tooltip'
import CheckIcon from '@material-ui/icons/Check'
import Badge from '@ui/Badge'

export interface FeatureGroupTitleProps {
  variant?: 'group' | 'plan-title'
  children?: React.ReactNode
}

export function FeatureGroupTitle({
  children,
  variant = 'group',
}: FeatureGroupTitleProps) {
  const styles = useFeatureGroupTitleStyles({ variant })

  return <div className={styles.title}>{children}</div>
}

const useFeatureGroupTitleStyles = makeStyles<
  Theme,
  Pick<FeatureGroupTitleProps, 'variant'>
>((theme) => ({
  title: ({ variant }) => ({
    fontWeight: 550,
    fontSize: 13,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: variant === 'group' ? 'flex-start' : 'center',
    marginTop: 32,
    color: variant === 'group' ? theme.palette.op.gray[1] : theme.palette.op.gray[2],
    boxShadow: `0 1.5px 0 0 ${alpha(theme.palette.op.gray[1], 0.06)}`,
  }),
}))

export interface FeatureTitleProps extends Omit<Feature, 'values' | 'title'> {
  children?: React.ReactNode
}

export function FeatureTitle(props: FeatureTitleProps) {
  const styles = useFeatureTitleStyles(props)
  const { children, isAddon, isBeta, isComingSoon, tooltip } = props

  return (
    <div className={styles.featureTitle}>
      <Tooltip title={tooltip}>
        <span className={styles.text}>{children}</span>
      </Tooltip>

      {isAddon && <Badge label="Add-on" variant="gray" />}
      {isBeta && <Badge label="Beta" variant="primary" />}
      {isComingSoon && <Badge label="Coming soon" variant="warning" />}
    </div>
  )
}

const useFeatureTitleStyles = makeStyles<Theme, FeatureTitleProps>((theme) => ({
  featureTitle: {
    fontWeight: 450,
    fontSize: 13,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.op.gray[2],
    boxShadow: `0 1.5px 0 0 ${alpha(theme.palette.op.gray[1], 0.06)}`,
  },
  text: {
    marginRight: 7,
  },
}))

export interface FeatureValueItemProps {
  value: FeatureValue
}

export function FeatureValueItem({ value }: FeatureValueItemProps) {
  const styles = useFeatureValueItemStyles()

  function getChildrenFromValue(value: FeatureValue): React.ReactElement {
    switch (typeof value) {
      case 'number':
      case 'string':
        return <span>{value}</span>

      case 'boolean':
        return value ? (
          <CheckIcon className={styles.check} aria-label="Available" role="img" />
        ) : (
          <VisuallyHidden>Not available</VisuallyHidden>
        )

      case 'object': {
        switch (value.type) {
          case 'with-tooltip':
            return (
              <Tooltip title={value.tooltip}>{getChildrenFromValue(value.value)}</Tooltip>
            )
          case 'link':
            return (
              <a
                className={styles.externalLink}
                href={value.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value.label}
              </a>
            )
        }
      }
    }
  }

  return <div className={styles.item}>{getChildrenFromValue(value)}</div>
}

const useFeatureValueItemStyles = makeStyles((theme) => ({
  item: {
    fontWeight: 450,
    fontSize: 13,
    height: 40,
    color: theme.palette.op.gray[1],
    boxShadow: `0 1.5px 0 0 ${alpha(theme.palette.op.gray[1], 0.06)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  check: {
    transform: 'scale(0.8)',
    color: theme.palette.primary.light,
  },
  externalLink: {
    color: theme.palette.op.primary[2],
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },

    '&::after': {
      content: '"↗"',
    },
  },
}))
