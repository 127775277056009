import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { Command, Footer } from '../common-v2'
import IdentityResult from './identity'
import SearchInput from './input'
import SearchResults from './results'
import Suggestions from './suggestions'

export interface SearchCommandProps {}

const SearchCommand: React.FC<SearchCommandProps> = function ({}) {
  const styles = useStyles({})
  const { search } = useAppStore()

  const renderBody = () => {
    if (search.identity) {
      return <IdentityResult />
    }
    return (
      <>
        <SearchInput />
        {search.results ? (
          <SearchResults />
        ) : (
          search.suggestions.length > 0 && <Suggestions />
        )}
        {!search.results && search.suggestions.length === 0 && (
          <Footer size="small">
            <Typography variant="caption1" color="textTertiary" fontWeight="regular">
              💡 Tip: Try typing <b>“in:”</b> or <b>“from:”</b> to focus your search.
            </Typography>
          </Footer>
        )}
      </>
    )
  }

  return (
    <Command width={672} height="large">
      {renderBody()}
    </Command>
  )
}

export default observer(SearchCommand)

const useStyles = makeStyles((theme: Theme) => ({}))
