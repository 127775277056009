import Debug from 'debug'
import { action, computed, makeObservable, observable } from 'mobx'
import React from 'react'
import KeyboardListController from '../../../../component/keyboard-list/controller'
import { ImmutableCollection } from '../../../../service/model'
import { fuzzySearch } from '../../../../lib/search'
import shortId from '../../../../lib/short-id'
import Stepper from '../../../../lib/stepper'

export interface CompanyView {
  readonly id: string
  readonly name: string
  readonly count: number
  readonly checked: boolean
}

export default abstract class CompanyFilterController extends KeyboardListController<CompanyView> {
  abstract companies: CompanyView[]

  readonly rowHeight = 44
  readonly topPadding = 10
  readonly bottomPadding = 10
  readonly itemHeight = this.rowHeight + this.topPadding + this.bottomPadding
  readonly itemHoverMode = 'select'
  override readonly itemSpacing = { bottom: 2 }

  readonly id = shortId()
  readonly debug = Debug(`op:company-filter-controller:@${this.id}`)
  readonly stepper = new Stepper(this, {
    name: `company-filter-${this.id}`,
  })

  search = ''

  constructor() {
    super()

    makeObservable(this, {
      search: observable.ref,
      data: computed,
      handleEnter: action.bound,
      handleSearchInputChange: action.bound,
    })
  }

  get data() {
    const companies = this.search
      ? fuzzySearch(this.companies, this.search, ['name'])
      : this.companies

    return new ImmutableCollection<CompanyView>(companies)
  }

  abstract handleItemToggle(item: CompanyView): void

  override handleEnter(item: CompanyView) {
    this.handleItemToggle(item)
  }

  handleSearchInputChange(event: React.FormEvent<HTMLInputElement>) {
    this.search = event.currentTarget.value
  }
}
