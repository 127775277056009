import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { observer } from 'mobx-react-lite'

interface TagProps {
  name: string
  color: string
  onDelete?: () => void
}

const Tag: React.FC<TagProps> = function ({ name, color, onDelete }) {
  const styles = useStyles({})
  return (
    <div className={styles.root} style={{}}>
      <i className={styles.icon} style={{ backgroundColor: color }} />
      <Typography variant="caption" className={styles.label}>
        {name}
      </Typography>
      {onDelete && (
        <ClearIcon fontSize="small" className={styles.delete} onClick={onDelete} />
      )}
    </div>
  )
}

export default observer(Tag)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: 15,
    marginRight: 3,
    marginTop: 1,
    marginBottom: 2,
    background: theme.palette.op.background.highlight(0.08),
  },
  icon: {
    width: 8,
    height: 8,
    marginRight: 6,
    borderRadius: 4,
  },
  label: {
    color: theme.palette.text.primary,
    lineHeight: 1,
    fontWeight: 600,
  },
  delete: {
    cursor: 'pointer',
    fontSize: '0.8rem',
    color: theme.palette.text.primary,
    marginLeft: 6,
  },
}))
