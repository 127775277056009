import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ForwardIcon from '@material-ui/icons/Forward'
import Header from './common/header'
import PhoneNumberPicker from './phone-number-selector'
import { Command } from './common-v2'

export interface ForwardCallsProps {
  defaultValue?: string
  onSelect: (number: string) => void
}

const ForwardCalls: React.FC<ForwardCallsProps> = function ({ defaultValue, onSelect }) {
  const styles = useStyles({})
  return (
    <Command>
      <Header
        title="Forward calls to"
        subtitle="All incoming calls will be forwarded to this number. Please note that when enabled, business hours, auto-replies, and all other OpenPhone settings will be disabled for this inbox."
      />
      <PhoneNumberPicker
        actionTitle="Forward"
        actionIcon={<ForwardIcon />}
        defaultValue={defaultValue}
        onSelect={onSelect}
      />
    </Command>
  )
}

export default ForwardCalls

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
