import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { InfoIcon } from '../../component/icons/custom'

interface InfoButtonProps {
  text: string
}

const InfoButton: React.FC<InfoButtonProps> = function ({ text }) {
  const styles = useStyles({})

  if (!text) return null

  return (
    <Tooltip title={text} placement="bottom-start">
      <div style={{ display: 'inline-block' }}>
        <InfoIcon className={styles.icon} />
      </div>
    </Tooltip>
  )
}

export default InfoButton

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: 10,
    opacity: 0.5,
    cursor: 'pointer',
    transition: theme.transitions.create(['opacity'], {
      duration: 0.2,
      easing: 'ease',
    }),

    '&:hover': {
      opacity: 1,
    },
  },
}))
