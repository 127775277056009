// @ts-strict-ignore
import { action, computed, makeObservable, reaction, observable } from 'mobx'
import React, { createRef } from 'react'
import { VirtualListStore, VirtualListItem } from '../virtual-list/store'
import Stepper from '../../lib/stepper'
import { Collection, ImmutableCollection } from '../../service/model'

export interface ItemSpacing {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export interface ListPadding {
  top: number
  bottom: number
}

export type HoverMode = 'select' | 'independent'

export default abstract class KeyboardListController<T extends VirtualListItem> {
  abstract data: Collection<T> | ImmutableCollection<T>
  abstract stepper: Stepper<T>
  abstract itemHeight: ((item: T, index: number) => number) | number
  abstract itemHoverMode: HoverMode
  pinToIndex?: number

  readonly highlighterRef: React.MutableRefObject<HTMLDivElement> = createRef()
  readonly virtualListRef: React.MutableRefObject<VirtualListStore<T>> = createRef()

  highlighterAnimated = false
  itemSpacing: ItemSpacing = {}
  listPadding: ListPadding = { top: 0, bottom: 0 }

  constructor() {
    makeObservable(this, {
      itemSpacing: observable,
      listPadding: observable,
      index: computed,
      handleMouseMove: action.bound,
      handleItemMouseEnter: action.bound,
      updateHighlighter: action.bound,
    })

    reaction(
      () => [this.virtualListRef.current?.views, this.index] as const,
      (_, prev) => {
        const [, prevIndex] = prev ?? []
        const animate =
          this.highlighterAnimated &&
          this.index >= 0 &&
          prevIndex >= 0 &&
          typeof this.index === 'number' &&
          typeof prevIndex === 'number'

        this.updateHighlighter(animate)
      },
      {
        name: 'KeyboardListIndexChange',
        fireImmediately: true,
      },
    )
  }

  get index(): number | null {
    return this.stepper?.index ?? -1
  }

  handleEnter?(item: T, index: number): void

  handleBottomReached(): void {
    // do nothing
  }

  handleMouseMove() {
    this.highlighterAnimated = false
  }

  handleItemMouseEnter(item: T, index: number) {
    this.stepper.selectIndex(index, { skipBehavior: 'stay' })
  }

  updateHighlighter(animate: boolean) {
    const el = this.highlighterRef.current
    if (!el) return

    if (animate) {
      el.style.transition = 'transform 256ms cubic-bezier(0.19, 1, 0.22, 1)'
    } else {
      el.style.transition = 'none'
    }

    const views = this.virtualListRef.current?.views ?? {}
    const view = views[this.data.list[this.index]?.id]
    const itemTop = this.itemSpacing?.top ?? 0
    const itemBottom = this.itemSpacing?.top ?? 0
    const height = itemTop + (view?.height ?? 0) + itemBottom
    const left = this.itemSpacing?.left ?? 10
    const right = this.itemSpacing?.right ?? 10

    let offset = 0
    let opacity = 0

    if (this.index >= 0) {
      offset = view?.offset ?? 0
      opacity = 1
    }

    el.style.height = `${height}px`
    el.style.left = `${left}px`
    el.style.right = `${right}px`
    el.style.transform = `translateY(${offset}px)`
    el.style.opacity = `${opacity}`

    if (this.highlighterAnimated) {
      this.virtualListRef.current?.scrollIntoView(this.index)
    }

    this.highlighterAnimated = true
  }
}
