// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIcon,
  AnimalsIcon,
  FlagsIcon,
  FoodIcon,
  FrequentIcon,
  ObjectsIcon,
  SmileysIcon,
  SymbolsIcon,
  TravelIcon,
} from '../../component/icons/Tint/20/EmojiTabs'
import SearchInput from '../../component/search-input'
import Segment from '../../component/segment'
import SegmentControl from '../../component/segment/control'
import List, { VirtualListStore } from '../../component/virtual-list'
import { chunk, searchIndex } from '../../lib/collections'
import { Char, Emoji, EmojiCategory, getEmojiChar, getEmojis } from '../../lib/emoji'
import { isNonNull } from '../../lib/rx-operators'
import shortId from '../../lib/short-id'
import { throttle } from '../../lib/throttle'
import useGridStepper from '../../lib/use-grid-stepper'
import useInputState from '../../lib/use-input-state'
import { KeyMappings } from '../../lib/use-key-stepper'
import { ImmutableCollection } from '../../service/model'
import { fonts } from '../../theme'
import FitzpatrickScaleSelector from './fitzpatrick-scale-selector'

interface EmojiListProps {
  skinTone?: string
  onEmojiSelected?: (emoji: string) => void
}

interface EmojiPickerCategory {
  title: 'Recently Used' | EmojiCategory
  icon: () => React.ReactNode
}

const columnCount = 8
const rowHeight = 42
const headerRowHeight = 45
const cellSize = rowHeight
const gridPadding = 10

const categories: readonly EmojiPickerCategory[] = [
  { title: 'Recently Used', icon: () => <FrequentIcon /> },
  { title: 'Smileys & People', icon: () => <SmileysIcon /> },
  { title: 'Animals & Nature', icon: () => <AnimalsIcon /> },
  { title: 'Food & Drink', icon: () => <FoodIcon /> },
  { title: 'Travel & Places', icon: () => <TravelIcon /> },
  { title: 'Activities', icon: () => <ActivityIcon /> },
  { title: 'Objects', icon: () => <ObjectsIcon /> },
  { title: 'Symbols', icon: () => <SymbolsIcon /> },
  { title: 'Flags', icon: () => <FlagsIcon /> },
]

type Row =
  | {
      id: string
      type: 'emojis'
      emojis: Emoji[]
    }
  | {
      id: string
      type: 'header'
      title: string
    }

interface HeaderRowOffset {
  title: typeof categories[number]['title']
  offset: number
}

const EmojiList: React.FC<EmojiListProps> = function ({ onEmojiSelected, skinTone }) {
  const styles = useStyles({})
  const ref = useRef<VirtualListStore<Row>>(null)
  const [input, setInput] = useInputState('')
  const [selectedEmoji, setSelectedEmoji] = useState<Emoji>(null)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [categoriesEnabled, setCategoriesEnabled] = useState(true)

  const rows = useMemo(() => {
    const emojiList = getEmojis().list
    const result: Row[] = []

    const filtered = (() => {
      if (input) {
        return emojiList.filter((emoji) => emoji.keywords.includes(input.trim()))
      } else {
        return emojiList
      }
    })()

    if (input) {
      setCategoriesEnabled(false)
      result.push({
        id: shortId(),
        type: 'header',
        title: 'Results',
      })
      chunk(filtered, columnCount).forEach((emojis) => {
        result.push({ id: shortId(), type: 'emojis', emojis })
      })
    } else {
      setCategoriesEnabled(true)
      if (!input) {
        const recents = getRecents()
        if (recents.length > 0) {
          result.push({
            id: shortId(),
            type: 'header',
            title: 'Recently Used',
          })
          chunk(recents, columnCount).forEach((emojis) => {
            result.push({ id: shortId(), type: 'emojis', emojis })
          })
        }
      }

      const groups: { [key: string]: Emoji[] } = filtered.reduce((final, emoji) => {
        if (!final[emoji.category]) {
          final[emoji.category] = []
        }
        final[emoji.category].push(emoji)
        return final
      }, {})

      Object.keys(groups).map((group) => {
        result.push({
          id: shortId(),
          type: 'header',
          title: group,
        })
        chunk(groups[group], columnCount).forEach((emojis) => {
          result.push({ id: shortId(), type: 'emojis', emojis })
        })
      })
    }
    return new ImmutableCollection(result)
  }, [input])

  const calculateOffsets = useCallback((): HeaderRowOffset[] => {
    return categories.map((category) => {
      const headerRow = rows.list.findIndex(
        (row) => row.type === 'header' && row.title === category.title,
      )
      const offset = ref.current?.getOffsetForRow(headerRow)
      return { title: category.title, offset }
    })
  }, [categories, rows])

  const recalculateSelectedCategory = useCallback(
    (scrollTop: number) => {
      const offsets = calculateOffsets()
      const index = searchIndex(
        offsets,
        (_, index) => offsets[index + 1]?.offset - scrollTop - headerRowHeight / 2,
      )
      setSelectedCategory(index)
    },
    [calculateOffsets],
  )

  const { getItemProps, selectedIndex } = useGridStepper({
    name: 'EmojiList',
    rowCount: rows.length,
    columnCount: (index) => {
      const row = rows.list[index]

      if (!row) {
        return 0
      }

      return row.type === 'header' ? 1 : row.emojis.length
    },
    skip: (index) => {
      const row = rows.list[index.row]

      if (!row) {
        return false
      }

      return row.type === 'header'
    },
    defaultSelectedIndex: { row: 1, col: 0 },
    handleSelect: (index) => {
      const row = rows.list[index.row]
      if (row.type === 'header') return

      const emoji = row.emojis[index.col]
      if (skinTone && emoji.skinVariations?.[skinTone]) {
        handleSelect(emoji, emoji.skinVariations[skinTone])
      } else if (emoji.skinVariations) {
        setSelectedEmoji(emoji)
      } else {
        handleSelect(emoji)
      }
    },
    deps: [skinTone, rows],
  })

  useEffect(() => recalculateSelectedCategory(0), [input, recalculateSelectedCategory])

  useEffect(() => {
    ref.current?.scrollIntoView(selectedIndex.row, 'nearest')
  }, [selectedIndex.row])

  const handleCategoryChange = useCallback(
    (index: number) => {
      const offsets = calculateOffsets()
      ref.current?.setScrollTo(offsets[index].offset)
      setSelectedCategory(index)
    },
    [calculateOffsets],
  )

  const handleScroll = useCallback(
    throttle(({ top }) => recalculateSelectedCategory(top), 100),
    [recalculateSelectedCategory],
  )

  const handleSelect = useCallback(
    (emoji: Emoji, variation?: Char) => {
      updateRecents(emoji)
      onEmojiSelected?.(variation ? variation.char : emoji.char)
    },
    [onEmojiSelected],
  )

  const renderRow = (row: Row, index: number): React.ReactNode => {
    if (row.type === 'header') {
      return (
        <div
          {...getItemProps({ row: index, col: 0 })}
          className={cx(styles.header, selectedIndex.row === index && styles.highlighted)}
        >
          {row.title}
        </div>
      )
    } else {
      return (
        <div className={styles.row}>
          {row.emojis.map((em, col) => {
            const { char } = getEmojiChar(em, skinTone)

            return (
              <div
                {...getItemProps({ row: index, col })}
                key={char}
                id={char}
                className={cx(
                  styles.item,
                  selectedIndex.row === index &&
                    selectedIndex.col === col &&
                    styles.highlighted,
                )}
              >
                {char}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const calculateRowHeight = (row: Row) => {
    return row.type === 'header' ? headerRowHeight : rowHeight
  }

  return (
    <>
      {selectedEmoji && (
        <FitzpatrickScaleSelector
          emoji={selectedEmoji}
          onClose={() => setSelectedEmoji(null)}
          onSelect={(variation) => handleSelect(selectedEmoji, variation)}
        />
      )}
      <div className={styles.root}>
        <div className={styles.categories}>
          <Segment
            disabled={!categoriesEnabled}
            value={selectedCategory}
            keys={KeyMappings.NONE}
            onChange={handleCategoryChange}
          >
            {categories.map(({ title, icon }, i) => (
              <SegmentControl key={i} icon={icon()} title={title} />
            ))}
          </Segment>
        </div>
        <div className={styles.search}>
          <SearchInput
            autoFocus
            value={input}
            onChange={setInput}
            placeholder="Search Emoji…"
          />
        </div>
        <div>
          <List
            ref={ref}
            data={rows}
            height={275}
            itemContent={renderRow}
            itemEstimatedHeight={calculateRowHeight}
            onPassiveScroll={handleScroll}
          />
        </div>
      </div>
    </>
  )
}

const getRecents = (): Emoji[] => {
  const emojis = getEmojis()
  const cache = window.localStorage.getItem('recent_emojis')
  const recents: string[] = cache ? JSON.parse(cache) : []
  return recents
    .map((recent) => emojis.get(recent))
    .filter(isNonNull)
    .slice(0, columnCount * 2)
}

const updateRecents = (emoji: Emoji) => {
  let recents = getRecents()
  recents = [emoji, ...recents.filter((v) => v !== emoji).slice(0, columnCount * 2)]
  window.localStorage.setItem(
    'recent_emojis',
    JSON.stringify(recents.map((recent) => recent.char)),
  )
}

export default EmojiList

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    outline: 'none',
  },
  categories: {
    padding: `8px ${gridPadding}px 0`,
  },
  search: {
    padding: `12px ${gridPadding}px 0`,
    marginBottom: 5,
  },
  header: {
    color: theme.palette.op.text.tertiary,
    fontSize: 12,
    padding: '17px 17px 0',
    boxSizing: 'border-box',
    fontWeight: 550,
    height: headerRowHeight,
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: `0 ${gridPadding}px`,
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: cellSize,
    cursor: 'pointer',
    justifyContent: 'center',
    fontSize: '1.5rem',
    borderRadius: 5,
    fontFamily: fonts.emoji,
    height: rowHeight,

    '&::before': {
      background: theme.palette.op.hover.primary,
      borderRadius: 'inherit',
      bottom: 0,
      content: '""',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateZ(0px)',
      transition: theme.transitions.create(['opacity'], { duration: 200 }),
    },
  },
  highlighted: {
    '&::before': {
      opacity: 1,
    },
  },
}))
