// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import { toE164 } from '../lib/phone-number'
import useKeyboardShortcuts from '../lib/use-keyboard-shortcuts'
import { useSize } from '../lib/use-size'
import { mediaStyles } from '../theme'
import Analytics from './analytics'
import Banners from './banner'
import NotificationBanner from './banner/notification'
import CoToComBanner from './banner/co-to-com'
import Contacts from './contacts'
import Inbox from './inbox'
import { SettingsRoute } from './settings'
import AppSideMenu from './side-menu'
import ActiveCalls from './voice'
import { Redirect } from '@src/app/router'

interface MainProps {}

const Main: React.FC<MainProps> = function ({}) {
  const styles = useStyles({})
  const ref = useRef<HTMLDivElement>(null)
  const { voice, command } = useAppStore()
  const [compressed, setCompressed] = useState(false)

  useSize(
    ref,
    (size) => {
      setCompressed(size.width < 1400)
    },
    [],
  )

  useKeyboardShortcuts({
    name: 'main',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Meta+Slash') {
        command.present({ name: 'search' })
        event.preventDefault()
      } else if (shortcut === 'Meta+KeyK') {
        command.present({ name: 'app' })
        event.preventDefault()
      }
    },
  })

  /**
   * Intercept links with tel and use OpenPhone instead
   */
  useEffect(() => {
    const handleClick = function (event): boolean {
      const element: any = event.target || event.srcElement
      if (element.tagName.toUpperCase() == 'A' && element.href?.startsWith('tel:')) {
        event.preventDefault()
        const number = toE164(element.href.substr(4))
        command.present({ name: 'dialer', phoneNumber: number })
        return false
      }
      return true
    }

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [])

  return (
    <div ref={ref} className={styles.root} id="main-container">
      <Banners>
        <NotificationBanner />
        <CoToComBanner />
      </Banners>
      {voice.hasActiveCalls && <ActiveCalls />}
      <div className={styles.main}>
        <AppSideMenu compressed={compressed} />
        <Switch>
          <Route path="/contacts" component={Contacts} />
          <Route path="/analytics" component={Analytics} />
          <Route path="/settings" component={SettingsRoute} />
          <Route path="/inbox" component={Inbox} />
          <Redirect to="/inbox" />
        </Switch>
      </div>
    </div>
  )
}

export default observer(Main)

const useStyles = makeStyles((theme: Theme) => ({
  tabs: mediaStyles({
    phone: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    },
  }),
  root: mediaStyles({
    phone: {
      paddingTop: 51,
    },
    desktop: {
      height: '100%',
    },
    common: {
      minWidth: 940,
      display: 'flex',
      flexDirection: 'column',
      transition: theme.transitions.create(['opacity'], {
        duration: 200,
        easing: 'ease',
      }),
    },
  }),
  main: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
}))
