import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import FileIcon from '../../../component/file-icon'
import { fileType } from '../../../lib/file'
import { FileItemPart, Item } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'

export type FileMediaProps<I extends Item> = ItemPartProps<I, FileItemPart>

const FileMedia = function <I extends Item>({
  controller,
  item,
  file,
  ...props
}: FileMediaProps<I>) {
  const styles = useStyles({})
  const type = fileType(file.type, file.url)

  const handleFileClick = useCallback(
    () => controller.openMedia(file),
    [controller, file],
  )

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props} className={styles.bubble}>
        <div className={styles.root} onClick={handleFileClick}>
          <FileIcon type={type} className={styles.icon} />
          <div className={styles.body}>
            <div className={styles.name}>{file.name ?? 'Unnamed'}</div>
          </div>
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(FileMedia)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      margin: '0 7px',
    },
    body: {
      marginRight: 20,
    },
    name: {
      fontSize: '0.85rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    bubble: {
      userSelect: 'none',
      color: theme.palette.op.text.primary,
      backgroundColor: theme.palette.op.gray[5],

      '&:hover': {
        backgroundColor: theme.palette.op.hover.primary,
      },
    },
  }),
  { name: FileMedia.name },
)
