// @ts-strict-ignore
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { fromEvent } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { useAppStore } from '@src/app/context'
import Key from '../../../component/keyboard-key'
import { useObservable } from '../../../lib/hooks'
import { formatted, toE164 } from '../../../lib/phone-number'
import { usePhoneNumberInputState } from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Identity } from '../../../service/model'
import { SearchResult } from '../../../service/search-store'
import Content from '../common/content'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Textfield from '../common/textfield'
import Row from './row'

export interface PhoneNumberSearchCommandProps {
  onSelect: (item: string | Identity) => void
  actionIcon: React.ReactNode
  actionTitle: string
  defaultValue?: string
  actionColor?: 'primary' | 'secondary'
}

const PhoneNumberSearchCommand: React.FC<PhoneNumberSearchCommandProps> = function ({
  actionIcon,
  actionTitle,
  onSelect,
  defaultValue,
  actionColor = 'primary',
}) {
  const { service, toast } = useAppStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const [input, setInput] = usePhoneNumberInputState(formatted(defaultValue))
  const [items, setItems] = useState<SearchResult[]>([])

  useObservable(
    () =>
      fromEvent(inputRef.current, 'input', { passive: true }).pipe(
        debounceTime(100),
        map((e) => (e.target as HTMLInputElement).value),
      ),
    function (value) {
      service.search.identities(value).then(setItems)
    },
  )

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    defaultSelectedIndex: 0,
    deps: [input],
    name: 'command/phone-number-selector/search',
    handleSelect: (result) => {
      let phone: string
      if (!result) {
        phone = toE164(input)
      } else if (result.matchedPhoneNumber) {
        phone = result.matchedPhoneNumber
      } else if (result.identity.phones.length > 1) {
        return onSelect(result.identity)
      } else if (result.identity.phones.length === 1) {
        phone = result.identity.phones[0].number
      } else {
        toast.show({
          message: `${result.identity.name} has no phone numbers.`,
        })
      }
      onSelect(toE164(phone))
    },
  })

  const handleSelect = () => {
    onSelect(toE164(input))
  }

  return (
    <>
      <Textfield
        autoFocus
        value={input}
        innerRef={inputRef}
        spellCheck={false}
        onChange={setInput}
        placeholder="Enter a phone number or name"
      />
      <Content>
        {items.map((item, index) => (
          <Row
            {...getItemProps(index)}
            key={item.identity.id}
            query={input}
            identity={item.identity}
            secondaryLabel={item.matchedPhoneNumber}
            highlighted={index === selectedIndex}
          />
        ))}
      </Content>
      <Footer
        actions={
          <FooterButton
            variant="contained"
            color={actionColor}
            onClick={handleSelect}
            startIcon={actionIcon}
          >
            {actionTitle}
          </FooterButton>
        }
      >
        <Typography variant="caption" color="textSecondary">
          Press <Key name="Enter" /> to {actionTitle.toLocaleLowerCase()}
        </Typography>
      </Footer>
    </>
  )
}

export default observer(PhoneNumberSearchCommand)
