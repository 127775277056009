// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import Mention from '../../mention'
import IconButton from '../../../component/icon-button'
import InputBar from '../../../component/input-bar'
import FormattedText from '../../../component/formatted-text'
import Typography from '@ui/Typography'
import { friendlyDateTime } from '../../../lib'
import { Note } from '../../../service/model'
import { InputBarDeserializer } from '../../input-bar'
import { MoreSmallIcon } from '../../../component/icons/custom'
import { AppAvatar } from '@src/app/components'

interface ContactNoteProps {
  note: Note
  editing?: boolean
  endEditing?: () => void
  onMore: (anchorEl: HTMLButtonElement, note: Note) => void
}

const ContactNote: React.FC<ContactNoteProps> = function ({
  note,
  editing,
  endEditing,
  onMore,
}) {
  const styles = useStyles({})
  const app = useAppStore()

  const handleMore = useCallback((event) => onMore(event.target, note), [note, onMore])

  const renderMention = useCallback(
    (mention: string, offset?: number) => <Mention key={offset} id={mention} />,
    [],
  )

  const renderInputBar = () => {
    const controller = app.contacts.contactNoteInputBarRegistry.get(note)

    return (
      <InputBar
        controller={controller}
        colorScheme="note"
        placeholder="Edit note…"
        renderMention={renderMention}
      />
    )
  }

  useEffect(() => {
    if (editing) {
      const controller = app.contacts.contactNoteInputBarRegistry.get(note)
      const subscription = controller.messages$.subscribe(endEditing)
      const deserializer = new InputBarDeserializer(app, note.enrichment)
      const value = deserializer.deserialize(note.text)
      controller.message = value
      return () => subscription.unsubscribe()
    }
  }, [note, editing, endEditing])

  return (
    <div className={styles.root}>
      <AppAvatar identity={note.user} size={36} />
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography nowrap variant="callout" fontWeight="medium">
            {note.user?.nameWithStatus}
          </Typography>
          <Typography
            nowrap
            variant="caption1"
            color="textTertiary"
            fontWeight="regular"
            style={{ marginLeft: 5 }}
          >
            {friendlyDateTime(note.createdAt)}
          </Typography>
          {note.canEdit && (
            <IconButton
              size="small"
              icon={<MoreSmallIcon />}
              onClick={handleMore}
              className={styles.moreButton}
            />
          )}
        </div>
        {editing ? (
          renderInputBar()
        ) : (
          <Typography component="div" variant="callout">
            <FormattedText value={note.text} renderMention={renderMention} />
          </Typography>
        )}
      </div>
    </div>
  )
}

export default observer(ContactNote)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px 0',
    marginBottom: 2,
  },
  content: {
    flex: 1,
    marginLeft: 12,
    userSelect: 'text',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  moreButton: {
    marginLeft: 'auto',
  },
  edit: {
    margin: '5px 0',
  },
}))
