import React from 'react'
import { observer } from 'mobx-react-lite'
import Button from '../../../../component/button-v2'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../../component/dialog'
import Typography from '@ui/Typography'
import { PhoneNumber } from '../../../../service/model'
import { CallTransferConfirmDialogController } from '../../store'

export interface ConfirmTransferProps {
  controller: CallTransferConfirmDialogController
}

const ConfirmTransfer: React.FC<ConfirmTransferProps> = function ({ controller }) {
  return (
    <Dialog
      open={controller.isOpen}
      onClose={controller.handleClose}
      disableEscapeKeyDown
    >
      <DialogTitle>Transfer this call to {controller.transfereeName}</DialogTitle>
      <DialogContent>
        <Typography variant="body" color="textSecondary">
          {controller.transferee?.to instanceof PhoneNumber ? (
            <>
              Transferring this call will end the call for you immediately, and anyone in{' '}
              <strong>{controller.transfereeName}</strong> will be able to pick up.
              {controller.transferee?.to.isOffHours ? (
                <>
                  <br />
                  <br />
                  Please note that this inbox is currently{' '}
                  <strong>outside of business hours</strong>.
                </>
              ) : null}
            </>
          ) : (
            <>Transferring this call will end the call for you immediately.</>
          )}{' '}
          Are you sure you want to continue with the transfer?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="textSecondary" onClick={controller.handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={controller.handleConfirm}>
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(ConfirmTransfer)
