export default class SyncStorage<T = unknown> {
  get(key: string): T | null {
    const value = localStorage.getItem(key)
    if (!value) return value as any
    try {
      return JSON.parse(value)
    } catch (e) {
      return value as any
    }
  }

  set(key: string, item: any) {
    if (typeof item === 'string') {
      localStorage.setItem(key, item)
    } else {
      localStorage.setItem(key, JSON.stringify(item))
    }
  }

  delete(key: string) {
    localStorage.removeItem(key)
  }
}
