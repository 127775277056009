// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import Collapse from '@ui/Collapse'
import { AppAvatar } from '@src/app/components'
import { AppAvatarRow } from '@src/app/components'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import IconButton from '../../../component/icon-button'
import { DeleteIcon } from '../../../component/icons/Tint/20/General'
import { NativeSelect } from '../../../component/select'
import Switch from '../../../component/switch'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import { removeAtIndex, replaceAtIndex } from '../../../lib/collections'
import { logError } from '../../../lib/log'
import { formatted } from '../../../lib/phone-number'
import { EntityPhoneNumber } from '../../../service/model'
import { IvrOption, IvrSettings } from '../../../types'
import { AudioPlayer, Header, Item, Section } from '../common'

interface AutoResponderProps {
  phoneNumber: EntityPhoneNumber
}

const AutoResponder: React.FC<AutoResponderProps> = function ({ phoneNumber }) {
  const styles = useStyles({})
  const { command, showAlert, history, service, toast } = useAppStore()
  const subscription = service.billing.subscription
  const phoneNumbers = service.organization.phoneNumber.collection
  const members = service.member.collection
  const enabled = phoneNumber?.ivr?.enabled
  const [changed, setChanged] = useState(false)
  const ivr = phoneNumber?.ivr
  const [ivrCopy, setIvrCopy] = useState<IvrSettings>(null)

  const resetIvr = () => {
    setChanged(false)
    if (!ivr) {
      setIvrCopy({
        phoneNumberId: phoneNumber?.id,
        options: [{ digit: 1 }, { digit: 2 }],
      })
    } else {
      const options =
        !ivr.options || ivr.options.length === 0
          ? [{ digit: 1 }, { digit: 2 }]
          : ivr.options.sort((a, b) => a.digit - b.digit)

      setIvrCopy({ ...ivr, options })
    }
  }

  useEffect(() => {
    if (!phoneNumber?.id) return
    phoneNumber.fetchIvr().catch(logError)
    setIvrCopy({
      ...ivrCopy,
      phoneNumberId: phoneNumber.id,
    })
  }, [phoneNumber?.id])

  useEffect(resetIvr, [ivr])

  const handleSetRecording = () => {
    command.present({
      name: 'set greeting',
      title: 'Phone menu recording',
      description: 'The audio to play for callers',
      defaultText: ivrCopy.greeting,
      onComplete: (url: string, text: string) => {
        command.hide()
        setChanged(true)
        setIvrCopy({
          ...ivrCopy,
          greetingUrl: url,
          greeting: text,
        })
      },
    })
  }

  const handleDestination = (index: number) => {
    command.present({
      name: 'select ivr destination',
      currentPhoneNumber: phoneNumber,
      phoneNumbers: phoneNumbers.list.filter((p) => p.id !== phoneNumber.id),
      onAdd: () => {
        command.hide()
        history.push('/settings/phone-numbers')
      },
      onSelect: (destination) => {
        command.hide()
        setChanged(true)
        setIvrCopy({
          ...ivrCopy,
          options: replaceAtIndex(
            ivrCopy.options,
            {
              ...ivrCopy.options[index],
              destination,
            },
            index,
          ),
        })
      },
    })
  }

  const handleDefaultDestination = () => {
    if (!subscription.isPremium) {
      command.present({ name: 'requires premium' })
      return
    }
    command.present({
      name: 'select ivr destination',
      currentPhoneNumber: phoneNumber,
      phoneNumbers: phoneNumbers.list.filter((p) => p.id !== phoneNumber.id),
      options: [
        'repeat',
        'internal',
        'external',
        'default',
        'user',
        'voicemail',
        'audio',
      ],
      onAdd: () => {
        command.hide()
        history.push('/settings/phone-numbers')
      },
      onSelect: (destination) => {
        command.hide()
        setChanged(true)
        setIvrCopy({
          ...ivrCopy,
          defaultDestination: destination,
        })
      },
    })
  }

  const handleDigitChanged = (event, index: number) => {
    const digit = parseInt(event.target.value)
    const item = ivrCopy.options[index]
    const options = replaceAtIndex(
      ivrCopy.options.map(
        (o): IvrOption => (o.digit === digit ? { ...o, digit: null } : o),
      ),
      { ...item, digit },
      index,
    )
    setIvrCopy({ ...ivrCopy, options })
  }

  const handleAddOption = () => {
    const digits = ivrCopy.options
      .map((option) => option.digit)
      .filter((a) => a != null)
      .sort()
    const lastDigit = digits[digits.length - 1]
    const nextDigit = lastDigit + 1 > 9 ? null : lastDigit + 1

    setChanged(true)
    setIvrCopy({
      ...ivrCopy,
      options: [...ivrCopy.options, { digit: nextDigit }],
    })
  }

  const handleRemoveOption = (index: number) => {
    setChanged(true)
    setIvrCopy({
      ...ivrCopy,
      options: removeAtIndex(ivrCopy.options, index),
    })
  }

  const handleSave = () => {
    const ivr: IvrSettings = {
      ...ivrCopy,
      options: ivrCopy.options.filter((o) => o.destination),
    }

    if (ivr.options.length === 0) {
      showAlert({
        title: 'Invalid configuration',
        body: 'You need at least one option in your phone menu with a destination.',
      })
      return
    }

    if (!ivr.greetingUrl) {
      showAlert({
        title: 'Invalid configuration',
        body: 'You need a greeting message for your phone menu.',
      })
      return
    }
    phoneNumber
      .updateIvr(ivr)
      .then(() => {
        toast.show({ message: 'Saved!' })
      })
      .catch(toast.showError)
  }

  const handleCommandChange = (index: number, value: string) => {
    setChanged(true)
    setIvrCopy({
      ...ivrCopy,
      options: replaceAtIndex(
        ivrCopy.options,
        { ...ivrCopy.options[index], name: value, phrase: value },
        index,
      ),
    })
  }

  const handleEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true)
    setIvrCopy({
      ...ivrCopy,
      enabled: event.target.checked,
    })
  }

  const prettyDestination = (destination: string, defaultWhenNull: string) => {
    const pn = phoneNumbers.get(destination)
    const member = members.get(destination)

    if (destination === 'VM') {
      return (
        <Typography variant="footnote" color="textPrimary">
          Send to voicemail
        </Typography>
      )
    } else if (member) {
      return (
        <>
          <AppAvatar identity={member} size={20} />
          <Typography variant="footnote" color="textPrimary" style={{ marginLeft: 7 }}>
            {member.name}
          </Typography>
        </>
      )
    } else if (destination?.startsWith('http')) {
      return (
        <Typography variant="footnote" color="textPrimary">
          Play an audio
        </Typography>
      )
    } else if (pn && pn.id === phoneNumber?.id) {
      return (
        <Typography variant="footnote" color="textPrimary">
          Let the call through
        </Typography>
      )
    } else if (pn && pn.users.length === 1) {
      return (
        <>
          <AppAvatarRow identities={pn.members} className={styles.avatarRow} />
          <Typography variant="footnote" color="textPrimary">
            {pn.users[0]?.firstName}
          </Typography>
          <Typography variant="footnote" color="textSecondary" style={{ marginLeft: 5 }}>
            {pn.formattedNumber}
          </Typography>
        </>
      )
    } else if (pn && pn.users.length > 1) {
      return (
        <>
          <Typography variant="footnote" color="textPrimary">
            {pn.users.length > 1 ? pn.name : pn.users[0]?.firstName}
          </Typography>
          <Typography variant="footnote" color="textSecondary" style={{ marginLeft: 5 }}>
            {pn.formattedNumber}
          </Typography>
        </>
      )
    } else if (destination) {
      return (
        <Typography variant="footnote" color="textPrimary">
          {formatted(destination)}
        </Typography>
      )
    } else {
      return defaultWhenNull
    }
  }

  const renderItem = (item: IvrOption, index: number) => {
    const destination = prettyDestination(item.destination, 'Select a destination')

    return (
      <div key={`${index}:${item.digit}`} className={styles.item}>
        <div className={styles.keypadCommand}>
          <NativeSelect
            size={35}
            style={{ width: '100%' }}
            value={item.digit == null ? '' : item.digit}
            onChange={(e) => handleDigitChanged(e, index)}
          >
            <option disabled value="">
              --
            </option>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </NativeSelect>
        </div>
        <div className={styles.voiceCommand}>
          <TextInput
            fullWidth
            size={35}
            placeholder="Command"
            value={item.phrase || ''}
            onChange={(e: any) => handleCommandChange(index, e.target.value)}
            className={cx(styles.input, styles.voice)}
          />
        </div>
        <div className={styles.destination}>
          <Button
            height={35}
            align="start"
            variant="outlined"
            color="textPrimary"
            style={{ flex: 1, justifyContent: 'flex-start' }}
            className={styles.nowrap}
            onClick={() => handleDestination(index)}
          >
            {destination}
          </Button>
          <IconButton
            size="medium"
            onClick={() => handleRemoveOption(index)}
            className={styles.remove}
            title="Delete option"
            icon={<DeleteIcon />}
          />
        </div>
      </div>
    )
  }

  return (
    <Section highlighted={changed}>
      <Header
        title="Phone menu"
        subtitle="Design a phone menu controlled by keypad and voice and direct your callers to the appropriate department or person"
      />
      <Item
        title="Enabled"
        description="Quickly enable or disable the phone menu on your phone number"
        input={<Switch checked={ivrCopy?.enabled ?? false} onChange={handleEnable} />}
      />
      <Collapse collapsed={!ivrCopy?.enabled}>
        <Item
          title="Greeting message"
          description="Example: Hi! Thanks for calling. For sales, press 1 or say sales. For support, press 2 or say support."
          input={
            <Button variant="outlined" color="textPrimary" onClick={handleSetRecording}>
              {ivrCopy?.greetingUrl ? 'Change greeting' : 'Set a greeting message'}
            </Button>
          }
        >
          {ivrCopy?.greetingUrl && (
            <AudioPlayer media={{ url: ivrCopy?.greetingUrl, type: 'audio/mpeg' }} />
          )}
        </Item>

        <Item
          title="Menu options"
          description="Options are triggered by keypad and voice commands and they route callers to
          other phone numbers in your workspace"
        >
          <div className={styles.header}>
            <div className={styles.keypadCommand}>
              <Tooltip
                title="The keypad option that would trigger this menu item"
                placement="bottom-start"
              >
                <span className={styles.hint}>Key</span>
              </Tooltip>
            </div>
            <div className={styles.voiceCommand}>
              <Tooltip
                title="The voice command that would trigger this menu item"
                placement="bottom-start"
              >
                <span className={styles.hint}>Voice</span>
              </Tooltip>
            </div>
            <div className={styles.destination} style={{ padding: '0 5px' }}>
              <Tooltip
                title="Caller will be directed here once they trigger this option through keypad or voice"
                placement="bottom-start"
              >
                <span className={styles.hint}>Destination</span>
              </Tooltip>
            </div>
          </div>
          {ivrCopy?.options.map(renderItem)}

          {ivrCopy?.options.length <= 9 && (
            <Button
              height={35}
              variant="text"
              color="primary"
              startIcon={<AddIcon fontSize="small" />}
              style={{ marginTop: 15 }}
              onClick={handleAddOption}
            >
              {ivrCopy?.options.length < 1 ? 'Add an option' : 'Add another option'}
            </Button>
          )}
        </Item>

        <Item
          title="If no option is selected"
          description="Select what should happen when the caller stays on the line without selecting an option"
          input={
            <Button
              height={40}
              color="textPrimary"
              variant="outlined"
              style={{ width: '100%' }}
              className={styles.nowrap}
              onClick={handleDefaultDestination}
            >
              {prettyDestination(ivrCopy?.defaultDestination, 'Repeat the menu options')}
            </Button>
          }
        />
      </Collapse>
      {(changed || ivr) && (
        <div
          style={{
            borderRadius: 8,
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 0 5px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {changed && (
              <>
                <Button
                  height={35}
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  height={35}
                  variant="outlined"
                  color="textPrimary"
                  style={{ marginLeft: 16 }}
                  onClick={resetIvr}
                >
                  Discard
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </Section>
  )
}

export default observer(AutoResponder)

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    '& input': {
      padding: '5px 10px',
      fontSize: '0.9rem',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
  },
  key: {
    flex: '0 0 32px',
    textAlign: 'center',
  },
  voice: {
    flex: '0 0 100px',
  },
  remove: {
    marginLeft: 5,
  },
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  digitDropdown: {
    padding: 4,
    textAlign: 'center',
    width: 30,
    marginRight: 5,
    fontSize: 14,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11,
    color: theme.palette.text.primary,
    margin: '5px 0 8px',
  },
  hint: {
    textTransform: 'uppercase',
    textDecorationStyle: 'dotted',
    textDecorationLine: 'underline',
    cursor: 'help',
  },
  keypadCommand: {
    flex: '0 0 65px',
  },
  voiceCommand: {
    flex: '0 0 100px',
    margin: '0 10px',
  },
  destination: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  avatarRow: { marginRight: 10 },
}))
