import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../component/icon-button'
import {
  AddIcon,
  DeleteIcon,
  FilterFunnelIcon,
  MoreIcon,
  Share1Icon,
  UploadIcon,
} from '../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../component/popover-menu'
import Typography from '@ui/Typography'
import Header from '../header'

interface HeaderProps {}

const ContactHeader: React.FC<HeaderProps> = function ({}) {
  const styles = useStyles({})
  const moreRef = useRef<HTMLButtonElement>(null)
  const [showMore, setShowMore] = useState(false)
  const { contacts, command, showAlert, toast } = useAppStore()
  const someSelected = contacts.checked.size > 0

  const handleFilter = () => {
    hideMore()
    command.present({
      name: 'contact filters',
      view: contacts.view,
    })
  }

  const handleUpload = () => {
    hideMore()
    command.present({
      name: 'import contacts',
      onComplete: (csvContacts) => {
        contacts.bulkImport(csvContacts)
        command.hide()
        toast.show({ message: `${csvContacts.length} contacts imported.` })
      },
    })
  }

  const hideMore = () => {
    setShowMore(false)
  }

  const handleMore = () => {
    setShowMore(true)
  }

  const handleBulkDelete = () => {
    const count = contacts.checked.size
    showAlert({
      title: `Deleting ${count.toLocaleString('en')} contacts...`,
      body: 'Are you sure you want to continue?',
      actions: [
        {
          title: 'Yes, delete',
          type: 'destructive',
          onClick: () => {
            contacts.deleteSelected()
            contacts.unselectAll()
          },
        },
        {
          title: 'No, keep them',
        },
      ],
    })
  }

  const handleBulkShare = () => {
    const count = contacts.checked.size
    command.present({
      name: 'edit shared with',
      ids: [],
      title:
        count === 1 ? 'Share Contact' : `Share ${count.toLocaleString('en')} Contacts`,
      subtitle:
        count === 1
          ? 'Give other people in your org access to this contact'
          : 'Give other people in your org access to these contacts',
      onSave: (ids) => {
        contacts.shareSelected(ids)
        command.hide()
      },
    })
  }

  return (
    <Header className={styles.root}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="footnote">Contacts</Typography>
        <Typography variant="footnote" color="textSecondary" style={{ margin: '0 10px' }}>
          {contacts.list.length}
        </Typography>
        <IconButton icon={<FilterFunnelIcon />} title="Filter" onClick={handleFilter} />
      </div>
      <div className={styles.actions}>
        {someSelected && (
          <div className={styles.bulkActions}>
            <Typography variant="footnote" color="textSecondary" nowrap>
              {contacts.checked.size} selected
            </Typography>
            <IconButton
              icon={<DeleteIcon />}
              title="Delete All"
              onClick={handleBulkDelete}
            />
            <IconButton
              icon={<Share1Icon />}
              title="Bulk Share"
              onClick={handleBulkShare}
            />
          </div>
        )}
        <PopoverMenu
          open={showMore}
          onClose={hideMore}
          anchorEl={moreRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
        >
          <MenuItem icon={<UploadIcon />} onClick={handleUpload}>
            Import CSV
          </MenuItem>
        </PopoverMenu>
        <IconButton
          ref={moreRef}
          title="More actions"
          icon={<MoreIcon />}
          onClick={handleMore}
        />
        <IconButton
          opaque
          title="Add a new contact"
          icon={<AddIcon />}
          color="primary"
          onClick={contacts.newContact}
        />
      </div>
    </Header>
  )
}

export default observer(ContactHeader)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  bulkActions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    paddingRight: 22,
    borderRight: `1.5px solid ${theme.palette.op.border.common}`,

    '& > *': {
      marginLeft: 12,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      marginLeft: 12,
    },
  },
}))
