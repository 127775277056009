// @ts-strict-ignore
export function isMacDesktop(): boolean {
  return navigator.userAgent.includes('Macintosh')
}

export function isFirefox(): boolean {
  return navigator.userAgent.includes('Firefox/')
}

export function isChrome(): boolean {
  return getUABrand() === 'Google Chrome'
}

export function isEdge(): boolean {
  return getUABrand() === 'Microsoft Edge'
}

export function isBrave(): boolean {
  return !!navigator?.brave
}

export function isChromium(): boolean {
  return !isChrome() && !isEdge() && !isBrave() && getUABrand() === 'Chromium'
}

export function isSafari(): boolean {
  return 'safari' in window
}

export function getBrowserName(): string | null {
  if (isFirefox()) return 'Firefox'
  if (isChrome()) return 'Google Chrome'
  if (isBrave()) return 'Brave'
  if (isEdge()) return 'Microsoft Edge'
  if (isSafari()) return 'Safari'
  if (isChromium()) return 'Chromium'
  return null
}

function getUABrand() {
  if (!('userAgentData' in navigator)) return false
  const lastBrand =
    navigator.userAgentData.brands[navigator.userAgentData.brands.length - 1]
  return lastBrand.brand
}
