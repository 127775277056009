// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatarRow, type AppAvatarRowProps } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Tooltip from '../../../component/tooltip'
import { joinPretty } from '../../../lib'
import { isNonNull } from '../../../lib/rx-operators'
import { Conversation, Identity } from '../../../service/model'

interface ParticipantProps {
  conversation: Conversation
  size?: AppAvatarRowProps['size']
}

const Participant: React.FC<ParticipantProps> = function ({ conversation, size = 16 }) {
  const styles = useStyles({})
  const members = conversation.presence.map((p) => p.member).filter(isNonNull)
  const typing = conversation.presence
    .filter((p) => p.status === 'typing')
    .map((p) => p.member)

  const avatarTitle = `${joinPretty(members.map((m) => m.shortName))} ${
    members.length === 1 ? 'is' : 'are'
  } viewing...`

  const typingTitle =
    typing.length === 1
      ? `${typing[0].shortName} is typing...`
      : 'Multiple members are typing'

  const classNameForAvatar = (identity: Identity) => {
    const status = conversation.presence.find((p) => p.member.id === identity.id)?.status
    if (status === 'away') {
      return styles.away
    }
    return null
  }

  return (
    members.length > 0 && (
      <div className={styles.root}>
        {typing.length > 0 && (
          <Tooltip title={typingTitle}>
            <TypingIndicator />
          </Tooltip>
        )}
        <Tooltip title={avatarTitle}>
          <AppAvatarRow
            identities={members}
            size={size}
            classNameForAvatar={classNameForAvatar}
          />
        </Tooltip>
      </div>
    )
  )
}

interface TypingIndicatorProps extends React.HTMLProps<HTMLDivElement> {}

const TypingIndicator: React.FC<TypingIndicatorProps> = React.forwardRef(function (
  { className, ...props },
  ref,
) {
  const styles = useStyles({})
  return (
    <div {...props} ref={ref} className={styles.typing}>
      <span />
      <span />
      <span />
    </div>
  )
})

export default observer(Participant)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
  },
  typing: {
    willChange: 'transform',
    borderRadius: 50,
    display: 'flex',
    marginRight: 5,
    position: 'relative',
    transition: 'all 300ms ease',
    width: 30,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.op.tag.purple.bg,

    '& span': {
      height: 5,
      width: 5,
      float: 'left',
      margin: '0 1px',
      background: theme.palette.op.tag.purple.text,
      display: 'block',
      borderRadius: '50%',
      opacity: 0.4,

      '&:nth-of-type(1)': {
        animation: '1s $blink infinite 0.3333s',
      },
      '&:nth-of-type(2)': {
        animation: '1s $blink infinite 0.6666s',
      },
      '&:nth-of-type(3)': {
        animation: '1s $blink infinite 1s',
      },
    },
  },

  away: {
    opacity: 0.4,
  },

  '@keyframes blink': {
    '50%': { opacity: 1 },
  },
}))
