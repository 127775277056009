import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import Image from '../../../../component/image'
import { DecodableMessageMedia } from '../../../../service/model'

export interface ImageMediaPreviewProps {
  media: DecodableMessageMedia
  onOpen: (media: DecodableMessageMedia) => void
}

const ImageMediaPreview: React.FC<ImageMediaPreviewProps> = function ({ media, onOpen }) {
  const styles = useStyles({})

  const handleClick = function () {
    onOpen(media)
  }

  return (
    <div className={styles.root} onClick={handleClick}>
      <Image media={media} alt="preview" className={styles.image} />
    </div>
  )
}

export default ImageMediaPreview

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 75,
      height: 75,
      borderRadius: 7,
      cursor: 'pointer',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 7,
      pointerEvents: 'none',
    },
  }),
  { name: ImageMediaPreview.name },
)
