import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { SearchIcon } from './icons/Tint/20/General'
import TextInput, { TextInputProps } from '@ui/TextInput'

export interface SearchInputProps extends TextInputProps {}

const SearchInput = React.forwardRef<HTMLInputElement, TextInputProps>(function (
  { className, ...props },
  ref,
) {
  const styles = useStyles({})
  return (
    <div className={cx(styles.root, className)}>
      <SearchIcon className={styles.icon} />
      <TextInput ref={ref} className={styles.search} placeholder="Search" {...props} />
    </div>
  )
})

export default SearchInput

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    left: 10,
    color: theme.palette.op.text.tertiary,
  },
  search: {
    width: '100%',
    borderRadius: 5,
    height: 36,
    padding: '0 10px 0 40px',
  },
}))
