import React, { memo } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
interface SideMenuSectionHeaderProps extends React.HTMLProps<HTMLDivElement> {}

const SideMenuSectionHeader: React.FC<SideMenuSectionHeaderProps> = function ({
  className,
  ...props
}) {
  const styles = useStyles({})
  const T: any = Typography
  return (
    <T
      {...props}
      className={cx(styles.root, className)}
      color="secondary"
      variant="caption1"
      fontWeight="medium"
    />
  )
}

export default memo(SideMenuSectionHeader)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: 2,
    color: theme.palette.op.gray[2],
  },
}))
