// @ts-strict-ignore
import Debug from 'debug'
import { useEffect, useState } from 'react'
import useMutexTimeout from './use-mutex-timeout'

const debug = Debug('op:use-transition')

export type TransitionState =
  | 'enter'
  | 'entering'
  | 'entered'
  | 'exit'
  | 'exiting'
  | 'exited'

/**
 * When enter/exit delay is not desired, we need to fallback to a delay of
 * effectively 0ms, but not actually zero because it may not be enough time to
 * propagate the `enter` and `exit` states.
 */
export const defaultFallbackDelay = 20
export const defaultDelay = 250
export const defaultDuration = 200

export interface UseTransitionOptions {
  enterDelay?: boolean | number
  enterDuration?: number
  exitDelay?: boolean | number
  exitDuration?: number
}

/**
 * Transition state hook that operates on a single boolean open/closed state.
 *
 * @param enter Whether it should transition in or not.
 */
export default function useTransition(
  enter: boolean,
  options: number | UseTransitionOptions = defaultDuration,
  deps: any[] = [],
): TransitionState {
  const [state, setState] = useState<TransitionState>(enter ? 'entered' : 'exited')
  const {
    enterDelay: _enterDelay = false,
    enterDuration,
    exitDelay: _exitDelay = false,
    exitDuration,
  } = typeof options === 'number'
    ? { enterDuration: options, exitDuration: options }
    : options

  const enterDelay =
    typeof _enterDelay === 'number'
      ? _enterDelay
      : _enterDelay
      ? defaultDelay
      : defaultFallbackDelay

  const exitDelay =
    typeof _exitDelay === 'number'
      ? _exitDelay
      : _exitDelay
      ? defaultDelay
      : defaultFallbackDelay

  debug('(enter: %O) (state: %O) (options: %O)', enter, state, {
    enterDelay,
    enterDuration,
    exitDelay,
    exitDuration,
  })

  const startEnter = () => {
    setState('enter')
    startEnteringDelay()
  }

  const startExit = () => {
    setState('exit')
    startExitingDelay()
  }

  const startEntering = () => {
    setState('entering')
    startEnteredDuration()
  }

  const startExiting = () => {
    setState('exiting')
    startExitedDuration()
  }

  const startEntered = () => setState('entered')
  const startExited = () => setState('exited')

  const [startEnteringDelay, startExitingDelay, cancelDelay] = useMutexTimeout(
    [enterDelay, exitDelay],
    [startEntering, startExiting],
    deps,
  )

  const [startEnteredDuration, startExitedDuration, cancelDuration] = useMutexTimeout(
    [enterDuration, exitDuration],
    [startEntered, startExited],
    deps,
  )

  const cancel = () => {
    cancelDelay()
    cancelDuration()
  }

  useEffect(() => {
    debug('enter value changed (enter: %O)', enter)
    cancel()

    if (enter) {
      if (state === 'exit' || state === 'exiting') {
        startEntering()
      } else if (state !== 'entered') {
        startEnter()
      }
    } else {
      if (state === 'enter' || state === 'entering') {
        startExiting()
      } else if (state !== 'exited') {
        startExit()
      }
    }

    return cancel
  }, [enter])

  return state
}
