// @ts-strict-ignore
import { action, makeObservable, observable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import { Model } from './base'
import { GooglePeopleSyncProgressState } from '../transport/websocket'

export interface IContactSettings {
  createdAt: number
  id: string
  meta: { hasRefreshToken: boolean }
  hasRefreshToken: boolean
  source: string
  sourceName: string
  type: string
  updatedAt: number
  userId: string
}

export class ContactSettings implements IContactSettings, Model {
  createdAt: number = null
  id: string = null
  meta: { hasRefreshToken: boolean } = null
  hasRefreshToken: boolean = null
  source: string = null
  sourceName: string = null
  type: string = null
  updatedAt: number = null
  userId: string = null
  resyncStatus: GooglePeopleSyncProgressState | null = null

  constructor(private root: Service) {
    makeObservable(this, {
      resyncStatus: observable.ref,
      resync: action,
    })
  }

  resync() {
    this.resyncStatus = 'started'

    return this.root.transport.contacts.settings.resync(this).catch(
      action((error) => {
        // TODO: this request can fail, but the job doesn't
        this.resyncStatus = null
        throw error
      }),
    )
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.updatedAt = parseDate(json.updatedAt)
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      hasRefreshToken: this.hasRefreshToken,
      source: this.source,
      sourceName: this.sourceName,
      type: this.type,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}
