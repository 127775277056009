// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import IconButton from '../../../component/icon-button'
import { ArrowLeftIcon, SearchIcon } from '../../../component/icons/Tint/20/General'
import { CircularProgress } from '../../../component/progress'
import Typography from '@ui/Typography'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import { typography } from '../../../theme'

interface InputHeaderProps extends React.HTMLProps<HTMLInputElement> {
  rootClassName?: string
  loading?: boolean
  accessory?: React.ReactNode
  inputShadow?: React.ReactNode
  onBack?: () => void
}

export const InputHeader: React.FC<InputHeaderProps> = React.forwardRef(function (
  { loading, onBack, rootClassName, children, accessory, inputShadow, ...props },
  ref,
) {
  const styles = useStyles({})

  useKeyboardShortcuts({
    node: document,
    name: 'InputHeadr',
    handler: (shortcut, event) => {
      if (shortcut == 'Escape' && onBack) {
        onBack()
        event.preventDefault()
      }
    },
  })

  return (
    <div className={cx(styles.root, rootClassName)}>
      {loading ? (
        <Typography color="textSecondary" className={styles.icon}>
          <CircularProgress size={20} color="inherit" />
        </Typography>
      ) : onBack ? (
        <IconButton
          title="Go back"
          shortcut="Esc"
          icon={<ArrowLeftIcon />}
          className={styles.back}
          onClick={onBack}
        />
      ) : (
        <Typography color="textSecondary" className={styles.icon}>
          <SearchIcon />
        </Typography>
      )}
      {children}
      <div className={styles.inputWrapper}>
        <div className={styles.shadow}>{inputShadow}</div>
        <input ref={ref} {...props} className={styles.input} />
      </div>
      {accessory}
    </div>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
  },
  inputWrapper: {
    flex: 1,
    width: 'fit-content',
    position: 'relative',
  },
  input: {
    ...typography.headline,
    background: 'none',
    position: 'relative',
    border: 'none',
    outline: 'none',
    height: 64,
    width: '100%',
    padding: 0,
    margin: 0,
  },
  shadow: {
    ...typography.headline,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  back: {
    marginRight: 10,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 10,
    width: 30,
  },
  loading: {
    color: theme.palette.op.text.tertiary,
  },
}))
