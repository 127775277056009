// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useImperativeHandle, useRef } from 'react'
import { fromEvent } from 'rxjs'
import { useObservable, useReaction, useStore } from '../../lib/hooks'
import useCombinedRefs from '../../lib/use-combined-ref'
import useDebug from '../../lib/use-debug'
import useDraggable from '../../lib/use-draggable'
import { useSize } from '../../lib/use-size'
import { ScrollViewStore } from './store'

export interface ScrollViewRef {
  scrollTo(scroll: ScrollToOptions): void
}

interface ScrollViewProps extends React.HTMLProps<HTMLDivElement> {
  rootRef?: React.MutableRefObject<HTMLDivElement>
  contentClassName?: string
  hideScroll?: boolean
  onResize?: (rect: DOMRect) => void
  onPassiveScroll?: (point: { left: number; top: number }) => void
}

const ScrollView: React.ForwardRefRenderFunction<ScrollViewRef, ScrollViewProps> =
  function (
    {
      name,
      rootRef,
      className,
      children,
      contentClassName,
      hideScroll,
      onResize,
      onPassiveScroll,
      ...props
    },
    outerRef,
  ) {
    const styles = useStyles({})
    const debug = useDebug(`op:scrollview:@${name}`)
    const store = useStore(
      () => new ScrollViewStore({ hideScroll, onResize, onPassiveScroll, name }),
      [hideScroll, onResize, onPassiveScroll, name],
    )
    const root = useCombinedRefs<HTMLDivElement>(rootRef)
    const content = useRef<HTMLDivElement>()
    const scrollContainer = useRef<HTMLDivElement>()
    const knob = useRef<HTMLDivElement>()

    useSize(root, store.setSize, [store])

    useSize(content, store.setContentSize, [store])

    useObservable(
      () => fromEvent(scrollContainer.current, 'scroll', { passive: true }),
      () =>
        store.onScroll(
          scrollContainer.current.scrollLeft,
          scrollContainer.current.scrollTop,
        ),
      [store],
    )

    useReaction(
      () => store.scrollTo,
      ({ top }) => {
        debug(`useReaction scrollOffset: ${top}`)
        scrollContainer.current.scrollTo({ top, left: 0 })
      },
      { fireImmediately: true },
      [store],
    )

    useReaction(
      () => store.knobHeight,
      (height) => (knob.current.style.height = `${height}px`),
      { fireImmediately: true },
      [store],
    )

    useReaction(
      () => store.knobOffset,
      (offset) => (knob.current.style.top = `${offset}px`),
      { fireImmediately: true },
      [store],
    )

    useReaction(
      () => store.knobHidden,
      (hidden) => (knob.current.style.opacity = `${hidden ? 0 : 1}`),
      { fireImmediately: true },
      [store],
    )

    useReaction(
      () => store.isDragging,
      (dragging) =>
        dragging
          ? knob.current.classList.add(styles.dragging)
          : knob.current.classList.remove(styles.dragging),
      { fireImmediately: true },
      [store],
    )

    useDraggable(
      {
        node: knob,
        onDrag: store.onKnobDrag,
        onStart: store.onKnobDragStart,
        onEnd: store.onKnobDragEnd,
      },
      [store],
    )

    useImperativeHandle(
      outerRef,
      () => ({
        scrollTo: (scroll) => {
          store.setScrollOffset(scroll?.top ?? 0)
        },
      }),
      [store],
    )

    return (
      <div
        {...props}
        ref={root}
        className={cx(styles.root, className)}
        onMouseEnter={store.onMouseEnter}
        onMouseLeave={store.onMouseLeave}
      >
        <div ref={scrollContainer} className={styles.scrollContainer}>
          <div ref={content} className={contentClassName}>
            {children}
          </div>
        </div>
        <div className={styles.bar}>
          <div ref={knob} className={styles.knob} />
        </div>
      </div>
    )
  }

export default observer(ScrollView, { forwardRef: true })

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollContainer: {
    overflow: 'auto',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  bar: {
    position: 'absolute',
    top: 3,
    right: 2,
    bottom: 3,
    width: 7,
  },
  knob: {
    position: 'absolute',
    width: 7,
    height: 100,
    background: theme.palette.op.gray[4],
    borderRadius: 4,
    transition: theme.transitions.create(['background', 'opacity'], { duration: 100 }),

    '&:hover': {
      background: theme.palette.op.gray[3],
    },
  },
  dragging: {
    background: theme.palette.op.gray[3],
  },
}))
