import React, { ReactNode } from 'react'
import Button from '@src/component/button-v2'
import Typography from '@ui/Typography'
import { makeStyles } from '@material-ui/core'

interface IntegrationStatusProps {
  user: string
  onDisconnect: () => void
  importStatus?: ReactNode
}

function IntegrationStatus({ user, onDisconnect, importStatus }: IntegrationStatusProps) {
  const styles = useStyles()
  return (
    <div className={styles.integrationStatus}>
      <div className={styles.integrationStatusInfo}>
        <Typography variant="body">
          Connected to{' '}
          <Typography
            component="span"
            variant="body"
            className={styles.integrationStatusInfoUser}
          >
            {user}
          </Typography>
        </Typography>
        {importStatus}
      </div>
      <Button variant="outlined" color="textPrimary" onClick={onDisconnect}>
        Disconnect
      </Button>
    </div>
  )
}

export default IntegrationStatus

const useStyles = makeStyles((theme) => ({
  integrationStatus: {
    display: 'flex',
    padding: '18px 24px',
    borderRadius: 10,
    boxShadow: `inset 0 0 0 1px ${theme.palette.op.hover.primary}`,
    backgroundColor: theme.palette.op.gray[6],
    width: '100%',
  },
  integrationStatusInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 4,
    flex: 1,
  },
  integrationStatusInfoUser: {
    fontWeight: 550,
  },
  integrationsStatusConnection: {},
  integrationsStatusLastStatus: {},
}))
