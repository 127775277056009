// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MenuItem } from '../../../popover-menu'
import Typography from '@ui/Typography'
import { SnippetsIcon, GiphyIcon } from '../../../icons/Tint/20/General'
import useKeyStepper, { KeyMappings } from '../../../../lib/use-key-stepper'
import useKeyboardShortcuts from '../../../../lib/use-keyboard-shortcuts'
import Snippets from './snippets'
import Giphy from './giphy'
import Controller from '../../controller'
import { CommandMode } from '../controller'

export interface CommandsProps {
  controller: Controller
}

type CommandIcons = { [K in CommandMode]: () => React.ReactNode }

const COMMAND_ICONS: CommandIcons = {
  snippets: () => <SnippetsIcon />,
  giphy: () => <GiphyIcon />,
  /* contact: () => <ContactCardIcon />, */
}

const Commands: React.FC<CommandsProps> = function ({ controller }) {
  const styles = useStyles({})
  const currentMode = controller.menu.commandMode
  const filteredCommands = controller.menu.filteredCommands

  const modes = useMemo(
    (): CommandMode[] => filteredCommands.map((command) => command.mode),
    [filteredCommands],
  )

  useKeyboardShortcuts({
    name: 'Commands',
    node: controller.editor.ref.current,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (currentMode && shortcut === 'Escape') {
        controller.menu.close()
        event.preventDefault()
      }
    },
    dep: [controller, currentMode],
  })

  const { getItemProps, selectedIndex } = useKeyStepper({
    node: controller.editor.ref.current,
    keys: { ...KeyMappings.VERTICAL, select: ['Enter', 'Tab'] },
    filter: (_, e) => !e.defaultPrevented,
    items: modes,
    name: 'component/input-bar/input-menu/commands',
    handleSelect: action((item) => (controller.menu.commandMode = item)),
    deps: [controller],
  })

  switch (currentMode) {
    case 'snippets':
      return <Snippets controller={controller} />
    case 'giphy':
      return <Giphy controller={controller} />
    default:
      return (
        <>
          {filteredCommands.length > 0
            ? filteredCommands.map(({ mode, text }, index) => {
                return (
                  <MenuItem
                    key={mode}
                    icon={COMMAND_ICONS[mode]()}
                    height="medium"
                    highlighted={selectedIndex === index}
                    {...getItemProps(index)}
                  >
                    <div className={styles.command}>
                      <Typography color="textPrimary" className={styles.mode}>
                        /{mode}
                      </Typography>
                      <Typography color="textSecondary">{text}</Typography>
                    </div>
                  </MenuItem>
                )
              })
            : null}
        </>
      )
  }
}

export default observer(Commands)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    command: {
      display: 'flex',
    },
    mode: {
      flex: 1,
    },
    empty: {
      textAlign: 'center',
    },
  }),
  { name: Commands.name },
)
