import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { isMac } from '../..'
import {
  ActivityIcon,
  BlockIcon,
  BuildingIcon,
  ContactsIcon,
  CreditCardIcon,
  FolderIcon,
  FilterSettingDefaultIcon,
  GiftIcon,
  IntegrationsIcon,
  NumberHashIcon,
  ProfileIcon,
  TeamIcon,
  WebhookIcon,
} from '../../component/icons/Tint/20/General'
import Typography from '@ui/Typography'
import Badge from '@ui/Badge'

interface SideMenuProps extends React.HTMLProps<HTMLDivElement> {}

const SideMenu: React.FC<SideMenuProps> = function ({ className, ...props }) {
  const styles = useStyles({})
  const { service, history } = useAppStore()
  const user = service.user.current.asMember
  const pathname = history.location.pathname

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <SectionTitle>Workspace</SectionTitle>
        </div>
        {user.isAdmin && (
          <Item
            selected={pathname.startsWith('/settings/company')}
            onClick={() => history.push('/settings/company')}
            icon={<BuildingIcon />}
          >
            <RowText>General</RowText>
          </Item>
        )}
        <Item
          selected={pathname.startsWith('/settings/members')}
          onClick={() => history.push('/settings/members')}
          icon={<TeamIcon />}
        >
          <RowText>Members</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/groups')}
          onClick={() => history.push('/settings/groups')}
          icon={<FolderIcon />}
        >
          <RowText>Groups</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/phone-numbers')}
          onClick={() => history.push('/settings/phone-numbers')}
          icon={<NumberHashIcon />}
        >
          <RowText>Phone numbers</RowText>
        </Item>
        {user.isAdmin && (
          <>
            <Item
              selected={pathname.startsWith('/settings/integrations')}
              onClick={() => history.push('/settings/integrations')}
              icon={<IntegrationsIcon />}
            >
              <RowText>Integrations</RowText>
            </Item>
            <Item
              selected={pathname.startsWith('/settings/billing')}
              onClick={() => history.push('/settings/billing')}
              icon={<CreditCardIcon />}
            >
              <RowText>Plan & billing</RowText>
            </Item>
            <Item
              selected={pathname.startsWith('/settings/webhooks')}
              onClick={() => history.push('/settings/webhooks')}
              icon={<WebhookIcon />}
            >
              <RowText>
                Webhooks <Badge label="Beta" variant="primary" />
              </RowText>
            </Item>
          </>
        )}

        <div className={styles.header}>
          <SectionTitle>Your account</SectionTitle>
        </div>
        <Item
          selected={pathname.startsWith('/settings/profile')}
          onClick={() => {
            history.push('/settings/profile')
          }}
          icon={<ProfileIcon />}
        >
          <RowText>Profile</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/preferences')}
          onClick={() => {
            history.push('/settings/preferences')
          }}
          icon={<FilterSettingDefaultIcon />}
        >
          <RowText>Preferences</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/contacts')}
          onClick={() => history.push('/settings/contacts')}
          icon={<ContactsIcon />}
        >
          <RowText>Contacts</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/notifications')}
          onClick={() => history.push('/settings/notifications')}
          icon={<ActivityIcon />}
        >
          <RowText>Notifications</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/referrals')}
          onClick={() => history.push('/settings/referrals')}
          icon={<GiftIcon />}
        >
          <RowText>Referrals</RowText>
        </Item>
        <Item
          selected={pathname.startsWith('/settings/blocklist')}
          onClick={() => history.push('/settings/blocklist')}
          icon={<BlockIcon />}
        >
          <RowText>Blocklist</RowText>
        </Item>
      </div>
    </div>
  )
}

interface ItemProps extends React.HTMLProps<HTMLDivElement> {
  selected: boolean
  icon: React.ReactNode
}

const Item: React.FC<ItemProps> = function ({ icon, selected, children, ...props }) {
  const styles = useStyles({})
  return (
    <div {...props} className={cx(styles.row, selected && styles.selected)}>
      <span className={styles.icon}>{icon}</span>
      {children}
    </div>
  )
}

const SectionTitle: React.FC = ({ children }) => {
  return (
    <Typography fontWeight="medium" variant="caption1" color="textTertiary">
      {children}
    </Typography>
  )
}

const RowText: React.FC = ({ children }) => {
  const styles = useStyles({})
  return (
    <Typography variant="footnote" color="textPrimary" className={styles.rowText}>
      {children}
    </Typography>
  )
}

export default observer(SideMenu)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 248,
    borderRight: `1.5px solid ${theme.palette.op.border.common}`,
    overflow: 'auto',
  },
  row: {
    height: 30,
    borderRadius: 4,
    padding: '0 16px',
    marginTop: '2px',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&::before': {
      content: '""',
      position: 'absolute',
      borderRadius: 'inherit',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },

    '&:hover::before': {
      background: theme.palette.op.hover.primary,
    },
  },
  rowText: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 7,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    transform: 'scale(0.9)',
    color: theme.palette.text.secondary,
  },
  header: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    margin: '16px 16px 0',

    '&:first-child': {
      '-webkit-app-region': isMac ? 'drag' : undefined,
    },
  },
  menu: {
    padding: '0 8px',
  },
  selected: {
    background: theme.palette.op.hover.primary,
  },
  menuSection: {
    outline: 'none',
    marginBottom: '1rem',
  },
}))
