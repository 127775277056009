// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button'
import IconButton from '../../../component/icon-button'
import { MoreIcon, DeleteIcon, MedalIcon } from '../../../component/icons/Tint/20/General'
import Menu, { MenuItem } from '../../../component/popover-menu'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '../../../component/table'
import Typography from '@ui/Typography'
import { capitalize, compareString } from '../../../lib'
import { Collection, EntityPhoneNumber, Member } from '../../../service/model'
import { SharedUserRole, UserPhoneNumber } from '../../../types'
import { Header, Section } from '../common'

interface PhoneNumberUsersProps {
  canEdit: boolean
  members: Collection<Member>
  phoneNumber: EntityPhoneNumber
}

const PhoneNumberUsers: React.FC<PhoneNumberUsersProps> = function ({
  canEdit,
  members,
  phoneNumber,
}) {
  const styles = useStyles({})
  const { command, service, showAlert, history, toast } = useAppStore()

  const sharedUsers: UserPhoneNumber[] =
    phoneNumber?.users.slice().sort((u1, u2) => {
      if (u1.role === 'owner') return -1
      if (u2.role === 'owner') return 1
      return compareString(u1.role, u2.role)
    }) ?? []

  const handleUnassign = (member: Member) => {
    if (!canEdit) return
    if (phoneNumber.users.length === 1) {
      showAlert({
        title: 'Cannot delete',
        body: `You must have at least one user on a phone number. Try adding another user before removing ${member.name}.`,
      })
      return
    }
    phoneNumber.removeMember(member).catch(toast.showError)
  }

  const handleMakeOwner = (member: Member) => {
    if (!canEdit) return
    phoneNumber.makeOwner(member).catch(toast.showError)
  }

  const handleShare = () => {
    if (!canEdit) return

    const maxUsers = service.billing.capabilities.maximumPhoneNumberUsers

    if (phoneNumber.users.length >= maxUsers) {
      showAlert({
        title: 'Share limit reached',
        body: 'You can only share this phone number with 10 teammates on a Standard plan. To add up to 100 teammates to any number, upgrade to Premium.',
        actions: [
          {
            type: 'primary',
            title: 'Compare Plans',
            onClick: () => {
              history.push('/settings/billing/plans')
            },
          },
          { title: 'Cancel' },
        ],
      })
    } else {
      command.present({
        name: 'share phone number',
        phoneNumber,
        onInvite: (id, email, role) => {
          if (id) {
            phoneNumber.addMember(members.get(id), role).catch(toast.showError)
          } else {
            service.member
              .invite({ email, phoneNumberId: phoneNumber.id, role })
              .catch(toast.showError)
          }
        },
      })
    }
  }

  return (
    <Section>
      <Header
        title="Users"
        subtitle="Users always see the inbox in their side menu, receive all notifications by default, can use the phone number, and can view all calls and messages"
        actions={
          canEdit ? (
            <Button onClick={handleShare} color="default" variant="outlined">
              Add a user
            </Button>
          ) : null
        }
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell style={{ width: 100 }}>Access</TableCell>
            <TableCell style={{ width: 30 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sharedUsers.map((user) => (
            <Assignee
              canEdit={canEdit}
              key={user.id}
              member={members.get(user.id)}
              onMakeOwner={handleMakeOwner}
              onDelete={handleUnassign}
              role={user.role}
            />
          ))}
        </TableBody>
      </Table>
    </Section>
  )
}

interface AssigneeProps {
  member: Member
  role: SharedUserRole
  canEdit: boolean
  onDelete?: (member: Member) => void
  onMakeOwner?: (member: Member) => void
}

const Assignee: React.FC<AssigneeProps> = observer(function Assignee({
  member,
  role,
  canEdit,
  onDelete,
  onMakeOwner,
}) {
  const styles = useStyles({})
  const [menuOpen, setMenuOpen] = useState(false)
  const ref = useRef<HTMLButtonElement>()

  const handleDelete = () => {
    onDelete(member)
    setMenuOpen(false)
  }

  const handleMakeOwner = () => {
    onMakeOwner(member)
    setMenuOpen(false)
  }

  return member ? (
    <TableRow>
      <TableCell style={{ display: 'flex', alignItems: 'center' }}>
        <AppAvatar identity={member} size={30} />
        <Typography className={styles.name} variant="footnote">
          {member.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="footnote" color="textSecondary">
          {capitalize(role)}
        </Typography>
      </TableCell>
      <TableCell>
        {canEdit && (
          <>
            <IconButton ref={ref} icon={<MoreIcon />} onClick={() => setMenuOpen(true)} />
            <Menu
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              anchorEl={ref.current}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: -5, horizontal: 'right' }}
            >
              {role !== 'owner' && (
                <MenuItem icon={<MedalIcon />} onClick={handleMakeOwner}>
                  Make owner
                </MenuItem>
              )}
              <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  ) : null
})

export default observer(PhoneNumberUsers)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 50,
  },
  row: {
    display: 'flex',
    height: 50,
    alignItems: 'center',
    padding: '0 16px',
    margin: '0 -16px',
    borderRadius: 7,

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  name: {
    margin: '0 1rem',
  },
  role: {
    flex: 1,
  },
  deleteButton: {},
}))
