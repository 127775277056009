// @ts-strict-ignore
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import { ScrollView } from '@src/component/scrollview'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import SelectToggle from '../../../component/select-toggle'
import { Header } from '../common'
import features from '@src/data/plans'
import { FeatureGroupTitle, FeatureTitle, FeatureValueItem } from './plans-common'
import { capitalize } from '@src/lib'
import Tooltip from '@src/component/tooltip'
import { fonts } from '@src/theme'
import Badge from '@ui/Badge'

interface BillingPlansProps {}

const BillingPlans: React.FC<BillingPlansProps> = function ({}) {
  const styles = useStyles({})
  const { service, history, toast } = useAppStore()
  const premium = service.billing.subscription.isPremium
  const currentBillingInterval = service.billing.subscription.annual
    ? 'yearly'
    : 'monthly'
  const loading = toast.state?.open && toast.state.type === 'loading'
  const [billingInterval, setBillingInterval] = useState<'yearly' | 'monthly'>(
    currentBillingInterval,
  )

  const getPlanHeaderVariant = (
    plan: 'standard' | 'premium',
  ): PlanHeaderProps['variant'] => {
    const differentBillingInterval = currentBillingInterval !== billingInterval
    const currentPlan = premium ? 'premium' : 'standard'
    const isSamePlan = plan === currentPlan

    if (isSamePlan && differentBillingInterval) {
      return 'update'
    }

    if (isSamePlan) {
      return 'current'
    }

    if (currentPlan === 'premium' && plan === 'standard') {
      return 'downgrade'
    }

    return 'upgrade'
  }

  // FIXME: We should retrieve this data from an API in the future.
  const priceStandard = 13
  const legacyPriceStandard = 10
  const pricePremium = 25
  const legacyPricePremium = 20

  const createPlanHeaderProps = (
    plan: 'standard' | 'premium',
  ): Pick<PlanHeaderProps, 'price' | 'discount' | 'isLegacy' | 'variant' | 'onClick'> => {
    const variant = getPlanHeaderVariant(plan)
    let loadingText = ''
    let successText = ''

    switch (variant) {
      case 'downgrade':
        loadingText = `Downgrading your subscription to ${capitalize(plan)}...`
        successText = `Subscription downgraded to ${capitalize(plan)}.`
        break
      case 'upgrade':
        loadingText = `Upgrading your subscription to ${capitalize(plan)}...`
        successText = `Subscription upgraded to ${capitalize(plan)}.`
        break
      case 'update':
        loadingText = `Updating your ${capitalize(plan)} subscription to ${capitalize(
          billingInterval,
        )} billing...`
        successText = `Subscription updated to ${capitalize(billingInterval)} billing.`
        break
    }

    // If their current plan is legacy, then they will always have access to
    // the legacy pricing.
    const hasLegacyPricing = service.billing.subscription.isLegacy

    // The legacy plan is only the standard plan billed monthly.
    const isLegacy =
      hasLegacyPricing && plan === 'standard' && billingInterval === 'monthly'

    const basePrice = plan === 'standard' ? priceStandard : pricePremium
    const discount = billingInterval === 'yearly' ? 20 : 0
    const price = discount ? Math.floor(basePrice * (1 - discount / 100)) : basePrice

    return {
      price,
      discount:
        hasLegacyPricing && plan === 'standard' && billingInterval === 'yearly'
          ? 0
          : discount,
      isLegacy,
      variant,
      onClick: () => {
        toast.showLoading(loadingText)

        service.billing
          .upgrade({ plan, annual: billingInterval === 'yearly' })
          .then(() => toast.show({ message: successText }))
          .catch(toast.showError)
      },
    }
  }

  const handleEnterprise = () => {
    window.open(
      'mailto:sales@openphone.co?subject=OpenPhone Enterprise plan inquiry',
      '_blank',
    )
  }
  const selectToggleOptions = [
    { value: 'monthly', title: 'Monthly' },
    { value: 'yearly', title: 'Yearly' },
  ]

  const handleBack = () => {
    history.push('/settings/billing')
  }

  return (
    <ScrollView className={styles.root}>
      <div className={styles.container}>
        <Header
          backTitle="Back to billing"
          onBack={handleBack}
          title="Plans"
          subtitle="Simple and flexible pricing plans"
        />
        <div className={styles.plans}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td>
                  <SelectToggle
                    options={selectToggleOptions}
                    value={billingInterval}
                    onToggle={(selectedPlan: string) => {
                      setBillingInterval(selectedPlan === 'yearly' ? 'yearly' : 'monthly')
                    }}
                  />
                </td>
                <th scope="col">
                  <PlanHeader
                    name="Standard"
                    legacyPrice={legacyPriceStandard}
                    isLoading={loading}
                    interval={billingInterval}
                    {...createPlanHeaderProps('standard')}
                  />
                </th>
                <th scope="col">
                  <PlanHeader
                    name="Premium"
                    legacyPrice={legacyPricePremium}
                    isLoading={loading}
                    interval={billingInterval}
                    {...createPlanHeaderProps('premium')}
                  />
                </th>
                <th scope="col">
                  <PlanHeader
                    name="Enterprise"
                    discount={0}
                    price={0}
                    legacyPrice={0}
                    onClick={handleEnterprise}
                    variant="enterprise"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {features.map((group) => (
                <React.Fragment key={group.title}>
                  <tr>
                    <th scope="row" colSpan={4}>
                      <FeatureGroupTitle>{group.title}</FeatureGroupTitle>
                    </th>
                  </tr>

                  {group.features.map(({ title, values, ...feature }) => (
                    <tr key={title}>
                      <th scope="row">
                        <FeatureTitle {...feature}>{title}</FeatureTitle>
                      </th>

                      {Object.keys(values).map((plan) => (
                        <td key={plan}>
                          <FeatureValueItem value={values[plan]} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ScrollView>
  )
}

export default observer(BillingPlans)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  container: {
    maxWidth: 956,
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: '48px 40px',
  },
  plans: {
    display: 'flex',
    maxWidth: 752,
    width: 752,
    marginTop: 48,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    borderSpacing: 0,

    '& td, & th': {
      textAlign: 'left',
      verticalAlign: 'top',
      fontWeight: 'normal',
      padding: 0,
    },
  },
  planNames: {
    flex: '0 0 280px',
    padding: '15px 0',
  },
  plan: {
    flex: 1,
    padding: '15px 0',
    borderRadius: 7,

    '& $item': {
      justifyContent: 'center',
    },
  },
  toggleContainer: {
    height: 142,
  },
  addOn: {
    fontSize: 11,
    background: theme.palette.primary.light,
    padding: '2px 8px',
    borderRadius: 20,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  faded: {
    opacity: 0.15,
  },
}))

interface PlanHeaderProps {
  name: string
  price: number
  legacyPrice: number
  discount: number
  variant: 'enterprise' | 'current' | 'downgrade' | 'upgrade' | 'update'
  onClick: () => void
  isLoading?: boolean
  isLegacy?: boolean
  interval?: 'monthly' | 'yearly'
}

function PlanHeader({
  name,
  price,
  legacyPrice,
  discount,
  variant,
  onClick,
  isLoading,
  isLegacy,
  interval = 'yearly',
}: PlanHeaderProps) {
  const styles = usePlanHeaderStyles({ variant })

  let ctaText: string
  let ctaColor: React.ComponentProps<typeof Button>['color'] = 'primary'
  let ctaVariant: React.ComponentProps<typeof Button>['variant'] = 'contained'

  switch (variant) {
    case 'current':
      ctaText = 'Current plan'
      break
    case 'downgrade':
      ctaText = 'Downgrade'
      break
    case 'upgrade':
      ctaText = 'Upgrade'
      break
    case 'update':
      ctaText = 'Update plan'
      break
    case 'enterprise':
      ctaText = 'Contact Sales'
      ctaVariant = 'outlined'
      ctaColor = 'textPrimary'
      break
  }

  return (
    <div className={styles.header}>
      <div className={styles.headline}>
        <span className={styles.name}>{name}</span>
        {discount && !isLegacy ? (
          <Badge
            className={styles.badgeDiscount}
            variant="success"
            label={`${discount}% off`}
          />
        ) : null}
        {isLegacy ? (
          <Tooltip
            placement="top"
            title={`You are “grandfathered” into cheaper pricing for the ${name} plan.`}
          >
            <div className={styles.badgeLegacy}>Legacy</div>
          </Tooltip>
        ) : null}
      </div>
      {variant === 'enterprise' ? (
        <span className={styles.letsTalk}>Let’s talk</span>
      ) : (
        <>
          <div className={styles.price}>
            <span className={styles.currency}>$</span>
            <span className={styles.amount}>{isLegacy ? legacyPrice : price}</span>
            {isLegacy && legacyPrice !== price ? (
              <div className={styles.legacyPrice}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>{price}</span>
              </div>
            ) : null}
          </div>

          <span className={styles.priceHelper}>Per user/per month</span>
          <span className={styles.priceHelper}>Billed {interval}</span>
        </>
      )}
      <Button
        onClick={onClick}
        disabled={variant === 'current' || isLoading}
        color={ctaColor}
        variant={ctaVariant}
        height={36}
        className={styles.button}
      >
        {ctaText}
      </Button>
    </div>
  )
}

const usePlanHeaderStyles = makeStyles<Theme, Partial<PlanHeaderProps>>(
  (theme: Theme) => ({
    header: {
      width: 128,
      height: 142,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      height: 22,
    },
    name: {
      fontSize: 15,
      fontWeight: 550,
      lineHeight: '20px',
    },
    badgeDiscount: {
      marginLeft: 7,
    },
    badgeLegacy: {
      display: 'inline-block',
      height: 22,
      marginLeft: 7,
      padding: '4px 5px',
      borderRadius: 4,
      backgroundColor: theme.palette.op.tag.purple.bg,
      color: theme.palette.op.tag.purple.text,
      fontSize: '11px',
      fontWeight: 550,
    },
    price: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 2,
    },
    legacyPrice: {
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: 6,
      '& $currency, & $amount': {
        color: theme.palette.op.gray[3],
      },
      '& $amount': {
        textDecoration: 'line-through',
      },
    },
    currency: {
      fontWeight: 650,
      fontSize: 13,
      lineHeight: '16px',
      color: theme.palette.op.gray[1],
      marginTop: 5,
    },
    amount: {
      fontFamily: fonts.header,
      fontWeight: 600,
      fontSize: 35,
      lineHeight: '40px',
      color: theme.palette.op.gray[1],
      marginLeft: 2,
    },
    priceHelper: {
      fontWeight: 450,
      fontSize: 11,
      lineHeight: '14px',
      color: theme.palette.op.gray[2],
    },
    letsTalk: {
      fontFamily: fonts.header,
      fontWeight: 600,
      fontSize: 29,
      lineHeight: '32px',
      color: theme.palette.op.gray[1],
      marginTop: 4,
    },
    button: ({ variant }) => ({
      fontSize: 13,
      fontWeight: ['upgrade', 'downgrade'].includes(variant) ? 650 : 550,
      marginTop: 'auto',

      '&:disabled': {
        color: theme.palette.op.gray[2],
        backgroundColor: theme.palette.op.hover.primary,
      },
    }),
  }),
)
