// @ts-strict-ignore
import React, { Dispatch, SetStateAction } from 'react'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core'
import IconButton from '../../../component/icon-button'
import {
  CheckBoxTickIcon,
  ContactInfoNumberIcon,
  DeleteIcon,
} from '../../../component/icons/Tint/16/General'
import Typography from '@ui/Typography'
import { SelectSearch, SelectSearchOptionValue } from '../../../component'
import { useAppStore } from '@src/app/context'
import { observer } from 'mobx-react-lite'
import { Invitation, RoleName } from '../../..'
import cx from 'classnames'
import SelectRole from './SelectRole'

interface OptionType {
  name: string
  symbol?: string
  value: string
}
interface InviteeListProps {
  items: Invitation[]
  setItems: Dispatch<SetStateAction<Invitation[]>>
}

const newNumberOption = {
  name: 'New number',
  value: undefined,
}

const InviteeList = ({ items, setItems }: InviteeListProps) => {
  const { service, inboxes } = useAppStore()
  const theme = useTheme()
  const styles = useStyles()
  const handleDeleteRow = (index: number) => {
    setItems((members: Invitation[]) => members.filter((_, at) => at !== index))
  }

  const roleBasedPhoneNumbersOptions: OptionType[] = service.user.current.asMember.isAdmin
    ? service.organization.phoneNumber.collection.list.map((phoneNumber) => {
        return {
          name: phoneNumber.name,
          symbol: phoneNumber.symbol,
          value: phoneNumber.id,
        }
      })
    : inboxes.all.list
        .filter((inbox) => !!inbox.phoneNumber)
        .map((inbox) => {
          return {
            name: inbox.phoneNumber.name,
            symbol: inbox.phoneNumber.symbol,
            value: inbox.phoneNumber.id,
          }
        })

  const phoneNumbersOptions: OptionType[] = [
    newNumberOption,
    ...roleBasedPhoneNumbersOptions,
  ]

  const handleNumberAssignmentChange =
    (index: number) => (option: SelectSearchOptionValue) => {
      setItems((prevItems) => {
        const newItems = prevItems.slice()
        newItems[index].phoneNumberId = option.value
        return newItems
      })
    }

  if (items.length === 0) {
    return null
  }
  return (
    <TableContainer className={styles.root}>
      <Table>
        <TableHead>
          <TableRow>
            <Cell padding="none">Email</Cell>
            <Cell align="left">Number assignment</Cell>
            <Cell align="left">Role</Cell>
            <Cell align="left"></Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((member, index) => {
            const defaultValue = phoneNumbersOptions.find(
              (option) => option.value === member.phoneNumberId,
            )
            return (
              <TableRow key={index}>
                <Cell align="left" component="th">
                  {member.email}
                </Cell>
                <Cell align="left" style={{ maxWidth: 200 }} width="200">
                  <SelectSearch<OptionType>
                    getOptionName={(option: OptionType) =>
                      option.name === newNumberOption.name
                        ? option.name
                        : `${option.symbol} ${option.name}`
                    }
                    onChange={handleNumberAssignmentChange(index)}
                    dropdownProps={{
                      placeholder: 'Search phone numbers...',
                    }}
                    renderOption={({ option, index, rootOptionProps }) => {
                      // ok. this is hack. but no idea about a better one. mui you know 🤷
                      const isSelected = rootOptionProps['aria-selected']
                      return (
                        <div
                          {...rootOptionProps}
                          key={index}
                          className={cx(
                            styles.numberAssignmentOption,
                            isSelected && styles.numberAssignmentOptionSelected,
                          )}
                        >
                          <div className={styles.nameContainer}>
                            {newNumberOption === option ? (
                              <ContactInfoNumberIcon
                                color={theme.palette.op.tag.purple.text}
                                className={styles.optionSymbol}
                              />
                            ) : (
                              <Typography
                                variant="footnote"
                                nowrap
                                className={styles.optionSymbol}
                              >
                                {option.symbol}
                              </Typography>
                            )}
                            <Typography
                              variant="footnote"
                              nowrap
                              className={cx(
                                isSelected && styles.numberAssignmentOptionSelectedTxt,
                              )}
                            >
                              {option.name}
                            </Typography>
                          </div>
                          {isSelected && (
                            <CheckBoxTickIcon
                              className={styles.numberAssignmentOptionSelectedIcon}
                            />
                          )}
                        </div>
                      )
                    }}
                    value={defaultValue}
                    options={phoneNumbersOptions}
                  />
                </Cell>
                <Cell align="left" width="120">
                  <SelectRole
                    value={member.role}
                    renderValue={(value: string) => (
                      <Typography variant="footnote" className={styles.roleLabel}>
                        {value}
                      </Typography>
                    )}
                    MenuProps={{
                      classes: { paper: styles.paperRoot },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(
                      event: React.ChangeEvent<{
                        name: string
                        value: RoleName
                      }>,
                    ) =>
                      setItems((items) => {
                        const newItems = items.slice()
                        newItems[index].role = event.target.value
                        return newItems
                      })
                    }
                  />
                </Cell>
                <Cell align="right" width="30">
                  <IconButton
                    onClick={() => handleDeleteRow(index)}
                    icon={<DeleteIcon />}
                    title="Remove invite"
                  />
                </Cell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {
      marginTop: 37,
    },
    roleLabel: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      textTransform: 'capitalize',
    },
    nameContainer: {
      display: 'flex',
    },
    optionSymbol: {
      paddingRight: 9,
      boxSizing: 'content-box',
    },
    numberAssignmentOption: {
      marginTop: 2,
      padding: '7px 11px',
      width: 232,
      height: 30,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 5,
      cursor: 'pointer',

      '&[data-focus="true"]': {
        backgroundColor: theme.palette.op.tag.purple.bgHover,
      },
    },
    numberAssignmentOptionSelected: {
      backgroundColor: theme.palette.op.tag.purple.bg,
    },
    numberAssignmentOptionSelectedTxt: {
      color: theme.palette.op.tag.purple.text,
    },
    numberAssignmentOptionSelectedIcon: {
      color: theme.palette.op.tag.purple.text,
    },
    paperRoot: {
      '& ul': {
        border: `1.5px solid ${theme.palette.op.border.common}`,
        padding: '4px 0px',
        borderRadius: 8,
      },
    },
  }),
  { name: InviteeList.name },
)

const Cell = withStyles((theme) => ({
  root: {
    padding: '10px 0',
    border: 'none',
  },
  head: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  footer: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))(TableCell)

export default observer(InviteeList)
