import React from 'react'
import { Identity } from '@src/service/model'
import AvatarGroup, { type AvatarGroupProps } from '@ui/AvatarGroup'
import { observer } from 'mobx-react-lite'
import { AppAvatar } from '../app-avatar'

export interface AppAvatarGroupProps extends Omit<AvatarGroupProps, 'children'> {
  identities: Identity[]
}

const AppAvatarGroup = observer(({ identities, ...props }: AppAvatarGroupProps) => {
  return (
    <AvatarGroup {...props}>
      {identities.map((identity) => (
        <AppAvatar
          key={identity['phoneNumber'] ?? identity.id}
          identity={identity}
          hideStatus={true}
        />
      ))}
    </AvatarGroup>
  )
})

AppAvatarGroup.displayName = 'AppAvatarGroup'

export default AppAvatarGroup
