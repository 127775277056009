// @ts-strict-ignore
import { IReactionPublic, IReactionOptions, reaction, when } from 'mobx'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'
import { IUiStore, Lambda } from '../types'

export function useStore<T extends IUiStore>(initializer: () => T, deps: any[]): T {
  const store = useMemo(initializer, deps)
  useEffect(() => {
    return () => store.tearDown?.()
  }, [store])

  return store
}

type ObservableCreator<T> = () => undefined | BehaviorSubject<T> | Observable<T>

export function useObservable<T>(
  observable: BehaviorSubject<T> | Observable<T> | ObservableCreator<T>,
  handler: (value: T) => void,
  deps: any[] = [],
  debug?: string,
): void {
  useEffect(() => {
    const obs = typeof observable === 'function' ? observable() : observable
    if (!obs) return
    const subs = obs.subscribe(handler)
    return () => {
      subs.unsubscribe()
    }
  }, deps)
}

export function useObservableState<T>(
  observable: BehaviorSubject<T> | Observable<T> | ObservableCreator<T>,
  initialValue?: T,
  deps: any[] = [],
  debug?: string,
): T {
  const observableRef = useRef<BehaviorSubject<T> | Observable<T>>()
  if (!observableRef.current) {
    if (typeof observable === 'function') {
      observableRef.current = observable()
    } else {
      observableRef.current = observable
    }
  }
  if (observableRef.current instanceof BehaviorSubject) {
    initialValue = observableRef.current.value
  }
  const [value, setValue] = useState<T>(initialValue)

  useLayoutEffect(() => {
    observableRef.current = typeof observable === 'function' ? observable() : observable
    if (observableRef.current instanceof BehaviorSubject) {
      setValue(observableRef.current.value)
    }
    const subs = observableRef.current.subscribe(setValue)
    return () => {
      subs.unsubscribe()
    }
  }, deps)

  return value
}

export function useReaction<T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: T, r: IReactionPublic) => void,
  options: IReactionOptions<any, boolean> = {},
  deps: any[] = [],
) {
  useEffect(() => reaction(expression, effect, options), deps)
}

export function useWhen<T>(
  expression: () => boolean,
  effect: Lambda,
  opts: IReactionOptions<any, boolean> & { deps?: any[] } = {},
) {
  const { deps = [], ...options } = opts
  useEffect(() => when(expression, effect, options), deps)
}

export function useDebouncedValue<T>(handler: () => T, delay: number = 0): T {
  const [value, setValue] = useState(handler())
  const timeoutRef = useRef(null)
  useReaction(handler, (val) => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => setValue(val), delay)
  })
  return value
}

/**
 * After the very first value assigned to it, it will only return a value if it's
 * not null, otherwise it keeps the last value
 * @param handler
 */
export function useNonNullable<T>(handler: () => T, deps: any[]): T {
  const [value, setValue] = useState(handler())
  useReaction(
    handler,
    (val) => {
      if (val != null) {
        setValue(val)
      }
    },
    { fireImmediately: true },
    deps,
  )
  return value
}
