// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { forwardRef, FC } from 'react'
import { typography } from '../../../theme'

interface InputProps extends React.HTMLProps<HTMLInputElement> {}

const Input: FC<InputProps> = forwardRef(({ className, ...props }, ref) => {
  const styles = useStyles({})
  return <input ref={ref} className={cx(styles.root, className)} {...props} />
})

export default Input

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...typography.callout,
    width: '100%',
    background: 'none',
    border: 'none',
    outline: 'none',
    color: theme.palette.op.text.primary,
    padding: '3px 15px',
    margin: '5px 0',

    '&::placeholder': {
      color: theme.palette.op.text.placeholder,
    },
  },
}))
