// @ts-strict-ignore
import { NoDataMessage } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import { compareString } from '../../..'
import Button from '../../../component/button-v2'
import { Header, Page } from '../common'
import GroupRow from './GroupRow'

const SettingsGroups = () => {
  const { service, command, workspace } = useAppStore()
  const user = service.user.current.asMember

  const handleCreateGroup = () => {
    command.present({ name: 'edit group' })
  }

  const sortedGroups = useMemo(
    () =>
      [...workspace.groups].sort((group1, group2) =>
        compareString(group1.name, group2.name, { caseInsensitive: true }),
      ),
    [workspace.groups],
  )

  const createGroupButton = (
    <Button variant="contained" color="primary" onClick={handleCreateGroup}>
      Create a group
    </Button>
  )

  return sortedGroups.length === 0 ? (
    <Page centered>
      <NoDataMessage
        icon="📂"
        title="Organize your team"
        description="Member groups let you conveniently mention departments or project teams all at once, by organizing them into a group."
        actions={
          user?.isAdmin ? (
            createGroupButton
          ) : (
            <Button disabled variant="contained">
              Only Admins and Owners can create groups
            </Button>
          )
        }
      />
    </Page>
  ) : (
    <Page>
      <Header
        title="Member groups"
        subtitle="View your workspace groups"
        actions={user?.isAdmin ? createGroupButton : null}
      />
      {sortedGroups.map((group) => (
        <GroupRow key={group.id} group={group} />
      ))}
    </Page>
  )
}

export default observer(SettingsGroups)
