// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useCallback } from 'react'
import IconButton from './icon-button'
import Popover from './popover'
import useKeyboardShortcuts from '../lib/use-keyboard-shortcuts'
import useMultipleRefs from '../lib/use-multiple-refs'

export type HandlerEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent

export interface Action {
  id: string
  icon: React.ReactNode
  title?: React.ReactNode
  shortcut?: string
  handler?(event: HandlerEvent, buttonEl: HTMLButtonElement): void
}

export interface FloatingActionsMenuProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  open: boolean
  actions: Action[]
  openDelay?: boolean | number
  closeDelay?: boolean | number
}

const FloatingActionsMenu: React.FC<FloatingActionsMenuProps> = React.forwardRef(
  ({ open, actions, openDelay = true, closeDelay = true, ...props }, ref) => {
    const styles = useStyles({})
    const actionIds = actions.map((action) => action.id)
    const buttonRefs = useMultipleRefs<HTMLButtonElement, string>(actionIds)

    useKeyboardShortcuts({
      name: 'FloatingActionsMenu',
      node: document,
      handler: (shortcut, event) => {
        if (!open) return
        const action = actions.find((action) => action.shortcut === shortcut)
        if (!action) return
        event.preventDefault()
        handleAction(action, event)
      },
      dep: [open, actions],
    })

    const handleAction = useCallback((action: Action, event: HandlerEvent) => {
      action.handler?.(event, buttonRefs.current[action.id].current)
    }, [])

    const createActionHandler = useCallback(
      (action: Action) => (event: HandlerEvent) => handleAction(action, event),
      [],
    )

    return (
      <Popover
        ref={ref}
        openDelay={openDelay}
        closeDelay={closeDelay}
        open={open}
        {...props}
        className={cx(styles.root, props.className)}
      >
        {actions.map((action) => (
          <IconButton
            ref={buttonRefs.current[action.id]}
            key={action.id}
            title={action.title}
            icon={action.icon}
            shortcut={action.shortcut?.replace(/^Digit|Key/g, '')}
            tooltipPlacement="top"
            onClick={createActionHandler(action)}
          />
        ))}
      </Popover>
    )
  },
)

export default FloatingActionsMenu

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& > *:not(:last-child)': {
        marginRight: 2,
      },
    },
  }),
  { name: FloatingActionsMenu.name },
)
