import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import useKeyStepper from '../../../lib/use-key-stepper'
import { SearchItemType } from '../../search/store'
import { Content } from '../common-v2'
import { FromRow, IdentityRow, InboxRow, SearchRow } from './row'

interface SuggestionsProps {}

const Suggestions: React.FC<SuggestionsProps> = function ({}) {
  const styles = useStyles({})
  const { search } = useAppStore()

  const { selectedIndex, getItemProps, setSelectedIndex } = useKeyStepper<SearchItemType>(
    {
      items: search.suggestions,
      name: 'command/search/suggestions',
      handleSelect: (item) => {
        if (item.type === 'search') {
          search.runSearch()
        } else if (item.type === 'identity') {
          search.setIdentity(item.identity)
        } else if (item.type === 'in') {
          search.setTerm('')
          search.addInbox(item.phoneNumber)
          setSelectedIndex(0)
        } else if (item.type === 'from') {
          search.setTerm('')
          search.addFrom(item.identity)
          setSelectedIndex(0)
        }
      },
    },
  )

  return (
    <Content>
      {search.suggestions.map((item, index) =>
        item.type === 'search' ? (
          <SearchRow
            key={item.term}
            index={index}
            selected={selectedIndex === index}
            getItemProps={getItemProps}
            term={item.term}
          />
        ) : item.type === 'in' ? (
          <InboxRow
            index={index}
            key={item.phoneNumber.id}
            selected={selectedIndex === index}
            getItemProps={getItemProps}
            phoneNumber={item.phoneNumber}
          />
        ) : item.type === 'from' ? (
          <FromRow
            index={index}
            key={item.identity.id}
            selected={selectedIndex === index}
            getItemProps={getItemProps}
            identity={item.identity}
          />
        ) : (
          item.type === 'identity' && (
            <IdentityRow
              index={index}
              key={item.identity.id}
              selected={selectedIndex === index}
              getItemProps={getItemProps}
              identity={item.identity}
            />
          )
        ),
      )}
    </Content>
  )
}

export default observer(Suggestions)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
