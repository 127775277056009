// @ts-strict-ignore
import React, { useCallback, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useBackdropClick, useEditor } from '.'
import keyboard from '../../../lib/keyboard'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import Input from './input'
import { List, ListItem } from './list'

export interface EditContactItemNameProps {
  defaultValue: string
  onSave: (name: string) => void
  onDelete: () => void
}

const options: ('Save' | 'Delete')[] = ['Save', 'Delete']

const EditContactItemName: React.FC<EditContactItemNameProps> = function ({
  defaultValue,
  onSave,
  onDelete,
}) {
  const [, hide] = useEditor()
  const rootRef = useRef<HTMLDivElement>(null)
  const [input, setInput] = useInputState(defaultValue)

  const handleSave = () => {
    onSave(input)
    hide()
  }

  const handleDelete = () => {
    onDelete()
    hide()
  }

  useBackdropClick(handleSave, [input])

  const { getItemProps, selectedIndex } = useKeyStepper({
    items: options,
    node: rootRef.current,
    defaultSelectedIndex: 0,
    name: 'contact/editor/name',
    handleSelect: (item) => {
      if (item === 'Save') {
        handleSave()
      } else if (item == 'Delete') {
        handleDelete()
      }
    },
    deps: [input],
  })

  return (
    <div ref={rootRef}>
      <Input
        autoFocus
        type="text"
        value={input}
        placeholder="Enter a name"
        onChange={setInput}
        onKeyDown={keyboard.onEnterOrTab(handleSave)}
      />
      <div>
        <List>
          {options.map((item, index) => (
            <ListItem
              key={item}
              isSelected={selectedIndex === index}
              {...getItemProps(index)}
            >
              {item}
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}

export default observer(EditContactItemName)
