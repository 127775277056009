import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../../component/button'
import { fromQueryString, toQueryString } from '../../lib/query-string'
import { AnimatingLogo } from '../loading'
import { redirectGoogleLogin } from './google-login'

interface NativeLoginProps {}

const NativeLogin: React.FC<NativeLoginProps> = function ({}) {
  const styles = useStyles({})
  const location = useLocation()
  const [result, setResult] = useState<any>(null)

  useEffect(() => {
    const params = fromQueryString(location.hash)
    if (params?.access_token) {
      setResult(params)
      redirectToApp(params)
    } else {
      redirectGoogleLogin()
    }
  }, [])

  const redirectToApp = (result: any) => {
    window.location.replace(`openphone://login${toQueryString(result)}`)
  }

  return (
    <div className={cx(styles.root, !result && styles.redirect)}>
      {result && (
        <>
          <AnimatingLogo />
          <Typography style={{ marginTop: 30 }} variant="body2" color="textSecondary">
            Redirecting you to the desktop app...
          </Typography>
          <Button
            style={{ marginTop: 10 }}
            variant="text"
            color="primary"
            onClick={() => redirectToApp(result)}
          >
            Click here if it's taking too long
          </Button>
        </>
      )}
    </div>
  )
}

export default NativeLogin

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  redirect: {
    background: 'white',
  },
}))
