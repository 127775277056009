import { action, makeObservable, observable } from 'mobx'
import AppStore from '../store'

export interface ToastAction {
  type: 'primary' | 'secondary' | 'destructive'
  title: React.ReactNode
  onClick?: () => void
}

export interface OpenToastState {
  open: true
  type: 'info' | 'error' | 'loading' | 'special'
  message: React.ReactNode
  duration?: number
  actions?: ToastAction[]
}

export interface ClosedToastState {
  open: false
}

export type ToastState = OpenToastState | ClosedToastState

class ToastUiStore {
  state: ToastState = { open: false }

  constructor(private app: AppStore) {
    makeObservable(this, {
      state: observable.ref,
      show: action,
      showSpecial: action,
      showLoading: action,
      showError: action,
      hide: action,
    })
  }

  show = (state: Omit<OpenToastState, 'open' | 'type'>): void => {
    this.state = { open: true, type: 'info', ...state }
  }

  showSpecial = (content: React.ReactNode, duration: number = 60000): void => {
    this.state = { open: true, type: 'special', message: content, duration }
  }

  showLoading = (message: string, actions?: ToastAction[]): void => {
    this.state = { open: true, type: 'loading', message, actions }
  }

  showError = (error: Error): void => {
    this.app.sound.play('error')
    this.state = { open: true, type: 'error', message: error.message }
  }

  hide = (): void => {
    this.state = { open: false }
  }
}

export default ToastUiStore
