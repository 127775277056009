// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import Service from '.'
import { GroupModel, PersistedCollection } from './model'
import { WorkspaceModel } from './model/WorkspaceModel'
import {
  GroupUpdatedMessage,
  GroupDeletedMessage,
  WebhookDelete,
  WebhookUpdate,
} from './transport/websocket'
import { GroupsRepository } from './worker/repository/GroupsRepository'
import { DecodableWebhookModel, WebhookModel } from './model/WebhookModel'
import { WebhooksRepository } from './worker/repository/WebhooksRepository'
import { FetchWebhookEventsRequest } from '@src/service'

export default class WorkspaceStore {
  workspaces: WorkspaceModel[] = []
  groups: PersistedCollection<GroupModel, GroupsRepository> = null
  webhooks: PersistedCollection<WebhookModel, WebhooksRepository> = null

  constructor(private root: Service) {
    this.handleWebsocket()

    this.groups = new PersistedCollection({
      table: this.root.storage.table('groups'),
      classConstructor: (json) => new GroupModel(this.root, json),
    })

    this.webhooks = new PersistedCollection({
      table: this.root.storage.table('webhooks'),
      classConstructor: (json) => new WebhookModel(this.root, json),
    })

    makeAutoObservable(this, {})

    this.webhooks.performQuery((repo) => repo.all())
    this.groups.performQuery((repo) => repo.all())
  }

  fetch() {
    return this.root.transport.workspace.fetch().then(
      action((response) => {
        this.workspaces = response.results
      }),
    )
  }

  addMember(workspaceId: string) {
    return this.root.transport.workspace.addMember(workspaceId)
  }

  addDomain(verificationEmail: string) {
    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.add({ verificationEmail })
  }

  deleteDomain(domainId: string) {
    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.delete(domainId)
  }

  fetchDomains() {
    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.fetch()
  }

  resendCode(domainId: string) {
    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.resendCode(domainId)
  }

  verifyDomain(email: string, code: string) {
    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.verify({ email, code })
  }

  fetchUserGroups() {
    return this.root.transport.account.organization.groups
      .fetch({ includeDeleted: true })
      .then(
        action((response) => {
          this.groups.load(response)
        }),
      )
  }

  updateGroup(group: Pick<GroupModel, 'id'> & Partial<GroupModel>) {
    return this.root.transport.account.organization.groups.update(group)
  }

  createGroup(group: Partial<GroupModel>) {
    return this.root.transport.account.organization.groups.create(group)
  }

  deleteGroup(id: string) {
    return this.root.transport.account.organization.groups.delete(id)
  }

  addUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups.addUser(groupId, userId)
  }

  deleteUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups.deleteUser(groupId, userId)
  }

  createWebhook(webhook: Partial<WebhookModel>) {
    return this.root.transport.webhooks
      .create(webhook)
      .then((response) =>
        this.root.workspace.webhooks.put(new WebhookModel(this.root, response)),
      )
  }

  updateWebhook(webhook: Partial<WebhookModel>) {
    return this.root.transport.webhooks.update(webhook)
  }

  deleteWebhook(webhook: Partial<WebhookModel>) {
    return this.root.transport.webhooks.delete(webhook)
  }

  fetchWebhooks() {
    return this.root.transport.webhooks
      .fetch()
      .then((response) => this.root.workspace.webhooks.load(response))
  }

  sendTestRequest(id: string, data: Record<string, any>) {
    return this.root.transport.webhooks.sendTestRequest(id, data)
  }

  resendEvent(webhookId: string, eventId: string) {
    return this.root.transport.webhooks.resendEvent(webhookId, eventId)
  }

  fetchWebhookEvents(params: FetchWebhookEventsRequest) {
    return this.root.transport.webhooks.fetchWebhookEvents(params)
  }

  private handleWebsocket() {
    this.root.transport.onMessage.subscribe(
      (
        msg: GroupUpdatedMessage | GroupDeletedMessage | WebhookDelete | WebhookUpdate,
      ) => {
        switch (msg.type) {
          case 'group-updated':
          case 'group-deleted':
            this.handleGroupUpdatedMessage(msg.group)
            return
          case 'webhook-delete':
            this.handleWebhookDelete(msg.webhook)
            return
          case 'webhook-update':
            this.handleWebhookUpdate(msg.webhook)
            return
        }
      },
    )
  }

  private handleWebhookDelete(webhook: DecodableWebhookModel) {
    this.webhooks.delete(new WebhookModel(this.root, webhook))
  }

  private handleWebhookUpdate(value: DecodableWebhookModel) {
    const webhook = this.webhooks.get(value.id)
    if (webhook) {
      webhook.deserialize(value)
    } else {
      this.webhooks.put(new WebhookModel(this.root, value))
    }
  }

  private handleGroupUpdatedMessage(group: GroupModel) {
    const userGroup = this.groups.get(group.id)
    if (userGroup) {
      userGroup.deserialize(group)
    } else {
      this.groups.put(new GroupModel(this.root, group))
    }
  }
}
