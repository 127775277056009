import { lighten, makeStyles } from '@material-ui/core'
import Illustration from '@src/component/illustrations'
import SignUpPhoneNumber from '@src/component/illustrations/SignUpPhoneNumber'
import SignUpPortNumber from '@src/component/illustrations/SignUpPortNumber'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useOnboardingState } from '.'
import Button from '../../component/button-v2'
import Typography from '@ui/Typography'
import { formatted } from '../../lib/phone-number'
import PickNumber from '../settings/phone-numbers/pick'
import { Actions, Card, Disclaimer } from './common'

const Number: React.FC<{}> = function () {
  const styles = useStyles({})
  const state = useOnboardingState()
  const [changeNumberOpen, setChangeNumberOpen] = useState(false)

  /**
   * Run the phone number search on initial load
   */
  useEffect(() => {
    if (state.selectedNumber) return
    state.searchAvailable({})
  }, [])

  return (
    <>
      <PickNumber
        open={changeNumberOpen}
        onClose={() => setChangeNumberOpen(false)}
        onSelect={state.setSelectedNumber}
      />
      <Card
        emoji={
          <Illustration
            illustration={state.isPorting ? SignUpPortNumber : SignUpPhoneNumber}
            style={{ width: 295 }}
          />
        }
        title={
          state.isPorting
            ? 'Say hello to your temporary number'
            : 'Say hello to your new number!'
        }
        description={
          state.isPorting
            ? 'You can use this number as much as you need during the porting process, which can take 5-12 business days to complete.'
            : 'Check it out, your very own OpenPhone number, ready and waiting to go!'
        }
      >
        <button
          disabled={state.isPorting}
          className={styles.phoneNumber}
          onClick={() => setChangeNumberOpen(true)}
        >
          {state.selectedNumber && (
            <Typography color="inherit" variant="largeTitle">
              {formatted(state.selectedNumber)}
            </Typography>
          )}
          {!state.selectedNumber && state.isSearching && (
            <div className={styles.loading} />
          )}
        </button>
        <Actions>
          <Button
            variant="contained"
            color="primary"
            height={40}
            onClick={state.numberSelected}
            disabled={!state.selectedNumber}
            loading={state.isSearching || state.loading}
          >
            Continue
          </Button>
          {!state.isPorting && (
            <Button
              variant="text"
              color="textSecondary"
              fontWeight="regular"
              height={36}
              onClick={() => setChangeNumberOpen(true)}
            >
              Pick a different number
            </Button>
          )}
        </Actions>
        {state.isPorting && (
          <Disclaimer
            emoji="👉"
            text="Once your free trial begins, we will ask you about your existing number to begin the porting process."
          />
        )}
      </Card>
    </>
  )
}

export default observer(Number)

const useStyles = makeStyles((theme) => ({
  phoneNumber: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
    borderRadius: 8,
    background: theme.palette.op.primary[4],
    color: theme.palette.op.primary[2],
    transition: theme.transitions.create(['background'], { duration: 160 }),

    '&:hover:not(:disabled)': {
      cursor: 'pointer',
      background: lighten(theme.palette.op.primary[4], 0.05),
    },
  },
  loading: {
    height: 10,
    background: theme.palette.op.background.highlight(0.2),
    borderRadius: 5,
    flex: '0 0 80%',
  },
  disabled: {},
}))
