// @ts-strict-ignore
import Modal, { type ModalProps } from '@material-ui/core/Modal'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import FileIcon from '../../component/file-icon'
import IconButton from '../../component/icon-button'
import { ArrowLeftIcon, ArrowRightIcon } from '../../component/icons/Tint/20/General'
import { fileType } from '../../lib/file'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { fonts } from '../../theme'
import MediaPreview from './preview'

interface MediaViewerProps {}

const MediaViewer: React.FC<MediaViewerProps> = function ({ children }) {
  const styles = useStyles({})
  const theme = useTheme()
  const { mediaViewer, closeMediaViewer } = useAppStore()
  const visible = Boolean(mediaViewer)
  const selectedIndex = mediaViewer?.index
  const media = mediaViewer?.media
  const m = media?.[mediaViewer.index]
  const mediaType = fileType(m?.type, m?.['url'] || m?.name)

  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add(styles.faded)
    } else {
      document.getElementById('root').classList.remove(styles.faded)
    }
  }, [visible])

  useKeyboardShortcuts({
    name: 'conversation/media-viewer',
    node: document,
    handler: (shortcut, event) => {
      if (!visible) return
      if (shortcut === 'ArrowLeft' && mediaViewer?.index > 0) {
        handlePrevious()
        event.preventDefault()
      } else if (
        shortcut === 'ArrowRight' &&
        mediaViewer.index < mediaViewer?.media.length - 1
      ) {
        handleNext()
        event.preventDefault()
      } else if (shortcut === 'Escape' && mediaViewer) {
        closeMediaViewer()
        event.preventDefault()
      }
    },
    dep: [visible],
  })

  const handleDownload = function () {
    if ('url' in m) {
      window.open(m.url, '_blank')
    }
  }

  const handleBackdropClick = function (event) {
    // TODO: This could result in bugs if a div element
    // is not supposed to cause a dismiss
    if (event.target.nodeName === 'DIV') {
      closeMediaViewer()
    }
  }

  const handleClose: ModalProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') {
      handleBackdropClick(event)
    }

    closeMediaViewer()
  }

  const handleNext = function () {
    mediaViewer.index += 1
  }

  const handlePrevious = function () {
    mediaViewer.index -= 1
  }

  const hasPrevious = mediaViewer?.index > 0
  const hasNext = mediaViewer?.index < (mediaViewer?.media?.length ?? 0) - 1

  return (
    <>
      {children}
      <Modal
        disableEscapeKeyDown
        open={visible}
        onClose={handleClose}
        className={styles.root}
      >
        {m ? (
          <div className={styles.root} onClick={handleBackdropClick}>
            {hasPrevious && (
              <IconButton
                className={styles.arrowLeft}
                title="Previous"
                shortcut="←"
                onClick={handlePrevious}
                style={{ background: theme.palette.op.background.highlight(0.15) }}
                icon={<ArrowLeftIcon />}
              />
            )}
            {hasNext && (
              <IconButton
                className={styles.arrowRight}
                title="Next"
                shortcut="→"
                onClick={handleNext}
                style={{ background: theme.palette.op.background.highlight(0.15) }}
                icon={<ArrowRightIcon />}
              />
            )}
            <div className={styles.header}>
              <div className={styles.headerSection}>
                <FileIcon type={mediaType} />
                <div style={{ marginLeft: 15 }}>{m?.name}</div>
              </div>
              <div className={styles.pageNumber}>
                {selectedIndex + 1}/{media.length}
              </div>
              <div
                className={styles.headerSection}
                style={{ justifyContent: 'flex-end' }}
              >
                <IconButton
                  size="medium"
                  color="default"
                  onClick={handleDownload}
                  title="Launch in new tab"
                  icon={<LaunchIcon />}
                />
                <IconButton
                  size="medium"
                  onClick={closeMediaViewer}
                  title="Close"
                  icon={<CloseIcon />}
                />
              </div>
            </div>
            <div className={styles.content}>
              <MediaPreview media={m} />
            </div>
          </div>
        ) : (
          <span />
        )}
      </Modal>
    </>
  )
}

const canHandleMedia = function (type: string, name: string): boolean {
  const mediaType = fileType(type, name)
  switch (mediaType) {
    case 'contact':
    case 'doc':
    case 'image':
    case 'pdf':
    case 'presentation':
    case 'spreadsheet':
    case 'video':
      return true
    default:
      return false
  }
}

export default observer(MediaViewer)
export { canHandleMedia }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    boxSizing: 'border-box',
    position: 'relative',
  },
  header: {
    flex: '0 0 70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 25px',
  },
  headerSection: {
    flex: 1,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
  faded: {
    opacity: 0.1,
  },
  arrowLeft: {
    position: 'absolute',
    left: 20,
    top: '50%',
  },
  arrowRight: {
    position: 'absolute',
    right: 20,
    top: '50%',
  },
  pageNumber: {
    flex: '0 0 100px',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontFamily: fonts.mono,
    color: theme.palette.text.secondary,
  },
}))
