import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import BillingGeneralRoute from './BillingGeneralRoute'
import BillingPlans from './plans'

const Billing = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/plans`} component={BillingPlans} />
      <Route path={path} component={BillingGeneralRoute} />
    </Switch>
  )
}

export default Billing
