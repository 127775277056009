// @ts-strict-ignore
declare var WorkerGlobalScope: any

// FIXME: Replace `logdna` package with `@logdna/browser`
import RemoteLogger from 'logdna'
import { platform } from '..'
import config from '../config'
import { User } from '../service/model'

enum LogLevel {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
}

/**
 * Methods to call on console to log each LogLevel.
 */
const logLevelMethods: Record<string, string> = {
  [LogLevel.trace]: 'trace',
  [LogLevel.debug]: 'debug',
  [LogLevel.info]: 'info',
  [LogLevel.warn]: 'warn',
  [LogLevel.error]: 'error',
  [LogLevel.fatal]: 'fatal',
}

/**
 * Ranking of LogLevel keys to determine which logs to print for a given LogLevel.
 */
const logLevelRanks: Record<string, number> = {
  [LogLevel.trace]: 0,
  [LogLevel.debug]: 1,
  [LogLevel.info]: 2,
  [LogLevel.warn]: 3,
  [LogLevel.error]: 3,
  [LogLevel.fatal]: 3,
}

/**
 * @internalapi
 */
class Log {
  private readonly local: Console = console
  private readonly remote: RemoteLogger.Logger
  private user: User

  /**
   * The current LogLevel threshold.
   */
  get logLevel() {
    return this._logLevel
  }

  /**
   * @param logLevel - The initial LogLevel threshold to display logs for.
   */
  constructor(private _logLevel: LogLevel, private isDev: boolean) {
    this.remote = RemoteLogger.createLogger(config.LOG_KEY, {
      app: platform ?? 'web',
      index_meta: true,
      level: _logLevel,
      hostname: config.LOG_ENV,
      env: config.LOG_ENV,
    })
    this.remote.addMetaProperty('userAgent', navigator.userAgent)
    this.remote.addMetaProperty('appVersion', config.VERSION)
    this.remote.addMetaProperty('appContext', platform ?? 'browser')
  }

  setUser(user: User) {
    this.user = user
    this.remote.addMetaProperty('userId', user.id)
  }

  /**
   * Log a console.info message if the current LogLevel threshold is 'debug'.
   * @param args - Any number of arguments to be passed to console.info
   */
  debug(message: string, meta?: any): void {
    this.log(LogLevel.debug, message, meta)
  }

  /**
   * Log a console.error message if the current LogLevel threshold is 'error' or lower.
   * @param args - Any number of arguments to be passed to console.error
   */
  error(message: string, meta?: any): void {
    this.log(LogLevel.error, message, meta)
  }

  /**
   * Log a console.error message with the error object turned into a JSON string
   * @param args - Any number of arguments to be passed to console.error
   */
  toJsonString(error: any): string {
    function replaceErrors(key, value) {
      if (value instanceof Error) {
        var error = {}

        Object.getOwnPropertyNames(value).forEach(function (key) {
          error[key] = value[key]
        })

        return error
      }

      return value
    }
    return JSON.stringify(error, replaceErrors)
  }

  /**
   * Log a console.info message if the current LogLevel threshold is 'info' or lower.
   * @param args - Any number of arguments to be passed to console.info
   */
  info(message: string, meta?: any): void {
    this.log(LogLevel.info, message, meta)
  }

  /**
   * Log a console message if the current LogLevel threshold is equal to or less than the
   *   LogLevel specified.
   * @param logLevel - The LogLevel to compare to the current LogLevel to determine
   *   whether the log should be printed.
   * @param args - Any number of arguments to be passed to console
   */
  log(logLevel: LogLevel, message: string, meta?: any): void {
    const methodName = logLevelMethods[logLevel]
    if (methodName && logLevelRanks[this.logLevel] <= logLevelRanks[logLevel]) {
      if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
        meta = { ...meta, worker: true }
      } else {
        meta = { ...meta, url: window.location.href }
      }
      const userName = this.user?.id ?? 'Anonymous'
      const body = `${userName} [v${config.VERSION}] - ${message}`
      ;(this.local as any)[methodName](body, meta)
      if (!this.isDev) {
        ;(this.remote as any)[methodName](body, { meta })
      }
    }
  }

  /**
   * Set/update the LogLevel threshold to apply to all future logs.
   * @param logLevel - The new LogLevel to use as a threshold for logs.
   */
  setLogLevel(logLevel: LogLevel): void {
    this._logLevel = logLevel
  }

  /**
   * Log a console.warn message if the current LogLevel threshold is 'warn' or lower.
   * @param args - Any number of arguments to be passed to console.warn
   */
  warn(message: string, meta?: any): void {
    this.log(LogLevel.warn, message, meta)
  }
}

const log = !!config.IS_DEV
  ? new Log(LogLevel.trace, true)
  : new Log(LogLevel.info, false)

const logError = (error: Error) => {
  log.error(error.message, { error })
  return undefined
}

export default log
export { logError, Log, LogLevel }
