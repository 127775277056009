// @ts-strict-ignore
import Debug from 'debug'
import LRU from 'lru-cache'
import SyncStorage from '../service/storage/sync'
import shortId from '../lib/short-id'

export interface CodableController<O> {
  serialize(): O | null
  deserialize(obj: O): void
  tearDown?(): void
}

export interface ControllerRegistryOptions<O> {
  storage: SyncStorage<O>
  cacheMax?: number
}

export default abstract class ControllerRegistry<T extends CodableController<O>, O, I> {
  abstract name: string

  protected handleDispose = (key: string, value: T) => {
    this.debug('disposing controller (id: %O)', key)
    this.onDispose(value)
    value.tearDown?.()
  }

  readonly id = shortId()
  readonly debug = Debug(`op:controller-registry:@${this.id}`)
  readonly cache: LRU<string, T>
  readonly storage: SyncStorage<O>

  constructor({ storage, cacheMax = 1 }: ControllerRegistryOptions<O>) {
    this.storage = storage

    this.cache = new LRU<string, T>({
      dispose: this.handleDispose,
      noDisposeOnSet: true,
      max: cacheMax,
    })
  }

  abstract create(identifierObj: I): T
  abstract getId(thing: T | I): string
  abstract onDispose(controller: T): void

  toStorageId(thing: T | I): string {
    const id = this.getId(thing)
    return `${this.name}.${id}`
  }

  get(identifierObj: I | null): T | null {
    if (!identifierObj) return null
    const id = this.toStorageId(identifierObj)
    const cached = this.cache.get(id)
    if (cached) {
      this.debug('cache hit (id: %O)', id)
      return cached
    }
    this.debug('cache miss (id: %O)', id)
    const obj = this.storage.get(id)
    const controller = this.create(identifierObj)
    controller.deserialize(obj)
    this.save(controller)
    return controller
  }

  save(controller: T | null, serialized?: O): void {
    if (!controller) return
    const id = this.toStorageId(controller)
    // If `save()` is given an undefined value for `serialized`, then we can
    // assume the caller did not pass an argument. However, if `null` is given,
    // we know we should clear the storage for this controller.
    const obj = serialized !== undefined ? serialized : controller.serialize()
    this.cache.set(id, controller)

    if (obj) {
      this.debug('saving (id: %O) (obj: %O)', id, obj)
      this.storage.set(id, obj)
    } else {
      this.debug('clearing (id: %O)', id)
      this.storage.delete(id)
    }
  }
}
