// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useImperativeHandle, useRef } from 'react'
import { DragIndicatorIcon } from '../../../component/icons/Tint/Misc'
import {
  AddressIcon,
  AtIcon,
  ChecboxIcon,
  CompanyIcon,
  DateIcon,
  MultiSelectIcon,
  NumberIcon,
  PhoneIcon,
  RoleIcon,
  TextIcon,
  URLIcon,
} from '../../../component/icons/Tint/16/ContactInfo'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { ContactItemType } from '../../../types'
import { useEditor } from '../editor'
import ItemValue from './value'

interface ItemProps {
  type: ContactItemType
  name: string
  value: any
  placeholder?: string
  disableName?: boolean
  disableValue?: boolean
  draggable?: boolean
  valueRef?: React.MutableRefObject<HTMLDivElement>
  actions?: React.ReactNode
  onNameChange?: (name: string) => void
  onValueClick?: () => void
  onDelete?: () => void
}

export interface ContactItemMethod {
  changeName: () => void
}

const Item: React.ForwardRefRenderFunction<ContactItemMethod, ItemProps> = function (
  {
    actions,
    type,
    name,
    value,
    disableValue,
    disableName,
    valueRef,
    placeholder,
    draggable,
    onNameChange,
    onValueClick,
    onDelete,
  },
  ref,
) {
  const styles = useStyles({ disableName, draggable })
  const [editor] = useEditor()
  const nameRef = useRef<HTMLDivElement>(null)

  const handleEditName = () => {
    if (disableName) return
    editor(nameRef.current, {
      name: 'edit name',
      defaultValue: name,
      onSave: onNameChange,
      onDelete: onDelete,
      onDiscard: onDelete,
    })
  }

  useImperativeHandle(ref, () => ({
    changeName: handleEditName,
  }))

  const Icon = icons[type]

  return (
    <div className={styles.root}>
      {draggable && <DragIndicatorIcon className={styles.drag} />}
      <Tooltip disabled={disableName} title="Edit name" placement="left">
        <div ref={nameRef} className={styles.name} onClick={handleEditName}>
          <Icon className={styles.icon} />
          <Typography
            nowrap
            variant="callout"
            color="textSecondary"
            className={styles.nameText}
          >
            {name}
          </Typography>
        </div>
      </Tooltip>
      <ItemValue
        ref={valueRef}
        onClick={onValueClick}
        value={value}
        placeholder={placeholder}
        actions={actions}
      />
    </div>
  )
}

export default observer(Item, { forwardRef: true })

const useStyles = makeStyles<Theme, Partial<ItemProps>>((theme) => ({
  drag: () => ({
    color: theme.palette.op.gray[4],
    position: 'absolute',
    left: -7,
    display: 'none',
  }),
  root: ({ draggable }) => ({
    display: 'flex',
    marginBottom: 2,
    position: 'relative',

    '&:hover': {
      '& $drag': {
        display: draggable ? 'block' : 'none',
      },
    },
  }),
  name: ({ disableName }) => ({
    alignItems: 'center',
    borderRadius: 5,
    cursor: disableName ? undefined : 'pointer',
    display: 'flex',
    flex: '0 0 125px',
    height: 30,
    marginRight: 2,
    padding: '0 10px',
    transition: theme.transitions.create(['background-color'], { duration: 160 }),

    '&:hover': {
      background: disableName ? 'none' : theme.palette.op.hover.primary,
    },
  }),
  nameText: {
    fontSize: 13,
    color: theme.palette.op.gray[2],
  },
  icon: {
    flex: '0 0 auto',
    color: theme.palette.op.gray[3],
    marginRight: 10,
  },
}))

const icons: { [key in ContactItemType]: React.FC<React.HTMLProps<SVGSVGElement>> } = {
  address: AddressIcon,
  boolean: ChecboxIcon,
  company: CompanyIcon,
  date: DateIcon,
  email: AtIcon,
  'multi-select': MultiSelectIcon,
  number: NumberIcon,
  'phone-number': PhoneIcon,
  role: RoleIcon,
  string: TextIcon,
  url: URLIcon,
}

export function isValueChanged(value1: any, value2: any): boolean {
  return value1 != value2 && (value1 || value2)
}
