// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import Key from '../../component/keyboard-key'
import useInputState from '../../lib/use-input-state'
import Footer from './common/footer'
import FooterButton from './common/footer-button'
import Header from './common/header'
import Content from './common/content'
import Textfield from './common/textfield'
import Item from './common/item'
import keyboard from '../../lib/keyboard'
import useKeyStepper from '../../lib/use-key-stepper'
import { parseFromString } from '../../lib'
import { useAppStore } from '@src/app/context'
import { Command } from './common-v2'

export interface PickDurationProps {
  title: string
  description: string
  onSet: (date: Date) => void
}

type ItemType = { type: 'input' } | { type: 'preset'; title: string; value: string }

const items: ItemType[] = [
  { type: 'input' },
  { type: 'preset', title: '30 minutes', value: 'in 30 minutes' },
  { type: 'preset', title: '1 hour', value: 'in 1 hour' },
  { type: 'preset', title: '2 hours', value: 'in 2 hours' },
  { type: 'preset', title: 'Tomorrow', value: 'Tomorrow' },
  { type: 'preset', title: 'Until further notice', value: 'in 200 years' },
]

const PickDuration: React.FC<PickDurationProps> = function ({
  title,
  description,
  onSet,
}) {
  const { toast } = useAppStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const [date, setDate] = useInputState('')

  const { selectedIndex, getItemProps } = useKeyStepper({
    name: 'command/pick-duration',
    items,
    handleSelect: (item) => {
      if (item.type === 'input') {
        handleSelect()
      } else {
        onSet(parseFromString(item.value))
      }
    },
    deps: [date],
  })

  useEffect(() => {
    if (selectedIndex === 0) {
      inputRef.current.focus()
    } else {
      inputRef.current.blur()
    }
  }, [selectedIndex === 0])

  const handleSelect = () => {
    const dndDate = parseFromString(date)
    if (!dndDate) {
      toast.show({ message: 'Could not parse the date' })
    } else if (dndDate > new Date()) {
      onSet(dndDate)
    } else {
      toast.show({ message: 'Date cannot be in the past.' })
    }
  }

  return (
    <Command>
      <Header title={title} subtitle={description} />
      <Content>
        {items.map((item, index) =>
          item.type === 'input' ? (
            <div key="input" {...getItemProps(index)} onClick={undefined}>
              <Textfield
                autoFocus
                placeholder="Try: 5pm, tomorrow morning, Sept 2"
                innerRef={inputRef}
                value={date}
                onChange={setDate}
                onKeyDown={keyboard.onEnter(handleSelect)}
              />
            </div>
          ) : (
            <Item
              key={index}
              {...getItemProps(index)}
              highlighted={index === selectedIndex}
            >
              {item.title}
            </Item>
          ),
        )}
      </Content>
      <Footer
        actions={
          <FooterButton
            disabled={!date}
            variant="contained"
            color="secondary"
            onClick={handleSelect}
          >
            Turn On
          </FooterButton>
        }
      >
        <Typography variant="caption" color="textSecondary">
          Press <Key name="Enter" /> to turn on DnD
        </Typography>
      </Footer>
    </Command>
  )
}

export default observer(PickDuration)
