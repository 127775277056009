import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import KeyboardList from '../../component/keyboard-list'
import { Contact } from '../../service/model'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import ContactHeader from './header'
import ContactRow from './row'
import SearchBar from './search-bar'

interface ContactListProps {}

const ContactList: React.FC<ContactListProps> = function ({}) {
  const styles = useStyles({})
  const { contacts } = useAppStore()

  useKeyboardShortcuts({
    node: document,
    name: 'ContactList',
    handler: (shortcut, event) => {
      if (shortcut === 'KeyX' || shortcut === 'Space') {
        contacts.toggleChecked(contacts.contact.id)
        event.preventDefault()
      }
    },
  })

  const rowRenderer = (contact: Contact, index: number) => {
    return (
      <ContactRow
        contact={contact}
        key={contact.id}
        onClick={() => contacts.keyboardList.stepper.selectIndex(index)}
      />
    )
  }

  return (
    <div className={styles.root}>
      <ContactHeader />
      <SearchBar />
      <KeyboardList
        controller={contacts.keyboardList}
        renderItem={rowRenderer}
        className={styles.list}
      />
    </div>
  )
}

export default observer(ContactList)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    display: 'flex',
    flex: 1,
  },
}))
