import { DisposeBag } from '../../lib/dispose'
import shortId from '../../lib/short-id'
import { makeAutoObservable, reaction, toJS } from 'mobx'
import Service from '..'
import { Model } from './base'
import { AvailabilityHours } from '../..'

export type ReactionEmojis = [string, string, string, string, string]
export type NotificationUnreadCount = 'conversations' | 'activities'
export interface IUserSettings {
  android: Record<string, unknown>
  ios: Record<string, unknown>
  web: {
    onboardingCompleted: boolean
    playNotificationSounds?: boolean
  }
  shared: {
    phoneNumberOrder: string[]
  }
  notifications?: {
    includeMessagePreview?: boolean
    snoozedDeliveryMethod?: 'silent' | 'none'
    schedule?: AvailabilityHours
    unreadCount?: NotificationUnreadCount
    playSounds?: 'all' | 'minimal' | 'none'
    defaultRingtone?: 'system' | 'primary' | 'secondary'
  }
  preferences: {
    reactionEmojis?: ReactionEmojis
    defaultSkinTone?: string
  }
}

export const defaultReactionEmojis: ReactionEmojis = ['😀', '😮', '❤️', '🎉', '👍']

export class UserSettings implements IUserSettings, Model {
  readonly id = shortId()
  ios = {}
  android = {}
  web = { onboardingCompleted: false, playNotificationSounds: true }
  shared = { phoneNumberOrder: [] }
  notifications: IUserSettings['notifications'] = {}
  preferences: IUserSettings['preferences'] = {
    reactionEmojis: undefined,
    defaultSkinTone: undefined,
  }

  private disposeBag = new DisposeBag()

  get playNotificationSounds() {
    // FIXME: this is a temporary getter, we should remove this getter
    // once we have a way to migrate settings and set defaults for new properties.
    return this.web.playNotificationSounds ?? true
  }

  constructor(private root: Service, attrs: Partial<IUserSettings>) {
    this.deserialize(attrs)
    makeAutoObservable(this)

    this.disposeBag.add(
      reaction(
        () => this.serialize(),
        (settings) => {
          this.root.transport.account.userSettings.update(settings)
        },
      ),
    )
  }

  deserialize(json: Partial<IUserSettings>) {
    Object.assign(this, json)

    return this
  }

  serialize(): IUserSettings {
    return {
      android: toJS(this.android),
      ios: toJS(this.ios),
      web: toJS(this.web),
      shared: toJS(this.shared),
      notifications: toJS(this.notifications),
      preferences: toJS(this.preferences),
    }
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
