// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Papa from 'papaparse'
import React, { useState } from 'react'
import { Contact } from '../../../service/model'
import FileSelector from '../select-file'
import Fields from './fields'
import Name from './name'

type Step = 'file' | 'mapping' | 'name'

export interface ImportContactsProps {
  onComplete: (contacts: Contact[], name: string) => void
}

const ImportContacts: React.FC<ImportContactsProps> = function ({ onComplete }) {
  const styles = useStyles({})
  const [step, setStep] = useState<Step>('file')
  const [data, setData] = useState<{ data: any[]; fields: string[] }>(null)
  const [contacts, setContacts] = useState<Contact[]>(null)
  const [name, setName] = useState<string>(null)

  const handleFileSelect = (file: File) => {
    setName(file.name.replace(/\.[^/.]+$/, ''))
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setData({ data: results.data, fields: results.meta.fields })
        setStep('mapping')
      },
    })
  }

  const handleMapping = (contacts: Contact[]) => {
    setContacts(contacts)
    setStep('name')
  }

  const handleImport = (name: string) => {
    onComplete(
      contacts.map((c) => {
        c.source = 'csv'
        c.sourceName = name
        return c
      }),
      name,
    )
  }

  return step === 'file' ? (
    <FileSelector
      title="Select a CSV file"
      description="Drag and drop a csv file here, or click to select a file."
      accept={['.csv']}
      onSelect={handleFileSelect}
    />
  ) : step === 'mapping' ? (
    <Fields data={data.data} fields={data.fields} onComplete={handleMapping} />
  ) : (
    <Name count={data.data.length} name={name} onImport={handleImport} />
  )
}

export default ImportContacts

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
