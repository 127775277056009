// @ts-strict-ignore
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useAutocomplete } from '@material-ui/lab'
import React, {
  ForwardRefExoticComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
} from 'react'
import TextField from '../textfield-v2'
import cx from 'classnames'
import Typography from '@ui/Typography'
import useInputState from '../../lib/use-input-state'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { observer } from 'mobx-react-lite'
import PopoverMenu from '../popover-menu'
import { PopoverOrigin } from '@material-ui/core'

export type SelectSearchOptionValue = { name: string; value: string }
export type RenderOptionProps<T> = {
  option: T
  index: number
  rootOptionProps: {}
}

export interface TriggerProps {
  onClick: () => void
  children: ReactNode
}

export type TriggerElement = HTMLButtonElement & HTMLDivElement

interface SelectSearchProps<T> {
  options: T[]
  onChange: (value: T | T[]) => void
  value: NonNullable<T> | NonNullable<T>[]
  open?: boolean
  onClose?: () => void
  rootClassName?: string
  dropdownProps?: Partial<{
    placeholder: string
    footer?: FunctionComponent
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
  }>
  renderOption?: FunctionComponent<RenderOptionProps<T>>
  getOptionName?: (option: NonNullable<T> | NonNullable<T>[]) => string
  multiple?: boolean

  renderTrigger?: ForwardRefExoticComponent<
    TriggerProps & React.RefAttributes<HTMLElement>
  >
}

const SelectSearch = <T extends SelectSearchOptionValue>({
  options,
  onChange,
  value: controlledValue,
  renderOption,
  getOptionName,
  open = false,
  multiple = false,
  onClose,
  rootClassName,
  dropdownProps = {},
  renderTrigger,
}: SelectSearchProps<T>) => {
  const [isOpen, setOpen] = useState(open)
  const [text, setText] = useInputState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>()
  const triggerRef = useRef<TriggerElement>(null)

  const handleClose = () => {
    setOpen(false)
    setText('')
    if (triggerRef.current) {
      triggerRef.current.focus()
    }
    onClose?.()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])

  useEffect(() => {
    if (!isOpen) return

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' || event.code === 'Tab') {
        event.preventDefault()
        handleClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown, { capture: true })
    return () => {
      window.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
  }, [isOpen])

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
  } = useAutocomplete({
    id: 'select-search',
    options: options,
    multiple,
    getOptionLabel: (option) => option.name,
    getOptionSelected: (option, value) => {
      return option.name === value.name && option.value === value.value
    },
    open: isOpen,
    value: controlledValue,
    inputValue: text,
    onChange: (_, value, reason) => {
      if (reason === 'select-option' || (multiple && reason === 'remove-option')) {
        onChange(value as T | T[])
        if (!multiple) {
          handleClose()
          setText('')
        }
      }
    },
  })

  const TriggerComponent = () => {
    const onClick = () => setOpen(true)
    const text = (
      <Typography variant="footnote" nowrap>
        {getOptionName
          ? getOptionName(value as NonNullable<T>)
          : value
          ? (value as SelectSearchOptionValue).name
          : ''}
      </Typography>
    )

    if (renderTrigger) {
      const Trigger = renderTrigger
      return (
        <Trigger onClick={onClick} ref={triggerRef}>
          {text}
        </Trigger>
      )
    }

    return (
      <div className={cx(styles.displayOptionRoot)} onClick={onClick} ref={triggerRef}>
        {text}
        {isOpen ? (
          <ArrowDropUpIcon className={styles.icon} />
        ) : (
          <ArrowDropDownIcon className={styles.icon} />
        )}
      </div>
    )
  }

  const OptionComponent = renderOption ? renderOption : DefaultOption

  const FooterPopover = dropdownProps?.footer

  return (
    <div {...getRootProps()} ref={rootRef} className={cx(styles.root, rootClassName)}>
      <TriggerComponent />
      <input
        {...getInputProps()}
        style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}
      />
      <PopoverMenu
        open={isOpen}
        onClose={handleClose}
        anchorEl={rootRef.current}
        anchorOrigin={
          dropdownProps?.anchorOrigin ?? { vertical: 'bottom', horizontal: 'left' }
        }
        transformOrigin={
          dropdownProps?.transformOrigin ?? { vertical: 'top', horizontal: 'left' }
        }
        style={{ margin: '5px 0' }}
        className={styles.popOverMenu}
      >
        <>
          <TextField
            className={styles.searchInput}
            value={text}
            onChange={setText}
            autoFocus
            ref={inputRef}
            placeholder={dropdownProps?.placeholder ?? 'Search for items...'}
          />
          <div className={styles.separator} />
          {groupedOptions.length > 0 ? (
            <>
              <ul className={styles.listbox} {...getListboxProps()}>
                {groupedOptions.map((option, index) => {
                  return (
                    <OptionComponent
                      key={index}
                      option={option}
                      index={index}
                      rootOptionProps={getOptionProps({ option, index })}
                    />
                  )
                })}
              </ul>
              {FooterPopover && <FooterPopover />}
            </>
          ) : (
            <Typography className={styles.noOptionsLabel} variant="footnote">
              No options
            </Typography>
          )}
        </>
      </PopoverMenu>
    </div>
  )
}

const useStyles = makeStyles<Theme>(
  (theme: Theme) =>
    createStyles({
      root: {},
      displayOptionRoot: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '5px 7px',
        width: 'fit-content',
        '&:hover': {
          backgroundColor: theme.palette.op.hover.primary,
        },
      },
      popOverMenu: {
        '& > .MuiPaper-root > div': {
          padding: 0,
        },
      },
      separator: {
        borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
      },
      icon: {
        marginLeft: 6,
      },
      noOptionsLabel: {
        padding: '7px 11px',
        width: 232,
        height: 30,
      },
      searchInput: {
        border: '0 !important',
        background: 'transparent',
        fontSize: 13,
        height: 32,
        padding: '8px 14px',
        boxShadow: 'none !important',
        caretColor: theme.palette.op.primary[2],
      },
      listbox: {
        margin: 0,
        zIndex: 1,
        listStyle: 'none',
        padding: '6px 4px',
        overflow: 'auto',
        borderRadius: 9,
        maxHeight: 200,
        '& li[data-focus="true"]': {
          backgroundColor: '#4a8df6',
          color: 'white',
          cursor: 'pointer',
        },
        '& li:active': {
          backgroundColor: '#2977f5',
          color: 'white',
        },
      },
    }),
  { name: SelectSearch.name },
)

function DefaultOption<T extends SelectSearchOptionValue>({
  option,
  index,
  rootOptionProps,
}: RenderOptionProps<T>) {
  const styles = useDefaultOptionStyles()
  return (
    <li {...rootOptionProps} className={styles.optionContainer}>
      {option.name}
    </li>
  )
}

const useDefaultOptionStyles = makeStyles(() => ({
  optionContainer: {
    cursor: 'pointer',
    width: 232,
  },
}))

export default observer(SelectSearch)
