import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ReactNode } from 'react'

function ConnectedLayout({ children }: { children: ReactNode }) {
  const styles = useStyles()
  return <div className={styles.root}>{children}</div>
}

export default ConnectedLayout

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
})
