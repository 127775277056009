// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import Service from '.'
import { Coupon, CreditCard, Invoice } from '../types'
import { Capabilities, Subscription } from './model'
import { makePersistable } from './storage/persistable'
import { UpgradeParams } from './transport/billing'

export default class BillingStore {
  subscription: Subscription = null
  coupon: Coupon = null
  invoices: Invoice[] = []
  upcomingInvoice: Invoice = null
  card: CreditCard = null
  capabilities: Capabilities = null
  showPremiumBanner = true

  constructor(private root: Service) {
    this.subscribeToWebSocket()
    makeAutoObservable(this, {})
    makePersistable(this, 'BillingStore', {
      subscription: root.storage.async((d) => new Subscription(root).deserialize(d)),
      coupon: root.storage.async(),
      showPremiumBanner: root.storage.sync(),
      capabilities: root.storage.async((json) =>
        new Capabilities(root).deserialize(json?.raw ?? []),
      ),
    })
  }

  fetchCapabilities() {
    return this.root.transport.billing.capabilities().then(
      action((json) => {
        this.capabilities ??= new Capabilities(this.root)
        this.capabilities.deserialize(json)
      }),
    )
  }

  fetchSubscription() {
    return this.root.transport.billing.subscription().then(
      action((json) => {
        this.subscription ??= new Subscription(this.root)
        this.subscription.deserialize(json)
      }),
    )
  }

  fetchInvoices() {
    return this.root.transport.billing.invoices().then(
      action((res) => {
        this.invoices = res
      }),
    )
  }

  fetchUpcomingInvoice() {
    return this.root.transport.billing.upcomingInvoice().then(
      action((res) => {
        this.upcomingInvoice = res
      }),
    )
  }

  fetchCards() {
    return this.root.transport.billing.creditCard().then(
      action((res) => {
        this.card = res
      }),
    )
  }

  upgrade(params: UpgradeParams) {
    return this.root.transport.billing.upgrade(params).then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  convertCredits(amount: number) {
    return this.root.transport.billing.convertCredits(amount).then(
      action((res) => {
        this.subscription.deserialize(res.subscription)
      }),
    )
  }

  addCredits(amount: number) {
    return this.root.transport.billing.addCredits(amount).then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  autoCharge(amount: number) {
    return this.root.transport.billing.autoCharge(amount).then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  restart() {
    return this.root.transport.billing.restart().then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  endTrial() {
    return this.root.transport.billing.endTrial().then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  cancel() {
    return this.root.transport.billing.cancel().then(
      action((res) => {
        this.subscription.deserialize(res)
      }),
    )
  }

  upsertCreditCard(token: string) {
    return this.root.transport.billing.upsertCreditCard(token).then(
      action((res) => {
        this.card = res
      }),
    )
  }

  fetchCoupon(code: string) {
    return this.root.transport.billing
      .coupon(code)
      .then(action((res) => (this.coupon = res)))
  }

  private subscribeToWebSocket() {
    this.root.transport.onMessage.subscribe(
      action((message: any) => {
        switch (message.type) {
          case 'subscription-update':
            return this.subscription.deserialize(message.subscription)
        }
      }),
    )
  }
}
