// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import {
  Member,
  Organization,
  PhoneNumberGroup,
  GroupModel,
  User,
} from '../service/model'
import { InputBarDeserializer } from './input-bar'
import { useAppStore } from '@src/app/context'

export interface MentionProps {
  id: string
  className?: string
  fallback?: string
}

interface MentionView {
  displayName: string
  isMe: boolean
  isDeleted: boolean
}

const getMentionView = (
  object: Organization | Member | PhoneNumberGroup | GroupModel,
  {
    currentUser,
    fallbackDisplayName,
  }: {
    currentUser: User
    fallbackDisplayName: string
  },
): MentionView => {
  if (object instanceof Member) {
    const isDeleted = object.status === 'archived'
    const objName = object.name ?? fallbackDisplayName
    return {
      isMe: currentUser.id === object.id,
      displayName: isDeleted ? objName ?? 'Deleted Member' : objName,
      isDeleted,
    }
  }

  if (object instanceof GroupModel) {
    const isDeleted = !!object.deletedAt
    const objName = object.name ?? fallbackDisplayName
    return {
      isMe: false,
      displayName: isDeleted ? objName ?? 'Deleted Group' : objName,
      isDeleted,
    }
  }

  if (object instanceof PhoneNumberGroup) {
    return {
      isMe: object.members.some((member) => member.id === currentUser.id),
      displayName: object.name,
      isDeleted: false,
    }
  }

  if (object instanceof Organization) {
    return {
      isMe: true,
      isDeleted: false,
      displayName: object.name,
    }
  }
}

const getDisplayInfoById = (
  id: string,
  {
    fallbackDisplayName,
  }: {
    fallbackDisplayName: string
  },
): MentionView => {
  const entityType = id.startsWith('US')
    ? 'User'
    : id.startsWith('GR')
    ? 'Group'
    : 'Entity'

  return {
    isMe: false,
    isDeleted: true,
    displayName: fallbackDisplayName ?? `Deleted ${entityType}`,
  }
}

const Mention: React.FC<MentionProps> = function ({ id, className, fallback }) {
  const styles = useStyles({})
  const app = useAppStore()
  const user = app.service.user.current
  const object = useMemo(() => InputBarDeserializer.getModelById(app, id), [id])

  const view = object
    ? getMentionView(object, { currentUser: user, fallbackDisplayName: fallback })
    : getDisplayInfoById(id, { fallbackDisplayName: fallback })

  return (
    <span
      className={cx(
        styles.root,
        view.isMe ? styles.my : styles.their,
        view.isDeleted && styles.deleted,
        className,
      )}
    >
      @{view.displayName}
    </span>
  )
}

export default observer(Mention)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'inline-block',
      borderRadius: 3,
      userSelect: 'text',
      verticalAlign: 'baseline',
      padding: '0 1px',
    },
    their: {
      backgroundColor: theme.palette.op.mention.theirBg,
      color: theme.palette.op.mention.theirName,
    },
    my: {
      backgroundColor: theme.palette.op.mention.myBg,
      color: theme.palette.op.mention.myName,
    },
    deleted: {
      textDecoration: 'line-through',
    },
  }),
  { name: Mention.name },
)
