// @ts-strict-ignore
import VCF from 'vcf'
import { RequestPromise, client } from './api'

export interface VCard {
  // n:
  //   | [
  //       familyNames: string,
  //       givenNames: string,
  //       additionalNames: string,
  //       honorificPrefixes: string,
  //       honorificSuffixes: string,
  //     ]
  //   | null
  fn: string | null
  org: string | null
  // title: string | null
  photo: string | null
}

export function fromUrl(url: string): RequestPromise<VCard> {
  const request = client.get(url)
  const { cancel } = request
  const p: any = request.then((contact) => convertVCard(new VCF().parse(contact)))
  p.cancel = cancel
  return p
}

export function fromFile(file: File): Promise<VCard> {
  return new Promise<VCard>((resolve) => {
    const reader = new FileReader()
    reader.readAsText(file, 'UTF-8')
    reader.onload = (evt) => {
      resolve(convertVCard(new VCF().parse(evt.target.result as string)))
    }
  })
}

// export async function toFile(vcard: VCard): Promise<File> {
//   // TODO
// }

function convertVCard(card: VCF): VCard {
  const fn = (card.get('fn')?.valueOf() as string) ?? null
  const org = (card.get('org')?.valueOf() as string) ?? null
  const photo = (card.get('photo')?.valueOf() as string) ?? null

  return { fn, org, photo }
}

// const resolveProperty = (
//   property: VCF.Property | VCF.Property[],
// ):
//   | [
//       field: string,
//       attributes: { [key: string]: string | string[] },
//       type: string,
//       value: string | string[],
//     ]
//   | null => (Array.isArray(property) ? property[0] : property)?.toJSON() ?? null
