// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { DecodableMessageMedia } from '../service/model'
import useCombinedRefs from '../lib/use-combined-ref'

export interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  media: DecodableMessageMedia
  thumbnail?: boolean
}

const ImageView: React.ForwardRefRenderFunction<{}, ImageProps> = function (
  { media, thumbnail, ...props },
  ref,
) {
  const mediaRef = useCombinedRefs<HTMLImageElement>(ref)

  useEffect(() => {
    if (media.url) {
      mediaRef.current.src = thumbnail ? media.thumbnailUrl : media.url
    } else if (media.file) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => (mediaRef.current.src = reader.result as any),
        false,
      )
      reader.readAsDataURL(media.file)
    }
  }, [media])

  return <img {...props} ref={mediaRef} />
}

export default observer(ImageView, { forwardRef: true })
