// @ts-strict-ignore
export interface ThrottleOptions {
  leading?: boolean
  trailing?: boolean
}

export function throttle<A extends any[], R>(
  func: (...args: A) => R,
  wait: number,
  options?: ThrottleOptions,
): (...args: A) => R {
  var args: any
  var result: any
  var timeout = null
  var previous = 0
  if (!options) options = {}
  var later = function () {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func(...args)
    if (!timeout) args = null
  }
  return function () {
    var now = Date.now()
    if (!previous && options.leading === false) previous = now
    var remaining = wait - (now - previous)
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func(...args)
      if (!timeout) args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}
