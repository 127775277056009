// @ts-strict-ignore
import { action, makeObservable, observable, reaction } from 'mobx'
import { Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { DisposeBag } from '../../lib/dispose'
import { logError } from '../../lib/log'
import {
  ActivityMentionAlert,
  ActivityReactionAlert,
  Alert,
  Collection,
  ContactNoteMentionAlert,
  isThreadAlert,
  ThreadCommentReactionAlert,
  ThreadMentionAlert,
  ThreadReplyAlert,
  ThreadResolutionAlert,
} from '../../service/model'
import { AlertPushNotification } from '../../service/transport/websocket'
import { IUiStore } from '../../types'
import AppStore from '../store'

export type FilterType = 'all' | 'threads'

export class AlertsUiStore implements IUiStore {
  open = false
  filter: FilterType = 'all'
  filteredCollection: Collection<Alert> = null

  pinnedAlertId?: string
  private bindSubscription: Subscription
  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    makeObservable(this, {
      open: observable,
      filter: observable,
      show: action.bound,
      hide: action.bound,
      openAlert: action.bound,
      setFilter: action.bound,
    })

    this.app.service.alert.getAll()

    this.fetchRemote()

    this.disposeBag.add(
      reaction(
        () => this.filter,
        (filter) => {
          this.filteredCollection = new Collection({
            filter: (alert) => {
              if (alert.isDeleted) {
                return false
              } else if (filter === 'threads') {
                return isThreadAlert(alert)
              }
              return true
            },
            compare: (a, b) => b.createdAt - a.createdAt,
          })
          this.bindSubscription?.unsubscribe()
          this.bindSubscription = this.filteredCollection.bind(
            this.app.service.alert.collection,
          )
        },
        { fireImmediately: true },
      ),

      // Fetch changes to alerts when data goes stale
      this.app.onDataNeedsRefresh.subscribe(() => {
        this.fetchRemote()
      }),

      this.registerForNotifications(),
    )
  }

  get list(): Alert[] {
    return this.app.service.alert.collection.list
      .filter((alert) => !alert.isDeleted && alert.associatedObjectsLoaded)
      .sort((a, b) => b.createdAt - a.createdAt)
  }

  get unread(): number {
    return this.list.filter((a) => !a.readAt).length
  }

  get pinToIndex() {
    return this.filteredCollection.indexOf(this.pinnedAlertId)
  }

  show(alertId?: string) {
    this.open = true
    this.pinnedAlertId = alertId
    this.app.service.alert.markRead(this.list.filter((alert) => alert.isUnread))
  }

  hide() {
    this.open = false
  }

  markAllAsOpened = () => {
    this.app.service.alert
      .markOpened(this.list.filter((alert) => alert.isUnopened))
      .then(() => this.app.toast.show({ message: 'Marked all activities as read.' }))
      .catch(this.app.toast.showError)
  }

  openAlert(alert: Alert) {
    this.hide()
    this.app.service.alert.markOpened([alert])

    if (
      alert instanceof ActivityMentionAlert ||
      alert instanceof ThreadCommentReactionAlert ||
      alert instanceof ThreadMentionAlert ||
      alert instanceof ThreadReplyAlert ||
      alert instanceof ThreadResolutionAlert ||
      (alert instanceof ActivityReactionAlert && !alert.conversation?.isDirect)
    ) {
      this.app.inboxes.openConversation(alert.conversation, alert.activity.id)
    } else if (alert instanceof ActivityReactionAlert && alert.conversation?.isDirect) {
      this.app.inboxes.openDirectMessage(
        alert.conversation.phoneNumber,
        alert.conversation,
        alert.activity.id,
      )
    } else if (alert instanceof ContactNoteMentionAlert) {
      this.app.history.push('/contacts')
      this.app.contacts.setSelectedId(alert.contact.id)
    }
  }

  setFilter(filter: FilterType) {
    this.filter = filter
  }

  tearDown() {
    this.bindSubscription?.unsubscribe()
    this.disposeBag.dispose()
  }

  private fetchRemote() {
    this.app.service.alert.fetchMissing().catch(logError)
  }

  private registerForNotifications() {
    return this.app.service.transport.onMessage
      .pipe(
        filter(
          (m): m is AlertPushNotification =>
            m._class === 'PushNotification' && m.data.custom.type === 'alert',
        ),
        map((m) => m.data),
      )
      .subscribe((data) => {
        if (data.custom.silent) return

        const supportsReply: Alert['type'][] = [
          'activity-resolved',
          'activity-unresolved',
          'mention-in-activity-comment',
          'reaction-on-activity-comment',
          'comment-on-activity',
          'mention-in-activity',
          'reaction-on-activity',
        ]

        this.app.notification.show({
          id: data.collapseKey,
          title: data.title,
          body: data.body,
          silent: data.custom.silent,
          sound: 'default',
          hasReply: supportsReply.includes(data.custom.alertType),
          onReply: async (body: string) => {
            const activity = await this.app.service.activity.getById(
              data.custom.activityId,
            )
            activity?.sendComment(body, [])
          },
          onClick: () => {
            this.app.focus()
            this.app.url.route(data.custom.action)
          },
        })
      })
  }
}
