import { makeAutoObservable } from 'mobx'
import Service from '.'
import { map } from '../lib'
import { Blocklist } from './model'
import { PersistedCollection } from './model'
import { BlocklistRepository } from './worker/repository/blocklist'

export default class BlocklistStore {
  collection: PersistedCollection<Blocklist, BlocklistRepository>

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('blocklist'),
      classConstructor: () => new Blocklist(service),
    })
    makeAutoObservable(this, {})
  }

  get byPhoneNumber() {
    return map(this.collection.list, 'phoneNumber')
  }

  fetch = () => {
    this.collection.performQuery((repo) => repo.all())
    return this.service.transport.account.blocklist
      .list()
      .then((items) => this.collection.load(items, { deleteOthers: true }))
  }

  block = (phoneNumber: string) => {
    return this.service.transport.account.blocklist
      .create(phoneNumber)
      .then((res) => this.collection.load(res))
  }

  unblock = async (phoneNumber: string) => {
    const obj = this.byPhoneNumber[phoneNumber]
    if (obj) {
      return this.delete(obj.id)
    }
  }

  delete = (id: string) => {
    this.collection.delete(id)
    return this.service.transport.account.blocklist.delete(id)
  }
}
