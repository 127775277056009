import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import { fonts } from '../../theme'
import { ReactionSize } from '.'

export interface IReactionGroup {
  emoji: string
  variations: string[]
  count: number
  currentUserReacted: boolean
}

export interface ReactionGroupProps
  extends IReactionGroup,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size: ReactionSize
}

const ReactionGroup: React.FC<ReactionGroupProps> = function ({
  size,
  variations,
  count,
  currentUserReacted,
  ...props
}) {
  const styles = useStyles({ size })

  return (
    <div
      {...props}
      className={cx(
        styles.root,
        currentUserReacted ? styles.currentUserReacted : null,
        props.className,
      )}
    >
      {variations.map((body) => (
        <span key={body} className={styles.emoji}>
          {body}
        </span>
      ))}
      <span className={styles.count}>{count}</span>
    </div>
  )
}

export default ReactionGroup

const useStyles = makeStyles<Theme, Partial<ReactionGroupProps>>(
  (theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.op.gray[5],
      borderRadius: 20,
      boxShadow: `0 0 0 2.5px ${theme.palette.background.default}`,
      margin: 2,
      padding: getPadding,
      color: theme.palette.text.secondary,
      cursor: 'pointer',

      '&:before': {
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 'inherit',
        background: theme.palette.op.hover.primary,
        pointerEvents: 'none',
        position: 'absolute',
        opacity: 0,
      },

      '&:hover:before': {
        opacity: 1,
      },

      '& > *:not(:first-child)': {
        marginLeft: getSpacing,
      },
    },
    currentUserReacted: {
      color: theme.palette.op.mention.theirName,
    },
    emoji: {
      fontSize: getEmojiFontSize,
      fontFamily: fonts.emoji,
      position: 'relative',
      top: getTop,
    },
    count: {
      fontSize: getCountFontSize,
      fontWeight: 650,
      fontVariantNumeric: 'tabular-nums',
    },
  }),
  { name: ReactionGroup.name },
)

const getPadding = ({ size }: Partial<ReactionGroupProps>) => {
  switch (size) {
    case 24:
      return '0 7px 0 7px'
    case 20:
      return '0 7px 0 6px'
  }
}

const getSpacing = ({ size }: Partial<ReactionGroupProps>) => {
  switch (size) {
    case 24:
      return 3
    case 20:
      return 3
  }
}

const getEmojiFontSize = ({ size }: Partial<ReactionGroupProps>) => {
  switch (size) {
    case 24:
      return 14
    case 20:
      return 10
  }
}

const getTop = ({ size }: Partial<ReactionGroupProps>) => {
  switch (size) {
    case 24:
      return 1
    case 20:
      return 0
  }
}

const getCountFontSize = ({ size }: Partial<ReactionGroupProps>) => {
  switch (size) {
    case 24:
      return 11
    case 20:
      return 9
  }
}
