// @ts-strict-ignore
import { PopoverOrigin } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo, useRef } from 'react'
import IconButton from '../icon-button'
import {
  AtIcon,
  CommandsIcon,
  EmojiIcon,
  MoreIcon,
  PaperclipIcon,
} from '../icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../popover-menu'
import { isTruthy } from '../../lib/rx-operators'
import Controller from './controller'

export type ActionsVariant = 'full' | 'collapsed'

export interface ActionsProps {
  variant?: ActionsVariant
  className: string
  controller: Controller
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'center' }
const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'center' }

const Actions: React.FC<ActionsProps> = function ({
  className,
  controller,
  variant = 'full',
}) {
  const styles = useStyles({ variant })
  const emojiButtonRef = useRef<HTMLButtonElement>(null)
  const uploadRef = useRef<HTMLInputElement>(null)
  const moreRef = useRef<HTMLButtonElement>(null)

  const handleAttachClicked = useCallback(() => uploadRef.current.click(), [])

  const handleFilesSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (files.length > 0) {
        controller.handleAttachmentsAdd(Array.from(files))
      }
      event.target.value = ''
    },
    [controller],
  )

  const handleEmojiPickerOpen = useCallback(() => {
    controller.openEmojiPicker({
      anchorEl: emojiButtonRef.current,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      transformOrigin: { vertical: 'bottom', horizontal: 'center' },
      onEmojiSelected: controller.handleSelectEmoji,
    })
  }, [controller])

  const closeAfter = useCallback(
    <A extends any[]>(fn: (...args: A) => void) =>
      (...args: A) => {
        fn(...args)
        controller.actions.closeMenu()
      },
    [controller],
  )

  const actions = useMemo(
    () =>
      [
        controller.features.commands
          ? {
              id: 'commands',
              title: 'Commands',
              icon: () => <CommandsIcon />,
              handler: closeAfter(controller.actions.handleCommandButtonClick),
            }
          : null,
        controller.features.mentions
          ? {
              id: 'mentions',
              title: 'Mention someone',
              icon: () => <AtIcon />,
              handler: closeAfter(controller.actions.handleMentionButtonClick),
            }
          : null,
        controller.features.attachments
          ? {
              id: 'upload',
              title: 'Attach a file',
              icon: () => <PaperclipIcon />,
              handler: closeAfter(handleAttachClicked),
            }
          : null,
      ].filter(isTruthy),
    [controller, closeAfter, handleAttachClicked],
  )

  return (
    <div className={cx(className, styles.root)} onClick={controller.handleActionsClick}>
      {variant === 'full' ? (
        actions.map((action) => (
          <IconButton
            key={action.id}
            icon={action.icon()}
            title={action.title}
            tooltipPlacement="top"
            onClick={action.handler}
          />
        ))
      ) : (
        <>
          <PopoverMenu
            open={Boolean(controller.actions.menuOpen)}
            onClose={controller.actions.handleMenuClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            anchorEl={moreRef.current}
          >
            {actions.map((action) => (
              <MenuItem key={action.id} icon={action.icon()} onClick={action.handler}>
                {action.title}
              </MenuItem>
            ))}
          </PopoverMenu>
          <IconButton
            ref={moreRef}
            icon={<MoreIcon />}
            title="More actions"
            tooltipPlacement="top"
            onClick={controller.actions.handleMoreButtonClick}
          />
        </>
      )}
      <IconButton
        ref={emojiButtonRef}
        icon={<EmojiIcon />}
        title="Emoji"
        tooltipPlacement="top"
        onClick={handleEmojiPickerOpen}
      />
      <input
        ref={uploadRef}
        tabIndex={-1}
        type="file"
        multiple
        className={styles.upload}
        onChange={handleFilesSelect}
      />
    </div>
  )
}

export default observer(Actions)

const useStyles = makeStyles<Theme, Partial<ActionsProps>>(
  (theme) => ({
    root: {
      display: 'flex',

      '& > *:not(:last-child)': {
        marginRight: 2,
      },
    },
    upload: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      opacity: 0,
      overflow: 'hidden',
    },
  }),
  { name: Actions.name },
)
