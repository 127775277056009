// @ts-strict-ignore
import { createClient as createElectronClient } from '@openphone/desktop-client'
import React from 'react'
import ReactDOM from 'react-dom'
import Worker from 'worker-loader!./service/worker'
import App from './app'
import AppStore from './app/store'
import { createBrowserHistory } from './lib/history'
import log, { logError } from './lib/log'
import './lib/polyfills'
import Service from './service'

bootstrap()

async function bootstrap() {
  const service = new Service(`/${((fn) => fn.match(/"(.*?)"/)[1])(`${Worker}`)}`)
  const history = createBrowserHistory()

  window.onerror = function (msg, url, lineNo, columnNo, error) {
    const message = typeof msg === 'string' ? msg : 'Unknown error'
    // Benign error that can be ignored https://stackoverflow.com/a/50387233
    if (message === 'ResizeObserver loop limit exceeded') return
    log.error(message, { url, lineNo, columnNo, error })
    return false
  }

  const client = createElectronClient()
  const store = new AppStore(client, history, service)

  if (window.location.hostname === 'my.openphone.co') {
    try {
      // Migrate to .com
      const idToken = service.auth.session?.idToken
      const refreshToken = service.auth.session?.refreshToken

      await store.serviceWorker.unregister().catch(() => null)

      const newUrl = new URL(window.location.pathname, 'https://my.openphone.com')

      if (idToken) {
        newUrl.searchParams.set('session_id_token', idToken)
      }

      if (refreshToken) {
        newUrl.searchParams.set('session_refresh_token', refreshToken)
      }

      window.location.href = newUrl.toString()
      return
    } catch (e) {
      // Log error if migration failed
      logError(e)
    }
  }

  window.app = store

  ReactDOM.render(<App store={store} />, document.getElementById('root'))
}
