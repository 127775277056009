// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { fromEvent } from 'rxjs'
import Image from '../../component/image'
import { MessageMedia } from '../../service/model'

interface ImageViewerProps {
  media: MessageMedia
}

const ImageViewer: React.FC<ImageViewerProps> = function ({ media }) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const optimalScaleRef = useRef<number>()
  const transformRef = useRef({ scale: 1, x: 0, y: 0 })

  function setTransform(scale?: number, x: number = 0, y: number = 0, animate?: boolean) {
    transformRef.current = { scale, x, y }
    imageRef.current.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${scale}, ${scale})`
    if (animate) {
      imageRef.current.style.transition = 'transform 200ms ease 0s'
    } else {
      imageRef.current.style.transition = 'none'
    }
  }

  function handleZoom(
    offsetX: number,
    offsetY: number,
    clientX: number,
    clientY: number,
  ) {
    if (optimalScaleRef.current < transformRef.current.scale) {
      setTransform(optimalScaleRef.current, 0, 0, true)
    } else if (optimalScaleRef.current === transformRef.current.scale) {
      const scale = optimalScaleRef.current < 1 ? 1 : 2
      const { naturalWidth, naturalHeight } = imageRef.current
      let deltaX = naturalWidth / 2 - offsetX
      let deltaY = naturalHeight / 2 - offsetY
      setTransform(scale, deltaX, deltaY, true)
    }
  }

  useEffect(() => {
    function adjustSize(initial: boolean) {
      const { clientHeight, clientWidth } = rootRef.current
      const { naturalHeight, naturalWidth } = imageRef.current
      const overflow = naturalWidth > clientWidth || naturalHeight > clientHeight

      if (overflow) {
        optimalScaleRef.current = Math.min(
          (clientWidth - 175) / naturalWidth,
          clientHeight / naturalHeight,
        )
        if (initial) {
          setTransform(optimalScaleRef.current, 0, 0)
        } else {
          setTransform(
            optimalScaleRef.current,
            transformRef.current.x,
            transformRef.current.y,
          )
        }
      } else {
        optimalScaleRef.current = 1
      }
    }

    const resizeObserver = new ResizeObserver(() => adjustSize(false))
    resizeObserver.observe(rootRef.current)

    const subs = [
      fromEvent(imageRef.current, 'load').subscribe(() => {
        imageRef.current.style.display = 'block'
        adjustSize(true)
      }),
      fromEvent(window, 'resize').subscribe(() => adjustSize(false)),
    ]

    return () => {
      subs.forEach((s) => s.unsubscribe())
      resizeObserver.unobserve(rootRef.current)
    }
  }, [])

  useEffect(() => {
    let active = false
    let initialX, initialY, lastX, lastY

    imageRef.current.addEventListener('touchstart', dragStart, false)
    imageRef.current.addEventListener('mousedown', dragStart, false)
    imageRef.current.addEventListener('touchend', dragEnd, false)
    imageRef.current.addEventListener('mouseup', dragEnd, false)
    rootRef.current.addEventListener('touchend', dragEnd, false)
    rootRef.current.addEventListener('mouseup', dragEnd, false)
    document.addEventListener('touchmove', drag, false)
    document.addEventListener('mousemove', drag, false)
    document.addEventListener('mouseleave', dragEnd, false)

    function dragStart(e) {
      if (e.type === 'touchstart') {
        initialX = e.touches[0].clientX
        initialY = e.touches[0].clientY
      } else {
        initialX = e.clientX
        initialY = e.clientY
      }
      lastX = initialX
      lastY = initialY
      active = true
    }

    function dragEnd(e) {
      active = false

      const t = e.type === 'touchmove' ? e.touches[0] : e
      const currentX = t.clientX
      const currentY = t.clientY
      const offsetX = t.offsetX
      const offsetY = t.offsetY
      const deltaX = currentX - initialX
      const deltaY = currentY - initialY
      const delta = Math.abs(Math.max(deltaX, deltaY))

      if (e.target === imageRef.current && delta < 5) {
        handleZoom(offsetX, offsetY, currentX, currentY)
        e.stopPropagation()
        return
      }
    }

    function drag(e) {
      if (active) {
        e.preventDefault()

        let currentX,
          currentY = 0

        if (e.type === 'touchmove') {
          currentX = e.touches[0].clientX
          currentY = e.touches[0].clientY
        } else {
          currentX = e.clientX
          currentY = e.clientY
        }
        const deltaX = transformRef.current.x + currentX - lastX
        const deltaY = transformRef.current.y + currentY - lastY
        lastX = currentX
        lastY = currentY

        setTransform(transformRef.current.scale, deltaX, deltaY)
      }
    }

    return () => {
      imageRef.current.removeEventListener('touchstart', dragStart, false)
      imageRef.current.removeEventListener('mouseup', dragEnd, false)
      imageRef.current.removeEventListener('touchend', dragEnd, false)
      imageRef.current.removeEventListener('mousedown', dragStart, false)
      rootRef.current.removeEventListener('touchend', dragEnd, false)
      rootRef.current.removeEventListener('mousedown', dragStart, false)
      document.removeEventListener('touchmove', drag, false)
      document.removeEventListener('mousemove', drag, false)
      document.removeEventListener('mouseleave', dragEnd, false)
    }
  }, [])

  return (
    <div ref={rootRef} className={styles.root}>
      <Image ref={imageRef} media={media} tabIndex={-1} className={styles.image} />
    </div>
  )
}

export default observer(ImageViewer)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    outline: 'none',
    position: 'absolute',
    display: 'none',
  },
  enlargable: {
    cursor: 'zoom-in',
  },
}))
