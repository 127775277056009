// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router'
import { useAppStore } from '@src/app/context'
import { isMac } from '../..'
import { useStore } from '../../lib/hooks'
import EmailCode from './code'
import ForgotPassword from './forgot'
import LoginOptions from './login-options'
import Password from './password'
import { LoginUiStore } from './store'
import { useStorePartnerStackKey } from '@src/app/onboarding/partnerStack'

interface LoginProps {}

const LoginStoreContext = React.createContext<LoginUiStore>(null)

const Login: React.FC<LoginProps> = function ({}) {
  const styles = useStyles({})
  const app = useAppStore()
  const store = useStore(() => new LoginUiStore(app), [])

  useStorePartnerStackKey()

  return (
    <LoginStoreContext.Provider value={store}>
      <div className={styles.root}>
        <Switch>
          <Route path={['/login/code', '/signup/code']} component={EmailCode} />
          <Route path="/login/forgot" component={ForgotPassword} />
          <Route path="/login/password" component={Password} />
          <Route path={['/login', '/signup', '/coupon']} component={LoginOptions} />
        </Switch>
      </div>
    </LoginStoreContext.Provider>
  )
}

export default Login

export function useLoginStore() {
  return useContext(LoginStoreContext)
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
}))
