import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { MenuItem } from '../../../popover-menu'
import Typography from '@ui/Typography'
import useKeyStepper, { KeyMappings } from '../../../../lib/use-key-stepper'
import Controller from '../../controller'
import { typography } from '../../../../theme'

export interface EmojisProps {
  controller: Controller
}

const Emojis: React.FC<EmojisProps> = function ({ controller }) {
  const styles = useStyles({})
  const filteredEmojis = controller.menu.filteredEmojis

  const { getItemProps, selectedIndex } = useKeyStepper({
    node: controller.editor.ref.current,
    keys: { ...KeyMappings.VERTICAL, select: ['Enter', 'Tab'] },
    filter: (_, e) => !e.defaultPrevented,
    items: filteredEmojis,
    name: 'component/input-bar/input-menu/emojis',
    handleSelect: (item) => controller.handleSelectEmoji(item.char),
    deps: [controller],
  })

  return (
    <>
      {filteredEmojis.length > 0
        ? filteredEmojis.map(({ char, shortName }, index) => {
            return (
              <MenuItem
                key={char}
                icon={
                  <span className={styles.emoji}>
                    {controller.getEmojiWithSkinTone(char)}
                  </span>
                }
                height="medium"
                highlighted={selectedIndex === index}
                {...getItemProps(index)}
              >
                <Typography color="textPrimary">:{shortName}:</Typography>
              </MenuItem>
            )
          })
        : null}
    </>
  )
}

export default observer(Emojis)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    empty: {
      textAlign: 'center',
    },
    emoji: {
      ...typography.emoji,
    },
  }),
  { name: Emojis.name },
)
