import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Typography from '@ui/Typography'
interface ItemValueProps extends Omit<React.HTMLProps<HTMLDivElement>, 'value'> {
  placeholder?: string
  value: React.ReactNode
  actions?: React.ReactNode
}
const ItemValue: React.ForwardRefRenderFunction<HTMLDivElement, ItemValueProps> =
  function ({ actions, value, className, placeholder, ...props }, outerRef) {
    const styles = useStyles({})
    const hasValue = Array.isArray(value)
      ? value.length > 0
      : value !== '' && value != null

    return (
      <div {...props} ref={outerRef} className={cx(styles.root, className)}>
        {hasValue ? (
          <Typography
            nowrap
            variant="footnote"
            color="textPrimary"
            className={styles.value}
          >
            {value}
          </Typography>
        ) : (
          <Typography
            nowrap
            variant="footnote"
            color="textTertiary"
            className={styles.value}
          >
            {placeholder}
          </Typography>
        )}
        {actions && hasValue && <div className={styles.actions}>{actions}</div>}
      </div>
    )
  }

export default observer(ItemValue, { forwardRef: true })

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    minHeight: 30,
    padding: '0 10px',
    transition: theme.transitions.create(['background-color'], {
      duration: 160,
    }),

    '&:hover': {
      background: theme.palette.op.hover.primary,

      '& $actions': {
        display: 'flex',
        opacity: 1,
      },
    },
  },
  actions: {
    alignItems: 'center',
    display: 'none',
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      duration: 160,
    }),

    '& > *': {
      marginLeft: 2,
    },
  },
  value: {
    flex: 1,
  },
}))
