// @ts-strict-ignore
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { Subject } from 'rxjs'
import slate from 'slate'
import { removeAtIndex } from '@src/lib/collections'
import { getEmojis } from '@src/lib/emoji'
import { DisposeBag } from '@src/lib/dispose'
import shortId from '@src/lib/short-id'
import { Identity, DecodableMessageMedia, Snippet } from '@src/service/model'
import { DeepReadonly } from '@src/types'
import { EmojiPickerProps } from '../emoji-picker'
import BaseEditorController, { TextTarget } from '../editor/controller'
import MenuController from './input-menu/controller'
import { Gif } from './input-menu/commands/giphy'

export type MessageBlock = DeepReadonly<slate.Descendant>
export type Selection = DeepReadonly<slate.Selection>
export type SlateEditor = slate.Editor

export interface Message {
  readonly message: readonly MessageBlock[]
  readonly media: readonly DecodableMessageMedia[]
}

export interface InputBarFeatures {
  attachments: boolean
  commands: boolean
  mentions: boolean
}

export default abstract class Controller {
  abstract features: InputBarFeatures
  abstract giphyApiKey: string
  abstract snippets: Snippet[]
  abstract mentionTargets: Identity[]

  media: DecodableMessageMedia[] = []

  readonly id = shortId()
  readonly debug = Debug(`op:input-bar:controller:@${this.id}`)
  readonly editor = new EditorController(this)
  readonly menu = new MenuController(this)
  readonly actions = new ActionsController(this)
  readonly messages$ = new Subject<Message>()

  protected clearCommandAfterMenuClose = false
  protected disposeBag = new DisposeBag()

  static createSlateEditor(): SlateEditor {
    return EditorController.createEditor()
  }

  constructor(readonly slateEditor: SlateEditor) {
    makeObservable(this, {
      media: observable,
      focused: computed,
      message: computed,
      selection: computed,
      messageSerialized: computed,
      isEmpty: computed,
      isSendEnabled: computed,
      clear: action.bound,
      handleActionsClick: action.bound,
      handleAttachmentsAdd: action.bound,
      handleClickAway: action.bound,
      handleDeleteMedia: action.bound,
      handleDragover: action.bound,
      handleDrop: action.bound,
      handlePaste: action.bound,
      handleSelectEmoji: action.bound,
      handleSelectGif: action.bound,
      handleSelectMention: action.bound,
      handleSelectSnippet: action.bound,
      handleSend: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.menu.mode,
        (currentMenuMode) => {
          if (!currentMenuMode) {
            this.editor.focus()
            if (this.clearCommandAfterMenuClose) this.editor.clearTarget()
            this.clearCommandAfterMenuClose = false
            this.editor.target = null
          }
        },
        { name: 'MenuModeChange' },
      ),

      reaction(
        () => this.menu.commandMode,
        (currentCommandMenuMode) => {
          if (currentCommandMenuMode) {
            // If the snippets/giphy/etc menu is opened, it means the user
            // consciously chose to accept one of the commands in the menu. This
            // flag will determine whether to clear out the command text in the
            // editor if they cancel out of the input menu.
            this.clearCommandAfterMenuClose = true
            this.editor.updateTargetText(`/${currentCommandMenuMode}`)
          } else {
            this.editor.focus()
            this.editor.updateTargetText('/')
          }
        },
        { name: 'CommandMenuModeChange' },
      ),
    )
  }

  get focused(): boolean {
    return this.editor.focused
  }

  get message(): readonly MessageBlock[] {
    return this.editor.value
  }

  set message(value: readonly MessageBlock[]) {
    // TODO: slate types aren't readonly even though it uses immer
    this.editor.value = value as any
  }

  get selection(): Selection {
    return this.editor.selection
  }

  set selection(value: Selection) {
    // TODO: slate types aren't readonly even though it uses immer
    this.editor.selection = value as any
  }

  get messageSerialized(): string {
    return this.editor.valueSerialized
  }

  get isEmpty(): boolean {
    return this.editor.isEmpty && !this.hasMedia
  }

  get isSendEnabled(): boolean {
    return !this.isEmpty && !this.menu.mode
  }

  get hasMedia(): boolean {
    return this.media.length > 0
  }

  abstract openMedia(media: DecodableMessageMedia): void
  abstract openEmojiPicker(props: Omit<EmojiPickerProps, 'open'>): void
  abstract handleNewSnippet(): void
  abstract handleEditSnippet(snippet: Snippet): void

  addAttachments(files: File | readonly File[]) {
    this.media.push(
      ...(files instanceof File ? [files] : files).map((file) => ({
        type: file.type,
        name: file.name,
        file,
      })),
    )
  }

  clear() {
    this.message = []
    this.media = []
    this.selection = null
  }

  handleAttachmentsAdd(files: readonly File[]) {
    if (!this.features.attachments) return
    this.addAttachments(files)
    this.editor.focus()
  }

  handleClickAway() {
    this.menu.close()
  }

  handlePaste(event: ClipboardEvent) {
    if (!this.features.attachments || event.clipboardData.items.length === 0) return
    const file = Array.from(event.clipboardData.items)
      .map((i) => i.getAsFile())
      .find((i) => i)

    if (file) {
      event.preventDefault()
      event.stopPropagation()
      this.addAttachments(file)
    }
  }

  handleDragover(event: DragEvent) {
    if (!this.features.attachments) return
    event.preventDefault()
    event.stopPropagation()
  }

  handleDrop(event: DragEvent) {
    if (!this.features.attachments) return
    event.preventDefault()
    event.stopPropagation()

    if (event.dataTransfer.files.length > 0) {
      this.addAttachments(Array.from(event.dataTransfer.files))
    }
  }

  handleDeleteMedia(item: DecodableMessageMedia) {
    this.media = removeAtIndex(this.media, this.media.indexOf(item))
    this.editor.focus()
  }

  handleSend(): void {
    if (!this.isSendEnabled) return
    this.messages$.next({ message: this.message, media: this.media })
    this.clear()
    this.editor.focus()
  }

  handleSelectGif(gif: Gif) {
    this.menu.handleClose()
    this.editor.focus()
    this.editor.clearTarget()
    this.media.push(this.convertGif(gif))
  }

  handleSelectSnippet(snippet: Snippet) {
    this.menu.handleClose()
    this.editor.focus()
    this.editor.clearTarget()
    this.editor.insertText(snippet.text)
  }

  handleSelectEmoji(emoji: string) {
    emoji = this.getEmojiWithSkinTone(emoji)
    this.menu.handleClose()
    this.editor.focus()
    this.editor.clearTarget()
    this.editor.insertText(emoji)
  }

  handleSelectMention(mention: Identity) {
    this.menu.handleClose()
    this.editor.focus()
    this.editor.clearTarget()
    this.editor.insertMention(mention.id, mention.name)
  }

  handleActionsClick() {
    this.editor.focus()
  }

  tearDown() {
    this.disposeBag.dispose()
    this.editor.tearDown()
  }

  /**
   * Applies the user's skin tone to the given emoji.
   * Should be overridden to get the preferred skin tone from the user's preferences or some other place.
   *
   * @param emoji String emoji
   * @returns Emoji with Skin tone applied if needed
   */
  getEmojiWithSkinTone(emoji: string) {
    return emoji
  }

  protected convertGif(gif: Gif): DecodableMessageMedia {
    return {
      type: 'image/gif',
      name: gif.title,
      url: gif.url,
    }
  }
}

export class ActionsController {
  menuOpen = false

  constructor(protected controller: Controller) {
    makeObservable(this, {
      menuOpen: observable.ref,
      handleCommandButtonClick: action.bound,
      handleMentionButtonClick: action.bound,
      handleMoreButtonClick: action.bound,
      handleMenuClose: action.bound,
      closeMenu: action.bound,
    })
  }

  handleCommandButtonClick() {
    this.controller.editor.startCommand()
  }

  handleMentionButtonClick() {
    this.controller.editor.startMention()
  }

  handleMoreButtonClick() {
    this.menuOpen = true
  }

  handleMenuClose() {
    this.menuOpen = false
  }

  closeMenu() {
    this.menuOpen = false
  }
}

export class EditorController extends BaseEditorController {
  constructor(protected controller: Controller) {
    super(controller.slateEditor)

    makeObservable(this, {
      handleTargetChange: action.bound,
      handleCommand: action.bound,
      handleEmoji: action.bound,
      handleMention: action.bound,
      handleEnter: action.bound,
    })
  }

  handleTargetChange(target: TextTarget | null) {
    if (!target) {
      this.controller.menu.close()
      this.clearTarget()
      return
    }

    switch (target.type) {
      case 'command':
        return this.handleCommand(target.text)
      case 'emoji':
        return this.handleEmoji(target.text)
      case 'mention':
        return this.handleMention(target.text)
    }
  }

  handleCommand(command: string) {
    if (!this.controller.features.commands) return
    const filter = command.substring(1)
    this.debug('detected command, adjusting menu filter (filter: %O)', filter)
    this.controller.menu.mode = 'commands'
    this.controller.menu.commandFilter = filter
  }

  handleEmoji(emoji: string) {
    if (emoji.startsWith(':') && emoji.endsWith(':')) {
      const slug = emoji.substring(1, emoji.length - 1)
      const emojis = getEmojis()
      // TODO: optimize the find
      const char = emojis.find((e) => e.shortName === slug)
      this.debug('emoji submitted (slug: %O) (char: %O)', slug, char)
      if (char) {
        // TODO: this method is called synchronously during a mobx reaction
        // when the editor value changes and handleSelectEmoji makes further
        // editor changes. Is there a better way than setTimeout to stop the
        // loop?
        setTimeout(() => this.controller.handleSelectEmoji(char.char), 0)
      } else {
        this.controller.menu.close()
      }
    } else {
      const filter = emoji.substring(1)
      this.debug('detected emoji, adjusting menu filter (filter: %O)', filter)
      this.controller.menu.mode = 'emojis'
      this.controller.menu.emojiFilter = filter
    }
  }

  handleMention(mention: string) {
    if (!this.controller.features.mentions) return
    const filter = mention.substring(1)
    this.debug('detected mention, adjusting menu filter (filter: %O)', filter)
    this.controller.menu.mode = 'mentions'
    this.controller.menu.mentionFilter = filter
  }

  handleEnter() {
    this.controller.handleSend()
  }
}
