import merge from 'lodash/fp/merge'

export interface BannerConfig {
  'co-to-com': boolean
  notifications: boolean
  referral: boolean
  lightMode: boolean
}

interface UserConfig {
  banner: BannerConfig
}

const KEY = 'userconfig'

let config: UserConfig = {
  banner: {
    'co-to-com': true,
    notifications: true,
    referral: true,
    lightMode: true,
  },
}

const load = () => {
  const cache = localStorage.getItem(KEY)
  if (cache) {
    config = merge(config, JSON.parse(cache))
  }
}

const save = () => {
  setTimeout(() => localStorage.setItem(KEY, JSON.stringify(config)))
}

export const getBannerConfig = (key: keyof BannerConfig): boolean => {
  return config.banner[key]
}

export const setBannerConfig = (key: keyof BannerConfig, value: boolean) => {
  config.banner[key] = value
  save()
}

load()
