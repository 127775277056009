import MuiNativeSelect, {
  NativeSelectProps as MuiNativeSelectProps,
} from '@material-ui/core/NativeSelect'
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select'
import React, { forwardRef } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { typography } from '../theme'
import { InputSize } from '@ui/TextInput'
import { ChevronDownIcon } from './icons/Tint/08'

interface NativeSelectProps extends MuiNativeSelectProps {
  size?: InputSize
}

interface SelectProps extends MuiSelectProps {
  size?: InputSize
}

export const NativeSelect: React.FC<NativeSelectProps> = function ({
  size = 45,
  ...props
}) {
  const classes = useNativeSelectStyles({ size })
  return (
    <MuiNativeSelect
      IconComponent={ChevronDownIcon}
      disableUnderline
      classes={classes}
      {...props}
    />
  )
}

export const Select = forwardRef(function ({ size = 45, ...props }: SelectProps, ref) {
  const classes = useNativeSelectStyles({ size })
  return (
    <MuiSelect
      IconComponent={ChevronDownIcon}
      disableUnderline
      classes={classes}
      {...props}
    />
  )
})

export const useNativeSelectStyles = makeStyles<Theme, NativeSelectProps>((theme) => ({
  root: ({ size }) => ({
    ...(size === 45 ? typography.body : typography.footnote),
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: theme.palette.op.borderRadius.dropdown,
    color: theme.palette.op.text.primary,
    height: size,
    padding: size === 45 ? '0 16px' : '0 12px',
    transition: theme.transitions.create(['box-shadow'], {
      easing: 'ease',
      duration: 240,
    }),
    boxShadow: theme.palette.op.shadow.dropdown.inactive,

    '&:disabled': { pointerEvents: 'none' },

    '&:invalid': { color: theme.palette.op.text.placeholder },
  }),
  select: {
    paddingRight: `35px !important`,

    '&:disabled': {
      color: theme.palette.op.gray[1],
      boxShadow: theme.palette.op.shadow.dropdown.disabled,
      backgroundColor: theme.palette.op.match({
        light: 'rgba(188, 188, 200, 0.04)',
        dark: 'rgba(75, 75, 102, 0.04)',
      }),

      '& + $icon': {
        color: theme.palette.op.gray[3],
      },
    },

    '&:not(:disabled):hover': {
      backgroundColor: theme.palette.op.match({
        light: 'rgba(188, 188, 200, 0.06)',
        dark: 'rgba(75, 75, 102, 0.06)',
      }),
      boxShadow: theme.palette.op.shadow.dropdown.hover,

      '& + $icon': {
        color: theme.palette.op.gray[2],
      },
    },

    '&:not(:disabled):focus': {
      background: 'transparent',
      borderRadius: theme.palette.op.borderRadius.dropdown,
      boxShadow: theme.palette.op.shadow.dropdown.active,

      '& + $icon': {
        color: theme.palette.op.primary[2],
      },
    },
  },
  icon: {
    top: 'calc(50% - 4px)',
    right: ({ size }) => (size === 45 ? 16 : 12),
    color: theme.palette.op.gray[3],
  },
}))
