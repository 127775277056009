import Transport from '.'
import config from '../../config'
import { HttpTransaction } from './transaction'

export default class AuthClient {
  constructor(private transport: Transport) {}

  signin = {
    exists: (email: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AUTH_SERVICE_URL}signin/exists`,
          query: { email },
        }),
      )
    },

    sendCode: (email: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH0_BASE_URL}passwordless/start`,
          body: {
            client_id: config.AUTH0_CLIENT_ID,
            email: email,
            connection: 'email',
            send: 'code',
          },
        }),
      )
    },

    verifyCode: (email: string, code: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}signin/code`,
          body: { email, code },
        }),
      )
    },

    password: (email: string, password: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}signin/password`,
          body: { email, password },
        }),
      )
    },

    google: (accessToken: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}signin/social`,
          body: {
            accessToken,
            connection: 'google-oauth2',
          },
        }),
      )
    },

    forgotPassword: (email: string): Promise<string> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}signin/forgot`,
          body: { email },
        }),
      )
    },

    refreshToken: (refreshToken: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}signin/refresh`,
          body: { refreshToken },
        }),
      )
    },
  }

  register = {
    sendCode: (email: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH0_BASE_URL}passwordless/start`,
          body: {
            client_id: config.AUTH0_CLIENT_ID,
            email: email,
            connection: 'email',
            send: 'code',
          },
        }),
      )
    },

    verifyCode: (email: string, code: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}verifyCode`,
          body: { email, code },
        }),
      )
    },

    password: (email: string, password: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}login`,
          body: { email, password },
        }),
      )
    },

    google: (accessToken: string): Promise<LoginResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.AUTH_SERVICE_URL}social`,
          body: {
            accessToken,
            connection: 'google-oauth2',
          },
        }),
      )
    },
  }

  setPassword(password: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.AUTH_SERVICE_URL}password`,
        body: { password },
      }),
    )
  }

  changePassword(oldPassword: string, password: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.AUTH_SERVICE_URL}password`,
        body: { oldPassword, password },
      }),
    )
  }

  changeEmail(email: string) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.AUTH_SERVICE_URL}email/sendCode`,
        body: { email },
      }),
    )
  }

  verifyChangeEmail(email: string, code: string) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'patch',
        url: `${config.AUTH_SERVICE_URL}email/verify`,
        body: { email, code },
      }),
    )
  }
}

export interface LoginResponse {
  id_token: string
  scope: string
  refresh_token: string
  access_token: string
  token_type: string
}
