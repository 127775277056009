import React, { useState } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import Tooltip from '../../component/tooltip'
import { observer } from 'mobx-react-lite'

interface SideMenuRowProps extends React.HTMLProps<HTMLDivElement> {
  title?: string
  shortcut?: string
  icon: React.ReactNode
  highlighted?: boolean
  rootClassName?: string
  accessory?: React.ReactNode | ((hover: boolean) => React.ReactNode)
}

const SideMenuRow = observer<SideMenuRowProps, HTMLDivElement>(
  (
    {
      title,
      shortcut,
      highlighted,
      className,
      icon,
      children,
      rootClassName,
      accessory,
      ...props
    },
    ref,
  ) => {
    const styles = useStyles({})
    const [hovered, setHovered] = useState(false)
    const view = typeof accessory === 'function' ? accessory(hovered) : accessory

    const component = (
      <div
        {...props}
        ref={ref}
        role="button"
        className={cx(styles.root, rootClassName, highlighted && styles.selected)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Typography className={styles.icon} color="textSecondary">
          {icon}
        </Typography>
        <Typography variant="footnote" className={cx(styles.item, className)}>
          {children}
        </Typography>
        <div className={styles.accessory}>{view}</div>
      </div>
    )

    if (title) {
      return (
        <Tooltip title={title} shortcut={shortcut} placement="right">
          {component}
        </Tooltip>
      )
    }
    return component
  },
  { forwardRef: true },
)

export default SideMenuRow

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    position: 'relative',
    cursor: 'pointer',
    height: 30,
    color: theme.palette.text.primary,
    borderRadius: 5,
    zIndex: 1,
    margin: '0 8px 2px',

    '&:hover::before': {
      opacity: 1,
    },

    '&$selected::after': {
      opacity: 1,
    },

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.palette.op.hover.primary,
      opacity: 0,
      borderRadius: 'inherit',
      zIndex: -1,
    },
  },
  selected: {},
  icon: {
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    marginLeft: 11,
    flex: 1,
    minWidth: 0,
  },
  accessory: {
    display: 'flex',
    alignItems: 'center',
  },
}))
