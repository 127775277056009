import React, { useState, useEffect } from 'react'
import LocationIcon from '@material-ui/icons/RoomOutlined'
import useStyles from './styles'

type AreaCodeFilterProps = {
  onChange: (areaCode: string) => void
}

const AreaCodeFilter: React.FC<AreaCodeFilterProps> = function ({ onChange }) {
  const styles = useStyles({})
  const [areaCode, setAreaCode] = useState('')

  const onInputChange = (event: React.FormEvent) => {
    const value = (event.target as HTMLInputElement).value
    setAreaCode(value.replace(/\D/g, '').substr(0, 3))
  }

  useEffect(() => {
    if (areaCode.length === 3) {
      onChange(areaCode)
    }
  }, [areaCode])

  return (
    <div className={styles.filter}>
      <LocationIcon fontSize="small" className={styles.icon} />
      <input
        value={areaCode}
        type="text"
        placeholder="Enter an area code (e.g. 415)"
        onChange={onInputChange}
      />
    </div>
  )
}

export default AreaCodeFilter
