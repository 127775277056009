import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import useTitle from '../../lib/use-title'
import ContactDetails, { ContactDetailsMethods } from './details'
import ContactList from './list'

interface ContactsProps {}

const Contacts: React.FC<ContactsProps> = function ({}) {
  const styles = useStyles({})
  const listRef = useRef<ContactDetailsMethods>(null)
  const { history, service, toast, contacts } = useAppStore()

  useEffect(() => {
    const code = history.consumeQueryParam('code')

    if (code) {
      service.contact
        .googleSync(code, `${window.location.origin}/contacts`)
        .catch(toast.showError)
    }
  }, [])

  useEffect(() => {
    contacts.loadDataIfNecessary()
  }, [])

  useTitle('Contacts')

  return (
    <div className={styles.root}>
      <ContactList />
      <ContactDetails ref={listRef} />
    </div>
  )
}

export default Contacts

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
}))
