// @ts-strict-ignore
import { action, computed, makeObservable } from 'mobx'
import Controller, { CompanyView } from '../command/contact-filters/companies/controller'
import AppStore from '../store'
import ContactsUiStore from './store'

export default class CompanyFilterController extends Controller {
  constructor(protected app: AppStore, protected store: ContactsUiStore) {
    super()

    makeObservable(this, {
      companies: computed,
      handleItemToggle: action.bound,
    })
  }

  get companies(): CompanyView[] {
    if (!this.app || !this.store) return []

    const data = this.app.service.contact.collection.list.reduce((acc, value) => {
      if (value.company) {
        if (acc[value.company]) {
          acc[value.company] += 1
        } else {
          acc[value.company] = 1
        }
      }
      return acc
    }, {})

    return Object.keys(data).map((key, index) => ({
      id: `${index}`,
      name: key,
      count: data[key],
      checked: this.store.view.company?.includes(key),
    }))
  }

  handleItemToggle(item: CompanyView) {
    const { view } = this.store
    if (item.checked) {
      view.company = (view.company ?? []).filter((c) => c !== item.name)
    } else {
      view.company = [...(view.company ?? []), item.name]
    }
  }
}
