// @ts-strict-ignore
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import RadioIcon from '@material-ui/icons/RadioButtonUnchecked'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../../component/button'
import { Dialog, DialogActions, DialogContent } from '../../../../component/dialog'
import { CircularProgress } from '../../../../component/progress'
import { countryForAreaCode, formatted } from '../../../../lib/phone-number'
import useInputState from '../../../../lib/use-input-state'
import { AvailablePhoneNumber } from '../../../../types'
import AreaCodeFilter from './area-code-filter'
import ContainsFilter from './contains-filter'
import LocationFilter, { Location } from './location-filter'

enum Type {
  ByCity,
  ByAreaCode,
  TollFree,
}

type PickNumberProps = {
  open: boolean
  confirm?: boolean
  onClose?: () => void
  onSelect?: (number: AvailablePhoneNumber) => void
}

const PickNumber: React.FC<PickNumberProps> = function ({
  open,
  confirm,
  onClose,
  onSelect,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const [type, setType] = useState<Type>(Type.ByCity)
  const [areaCode, setAreaCode] = useState<string>(null)
  const [location, setLocation] = useState<Location>(null)
  const [contains, setContains] = useInputState('')
  const [number, setNumber] = useState<AvailablePhoneNumber>(null)
  const subscription = service.billing.subscription
  const phoneNumbers = service.organization.phoneNumber.collection.length
  const members = service.member.collection.length

  /**
   * every member comes with 1 phone number for free.
   */
  const isPhoneNumberFree = members > phoneNumbers

  const isAnnualPlan = Boolean(service.billing.subscription.annual)

  const [searchState, runSearch] = useStatefulPromise<AvailablePhoneNumber[]>(() => {
    switch (type) {
      case Type.ByCity:
        return service.organization.phoneNumber.search({
          inLocality: location ? location.locality : null,
          inRegion: location ? location.region : null,
          country: location ? location.country : null,
          contains: contains ? contains : null,
        })
      case Type.ByAreaCode:
        return service.organization.phoneNumber.search({
          areaCode: areaCode ? areaCode : null,
          contains: contains ? contains : null,
          country: areaCode ? countryForAreaCode(areaCode) : null,
        })
      case Type.TollFree:
        return service.organization.phoneNumber.search({
          contains: contains ? contains : null,
          tollFree: true,
        })
    }
  })

  useEffect(() => {
    if (contains.length !== 1) {
      runSearch()
    }
  }, [location, areaCode, type, contains])

  useEffect(() => {
    if (!open) {
      setNumber(null)
    }
  }, [open])

  const region = (number: AvailablePhoneNumber): string => {
    return number.region
      ? [number.locality, number.region].filter((a) => a).join(', ')
      : 'Toll-Free'
  }

  const handleSelect = (index: number) => {
    if (confirm) {
      setNumber(searchState.data[index])
    } else {
      onClose()
      onSelect(searchState.data[index])
    }
  }

  const handleConfirm = () => {
    onClose()
    onSelect(number)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: number ? null : styles.root }}
    >
      {confirm && number ? (
        <>
          <DialogContent>
            <Typography variant="h4" color="textPrimary">
              {number.formatted}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {region(number)}
            </Typography>
            <Typography variant="body2" color="textPrimary" style={{ marginTop: 30 }}>
              {isPhoneNumberFree
                ? 'Are you sure you want to add this phone number to your workspace?'
                : isAnnualPlan
                ? 'By adding this number to your workspace your account will be charged a prorated annual amount at a cost of $5/month. Are you sure you want to continue?'
                : 'By adding this number to your workspace your account will be charged an additional $5/month. Are you sure you want to continue?'}
            </Typography>
            {subscription.trial && (
              <Typography variant="body2" color="textPrimary" style={{ marginTop: 20 }}>
                Note: By confirming this purchase, your free trial will expire and you
                will be charged right away.
              </Typography>
            )}
          </DialogContent>
          <DialogActions style={{ padding: '30px 10px 15px' }}>
            <Button
              variant="text"
              color="default"
              style={{ marginRight: 5 }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Add number
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <div>
            <Tabs value={type} onChange={(_, value) => setType(value)}>
              <Tab className={styles.tab} label="By City" value={Type.ByCity} />
              <Tab className={styles.tab} label="By Area Code" value={Type.ByAreaCode} />
              <Tab className={styles.tab} label="Toll-Free" value={Type.TollFree} />
            </Tabs>
          </div>
          <DialogContent className={styles.body}>
            {type === Type.ByCity ? (
              <LocationFilter location={location} onChange={setLocation} />
            ) : (
              type === Type.ByAreaCode && <AreaCodeFilter onChange={setAreaCode} />
            )}
            <ContainsFilter value={contains} onChange={setContains} />
            {searchState.status === PromiseStateStatus.Loading && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '30px 0',
                }}
              >
                <CircularProgress size={30} color="inherit" />
              </div>
            )}
            {searchState.data && searchState.data.length > 0 ? (
              <List>
                {searchState.data.map((number, index) => (
                  <ListItem
                    button
                    className={styles.row}
                    key={number.phoneNumber}
                    onClick={() => handleSelect(index)}
                  >
                    <ListItemIcon>
                      <RadioIcon style={{ opacity: 0.1 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <div className={styles.item}>
                          <Typography variant="body1" color="textPrimary">
                            {formatted(number.phoneNumber, contains)}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {region(number)}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              searchState.data && (
                <Typography
                  className={styles.emptyList}
                  variant="body2"
                  color="textSecondary"
                >
                  No numbers found
                </Typography>
              )
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default PickNumber

const useStyles = makeStyles((theme) => ({
  root: {
    height: 600,
  },
  body: {
    padding: '10px 0 0',
  },
  emptyList: {
    textAlign: 'center',
    padding: '3em 2em',
  },
  row: {
    padding: '0 2em',
  },
  tab: {
    minHeight: 60,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 0',
  },
}))
