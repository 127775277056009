// @ts-strict-ignore
const callEnded = require('./call-ended.mp3')
const callStarted = require('./call-started.mp3')
const error = require('./error.mp3')
const missedCall = require('./missed-call.mp3')
const newMessage = require('./new-message.mp3')
const primaryRingtone = require('./primary-ringtone.mp3')
const reaction = require('./reaction.mp3')
const secondaryNotification = require('./secondary-notification.mp3')
const secondaryRingtone = require('./secondary-ringtone.mp3')
const textRecieved = require('./text-received.mp3')

export class Sound {
  private urls = {
    callEnded,
    callStarted,
    error,
    missedCall,
    newMessage,
    primaryRingtone,
    reaction,
    secondaryNotification,
    secondaryRingtone,
    textRecieved,
  }

  private cache = new Map<string, HTMLAudioElement>()

  constructor() {}

  url(name: keyof Sound['urls']) {
    return this.urls[name].default
  }

  play(name: keyof Sound['urls']) {
    if (!this.cache.has(name)) {
      this.cache.set(name, new Audio(this.url(name)))
    }
    const audio = this.cache.get(name)
    return audio.play().catch((error) => {
      if (error instanceof DOMException && error.name === 'NotAllowedError') return
      throw error
    })
  }
}
