import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@ui/Typography'

interface SectionHeaderProps {
  title: string
  subtitle: string
  action?: ReactNode
}

export function SectionHeader({ title, subtitle, action }: SectionHeaderProps) {
  const styles = useStyles()

  return (
    <header className={styles.root}>
      <div className={styles.leftColumn}>
        <Typography variant="title3">{title}</Typography>
        <Typography variant="body" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
      {action}
    </header>
  )
}

export default SectionHeader

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  leftColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    rowGap: 6,
  },
})
