import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import keyboard from '../../../lib/keyboard'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Header from '../common/header'
import Textfield from '../common/textfield'

export interface ChangeEmailCommandProps {}

const ChangeEmailCommand: React.FC<ChangeEmailCommandProps> = function ({}) {
  const { command, service, toast } = useAppStore()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useInputState('')

  const handleNext = () => {
    if (!email) return
    setLoading(true)
    service.auth
      .changeEmail(email)
      .then(() => {
        setLoading(false)
        command.present({ name: 'change email code', email })
      })
      .catch((e) => {
        setLoading(false)
        toast.showError(e)
      })
  }

  return (
    <Command>
      <Header
        title="Change your email address"
        subtitle="We will send a code to your new email address"
      />
      <Textfield
        autoFocus
        placeholder="Enter new email address"
        value={email}
        onChange={setEmail}
        onKeyDown={keyboard.onEnter(handleNext)}
      />
      <Footer
        actions={
          <FooterButton
            variant="contained"
            disabled={!Boolean(email) || loading}
            onClick={handleNext}
          >
            Send code
          </FooterButton>
        }
      />
    </Command>
  )
}

export default ChangeEmailCommand
