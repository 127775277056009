import { useCallback, useState } from 'react'

export enum PromiseStateStatus {
  'Idle' = 'idle',
  'Success' = 'success',
  'Loading' = 'loading',
  'Failed' = 'failed',
}

export type PromiseState<T> =
  | {
      /**
       * The resolved value of the promise.
       */
      data: null

      /**
       * The error that caused the promise to reject.
       */
      error: null

      /**
       * The current status of the promise invocation.
       *
       * Possible values:
       * - 'idle': the promise has not yet been invoked
       * - 'loading': the promise is still being invoked
       * - 'success': the promise has been resolved, see {@link PromiseState.data}
       * - 'failed': the promise has been rejected, see {@link PromiseState.error}
       */
      status: PromiseStateStatus.Idle
    }
  | {
      data: null
      error: null
      status: PromiseStateStatus.Loading
    }
  | {
      data: T
      error: null
      status: PromiseStateStatus.Success
    }
  | {
      data: null
      error: Error
      status: PromiseStateStatus.Failed
    }

/**
 * Returns the state of a lazily invoked promise, and a function to invoke it.
 */
const useStatefulPromise = <T = void, K = never>(
  promise: (...args: K[]) => Promise<T>,
): [PromiseState<T>, (...args: K[]) => Promise<T>] => {
  const [state, setState] = useState<PromiseState<T>>({
    data: null,
    status: PromiseStateStatus.Idle,
    error: null,
  })

  const run = useCallback(
    (...args: K[]) => {
      setState({
        data: null,
        status: PromiseStateStatus.Loading,
        error: null,
      })
      return promise(...args)
        .then((response) => {
          setState({
            data: response,
            status: PromiseStateStatus.Success,
            error: null,
          })
          return response
        })
        .catch((error) => {
          setState({
            data: null,
            status: PromiseStateStatus.Failed,
            error: error,
          })
          return error
        })
    },
    [promise],
  )

  return [state, run]
}

export default useStatefulPromise
