// @ts-strict-ignore
import { makeStyles, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../../../component/icon-button'
import {
  DeleteIcon,
  MoreIcon,
  ReplaceIcon,
} from '../../../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../../../component/popover-menu'
import Typography from '@ui/Typography'
import { DomainModel } from '../../../../service/model/DomainModel'
import dayjs from 'dayjs'

interface DomainListProps extends React.HTMLProps<HTMLDivElement> {}

const DomainList: React.FC<DomainListProps> = ({ className, ...props }) => {
  const styles = useDomainListStyles()
  const { workspace } = useAppStore()

  useEffect(() => {
    workspace.fetchDomains()
  }, [])

  return (
    <div {...props} className={cx(styles.root, className)}>
      {workspace.domains.map((item) => (
        <DomainRow item={item} key={item.id} />
      ))}
    </div>
  )
}

const DomainRow = observer(({ item }: { item: DomainModel }) => {
  const { toast, command, workspace, service } = useAppStore()
  const theme = useTheme()
  const styles = useDomainRowStyles()
  const [showMore, setShowMore] = useState(false)
  const moreRef = useRef()

  const handleDeleteDomain = () => {
    workspace.deleteDomain(item.id).catch(toast.showError)

    workspace.domains = workspace.domains.filter((domain) => domain.id !== item.id)

    setShowMore(false)
    toast.show({ message: 'The domain has been removed' })
  }

  const handleResendCode = () => {
    workspace.resendCode(item.id)
    setShowMore(false)
    toast.show({ message: 'The code has been sent' })
    command.present({ name: 'verify domain', email: item.verificationEmail })
  }

  return (
    <div className={styles.root}>
      <Typography variant="footnote">{item.domain}</Typography>
      <Typography
        variant="caption2"
        color="textSecondary"
        className={styles.createdAt}
      >{`Added ${dayjs(item.createdAt).format('MMM DD')}`}</Typography>
      <div className={styles.actionContainer}>
        {!item.verified && (
          <Typography variant="caption2" className={styles.unverifiedTxt}>
            Unverified
          </Typography>
        )}
        {(service.user.current.asMember.isAdmin ||
          service.user.current.asMember.isOwner) && (
          <>
            <IconButton
              ref={moreRef}
              onClick={() => setShowMore(true)}
              icon={<MoreIcon />}
            />
            <PopoverMenu
              open={showMore}
              onClose={() => setShowMore(false)}
              anchorEl={moreRef.current}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: -8, horizontal: 'right' }}
            >
              {!item.verified && (
                <MenuItem icon={<ReplaceIcon />} onClick={handleResendCode}>
                  Resend code
                </MenuItem>
              )}
              <MenuItem
                icon={<DeleteIcon color={theme.palette.op.secondary.red2} />}
                onClick={handleDeleteDomain}
              >
                <Typography color="error">Remove domain</Typography>
              </MenuItem>
            </PopoverMenu>
          </>
        )}
      </div>
    </div>
  )
})

const useDomainRowStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.op.hover.primary,
    height: 40,
    padding: '5px 9px 5px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    marginBottom: 6,
    '& > *': {
      flex: 1,
    },
  },
  createdAt: {
    textAlign: 'center',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  unverifiedTxt: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 26,
    color: '#e27e02',
    backgroundColor: 'rgba(252, 162, 50, 0.15)',
    padding: '4px 5px',
    borderRadius: 4,
    height: 22,
  },
}))

const useDomainListStyles = makeStyles(() => ({
  root: {},
}))

export default observer(DomainList)
