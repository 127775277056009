import { useLayoutEffect, useRef, useState } from 'react'

export default function useIsOverflowing<T extends HTMLElement>() {
  const elementRef = useRef<T>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useLayoutEffect(() => {
    if (!elementRef.current) return

    if (elementRef.current.offsetWidth < elementRef.current.scrollWidth) {
      setIsOverflowing(true)
    }
  }, [])

  return { isOverflowing, ref: elementRef }
}
