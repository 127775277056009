// @ts-strict-ignore
import { action, makeAutoObservable, observable } from 'mobx'
import { compareString, insertAtIndex, removeAtIndex } from '../../lib'
import { Conversation, Member, PhoneNumber } from '../../service/model'
import AppStore from '../store'
import analytics from '@src/lib/analytics'
import { InboxUiStore } from '@src/app/inbox/store'

export default class SideMenuUiStore {
  // controls the more menu for each inbox
  moreMenu: {
    anchor: { left: number; top: number }
    phoneNumber: PhoneNumber
  } = null

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      isInbox: false,
      isContacts: false,
      isAnalytics: false,
      isSettings: false,
      moreMenu: observable.ref,
    })
  }

  get dms(): { member: Member; conversation: Conversation }[] {
    const user = this.root.service.user.current
    const members = this.root.service.member.collection.list
    const conversations = this.root.inboxes.dm?.conversations.list ?? []

    return members
      .map((member) => ({
        member,
        conversation: conversations.find(
          (c) => c.phoneNumber === member.directNumber && !c.isArchived,
        ),
      }))
      .filter((dm) => Boolean(dm.member.name))
      .sort(
        (dm1, dm2) => dm1.conversation?.lastActivityAt - dm2.conversation?.lastActivityAt,
      )
      .sort((dm1, dm2) => {
        if (dm1.member.id === user.id) return -1
        if (dm2.member.id === user.id) return 1
        return compareString(dm1.member.name, dm2.member.name, { caseInsensitive: true })
      })
  }

  get isInbox(): boolean {
    return this.root.history.location.pathname.startsWith('/inbox')
  }

  get isContacts(): boolean {
    return this.root.history.location.pathname.startsWith('/contacts')
  }

  get isAnalytics(): boolean {
    return this.root.history.location.pathname.startsWith('/analytics')
  }

  get isSettings(): boolean {
    return (
      this.root.history.location.pathname.startsWith('/settings') ||
      location.pathname.startsWith('/account')
    )
  }

  get isAlerts(): boolean {
    return this.root.alerts.open
  }

  handleSearch = () => {
    analytics.click('Search')
    this.root.command.present({ name: 'search' })
  }

  handleAlerts = () => {
    analytics.click('Activity')
    this.root.alerts.show()
  }

  handleAnalytics = () => {
    analytics.click('Analytics', '/analytics')
    this.root.history.push('/analytics')
  }

  handleContacts = () => {
    analytics.click('Contacts', '/contacts')
    this.root.history.push('/contacts')
  }

  handleSettings = () => {
    analytics.click('Settings', '/settings')
    this.root.history.push('/settings')
  }

  handleInvite = () => {
    analytics.click('Invite team')
    this.root.command.present({ name: 'invite member' })
  }

  handleReferFriend = () => {
    analytics.click('Refer a friend')
    this.root.command.present({ name: 'referral' })
  }

  handleDirectMessage = ({
    member,
    conversation,
  }: {
    member: Member
    conversation: Conversation
  }) => {
    analytics.click('Open direct message')
    this.root.inboxes.openDirectMessage(member.directNumber, conversation)
  }

  handleCopy = (e: any, text: string) => {
    analytics.click('Copy inbox phone number to clipboard')
    e.stopPropagation()
    navigator.clipboard.writeText(text)
    this.root.toast.show({ message: 'Copied to clipboard.', duration: 1000 })
  }

  handleInboxSelection = (inbox: InboxUiStore) => {
    analytics.click('Open inbox')
    this.root.inboxes.setSelected(inbox)
  }

  openEmojiSelector = (
    event: React.MouseEvent<HTMLDivElement>,
    phoneNumber: PhoneNumber,
  ) => {
    event.stopPropagation()
    this.root.showEmojiPicker({
      anchorEl: event.target as HTMLDivElement,
      onEmojiSelected: action((emoji) => {
        phoneNumber.update({ symbol: emoji })
      }),
    })
  }

  openMoreMenu = (event, phoneNumber: PhoneNumber) => {
    analytics.click('Open inbox menu')
    event.stopPropagation()
    this.moreMenu = { anchor: { left: event.clientX, top: event.clientY }, phoneNumber }
  }

  handleMute = (phoneNumber: PhoneNumber) => {
    this.hideMoreMenu()
    if (phoneNumber.mutedUntil > Date.now()) {
      analytics.click('Unmute inbox')
      phoneNumber.unmute()
      this.root.toast.show({ message: 'Unmuted.' })
    } else {
      analytics.click('Mute inbox')
      this.root.command.present({ name: 'mute phone number', phoneNumber })
    }
  }

  handlePhoneSettings = (phoneNumber: PhoneNumber) => {
    const path = `/settings/phone-numbers/${phoneNumber.id}`
    analytics.click('Inbox settings', path)
    this.hideMoreMenu()
    this.root.history.push(path)
  }

  hideMoreMenu = () => {
    this.moreMenu = null
  }

  handleInboxDrop = (drop: { removedIndex: number; addedIndex: number }) => {
    if (!drop) return
    const { removedIndex, addedIndex } = drop
    const sortedIds = this.root.inboxes.sorted.map((i) => i.id)
    const item = sortedIds[removedIndex]
    this.root.service.user.current.settings.shared.phoneNumberOrder = insertAtIndex(
      removeAtIndex(sortedIds, removedIndex),
      item,
      addedIndex,
    )
  }
}
