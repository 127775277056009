import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { Command } from './common-v2'
import Content from './common/content'
import Footer from './common/footer'
import FooterButton from './common/footer-button'
import Header from './common/header'

export interface RequiresPremiumProps {}

const RequiresPremium: React.FC<RequiresPremiumProps> = function ({}) {
  const styles = useStyles({})
  const { command, history } = useAppStore()

  const handleCompare = () => {
    history.push('/settings/billing/plans')
    command.hide()
  }

  return (
    <Command>
      <Header title="Premium Required" />
      <Content>
        <div className={styles.content}>
          This feature is only available on the Premium plan. To use it, upgrade your
          subscription and try again.
        </div>
      </Content>
      <Footer
        actions={
          <FooterButton variant="contained" onClick={handleCompare}>
            Compare Plans
          </FooterButton>
        }
      ></Footer>
    </Command>
  )
}

export default RequiresPremium

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem',
    fontSize: '0.9rem',
    lineHeight: 1.5,
  },
}))
