// @ts-strict-ignore
import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CircularProgress } from '../../../component/progress'
import Typography from '@material-ui/core/Typography'
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone'

interface FileSelectorProps {
  accept: string[]
  placeholder?: string
  loading?: boolean
  errorMessage?: string
  onComplete: (file: File) => void
}

const FileSelector: React.FC<FileSelectorProps> = function ({
  accept,
  loading,
  placeholder,
  errorMessage,
  onComplete,
}) {
  const styles = useStyles({})
  const [dropError, setDropError] = useState(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    setDropError(null)
    onComplete(acceptedFiles[0])
  }, [])

  const onDropRejected = (files: FileRejection[], event: DropEvent) => {
    setDropError(
      `Invalid file ${files[0].file.name}. Your file type of ${files[0].file.type} is not supported.`,
    )
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept,
    disabled: loading,
    onDropAccepted,
    onDropRejected,
  })

  return (
    <div className={styles.root}>
      <div
        {...getRootProps()}
        className={cx(styles.dropZone, isDragActive && styles.active)}
      >
        <input {...getInputProps()} />
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : isDragActive ? (
          <Typography variant="body2" color="textSecondary">
            Drop your file here ...
          </Typography>
        ) : (
          <div>
            <Typography variant="body2" color="textSecondary">
              {placeholder}
            </Typography>
            {(dropError || errorMessage) && (
              <Typography variant="caption" color="error">
                {dropError || errorMessage}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FileSelector

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2rem',
  },
  dropZone: {
    height: 275,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 5,
    textAlign: 'center',
    padding: 20,
    boxSizing: 'border-box',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border-color 0.3s ease',

    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
  },
  loading: {},
  active: {
    borderColor: theme.palette.primary.light,
  },
}))
