import Popper from '@material-ui/core/Popper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import { AddIcon } from '../../component/icons/Tint/20/General'
import {
  HeaderItem,
  SeparatorItem,
  IdentityItem,
  Menu,
  MenuItem,
} from '../../component/menu-v2'
import ClickAwayListener from '@ui/ClickAwayListener'
import Typography from '@ui/Typography'
import useInputState from '../../lib/use-input-state'
import useKeyStepper from '../../lib/use-key-stepper'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { Contact } from '../../service/model'
import { global, typography } from '../../theme'
import { isValueChanged } from './item'

interface EditNameProps {
  contact: Contact
  onDone: () => void
  onAddToExisting: (contact: Contact) => void
}

type ItemType =
  | { type: 'new'; id: string }
  | { type: 'header'; id: string }
  | { type: 'contact'; contact: Contact; id: string }

const EditName: React.FC<EditNameProps> = function ({
  contact,
  onDone,
  onAddToExisting,
}) {
  const styles = useStyles({})
  const { service, contacts, toast } = useAppStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const [name, setName] = useInputState(contact.fullName ?? '')
  const [items, setItems] = useState<ItemType[]>([])
  const contactRef = useRef(contact)

  useEffect(() => {
    if (!contactRef.current.local) return
    if (name) {
      service.search.identities(name, 5, 'contacts').then((res) => {
        const contacts = res
          .map((r) => r.identity)
          .filter((r) => r instanceof Contact && r !== contact) as Contact[]

        if (contacts.length === 0) {
          setItems([{ type: 'new', id: 'new' }])
        } else {
          setItems([
            { type: 'new', id: 'new' },
            { type: 'header', id: 'header' },
            ...contacts.map(
              (contact): ItemType => ({ type: 'contact', contact, id: contact.id }),
            ),
          ])
        }
      })
    } else {
      setItems([])
    }
  }, [name])

  const handleSave = useCallback(() => {
    /**
     * If name is unchanged, return
     */
    if (!isValueChanged(name, contactRef.current.fullName)) {
      onDone()
      return
    }

    /**
     * Break name into first and last and  then
     * save it
     */
    const index = name.indexOf(' ')
    let firstName = '',
      lastName = ''

    if (index === -1) {
      firstName = name
    } else {
      firstName = name.substr(0, index)
      lastName = name.substr(index + 1)
    }

    contactRef.current
      .update({ firstName, lastName })
      .then((contact) => {
        contacts.setSelectedId(contact.id)
      })
      .catch(toast.showError)
    onDone()
  }, [name, onDone])

  useKeyboardShortcuts({
    node: document,
    name: 'EditContactNameModal',
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        onDone()
        event.preventDefault()
      }
    },
  })

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'contact/edit-name',
    skip: (item) => item?.type === 'header',
    handleSelect: (item) => {
      if (!item || item.type === 'new') {
        handleSave()
      } else if (item.type == 'contact') {
        onAddToExisting(item.contact)
        onDone()
      }
    },
    deps: [name, handleSave],
  })

  return (
    <ClickAwayListener onClickAway={handleSave}>
      <div className={styles.root}>
        <input
          autoFocus
          ref={inputRef}
          className={styles.input}
          onChange={setName}
          onFocus={(e) => e.target.select()}
          placeholder="Add a name..."
          value={name}
        />
        <Popper
          id="input-participant"
          anchorEl={inputRef.current}
          open={items.length > 0}
          placement="bottom-start"
          className={styles.popover}
        >
          <Menu
            size="medium"
            style={{ width: inputRef.current?.getBoundingClientRect().width ?? 'auto' }}
          >
            {items.map((item, index) =>
              item.type === 'new' ? (
                <MenuItem
                  key={item.id}
                  {...getItemProps(index)}
                  icon={
                    <div className={styles.addIcon}>
                      <AddIcon />
                    </div>
                  }
                  highlighted={index === selectedIndex}
                  style={{ height: 56 }}
                >
                  <Typography variant="footnote" color="textSecondary">
                    Create{' '}
                    <Typography component="b" variant="inherit" color="textPrimary">
                      {name}
                    </Typography>
                  </Typography>
                </MenuItem>
              ) : item.type === 'header' ? (
                <React.Fragment key={item.id}>
                  <SeparatorItem />
                  <HeaderItem {...getItemProps(index)}>
                    Add to an existing contact
                  </HeaderItem>
                </React.Fragment>
              ) : (
                <IdentityItem
                  key={item.id}
                  {...getItemProps(index)}
                  highlighted={index === selectedIndex}
                  identity={item.contact}
                  height="large"
                />
              ),
            )}
          </Menu>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default observer(EditName)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignSelf: 'stretch',
  },
  popover: {
    ...global.popover(theme),
    marginTop: 5,
    boxShadow: theme.shadows[8],
  },
  input: {
    ...typography.title2,
    alignSelf: 'stretch',
    background: 'none',
    border: 'none',
    color: theme.palette.op.text.primary,
    height: 30,
    margin: '13px 0 1px',
    outline: 'none',
    textAlign: 'center',
    width: '100%',

    '&::placeholder': {
      color: theme.palette.op.text.placeholder,
    },
  },
  addIcon: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.op.primary[1],
    background: theme.palette.op.primary[4],
  },
}))
