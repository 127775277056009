import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Actions, Card, Disclaimer } from './common'
import TextField from '../../component/textfield-v2'
import Button from '../../component/button-v2'
import useInputState from '../../lib/use-input-state'
import { useOnboardingState } from '.'
import keyboard from '../../lib/keyboard'
import { observer } from 'mobx-react-lite'

interface PasswordProps {}

const Password: React.FC<PasswordProps> = function ({}) {
  const styles = useStyles({})
  const store = useOnboardingState()
  const [password, setPassword] = useInputState('')
  const [confirmPassword, setConfirmPassword] = useInputState('')

  const handleSet = () => {
    store.setPasswordAndAdvance(password)
  }

  return (
    <Card
      emoji="🔐"
      title="Add a password"
      description="Lastly, you can add a password to your account to log in with if you wish."
    >
      <TextField
        autoFocus
        fullWidth
        value={password}
        onChange={setPassword}
        label="Password"
        type="password"
      />
      <TextField
        fullWidth
        value={confirmPassword}
        onChange={setConfirmPassword}
        label="Repeat password"
        type="password"
        rootStyle={{ marginTop: 20 }}
        onKeyDown={keyboard.onEnter(handleSet)}
      />
      <Actions>
        <Button
          fullWidth
          disabled={!password || password !== confirmPassword}
          variant="contained"
          color="primary"
          loading={store.loading}
          onClick={handleSet}
        >
          Save password and finish
        </Button>
        <Button
          fullWidth
          height={36}
          variant="text"
          color="textSecondary"
          fontWeight="regular"
          onClick={store.skipPassword}
          disabled={store.loading}
        >
          Skip and finish
        </Button>
      </Actions>
      <Disclaimer
        emoji="👉"
        text="You will always be able to log in securely using just your email address, even if you create a password."
      />
    </Card>
  )
}

export default observer(Password)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
