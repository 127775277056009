import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import MediaPreview from './preview'
import { DecodableMessageMedia } from '../../../service/model'

export interface MediaProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  media: DecodableMessageMedia[]
  onOpen(media: DecodableMessageMedia): void
  onDelete(media: DecodableMessageMedia): void
}

const Media: React.FC<MediaProps> = function ({
  media,
  onOpen,
  onDelete,
  className,
  ...props
}) {
  const styles = useStyles({})

  return (
    <div {...props} className={cx(styles.root, className)}>
      {media.map((item) => (
        <MediaPreview key={item.name} media={item} onOpen={onOpen} onDelete={onDelete} />
      ))}
    </div>
  )
}

export default observer(Media)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '13px 13px -3px',
    },
  }),
  { name: Media.name },
)
