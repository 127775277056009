// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../../component/icon-button'
import { CopyIcon } from '../../../component/icons/Tint/16/General'
import { Contact } from '../../../service/model'
import { useEditor } from '../editor'
import Item, { isValueChanged } from './index'

interface DefaultItemProps {
  contact: Contact
  item: 'company' | 'role'
}

const DefaultItem: React.FC<DefaultItemProps> = function ({ contact, item }) {
  const { toast } = useAppStore()
  const valueRef = useRef<HTMLDivElement>(null)
  const [editor] = useEditor()

  const handleEditValue = () => {
    if (item === 'company') {
      editor(valueRef.current, {
        name: 'edit company',
        defaultValue: contact.company,
        onSave: (company) => {
          const value = company?.trim()
          if (isValueChanged(contact.company, value)) {
            contact.update({ company: value ?? null }).catch(toast.showError)
          }
        },
      })
    } else if (item === 'role') {
      editor(valueRef.current, {
        name: 'edit string',
        defaultValue: contact.role,
        placeholder: 'Enter a role',
        onSave: (role) => {
          const value = role?.trim()
          if (isValueChanged(contact.role, value)) {
            contact.update({ role: value ?? null }).catch(toast.showError)
          }
        },
      })
    }
  }

  const handleCopy = (event) => {
    navigator.clipboard.writeText(contact[item])
    toast.show({ message: `"${contact[item]}" copied to clipboard.` })
    event.stopPropagation()
  }

  return (
    <Item
      type={item}
      name={item == 'company' ? 'Company' : 'Role'}
      disableName
      placeholder={`Set a ${item}...`}
      valueRef={valueRef}
      value={contact[item]}
      onValueClick={handleEditValue}
      actions={
        <IconButton
          key="copy"
          size="small"
          icon={<CopyIcon />}
          title="Copy"
          onClick={handleCopy}
        />
      }
    />
  )
}

export default observer(DefaultItem)
