import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { observer } from 'mobx-react-lite'
import UserTag from '../../../component/user-tag'
import { formatted, toE164 } from '../../../lib/phone-number'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Identity } from '../../../service/model'
import Content from '../common/content'
import Item from '../common/item'

interface ContactNumbersProps {
  identity: Identity
  onSelect: (phoneNumber: string) => void
}

const ContactNumbers: React.FC<ContactNumbersProps> = function ({ identity, onSelect }) {
  const styles = useStyles({})
  const items = identity.phones

  const { getItemProps, selectedIndex } = useKeyStepper({
    items,
    name: 'command/phone-number-selector/numbers',
    handleSelect: (item) => {
      onSelect(toE164(item.number))
    },
  })

  return (
    <Content>
      <div style={{ padding: '1rem 2rem' }}>
        <UserTag identity={identity} />
      </div>
      <div>
        {items.map((item, index) => (
          <Item
            key={item.number}
            className={styles.item}
            highlighted={selectedIndex === index}
            {...getItemProps(index)}
          >
            <Typography variant="body1" color="textPrimary">
              {formatted(item.number)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {item.name}
            </Typography>
          </Item>
        ))}
      </div>
    </Content>
  )
}

export default observer(ContactNumbers)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
