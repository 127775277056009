// @ts-strict-ignore
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  loadStripe,
  StripeCardElementChangeEvent,
  StripeError,
  Token,
} from '@stripe/stripe-js'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import Button from '../component/button-v2'
import config from '../config'
import { typography } from '../theme'
import { PadlockIcon } from './icons/Tint/20/General'
import Typography from '@ui/Typography'

interface CardFieldProps {
  onChange: (event: StripeCardElementChangeEvent) => void
}

const CardField: React.FC<CardFieldProps> = function ({ onChange }) {
  const theme = useTheme()
  const styles = useStyles({})
  return (
    <div className={styles.formRow}>
      <CardElement
        options={{
          iconStyle: 'solid',
          style: {
            base: {
              iconColor: theme.palette.text.secondary,
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontSmoothing: 'antialiased',
            },
            invalid: {
              iconColor: theme.palette.error.main,
              color: theme.palette.error.main,
            },
          },
        }}
        onChange={onChange}
      />
    </div>
  )
}

interface CheckoutFormProps {
  actionTitle?: string
  loading?: boolean
  onSubmit: (token: Token) => void
}

const CheckoutForm: React.FC<CheckoutFormProps> = function ({
  actionTitle = 'Save',
  loading,
  onSubmit,
}) {
  const styles = useStyles({})
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<StripeError>(null)
  const [focused, setFocused] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (!elements) return
    elements.getElement('card').on('focus', () => setFocused(true))
    elements.getElement('card').on('blur', () => setFocused(false))
    setTimeout(() => {
      elements.getElement('card').focus()
    })
  }, [elements])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }

    const payload = await stripe.createToken(elements.getElement(CardElement))

    setProcessing(false)

    if (payload.error) {
      setError(payload.error)
    } else {
      onSubmit(payload.token)
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <fieldset className={cx(styles.formGroup, focused && styles.focused)}>
        <CardField
          onChange={(e: any) => {
            setError(e.error)
            setCardComplete(e.complete)
          }}
        />
      </fieldset>
      <div>
        {error ? (
          <Typography
            color="error"
            variant="caption1"
            style={{ marginTop: 16, textAlign: 'center' }}
          >
            {error.message}
          </Typography>
        ) : (
          <Typography color="textSecondary" variant="caption1" fontWeight="regular" />
        )}
        <Button
          fullWidth
          type="submit"
          disabled={!stripe}
          variant="contained"
          color="primary"
          loading={Boolean(processing) || loading}
          style={{ marginTop: 20 }}
        >
          {actionTitle}
        </Button>
        <Typography
          variant="footnote"
          color="textSecondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 24,
          }}
        >
          <PadlockIcon className={styles.padlockIcon} />
          Secure and encrypted
        </Typography>
      </div>
    </form>
  )
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.STRIPE_API_KEY)

const CreditCard: React.FC<CheckoutFormProps> = function (props) {
  const styles = useStyles({})
  return (
    <div className={styles.root}>
      <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
      </Elements>
    </div>
  )
}

export default CreditCard

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,

    '&:last-child': {
      borderBottom: 'none',
    },

    '& .StripeElement': {
      width: '100%',
      padding: '11px 15px 11px 0',
    },
  },
  form: {},
  formGroup: {
    ...typography.footnote,
    background: theme.palette.op.gray[6],
    border: `1.5px solid ${alpha(theme.palette.op.gray[1], 0.2)}`,
    borderRadius: 5,
    color: theme.palette.op.text.primary,
    padding: '5px 0',
    willChange: 'opacity, transform',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      easing: 'ease',
      duration: 240,
    }),
  },
  focused: {
    border: `1.5px solid ${theme.palette.op.primary[2]}`,
    boxShadow: `0 0 0 4px ${alpha(theme.palette.op.primary[2], 0.18)}`,
  },
  padlockIcon: { marginRight: 6, color: theme.palette.op.secondary.green2 },
}))
