// @ts-strict-ignore
import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { Command } from './common-v2'
import {
  getDatePickerLabel,
  useDateFilter,
  useHandlePopoverOption,
} from '../analytics/use-date-filter'
import Button from '@src/component/button-v2'
import { useAppStore } from '@src/app/context'
import { DateIcon } from '@src/component/icons/Tint/16/ContactInfo'
import { MenuItem, Popover } from '@src/component/menu'
import DayPicker, { RangeModifier } from 'react-day-picker'
import { useNativeSelectStyles } from '@src/component/select'
import classNames from 'classnames'
import { ChevronDownIcon } from '@src/component/icons/Tint/08'
import { fonts } from '@src/theme'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'

const ReportExportCommand = () => {
  const selectStyles = useNativeSelectStyles({ size: 45 })
  const styles = useStyles({})
  const { analytics, service, showAlert, command } = useAppStore()
  const [range, setRange] = useState(analytics.range)
  const user = service.user.current.asMember
  const [exportType, setExportType] = useState(analytics.exportType)

  const [exportEstimate, runExportEstimate] = useStatefulPromise(
    (ids: string[], range: RangeModifier) =>
      service.transport.report.export.exportEstimate(ids, range),
  )

  const [csvExport, runExport] = useStatefulPromise(
    (ids: string[], range: RangeModifier) =>
      service.transport.report.export.queue(ids, range, exportType).then(() => {
        showAlert({
          title: 'Export queued',
          body: `Your CSV export has been queued and will be emailed to ${user.email} once complete`,
        })

        command.hide()
      }),
  )

  const handleExportCsv = () => {
    runExport(analytics.selectedPhoneNumbers, range)
  }

  const {
    editingRange,
    datePickerButtonRef,
    datePickerEl,
    showDateRangePicker,
    handleDatePicker,
    handleDateRangePickerClose,
    handleDateRangeChange,
    handlePickCustomRange,
    handleDayClick,
    dayPickerStyles,
    dayPickerClassNames,
  } = useDateFilter({
    range,
    onChange: ({ range }) => {
      setRange(range)
    },
  })

  const { handlePopoverOption } = useHandlePopoverOption({
    handleDateRangeChange,
    handlePickCustomRange,
    onOption: (option) => setExportType(option),
  })

  const { from, to } = editingRange.from ? editingRange : range

  useEffect(() => {
    runExportEstimate(analytics.selectedPhoneNumbers, range)
  }, [from, to, analytics.selectedPhoneNumbers])

  const shouldShowLargeAmountOfDataAlert = () => {
    const data = exportEstimate.data

    if (!data) {
      return false
    }

    return data.records > 1000
  }

  const isExportEstimateLoading = exportEstimate.status === PromiseStateStatus.Loading
  const isCsvExportLoading = csvExport.status === PromiseStateStatus.Loading

  return (
    <Command>
      <div className={styles.container}>
        <h1 className={styles.title}>Export date range</h1>
        <p className={styles.description}>
          Export all data within the selected date range
        </p>

        <div className={styles.select}>
          <button
            ref={datePickerButtonRef}
            onClick={handleDatePicker}
            className={classNames(
              styles.rangeButton,
              selectStyles.root,
              selectStyles.select,
            )}
          >
            <DateIcon aria-hidden="true" className={selectStyles.icon} />

            <span className={styles.rangeButtonText}>
              {getDatePickerLabel(range, exportType)}
            </span>

            <ChevronDownIcon
              aria-hidden="true"
              className={classNames(styles.rangeButtonChevronIcon, selectStyles.icon)}
            />
          </button>

          <Popover
            open={Boolean(datePickerEl)}
            onClose={handleDateRangePickerClose}
            anchorEl={datePickerEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            style={{ margin: '5px 0' }}
          >
            {!showDateRangePicker ? (
              <>
                <MenuItem onClick={() => handlePopoverOption('today')}>Today</MenuItem>
                <MenuItem onClick={() => handlePopoverOption('yesterday')}>
                  Yesterday
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('last-7-days')}>
                  Last 7 days
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('last-4-weeks')}>
                  Last 4 weeks
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('last-3-months')}>
                  Last 3 months
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('last-12-months')}>
                  Last 12 months
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('month-to-date')}>
                  Month to date
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('quarter-to-date')}>
                  Quarter to date
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('year-to-date')}>
                  Year to date
                </MenuItem>
                <MenuItem onClick={() => handlePopoverOption('custom')}>Custom</MenuItem>
              </>
            ) : (
              <DayPicker
                onDayClick={handleDayClick}
                selectedDays={[from, { from, to }]}
                initialMonth={from || new Date()}
                numberOfMonths={2}
                modifiers={{ [dayPickerStyles.start]: from, [dayPickerStyles.end]: to }}
                month={from}
                toMonth={new Date()}
                disabledDays={{ after: new Date() }}
                classNames={dayPickerClassNames}
              />
            )}
          </Popover>

          <Button
            className={styles.exportButton}
            onClick={handleExportCsv}
            loading={isCsvExportLoading || isExportEstimateLoading}
          >
            Export CSV
          </Button>
        </div>

        {shouldShowLargeAmountOfDataAlert() && (
          <div className={styles.alert}>
            <span role="img" aria-label="Alert" className={styles.alertIcon}>
              🚨
            </span>

            <div>
              <p className={styles.alertTitle}>Large amount of data included</p>

              <p className={styles.alertBody}>
                You're about to queue a large amount of data that may take a while to
                export and appear in your email. Consider picking a smaller date range.
              </p>
            </div>
          </div>
        )}
      </div>
    </Command>
  )
}

export default observer(ReportExportCommand)

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '28px 32px 32px',
  },
  title: {
    fontFamily: fonts.header,
    fontWeight: 600,
    fontSize: 21,
    lineHeight: '24px',
    height: 24,
    color: theme.palette.op.text.primary,
  },
  description: {
    marginTop: 6,
    fontWeight: 450,
    fontSize: 15,
    lineHeight: '20px',
    height: 20,
    color: theme.palette.op.gray[2],
  },
  select: {
    marginTop: 16,
    display: 'flex',
  },
  rangeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    height: 40,
    borderRadius: 6,
  },
  rangeButtonText: {
    flex: 1,
    textAlign: 'left',
    marginLeft: 10,
    fontSize: 13,
    fontWeight: 550,
  },
  rangeButtonChevronIcon: {
    position: 'absolute',
  },
  exportButton: {
    marginLeft: 8,
    minWidth: 120,
    height: 40,
    fontSize: 13,
    lineHeight: '16px',
  },
  alert: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    gridGap: '14px',
    padding: '14px 16px',
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: theme.palette.op.note.bg,
  },
  alertIcon: {
    fontSize: 15,
    lineHeight: '20px',
    height: 20,
    width: 20,
  },
  alertTitle: {
    fontWeight: 550,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.op.note.label,
    margin: 0,
  },
  alertBody: {
    fontWeight: 450,
    fontSize: 13,
    lineHeight: '16px',
    color: theme.palette.op.note.text,
    opacity: 0.7,
    margin: '2px 0 0',
  },
}))
