import Typography from '@ui/Typography'
import React, { memo } from 'react'
import { useAppStore } from '@src/app/context'
import Banner from '../../component/banner'

interface CoToComBannerProps {}

const CoToComBanner: React.FC<CoToComBannerProps> = function ({}) {
  const { isElectron } = useAppStore()

  const handleClick = () => {
    window.location.href = 'https://my.openphone.com'
  }

  return !isElectron && window.location.origin === 'https://my.openphone.co' ? (
    <>
      <Banner tag="co-to-com" onClick={handleClick}>
        <Typography variant="callout">
          ✨ OpenPhone is now available on <strong>my.openphone.com</strong>! ✨
        </Typography>
      </Banner>
    </>
  ) : null
}

export default memo(CoToComBanner)
