import React, { HTMLAttributes } from 'react'

const CheckboxActiveFull = ({ id, ...props }: HTMLAttributes<HTMLOrSVGElement>) => {
  const filterId = `checkbox_activefull-a-${id}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <filter id={filterId} colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="20" height="20" />
        <path
          fill="currentColor"
          d="M5,2 L15,2 C16.6568542,2 18,3.34314575 18,5 L18,15 C18,16.6568542 16.6568542,18 15,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,5 C2,3.34314575 3.34314575,2 5,2 Z"
        />
        <g filter={`url(#${filterId})`} transform="translate(2 2)">
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M12.1117387,4.88027998 C12.3870232,5.13721223 12.4255741,5.55279421 12.2179676,5.85373906 L12.1482915,5.94031012 L7.31173869,11.11972 L7.25596025,11.1669793 L7.17752445,11.2197055 L7.13025124,11.2450302 L7.03375278,11.2842554 L6.93083015,11.3100143 L6.82456259,11.3210357 L6.724126,11.3175954 L6.6691871,11.3100146 L6.56624584,11.2842552 L6.46970761,11.2450027 L6.37669272,11.1906817 L6.2984074,11.1290204 L6.25170855,11.0831673 L3.85170855,8.51173869 C3.56908308,8.20892568 3.58544831,7.73433402 3.88826131,7.45170855 C4.16354586,7.1947763 4.58079624,7.18494381 4.86672557,7.41278738 L4.94829145,7.48826131 L6.799,9.471 L11.0517085,4.91683274 C11.334334,4.61401973 11.8089257,4.5976545 12.1117387,4.88027998 Z"
          />
        </g>
      </g>
    </svg>
  )
}
export default CheckboxActiveFull
