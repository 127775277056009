// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { useReaction } from '../../lib/hooks'
import useCombinedRefs from '../../lib/use-combined-ref'
import useDebug from '../../lib/use-debug'
import { ImmutableCollection } from '../../service/model/base'
import { ScrollView, ScrollViewRef } from '../scrollview'
import ItemGroup from './group'
import { VirtualListItem, VirtualListStore } from './store'

export interface VirtualListProps<T extends VirtualListItem>
  extends Omit<React.HTMLProps<HTMLDivElement>, 'data'> {
  data: ImmutableCollection<T>
  height?: string | number
  pinToIndex?: number
  pinToBottom?: boolean
  hideScroll?: boolean
  listPadding?: { top: number; bottom: number }
  itemContent(item: T, index: number): React.ReactNode
  itemSpacing?: { top?: number; bottom?: number }
  itemEstimatedHeight?: ((item: T, index: number) => number) | number
  onPassiveScroll?(offset: { top: number; left: number }): void
  onTopReached?(): void
  onBottomReached?(): void
  onItemMouseEnter?(item: T, index: number): void
  listRef?: React.MutableRefObject<HTMLDivElement>
  name?: string
}

const VirtualList = function <T extends VirtualListItem>(
  {
    data,
    height,
    pinToIndex,
    pinToBottom,
    hideScroll = false,
    listPadding,
    itemContent,
    itemSpacing,
    itemEstimatedHeight,
    name,
    onTopReached,
    onBottomReached,
    onItemMouseEnter,
    onPassiveScroll,
    listRef,
    className,
    ...props
  }: VirtualListProps<T>,
  outerRef?: React.ForwardedRef<VirtualListStore<T>>,
) {
  const debug = useDebug(`op:vlist:@${name}`)
  const styles = useStyles({})
  const internalListRef = useCombinedRefs<HTMLDivElement>(listRef)
  const viewportRef = useRef<ScrollViewRef>(null)
  const viewportHeight = useRef<number>(parseInt(height as any) || 0)
  const store = useMemo(
    () =>
      new VirtualListStore(data, {
        name,
        pinToIndex,
        pinToBottom,
        listPadding,
        itemSpacing,
        itemEstimatedHeight,
        viewportHeight: viewportHeight.current,
      }),
    [data],
  )

  useReaction(
    () => store.items.length > 0 && store.views[store.items[0].id].offset,
    (offsetTop) => {
      if (offsetTop != null) {
        debug(`set list padding to ${offsetTop}`)
        internalListRef.current.style.paddingTop = `${offsetTop}px`
      }
    },
    { name: 'AdjustListPadding', fireImmediately: true },
    [store],
  )

  useReaction(
    () => store.height,
    (height) => {
      debug(`set list height to ${height}`)
      internalListRef.current.style.height = `${height}px`
    },
    { name: 'AdjustListHeight', fireImmediately: true },
    [store],
  )

  useReaction(
    () => store.scrollTo,
    (scrollTo) => {
      debug(`scroll viewport to ${scrollTo.top}`)
      viewportRef.current.scrollTo(scrollTo)
    },
    { name: 'AdjustViewportScrollPosition', fireImmediately: true },
    [store],
  )

  useReaction(
    () => store.topReached,
    (topReached) => {
      if (topReached) {
        debug(`scroll reached top`)
        onTopReached?.()
      }
    },
    { name: 'TopReached' },
    [store],
  )

  useReaction(
    () => store.bottomReached,
    (bottomReached) => {
      if (bottomReached) {
        debug('scroll reached bottom')
        onBottomReached?.()
      }
    },
    { name: 'BottomReached' },
    [store],
  )

  useImperativeHandle(outerRef, () => store, [store])

  useEffect(() => {
    return () => store.tearDown()
  }, [store])

  const handleViewportResize = useCallback(
    (rect: DOMRect) => {
      viewportHeight.current = rect.height
      store.setViewportHeight(viewportHeight.current)
    },
    [store],
  )

  const handleScroll = useCallback(
    (scroll: { left: number; top: number }) => {
      store.setOffsetTop(scroll.top)
      onPassiveScroll?.(scroll)
    },
    [store],
  )

  return (
    <ScrollView
      {...props}
      name={name}
      ref={viewportRef}
      data-store-id={store.id}
      className={cx(styles.viewport, className)}
      onResize={handleViewportResize}
      onPassiveScroll={handleScroll}
      hideScroll={hideScroll}
      style={{ height }}
    >
      <div ref={internalListRef} className={styles.list}>
        <ItemGroup key={store.id} store={store}>
          {store.items.map((item) => {
            const index = store.views[item.id].index
            const handleMouseEnter = onItemMouseEnter
              ? () => onItemMouseEnter(item, index)
              : null

            return (
              <div key={item.id} data-item-id={item.id} onMouseEnter={handleMouseEnter}>
                {itemContent(item, index)}
              </div>
            )
          })}
        </ItemGroup>
      </div>
    </ScrollView>
  )
}

export * from './store'
export default observer(VirtualList, { forwardRef: true })

const useStyles = makeStyles<Theme, Partial<VirtualListProps<any>>>((theme) => ({
  viewport: {
    flex: 1,
  },
  list: {
    position: 'relative',
  },
}))
