// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { NoSelectionMessage } from '@src/app/components'
import { ChecboxIcon } from '@src/component/icons/Tint/16/ContactInfo'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import { useDebouncedValue, useObservable } from '../../../lib/hooks'
import useKeyboardShortcuts, {
  defaultWithInputOnScreen,
} from '../../../lib/use-keyboard-shortcuts'
import AddParticipants from './add-participants'
import Compose from './compose'
import MessagesHeader from './header'
import ActivityList, { ActivityListMethods } from './list'

interface ConversationProps {
  showDetailsButton: boolean
  disableEditParticipants?: boolean
}

const Conversation: React.ForwardRefRenderFunction<HTMLDivElement, ConversationProps> =
  function ({ showDetailsButton, disableEditParticipants }, ref) {
    const styles = useStyles({})
    const { command, conversation: conversationStore, history, inboxes } = useAppStore()
    const conversation = useDebouncedValue(() => conversationStore.current)
    const controller = conversationStore.inputBarController
    const listRef = useRef<ActivityListMethods>(null)
    const inbox = inboxes.selected

    useEffect(() => {
      if (history.query.call) {
        command.present({ name: 'dialer', phoneNumber: history.query.call })
      }
    }, [])

    useKeyboardShortcuts({
      name: 'conversation',
      filter: defaultWithInputOnScreen({
        allowedShortcutsAlways: ['Escape', 'Meta+KeyK'],
      }),
      node: document,
      handler: (shortcut, event) => {
        if (shortcut === 'Meta+KeyK') {
          command.present({ name: 'app' })
          event.preventDefault()
        } else if (shortcut === 'KeyE') {
          inbox.toggleDone(conversation)
          event.preventDefault()
        } else if (shortcut === 'KeyU') {
          inbox.toggleRead(conversation)
          event.preventDefault()
        }
      },
      dep: [inbox, conversation],
    })

    const handleMarkAsRead = () => {
      if (conversation?.isUnread) {
        conversation.markAsRead()
      }
    }

    useObservable(
      () => controller?.messages$,
      () => listRef.current?.scrollToBottom(),
      [controller],
    )

    return (
      <div ref={ref} onClick={handleMarkAsRead} className={styles.root}>
        {conversation && controller ? (
          <>
            {!disableEditParticipants &&
            conversation.isNew &&
            !conversation.directNumberId ? (
              <AddParticipants
                conversation={conversation}
                onDiscard={inbox.delete}
                store={conversationStore}
              />
            ) : (
              <MessagesHeader
                conversation={conversation}
                showDetailsButton={showDetailsButton}
              />
            )}
            <ActivityList ref={listRef} />
            <Compose conversation={conversation} controller={controller} />
          </>
        ) : (
          <div className={styles.noSelectionMessage}>
            <NoSelectionMessage
              icon={<ChecboxIcon />}
              description="No conversation selected"
            />
          </div>
        )}
      </div>
    )
  }

export default observer(Conversation, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      outline: 'none',
      borderRight: `1.5px solid ${theme.palette.op.border.common}`,
    },
    noSelectionMessage: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: Conversation.name },
)
