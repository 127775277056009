// @ts-strict-ignore
import { makeAutoObservable, reaction } from 'mobx'
import { filter, map } from 'rxjs/operators'
import { Collection, Conversation } from '../../service/model'
import { ActivityPushNotification } from '../../service/transport/websocket'
import AppStore from '../store'
import InboxUiStore from './conversations/store'

export default class InboxesUiStore {
  all = new Collection<InboxUiStore>({ bindElements: true })
  selected: InboxUiStore = null
  detailsOpen = false

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      // we don't want to compute this value due to a race condition with `CreateDirects` reaction
      dm: false,
    })

    reaction(
      () => this.root.service.user.phoneNumbers.list,
      () => {
        if (this.root.service.user.phoneNumbers.length === 0) return
        this.root.service.user.phoneNumbers.list.forEach(({ id }) => {
          if (!this.all.has(id)) {
            this.all.put(new InboxUiStore(this.root, { phoneNumberId: id }))
          }
        })
        /**
         * Delete inboxes that are no longer connected to a phone number. Often happens because
         * the phone number has been deleted.
         */
        this.all.list.forEach((inbox) => {
          const doesNotExist = !inbox.phoneNumber && !inbox.directNumber
          const cannotAccess = !this.root.service.user.phoneNumbers.has(
            inbox.phoneNumberId,
          )

          if (!inbox.isDirectMessage && (doesNotExist || cannotAccess)) {
            this.all.delete(inbox)
          }
        })
      },
      { name: 'CreateInboxes', fireImmediately: true },
    )

    reaction(
      () => this.root.service.user.current?.asMember.directNumberId,
      (directNumberId) => {
        if (!directNumberId || this.all.has(directNumberId)) return
        this.all.deleteBulk(this.all.list.filter((inbox) => inbox.directNumberId))
        this.all.put(new InboxUiStore(this.root, { directNumberId }))
      },
      { name: 'CreateDirects', fireImmediately: true },
    )

    reaction(
      () => this.all.list,
      () => {
        /**
         * Set the default selected inbox based on URL
         */
        if (!this.selected) {
          const parts = this.root.history.location.pathname.split('/')
          if (parts[1] === 'inbox' && parts[2]) {
            this.selected = this.all.get(parts[2])
          } else if (
            !parts[1] ||
            parts[1] === 'inbox' ||
            parts[1] === 'login' ||
            parts[1] === 'signup'
          ) {
            this.setSelectedInboxIfNecessary()
          }
        }
      },
      { fireImmediately: true },
    )

    reaction(
      () =>
        this.all.list.reduce(
          (total, inbox) => (total += inbox.muted ? 0 : inbox.unread),
          0,
        ),
      (totalUnread) => {
        this.root.electron?.app.setBadgeCount(totalUnread)
      },
    )

    reaction(
      () => this.selected,
      (inbox) => inbox?.loadConversations(),
      { fireImmediately: true },
    )

    this.hydrateData()
    this.registerForNotifications()
  }

  get sorted() {
    const existing =
      this.root.service.user.current.settings?.shared?.phoneNumberOrder ?? []

    return this.all.list
      .filter((i) => i.phoneNumber)
      .sort((a, b) => {
        let ai = existing.indexOf(a.phoneNumber.id)
        let bi = existing.indexOf(b.phoneNumber.id)
        return (ai > -1 ? ai : 999) - (bi > -1 ? bi : 999)
      })
  }

  get dm(): InboxUiStore {
    return this.all.get(this.root.service.user.current.asMember?.directNumberId)
  }

  get selectedPhoneNumber() {
    return !this.selected || this.selected.isDirectMessage
      ? this.all.list.find((i) => !i.isDirectMessage).phoneNumber
      : this.selected.phoneNumber
  }

  /**
   * Sets the selected inbox and changes the URL to reflect the selection
   * @param inbox
   */
  setSelected = (inbox: InboxUiStore | undefined) => {
    if (!inbox) return
    let url = `/inbox/${inbox.id}`
    if (this.selected !== inbox || !this.root.history.location.pathname.startsWith(url)) {
      this.selected = inbox
      this.root.history.push(url)
    }
  }

  /**
   * Sets the selected inbox if it's not set already, skipping over the direct number inbox
   */
  setSelectedInboxIfNecessary = () => {
    if (!this.selected?.phoneNumber) {
      const inbox = this.all.list.find((i) => i.phoneNumber)
      if (inbox) this.setSelected(inbox)
    }
  }

  /**
   * It returns the currently selected inbox, if none is selected, it will select the
   * first one and return it
   */
  getOrSetPhoneNumberInbox = () => {
    this.setSelectedInboxIfNecessary()
    return this.selected
  }

  /**
   * Open a conversation in the inbox to which it belongs
   *
   * @param conversation - The conversation to open
   * @param anchorActivityId - Optionally jump to a specific activity after opening
   */
  openConversation = async (conversation: Conversation, anchorActivityId?: string) => {
    const inbox = this.all.list.find(
      (p) => p.id === conversation.phoneNumberId || p.id === conversation.directNumberId,
    )
    this.setSelected(inbox)
    this.selected.setSelectedConversation({
      conversation,
      anchorActivityId,
      markAsRead: true,
    })
  }

  /**
   * Find a conversation by its ID and open it in the inbox to which it belongs
   *
   * If a conversation cannot by found, this will throw an error.
   *
   * @param conversationId - The ID of the conversation to open
   * @param anchorActivityId - Optionally jump to a specific activity after opening
   */
  openConversationById = async (conversationId: string, anchorActivityId?: string) => {
    const conversation = await this.root.service.conversation.getById(conversationId)

    if (!conversation) {
      throw Error('Conversation not found.')
    }

    await this.openConversation(conversation, anchorActivityId)
  }

  /**
   * Find a conversation by the phone number and open it in the inbox to which it belongs
   *
   * If a conversation cannot by found, this will throw an error.
   *
   * @param phoneNumber - The phone number with which we want to open conversation
   * @param anchorActivityId - Optionally jump to a specific activity after opening
   */
  openConversationByPhoneNumber = async (
    phoneNumber: string,
    anchorActivityId?: string,
  ) => {
    const conversation = await this.root.service.conversation.findOne({
      phoneNumber,
      canBeNew: true,
    })

    if (!conversation) {
      throw Error('Conversation not found.')
    }

    await this.openConversation(conversation, anchorActivityId)
  }

  /**
   * Opens an ongoing direct conversation or creates a new one if it can't find one
   * @param directNumber
   * @param conversation
   */
  openDirectMessage = (
    directNumber: string,
    conversation?: Conversation,
    anchorActivityId?: string,
  ) => {
    this.setSelected(this.dm)
    if (!conversation) {
      conversation = this.dm.conversations.find((c) => c.phoneNumber == directNumber)
    }
    if (conversation) {
      this.dm.setSelectedConversation({
        conversation,
        anchorActivityId,
        markAsRead: true,
      })
    } else {
      this.dm.newConversation(directNumber)
    }
  }

  /**
   * Slides open the details panel on the right. This is only usefull if the window
   * is minimized and the right panel is hidden by default.
   */
  openDetails = () => {
    this.detailsOpen = true
  }

  closeDetails = () => {
    this.detailsOpen = false
  }

  /**
   * Create a conversation in the first available inbox.
   *
   * @param phoneNumber - The phone number with which to create the conversation
   */
  newConversationInDefaultInbox(phoneNumber: string) {
    if (!this.selected) {
      this.setSelected(this.all.list.find((i) => i.phoneNumber))
    }
    this.selected.newConversation(phoneNumber)
  }

  async submitFeedback() {
    const feedbackPhoneNumber = '+14158516951'
    try {
      await this.openConversationByPhoneNumber(feedbackPhoneNumber)
    } catch {
      this.newConversationInDefaultInbox(feedbackPhoneNumber)
    }
  }

  private hydrateData() {
    this.root.service.conversation.loadUnreadCounts()
  }

  private registerForNotifications() {
    return this.root.service.transport.onMessage
      .pipe(
        filter(
          (m): m is ActivityPushNotification =>
            m._class === 'PushNotification' &&
            ['call', 'message', 'voicemail'].includes(m.data.custom.type),
        ),
        map((m) => m.data),
      )
      .subscribe((data) => {
        const conversationFocused =
          this.root.isFocused &&
          data.custom.conversationId === this.root.conversation.current?.id

        if (data.custom.silent || conversationFocused) return

        this.root.notification.show({
          id: data.collapseKey,
          title: data.title,
          body: data.body,
          silent: data.custom.silent,
          hasReply: true,
          sound: data.custom.type === 'call' ? 'missed-call' : 'new-message',
          onReply: async (body: string) => {
            const conv = await this.root.service.conversation.getById(
              data.custom.conversationId,
            )
            conv?.send(body, [])
          },
          onClick: () => {
            this.root.focus()
            this.root.url.route(data.custom.action)
          },
        })
      })
  }
}

export { InboxUiStore }
