// @ts-strict-ignore
import React from 'react'
import { Token } from '@stripe/stripe-js'
import { action, makeAutoObservable, reaction } from 'mobx'
import { XIcon } from '../../component/icons/Tint/20/General'
import Typography from '@ui/Typography'
import { DisposeBag } from '../../lib/dispose'
import { Organization } from '../../service/model'
import {
  AvailablePhoneNumber,
  Invitation,
  IUiStore,
  SearchPhoneNumberParams,
} from '../../types'
import AppStore from '../store'
import IconButton from '../../component/icon-button'
import { wait } from '../..'
import analytics from '@src/lib/analytics'
import { logError } from '@src/lib/log'

type OnboardingStep =
  | 'account'
  | 'workspace'
  | 'choose number'
  | 'company'
  | 'invite'
  | 'password'
  | 'pending invites'
  | 'phone number source'
  | 'plans'
  | 'subscription'
  | 'use case'
  | 'verify code'
  | 'verify real'

const path: { [key in OnboardingStep]: string } = {
  'choose number': '/onboarding/number',
  workspace: '/onboarding/workspace',
  password: '/onboarding/password',
  'pending invites': '/onboarding/pendingInvites',
  'phone number source': '/onboarding/number-type',
  'use case': '/onboarding/usage',
  'verify code': '/onboarding/verify-code',
  'verify real': '/onboarding/verify',
  account: '/onboarding/account',
  company: '/onboarding/company',
  invite: '/onboarding/invite',
  plans: '/onboarding/plans',
  subscription: '/onboarding/subscription',
}

interface State {
  initial: OnboardingStep
  current: OnboardingStep
  states: Partial<{
    [key in OnboardingStep]: { [key: string]: OnboardingStep }
  }>
}

export default class OnboardingUiState implements IUiStore {
  availableNumbers: AvailablePhoneNumber[] = null
  isSearching: boolean = false
  loading: boolean = false
  selectedNumber: string = null
  isPorting = false
  plan: 'standard' | 'premium' = 'standard'
  billingInterval: 'yearly' | 'monthly' = 'yearly'
  verifiedPhoneNumber: string = null

  // State Machine
  initial: OnboardingStep = null
  current: OnboardingStep = null
  states: Partial<{
    [key in OnboardingStep]: { [key: string]: OnboardingStep }
  }> = {}

  private totalInvites = 0
  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    makeAutoObservable(this, {})

    this.disposeBag.add(
      this.app.history.observe().subscribe(
        action((history) => {
          const step = Object.entries(path).find(
            ([, path]) => history.location.pathname === path,
          )
          this.goToStep(step ? (step[0] as OnboardingStep) : this.initial)
        }),
      ),

      reaction(
        () => this.app.service.user.invites.length > 0,
        (hasInvites) => {
          if (hasInvites) {
            Object.assign(this, pendingInvitesTemplate)
          } else if (this.app.needsOnboarding === false) {
            this.finish()
          } else if (this.subscription.isActive) {
            Object.assign(
              this,
              this.phoneNumbers.length > 0
                ? invitedWithNumberFlow
                : invitedWithoutNumberFlow,
            )
          } else {
            Object.assign(
              this,
              this.user.verifiedPhoneNumber
                ? firstTimeFlowVerifiedTemplate
                : firstTimeFlowTemplate,
            )
          }

          this.goToStep(this.initial)
        },
        { fireImmediately: true },
      ),
    )
  }

  get user() {
    return this.app.service.user.current
  }

  get org() {
    return this.app.service.organization.current
  }

  get subscription() {
    return this.app.service.billing.subscription
  }

  get coupon() {
    return this.app.service.billing.coupon
  }

  get phoneNumbers() {
    return this.app.service.user.phoneNumbers.list
  }

  searchAvailable = (filter?: SearchPhoneNumberParams) => {
    this.isSearching = true
    this.app.service.transport.account.phoneNumbers.available(filter).then(
      action((numbers) => {
        this.setSelectedNumber(numbers[0])
        this.isSearching = false
      }),
    )
  }

  advance = (action: string) => {
    const nextStep = this.states[this.current]?.[action]
    if (nextStep) {
      return this.goToStep(nextStep)
    } else {
      return false
    }
  }

  updateUserAndAdvance = (
    firstName: string,
    lastName: string,
    partnerStackKey: string | null,
  ) => {
    this.app.service.user.current.update({ firstName, lastName })

    if (partnerStackKey) {
      this.app.service.transport.account
        .associate({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          partnerKey: partnerStackKey,
        })
        .catch(logError)
    }

    if (this.hasNextStep('next')) {
      this.advance('next')
    } else {
      this.finish()
    }
  }

  joinWorkspace = (workspaceId: string) => {
    return (
      this.app.service.workspace
        .addMember(workspaceId)
        // need to refetch a JWT token
        .then(() => wait(700))
        .then(() => this.app.service.auth.refreshToken())
        .then(() => this.app.service.reset())
        .catch(this.app.toast.showError)
    )
  }

  updateOrgAndAdvance = (org: Partial<Organization>, domain?: string) => {
    this.app.service.organization.current.update(org).catch(this.app.toast.showError)
    if (domain) {
      this.app.service.workspace.addDomain(domain).catch(this.app.toast.showError)
    }
    this.advance('next')
  }

  sendInvitesAndAdvance = (invites: Invitation[]) => {
    this.totalInvites = invites.length
    this.app.service.member.inviteBulk(invites)
    this.app.toast.show({
      message: `${invites.length} ${invites.length === 1 ? 'invite' : 'invites'} sent.`,
    })
    this.advance('next')
  }

  setPasswordAndAdvance = (password: string) => {
    this.loading = true
    this.app.service.user
      .setPassword(password)
      .then(this.finish)
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  skipPassword = () => {
    this.finish()
  }

  setPlanAndAdvance = (
    plan: 'standard' | 'premium',
    billingInterval: 'yearly' | 'monthly',
  ) => {
    this.plan = plan
    this.billingInterval = billingInterval
    this.advance('next')
  }

  setSelectedNumber = (number: AvailablePhoneNumber) => {
    this.selectedNumber = number.phoneNumber
  }

  numberTypeSelected = (isPorting: boolean) => {
    this.isPorting = isPorting
    this.advance('next')
  }

  numberSelected = () => {
    if (this.subscription.isActive) {
      this.loading = true
      this.purchaseNumber()
        .then(() => this.advance('next'))
        .catch(this.app.toast.showError)
        .finally(this.stopLoading)
    } else {
      this.advance('next')
    }
  }

  sendVerification = async (number: string, advance = true) => {
    if (number === '+14242424242') {
      this.verifiedPhoneNumber = number
      advance && this.advance('next')
    } else {
      this.loading = true
      return this.app.service.phoneNumber
        .verify(number)
        .then(
          action(() => {
            this.verifiedPhoneNumber = number
            advance && this.advance('next')
          }),
        )
        .catch((error) => {
          this.app.toast.showError(error)
          throw error
        })
        .finally(this.stopLoading)
    }
  }

  verifyNumber = async (code: string) => {
    if (this.verifiedPhoneNumber === '+14242424242') {
      this.advance('next')
    } else {
      this.loading = true
      return this.app.service.phoneNumber
        .checkCode(this.verifiedPhoneNumber, code)
        .then(
          action(() => {
            this.advance('next')
          }),
        )
        .catch((error) => {
          this.app.toast.showError(error)
          throw error
        })
        .finally(this.stopLoading)
    }
  }

  purchaseNumber = () => {
    return this.app.service.phoneNumber
      .buy(this.selectedNumber, this.isPorting)
      .then(() => {
        this.advance('next')
      })
  }

  upgrade = (token: Token) => {
    this.loading = true
    this.app.service.billing
      .upgrade({
        token: token.id,
        plan: this.plan,
        annual: this.billingInterval === 'yearly',
        coupon: this.coupon?.id,
      })
      .then(() => {
        analytics.addedPaymentInfo()
        return this.purchaseNumber()
      })
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  finish = () => {
    this.loading = false
    this.tearDown()
    this.app.history.push('/inbox')
    this.app.inboxes.setSelectedInboxIfNecessary()
    this.app.showConfetti()

    const content = (
      <div style={{ width: 308 }}>
        <Typography variant="callout" fontWeight="bold">
          👋 Welcome to OpenPhone
        </Typography>
        <Typography variant="footnote" style={{ marginTop: 7 }}>
          Your new phone number is all set up and ready to rock. Go ahead, start calling
          or texting!
        </Typography>
        <IconButton
          size="small"
          icon={<XIcon />}
          style={{ position: 'absolute', top: 12, right: 12, color: '#FFF' }}
          onClick={this.app.toast.hide}
        />
      </div>
    )
    this.app.toast.showSpecial(content)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private goToStep(step: OnboardingStep) {
    if (step == this.current) {
      return false
    }
    this.current = step
    this.app.history.push(path[step])
    return true
  }

  private hasNextStep(action: string) {
    return Boolean(this.states[this.current]?.[action])
  }

  private stopLoading = () => {
    this.loading = false
  }
}

const pendingInvitesTemplate: State = {
  initial: 'pending invites',
  current: null,
  states: {
    'pending invites': { next: null },
  },
}

const firstTimeFlowTemplate: State = {
  initial: 'account',
  current: null,
  states: {
    account: { next: 'workspace' },
    workspace: { next: 'verify real', done: 'choose number' },
    'verify real': { next: 'verify code', skip: 'phone number source' },
    'verify code': { next: 'phone number source' },
    'phone number source': { next: 'choose number' },
    'choose number': { next: 'plans' },
    plans: { next: 'subscription' },
    subscription: { next: 'use case' },
    'use case': { personal: 'password', work: 'company', skip: 'password' },
    company: { next: 'invite' },
    invite: { next: 'password' },
    password: { next: null },
  },
}

const firstTimeFlowVerifiedTemplate: State = {
  initial: 'account',
  current: null,
  states: {
    account: { next: 'workspace' },
    workspace: { next: 'phone number source', done: 'password' },
    'phone number source': { next: 'choose number' },
    'choose number': { next: 'plans' },
    plans: { next: 'subscription' },
    subscription: { next: 'use case' },
    'use case': { personal: 'password', work: 'company', skip: 'password' },
    company: { next: 'invite' },
    invite: { next: 'password' },
    password: { next: null },
  },
}

const invitedWithoutNumberFlow: State = {
  initial: 'account',
  current: null,
  states: {
    account: { next: 'choose number' },
    'choose number': { next: 'password' },
    password: { next: null },
  },
}

const invitedWithNumberFlow: State = {
  initial: 'account',
  current: null,
  states: {
    account: { next: 'password' },
    password: { next: null },
  },
}
