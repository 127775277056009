import { useTheme } from '@material-ui/core'
import React from 'react'
import { ThemeKey } from '../../theme'

interface IllustrationSizes {
  base: string
  x2: string
  x3: string
}

interface IllustrationProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string
  illustration: Record<ThemeKey, IllustrationSizes>
}

export default function Illustration({ illustration, ...props }: IllustrationProps) {
  const theme = useTheme()
  const sizes = illustration[theme.palette.type]

  return <img src={sizes.base} srcSet={`${sizes.x2} 2x, ${sizes.x3} 3x`} {...props} />
}
