import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Image from '../../../component/image'
import { ImagesItemPart, Item } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'

export type ImagesProps<I extends Item> = ItemPartProps<I, ImagesItemPart>

const Images = function <I extends Item>({
  controller,
  item,
  images,
  ...props
}: ImagesProps<I>) {
  const styles = useStyles({})

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props} transparent>
        <div className={styles.root}>
          {images.map((image) => (
            <div
              key={image.url}
              className={styles.media}
              onClick={() => controller.openMedia(image)}
            >
              <Image media={image} thumbnail />
            </div>
          ))}
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(Images)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      overflow: 'hidden',
      borderRadius: 'inherit',
    },
    media: {
      cursor: 'pointer',
      lineHeight: 0,
      userSelect: 'none',

      '& img': {
        width: 250,
        height: 250,
        objectFit: 'cover',
      },
    },
  }),
  { name: Images.name },
)
