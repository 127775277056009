// @ts-strict-ignore
import React, { useEffect, useRef, useState, MouseEvent, HTMLProps } from 'react'
import Plyr from 'plyr'
import { DecodableMessageMedia } from '../service/model'
import { DeleteIcon, DownloadIcon, MoreIcon } from './icons/Tint/20/General'
import { makeStyles, Theme } from '@material-ui/core'
import cx from 'classnames'
import IconButton from './icon-button'
import PopoverMenu, { MenuItem } from './popover-menu'

export interface AudioPlayerProps extends Omit<HTMLProps<HTMLDivElement>, 'media'> {
  media: DecodableMessageMedia
  handleDelete?(): void
  onMenuClick?(): void
  showOnlyDownloadAction?: boolean
}

const AudioPlayer: React.FC<AudioPlayerProps> = function ({
  media,
  className,
  type,
  onMenuClick,
  handleDelete,
  showOnlyDownloadAction = true,
  ...props
}) {
  const styles = useStyles()
  const playerRef = useRef<Plyr>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [anchorEl, setAnchorEl] = useState<Element>(null)

  useEffect(() => {
    if (!audioRef.current) return
    playerRef.current = new Plyr(audioRef.current, {
      storage: { enabled: true, key: 'opplayer' },
      controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
    })
  }, [])

  useEffect(() => {
    if (!media?.url) return
    playerRef.current.source = {
      type: 'audio',
      sources: [{ src: media.url, type: media.type }],
    }
  }, [media?.url])

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event: MouseEvent) => {
    onMenuClick?.()
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadRecording = () => {
    handleMenuClose()
    window.open(media.url, '_blank').focus()
  }

  return (
    <div {...props} className={cx(styles.wrapper, className)}>
      <audio ref={audioRef} controls tabIndex={-1}>
        <source src={media.url} type={media.type} />
        Your browser does not support the audio element.
      </audio>
      {showOnlyDownloadAction ? (
        <IconButton icon={<DownloadIcon />} onClick={handleDownloadRecording} />
      ) : (
        <>
          <IconButton onClick={handleMenuOpen} icon={<MoreIcon />} />
          <PopoverMenu
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            minWidth={146}
            onMouseMove={(event) => {
              event.stopPropagation()
            }}
          >
            <MenuItem icon={<DownloadIcon />} onClick={handleDownloadRecording}>
              Download
            </MenuItem>
            {handleDelete && (
              <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
                Delete
              </MenuItem>
            )}
          </PopoverMenu>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default AudioPlayer
