// @ts-strict-ignore
import { action, makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import { UserAnalytics } from '../../types'
import {
  Collection,
  DirectNumber,
  Invite,
  Member,
  Model,
  PhoneNumber,
  UserSettings,
} from './'

export interface IUser {
  id: string
  blocked: boolean
  email: string
  firstName: string
  lastName: string
  pictureUrl: string
  createdAt: number
  updatedAt: number
  verifiedPhoneNumber: string
  analytics: UserAnalytics
  settings: UserSettings
}

export class User implements IUser, Model {
  id: string = null
  blocked: boolean = null
  email: string = null
  firstName: string = null
  lastName: string = null
  pictureUrl: string = null
  createdAt: number = null
  updatedAt: number = null
  verifiedPhoneNumber: string = null
  analytics: UserAnalytics = null
  settings: UserSettings | null = null

  // Relations
  directNumbers = new Collection<DirectNumber>({ bindElements: true })
  phoneNumbers = new Collection<PhoneNumber>({ bindElements: true })
  invites: Invite[] = []

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get asMember(): Member {
    return this.root.member.collection.get(this.id)
  }

  get isOpenPhoneOrganizationMember() {
    const [, emailDomain] = this.email.split('@')
    return emailDomain === 'openphone.co'
  }

  /**
   * Only require a phone verification if user is using a free email provider
   */
  get needsPhoneVerification() {
    return this.analytics?.enrichment?.emailProvider !== false
  }

  fetch = () => {
    this.root.transport.account.get().then(
      action((response) => {
        this.deserialize(response)
      }),
    )
  }

  update = (user: Partial<this>) => {
    Object.assign(this, user)
    this.root.user.update(this)
  }

  deserialize = ({ directNumbers, settings, ...json }: any) => {
    Object.assign(this, json)

    if (directNumbers) {
      this.directNumbers.clear()
      this.directNumbers.putBulk(
        directNumbers.map((dn) => new DirectNumber(this.root).deserialize(dn)),
      )
    }

    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)

    this.settings?.tearDown()
    this.settings = new UserSettings(this.root, settings)
    return this
  }

  serialize = (): any => {
    return {
      id: this.id,
      blocked: this.blocked,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      pictureUrl: this.pictureUrl,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      verifiedPhoneNumber: this.verifiedPhoneNumber,
      analytics: toJS(this.analytics),
      directNumbers: this.directNumbers.list?.map((dn) => dn.serialize()),
      settings: this.settings.serialize(),
    }
  }
}
