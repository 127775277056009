import React, { useState, useEffect } from 'react'
import { toHHMMSS } from '../lib/date'

interface TimerProps {
  startTimestamp: number
}

const Timer: React.FC<TimerProps> = function ({ startTimestamp }) {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const id = setInterval(() => {
      const seconds = Math.floor((Date.now() - startTimestamp) / 1000)
      setSeconds(Math.max(0, seconds))
    }, 1000)
    return () => clearInterval(id)
  }, [startTimestamp])

  return <span>{startTimestamp ? toHHMMSS(seconds) : '0:00'}</span>
}

export default Timer
