// @ts-strict-ignore
import axios from 'axios'

export class ValidationError extends Error {}
export class BadRequestError extends Error {}
export class AuthenticationError extends Error {}
export class ForbiddenError extends Error {}
export class UpgradeRequiredError extends Error {}
export class NotFoundError extends Error {}
export class ConnectionError extends Error {}
export class UnknownError extends Error {}
export class TooManyRequestsError extends Error {}
export class MaintenanceError extends Error {}

export function convertError(error: any): Error {
  const status = error.response ? error.response.status : error.statusCode
  if (status) {
    const data = error.response ? error.response.data : error
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    switch (status) {
      case 400:
        return new BadRequestError(errorMessageFromJSON(data))
      case 401:
        return new AuthenticationError(errorMessageFromJSON(data))
      case 403:
        return new ForbiddenError(errorMessageFromJSON(data))
      case 404:
        return new NotFoundError(errorMessageFromJSON(data))
      case 426:
        return new UpgradeRequiredError(errorMessageFromJSON(data))
      case 429:
        return new TooManyRequestsError(errorMessageFromJSON(data))
      case 503:
        return new MaintenanceError(errorMessageFromJSON(data))
      default:
        return new UnknownError(errorMessageFromJSON(data))
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return new ConnectionError('Could not establish a connection. Try again momentarily.')
  } else {
    // Something happened in setting up the request that triggered an Error
    return new ConnectionError(error.message)
  }
}

export default function (error: any) {
  if (error instanceof axios.Cancel) {
    console.warn(`Request canceled: "${error.message}"`)
  } else {
    throw convertError(error)
  }
}

export function convertWorkerError(className: string, error: Error) {
  let err: Error
  switch (className) {
    case 'AuthenticationError':
      err = new AuthenticationError(error.message)
      break
    case 'BadRequestError':
      err = new BadRequestError(error.message)
      break
    case 'ForbiddenError':
      err = new ForbiddenError(error.message)
      break
    case 'NotFoundError':
      err = new NotFoundError(error.message)
      break
    case 'ConnectionError':
      err = new ConnectionError(error.message)
      break
    case 'UnknownError':
      err = new UnknownError(error.message)
      break
    default:
      err = new Error(error.message)
      break
  }
  err.stack = error.stack
  err.name = error.name
  err.message = error.message
  return err
}

export function errorTitle(error: Error): string {
  if (error instanceof BadRequestError) {
    return 'Invalid request'
  } else if (error instanceof AuthenticationError) {
    return 'Login failed'
  } else if (error instanceof ConnectionError) {
    return 'Network issue'
  } else if (error instanceof ForbiddenError) {
    return 'Access denied'
  } else if (error instanceof NotFoundError) {
    return 'Not found'
  } else if (error instanceof TooManyRequestsError) {
    return 'Too many requests'
  } else if (error instanceof UnknownError) {
    return 'Hmmm...'
  }
}

function errorMessageFromJSON(json: any): string {
  return (
    json.message ||
    json.description ||
    json.error_description ||
    (json.error && json.error.message) ||
    'Unknown error. Please try again.'
  )
}
