// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { fonts } from '../theme'
import Tooltip from './tooltip'
import { computeRelativeFontSize } from './utils'

type IconButtonColor = 'primary' | 'default'

interface IconButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'title'
  > {
  icon: React.ReactNode
  color?: IconButtonColor | string
  backgroundColor?: string
  opaque?: boolean
  size?: 36 | 40 | 48 | 'small' | 'medium' | 'large'
  title?: React.ReactNode
  shortcut?: string
  variant?: 'transparent' | 'outlined'
  rounded?: boolean
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const IconButton: React.FC<IconButtonProps> = React.forwardRef(function (
  {
    className,
    icon,
    opaque,
    color,
    backgroundColor,
    disabled,
    rounded,
    shortcut,
    size = 'medium',
    variant = 'transparent',
    title,
    tooltipPlacement,
    style,
    ...props
  },
  ref,
) {
  const styles = useStyles({
    color,
    backgroundColor,
    disabled,
    rounded,
    size,
    variant,
  })

  const inlineStyles = useMemo(
    () => ({ ...style, ...getSizeStyles(size) }),
    [size, style],
  )

  const button = (
    <button
      {...props}
      ref={ref}
      disabled={disabled}
      className={cx(
        styles.root,
        styles.color,
        rounded && styles.rounded,
        opaque && styles.opaque,
        disabled && styles.disabled,
        className,
      )}
      style={inlineStyles}
    >
      {icon}
    </button>
  )

  if (title) {
    /**
     * Wrap button in span because if the button is disabled, the tooltip
     * cannot work with a disabled child as it won't be firing events the tooltip
     * needs to listen to
     */
    return (
      <Tooltip title={title} shortcut={shortcut} placement={tooltipPlacement}>
        <span
          className={styles.wrapper}
          style={{ minWidth: inlineStyles.width, height: inlineStyles.height }}
        >
          {button}
        </span>
      </Tooltip>
    )
  }

  return button
})

export default IconButton

const useStyles = makeStyles<Theme, Partial<IconButtonProps>>((theme: Theme) => ({
  root: ({ variant, color, disabled }) => ({
    alignItems: 'center',
    background: 'none',
    ...(variant === 'transparent' ? { border: 'none' } : getBorderStyles(theme, color)),
    borderRadius: 6,
    cursor: 'pointer',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
    margin: 0,
    outline: 'none',
    padding: 0,
    position: 'relative',
    fontFamily: fonts.emoji,
    transition: theme.transitions.create(['color'], { duration: 192 }),

    '&::before': {
      backgroundColor: theme.palette.op.hover.primary,
      borderRadius: 'inherit',
      bottom: 0,
      content: '""',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: theme.transitions.create(['opacity'], { duration: 192 }),
    },

    '&:hover, &:focus': {
      '&::before': {
        opacity: 1,
      },
    },
  }),
  rounded: {
    borderRadius: '50%',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',

    '&:hover, &:focus': {
      '&::before': {
        opacity: 0,
      },
    },
  },
  opaque: {
    background: theme.palette.op.gray[5],
  },
  wrapper: {
    display: 'block',
  },
  color: ({ color, backgroundColor, disabled }) => ({
    color: getColor(theme, color, disabled),
    backgroundColor,

    '&:hover, &:focus': {
      color: getHoverColor(theme, color, disabled),
    },
  }),
}))

const getSizeStyles = (size: IconButtonProps['size']) => {
  const x = getSizeInPx(size)
  const y = computeRelativeFontSize(x)

  return {
    width: x,
    height: x,
    fontSize: y,
  }
}

const getSizeInPx = (size: Exclude<IconButtonProps['size'], 'auto'>) => {
  switch (size) {
    case 'large':
      return 45
    case 'medium':
      return 30
    case 'small':
      return 22
  }
  return size
}

const getColor = (
  theme: Theme,
  clr: IconButtonColor | string,
  disabled: boolean,
): string => {
  if (disabled) return alpha(theme.palette.op.gray[4], 0.5)
  if (!clr) return theme.palette.op.text.secondary

  switch (clr) {
    case 'primary':
      return theme.palette.op.primary[2]
    case 'default':
      return theme.palette.op.text.secondary
    default:
      return clr
  }
}

const getBorderStyles = (theme: Theme, clr: IconButtonColor | string) => {
  if (clr === 'primary') {
    return {
      border: `1.5px solid ${theme.palette.op.primary[1]}`,
    }
  } else if (clr === 'danger') {
    return {
      border: `1.5px solid ${theme.palette.op.secondary.red1}`,
    }
  } else if (clr === 'secondaryGreen') {
    return {
      border: `1.5px solid ${theme.palette.op.secondary.green1}`,
    }
  } else if (clr === 'textPrimary') {
    return {
      border: `1.5px solid ${alpha(theme.palette.op.text.primary, 0.18)}`,
      boxShadow: theme.palette.op.match({
        light: '0 1.5px 4px 0 rgba(0, 0, 0, 0.06)',
        dark: 'unset',
      }),
    }
  } else if (clr === 'textSecondary') {
    return {
      border: `1.5px solid ${alpha(theme.palette.op.text.primary, 0.18)}`,
    }
  }
}

const getHoverColor = (
  theme: Theme,
  clr: IconButtonColor | string,
  disabled: boolean,
): string => {
  if (disabled) return alpha(theme.palette.op.gray[4], 0.5)
  if (!clr) return theme.palette.op.primary[3]

  switch (clr) {
    case 'primary':
    case 'default':
      return theme.palette.op.primary[3]
    default:
      return clr
  }
}
