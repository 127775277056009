import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
    height: 55,

    '& input': {
      flex: 1,
      fontSize: '1em',
      paddingRight: '2em',
      border: 'none',
      alignSelf: 'stretch',
      outline: 'none',
      background: 'none',
      color: theme.palette.text.primary,
    },
  },
  warning: {
    fontSize: '0.8rem',
    color: theme.palette.error.main,
    marginRight: 15,
  },
  icon: {
    margin: '0 2rem',
    color: theme.palette.op.background.highlight(0.2),
  },
  searchWrapper: {
    flex: 1,
    display: 'grid',
    '& > *': {
      'grid-area': '1 / 1',
    },
  },
  hideSearchInput: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  locationChip: {
    width: 'max-content',
  },
  '@global': {
    '.pac-container': {
      zIndex: 99999,
      backgroundColor: theme.palette.op.background.deepPopover,
      boxShadow: theme.shadows[4],
      borderTop: 'none',
      borderRadius: 5,
    },
    '.pac-item': {
      color: theme.palette.text.secondary,
      borderTop: 'none',
      lineHeight: '40px',
      padding: '0 1rem',

      '&:hover, &.pac-item-selected': {
        backgroundColor: theme.palette.op.background.highlight(0.07),
      },
    },
    '.pac-item-query': {
      color: theme.palette.text.primary,
      paddingRight: 10,
    },
    '.pac-icon': {
      display: 'none',
    },
    '.pac-logo:after': {
      display: 'none',
    },
  },
}))

export default useStyles
