// @ts-strict-ignore
import { createCallbackState } from '@src/app/callback'
import { makeAutoObservable } from 'mobx'
import config from '../../../../config'
import { toQueryString, uniqueAndNonNull } from '../../../../lib'
import { EntityPhoneNumber, HubspotIntegration } from '../../../../service/model'
import { IUiStore } from '../../../../types'
import AppStore from '../../../store'

export type ImportStatus = 'loading' | 'failed' | 'success'
export default class HubSpotController implements IUiStore {
  /**
   * enable or disable import feature when it's available
   */
  importEnabled = false
  importStatus: ImportStatus = 'success'

  constructor(private app: AppStore) {
    makeAutoObservable(this)
  }

  get integration() {
    return this.app.service.integration.collection.list.find(
      (i) => i.type === 'hubspot',
    ) as HubspotIntegration
  }

  get allPhoneNumbers() {
    return this.app.service.phoneNumber.collection
  }

  get areAllActivated() {
    return (
      this.integration.resourceIds.every((id) => this.allPhoneNumbers.has(id)) &&
      this.integration.resourceIds.length === this.allPhoneNumbers.length
    )
  }

  get canImport() {
    return true
  }

  activatePhoneNumber = (phoneNumber: EntityPhoneNumber) => {
    if (!this.integration) return
    this.integration
      .update({
        resourceIds: uniqueAndNonNull([...this.integration.resourceIds, phoneNumber.id]),
      })
      .then(() =>
        this.app.toast.show({
          message: `${phoneNumber.formattedNumber} is activated.`,
        }),
      )
      .catch(this.app.showErrorAlert)
  }

  activateAllPhoneNumbers = () => {
    if (!this.integration) return

    this.integration
      .update({
        resourceIds: [...this.allPhoneNumbers.keys],
      })
      .then(() =>
        this.app.toast.show({
          message: `All numbers are activated.`,
        }),
      )
      .catch(this.app.showErrorAlert)
  }

  deactivateAllPhoneNumbers = () => {
    if (!this.integration) return

    this.integration
      .update({
        resourceIds: [],
      })
      .then(() =>
        this.app.toast.show({
          message: `All numbers are paused.`,
        }),
      )
      .catch(this.app.showErrorAlert)
  }

  pausePhoneNumber = (phoneNumber: EntityPhoneNumber) => {
    if (!this.integration) return
    this.integration
      .update({
        resourceIds: this.integration.resourceIds.filter((id) => id !== phoneNumber.id),
      })
      .then(() =>
        this.app.toast.show({
          message: `${phoneNumber.formattedNumber} is paused.`,
        }),
      )
      .catch(this.app.showErrorAlert)
  }

  connect = () => {
    const scopes = [
      'crm.schemas.deals.read',
      'crm.objects.owners.read',
      'crm.objects.contacts.write',
      'crm.objects.companies.write',
      'crm.objects.companies.read',
      'crm.objects.deals.read',
      'crm.schemas.contacts.read',
      'crm.objects.deals.write',
      'crm.objects.contacts.read',
      'crm.schemas.companies.read',
    ]

    const state = createCallbackState({
      redirectTo: this.app.history.location.pathname,
      desktop: this.app.isElectron,
    })

    const query = toQueryString({
      client_id: config.HUBSPOT_CLIENT_ID,
      redirect_uri: `${window.location.origin}/callback/hubspot`,
      scope: scopes.join(' '),
      state,
    })

    this.app.url.open(`https://app.hubspot.com/oauth/authorize${query}`)
  }

  createWithCode = (code: string) => {
    this.app.service.integration
      .createHubspot({
        code: code,
        redirectUri: `${window.location.origin}/callback/hubspot`,
        resourceIds: [],
      })
      .catch(this.app.showErrorAlert)
  }

  delete = () => {
    if (!this.integration) return
    this.app.showAlert({
      title: 'Disconnect HubSpot integration?',
      body: `Disconnecting this HubSpot integration will stop all the new calls and messages from being logged.`,
      actions: [
        {
          title: 'Yes, disconnect',
          type: 'destructive',
          onClick: () => {
            this.app.service.integration
              .delete(this.integration)
              .catch(this.app.showErrorAlert)
          },
        },
        {
          title: 'Cancel',
        },
      ],
    })
  }

  toggleLogCalls = () => {
    if (!this.integration) return
    this.integration
      .update({
        logCalls: {
          ...this.integration.logCalls,
          enabled: !this.integration.logCalls?.enabled,
        },
      })
      .catch(this.app.showErrorAlert)
  }

  toggleLogMessages = () => {
    if (!this.integration) return
    this.integration
      .update({
        logMessages: {
          ...this.integration.logMessages,
          enabled: !this.integration.logMessages?.enabled,
        },
      })
      .catch(this.app.showErrorAlert)
  }

  updateActivityType = (calls: string, messages: string) => {
    if (
      !this.integration ||
      (this.integration.logCalls.activityType === calls &&
        this.integration.logMessages.activityType === messages)
    ) {
      return
    }
    this.integration
      .update({
        logCalls: {
          ...this.integration.logMessages,
          activityType: calls,
        },
        logMessages: {
          ...this.integration.logMessages,
          activityType: messages,
        },
      })
      .then(() => this.app.toast.show({ message: 'Activity type updated.' }))
      .catch(this.app.showErrorAlert)
  }

  importContacts = async () => {
    this.importStatus = 'loading'
    // Simulate an async operation of importing contacts
    const importResult: ImportStatus = await new Promise((resolve) => {
      setTimeout(() => resolve(Math.random() > 0.5 ? 'failed' : 'success'), 1500)
    })
    this.importStatus = importResult
  }

  tearDown() {}
}
