import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'

interface ActionsProps extends React.HTMLProps<HTMLDivElement> {}

export const Actions: React.FC<ActionsProps> = function ({ className, ...props }) {
  const styles = useStyles({})
  return <div {...props} className={cx(styles.root, className)}></div>
}

export default Actions

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: 20,

    '& > *': {
      marginBottom: 8,
    },
  },
}))
