// @ts-strict-ignore
import shortid from '../../lib/short-id'
import { Event } from '@bugsnag/js'
import { makeAutoObservable } from 'mobx'
import { IUiStore } from '../..'
import AppStore from '../store'

export default class ErrorStore implements IUiStore {
  private event: Event = null
  clearError: () => void

  constructor(private app: AppStore) {
    makeAutoObservable(this, {})
    this.app.update.checkForUpdate()
  }

  get errorMessage() {
    return this.event?.originalError.message
  }

  get errorId() {
    return this.event?.getMetadata('request', 'errorId')
  }

  setEvent = (event: Event) => {
    const errorId = shortid()
    event.addMetadata('request', { errorId })
    this.event = event
  }

  copyError = () => {
    const error: Error = this.event.originalError
    const text = `[${this.errorId}] ${error.stack}`
    navigator.clipboard.writeText(text)
    this.app.toast.show({ message: 'Copied to clipboard!' })
  }

  reload = () => {
    this.event = null
    if (this.app.update.updateAvailable) {
      this.app.update.installAndRestart()
    } else {
      this.clearError?.()
    }
  }

  reset = () => {
    this.event = null
    this.app.reset()
  }

  tearDown() {}
}
