// @ts-strict-ignore
import { useAppStore } from '@src/app/context'
import { DependencyList } from 'react'
import { useReaction } from '../hooks'

export function useOnCommandClose(effect: () => void, deps?: DependencyList) {
  const { command } = useAppStore()

  useReaction(
    () => command.command,
    (currentCommand) => {
      if (!currentCommand) {
        effect()
      }
    },
    {},
    [command, ...deps],
  )
}
