import { observer } from 'mobx-react-lite'
import React, { useLayoutEffect } from 'react'
import { useOnboardingState } from '.'
import Button from '../../component/button-v2'
import TextField from '../../component/textfield-v2'
import keyboard from '../../lib/keyboard'
import useInputState from '../../lib/use-input-state'
import { Actions, Card } from './common'
import { useRestorePartnerStackKey } from './partnerStack'

const Account: React.FC<{}> = function ({}) {
  const state = useOnboardingState()
  const [firstName, setFirstName] = useInputState(state.user.firstName ?? '')
  const [lastName, setLastName] = useInputState(state.user.lastName ?? '')
  const [partnerStackKey, clearPartnerStackKey] = useRestorePartnerStackKey()

  useLayoutEffect(() => {
    if (state.user.firstName && state.user.lastName) {
      state.updateUserAndAdvance(
        state.user.firstName,
        state.user.lastName,
        partnerStackKey,
      )

      if (partnerStackKey) {
        clearPartnerStackKey()
      }
    }
  }, [])

  const continuePressed = () => {
    state.updateUserAndAdvance(firstName, lastName, partnerStackKey)

    clearPartnerStackKey()
  }

  return (
    <Card
      emoji="✍️"
      title="Your full name"
      description="Enter your first and last name to get your account up and running."
    >
      <div style={{ display: 'flex' }}>
        <TextField
          fullWidth
          autoFocus
          required
          label="First name"
          value={firstName}
          onChange={setFirstName}
          rootStyle={{ marginRight: 16, flex: 1 }}
        />
        <TextField
          fullWidth
          required
          label="Last name"
          value={lastName}
          onChange={setLastName}
          onKeyPress={keyboard.onEnter(continuePressed)}
          rootStyle={{ flex: 1 }}
        />
      </div>
      <Actions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          height={40}
          onClick={continuePressed}
          disabled={!firstName || !lastName}
        >
          Continue
        </Button>
      </Actions>
    </Card>
  )
}

export default observer(Account)
