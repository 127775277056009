// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import {
  EntityPhoneNumber,
  Identity,
  IdentityPhone,
  Model,
  PhoneNumber,
  Presence,
  compareByName,
} from '.'
import Service from '..'
import { getInitials, parseDate } from '../../lib'
import { isNonNull, isTruthy } from '../../lib/rx-operators'
import { RoleName } from '../../types'

export interface MemberPhoneNumber {
  id: string
  number: string
  name: string
  symbol: string
}

export interface SerializedMember {
  id: string
  firstName: string
  lastName: string
  email: string
  pictureUrl: string
  status: 'invited' | 'active' | 'suspended' | 'archived'
  role: RoleName
  phoneNumbers: { number: string }[]
  createdAt: number
  updatedAt: number
  directNumber?: string
  directNumberId?: string
}

export class Member implements Identity, Model {
  id: string = null
  firstName: string = null
  lastName: string = null
  email: string = null
  pictureUrl: string = null
  status: 'invited' | 'active' | 'suspended' | 'archived' = null
  role: RoleName = null
  phoneNumbers: MemberPhoneNumber[] = []
  createdAt: number = null
  updatedAt: number = null
  directNumber: string = null
  directNumberId: string = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get name(): string {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].filter(isTruthy).join(' ')
    }
    return this.email.split('@')[0]
  }

  get nameWithStatus(): string {
    let name: string
    if (this.firstName || this.lastName) {
      name = [this.firstName, this.lastName].filter(isTruthy).join(' ')
    } else {
      name = this.email.split('@')[0]
    }
    return [name, this.statusSymbol].filter(isNonNull).join(' ')
  }

  get shortName(): string {
    return this.firstName
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get presence(): Presence {
    return this.root.member.presence.get(this.id)
  }

  get sharedPhoneNumbers(): PhoneNumber[] {
    return this.root.phoneNumber.collection.list.filter((pn) => pn.isSharedWith(this))
  }

  get phones(): IdentityPhone[] {
    return this.sharedPhoneNumbers.map((p) => ({
      id: p.id,
      name: p.name,
      symbol: p.symbol,
      number: p.number,
    }))
  }

  get emailAddresses(): string[] {
    return [this.email]
  }

  get isOwner(): boolean {
    return ['owner'].includes(this.role)
  }

  get isAdmin(): boolean {
    return ['owner', 'admin'].includes(this.role)
  }

  get isBilling(): boolean {
    return ['owner', 'admin', 'billing'].includes(this.role)
  }

  get statusSymbol() {
    if (this.presence?.onCall) {
      return '📞'
    }
    return this.presence?.symbol
  }

  get statusText() {
    if (this.presence?.onCall) {
      return 'On a call...'
    }
    return this.presence?.text
  }

  get isAnonymous() {
    return false
  }

  canAdminPhoneNumber = (phoneNumber: PhoneNumber | EntityPhoneNumber): boolean => {
    return (
      this.isAdmin ||
      Boolean(
        phoneNumber?.users?.find(
          (u) => u.id === this.id && (u.role === 'owner' || u.role === 'admin'),
        ),
      )
    )
  }

  canInvite(role: RoleName) {
    return role !== 'billing' && (this.role === role || this.canManage(role))
  }

  canManage(role: RoleName) {
    const permissionsMap: { [key in RoleName]: RoleName[] } = {
      owner: ['owner', 'admin', 'member', 'billing'],
      admin: ['admin', 'member', 'billing'],
      member: ['member'],
      billing: ['billing'],
    }

    return permissionsMap[this.role].includes(role)
  }

  setRole = (role: RoleName) => {
    this.role = role
    return this.root.member.setRole(this)
  }

  delete = () => {
    if (this.status === 'invited') {
      return this.root.member.uninvite(this.id)
    } else {
      return this.root.member.delete(this)
    }
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = (): SerializedMember => {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      pictureUrl: this.pictureUrl,
      status: this.status,
      role: this.role,
      phoneNumbers: toJS(this.phoneNumbers),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      directNumber: this.directNumber,
      directNumberId: this.directNumberId,
    }
  }
}

export const isMember = (a: any): a is Member => {
  return a instanceof Member
}

export const compareMembersByPresence = (a: Member, b: Member): number => {
  const diff = a.presence.sortWeight - b.presence.sortWeight
  return diff === 0 ? compareByName(a, b) : diff
}
