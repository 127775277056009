import React from 'react'
import { useAppStore } from '@src/app/context'
import { fromNowV2 } from '../../lib'
import PickDuration from './pick-duration'

export interface DoNotDisturbProps {}

const DoNotDisturb: React.FC<DoNotDisturbProps> = function ({}) {
  const { command, service, toast } = useAppStore()

  const handleSet = (date: Date) => {
    command.hide()
    service.user.current.asMember.presence
      .snoozeNotifications(date.getTime())
      .catch(toast.showError)
    toast.show({ message: `Do not disturb on ${fromNowV2(date)}.` })
  }

  return (
    <PickDuration
      title="Do Not Disturb"
      description="Turn off calls and notifications for a period of time"
      onSet={handleSet}
    />
  )
}

export default DoNotDisturb
