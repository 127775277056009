// @ts-strict-ignore
import { PopoverOrigin } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../component/icon-button'
import { CopyIcon, MoreSmallIcon, MutedIcon } from '../../component/icons/custom'
import { DNDIcon, SettingsIcon } from '../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../component/popover-menu'
import Typography from '@ui/Typography'
import Unread from '../../component/unread'
import { typography } from '../../theme'
import { InboxUiStore } from '../inbox/store'
import SideMenuRow from './row'
import SideMenuSectionHeader from './section-header'
import VerticalSortable, { VerticalSortableItem } from '@ui/VerticalSortable'

interface PhoneNumbersProps {
  selectedId: string
  hideAccessory?: boolean
  hideTitle?: boolean
  onClick: (inbox: InboxUiStore) => void
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }

const PhoneNumbers: React.FC<PhoneNumbersProps> = function ({
  selectedId,
  hideAccessory,
  hideTitle,
  onClick,
}) {
  const { sideMenu, inboxes, service } = useAppStore()
  const currentUser = service.user.current.asMember

  function handleDragEnd(oldIndex: number, newIndex: number) {
    sideMenu.handleInboxDrop({ removedIndex: oldIndex, addedIndex: newIndex })
  }

  return (
    <>
      {!hideTitle && <SideMenuSectionHeader>Inboxes</SideMenuSectionHeader>}
      {!hideAccessory && (
        <PopoverMenu
          open={Boolean(sideMenu.moreMenu)}
          onClose={sideMenu.hideMoreMenu}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorReference="anchorPosition"
          anchorPosition={sideMenu.moreMenu?.anchor}
        >
          <MenuItem
            icon={<DNDIcon />}
            onClick={() => sideMenu.handleMute(sideMenu.moreMenu?.phoneNumber)}
          >
            {sideMenu.moreMenu?.phoneNumber.mutedUntil > Date.now() ? 'Unmute' : 'Mute'}
          </MenuItem>
          {currentUser?.canAdminPhoneNumber(sideMenu.moreMenu?.phoneNumber) && (
            <MenuItem
              icon={<SettingsIcon />}
              onClick={() => sideMenu.handlePhoneSettings(sideMenu.moreMenu?.phoneNumber)}
            >
              Settings
            </MenuItem>
          )}
        </PopoverMenu>
      )}
      <VerticalSortable items={inboxes.sorted} onReorder={handleDragEnd}>
        {inboxes.sorted.map((inbox) => (
          <VerticalSortableItem key={inbox.id} id={inbox.id}>
            <PhoneNumberRow
              key={inbox.id}
              inbox={inbox}
              hideAccessory={hideAccessory}
              onClick={onClick}
              selected={selectedId === inbox.id}
            />
          </VerticalSortableItem>
        ))}
      </VerticalSortable>
    </>
  )
}

const PhoneNumberRow = observer<{
  inbox: InboxUiStore
  selected: boolean
  hideAccessory?: boolean
  onClick: (inbox: InboxUiStore) => void
}>(({ inbox, selected, hideAccessory, onClick }) => {
  const styles = useStyles({})
  const { sideMenu } = useAppStore()
  const unread = inbox.unread

  return (
    <SideMenuRow
      className={cx(inbox.muted && styles.muted)}
      rootClassName={styles.root}
      onClick={() => onClick(inbox)}
      highlighted={selected}
      icon={
        <div
          className={styles.phoneEmoji}
          onClick={(e) => sideMenu.openEmojiSelector(e, inbox.phoneNumber)}
        >
          {inbox.phoneNumber.symbol || '📱'}
        </div>
      }
      accessory={(hover) =>
        hideAccessory ? null : hover ? (
          <>
            <IconButton
              size="small"
              title="Copy"
              icon={<CopyIcon />}
              onClick={(e) => sideMenu.handleCopy(e, inbox.phoneNumber.formattedNumber)}
              style={{ marginRight: 5 }}
            />
            <IconButton
              size="small"
              title="More"
              icon={<MoreSmallIcon />}
              onClick={(e) => sideMenu.openMoreMenu(e, inbox.phoneNumber)}
            />
          </>
        ) : inbox.muted ? (
          <MutedIcon style={{ transform: 'scale(0.8)' }} />
        ) : (
          unread > 0 && <Unread count={unread} />
        )
      }
    >
      <Typography nowrap variant="caption1" className={styles.name} fontWeight="regular">
        {inbox.phoneNumber.name}
      </Typography>
      <Typography nowrap variant="caption3" color="textSecondary" fontWeight="regular">
        {inbox.phoneNumber.formattedNumber}
      </Typography>
    </SideMenuRow>
  )
})

export default observer(PhoneNumbers)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 45,
  },
  name: {
    marginRight: 10,
    marginBottom: 2,
  },
  phoneEmoji: {
    ...typography.emoji,
    fontSize: '1.2rem',
    width: 30,
    height: 30,
    background: theme.palette.op.hover.primary,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create(['background'], {
      duration: 240,
      easing: 'ease',
    }),
  },
  muted: {
    opacity: 0.5,
  },
}))
