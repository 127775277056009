import AppStore from '@src/app/store'

import ProtocolHandler from './ProtocolHandler'

export const PROTOCOL = 'openphone'

/**
 * The protocol handler for OpenPhone.
 *
 * The `openphone:` protocol is a mechanism to encode actions in URL format,
 * allowing other components within OpenPhone (e.g. backend) to trigger certain
 * actions within the app.
 *
 * Available actions are:
 *  - `openphone://login?access_token=<token>`: login via google
 *  - `openphone://dial?number=<phone number>`: open the dialer with a phone number
 *  - `openphone://message?number=<phone number>`: open a new conversation in the selected inbox
 *  - `openphone://<path>`: navigate to a path within the app
 */
export default class OpenPhoneProtocolHandler extends ProtocolHandler {
  readonly protocol: string = PROTOCOL
  readonly web: boolean = false
  readonly desktop: boolean = true

  /**
   * A map of path-matching regexes to their respective handler methods.
   *
   * The regex is only for the pathname, not the query string.
   */
  protected readonly handlers: ReadonlyMap<
    RegExp,
    (url: URL, match: RegExpMatchArray) => void
  >

  constructor(app: AppStore) {
    super(app)
    // FIXME: replace with URL Pattern API
    // see https://linear.app/openphone/issue/ENG-3529
    this.handlers = new Map([
      [/^\/\/login$/, this.handleLogin],
      [/^\/\/dial$/, this.handleDial],
      [/^\/\/message$/, this.handleMessage],
      [/^\/\/alert$/, this.handleAlerts],
      [/^\/\/alert\/([^\/]+)$/, this.handleAlert],
      [/^\/\/contact$/, this.handleContacts],
      [/^\/\/contact\/([^\/]+)$/, this.handleContact],
      [/^\/\/contact\/([^\/]+)\/note\/([^\/]+)$/, this.handleContactNote],
      [/^\/\/conversation$/, this.handleInbox],
      [/^\/\/conversation\/([^\/]+)$/, this.handleConversation],
      [/^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)$/, this.handleConversation],
      [
        /^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)\/comment$/,
        this.handleConversation,
      ],
      [
        /^\/\/conversation\/([^\/]+)\/activity\/([^\/]+)\/comment\/([^\/]+)$/,
        this.handleConversation,
      ],
      [/^\/\/settings$/, this.handleSettings],
      [/^\/\/settings\/phone-numbers\/([^\/]+)$/, this.handleSettingsPhoneNumber],
      [/^\/\/settings\/integrations\/([^\/]+)$/, this.handleSettingsIntegration],
    ])
  }

  handle(url: URL): void {
    const path = url.pathname

    for (const [regex, handler] of this.handlers.entries()) {
      const match = path.match(regex)

      if (match) {
        return handler(url, match)
      }
    }
  }

  protected handleLogin = (url: URL) => {
    const loginStore = this.app.login
    // FIXME: make LoginUiStore a permanent instance in AppStore
    if (!loginStore) return
    const token = url.searchParams.get('access_token')
    if (!token) return
    loginStore.loading = true
    this.app.service.auth
      .googleSignin(token, loginStore.inviteCode)
      .catch(this.app.toast.showError)
      .finally(() => {
        loginStore.loading = false
      })
  }

  protected handleDial = (url: URL) => {
    const number = url.searchParams.get('number')
    if (!number) return
    const phoneNumber = this.normalizePhoneNumber(number)
    this.app.command.present({ name: 'dialer', phoneNumber })
  }

  protected handleMessage = (url: URL) => {
    const number = url.searchParams.get('number')
    if (!number) return
    const phoneNumber = this.normalizePhoneNumber(number)
    this.app.inboxes.selected.newConversation(phoneNumber, true)
  }

  protected handleAlerts = () => {
    this.app.alerts.show()
  }

  protected handleAlert = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.alerts.show(id)
  }

  protected handleContacts = () => {
    this.app.contacts.show()
  }

  protected handleContact = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.contacts.show(id)
  }

  protected handleContactNote = (url: URL, match: RegExpMatchArray) => {
    const id = match[1]
    this.app.contacts.show(id)
  }

  protected handleInbox = (url: URL, match: RegExpMatchArray) => {
    const phoneNumberId = url.searchParams.get('phoneNumberId')
    const directNumberId = url.searchParams.get('directNumberId')

    if (phoneNumberId || directNumberId) {
      const inbox = this.app.inboxes.all.find(
        (i) => i.id === phoneNumberId || i.id === directNumberId,
      )
      this.app.inboxes.setSelected(inbox)
    }
  }

  protected handleConversation = (url: URL, match: RegExpMatchArray) => {
    const conversationId = match[1]
    const anchorActivityId = match[2]

    this.app.inboxes
      .openConversationById(conversationId, anchorActivityId)
      .catch(this.app.toast.showError)
  }

  protected handleSettings = () => {
    this.app.history.push('/settings')
  }

  protected handleSettingsPhoneNumber = (url: URL, match: RegExpMatchArray) => {
    const phoneNumberId = match[1]
    this.app.history.push(`/settings/phone-numbers/${phoneNumberId}${url.search}`, false)
  }

  protected handleSettingsIntegration = (url: URL, match: RegExpMatchArray) => {
    const integration = match[1]
    this.app.history.push(`/settings/integrations/${integration}${url.search}`, false)
  }
}
