// @ts-strict-ignore
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  TableBody,
} from '@material-ui/core'
import { useAppStore } from '@src/app/context'
import { Header } from '@src/app/settings/common'
import WebhookEventsTableCell from '@src/app/settings/webhooks/events-table/WebhookEventsTableCell'
import WebhookEventsTableRow from '@src/app/settings/webhooks/events-table/WebhookEventsTableRow'
import Button from '@src/component/button-v2'
import IconButton from '@src/component/icon-button'
import { ChevronLeftIcon, ChevronRightIcon } from '@src/component/icons/Tint/08'
import { RefreshIcon } from '@src/component/icons/Tint/20/General'
import { CircularProgress } from '@src/component/progress'
import Typography from '@ui/Typography'
import {
  FetchWebhookEventsRequest,
  WebhookEventsResponse,
  WebhookEventsResultObject,
} from '@src/service'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'

interface WebhookEventsTableProps {
  webhookId: string
}

export function getLastHttpCallFromEvent(event?: WebhookEventsResultObject) {
  return event.httpCalls.sort(
    (a, z) => new Date(z.executedAt).getTime() - new Date(a.executedAt).getTime(),
  )[0]
}

function useEventDetailRoute(
  webhookId: string,
  events?: WebhookEventsResponse['result'],
) {
  const { command, history } = useAppStore()
  const eventDetailMatch = useRouteMatch<{ webhookId: string; eventId: string }>(
    '/settings/webhooks/:webhookId/event/:eventId',
  )

  useEffect(() => {
    if (
      !eventDetailMatch &&
      command.command?.name === 'test request details' &&
      !command.command.isTestRequest
    ) {
      command.hide()
    }

    if (!eventDetailMatch || !events) return

    const event = events.find((event) => event.id === eventDetailMatch.params.eventId)

    if (!event) {
      history.replace(`/settings/webhooks/${webhookId}`)
      return
    }

    const newestHttpCall = getLastHttpCallFromEvent(event)

    const statusCode = Number.isNaN(parseInt(newestHttpCall.statusCode, 10))
      ? (newestHttpCall.statusCode as Exclude<typeof newestHttpCall['statusCode'], '200'>)
      : parseInt(newestHttpCall.statusCode, 10)

    command.present({
      name: 'test request details',
      request: {
        name: event?.data?.object?.type,
        statusCode,
        date: newestHttpCall.executedAt,
        payload: JSON.stringify(event?.data, null, 2),
        response: JSON.stringify(newestHttpCall.response, null, 2),
        webhookId: event.webhookId,
      },
    })
  }, [eventDetailMatch, events, command])
}

const WebhookEventsTable = ({ webhookId }: WebhookEventsTableProps) => {
  const styles = useStyles()
  const { workspace } = useAppStore()
  const [currentPageNumber, setPageNumber] = useState(1)
  const [state, run] = useStatefulPromise<
    WebhookEventsResponse,
    FetchWebhookEventsRequest
  >((props) => workspace.fetchWebhookEvents(props))
  const [eventsLog, setEventsLog] = useState<WebhookEventsResponse>(null)

  useEventDetailRoute(webhookId, eventsLog?.result)

  useEffect(() => {
    run({
      id: webhookId,
      params: { limit: 10, page: currentPageNumber },
    })
  }, [])

  useEffect(() => {
    setEventsLog(state.data)
  }, [state.data])

  const handleFetchWebhooks = useCallback(
    (page?: number) =>
      run({
        id: webhookId,
        params: { limit: 10, page },
      }),
    [run],
  )

  const handleNextPage = () => {
    handleFetchWebhooks(currentPageNumber + 1).then(() =>
      setPageNumber((prevPage) => prevPage + 1),
    )
  }

  const handlePrevPage = () => {
    handleFetchWebhooks(currentPageNumber - 1).then(() =>
      setPageNumber((prevPage) => prevPage - 1),
    )
  }

  const handleRefresh = () => {
    handleFetchWebhooks()
    setPageNumber(1)
  }

  const handleResendEvent = useCallback(
    (event: WebhookEventsResultObject) => async () => {
      const response = await workspace.resendEvent(event.webhookId, event.data.object.id)
      setEventsLog((currentResponse) => {
        const eventsCopy = [...currentResponse.result]
        const retriedEventIndex = eventsCopy.findIndex((item) => item.id === response.id)
        eventsCopy.splice(retriedEventIndex, 1, response)
        return { ...currentResponse, result: eventsCopy }
      })
    },
    [],
  )

  const hasNextPage =
    eventsLog?.pageInfo?.total - currentPageNumber * eventsLog?.pageInfo?.limit > 0
  const hasPrevPage = currentPageNumber > 1

  return (
    <div>
      <Header
        title="Events log"
        subtitle="URL requests and all successful or failed responses"
        actions={
          <IconButton icon={<RefreshIcon />} title="Refresh" onClick={handleRefresh} />
        }
      />
      {state.status === PromiseStateStatus.Loading ? (
        <div className={styles.tableInfoContainer}>
          <CircularProgress />
        </div>
      ) : state.status === PromiseStateStatus.Failed ? (
        <div className={styles.tableInfoContainer}>
          <Typography>Oops something went wrong. Please try to refresh.</Typography>
        </div>
      ) : eventsLog?.result.length === 0 ? (
        <div className={styles.tableInfoContainer}>
          <Typography>No events yet</Typography>
        </div>
      ) : (
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <WebhookEventsTableCell width="230px" padding="none">
                  Event
                </WebhookEventsTableCell>
                <WebhookEventsTableCell width="200px" align="left">
                  Date & Time
                </WebhookEventsTableCell>
                <WebhookEventsTableCell align="left">Response</WebhookEventsTableCell>
                <WebhookEventsTableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody className={styles.body}>
              {eventsLog?.result.map((event) => {
                return (
                  <WebhookEventsTableRow
                    key={event?.data?.object?.id}
                    event={event}
                    handleResendEvent={handleResendEvent(event)}
                  />
                )
              })}
            </TableBody>
          </Table>
          <div className={styles.tableFooter}>
            <div className={styles.tableFooterLeftColumn}>
              <Typography variant="footnote" color="textTertiary">
                {`Showing ${(currentPageNumber - 1) * 10 + 1} - ${
                  (currentPageNumber - 1) * 10 + eventsLog?.result.length
                } of ${eventsLog?.pageInfo?.total}`}
              </Typography>
            </div>
            {(hasNextPage || hasPrevPage) && (
              <div className={styles.tableFooterRightColumn}>
                <IconButton
                  title="Previous"
                  onClick={handlePrevPage}
                  icon={<ChevronLeftIcon />}
                  disabled={!hasPrevPage}
                />
                <IconButton
                  title="Next"
                  onClick={handleNextPage}
                  icon={<ChevronRightIcon />}
                  disabled={!hasNextPage}
                />
              </div>
            )}
          </div>
        </TableContainer>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    tableContainer: { marginTop: 16 },
    body: {},
    tableInfoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 300,
    },
    tableFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 30,
    },
    tableFooterRightColumn: {
      display: 'flex',
    },
    tableFooterLeftColumn: {
      flex: 1,
    },
  }),
  { name: WebhookEventsTable.name },
)

export default observer(WebhookEventsTable)
