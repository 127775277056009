import { v4 as uuid } from 'uuid'
import cx from 'classnames'
import React, { useState } from 'react'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { createStyles, withStyles, makeStyles, alpha } from '@material-ui/core/styles'

interface TextFieldProps extends InputBaseProps {
  label?: string
}

export const Input = withStyles((theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      // TODO: Should we use the theme's palette here? @lightMode
      border: '1.5px solid rgba(255, 255, 255, 0.10)',
      fontSize: '1rem',
      transition: 'all 0.3s ease',

      '&::placeholder': {
        color: theme.palette.text.secondary,
      },

      '&:focus': {
        border: '1.5px solid rgba(255, 255, 255, 0.3)',
        boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.1)',
      },
    },
  }),
)(InputBase)

export const Label = withStyles((theme) =>
  createStyles({
    focused: {
      color: `${theme.palette.text.primary} !important`,
    },
  }),
)(InputLabel)

const TextField: React.FC<TextFieldProps> = function ({
  id: defaultId,
  label,
  fullWidth,
  style,
  ...props
}) {
  const styles = useStyles({})
  const [id] = useState(defaultId || uuid())

  return (
    <FormControl
      style={{ width: fullWidth ? '100%' : 'auto', ...style }}
      className={cx(label && styles.label)}
    >
      {label && (
        <Label shrink htmlFor={id}>
          {label}
        </Label>
      )}
      <Input id={id} fullWidth={fullWidth} {...props} />
    </FormControl>
  )
}

export default TextField

const useStyles = makeStyles((theme) => ({
  label: {
    paddingTop: theme.spacing(3),
  },
}))
