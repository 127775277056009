import MuiButton from '@material-ui/core/Button'
import type { ButtonProps } from '@material-ui/core/Button'
import { withStyles, createStyles, darken, alpha } from '@material-ui/core/styles'
import { colors } from '../theme'

const Button = withStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      textTransform: 'none',
      transition: theme.transitions.create(['background'], {
        duration: 240,
        easing: 'ease',
      }),
    },
    text: {
      color: theme.palette.op.text.secondary,
    },
    contained: {
      boxShadow: 'none',

      '&:hover': {
        boxShadow: 'none',
      },

      '&$disabled': {
        color: theme.palette.op.gray[4],
        background: theme.palette.op.gray[5],
      },
    },
    outlined: {},
    textPrimary: {
      color: theme.palette.primary.light,
    },
    textSecondary: {
      color: theme.palette.op.text.secondary,

      '&:hover': {
        background: theme.palette.op.hover.primary,
      },
    },
    outlinedPrimary: {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      transition: theme.transitions.create(['background', 'color', 'border-color'], {
        duration: '0.3s',
        easing: 'ease',
      }),

      '&:hover': {
        borderColor: theme.palette.primary.light,
        background: theme.palette.op.background.highlight(0.04),
      },
    },
    disabled: {},
  }),
)(MuiButton)

const DangerButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    transition: theme.transitions.create(['box-shadow'], {
      duration: '0.3s',
      easing: 'ease',
    }),
  },
  textPrimary: {
    color: theme.palette.primary.light,
  },
  contained: {
    color: 'white',
    backgroundColor: theme.palette.op.secondary.red1,

    '&:hover': {
      backgroundColor: darken(theme.palette.op.secondary.red1, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.op.secondary.red1, 0.1),
    },
  },
  outlined: {
    borderColor: theme.palette.op.legacy.buttonOutlined,
    color: theme.palette.op.legacy.buttonOutlined,

    '&:hover': {
      borderColor: darken(theme.palette.op.legacy.buttonOutlined, 0.1),
      backgroundColor: alpha(theme.palette.op.legacy.buttonOutlined, 0.05),
    },
    '&:focus': {
      borderColor: darken(theme.palette.op.legacy.buttonOutlined, 0.1),
      backgroundColor: alpha(theme.palette.op.legacy.buttonOutlined, 0.05),
    },
  },
  text: {
    color: colors.secondaryRed,

    '&:hover': {
      backgroundColor: alpha(colors.secondaryRed, 0.1),
    },
    '&:focus': {
      backgroundColor: alpha(colors.secondaryRed, 0.05),
    },
  },
}))(Button)

export default Button
export { DangerButton, ButtonProps }
