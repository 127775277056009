import Autolinker, {
  EmailMatch,
  Match,
  MentionMatch,
  PhoneMatch,
  UrlMatch,
} from 'autolinker'
import { Collection, Enrichment, Member } from '../../service/model'

export { EmailMatch, Match, MentionMatch, PhoneMatch, UrlMatch }

// We don't use the autolinking feature of autolinker, we use
// autolinker.parse().
const autolinker = new Autolinker({
  // This will enable the mentioning feature of autolinker, even if we aren't
  // using it for Twitter mentions.
  mention: 'twitter',
  // React will sanitize our plain text.
  sanitizeHtml: false,
  // Retain the original text from the user.
  stripPrefix: false,
  stripTrailingSlash: false,
})

export interface PlainTextPart {
  type: 'plain'
  text: string
}

export interface UrlLink {
  type: 'url'
  match: UrlMatch
}

export interface EmailLink {
  type: 'email'
  match: EmailMatch
}

export interface PhoneLink {
  type: 'phone'
  match: PhoneMatch
}

export type Link = UrlLink | EmailLink | PhoneLink

export interface LinkTextPart {
  type: 'link'
  link: Link
}

export interface MentionTextPart {
  type: 'mention'
  match: MentionMatch
}

export type TextPart = PlainTextPart | LinkTextPart | MentionTextPart

export function getTextParts(input: string): TextPart[] {
  const parts: TextPart[] = []
  const matches = autolinker.parse(input)
  let index = 0

  for (const match of matches) {
    const text = input.substring(index, match.getOffset())
    const type = match.getType()
    parts.push({ type: 'plain', text })

    if (type === 'mention') {
      parts.push({ type: 'mention', match: match as MentionMatch })
    } else {
      parts.push({ type: 'link', link: { type, match } as Link })
    }

    index += text.length + match.getMatchedText().length
  }

  parts.push({ type: 'plain', text: input.substring(index) })
  return parts
}

export function replaceMentions(
  text: string,
  enrichment: Enrichment,
  members: Collection<Member>,
): string {
  if (!enrichment) return text
  const ids = [
    ...(enrichment.taggedIds.userIds ?? []),
    ...(enrichment.taggedIds.groupIds ?? []),
    ...(enrichment.taggedIds.orgIds ?? []),
  ]
  ids.forEach((id) => {
    const name = members.get(id)?.name ?? enrichment.tokens[id]?.replacement
    text = text.replace(new RegExp(id, 'g'), name)
  })
  return text
}
