// @ts-strict-ignore
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { Collection, ImmutableCollection } from '../service/model'
import { isReadonlyArray } from './collections'
import shortId from './short-id'

export interface StepperOptions<T> {
  /**
   * A name for debugging purposes.
   */
  name?: string

  /**
   * The index at which to start.
   */
  index?: number

  /**
   * A hook for skipping items and proceeding during next/prev.
   */
  skip?(item: T, index: number): boolean
}

export interface SelectOptions {
  /**
   * When skipping items during selection, the stepper index is advanced by
   * this value until the next appropriate item is found.
   *
   * @default 1
   */
  advanceBy?: -1 | 1

  /**
   * When a "skip" is detected, either proceed to the next/prev index, stay at
   * the current index, or deselect completely.
   *
   * @default 'proceed'
   */
  skipBehavior?: 'proceed' | 'stay' | 'deselect'
}

export interface DataStore<T> {
  data: readonly T[] | Collection<T> | ImmutableCollection<T>
}

/**
 * Stepper keeps track of a selected index within an array of items or a
 * collection.
 *
 * The index can be advanced forwards or backwards by one item at a time, or
 * can be moved to the start or end of the array.
 *
 * Stepper has support for `skip`, a function which can be used to skip
 * particular items when moving the index.
 */
export default class Stepper<T> {
  readonly id = this.options.name ?? shortId()
  readonly debug = Debug(`op:lib:stepper:@${this.id}`)

  item: T | null = null

  constructor(protected store: DataStore<T>, protected options: StepperOptions<T> = {}) {
    this.item = this.items[this.options.index ?? -1] ?? null

    this.debug(
      'initiate (index: %O) (items: %O) (options: %O)',
      this.index,
      this.items,
      this.options,
    )

    makeObservable(this, {
      item: observable.ref,
      index: computed,
      items: computed,
      select: action.bound,
      selectIndex: action.bound,
      next: action.bound,
      prev: action.bound,
      start: action.bound,
      end: action.bound,
    })

    reaction(
      () => this.index,
      (index, prevIndex) => {
        this.debug('index change (%O -> %O)', prevIndex, index)
      },
      { name: 'Stepper.IndexChanged' },
    )
  }

  get index() {
    return this.items.indexOf(this.item)
  }

  get items(): readonly T[] {
    return isReadonlyArray(this.store.data) ? this.store.data : this.store.data.list
  }

  /**
   * Mark `item` as the selected item.
   *
   * This will adjust the index as needed and handle the case where `item`
   * should be skipped. This is the preferred way to make a new selection.
   */
  select(
    item: T | null,
    { advanceBy = 1, skipBehavior = 'proceed' }: SelectOptions = {},
  ) {
    const requestedIndex = this.items.indexOf(item)
    const availableIndex = this.findAvailableIndex(requestedIndex, advanceBy, this.index)
    let newIndex = availableIndex
    if (skipBehavior !== 'proceed' && availableIndex !== requestedIndex) {
      this.debug('skip detected during select (behavior: %O)', skipBehavior)
      newIndex = skipBehavior === 'stay' ? this.index : -1
    }
    this.item = newIndex > this.items.length - 1 ? null : this.items[newIndex]
  }

  /**
   * Set the stepper index to a particular index.
   */
  selectIndex(index: number | null, options: SelectOptions = {}): void {
    this.select(this.items[index], options)
  }

  /**
   * Step forward to the next suitable index.
   */
  next(): void {
    this.selectIndex(Math.min(this.items.length - 1, this.index + 1))
  }

  /**
   * Step backward to the previous suitable index.
   */
  prev(): void {
    this.selectIndex(Math.max(0, this.index - 1), { advanceBy: -1 })
  }

  /**
   * Set {@link index} to the first suitable index.
   */
  start(): void {
    this.selectIndex(0)
  }

  /**
   * Set {@link index} to the last suitable index.
   */
  end(): void {
    this.selectIndex(this.items.length - 1, { advanceBy: -1 })
  }

  protected findAvailableIndex(
    index: number,
    advanceBy: -1 | 1,
    originalIndex = index,
  ): number {
    const { skip } = this.options

    if (skip?.(this.items[index], index)) {
      this.debug('skipping (index: %O)', index)
      const nextIndex = index + advanceBy

      if (nextIndex < 0 || nextIndex >= this.items.length) {
        this.debug(
          'next index out of bounds (nextIndex: %O) (originalIndex: %O)',
          nextIndex,
          originalIndex,
        )

        return originalIndex
      }

      return this.findAvailableIndex(nextIndex, advanceBy, originalIndex)
    }

    return index
  }
}
