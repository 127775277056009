// @ts-strict-ignore
import { useRef, useState } from 'react'
import useKeyboardShortcuts, { FilterHandler } from './use-keyboard-shortcuts'

type GridIndex = { row: number; col: number }

interface KeyStepperProps<T> {
  name?: string
  node?: Element | Document
  rowCount: number
  columnCount: (row: number) => number
  deps?: any[]
  selectOnMouseMove?: boolean
  filter?: FilterHandler
  skip?: (index: GridIndex) => boolean
  handleSelect: (index: GridIndex) => void
  defaultSelectedIndex?: GridIndex
}

interface KeyStepperResult {
  selectedIndex: GridIndex
  setSelectedIndex: (index: GridIndex) => void
  getItemProps: (index: GridIndex) => void
}

export default function useGridStepper<T>(props: KeyStepperProps<T>): KeyStepperResult {
  const {
    name,
    node,
    rowCount,
    columnCount,
    deps = [],
    skip,
    selectOnMouseMove = true,
    filter,
    handleSelect,
    defaultSelectedIndex = { row: 0, col: 0 },
  } = props
  const selectedIndexRef = useRef<GridIndex>(null)
  const [selectedIndex, setSelectedIndex] = useState<GridIndex>(defaultSelectedIndex)

  // FIXME: We should avoid setting the selectedIndexRef.current in the render function
  selectedIndexRef.current = selectedIndex

  function selectNextAvailable(
    index: GridIndex,
    autoAdvanceBy: 0 | -1 | 1 = 0,
    originalIndex: GridIndex,
    event?: Event,
  ): GridIndex {
    originalIndex = originalIndex ?? index

    if (rowCount === 0) {
      // FIXME: selectNextAvailable is called within a setState callback, so we should avoid calling the setter here
      setSelectedIndex(null)
    }

    // If this item has to be skipped, then check the
    if (skip?.(index)) {
      const nextIndex = { ...index, row: index.row + autoAdvanceBy }
      if (nextIndex.row < 0 || nextIndex.row >= rowCount || nextIndex === index) {
        return originalIndex
      }

      return selectNextAvailable(nextIndex, autoAdvanceBy, originalIndex, event)
    }

    event?.preventDefault()

    const maxColumn = columnCount(index.row)
    index.col = Math.min(maxColumn - 1, index.col)
    return index
  }

  useKeyboardShortcuts({
    name: name ?? 'use-grid-stepper',
    node: node || document,
    filter,
    handler: (shortcut, event) => {
      if (shortcut === 'ArrowUp') {
        setSelectedIndex((i) => {
          const newIndex = { ...i, row: Math.max(0, i.row - 1) }
          return selectNextAvailable(newIndex, -1, i, event)
        })
      } else if (shortcut === 'ArrowDown') {
        setSelectedIndex((i) => {
          const newIndex = { ...i, row: Math.min(rowCount - 1, i.row + 1) }
          return selectNextAvailable(newIndex, 1, i, event)
        })
      } else if (shortcut === 'Meta+ArrowUp') {
        setSelectedIndex((i) => selectNextAvailable({ row: 0, col: 0 }, 1, i, event))
      } else if (shortcut === 'Meta+ArrowDown') {
        setSelectedIndex((i) =>
          selectNextAvailable({ row: rowCount - 1, col: 0 }, -1, i, event),
        )
      } else if (shortcut === 'ArrowRight') {
        setSelectedIndex((i) => {
          const maxCol = columnCount(i.row)
          const newIndex = { ...i, col: Math.min(maxCol - 1, i.col + 1) }
          return selectNextAvailable(newIndex, 0, i, event)
        })
      } else if (shortcut === 'Meta+ArrowRight') {
        setSelectedIndex((i) => {
          const maxCol = columnCount(i.row)
          const newIndex = { ...i, col: maxCol - 1 }
          return selectNextAvailable(newIndex, 0, i, event)
        })
      } else if (shortcut === 'ArrowLeft') {
        setSelectedIndex((i) => {
          const newIndex = { ...i, col: Math.max(0, i.col - 1) }
          return selectNextAvailable(newIndex, 0, i, event)
        })
      } else if (shortcut === 'Meta+ArrowLeft') {
        setSelectedIndex((i) => {
          const newIndex = { ...i, col: 0 }
          return selectNextAvailable(newIndex, 0, i, event)
        })
      } else if (shortcut === 'Enter') {
        const index = rowCount === 0 ? null : selectedIndexRef.current
        if (index) {
          handleSelect(index)
          event.preventDefault()
        }
      }
    },
    dep: [rowCount, ...deps],
  })

  const getItemProps = (index: GridIndex): any => {
    return {
      onMouseMove: () => {
        if (selectOnMouseMove && !skip?.(index)) {
          setSelectedIndex(index)
        }
      },
      onClick: () => {
        if (!skip?.(index)) {
          setSelectedIndex(index)
          handleSelect(index)
        }
      },
    }
  }

  return { selectedIndex, setSelectedIndex, getItemProps }
}
