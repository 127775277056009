// @ts-strict-ignore
import { makeAutoObservable, action } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import { Model } from '.'
import { isNonNull } from '../../lib/rx-operators'

type PresenceStatus = 'online' | 'offline'

export interface IPresence {
  userId?: string
  status?: PresenceStatus
  snoozed?: boolean
  onCall?: boolean
  snoozedUntil?: number
  offlineUntil?: number
  symbol?: string
  text?: string
}

export class Presence implements IPresence, Model {
  userId?: string = null
  status?: PresenceStatus = null
  snoozed?: boolean = null
  _onCall?: boolean = null
  snoozedUntil?: number = null
  offlineUntil?: number = null
  symbol?: string = null
  text?: string = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get id() {
    return this.userId
  }

  get isOnline() {
    return this.status === 'online'
  }

  get isSnoozed() {
    return this.snoozed && this.snoozedUntil > Date.now()
  }

  get onCall(): boolean {
    return this.root.billing.capabilities.showOnCallStatus && this._onCall
  }

  get hasStatus(): boolean {
    return Boolean(this.text || this.symbol)
  }

  get fullStatus(): string {
    return [this.symbol, this.text].filter(isNonNull).join(' ')
  }

  /**
   * The weight to be used when sorting members by presence.
   *
   * Members should be ordered like this:
   *  1. Online
   *  2. Offline
   *  3. On a call
   *  4. Snoozed
   */
  get sortWeight(): number {
    if (this.isSnoozed) return 3
    if (this.onCall) return 2
    if (!this.isOnline) return 1
    return 0
  }

  setStatus = (symbol: string, text: string) => {
    this.text = text
    this.symbol = symbol
    return this.root.member.updatePresence(this)
  }

  setOnline = (online: boolean) => {
    if (online) {
      this.offlineUntil = null
    } else {
      this.offlineUntil = Date.now() + 3600000 * 24 * 365 * 100
    }
    this.status = online ? 'online' : 'offline'
    return this.root.member.updatePresence(this)
  }

  snoozeNotifications(until: number) {
    this.snoozedUntil = until
    return this.root.member
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = true)))
  }

  unsnooze() {
    this.snoozedUntil = null
    return this.root.member
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = false)))
  }

  save = () => {
    return this.root.member.updatePresence(this)
  }

  deserialize = ({ id, onCall, ...json }: any) => {
    Object.assign(this, json)
    if (typeof onCall === 'boolean') {
      this._onCall = onCall
    }
    this.snoozedUntil = parseDate(json.snoozedUntil)
    this.offlineUntil = parseDate(json.offlineUntil)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      userId: this.userId,
      status: this.status,
      snoozed: this.snoozed,
      snoozedUntil: this.snoozedUntil,
      offlineUntil: this.offlineUntil,
      _onCall: this._onCall,
      symbol: this.symbol,
      text: this.text,
    }
  }
}
