import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

export default withStyles((theme) => ({
  root: {
    marginTop: 23,
  },
  input: {
    border: `1.5px solid ${theme.palette.op.border.common}`,
    padding: 10,
    borderRadius: 6,

    '&:focus': {
      borderColor: theme.palette.op.border.focus,
    },
  },
}))(InputBase)
