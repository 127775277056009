// @ts-strict-ignore
import { debounce } from '@material-ui/core'
import Debug from 'debug'
import React, { createRef } from 'react'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { formatPartial } from '../../lib/phone-number'
import shortId from '../../lib/short-id'
import Stepper from '../../lib/stepper'
import {
  Identity,
  ImmutableCollection,
  PhoneNumber,
  PhoneNumberGroup,
  Member,
  Contact,
} from '../../service/model'
import KeyboardListController from '../keyboard-list/controller'

export type PhoneNumberRow =
  | {
      id: string
      type: 'header'
      title: string
    }
  | {
      id: string
      type: 'number'
      number: string
    }
  | {
      id: string
      type: 'phone-number-member'
      identity: Member
    }
  | {
      id: string
      type: 'phone-number'
      phoneNumber: PhoneNumber
    }
  | {
      id: string
      type: 'member'
      identity: Member
    }
  | {
      id: string
      type: 'contact'
      identity: Contact
    }

export type TransfereeDescriptor =
  | { type: 'inbox'; to: PhoneNumber }
  | { type: 'member'; to: Member }
  | { type: 'member-via-inbox'; to: Member; via: string }
  | { type: 'contact'; to: Contact; via: string }
  | { type: 'number'; to: string }

export default abstract class Controller {
  abstract currentUser: Member
  abstract currentPhoneNumber: PhoneNumber | null
  abstract currentPhoneNumberMembers: Member[]
  abstract phoneNumbers: PhoneNumber[]
  abstract members: Member[]
  abstract contacts: Contact[]

  readonly id = shortId()
  readonly debug = Debug(`op:phone-number-selector:@${this.id}`)
  readonly searchRef: React.MutableRefObject<HTMLInputElement> = createRef()
  readonly keyboardList = new PhoneNumberSelectorKeyboardListController(this)

  menuIndex: number = null
  searchFocused: boolean = null

  protected _search: string | null = null

  constructor() {
    this.debug('new controller')

    makeObservable<this, '_search'>(this, {
      menuIndex: observable.ref,
      searchFocused: observable.ref,
      _search: observable.ref,
      search: computed,
      phoneNumberGroups: computed,
      data: computed,
      hasResults: computed,
      handleSelectRow: action.bound,
      handleSearchClear: action.bound,
      handleSearchChange: action.bound,
      handleSearchFocus: action.bound,
      handleSearchBlur: action.bound,
      openMenu: action.bound,
      closeMenu: action.bound,
    })

    reaction(
      () => this.keyboardList.stepper.index,
      () => {
        this.menuIndex = null
      },
      { name: 'PhoneNumberSelector.StepperIndexChanged' },
    )
  }

  get search() {
    return this._search ? this._search.toLowerCase() : ''
  }

  set search(search: string) {
    this._search = search
  }

  get phoneNumberGroups(): PhoneNumberGroup[] {
    return this.phoneNumbers.map((phoneNumber) => phoneNumber.group)
  }

  get data(): ImmutableCollection<PhoneNumberRow> {
    const list: PhoneNumberRow[] = []
    const formattedSearch = formatPartial(this.search)
    const identityFilter = this.createIdentityFilter(this.search)
    const phoneNumberGroupFilter = (group: PhoneNumberGroup) =>
      group.phoneNumber.id !== this.currentPhoneNumber.id
    const memberFilter = (member: Member) => member.id !== this.currentUser.id
    const filteredCurrentPhoneNumberMembers =
      this.currentPhoneNumberMembers.filter(identityFilter).filter(memberFilter) ?? []
    const filteredPhoneNumberGroups = this.phoneNumberGroups
      .filter(identityFilter)
      .filter(phoneNumberGroupFilter)
    const filteredMembers = this.members.filter(identityFilter).filter(memberFilter)
    const filteredContacts = this.search ? this.contacts.filter(identityFilter) : []
    this.debug(
      'building data (search: %O) (formattedSearch: %O)',
      this.search,
      formattedSearch,
    )
    if (formattedSearch) {
      list.push(
        {
          id: 'call-number-header',
          type: 'header',
          title: 'Number',
        },
        {
          id: 'call-number',
          type: 'number',
          number: formattedSearch,
        },
      )
    }
    if (this.currentPhoneNumber && filteredCurrentPhoneNumberMembers.length > 0) {
      list.push(
        {
          id: 'current-phone-number-header',
          type: 'header',
          title: `In ${this.currentPhoneNumber.formattedName}`,
        },
        ...filteredCurrentPhoneNumberMembers.map(
          (member): PhoneNumberRow => ({
            id: `phone-number-member-${member.id}`,
            type: 'phone-number-member',
            identity: member,
          }),
        ),
      )
    }
    if (filteredPhoneNumberGroups.length > 0) {
      list.push(
        { id: 'phone-numbers-header', type: 'header', title: 'Shared inboxes' },
        ...filteredPhoneNumberGroups.map(
          ({ phoneNumber }): PhoneNumberRow => ({
            id: `phone-number-${phoneNumber.id}`,
            type: 'phone-number',
            phoneNumber,
          }),
        ),
      )
    }
    if (filteredMembers.length > 0) {
      list.push(
        { id: 'members-header', type: 'header', title: 'Team members' },
        ...filteredMembers.map(
          (member): PhoneNumberRow => ({
            id: `member-${member.id}`,
            type: 'member',
            identity: member,
          }),
        ),
      )
    }
    if (filteredContacts.length > 0) {
      list.push(
        { id: 'contacts-header', type: 'header', title: 'Contacts' },
        ...filteredContacts.map(
          (contact): PhoneNumberRow => ({
            id: `contact-${contact.id}`,
            type: 'contact',
            identity: contact,
          }),
        ),
      )
    }
    return new ImmutableCollection(list)
  }

  get hasResults(): boolean {
    return Boolean(this.data.find((item) => item.type !== 'header'))
  }

  /**
   * Called when a phone number is selected.
   */
  abstract handleSelect(transferee: TransfereeDescriptor): void

  handleSelectRow(row: PhoneNumberRow, index: number) {
    switch (row.type) {
      case 'member':
      case 'contact':
        if (row.identity.phones.length > 1) {
          return this.openMenu(index)
        }

        return this.handleSelect(
          row.type === 'member'
            ? {
                type: 'member-via-inbox',
                to: row.identity,
                via: row.identity.phones[0]?.id ?? null,
              }
            : {
                type: 'contact',
                to: row.identity,
                via: row.identity.phones[0]?.number ?? null,
              },
        )
      case 'phone-number':
        return this.handleSelect({ type: 'inbox', to: row.phoneNumber })
      case 'phone-number-member':
        return this.handleSelect({ type: 'member', to: row.identity })
      case 'number':
        return this.handleSelect({ type: 'number', to: row.number })
    }
  }

  handleSearchClear() {
    this.searchRef.current.value = ''
    this.setSearch(null)
  }

  handleSearchChange(event: React.FormEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.setSearch(event.target.value)
    }
  }

  handleSearchFocus(event: React.FocusEvent) {
    this.searchFocused = true
  }

  handleSearchBlur(event: React.FocusEvent) {
    this.searchFocused = false
  }

  openMenu(index: number) {
    this.menuIndex = index
  }

  closeMenu() {
    this.menuIndex = null
  }

  protected setSearch = debounce(
    action('setSearch', (search: string | null) => {
      this.keyboardList.stepper.selectIndex(0)
      this.search = search
    }),
    500,
  )

  protected createIdentityFilter =
    (search: string) =>
    (identity: Identity): boolean =>
      !search ||
      identity.name.toLowerCase().includes(search) ||
      Boolean(identity.phones.find((phone) => phone.number.includes(search)))
}

export class PhoneNumberSelectorKeyboardListController extends KeyboardListController<PhoneNumberRow> {
  readonly itemHeight = (row: PhoneNumberRow, index: number) =>
    row.type === 'header' ? 40 : 30
  readonly itemHoverMode = 'select'
  override readonly itemSpacing = { top: 0, right: 4, bottom: 2, left: 4 }

  constructor(protected controller: Controller) {
    super()
    makeObservable(this, {
      data: computed,
      stepper: computed,
      handleEnter: action.bound,
    })
  }

  get stepper() {
    return new Stepper(this, {
      name: `phone-number-selector-${this.controller.id}`,
      skip: (row) => row?.type === 'header',
    })
  }

  get data() {
    return this.controller.data
  }

  override handleEnter(item: PhoneNumberRow, index: number) {
    this.controller.handleSelectRow(item, index)
  }
}
