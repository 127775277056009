// @ts-strict-ignore
import { Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDown from '@material-ui/icons/ArrowDropDown'
import cx from 'classnames'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button, { ButtonProps } from '../component/button'
import Menu from '../component/menu'
import { PhoneNumber } from '../service/model'

interface FromPhoneNumberProps extends ButtonProps {
  selectedId?: string
  onNumberSelected: (phoneNumber: PhoneNumber) => void
  className?: string
  disabled?: boolean
  onlyName?: boolean
}

const FromPhoneNumber: React.FC<FromPhoneNumberProps> = function ({
  selectedId,
  onNumberSelected,
  className,
  onlyName,
  size = 'small',
  color = 'primary',
  ...props
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null)
  const phoneNumbers = service.user.phoneNumbers.list
  const phoneNumber = service.user.phoneNumbers.get(selectedId)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (phoneNumber: PhoneNumber) => {
    onNumberSelected(phoneNumber)
    handleClose()
  }

  return (
    <>
      <Button
        {...props}
        className={cx(styles.button, className)}
        onClick={handleClick}
        size={size}
        color={color}
        disabled={phoneNumbers.length <= 1}
      >
        {phoneNumber ? (
          <>
            <span style={{ marginRight: 6 }}>{phoneNumber.symbol}</span>{' '}
            {phoneNumber.name}
            {!onlyName && ` - ${phoneNumber.formattedNumber}`}
          </>
        ) : (
          <span>Select phone number...</span>
        )}
        {phoneNumbers.length > 1 && <ArrowDown fontSize="small" color="inherit" />}
      </Button>
      <Menu
        id="from-phone-number"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {phoneNumbers.map((phoneNumber) => (
          <MenuItem
            key={phoneNumber.id}
            dense
            onClick={() => handleSelect(phoneNumber)}
            className={styles.item}
            selected={phoneNumber.id === selectedId}
          >
            <Typography variant="body2" className={styles.itemName}>
              <span style={{ marginRight: 6 }}>{phoneNumber.symbol}</span>{' '}
              {phoneNumber.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {phoneNumber.formattedNumber}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default FromPhoneNumber

const useStyles = makeStyles({
  button: {
    marginLeft: -4,
    minWidth: 'auto',
    padding: '0px 4px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemName: {
    fontWeight: 500,
    marginRight: '1.5rem',
  },
})
