import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import { AppAvatar } from '@src/app/components'
import { fuzzySearch } from '../../../lib/search'
import { highlight } from '../../../lib/string'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Member } from '../../../service/model'
import CommandItem from './item'
import TextField from './textfield'

interface MemberPickerProps {
  includeIds?: string[]
  excludeIds?: string[]
  onSelect: (member: Member) => void
}

const MemberPicker: React.FC<MemberPickerProps> = function ({
  includeIds,
  excludeIds = [],
  onSelect,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const members = service.member.collection.list
  const [input, setInput] = useInputState('')

  const items = useMemo(() => {
    const activeMembers = members.filter(
      (member) =>
        (!includeIds || includeIds.includes(member.id)) &&
        !excludeIds.includes(member.id) &&
        member.status !== 'invited',
    )
    if (!input) {
      return activeMembers
    } else {
      return fuzzySearch(activeMembers, input, ['name', 'email'])
    }
  }, [input])

  const { getItemProps, selectedIndex } = useKeyStepper({
    items,
    name: 'command/common/member-picker',
    handleSelect: onSelect,
  })

  return (
    <>
      <TextField
        autoFocus
        value={input}
        onChange={setInput}
        placeholder="Type a name or an email"
      />
      <div className={styles.list}>
        {items.map((item, index) => (
          <CommandItem
            key={item.id}
            className={styles.row}
            {...getItemProps(index)}
            highlighted={index === selectedIndex}
          >
            <AppAvatar identity={item} className={styles.avatar} />
            <Typography
              variant="body2"
              color="textPrimary"
              dangerouslySetInnerHTML={{ __html: highlight(item.name, input) }}
            />
            <div className={styles.spacer} />
            <Typography
              variant="caption"
              color="textSecondary"
              dangerouslySetInnerHTML={{ __html: highlight(item.email, input) }}
            />
          </CommandItem>
        ))}
      </div>
    </>
  )
}

export default observer(MemberPicker)

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginBottom: 10,
  },
  row: {
    height: 45,
  },
  avatar: {
    marginRight: '1rem',
  },
  spacer: {
    flex: '1',
  },
}))
