// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import { DangerButton } from '../../../component/button'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { Header, Page } from '../common'
import AutoReplies from './auto-replies'
import BusinessHours from './business-hours'
import ConfirmDelete from './confirm-delete'
import General from './general'
import PhoneNumberIntegrations from './integrations'
import PhoneMenu from './phone-menu'
import Users from './users'
import Voicemail from './voicemail'

interface PathParams {
  id: string
}

const PhoneNumber = ({ match }: RouteComponentProps<PathParams>) => {
  const phoneNumberId = match.params.id
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { service, history, toast } = useAppStore()
  const user = service.user.current.asMember
  const subscription = service.billing.subscription
  const members = service.member.collection
  const phoneNumber = service.organization.phoneNumber.collection.get(phoneNumberId)
  const canEdit = user.canAdminPhoneNumber(phoneNumber)

  const handleDelete = () => {
    phoneNumber.delete().catch(toast.showError)
    handleBack()
  }

  const handleBack = () => {
    history.replace('/settings/phone-numbers')
  }

  return (
    <Page>
      <Header
        backTitle="Back to phone numbers"
        onBack={handleBack}
        title={phoneNumber?.formattedNumber}
        subtitle="Settings related to this phone number"
      />
      <General phoneNumber={phoneNumber} />
      <Users canEdit={canEdit} members={members} phoneNumber={phoneNumber} />
      <BusinessHours phoneNumber={phoneNumber} />
      <Voicemail phoneNumber={phoneNumber} />
      <PhoneMenu phoneNumber={phoneNumber} />
      <AutoReplies phoneNumber={phoneNumber} />
      <PhoneNumberIntegrations phoneNumber={phoneNumber} />
      {user.isAdmin && (
        <>
          <ConfirmDelete
            open={confirmDelete}
            setOpen={setConfirmDelete}
            onDelete={handleDelete}
          />
          <div>
            <Typography
              variant="footnote"
              textTransform="uppercase"
              style={{ padding: '5px 8px 0' }}
            >
              danger zone
            </Typography>
            <Typography
              variant="caption1"
              color="textSecondary"
              style={{ padding: '10px 8px 20px' }}
            >
              By deleting your phone number, you will lose access to all messages and
              calls to and from this phone number.
            </Typography>
            <Tooltip
              disabled={!subscription.trial}
              title="This phone number cannot be deleted during your trial. Convert to a paid subscription first."
              placement="top"
            >
              <span>
                <DangerButton
                  disabled={subscription.trial}
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete phone number
                </DangerButton>
              </span>
            </Tooltip>
          </div>
        </>
      )}
    </Page>
  )
}

export default observer(PhoneNumber)
