import { observer } from 'mobx-react-lite'
import React from 'react'
import { ReferralLink } from '../../settings'
import { Command } from '../common-v2'

const ReferralCommand = () => {
  return (
    <Command width={672} height="large">
      <ReferralLink fullWidth={true} />
    </Command>
  )
}

export default observer(ReferralCommand)
