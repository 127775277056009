import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button'
import { NativeSelect } from '../../../component/select'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { capitalize } from '../../../lib'
import { Member } from '../../../service/model'

interface MemberRowProps {
  member: Member
}

const MemberRow: React.FC<MemberRowProps> = function ({ member }) {
  const styles = useStyles({})
  const { service, toast } = useAppStore()
  const user = service.user.current.asMember
  const members = service.member.orgMember.list
  const owners = members.filter((m) => m.isOwner)
  const roles = useMemo(
    () => service.organization.role.filter((role) => user.canManage(role.name)),
    [service.organization.role, user.role],
  )

  const isUserRow = member.id === user.id
  const isSingleOwner = isUserRow && user.isOwner && owners.length === 1
  const canManage = user.canManage(member.role)

  const handleRemove = (member: Member) => {
    member.delete().catch(toast.showError)
    toast.show({ message: `${member.email} has been removed.` })
  }

  return (
    <div className={styles.row}>
      <div className={styles.col1}>
        <div className={styles.user}>
          <AppAvatar size={40} identity={member} className={styles.picture} />
          <div>
            <Typography variant="body" color="textPrimary">
              {member.status === 'invited' ? member.email : member.name}
            </Typography>
            <Typography
              style={{ marginTop: 4 }}
              variant="caption1"
              color="textTertiary"
              fontWeight="regular"
            >
              {member.status === 'invited' ? 'Invite sent' : member.email}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.col2}>
        {canManage && roles.length > 1 ? (
          <Tooltip disabled={!isSingleOwner} title="Cannot change role of the only owner">
            <span className={styles.tooltipContainer}>
              <NativeSelect
                disabled={isSingleOwner}
                size={35}
                value={member.role}
                onChange={(e: any) =>
                  member.setRole(e.target.value).catch(toast.showError)
                }
              >
                {roles.map((role) => (
                  <option key={role.name} value={role.name}>
                    {role.displayName}
                  </option>
                ))}
              </NativeSelect>
            </span>
          </Tooltip>
        ) : (
          <Typography component="span" variant="body" color="textPrimary">
            {capitalize(member.role)}
          </Typography>
        )}
      </div>
      {roles.length > 1 ? (
        <div className={styles.col3}>
          <Tooltip
            disabled={!isSingleOwner && canManage}
            title={
              isSingleOwner
                ? 'Cannot remove the only owner'
                : "You don't have the necessary permissions to remove this user"
            }
          >
            <span className={styles.tooltipContainer}>
              <Button
                disabled={isSingleOwner || !canManage}
                size="small"
                variant="text"
                onClick={() => handleRemove(member)}
              >
                Remove
              </Button>
            </span>
          </Tooltip>
        </div>
      ) : null}
    </div>
  )
}

export default observer(MemberRow)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
    userSelect: 'text',
  },
  picture: {
    marginRight: '1rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
  },
  col1: {
    flex: 1,
  },
  col2: {
    flex: '0 0 150px',
    textAlign: 'right',
  },
  col3: {
    flex: '0 0 100px',
    textAlign: 'right',
  },
  tooltipContainer: {
    display: 'inline-block',
  },
}))
