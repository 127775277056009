import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { MessageMedia } from '../../service/model'

interface DocViewerProps {
  media: MessageMedia
}

const DocViewer: React.FC<DocViewerProps> = function ({ media }) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file) {
    return null
  }

  return (
    <div ref={rootRef} className={styles.root}>
      <iframe
        frameBorder={0}
        className={styles.iframe}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${media.url}`}
      />
    </div>
  )
}

export default observer(DocViewer)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: 'calc(100% - 175px)',
    height: '100%',
  },
}))
