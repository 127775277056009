import { AvatarSize, AvatarSizeNumericValues } from '../avatar'
import { ReactionSize } from '../../component/reactions'

export interface ItemDimensions {
  readonly actionsMenuMarginHorizontal: number
  readonly actionsMenuMarginVertical: number
  readonly bubbleFontSize: number
  readonly bubbleMarginHorizontal: number
  readonly bubbleMaxWidth: number
  readonly bubbleWrapperMargin: number
  readonly messagePaddingHorizontal: number
  readonly messagePaddingVertical: number
  readonly reactionsHeight: ReactionSize
  readonly reactionsMarginHorizontal: number
  readonly reactionsMarginBottom: number
  readonly reactionsMarginTop: number
  readonly rowPaddingVertical: number
  readonly senderNameHeight: number
  readonly senderNameMarginHorizontal: number
  readonly senderNameTop: number
  readonly statusHeight: number
  readonly statusMarginBottom: number
  readonly statusMarginHorizontal: number
  readonly statusMarginTop: number
  readonly threadArrowWidth: number
  readonly threadMarginHorizontal: number
  readonly threadMargin: { top: number; bottom: number }
  readonly threadWidth: number
  readonly timestampHeight: number
  readonly timestampMarginHorizontal: number
  readonly timestampPopoverHeight: number
  readonly avatarSize: AvatarSize
}

export const getDefaultItemDimensions = ({
  bubbleMaxWidth = 400,
  bubbleWrapperMargin = 5,
  rowFontSize = 14,
  avatarSize = 36,
  bubbleMarginHorizontal,
  ...restProps
}: Omit<Partial<ItemDimensions>, 'avatarSize'> & {
  rowFontSize?: number
  avatarSize?: AvatarSizeNumericValues
}): ItemDimensions => {
  const calculatedBubbleMarginHorizontal = bubbleMarginHorizontal ?? avatarSize + 8

  return {
    actionsMenuMarginHorizontal: 10,
    actionsMenuMarginVertical: -5,
    bubbleFontSize: 15,
    bubbleMarginHorizontal: calculatedBubbleMarginHorizontal,
    bubbleMaxWidth,
    bubbleWrapperMargin,
    messagePaddingHorizontal: 14,
    messagePaddingVertical: 8,
    reactionsHeight: 24,
    reactionsMarginHorizontal: calculatedBubbleMarginHorizontal + 9,
    reactionsMarginTop: -13,
    reactionsMarginBottom: 6,
    rowPaddingVertical: 1,
    senderNameHeight: rowFontSize,
    senderNameMarginHorizontal: calculatedBubbleMarginHorizontal + 14,
    senderNameTop: bubbleWrapperMargin + 13,
    statusHeight: rowFontSize,
    statusMarginBottom: 3,
    statusMarginHorizontal: calculatedBubbleMarginHorizontal + 11,
    statusMarginTop: -3,
    threadArrowWidth: 12,
    threadMarginHorizontal: calculatedBubbleMarginHorizontal + 22,
    threadMargin: { top: 3, bottom: 20 },
    threadWidth: bubbleMaxWidth,
    timestampHeight: 14,
    timestampMarginHorizontal: 10,
    timestampPopoverHeight: 20,
    avatarSize,
    ...restProps,
  }
}
