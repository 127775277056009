// @ts-strict-ignore
import { useCallback } from 'react'
import { isArrayOfStrings } from './collections'
import useKeyboardShortcuts, { FilterHandler } from './use-keyboard-shortcuts'
import Stepper from './stepper'
import useInstance from './use-instance'

export interface KeyStepperProps<T> {
  name: string
  node?: Element | Document
  items: readonly T[]
  deps?: any[]
  keys?: KeyMapping
  selectOnMouseMove?: boolean
  filter?: FilterHandler
  skip?: (item: T, index: number) => boolean
  handleSelect: (item: T, index: number) => void
  handleChecked?: (item: T, index: number) => void
  defaultSelectedIndex?: number
}

export interface KeyStepperResult {
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  getItemProps: (index: number) => {
    onMouseMove: () => void
    onClick: () => void
  }
}

export type Key = string | string[]

export interface KeyMapping {
  next?: Key
  prev?: Key
  start?: Key
  end?: Key
  select?: Key
  check?: Key
}

const NONE: KeyMapping = {}

const HORIZONTAL: KeyMapping = {
  next: 'ArrowRight',
  prev: 'ArrowLeft',
  start: 'Meta+ArrowLeft',
  end: 'Meta+ArrowRight',
  select: 'Enter',
  check: ['KeyX', 'Space'],
}

const VERTICAL: KeyMapping = {
  next: 'ArrowDown',
  prev: 'ArrowUp',
  start: 'Meta+ArrowUp',
  end: 'Meta+ArrowDown',
  select: 'Enter',
  check: ['KeyX', 'Space'],
}

export const KeyMappings = { NONE, HORIZONTAL, VERTICAL }

const createKeyMatcher = (shortcut: string) => (key?: Key) => {
  return isArrayOfStrings(key) ? key.includes(shortcut) : key === shortcut
}

export default function useKeyStepper<T>({
  name,
  node,
  items,
  deps = [],
  keys = KeyMappings.VERTICAL,
  skip,
  selectOnMouseMove = true,
  filter,
  handleSelect,
  handleChecked,
  defaultSelectedIndex = 0,
}: KeyStepperProps<T>): KeyStepperResult {
  const stepper = useInstance<Stepper<T>>(
    (previousInstance) =>
      new Stepper(
        { data: items },
        {
          name,
          skip,
          index: previousInstance?.index ?? defaultSelectedIndex,
        },
      ),
    [items],
  )

  useKeyboardShortcuts({
    name,
    node: node || document,
    filter,
    handler: (shortcut, event) => {
      const keyMatches = createKeyMatcher(shortcut)
      if (keyMatches(keys.prev)) {
        stepper.prev()
        event.preventDefault()
      } else if (keyMatches(keys.next)) {
        stepper.next()
        event.preventDefault()
      } else if (keyMatches(keys.start)) {
        stepper.start()
        event.preventDefault()
      } else if (keyMatches(keys.end)) {
        stepper.end()
        event.preventDefault()
      } else if (keyMatches(keys.select)) {
        handleSelect(stepper.item, stepper.index)
        event.preventDefault()
      } else if (keyMatches(keys.check) && handleChecked) {
        handleChecked(stepper.item, stepper.index)
        event.preventDefault()
      }
    },
    dep: [stepper, handleChecked, handleSelect, ...deps],
  })

  const getItemProps = useCallback(
    (index: number) => {
      return {
        onMouseMove: () => {
          if (selectOnMouseMove && !skip?.(stepper.items[index], index)) {
            stepper.selectIndex(index)
          }
        },
        onClick: () => {
          if (!skip?.(stepper.items[index], index)) {
            stepper.selectIndex(index)
            handleSelect(stepper.items[index], index)
          }
        },
      }
    },
    [stepper, handleSelect, ...deps],
  )

  return {
    selectedIndex: stepper.index,
    setSelectedIndex: stepper.selectIndex,
    getItemProps,
  }
}
