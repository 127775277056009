// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ProfileIcon } from '@src/component/icons/Tint/16/General'
import { observer } from 'mobx-react-lite'
import React, { useImperativeHandle, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import { ScrollView } from '../../component/scrollview'
import { NoSelectionMessage } from '@src/app/components'
import ContactPreview from '../contact'

interface ContactDetailsProps {}

export interface ContactDetailsMethods {
  flash: () => void
}

const ContactDetails: React.ForwardRefRenderFunction<
  ContactDetailsMethods,
  ContactDetailsProps
> = function ({}, ref) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)
  const { contacts } = useAppStore()

  useImperativeHandle(ref, () => ({
    flash: function focusWindow() {
      if (!rootRef.current) {
        setTimeout(focusWindow, 5)
        return
      }
      rootRef.current?.focus()
      rootRef.current?.classList.add(styles.flashBorder)
      setTimeout(() => {
        rootRef.current?.classList.remove(styles.flashBorder)
      }, 1000)
    },
  }))

  return (
    <div className={styles.root}>
      {contacts.contact ? (
        <ScrollView rootRef={rootRef}>
          <ContactPreview contact={contacts.contact} />
        </ScrollView>
      ) : (
        <div className={styles.noSelectionMessage}>
          <NoSelectionMessage icon={<ProfileIcon />} description="No contact selected" />
        </div>
      )}
    </div>
  )
}

export default observer(ContactDetails, { forwardRef: true })

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes flash': {
    '0%': { boxShadow: `inset 0px 0px 0px 2px transparent;` },
    '5%': { boxShadow: `inset 0px 0px 0px 2px ${theme.palette.op.primary[3]}` },
    '40%': { boxShadow: `inset 0px 0px 0px 2px ${theme.palette.op.primary[3]}` },
    '100%': { boxShadow: `inset 0px 0px 0px 2px transparent;` },
  },
  flashBorder: {
    animation: '$flash 750ms ease',
  },
  root: {
    flex: '0 0 400px',
    borderLeft: `1.5px solid ${theme.palette.op.border.common}`,
  },
  noSelectionMessage: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}))
