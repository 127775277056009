// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { Card } from '../common/card'
import { makeStyles } from '@material-ui/core'
import Button from '../../../component/button-v2'
import { useOnboardingState } from '..'
import { CircularProgress } from '../../../component/progress'
import { AppAvatar } from '@src/app/components'

const Workspace = () => {
  const { service, toast } = useAppStore()
  const styles = useStyles()
  const state = useOnboardingState()
  const [joining, setJoining] = useState(false)
  const [workspacesState, setWorkspacesState] = useState<'idle' | 'loading' | 'success'>(
    'idle',
  )

  useEffect(() => {
    if (workspacesState === 'idle') {
      setWorkspacesState('loading')
      service.workspace.fetch().then(() => setWorkspacesState('success'))
    }
  }, [workspacesState])

  const handleAddMember = (workspaceId: string) => {
    setJoining(true)
    const workspace = service.workspace.workspaces.find((w) => w.id == workspaceId)
    toast.showLoading(`Joining ${workspace.name}...`)
    state.joinWorkspace(workspaceId).finally(() => setJoining(false))
  }

  const handleNewWorkspace = () => {
    state.advance('next')
  }

  if (workspacesState === 'success') {
    if (service.workspace.workspaces.length === 0) {
      // move to the next step since there is no organization to join
      state.advance('next')
    } else if (
      !!service.workspace.workspaces.find(
        (org) => org.id === service.organization.current.id,
      )
    ) {
      // skip the step since user already joined organization
      state.advance('done')
    }
  }

  return (
    <div>
      {workspacesState === 'loading' || workspacesState === 'idle' ? (
        <CircularProgress className={styles.progress} />
      ) : (
        <Card
          emoji="👋"
          title="Your team is already here"
          description="Based on your email address, you already have access to the following workspaces. You can choose to join, or create your own."
          className={styles.card}
          contentClassName={styles.cardContent}
        >
          <>
            {service.workspace.workspaces.map((workspace) => (
              <div className={styles.rowRoot} key={workspace.id}>
                <div className={styles.rowLeftColumn}>
                  <AppAvatar
                    identity={{ pictureUrl: workspace.pictureUrl }}
                    size={40}
                    className={styles.avatar}
                  />
                  <div>
                    <Typography variant="headline" color="textPrimary">
                      {workspace.name}
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                      {`${workspace.memberCount} member` +
                        `${workspace.memberCount > 1 ? 's' : ''}`}
                    </Typography>
                  </div>
                </div>
                <Button
                  variant="contained"
                  onClick={() => handleAddMember(workspace.id)}
                  disabled={joining}
                >
                  Join
                </Button>
              </div>
            ))}
            <Button
              variant="outlined"
              className={styles.newWorkspaceBtn}
              onClick={handleNewWorkspace}
            >
              Create new workspace
            </Button>
          </>
        </Card>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: 432,
  },
  progress: {
    position: 'absolute',
    top: '50%',
  },
  cardContent: {
    width: '100%',
  },
  rowRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 96,
    borderRadius: 10,
    marginTop: 16,
    padding: 24,
    background: theme.palette.op.gray[6],
    border: `1.5px solid ${theme.palette.op.border.focus}`,
  },
  rowLeftColumn: {
    display: 'flex',
  },
  newWorkspaceBtn: {
    width: '100%',
    marginTop: 24,
  },
  avatar: {
    marginRight: 15,
  },
}))

export default observer(Workspace)
