// @ts-strict-ignore
import fuzzysort from 'fuzzysort'
import { EntityPhoneNumber, Member, OpenPhoneNumber, PhoneNumber } from '../service/model'
import { TemplateTagItemOption } from '../types'

export interface SearchOptions {
  limit?: number
}

export function fuzzySearch<T>(
  collection: readonly T[],
  query: string,
  fields: (keyof T)[],
  options: SearchOptions = {},
): T[] {
  const result = fuzzysort.go(query, collection, {
    limit: 10,
    keys: fields as any,
    threshold: -10000,
    ...options,
  })
  return result.map((r) => r.obj)
}

export async function searchMembers(query: string, members: Member[]): Promise<Member[]> {
  const result = await fuzzysort.goAsync(query, members, {
    limit: 10,
    keys: ['firstName', 'lastName', 'email'],
    threshold: -10000,
  })
  return result.map((r) => r.obj)
}

export async function searchPhoneNumbers<T extends EntityPhoneNumber | PhoneNumber>(
  query: string,
  phoneNumbers: T[],
): Promise<T[]> {
  const pns = phoneNumbers.map((p) => ({
    id: p.id,
    name: p.name,
    number: p.number,
    unames: p.members.map((m) => m.name).join(' '),
  }))
  const result = await fuzzysort.goAsync(query, pns, {
    limit: 10,
    keys: ['name', 'number', 'unames'],
    threshold: -10000,
  })
  return result.map((r) => phoneNumbers.find((p) => p.id === r.obj.id))
}

export async function searchTags(
  query: string,
  options: TemplateTagItemOption[],
): Promise<TemplateTagItemOption[]> {
  if (!query) return options
  const result = await fuzzysort.goAsync(query, options, {
    limit: 10,
    keys: ['name'],
    threshold: -10000,
  })
  return result.map((r) => r.obj)
}
