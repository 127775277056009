import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { Menu } from '../menu-v2'
import Typography from '@ui/Typography'
import KeyboardList from '../keyboard-list'
import Controller, { PhoneNumberRow } from './controller'
import PhoneNumberSelectorRow from './row'
import SearchBar from './search-bar'

export { Controller }

export interface PhoneNumberSelectorProps {
  controller: Controller
}

const PhoneNumberSelector: React.FC<PhoneNumberSelectorProps> = ({ controller }) => {
  const styles = useStyles({})

  const itemContent = useCallback(
    (row: PhoneNumberRow, index: number) => (
      <PhoneNumberSelectorRow
        key={row.id}
        controller={controller}
        row={row}
        index={index}
      />
    ),
    [controller],
  )

  useKeyboardShortcuts({
    name: 'PhoneNumberSelector',
    node: controller.searchFocused ? controller.searchRef.current : document,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape' && typeof controller.menuIndex === 'number') {
        controller.closeMenu()
        event.preventDefault()
      }
    },
    dep: [controller],
  })

  return (
    <div className={styles.root}>
      <SearchBar controller={controller} />
      <Menu className={styles.menu}>
        {controller.hasResults ? (
          <KeyboardList
            name="phone-number-selector"
            node={controller.searchFocused ? controller.searchRef.current : document}
            controller={controller.keyboardList}
            renderItem={itemContent}
            className={styles.list}
          />
        ) : (
          <div className={styles.empty}>
            <span className={styles.emoji}>📞</span>
            <Typography variant="caption1" color="textSecondary" fontWeight="regular">
              No results found
            </Typography>
          </div>
        )}
      </Menu>
    </div>
  )
}

export default observer(PhoneNumberSelector)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    menu: {
      display: 'flex',
      flex: 1,
      overflowY: 'hidden',
    },
    list: {
      display: 'flex',
      flex: 1,
    },
    empty: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignSelf: 'center',
      textAlign: 'center',
    },
    number: {
      fontWeight: 550,
    },
    emoji: {
      marginBottom: 4,
      fontSize: 24,
    },
  }),
  { name: PhoneNumberSelector.name },
)
