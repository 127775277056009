// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { Command, Footer } from '../common-v2'
import Button from '@src/component/button-v2'
import Typography from '@ui/Typography'
import { useAppStore } from '@src/app/context'
import { ArrowBackIcon } from '@src/component/icons/Tint/20/General'
import { formatDate } from '@src/lib'
import { getStatusBackgroundColor, getStatusCodeColor, HttpStatusCodes } from '@src/data'
import { fonts } from '@src/theme'
import Code from '@src/component/code'
import { useOnCommandClose } from '@src/lib/use-on-command-close'
export interface TestRequestDetailsCommandProps {
  request: {
    name: string
    statusCode: number | string
    date: string
    payload: string
    response: string
    webhookId: string
  }
  isTestRequest?: boolean
}

const TestRequestDetailsCommand = ({
  request: { name, statusCode, date, webhookId, payload, response },
  isTestRequest = false,
}: TestRequestDetailsCommandProps) => {
  const styles = useStyles({
    statusCode: typeof statusCode === 'number' ? statusCode : undefined,
    webhookId,
  })
  const { command, history } = useAppStore()

  useOnCommandClose(() => {
    if (!isTestRequest) {
      history.push(`/settings/webhooks/${webhookId}`)
    }
  }, [history, webhookId])

  return (
    <Command width={672}>
      <div className={styles.root}>
        <header className={styles.header}>
          <div className={styles.headerMetadata}>
            <Typography variant="body" className={styles.status}>
              {typeof statusCode === 'number'
                ? `${statusCode} ${HttpStatusCodes[statusCode]}`
                : statusCode}
            </Typography>
            <Typography variant="body" className={styles.datetime}>
              <time>{formatDate(date, 'MMM D, YYYY')}</time>&nbsp;
              <time className={styles.time}>{formatDate(date, 'HH:mm')}</time>
            </Typography>
          </div>
          <div className={styles.headerContent}>
            <Typography variant="title3" className={styles.headline}>
              {name}
            </Typography>
            {isTestRequest && (
              <Typography variant="body" color="textSecondary">
                Test request was successfully sent.
              </Typography>
            )}
          </div>
        </header>
        <div className={styles.content}>
          {response ? (
            <>
              <Typography variant="footnote" className={styles.codeHeadline}>
                Response
              </Typography>
              <Code content={response} />
            </>
          ) : null}
          {payload ? (
            <>
              <Typography variant="footnote" className={styles.codeHeadline}>
                Request
              </Typography>
              <Code content={payload} />
            </>
          ) : null}
        </div>
      </div>
      <Footer>
        <div className={styles.footer}>
          {isTestRequest ? (
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              className={styles.backButton}
              onClick={() =>
                command.present({ name: 'send test request', id: webhookId })
              }
            >
              Try different requests
            </Button>
          ) : (
            <div />
          )}

          <Button onClick={() => command.hide()}>
            {isTestRequest ? 'Done' : 'Close'}
          </Button>
        </div>
      </Footer>
    </Command>
  )
}

const useStyles = makeStyles<Theme, { statusCode?: number; webhookId?: string }>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      padding: 24,
      minHeight: 0,
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,
    },
    headerMetadata: {
      display: 'flex',
      columnGap: 12,
      alignItems: 'center',
    },
    status: ({ statusCode }) => ({
      color: getStatusCodeColor(theme, statusCode),
      background: getStatusBackgroundColor(theme, statusCode),
      borderRadius: 5,
      padding: '5px 7px',
      fontFamily: fonts.jetBrainsMono,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '14px',
      display: 'flex',
      alignItems: 'center',
      columnGap: 6,

      '&:before': {
        content: '""',
        width: 4,
        height: 4,
        background: 'currentColor',
        display: 'block',
        borderRadius: '50%',
      },
    }),
    datetime: {
      color: theme.palette.op.gray[2],
      fontSize: 12,
    },
    time: {
      color: theme.palette.op.gray[3],
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 6,
    },
    headline: { fontFamily: fonts.jetBrainsMono },
    content: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    codeHeadline: {
      color: theme.palette.op.gray[3],
      marginBottom: 10,
      '& + pre:not(:last-child)': {
        marginBottom: 20,
      },
    },
    footer: ({ webhookId }) => ({
      display: 'flex',
      justifyContent: webhookId ? 'space-between' : 'flex-end',
    }),
    backButton: {
      transform: 'translateX(-14px)',
    },
    progress: {
      color: theme.palette.op.gray[4],
      marginRight: 10,
    },
  }),
  { name: TestRequestDetailsCommand.name },
)

export default TestRequestDetailsCommand
