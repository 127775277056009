import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { OpenPhoneNumber } from '../../service/model'
import { Command } from './common-v2'
import Header from './common/header'
import OpPhoneNumberSelector from './op-phone-number-selector'

export interface PickUserPhoneNumberProps {
  title: string
  subtitle?: string
  multi?: boolean
  excludeIds?: string[]
  initialSelectedIds?: string[]
  onSelect: (phoneNumbers: OpenPhoneNumber[]) => void
  onAdd: () => void
}

const PickUserPhoneNumber: React.FC<PickUserPhoneNumberProps> = function ({
  title,
  subtitle,
  multi,
  initialSelectedIds,
  excludeIds,
  onSelect,
  onAdd,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const phoneNumbers = service.user.phoneNumbers.list

  return (
    <Command>
      <Header title={title} subtitle={subtitle} />
      <OpPhoneNumberSelector
        multi={multi}
        phoneNumbers={phoneNumbers.filter((p) => !excludeIds?.includes(p.id))}
        initialSelectedIds={initialSelectedIds}
        onAdd={onAdd}
        onSelect={onSelect}
      />
    </Command>
  )
}

export default PickUserPhoneNumber

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
