// @ts-strict-ignore
import { useRef, useState, useEffect } from 'react'
import { DateUtils, DayPickerProps, RangeModifier } from 'react-day-picker'
import { dayjs, friendlyDate, sameDay } from '../../lib/date'
import { ReportGranularity } from '../../types'
import { makeStyles } from '@material-ui/core'
import { colors } from '@src/theme'
import { ExportType } from '@src/service/transport/report'

export type DateRange =
  | 'today'
  | 'yesterday'
  | 'last-7-days'
  | 'last-4-weeks'
  | 'last-3-months'
  | 'last-12-months'
  | 'month-to-date'
  | 'quarter-to-date'
  | 'year-to-date'
  | RangeModifier

interface UseDateFilterParams {
  range: RangeModifier
  onChange: (value: { range: RangeModifier; granularity: ReportGranularity }) => void
}

export function useDateFilter({ range, onChange }: UseDateFilterParams) {
  const [editingRange, setEditingRange] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  })
  const datePickerButtonRef = useRef<HTMLButtonElement>(null)
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [datePickerEl, setDatePickerEl] = useState<HTMLButtonElement>(null)
  const dayPickerStyles = useDayPickerStyles()

  useEffect(() => {
    setEditingRange({ from: undefined, to: undefined })
  }, [range])

  const handleDatePicker = function () {
    setShowDateRangePicker(false)
    setDatePickerEl(datePickerButtonRef.current)
  }

  const handleGranularityChange = function (_, newValue: ReportGranularity) {
    if (newValue) {
      onChange({ range: editingRange, granularity: newValue })
    }
  }

  const handleDayClick = function (day, modifiers: any = {}) {
    if (modifiers[dayPickerStyles.disabled]) {
      return
    }

    const newRange = DateUtils.addDayToRange(day, editingRange)
    if (newRange.to) {
      newRange.from = dayjs(newRange.from).startOf('day').toDate()
      newRange.to = dayjs(newRange.to).endOf('day').toDate()
    }
    setEditingRange(newRange)

    if (newRange.from && newRange.to) {
      doChangeRange(newRange)
      setTimeout(() => {
        handleDateRangePickerClose()
      }, 200)
    }
  }

  const handlePickCustomRange = function () {
    setShowDateRangePicker(true)
  }

  const doChangeRange = function (range: RangeModifier) {
    let granularity: ReportGranularity = 'day'

    const diff = (range.to as any) - (range.from as any)
    if (diff > 7 * 10 * 3600 * 1000) {
      granularity = 'week'
    }

    onChange({ range, granularity })
  }

  const handleDateRangeChange = function (value: DateRange) {
    handleDateRangePickerClose()
    switch (value) {
      case 'today':
        return doChangeRange({
          from: dayjs().startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'yesterday':
        return doChangeRange({
          from: dayjs().subtract(1, 'day').startOf('day').toDate(),
          to: dayjs().subtract(1, 'day').endOf('day').toDate(),
        })
      case 'last-7-days':
        return doChangeRange({
          from: dayjs().subtract(7, 'day').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'last-4-weeks':
        return doChangeRange({
          from: dayjs().subtract(4, 'week').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'last-3-months':
        return doChangeRange({
          from: dayjs().subtract(3, 'month').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'last-12-months':
        return doChangeRange({
          from: dayjs().subtract(12, 'month').startOf('day').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'month-to-date':
        return doChangeRange({
          from: dayjs().startOf('month').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'quarter-to-date':
        return doChangeRange({
          from: dayjs().startOf('quarter').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
      case 'year-to-date':
        return doChangeRange({
          from: dayjs().startOf('year').toDate(),
          to: dayjs().endOf('day').toDate(),
        })
    }
  }

  const handleDateRangePickerClose = function () {
    setDatePickerEl(null)
  }

  const dayPickerClassNames: DayPickerProps['classNames'] = {
    container: dayPickerStyles.container,
    wrapper: dayPickerStyles.wrapper,
    interactionDisabled: dayPickerStyles.interactionDisabled,

    navBar: dayPickerStyles.navBar,
    navButtonPrev: `${dayPickerStyles.navButton} ${dayPickerStyles.navButtonPrev}`,
    navButtonNext: `${dayPickerStyles.navButton} ${dayPickerStyles.navButtonNext}`,
    navButtonInteractionDisabled: dayPickerStyles.navButtonInteractionDisabled,

    months: dayPickerStyles.months,
    month: dayPickerStyles.month,
    caption: dayPickerStyles.caption,
    weekdays: dayPickerStyles.weekdays,
    weekdaysRow: dayPickerStyles.weekdaysRow,
    weekday: dayPickerStyles.weekday,
    weekNumber: dayPickerStyles.weekNumber,
    body: dayPickerStyles.body,
    week: dayPickerStyles.week,
    day: dayPickerStyles.day,

    footer: dayPickerStyles.footer,
    todayButton: dayPickerStyles.todayButton,

    /* default modifiers */
    today: dayPickerStyles.today,
    selected: dayPickerStyles.selected,
    disabled: dayPickerStyles.disabled,
    outside: dayPickerStyles.outside,
  }

  return {
    datePickerEl,
    datePickerButtonRef,
    handleDatePicker,
    handleGranularityChange,
    handleDayClick,
    handlePickCustomRange,
    handleDateRangeChange,
    handleDateRangePickerClose,
    showDateRangePicker,
    editingRange,
    dayPickerStyles,
    dayPickerClassNames,
  }
}

interface UseHandlePopoverOptionParams {
  handlePickCustomRange: () => void
  handleDateRangeChange: (value: DateRange) => void
  onOption: (option: ExportType) => void
}

export function useHandlePopoverOption({
  handlePickCustomRange,
  handleDateRangeChange,
  onOption,
}: UseHandlePopoverOptionParams) {
  const handlePopoverOption = (option: ExportType) => {
    if (option === 'custom') {
      handlePickCustomRange()
    } else {
      handleDateRangeChange(option)
    }

    onOption(option)
  }

  return {
    handlePopoverOption,
  }
}

export function dateRangeToString(range: RangeModifier) {
  if (!sameDay(range.to, new Date())) {
    return sameDay(range.to, range.from)
      ? `${friendlyDate(range.from)}`
      : `${friendlyDate(range.from)} to ${friendlyDate(range.to)}`
  }
  if (sameDay(range.from, new Date())) {
    return 'Today'
  } else if (sameDay(range.from, dayjs().subtract(7, 'day'))) {
    return 'Last 7 days'
  } else if (sameDay(range.from, dayjs().subtract(4, 'week'))) {
    return 'Last 4 weeks'
  } else if (sameDay(range.from, dayjs().subtract(3, 'month'))) {
    return 'Last 3 months'
  } else if (sameDay(range.from, dayjs().subtract(12, 'month'))) {
    return 'Last 12 months'
  } else if (sameDay(range.from, dayjs().startOf('month'))) {
    return 'Month to date'
  } else if (sameDay(range.from, dayjs().startOf('quarter'))) {
    return 'Quarter to date'
  } else if (sameDay(range.from, dayjs().startOf('year'))) {
    return 'year to date'
  } else {
    return `${friendlyDate(range.from)} to ${friendlyDate(range.to)}`
  }
}

export const useDayPickerStyles = makeStyles((theme) => ({
  container: {
    width: '500px',
    display: 'inline-block',
    fontSize: '0.8rem',
    color: theme.palette.text.primary,
  },
  wrapper: {
    outline: 'none',
    position: 'relative',
    flexDirection: 'row',
    paddingBottom: '1em',
    userSelect: 'none',
  },
  interactionDisabled: {
    cursor: 'default',

    '& $day': {
      cursor: 'default',
    },
  },
  navBar: {},
  navButton: {
    position: 'absolute',
    top: '1em',
    display: 'inline-block',
    marginTop: '2px',
    width: '1.25em',
    height: '1.25em',
    backgroundPosition: 'center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    cursor: 'pointer',
    outline: 'none',
  },
  navButtonPrev: {
    left: '1.5em',
    right: 'auto',
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC")',
  },
  navButtonNext: {
    right: '1.5em',
    left: 'auto',
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==")',
  },
  navButtonInteractionDisabled: {
    display: 'none',
  },
  months: {
    display: 'flex',
    flexDirection: 'row',
  },
  month: {
    display: 'table',
    width: '100%',
    margin: '0 1em',
    marginTop: '1em',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    userSelect: 'none',
  },
  caption: {
    display: 'table-caption',
    marginBottom: '0.5em',
    padding: '0 0.5em',
    textAlign: 'center',

    '& > div': {
      fontWeight: 500,
      fontSize: '1.15em',
    },
  },
  weekdays: {
    display: 'table-header-group',
    marginTop: '1em',
  },
  weekdaysRow: {
    display: 'table-row',
  },
  weekday: {
    display: 'table-cell',
    padding: '0.5em 0.5em 1em',
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    textAlign: 'center',
    fontSize: '0.875em',

    '& abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none',
    },
  },
  weekNumber: {
    display: 'table-cell',
    padding: '0.5em',
    minWidth: '1em',
    borderRight: `1.5px solid ${theme.palette.op.legacy.dateEditor.weekNumberBorder}`,
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    verticalAlign: 'middle',
    textAlign: 'right',
    fontSize: '0.75em',
    cursor: 'pointer',
  },
  body: {
    display: 'table-row-group',
  },
  week: {
    display: 'table-row',
  },
  day: {
    display: 'table-cell',
    padding: '4px 5px',
    borderRadius: '5px',
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',

    '&:hover': {
      backgroundColor: theme.palette.op.background.highlight(),
    },
  },
  footer: {
    paddingTop: '0.5em',
  },
  todayButton: {
    border: 'none',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    boxShadow: 'none',
    color: theme.palette.op.legacy.dateEditor.todayButton,
    fontSize: '0.875em',
    cursor: 'pointer',
  },
  today: {
    color: colors.secondaryRed,
    fontWeight: 700,
  },
  selected: {
    color: 'white',
    borderRadius: 0,
    backgroundColor: theme.palette.op.background.highlight(0.15),

    '&:hover': {
      backgroundColor: theme.palette.op.background.highlight(0.3),
    },
  },
  disabled: {
    color: theme.palette.op.background.highlight(0.2),
    backgroundColor: 'transparent !important',
    cursor: 'default',

    '&:hover': {
      background: 'transparent',
    },
  },
  outside: {
    color: theme.palette.text.hint,
    cursor: 'default',
    background: 'transparent !important',

    '&:hover': {
      background: 'transparent',
    },
  },
  start: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: theme.palette.op.background.highlight(0.3),
  },
  end: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: theme.palette.op.background.highlight(0.3),
  },
}))

const typeMap: Record<ExportType, string> = {
  custom: 'Custom',
  'last-12-months': 'Last 12 months',
  'last-3-months': 'Last 3 months',
  'last-4-weeks': 'Last 4 weeks',
  'last-7-days': 'Last 7 days',
  'month-to-date': 'Month to date',
  'quarter-to-date': 'Quarter to date',
  'year-to-date': 'Year to date',
  today: 'Today',
  yesterday: 'Yesterday',
}

export function getExportTypeLabel(exportType?: ExportType) {
  const typeText = typeMap[exportType] ?? 'Custom'

  return typeText
}

export function getDatePickerLabel(range: RangeModifier, exportType?: ExportType) {
  exportType = exportType ?? 'custom'

  if (exportType === 'custom') {
    return dateRangeToString(range)
  }

  return getExportTypeLabel(exportType)
}
