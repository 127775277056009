import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

export interface VerticalSortableItemProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string
  children: React.ReactNode
}

export function VerticalSortableItem({ id, children }: VerticalSortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
