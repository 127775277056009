// @ts-strict-ignore
import dayjs from 'dayjs'
import { RangeModifier } from 'react-day-picker'
import Transport from '.'
import config from '../../config'
import { ReportGranularity } from '../../types'
import { HttpTransaction } from './transaction'

export default class ReportClient {
  constructor(private transport: Transport) {}

  users(
    phoneNumberId: string[],
    range: RangeModifier,
    granularity: ReportGranularity = 'day',
  ) {
    range = this.coerceRange(range)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: config.REPORT_SERVICE_URL,
        body: {
          body: {
            query: {
              bool: {
                filter: [
                  {
                    range: {
                      createdAt: {
                        time_zone: timezone,
                        gte: range.from,
                        lte: range.to,
                      },
                    },
                  },
                  {
                    terms: {
                      phoneNumberId,
                    },
                  },
                ],
              },
            },
            aggs: {
              uniquePeople: {
                date_histogram: {
                  field: 'createdAt',
                  calendar_interval: granularity,
                  min_doc_count: 0,
                  time_zone: timezone,
                  extended_bounds: {
                    min: range.from.getTime(),
                    max: range.to.getTime(),
                  },
                },
                aggs: {
                  unique: {
                    cardinality: { field: 'phoneNumber' },
                  },
                },
              },
              incoming: {
                filter: {
                  term: { direction: 'incoming' },
                },
                aggs: {
                  statuses: {
                    terms: { field: 'status' },
                    aggs: {
                      histogram: {
                        date_histogram: {
                          field: 'createdAt',
                          calendar_interval: granularity,
                          min_doc_count: 0,
                          time_zone: timezone,
                          extended_bounds: {
                            min: range.from.getTime(),
                            max: range.to.getTime(),
                          },
                        },
                      },
                    },
                  },
                },
              },
              voicemails: {
                filter: {
                  term: { type: 'voicemail' },
                },
                aggs: {
                  histogram: {
                    date_histogram: {
                      field: 'createdAt',
                      calendar_interval: granularity,
                      min_doc_count: 0,
                      time_zone: timezone,
                      extended_bounds: {
                        min: range.from.getTime(),
                        max: range.to.getTime(),
                      },
                    },
                  },
                },
              },
              activitiesByDay: {
                terms: {
                  script: {
                    source: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getDayOfWeek()`,
                    lang: 'painless',
                  },
                },
                aggs: {
                  hours: {
                    histogram: {
                      script: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getHour()`,
                      interval: 1,
                      min_doc_count: 0,
                      extended_bounds: {
                        min: 0,
                        max: 23,
                      },
                    },
                  },
                },
              },
              belongsTo: {
                filter: {
                  exists: {
                    field: 'belongsTo',
                  },
                },
                aggs: {
                  activities: {
                    terms: {
                      field: 'type',
                    },
                    aggs: {
                      direction: {
                        terms: {
                          field: 'direction',
                        },
                        aggs: {
                          histogram: {
                            date_histogram: {
                              field: 'createdAt',
                              time_zone: timezone,
                              calendar_interval: granularity,
                              min_doc_count: 0,
                              extended_bounds: {
                                min: range.from.getTime(),
                                max: range.to.getTime(),
                              },
                            },
                          },
                        },
                      },
                      duration: {
                        date_histogram: {
                          field: 'createdAt',
                          time_zone: timezone,
                          calendar_interval: granularity,
                          min_doc_count: 0,
                          extended_bounds: {
                            min: range.from.getTime(),
                            max: range.to.getTime(),
                          },
                        },
                        aggs: {
                          duration: {
                            sum: {
                              field: 'duration',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              users: {
                terms: {
                  field: 'belongsTo',
                },
                aggs: {
                  histogram: {
                    date_histogram: {
                      field: 'createdAt',
                      time_zone: timezone,
                      calendar_interval: granularity,
                      min_doc_count: 0,
                      extended_bounds: {
                        min: range.from.getTime(),
                        max: range.to.getTime(),
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }),
    )
  }

  export = {
    list: () => {
      return this.transport.queue<ListExportsResponse>(
        new HttpTransaction({
          method: 'get',
          url: `${config.REPORT_SERVICE_URL}exports`,
        }),
      )
    },

    queue: (phoneNumberIds: string[], range: RangeModifier, exportType?: ExportType) => {
      range = this.coerceRange(range)
      exportType = exportType || 'custom'

      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.REPORT_SERVICE_URL}export`,
          body: {
            from: range.from.toISOString(),
            to: range.to.toISOString(),
            phoneNumberIds,
            toDownloadLocation: true,
            exportType,
          },
        }),
      )
    },

    clearUserExports: () => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.REPORT_SERVICE_URL}exports/user`,
        }),
      )
    },

    exportEstimate: (phoneNumberIds: string[], range: RangeModifier) => {
      range = this.coerceRange(range)
      return this.transport.queue<ExportEstimate>(
        new HttpTransaction({
          method: 'post',
          url: `${config.REPORT_SERVICE_URL}export-estimate`,
          body: {
            from: range.from.toISOString(),
            to: range.to.toISOString(),
            phoneNumberIds,
          },
        }),
      )
    },
  }

  protected coerceRange(range: RangeModifier): RangeModifier {
    return range.from && range.to
      ? range
      : {
          from: dayjs().startOf('month').toDate(),
          to: new Date(),
        }
  }
}

interface BaseExport {
  id: string
  orgId: string
  userId: string
  query: {
    to: string
    from: string
    phoneNumberIds: string[]
    toDownloadLocation: boolean
    exportType?: ExportType
  }
  filename: string
  requestedAt: string
  createdAt: string
  updatedAt: string
}

interface PendingExport extends BaseExport {
  status: 'pending'
}

interface ProcessingExport extends BaseExport {
  status: 'processing'
}

interface CompletedExport extends BaseExport {
  status: 'completed'
  url: string
}

interface FailedExport extends BaseExport {
  status: 'failed'
  errorMessage: string
}

export type ReportExport =
  | PendingExport
  | ProcessingExport
  | CompletedExport
  | FailedExport

export type ListExportsResponse = ReportExport[]

export interface ExportEstimate {
  records: number
  totalPages: number
}

export type ExportType =
  | 'today'
  | 'yesterday'
  | 'last-7-days'
  | 'last-4-weeks'
  | 'last-3-months'
  | 'last-12-months'
  | 'month-to-date'
  | 'quarter-to-date'
  | 'year-to-date'
  | 'custom'
