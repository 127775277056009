import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppStore } from '@src/app/context'
import { Content, EmptyListMessage } from '../common-v2'
import { observer } from 'mobx-react-lite'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import { ResultRow } from './row'
import useKeyStepper from '../../../lib/use-key-stepper'
import { action } from 'mobx'

interface SearchResultsProps {}

const SearchResults: React.FC<SearchResultsProps> = function ({}) {
  const styles = useStyles({})
  const { search, command } = useAppStore()

  useKeyboardShortcuts({
    node: document,
    name: 'SearchResults',
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        search.clearResults()
        event.preventDefault()
      }
    },
  })

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: search.results,
    defaultSelectedIndex: search.selectedResultIndex ?? 0,
    name: 'command/search/results',
    handleSelect: (item) => {
      if (!item) {
        search.runSearch()
        return
      }
      search.openResult(item)
      command.hide()
    },
  })

  useEffect(
    action(() => {
      search.selectedResultIndex = selectedIndex
    }),
    [selectedIndex],
  )

  return (
    <Content>
      {search.results.length === 0 ? (
        <EmptyListMessage text="No results found matching your search query." />
      ) : (
        search.results.map((result, index) => (
          <ResultRow
            key={result._id}
            index={index}
            selected={selectedIndex === index}
            getItemProps={getItemProps}
            result={result}
          />
        ))
      )}
    </Content>
  )
}

export default observer(SearchResults)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
