// @ts-strict-ignore
import Debug from 'debug'
import { computed, makeObservable, observable } from 'mobx'
import shortId from '../../lib/short-id'

export interface Brick {
  ratio: number
}

export interface Layout {
  bricks: BrickLayout[]
  grid: {
    width: number
    height: number
  }
}

export interface BrickLayout {
  x: number
  y: number
  width: number
  height: number
}

export default class MasonryStore<T extends Brick> {
  gridWidth: number = null

  readonly id = shortId()
  readonly debug = Debug(`op:masonry:@${this.id}`)

  constructor(readonly bricks: readonly T[], readonly columns: number) {
    makeObservable(this, {
      gridWidth: observable,
      columnWidth: computed,
      layout: computed,
    })
  }

  get columnWidth(): number {
    return this.gridWidth / this.columns
  }

  get layout(): Layout {
    this.debug(
      'calculating layout (bricks: %O) (gridWidth: %O) (columnWidth: %O)',
      this.bricks,
      this.gridWidth,
      this.columnWidth,
    )

    const columnOffsets = Array(this.columns).fill(0)
    const width = this.columnWidth

    const bricks = this.bricks.map((brick, index) => {
      const height = width / brick.ratio
      const y = Math.min(...columnOffsets)
      const columnIndex = columnOffsets.findIndex((v) => y === v)
      const x = width * columnIndex
      columnOffsets[columnIndex] += height

      this.debug(
        'brick layout (index: %O) (x: %O) (y: %O) (width: %O) (height: %O)',
        index,
        x,
        y,
        width,
        height,
      )

      return {
        x,
        y,
        width,
        height,
      }
    })

    return {
      bricks,
      grid: {
        width: this.gridWidth,
        height: Math.max(...columnOffsets),
      },
    }
  }
}
