// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { DragIndicatorIcon } from '../../component/icons/Tint/Misc'
import IncomingCall from './incoming-call'
import OngoingCall from './ongoing-call'

interface CallsModalProps {}

const CallsModal: React.FC<CallsModalProps> = function ({}) {
  const styles = useStyles({})
  const { electron, voice, conversation } = useAppStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const draggableRef = useRef<HTMLDivElement>(null)
  const [focused, setFocused] = useState(false)

  const calls = voice.call.list
  const ongoingCalls = calls.filter(
    (c) =>
      c.status === 'connecting' || c.status === 'connected' || c.status === 'ringing',
  )
  const incomingCalls = calls.filter((c) => c.status === 'incoming')

  useEffect(() => {
    electron?.app.askForMediaAccess('microphone')

    let active = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    draggableRef.current.addEventListener('touchstart', dragStart, { passive: true })
    draggableRef.current.addEventListener('mousedown', dragStart, { passive: true })
    draggableRef.current.addEventListener('touchend', dragEnd, { passive: true })
    draggableRef.current.addEventListener('mouseup', dragEnd, { passive: true })
    document.addEventListener('touchmove', drag, { passive: true })
    document.addEventListener('mousemove', drag, { passive: true })

    function dragStart(e) {
      if (e.type === 'touchstart') {
        initialX = e.touches[0].clientX - xOffset
        initialY = e.touches[0].clientY - yOffset
      } else {
        initialX = e.clientX - xOffset
        initialY = e.clientY - yOffset
      }
      active = true
    }

    function dragEnd(e) {
      initialX = currentX
      initialY = currentY
      active = false
    }

    function drag(e) {
      if (active) {
        if (e.type === 'touchmove') {
          currentX = e.touches[0].clientX - initialX
          currentY = e.touches[0].clientY - initialY
        } else {
          currentX = e.clientX - initialX
          currentY = e.clientY - initialY
        }

        xOffset = currentX
        yOffset = currentY

        setTranslate(currentX, currentY, rootRef.current)
      }
    }

    function setTranslate(xPos, yPos, el) {
      el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`
    }

    return () => {
      draggableRef.current?.removeEventListener('touchstart', dragStart)
      draggableRef.current?.removeEventListener('mouseup', dragEnd)
      draggableRef.current?.removeEventListener('touchend', dragEnd)
      draggableRef.current?.removeEventListener('mousedown', dragStart)
      document.removeEventListener('touchmove', drag)
      document.removeEventListener('mousemove', drag)
    }
  }, [])

  useLayoutEffect(() => {
    if (conversation.current && conversation.inputBarController?.focused) return
    rootRef.current.focus()
  }, [conversation])

  return (
    <div
      ref={rootRef}
      className={cx(styles.root, focused && styles.focused)}
      tabIndex={-1}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <div ref={draggableRef} className={styles.draggable}>
        <div className={styles.draggableIcon}>
          <DragIndicatorIcon />
          <DragIndicatorIcon />
        </div>
      </div>
      {ongoingCalls.map((call, index) => (
        <OngoingCall key={index} call={call} rootRef={rootRef} />
      ))}
      {incomingCalls.map((call, index) => (
        <IncomingCall key={index} call={call} />
      ))}
    </div>
  )
}

export default observer(CallsModal)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    left: 'auto',
    zIndex: 1300,
    width: 320,
    borderRadius: 12,
    boxShadow: `0 0 0 1.5px ${theme.palette.op.border.common}, 0 7px 52px 0 ${alpha(
      theme.palette.op.black,
      0.6,
    )}`,
    animation: '$slide 300ms ease',
    background: theme.palette.op.background.popover,
    overflow: 'hidden',

    '&:focus': {
      outline: 'none',
    },
  },
  focused: {
    boxShadow: `0 0 0 3px ${theme.palette.primary.light}`,
  },
  draggable: {
    color: theme.palette.op.background.highlight(0.3),
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: 36,
    right: 0,
    top: 0,
    left: 0,
  },
  draggableIcon: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 12,

    '& > *:not(:last-child)': {
      marginRight: 3,
    },
  },
  '@keyframes slide': {
    from: {
      transform: 'translateX(30px)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
}))
