// @ts-strict-ignore
import { GiphyFetch } from '@giphy/js-fetch-api'
import { action, makeObservable, observable, reaction } from 'mobx'
import { isEmpty } from '../../../../lib/collections'

export interface Gif {
  id: string
  title: string
  rating: string
  url: string
  size: number
  width: number
  height: number
  ratio: number
}

export class GiphyStore {
  readonly ids = new Set<string | number>()

  search: string = null
  fetchOffset = 0
  fetchTotal = Infinity
  fetchEndReached = false
  scrollOffset = 0
  viewportHeight = 0
  listHeight = 0
  gifs: readonly Gif[] = []
  error: string = null

  protected gf: GiphyFetch

  constructor(readonly apiKey: string) {
    this.gf = new GiphyFetch(this.apiKey)

    makeObservable(this, {
      search: observable,
      fetchOffset: observable,
      fetchTotal: observable,
      fetchEndReached: observable,
      scrollOffset: observable,
      viewportHeight: observable,
      listHeight: observable,
      gifs: observable.ref,
      error: observable,
      fetchGifs: action,
    })

    reaction(
      () => [this.scrollOffset, this.viewportHeight, this.listHeight],
      () => {
        if (this.scrollOffset + this.viewportHeight > this.listHeight - 100) {
          this.fetchGifs()
        }
      },
      { name: 'FetchMore' },
    )

    reaction(
      () => this.search,
      () => {
        this.gifs = []
        this.fetchTotal = Infinity
        this.fetchOffset = 0
        this.fetchEndReached = false
        this.fetchGifs()
      },
      { name: 'Search', fireImmediately: true },
    )

    reaction(
      () => [this.fetchTotal, this.fetchOffset],
      () => {
        this.fetchEndReached = this.fetchTotal <= this.fetchOffset
      },
      { name: 'EndReached' },
    )
  }

  fetchGifs() {
    if (this.fetchEndReached) return

    const options = { offset: this.fetchOffset, limit: 10 }
    const request = this.search
      ? this.gf.search(this.search, options)
      : this.gf.trending(options)

    request
      .then(
        action((gifs) => {
          this.fetchTotal = gifs.pagination.total_count
          this.gifs = [
            ...this.gifs,
            ...gifs.data
              .filter((gif) => !this.ids.has(gif.id))
              .map((gif) => {
                this.ids.add(gif.id)
                return this.convertGif(gif)
              }),
          ]
        }),
      )
      .catch(
        action((err) => {
          this.error = 'Could not fetch GIFs.'
          console.error(err)
        }),
      )

    this.fetchOffset += 10
  }

  convertGif(gif: any): Gif {
    const downsized = [
      gif.images.downsized,
      gif.images.downsized_large,
      gif.images.downsized_medium,
      gif.images.downsized_small,
    ].find((image: any) => !isEmpty(image))
    const size = Number.parseInt(downsized.size)
    const width = Number.parseInt(downsized.width as any)
    const height = Number.parseInt(downsized.height as any)
    const ratio = width / height

    return {
      id: gif.id,
      title: gif.title,
      rating: gif.rating,
      url: downsized.url,
      size,
      width,
      height,
      ratio,
    }
  }
}
