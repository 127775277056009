import { makeStyles, Theme } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../../component/button-v2'
import { Header, Section } from '../../common'
import DomainList from './DomainList'

const DomainForm = () => {
  const styles = useStyles()
  const { command, service } = useAppStore()

  const handleAddDomain = () => {
    command.present({ name: 'add domain' })
  }

  return (
    <Section>
      <Header
        title="Allowed email domains"
        subtitle="Anyone with an email address at these domains will be able to automatically sign up to this workspace"
        actions={
          (service.user.current.asMember.isAdmin ||
            service.user.current.asMember.isOwner) && (
            <Button variant="outlined" color="primary" onClick={handleAddDomain}>
              Add a domain
            </Button>
          )
        }
      />
      <DomainList style={{ marginTop: 16 }} />
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({}))

export default observer(DomainForm)
