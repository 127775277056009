import React, { useEffect } from 'react'

/**
 * Ideally we would use an AbortController but it's
 * not supported on node until version 15
 */
type Signal = {
  aborted: boolean
}

export function useAsyncEffect(
  asyncFunction: (aborted: Signal) => Promise<void>,
  dependencies: React.DependencyList,
) {
  useEffect(() => {
    const signal = { aborted: false }

    asyncFunction(signal)

    return () => {
      signal.aborted = true
    }
  }, dependencies)
}
