import React, { forwardRef } from 'react'
import { TriggerProps } from '@src/component'
import Button from '@src/component/button-v2'
import { Theme, makeStyles } from '@material-ui/core'

const SelectSearchTrigger = forwardRef<HTMLButtonElement, TriggerProps>(
  ({ onClick, children }, ref) => {
    const styles = useStyles()
    return (
      <Button
        variant="outlined"
        color="textSecondary"
        onClick={onClick}
        ref={ref}
        align="start"
        fullWidth
        endIcon={<div className={styles.arrowsIcon} />}
        className={styles.root}
      >
        {children}
      </Button>
    )
  },
)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // FIXME: this one's not in the theme
    boxShadow: `0 1.5px 4px 0 rgba(0, 0, 0, 0.06)`,
    color: theme.palette.op.gray[1],
    '& > div': {
      justifyContent: 'space-between',
    },
  },
  arrowsIcon: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 4,
    '&:before, &:after': {
      content: '""',
      width: 8,
      height: 5,
      background: theme.palette.op.gray[4],
      clipPath: 'polygon(0% 100%, 50% 0%, 100% 100%)',
    },
    '&:after': {
      transform: 'rotate(180deg)',
    },
  },
}))

export default SelectSearchTrigger
