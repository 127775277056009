import ProtocolHandler from './ProtocolHandler'

export const PROTOCOL = 'tel'

export default class TelProtocolHandler extends ProtocolHandler {
  readonly protocol = PROTOCOL
  readonly web = true
  readonly desktop = true

  handle(url: URL): void {
    this.app.command.present({
      name: 'dialer',
      phoneNumber: this.normalizePhoneNumber(url.pathname),
    })
  }
}
