// @ts-strict-ignore
import dayjs from 'dayjs'
import { makeAutoObservable, toJS } from 'mobx'
import { now } from 'mobx-utils'
import { Identity, IdentityPhone, Member, Model, fullName } from '.'
import Service from '..'
import { getInitials, parseDate } from '../../lib'
import { isNonNull } from '../../lib/rx-operators'
import {
  AvailabilityHours,
  PhoneNumberSettings,
  SharedUserRole,
  UserPhoneNumber,
} from '../../types'

export interface CodablePhoneNumber {
  availabilityHours: AvailabilityHours
  createdAt: number
  groupId: string
  id: string
  mutedUntil: number
  name: string
  number: string
  role: SharedUserRole
  formattedNumber: string
  settings: PhoneNumberSettings
  symbol: string
  updatedAt: number
  users: UserPhoneNumber[]
}

export class PhoneNumber implements Model, CodablePhoneNumber, IdentityPhone {
  availabilityHours: AvailabilityHours = null
  createdAt: number = null
  groupId: string = null
  id: string = null
  mutedUntil: number = null
  name: string = null
  number: string = null
  role: SharedUserRole = null
  formattedNumber: string = null
  settings: PhoneNumberSettings = null
  symbol: string = null
  updatedAt: number = null
  users: UserPhoneNumber[] = []

  group: PhoneNumberGroup = null

  constructor(private service: Service) {
    makeAutoObservable(this, {
      isSharedWith: false,
    })
  }

  get formattedName(): string {
    return `${this.symbol || '📱'} ${this.name}`
  }

  get muted() {
    if (!this.mutedUntil || this.mutedUntil <= Date.now()) {
      return false
    }
    const interval = this.mutedUntil - Date.now() + 10
    return this.mutedUntil > now(interval)
  }

  get isShared(): boolean {
    return this.users.length > 1
  }

  get isOffHours(): boolean {
    if (!this.availabilityHours || !this.availabilityHours.enabled) {
      return false
    }

    // Find the time in user's preferred timezone
    const localDateTimeString = new Date().toLocaleString('en-US', {
      timeZone: this.availabilityHours.timezone,
    })
    const localDateTime = dayjs(localDateTimeString)
    const localDay = (localDateTime.day() + 6) % 7
    const todaysSchedule = this.availabilityHours.schedule[localDay]

    // If no hours are set, that means the business is closed today
    if (todaysSchedule == null) {
      return true
    }

    const openingTime = parseInt(todaysSchedule.start)
    const closingTime = parseInt(todaysSchedule.end)
    // If end and start time are the same, the business is open the whole day
    if (openingTime === closingTime) {
      return false
    }

    // Check if the current time is within the defined business hours
    // in the user's timezone
    const localTime = parseInt(
      `${localDateTime.format('HH')}${localDateTime.format('mm')}`,
    )
    return localTime < openingTime || localTime >= closingTime
  }

  get members() {
    return this.users
      .map((u) => this.service.member.collection.get(u.id))
      .filter(isNonNull)
  }

  get userNames(): string {
    if (!this.users) {
      return null
    } else if (this.users.length === 1) {
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  isSharedWith(member: Member): boolean {
    return this.users.some((user) => user.id === member.id)
  }

  update = (attributes: Partial<PhoneNumber>) => {
    Object.assign(this, attributes)
    this.service.transport.account.phoneNumbers.update(this.serialize())
  }

  mute = (until: Date) => {
    this.mutedUntil = until.getTime()
    this.service.transport.account.phoneNumbers.mute(this.serialize())
  }

  unmute = () => {
    this.mutedUntil = null
    this.service.transport.account.phoneNumbers.unmute(this.serialize())
  }

  deserialize = (attrs: Partial<CodablePhoneNumber>) => {
    if (attrs) {
      const { groupId, ...json } = attrs
      Object.assign(this, json)
      this.groupId = groupId
      this.group = new PhoneNumberGroup(this)
      this.createdAt = parseDate(json.createdAt)
      this.mutedUntil = parseDate(json.mutedUntil)
      this.updatedAt = parseDate(json.updatedAt)
    }
    return this
  }

  serialize = (): CodablePhoneNumber => {
    return {
      availabilityHours: toJS(this.availabilityHours),
      createdAt: this.createdAt,
      formattedNumber: this.formattedNumber,
      groupId: this.groupId,
      id: this.id,
      mutedUntil: this.mutedUntil,
      name: this.name,
      number: this.number,
      role: this.role,
      settings: toJS(this.settings),
      symbol: this.symbol,
      updatedAt: this.updatedAt,
      users: toJS(this.users),
    }
  }
}

export class PhoneNumberGroup implements Identity {
  readonly pictureUrl = null
  readonly emailAddresses = []
  readonly isAnonymous = false

  constructor(readonly phoneNumber: PhoneNumber = null) {
    makeAutoObservable(this, {})
  }

  get id(): string {
    return this.phoneNumber.groupId
  }

  get name(): string {
    return this.phoneNumber.name
  }

  get shortName(): string {
    return this.phoneNumber.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get pictureSymbol(): string {
    return this.phoneNumber.symbol
  }

  get members() {
    return this.phoneNumber.members
  }

  get phones(): IdentityPhone[] {
    return [
      {
        id: this.phoneNumber.id,
        name: this.phoneNumber.name,
        symbol: this.phoneNumber.symbol,
        number: this.phoneNumber.number,
      },
    ]
  }
}
