// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatarGroup } from '@src/app/components'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import FormattedText from '../../../component/formatted-text'
import Typography from '@ui/Typography'
import Unread from '../../../component/unread'
import { useNonNullable } from '../../../lib/hooks'
import { Conversation } from '../../../service/conversation-store'
import Participant from './participant'
import PreviewIcon from './preview-icon'

interface ConversationProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onSelect'> {
  index: number
  conversation: Conversation
  onSelect: ({ conversation: Conversation }) => void
}

const ConversationRow: React.FC<ConversationProps> = function ({
  conversation,
  index,
  onSelect,
  ...props
}) {
  const styles = useStyle({})
  const { conversation: conversationStore } = useAppStore()
  const lastActivity = useNonNullable(() => conversation.lastActivity, [conversation])
  const lastActivityAt = lastActivity?.createdAt
  const registry = conversationStore.inputBarRegistry
  const draft = registry.draftMessages[conversation.id]

  useEffect(() => registry.populateDraftMessage(conversation), [conversation, registry])

  /**
   * Show the date of last activity on the current row. If date is today,
   * display the hour mark. If it's before today, show day and month
   */
  const date = useMemo(() => {
    if (!conversation) {
      return null
    }
    const now = dayjs()
    const date = dayjs(lastActivityAt)
    const isToday =
      date.year() === now.year() &&
      date.month() === now.month() &&
      date.date() === now.date()

    if (isToday) {
      return date.format('h:mm A')
    }
    return date.format('MMM D')
  }, [lastActivityAt])

  const handleClick = useCallback(
    () => onSelect({ conversation }),
    [conversation, onSelect],
  )

  return (
    <div {...props}>
      <div className={styles.root} onClick={handleClick}>
        <AppAvatarGroup identities={conversation.participants} />
        <div className={styles.body}>
          <div className={styles.line}>
            <span color="textPrimary" className={styles.name}>
              {conversation.isNew && (
                <span className={styles.new}>
                  {conversation.friendlyName ? 'New: ' : 'New Conversation'}
                </span>
              )}
              {conversation.friendlyName}
              {!conversation.isGroup &&
                conversation.participants[0]?.member?.presence?.hasStatus && (
                  <span
                    title={conversation.participants[0]?.member.presence.text}
                    className={styles.statusEmoji}
                  >
                    {conversation.participants[0]?.member.statusSymbol}
                  </span>
                )}
            </span>
            <span className={styles.date}>{date}</span>
          </div>
          <div className={styles.line2}>
            {draft ? (
              <Typography
                nowrap
                variant="footnote"
                color={conversation.isUnread ? 'textPrimary' : 'textSecondary'}
                className={styles.preview}
              >
                <span className={styles.draft}>Draft:</span>
                <FormattedText value={draft} inline />
              </Typography>
            ) : conversation.isNew ? (
              <Typography
                nowrap
                variant="footnote"
                color="textSecondary"
                className={styles.preview}
              >
                Send a message
              </Typography>
            ) : (
              <Typography
                nowrap
                variant="footnote"
                color="textSecondary"
                className={styles.preview}
              >
                <PreviewIcon activity={lastActivity} />
                <FormattedText value={lastActivity?.preview} inline />
              </Typography>
            )}
            {conversation.isUnread && (
              <Unread count={conversation.unreadCount} color="blue" />
            )}
            <div className={styles.presence}>
              <Participant conversation={conversation} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ConversationRow)

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '10px 25px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    alignItems: 'center',
    userSelect: 'none',
    minWidth: 0,
    cursor: 'pointer',
    marginBottom: 2,
    position: 'relative',

    '&:before': {
      content: '""',
      top: 0,
      left: 10,
      right: 10,
      bottom: 0,
      borderRadius: 5,
      background: theme.palette.op.hover.primary,
      pointerEvents: 'none',
      position: 'absolute',
      opacity: 0,
    },

    '&:hover:before': {
      opacity: 1,
    },
  },
  body: {
    marginLeft: 16,
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  line: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 0,
  },
  line2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 16,
  },
  name: {
    flex: 1,
    fontWeight: 550,
    lineHeight: '22px',
    color: theme.palette.text.primary,
    paddingRight: '1rem',
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  bold: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  unreadMark: {
    fontSize: 9,
    lineHeight: '10px',
    fontWeight: 750,
    padding: '0 5px',
    borderRadius: 25,
    color: theme.palette.op.text.primary,
    background: theme.palette.op.secondary.blue,
    minWidth: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  preview: {
    flex: 1,
  },
  marginBottom: {
    marginBottom: 1,
  },
  icon: {
    marginRight: 8,
  },
  date: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    flex: '0 1 55px',
    textAlign: 'right',
    color: theme.palette.op.gray[3],
  },
  highlighted: {
    '& $preview': {
      color: theme.palette.text.primary,
    },
    '& $date': {
      display: 'none',
    },
  },
  presence: {
    display: 'flex',
  },
  away: {
    opacity: 0.3,
  },
  draft: {
    fontSize: 12,
    marginRight: 3,
    color: theme.palette.op.legacy.draftGreen,
    fontWeight: 500,
  },
  new: {
    marginRight: 3,
    color: theme.palette.op.legacy.draftGreen,
    fontWeight: 500,
  },
  statusEmoji: {
    fontSize: '1rem',
    marginLeft: 5,
  },
}))
