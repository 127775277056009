import React, { useCallback } from 'react'
import { createErrorBoundary, start } from '../../lib/crash-reporting'
import { useStore } from '../../lib/hooks'
import AppStore from '../store'
import ErrorView from './error-view'
import ErrorStore from './store'

interface ErrorBoundaryProps {
  app: AppStore
}

start('client')
const BugsnagErrorBoundary = createErrorBoundary(React)

const ErrorBoundary: React.FC<ErrorBoundaryProps> = function ({ app, children }) {
  const store = useStore(() => new ErrorStore(app), [app])

  const Component = useCallback(
    ({ clearError }) => {
      store.clearError = clearError
      return <ErrorView store={store} />
    },
    [store],
  )

  return (
    <BugsnagErrorBoundary onError={store.setEvent} FallbackComponent={Component}>
      {children}
    </BugsnagErrorBoundary>
  )
}

export default ErrorBoundary
