// @ts-strict-ignore
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import ClickAwayListener from '@ui/ClickAwayListener'
import Editor from '../editor'
import IconButton from '../icon-button'
import { SendMessageInternalIcon } from '../icons/Tint/20/General'
import Actions from './actions'
import Controller from './controller'
import type { Message, MessageBlock, Selection, SlateEditor } from './controller'
import InputMenu from './input-menu'
import Media from './media'

export { Controller, Message, MessageBlock, Selection, SlateEditor }

export type InputBarVariant = 'full' | 'collapsed'

export interface InputBarProps {
  controller: Controller
  attachmentDropTargetRef?: React.RefObject<EventTarget>
  placeholder?: string
  variant?: InputBarVariant
  color?: string
  colorScheme?: 'regular' | 'note'
  sendIcon?: React.ReactNode
  renderMention?(mention: string): React.ReactNode
  className?: string
}

const InputBar = function ({
  controller,
  attachmentDropTargetRef,
  placeholder = 'Write a message…',
  variant = 'full',
  color,
  colorScheme = 'regular',
  sendIcon,
  renderMention,
  className,
}: InputBarProps) {
  const styles = useStyles({ variant, color, colorScheme })
  const theme = useTheme()

  if (colorScheme === 'note') {
    color = theme.palette.op.note.highlight
  }

  useEffect(() => {
    if (!attachmentDropTargetRef?.current) return
    const el = attachmentDropTargetRef.current

    el.addEventListener('paste', controller.handlePaste)
    el.addEventListener('drop', controller.handleDrop)
    el.addEventListener('dragover', controller.handleDragover)

    return () => {
      el.removeEventListener('paste', controller.handlePaste)
      el.removeEventListener('drop', controller.handleDrop)
      el.removeEventListener('dragover', controller.handleDragover)
    }
  }, [controller, attachmentDropTargetRef?.current])

  const renderActions = useCallback(
    () => (
      <Actions
        controller={controller}
        variant={variant}
        className={variant === 'full' ? styles.actions : null}
      />
    ),
    [controller, variant, styles.actions],
  )

  return (
    <ClickAwayListener onClickAway={controller.handleClickAway}>
      <div
        className={cx(
          styles.root,
          controller.focused ? styles.focus : null,
          styles[variant],
          className,
        )}
      >
        {controller.media.length > 0 ? (
          <Media
            media={controller.media}
            onOpen={controller.openMedia}
            onDelete={controller.handleDeleteMedia}
          />
        ) : null}
        <Editor
          controller={controller.editor}
          placeholder={placeholder}
          color={color}
          renderMention={renderMention}
          className={styles.textfield}
        />
        {variant === 'full' ? renderActions() : null}
        <div className={cx(styles.actions, styles.end)}>
          {variant === 'collapsed' ? renderActions() : null}
          <IconButton
            icon={sendIcon ?? <SendMessageInternalIcon />}
            color={color ?? 'primary'}
            className={cx(styles.actionButton, styles.send)}
            title="Send message"
            tooltipPlacement="top"
            disabled={!controller.isSendEnabled}
            onClick={controller.handleSend}
          />
        </div>
        <div className={styles.inputMenu}>
          <InputMenu controller={controller} />
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default observer(InputBar)

const useStyles = makeStyles<Theme, Partial<InputBarProps>>(
  (theme) => ({
    root: {
      position: 'relative',
      flex: 1,
      boxShadow: `inset 0 0 0 1.5px ${alpha(theme.palette.op.gray[1], 0.12)}`,
      borderRadius: 8,
      transition: theme.transitions.create(['border', 'box-shadow'], {
        easing: 'ease',
        duration: 240,
      }),
    },
    textfield: {},
    focus: ({ color, colorScheme }) => {
      if (colorScheme === 'note') {
        return {
          boxShadow: `inset 0 0 0 1.5px ${alpha(
            theme.palette.op.note.highlight,
            0.8,
          )}, 0 0 0 4px ${alpha(theme.palette.op.note.highlight, 0.14)}`,
        }
      }

      return {
        boxShadow: `inset 0 0 0 1.5px ${alpha(
          color ?? theme.palette.primary.light,
          0.6,
        )}, 0 0 0 4px ${alpha(color ?? theme.palette.primary.light, 0.1)}`,
      }
    },
    media: {
      margin: '16px 16px 2px',
    },
    actions: {
      display: 'flex',
      position: 'absolute',
    },
    end: {},
    full: {
      '& $textfield': {
        padding: '16px 16px 46px',
      },

      '& $actions': {
        bottom: 9,
        left: 11,

        '&$end': {
          left: 'unset',
          right: 11,
        },
      },
    },
    collapsed: {
      '& $textfield': {
        padding: '10px 100px 10px 12px',
      },

      '& $actions': {
        bottom: 4,
        left: 5,

        '&$end': {
          left: 'unset',
          right: 5,
        },
      },
    },
    inputMenu: {
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  }),
  { name: InputBar.name },
)
