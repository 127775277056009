import React, { FunctionComponent, ReactNode, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core'

import { useAppStore } from '@src/app/context'
import { Header as CommonHeader } from '@src/app/settings/common'
import Typography from '@ui/Typography'
import IconButton from '@src/component/icon-button'
import { InfoIcon } from '@src/component/icons/custom'

type DismissibleInfoCard = FunctionComponent<{ onClose: () => void }>
interface HeaderProps {
  title: string
  subtitle: string
  icon: ReactNode
  infoCard?: DismissibleInfoCard
}

function Header({ title, subtitle, icon, infoCard: InfoCard }: HeaderProps) {
  const { history } = useAppStore()
  const styles = useStyles()
  const [isInfoCardOpened, setIsInfoCardOpened] = useState(false)

  const handleBack = () => history.push('/settings/integrations')
  const openInfoCard = () => setIsInfoCardOpened(true)
  const closeInfoCard = () => setIsInfoCardOpened(false)

  return (
    <div className={styles.root}>
      <CommonHeader
        title={
          <div className={styles.title}>
            {icon} <Typography variant="title3">{title}</Typography>
          </div>
        }
        subtitle={
          <div className={styles.subtitle}>
            {subtitle}
            {!isInfoCardOpened && InfoCard ? (
              <IconButton size="small" icon={<InfoIcon />} onClick={openInfoCard} />
            ) : null}
          </div>
        }
        backTitle="Back to integrations"
        onBack={handleBack}
      />
      {isInfoCardOpened && InfoCard ? <InfoCard onClose={closeInfoCard} /> : null}
    </div>
  )
}

export default observer(Header)

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 6,
    height: 28,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 6,
    minHeight: 22,
  },
})
