import React from 'react'
import { SlackIcon } from '@src/component/icons/custom'
import Button from '@src/component/button-v2'
import { useAppStore } from '@src/app/context'
import { Page } from '../common'
import { Header } from './components/header'
import { HowItWorksCard } from './components/how-it-works-card'
import { DisconnectedLayout } from './components/disconnected-layout'

const Slack = () => {
  const { history } = useAppStore()

  const handleAdd = function () {
    history.push('/settings/phone-numbers')
  }

  return (
    <Page>
      <DisconnectedLayout>
        <Header
          icon={<SlackIcon />}
          title="Slack"
          subtitle="Get notified of OpenPhone messages and calls in your Slack workspace"
        />
        <HowItWorksCard
          description={
            <>
              <p style={{ margin: 0 }}>
                When enabled, the Slack integration sends a log of messages and calls as
                they occur to your Slack workspace. You can enable Slack integration per
                phone number.
              </p>
              <p style={{ margin: '10px 0 0 0' }}>
                To enable, go to the phone number settings and choose the phone number you
                would like to activate this integration for. Scroll to bottom to find the
                option for Slack integration.
              </p>
            </>
          }
          action={
            <Button variant="outlined" color="textPrimary" onClick={handleAdd} fullWidth>
              Go to phone numbers
            </Button>
          }
        />
      </DisconnectedLayout>
    </Page>
  )
}

export default Slack
