// @ts-strict-ignore
import React, { useCallback, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import type { DialogName } from '@openphone/desktop-client'

import { useAppStore } from '@src/app/context'
import { DeployPreview } from '../dialogs/deploy-preview'

export function useDialogRoute() {
  const { url } = useRouteMatch()

  return useCallback((dialogName: DialogName) => `${url}/${dialogName}`, [url])
}

export default function DialogRouter() {
  const route = useDialogRoute()
  const { history, electron } = useAppStore()
  const styles = useStyles()

  useEffect(() => {
    electron.on('navigate-to-dialog', ({ dialogName }) => {
      history.replace(route(dialogName))
    })
  }, [route, electron])

  return (
    <div>
      <div className={styles.dragArea}></div>
      <Switch>
        <Route path={route('deploy-preview')} component={DeployPreview} />
      </Switch>
    </div>
  )
}

const useStyles = makeStyles({
  dragArea: {
    height: 30,
    '-webkit-app-region': 'drag',
  },
})
