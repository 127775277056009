import React from 'react'
import { Dialog, DialogTitle, DialogContent } from '../../../component/dialog'
import { Token } from '@stripe/stripe-js'
import CreditCardForm from '../../../component/credit-card'

type UpdateCreditCardProps = {
  open: boolean
  setOpen: (open: boolean) => void
  onSave: (token: string) => void
}

const UpdateCreditCard: React.FC<UpdateCreditCardProps> = function ({
  open,
  setOpen,
  onSave,
}) {
  const handleSubmit = (token: Token) => {
    onSave(token.id)
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Enter your credit card</DialogTitle>
      <DialogContent style={{ padding: '2rem' }}>
        <div style={{ width: 500 }}>
          <CreditCardForm onSubmit={handleSubmit} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCreditCard
