import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../../component/button-v2'
import Typography from '@ui/Typography'
import { Header, Page } from '../../common'
import { WebhooksPageTableCell as Cell } from './WebhooksPageTableCell'
import WebhooksPageTableRow from './WebhooksPageTableRow'

interface WebhooksPageProps {}

const WebhooksPage = (props: WebhooksPageProps) => {
  const styles = useStyles()
  const { workspace, command } = useAppStore()

  const handleCreateWebhook = () => {
    command.present({ name: 'create webhook' })
  }

  return (
    <Page>
      <div className={styles.root}>
        <Header
          title="Webhooks"
          subtitle="Manage all the webhooks in your workspace"
          actions={
            <Button variant="contained" color="primary" onClick={handleCreateWebhook}>
              Create webhook
            </Button>
          }
          className={styles.header}
        />
        <TableContainer className={styles.root}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <Cell>
                  <Typography variant="caption1" color="textTertiary">
                    URL
                  </Typography>
                </Cell>
                <Cell align="left">
                  <Typography variant="caption1" color="textTertiary">
                    Label
                  </Typography>
                </Cell>
                <Cell align="left">
                  <Typography variant="caption1" color="textTertiary">
                    Created
                  </Typography>
                </Cell>
                <Cell align="center">
                  <Typography variant="caption1" color="textTertiary">
                    Status
                  </Typography>
                </Cell>
                <Cell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {workspace.webhooks.map((webhook, index) => {
                return <WebhooksPageTableRow key={webhook.id} webhook={webhook} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Page>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: 16,
    },
    header: {
      padding: '0 14px',
    },
    table: {
      display: 'flex',
      flexDirection: 'column',

      '& tr': {
        display: 'grid',
        gridTemplateColumns: `minmax(220px, 1fr) 90px 90px 44px 30px`,
        columnGap: 16,
        padding: `0px 14px`,

        '& th, & td': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          '&[class*="alignCenter"]': {
            justifyContent: 'center',
          },
        },
      },
    },
  }),
  { name: WebhooksPage.name },
)

export default observer(WebhooksPage)
