// @ts-strict-ignore
import { action, flow, makeAutoObservable, runInAction } from 'mobx'
import { Subject } from 'rxjs'
import Service from '.'
import { PageInfo } from '../types'
import {
  Alert,
  AlertAssociations,
  ThreadMentionAlert,
  ActivityMentionAlert,
  ContactNoteMentionAlert,
  PersistedCollection,
  ThreadReplyAlert,
  ThreadCommentReactionAlert,
  DecodableAlert,
  ActivityReactionAlert,
  ThreadResolutionAlert,
} from './model'
import { makePersistable } from './storage/persistable'
import { AlertRepository } from './worker/repository'
import { AlertDeleteMessage, AlertUpdateMessage } from './transport/websocket'

export default class AlertStore {
  readonly collection: PersistedCollection<Alert, AlertRepository> = null

  private pageInfo: PageInfo = null
  private lastFetchedAt: number = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('alert'),
      classConstructor: (json) => {
        switch (json.type) {
          case 'comment-on-activity':
          case 'reply-in-mentioned-thread':
            return new ThreadReplyAlert(this.root)
          case 'reaction-on-activity':
            return new ActivityReactionAlert(this.root)
          case 'mention-in-activity-comment':
            return new ThreadMentionAlert(this.root)
          case 'mention-in-activity':
            return new ActivityMentionAlert(this.root)
          case 'mention-in-contact-note':
            return new ContactNoteMentionAlert(this.root)
          case 'reaction-on-activity-comment':
            return new ThreadCommentReactionAlert(this.root)
          case 'activity-resolved':
          case 'activity-unresolved':
            return new ThreadResolutionAlert(this.root)
        }
      },
    })

    makeAutoObservable(this, {}, { autoBind: true })

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'AlertStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.subscribeToWebhookEvents()
  }

  getAll() {
    this.collection.performQuery((repo) => repo.all())
  }

  async fetchMissing() {
    const self = this

    /**
     * If lastFetchedAt was older than a week ago, nuke the cache and try
     * fetching alerts again from scratch
     */
    if (this.lastFetchedAt < Date.now() - 3600 * 24 * 7 * 1000) {
      await this.collection.deleteQuery((repo) => repo.all())
      runInAction(() => {
        this.lastFetchedAt = null
      })
    }

    if (this.lastFetchedAt) {
      return this.root.transport.account.alert.since(new Date(this.lastFetchedAt)).then(
        flow(function* (res) {
          const alerts = yield self.collection.load(res) ?? []
          self.lastFetchedAt = Math.max(
            ...alerts.map((c) => c.updatedAt),
            self.lastFetchedAt + 1,
          )
        }),
      )
    } else {
      return this.root.transport.account.alert.paginated().then(
        flow(function* (res) {
          const alerts = yield self.load(res.result, res.associations) ?? []
          self.pageInfo = res.pageInfo
          self.lastFetchedAt = Math.max(
            ...alerts.map((c) => c.updatedAt + 1),
            (self.lastFetchedAt || Date.now()) + 1,
          )
        }),
      )
    }
  }

  async markRead(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach(
      action((alert) => {
        alert.readAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.read(ids)
  }

  async markUnread(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach((alert) => {
      alert.readAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unread(ids)
  }

  async markOpened(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.map(
      action((alert) => {
        alert.openedAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.open(ids)
  }

  async markUnopened(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach((alert) => {
      alert.openedAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unopen(ids)
  }

  delete(alerts: Alert[]) {
    const ids = alerts.map((a) => a.id)
    this.collection.deleteBulk(alerts)
    return this.root.transport.account.alert.delete(ids)
  }

  private subscribeToWebhookEvents() {
    this.root.transport.onNotificationData.subscribe((message) => {
      switch (message.type) {
        case 'alert-update':
          return this.handleAlertUpdate(message)
        case 'alert-delete':
          return this.handleAlertDelete(message)
      }
    })
  }

  private handleAlertUpdate(message: AlertUpdateMessage) {
    this.load(message.alert, message.associations)
  }

  private handleAlertDelete(message: AlertDeleteMessage) {
    this.collection.delete(message.alert.id)
  }

  private load(
    alerts: DecodableAlert | DecodableAlert[],
    { contacts = [], conversations = [], activities = [] }: AlertAssociations = {},
  ) {
    this.root.contact.load(contacts)
    this.root.conversation.load(conversations, activities)
    return this.collection.load(alerts)
  }
}
