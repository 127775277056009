// @ts-strict-ignore
import { Breadcrumbs, makeStyles, Theme } from '@material-ui/core'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../../component/button-v2'
import IconButton from '../../../../component/icon-button'
import { MoreIcon } from '../../../../component/icons/Tint/18/General'
import {
  DeleteIcon,
  PadlockIcon,
  ReplaceIcon,
  PlaneIcon,
} from '../../../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../../../component/popover-menu'
import Switch from '../../../../component/switch'
import TextField from '../../../../component/textfield-v2'
import Tooltip from '../../../../component/tooltip'
import Typography from '@ui/Typography'
import { WebhookModel } from '../../../../service/model/WebhookModel'
import CallsAndMessagesEvents from '../../../command/create-webhook/CallsAndMessagesEvents'
import ContactEvents from '../../../command/create-webhook/ContactEvents'
import { getTooltipErrorMsg } from '../../../command/create-webhook/CreateWebhookCommand'
import { Header, Page } from '../../common'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import cx from 'classnames'
import { CallsAndMessagesEventTypes, ContactEventTypes, EventTypes } from '@src/data'
import { WebhookEventsTable } from '@src/app/settings/webhooks/events-table'
import useTemporaryState from '@src/lib/useTemporaryState'

interface WebhookDetailsProps {
  webhook: WebhookModel
}

const callsAndMessagesEventTypesNames = CallsAndMessagesEventTypes.map(
  (event) => event.name,
)

const contactsEventTypesNames = ContactEventTypes.map((event) => event.name)

const schema = yup
  .object({
    url: yup.string().required('The URL is required').url('Please enter a valid URL'),
  })
  .required()

const isAllSelected = (thing: string[]) => thing.length === 1 && thing[0] === '*'

const WebhookDetails = ({ webhook }: WebhookDetailsProps) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      url: webhook.url,
      label: webhook.label,
    },
  })
  const { toast, history, workspace, service, inboxes, command } = useAppStore()
  const styles = useStyles({ enabled: webhook.enabled })

  const [showSecret, setShowSecret] = useState(false)
  const [showSubmitErrorTooltip, setShowSubmitErrorTooltip] = useState(false)
  const [showSaveEventTypesBtn, setShowSaveEventTypesBtn] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [callsAndMessagesEventTypes, setCallsAndMessagesEventTypes] = useState<
    (EventTypes | '*')[]
  >(
    isAllSelected(webhook.activitiesTrigger.events)
      ? callsAndMessagesEventTypesNames
      : webhook.activitiesTrigger.events,
  )
  const [contactEventTypes, setContactEventTypes] = useState<(EventTypes | '*')[]>(
    isAllSelected(webhook.contactsTrigger.events)
      ? contactsEventTypesNames
      : webhook.contactsTrigger.events,
  )

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    isAllSelected(webhook.activitiesTrigger.resourceIds)
      ? inboxes.all.list.filter((inbox) => inbox.phoneNumber).map((inbox) => inbox.id)
      : webhook.activitiesTrigger.resourceIds,
  )

  const [contactsAndGroups, setContactsAndGroups] = useState<string[]>(
    isAllSelected(webhook.contactsTrigger.resourceIds)
      ? [
          ...workspace.groups.map((gr) => gr.id),
          ...service.member.collection.list.map((member) => member.id),
        ]
      : webhook.contactsTrigger.resourceIds,
  )

  const [copiedMessage, showCopiedMessage] = useTemporaryState('Copied')

  const handleEnabledSwitch = action(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      webhook.enabled = checked
      webhook.save()
      toast.show({ message: 'The webhook has been updated!' })
    },
  )

  const handleDelete = () => {
    webhook.delete()
    toast.show({ message: 'The webhook has been deleted!' })
    history.push('/settings/webhooks')
  }

  const handleUrlOnBlur = action(() => {
    if (errors.url?.message) {
      return
    }

    webhook.url = getValues('url')
    webhook.save()
    toast.show({ message: 'The webhook has been updated!' })
  })

  const handleLabelOnBlur = action(() => {
    webhook.label = getValues('label')
    webhook.save()
    toast.show({ message: 'The webhook has been updated!' })
  })

  const handleTogglePopover: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleSaveEvents = action(() => {
    webhook.triggers = [
      {
        category: 'activities',
        events:
          callsAndMessagesEventTypesNames.length === callsAndMessagesEventTypes.length
            ? ['*']
            : callsAndMessagesEventTypes,
        resourceIds:
          inboxes.all.list.filter((inbox) => inbox.phoneNumber).length ===
          phoneNumbers.length
            ? ['*']
            : phoneNumbers,
      },
      {
        category: 'contacts',
        events:
          contactsEventTypesNames.length === contactEventTypes.length
            ? ['*']
            : contactEventTypes,
        resourceIds:
          service.member.collection.list.length + workspace.groups.length ===
          contactsAndGroups.length
            ? ['*']
            : contactsAndGroups,
      },
    ]
    webhook.save()
    toast.show({ message: 'The webhook has been updated!' })
    setShowSaveEventTypesBtn(false)
  })

  const trackEventTypeChanges = (
    dispatchFn: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    return (values: any) => {
      if (!showSaveEventTypesBtn) {
        setShowSaveEventTypesBtn(true)
      }
      dispatchFn(values)
    }
  }

  const tooltipMsg = getTooltipErrorMsg({
    contactEventTypes,
    phoneNumbers,
    arePhoneNumbersPristine: false,
    contactsAndGroups,
    areContactsPristine: false,
    callsAndMessagesEventTypes,
  })

  const SubmitButton = () => {
    if (tooltipMsg) {
      return (
        <Tooltip
          open={showSubmitErrorTooltip}
          onClose={() => setShowSubmitErrorTooltip(false)}
          onOpen={() => setShowSubmitErrorTooltip(true)}
          title={tooltipMsg}
        >
          <span>
            <Button disabled={true} onClick={handleSaveEvents}>
              Save
            </Button>
          </span>
        </Tooltip>
      )
    }
    return <Button onClick={handleSaveEvents}>Save</Button>
  }

  const handleRevealSecretOpen = useCallback(() => {
    setShowSecret(true)
  }, [showSecret, setShowSecret])

  const handleRevealSecretClose = useCallback(() => {
    setShowSecret(false)
  }, [showSecret, setShowSecret])

  const handleCopySecret = useCallback(
    (event) => {
      navigator.clipboard.writeText(webhook.key)
      showCopiedMessage()
      event.stopPropagation()
    },
    [webhook.key, showCopiedMessage],
  )

  const handleOpenSendRequestCommand = useCallback(() => {
    command.present({ name: 'send test request', id: webhook.id })
    setAnchorEl(null)
  }, [webhook.id])

  const handleRegenerateKey = () => {
    webhook.key = ''
    webhook.save().then(() => toast.show({ message: 'The secret has been updated!' }))
  }

  return (
    <>
      <Page>
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>
            <Typography
              variant="caption1"
              color="textTertiary"
              className={styles.breadcrumbItem}
              onClick={() => history.push('/settings/webhooks')}
            >
              Webhooks
            </Typography>
            <Typography
              variant="caption1"
              color="textSecondary"
              className={styles.breadcrumbItem}
              nowrap
              onClick={() => history.push(`/settings/webhooks/${webhook.id}`)}
            >
              {webhook.url}
            </Typography>
          </Breadcrumbs>
          <span className={styles.actions}>
            <IconButton
              onClick={handleTogglePopover}
              icon={<MoreIcon />}
              title="Actions"
            />
          </span>
          <PopoverMenu
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: -8, horizontal: 'right' }}
            elevation={2}
            maxWidth={218}
            minWidth={218}
            classes={{ paper: styles.detailsActionsMenu }}
          >
            <MenuItem icon={<PlaneIcon />} onClick={handleOpenSendRequestCommand}>
              <Typography variant="footnote">Send test request</Typography>
            </MenuItem>
            <MenuItem
              icon={<PadlockIcon />}
              onMouseEnter={handleRevealSecretOpen}
              onMouseLeave={handleRevealSecretClose}
              style={{ paddingRight: 0 }}
            >
              {showSecret ? (
                <div className={styles.secretContainer}>
                  <Typography
                    variant="footnote"
                    nowrap
                    color="textSecondary"
                    className={styles.secretTxt}
                  >
                    {webhook.key}
                  </Typography>
                  <Button
                    variant="text"
                    height={30}
                    onClick={handleCopySecret}
                    className={cx(
                      styles.copyButton,
                      copiedMessage && styles.copiedButton,
                    )}
                  >
                    {copiedMessage ?? 'Copy'}
                  </Button>
                </div>
              ) : (
                <Typography variant="footnote">Reveal signing secret</Typography>
              )}
            </MenuItem>
            <MenuItem icon={<ReplaceIcon />} onClick={handleRegenerateKey}>
              <Typography variant="footnote">Replace signing secret</Typography>
            </MenuItem>
            <ConfirmDeleteItem onConfirm={handleDelete} />
          </PopoverMenu>
        </div>
        <main className={styles.container}>
          <section>
            <Header
              title="Webhook details"
              subtitle="Manage general settings for this webhook"
            />
            <div className={styles.content}>
              <div className={styles.section}>
                <label className={styles.sectionLeftColumn} htmlFor="status">
                  <Typography variant="callout" color="textPrimary">
                    Status
                  </Typography>
                  <Typography variant="footnote" color="textTertiary">
                    When enabled, this webhook will receive push notifications
                  </Typography>
                </label>
                <label className={styles.switchColumn}>
                  <Typography className={styles.switchLabel}>
                    {webhook.enabled ? 'Enabled' : 'Disabled'}
                  </Typography>
                  <Switch
                    id="status"
                    size="medium"
                    checked={webhook.enabled}
                    onChange={handleEnabledSwitch}
                  />
                </label>
              </div>
              <div className={styles.section}>
                <label className={styles.sectionLeftColumn} htmlFor="url">
                  <Typography variant="callout" color="textPrimary">
                    URL
                  </Typography>
                  <Typography variant="footnote" color="textTertiary">
                    Your webhook URL
                  </Typography>
                </label>
                <div className={styles.sectionRightColumn}>
                  <TextField
                    id="url"
                    size={35}
                    errorText={errors.url?.message}
                    {...register('url', {
                      onBlur: handleUrlOnBlur,
                    })}
                  />
                </div>
              </div>
              <div className={styles.section}>
                <label className={styles.sectionLeftColumn} htmlFor="label">
                  <Typography variant="callout" color="textPrimary">
                    Label <span className={styles.optionalLabel}>(optional)</span>
                  </Typography>
                  <Typography variant="footnote" color="textTertiary">
                    Your webhook label
                  </Typography>
                </label>
                <div className={styles.sectionRightColumn}>
                  <TextField
                    id="label"
                    placeholder="Enter a label for this webhook…"
                    size={35}
                    {...register('label', {
                      onBlur: handleLabelOnBlur,
                    })}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <Header
              title="Event Types"
              subtitle="Listen to changes based on different event types"
            />
            <div className={styles.eventsContainer}>
              <CallsAndMessagesEvents
                callsAndMessagesEventTypes={callsAndMessagesEventTypes}
                setCallsAndMessagesEventTypes={trackEventTypeChanges(
                  setCallsAndMessagesEventTypes,
                )}
                phoneNumbers={phoneNumbers}
                setPhoneNumbers={trackEventTypeChanges(setPhoneNumbers)}
                phoneNumbersCardDescriptionRoot={styles.phoneNumbersCardDescriptionRoot}
              />
              <ContactEvents
                contactsAndGroups={contactsAndGroups}
                setContactsAndGroups={trackEventTypeChanges(setContactsAndGroups)}
                contactEventTypes={contactEventTypes}
                setContactEventTypes={trackEventTypeChanges(setContactEventTypes)}
                contactsCardDescriptionRoot={styles.contactsCardDescriptionRoot}
              />

              {showSaveEventTypesBtn && (
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}
                >
                  <SubmitButton />
                </div>
              )}
            </div>
          </section>
          <WebhookEventsTable webhookId={webhook.id} />
        </main>
      </Page>
    </>
  )
}

function ConfirmDeleteItem({ onConfirm }: { onConfirm: () => void }) {
  const [confirm, setConfirm] = useState(false)
  const styles = useStyles({ enabled: false })

  const handleClick = () => setConfirm(true)

  return (
    <MenuItem
      icon={<DeleteIcon className={styles.deleteIcon} />}
      onClick={confirm ? onConfirm : handleClick}
    >
      <Typography color="error" variant="footnote">
        {confirm ? 'Are you sure?' : 'Delete webhook'}
      </Typography>
    </MenuItem>
  )
}

const useStyles = makeStyles<Theme, { enabled: boolean }>(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 64,
      marginTop: 34,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 0',
      boxShadow: `0 1.5px 0 0 ${theme.palette.op.border.common}`,
    },
    eventsContainer: {
      marginTop: 24,
    },
    detailsActionsMenu: {
      width: 234,
      maxWidth: 234,
    },
    copyButton: {
      fontSize: 13,
      '&:hover:not(:disabled):before': {
        background: 'none',
      },
    },
    copiedButton: {
      color: theme.palette.op.gray[1],
    },
    sectionLeftColumn: {
      alignSelf: 'flex-start',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
    },
    sectionRightColumn: {
      width: 372,
    },
    switchColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    secretContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    secretTxt: {
      flex: 1,
      textAlign: 'left',
      direction: 'rtl',
    },
    switchLabel: ({ enabled }) => ({
      color: enabled ? theme.palette.primary.light : theme.palette.op.text.tertiary,
      marginRight: 12,
    }),
    optionalLabel: {
      color: theme.palette.op.gray[4],
    },
    phoneNumbersCardDescriptionRoot: {
      maxWidth: 190,
    },
    contactsCardDescriptionRoot: {
      maxWidth: 190,
    },
    breadcrumbItem: {
      cursor: 'pointer',
      maxWidth: 500,
    },
    breadcrumbsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    deleteIcon: {
      color: theme.palette.op.secondary.red2,
    },
  }),
  { name: WebhookDetails.name },
)

export default observer(WebhookDetails)
