import { when } from 'mobx'

import { toE164 } from '@src/lib/phone-number'

import ProtocolHandler from './ProtocolHandler'

export const PROTOCOL = 'sms'

export default class SMSProtocolHandler extends ProtocolHandler {
  readonly protocol: string = PROTOCOL
  readonly web = true
  readonly desktop = true

  handle(url: URL): void {
    const numbers = url.pathname.split(',').map(toE164)
    const message = url.searchParams.get('body') ?? undefined

    when(
      () => !!this.app.inboxes.selected,
      () => {
        this.app.inboxes.selected.newConversation(numbers.join(','), true, message)
      },
    )
  }
}
