// @ts-strict-ignore
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import DownArrow from '@material-ui/icons/KeyboardArrowDownOutlined'
import MuiToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, { useRef, useState } from 'react'
import DayPicker from 'react-day-picker'
import { SaveIcon } from '../../component/icons/Tint/24/General'
import { ExportHistoryIcon } from '@src/component/icons/Tint/16/General'
import { DateIcon } from '@src/component/icons/Tint/16/ContactInfo'
import Button from '../../component/button'
import { MenuItem, Popover } from '../../component/menu'
import Tooltip from '../../component/tooltip'
import { useAppStore } from '@src/app/context'
import { CalendarIcon, FilterIcon } from '../../component/icons/custom'
import {
  getDatePickerLabel,
  getExportTypeLabel,
  useDateFilter,
  useHandlePopoverOption,
} from './use-date-filter'
import { observer } from 'mobx-react-lite'
import IconButton from '@src/component/icon-button'
import classNames from 'classnames'
import { ReportExport } from '@src/service/transport/report'
import { CircularProgress } from '@src/component/progress'
import { ScrollView } from '@src/component/scrollview'
import { friendlyDate } from '@src/lib'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'

const DateFilter = function () {
  const styles = useStyles({})
  const { command, service, analytics, showAlert } = useAppStore()
  const user = service.user.current.asMember

  const handleSelectPhoneNumbers = function () {
    if (user.isAdmin) {
      command.present({
        name: 'pick org phone number',
        title: 'Pick phone numbers',
        subtitle: 'Select phone numbers you would like to filter by.',
        multi: true,
        initialSelectedIds: analytics.selectedPhoneNumbers,
        onSelect: (numbers) => {
          analytics.selectedPhoneNumbers = numbers.map((n) => n.id)
          command.hide()
        },
        onAdd: () => {
          command.hide()
        },
      })
    } else {
      command.present({
        name: 'pick user phone number',
        title: 'Pick phone numbers',
        subtitle: 'Select phone numbers you would like to filter by.',
        multi: true,
        initialSelectedIds: analytics.selectedPhoneNumbers,
        onSelect: (numbers) => {
          analytics.selectedPhoneNumbers = numbers.map((n) => n.id)
          command.hide()
        },
        onAdd: () => {
          command.hide()
        },
      })
    }
  }

  const handleExportClick = () => {
    command.present({
      name: 'report export',
    })
  }

  const {
    editingRange,
    datePickerButtonRef,
    datePickerEl,
    showDateRangePicker,
    handleDatePicker,
    handleDateRangePickerClose,
    handleDateRangeChange,
    handlePickCustomRange,
    handleDayClick,
    handleGranularityChange,
    dayPickerStyles,
    dayPickerClassNames,
  } = useDateFilter({
    range: analytics.range,
    onChange: ({ range, granularity }) => {
      analytics.range = range
      analytics.granularity = granularity
    },
  })

  const { handlePopoverOption } = useHandlePopoverOption({
    handleDateRangeChange,
    handlePickCustomRange,
    onOption: (option) => {
      analytics.exportType = option
    },
  })

  const { from, to } = editingRange.from ? editingRange : analytics.range

  const isExporting = analytics.isProcessingExport

  return (
    <div className={styles.root}>
      <div>
        <Button
          ref={datePickerButtonRef}
          variant="outlined"
          color="primary"
          startIcon={<CalendarIcon />}
          endIcon={<DownArrow />}
          onClick={handleDatePicker}
          style={{ marginRight: 16 }}
        >
          {getDatePickerLabel(analytics.range, analytics.exportType)}
        </Button>
        <Popover
          open={Boolean(datePickerEl)}
          onClose={handleDateRangePickerClose}
          anchorEl={datePickerEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          style={{ margin: '5px 0' }}
        >
          {!showDateRangePicker ? (
            <>
              <MenuItem onClick={() => handlePopoverOption('today')}>Today</MenuItem>
              <MenuItem onClick={() => handlePopoverOption('yesterday')}>
                Yesterday
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('last-7-days')}>
                Last 7 days
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('last-4-weeks')}>
                Last 4 weeks
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('last-3-months')}>
                Last 3 months
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('last-12-months')}>
                Last 12 months
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('month-to-date')}>
                Month to date
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('quarter-to-date')}>
                Quarter to date
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('year-to-date')}>
                Year to date
              </MenuItem>
              <MenuItem onClick={() => handlePopoverOption('custom')}>Custom</MenuItem>
            </>
          ) : (
            <DayPicker
              onDayClick={handleDayClick}
              selectedDays={[from, { from, to }]}
              initialMonth={from || new Date()}
              numberOfMonths={2}
              modifiers={{ [dayPickerStyles.start]: from, [dayPickerStyles.end]: to }}
              month={from}
              toMonth={new Date()}
              disabledDays={{ after: new Date() }}
              classNames={dayPickerClassNames}
            />
          )}
        </Popover>
        <Button
          variant="outlined"
          color="default"
          startIcon={<FilterIcon />}
          onClick={handleSelectPhoneNumbers}
        >
          {analytics.selectedPhoneNumbers.length}{' '}
          {analytics.selectedPhoneNumbers.length === 1 ? 'phone number' : 'phone numbers'}{' '}
          selected
        </Button>
      </div>
      <div className={styles.rightSideButtons}>
        {analytics.exports.length > 0 && <ExportHistory />}

        <Tooltip
          title={
            isExporting
              ? `Your CSV export has been queued and will be emailed to ${user.email} once complete`
              : 'Download data as CSV'
          }
          placement="bottom"
        >
          <span style={{ display: 'inline-block', marginRight: 16 }}>
            <Button
              variant="outlined"
              color="default"
              startIcon={isExporting ? undefined : <SaveIcon />}
              onClick={handleExportClick}
              disabled={isExporting}
            >
              {isExporting ? 'Exporting...' : 'Export'}
            </Button>
          </span>
        </Tooltip>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={analytics.granularity}
          exclusive
          onChange={handleGranularityChange}
        >
          <ToggleButton value="day">Day</ToggleButton>
          <ToggleButton value="week">Week</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}

export default observer(DateFilter)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    border: `1.5px solid ${theme.palette.op.border.common}`,
    borderRadius: 5,
    margin: 10,
    width: 'auto',
  },
  pn: {
    display: 'flex',
    alignItems: 'center',
  },
  pnName: {
    flex: '0 0 100px',
    marginRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.85rem',
  },
  pnNumber: {
    color: theme.palette.text.secondary,
    fontSize: '0.80rem',
  },
  rightSideButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ToggleButton = withStyles((theme) => ({
  root: {
    padding: '6px 15px',
  },
}))(MuiToggleButton)

export const ExportHistory = observer(() => {
  const styles = useExportHistoryStyles()
  const { analytics, service, showAlert } = useAppStore()
  const user = service.user.current.asMember

  const [showExportHistory, setShowExportHistory] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [clearExports, runClearExports] = useStatefulPromise(() =>
    analytics.clearUserExports(),
  )

  const isLoading = clearExports.status === PromiseStateStatus.Loading

  return (
    <>
      <IconButton
        icon={<ExportHistoryIcon />}
        ref={buttonRef}
        className={styles.exportHistoryButton}
        onClick={() => {
          setAnchorEl(buttonRef.current)
          setShowExportHistory(true)
        }}
      />

      <Popover
        open={showExportHistory}
        onClose={() => setShowExportHistory(false)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className={styles.popover}>
          <div className={styles.popoverItem}>
            <div className={styles.title}>Export history</div>

            <div className={styles.itemButtonContainer}>
              <Button
                variant="text"
                className={styles.itemButton}
                onClick={() => runClearExports()}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={16} color="primary" /> : 'Clear all'}
              </Button>
            </div>
          </div>

          <ScrollView className={styles.scrollView}>
            {analytics.exports.map((item) => (
              <ExportItem
                item={item}
                key={item.id}
                onResendCompleted={() => {
                  showAlert({
                    title: 'Export queued',
                    body: `Your CSV export has been queued and will be emailed to ${user.email} once complete`,
                  })

                  setShowExportHistory(false)
                }}
              />
            ))}
          </ScrollView>
        </div>
      </Popover>
    </>
  )
})

ExportHistory.displayName = 'ExportHistory'

interface ExportItemProps {
  item: ReportExport
  onResendCompleted: () => void
}

const ExportItem = observer(({ item, onResendCompleted }: ExportItemProps) => {
  const styles = useExportHistoryStyles()
  const { service } = useAppStore()

  const range = {
    from: new Date(item.query.from),
    to: new Date(item.query.to),
  }

  const [csvExport, runExport] = useStatefulPromise(() =>
    service.transport.report.export
      .queue(item.query.phoneNumberIds, range, item.query.exportType)
      .then(onResendCompleted),
  )

  const exportType = item.query.exportType ?? 'custom'
  const typeText = getExportTypeLabel(exportType)

  const isLoading = csvExport.status === PromiseStateStatus.Loading

  return (
    <div className={styles.popoverItem}>
      <div className={styles.title}>
        <DateIcon />

        <span className={styles.typeText}>{typeText}</span>

        <span className={styles.dateRange}>
          {friendlyDate(range.from)} - {friendlyDate(range.to)}
        </span>
      </div>

      <div className={styles.itemButtonContainer}>
        <Button
          variant="text"
          className={classNames(styles.itemButton, styles.resendButton)}
          color="primary"
          onClick={() => runExport()}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={16} color="primary" /> : 'Resend'}
        </Button>
      </div>
    </div>
  )
})

const useExportHistoryStyles = makeStyles((theme) => ({
  exportHistoryButton: {
    marginRight: 4,
  },
  popover: {
    padding: 4,
    minWidth: 366,
  },
  popoverItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 88px',
    height: 30,
    borderRadius: 5,
    padding: '7px 10px',

    '&:hover': {
      backgroundColor: theme.palette.op.hover.primary,
    },

    '&:not(:first-child)': {
      marginTop: 2,
    },
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '16px 100px 1fr',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: '16px',
    color: theme.palette.op.gray[3],
    height: 16,
    whiteSpace: 'nowrap',
  },
  typeText: {
    marginLeft: 10,
    fontWeight: 550,
  },
  dateRange: {
    marginLeft: 20,
    fontWeight: 450,
    color: theme.palette.op.gray[2],
  },
  itemButtonContainer: {
    margin: '-7px -10px',
    height: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemButton: {
    padding: 0,
    height: 30,

    '&:hover:not(:active)': {
      backgroundColor: 'transparent',
    },
  },
  resendButton: {
    color: theme.palette.op.primary[2],
    width: 64,
  },
  clearAllButton: {
    color: theme.palette.op.match({
      light: theme.palette.op.text.primary,
      dark: theme.palette.op.white,
    }),
  },
  scrollView: {
    maxHeight: 300,
  },
}))
