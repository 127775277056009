// @ts-strict-ignore
import Debug from 'debug'
import { action, makeObservable, computed, observable, reaction } from 'mobx'
import React, { createRef } from 'react'
import { v4 as uuid } from 'uuid'
import { IReactionGroup } from '../../component/reactions'
import {
  DecodableMessageMedia,
  Identity,
  ImmutableCollection,
  Member,
  MessageMedia,
  Reaction,
  User,
} from '../../service/model'
import { last } from '../../lib/collections'
import { hours, minutes } from '../../lib/date'
import { isEmojiOnlyString, stripSkinVariation } from '../../lib/emoji'
import shortId from '../../lib/short-id'
import { DisposeBag } from '../../lib/dispose'
import { fileType } from '../../lib/file'
import { throttle } from '../../lib/throttle'
import { EmojiPickerProps } from '../emoji-picker'
import { VirtualListStore, VirtualListItem } from '../virtual-list/store'
import { getDefaultItemDimensions } from './dimensions'

export interface ItemSource {
  readonly beforeId?: string | null
  readonly afterId?: string | null
  readonly id: string
  readonly type: ItemType
  readonly body: string | null
  readonly transcript?: string | null
  readonly createdAt: number
  readonly media: readonly MessageMedia[] | null
  readonly reactions: readonly Reaction[]
}

export type ItemType = 'message' | 'call' | 'voicemail' | 'loading'

export interface BaseItemPart {
  key?: string
  position?: 'top' | 'middle' | 'bottom' | 'single'
  showActionsMenu?: boolean
  showSenderName?: boolean
  showSenderPhoto?: boolean
}

export interface TextItemPart extends BaseItemPart {
  type: 'text'
  text: string
}

export interface AudioItemPart extends BaseItemPart {
  type: 'audio'
  audio: MessageMedia
}

export interface ContactItemPart extends BaseItemPart {
  type: 'contact'
  contact: MessageMedia
}

export interface FileItemPart extends BaseItemPart {
  type: 'file'
  file: MessageMedia
}

export interface ImagesItemPart extends BaseItemPart {
  type: 'images'
  images: MessageMedia[]
}

export interface VideosItemPart extends BaseItemPart {
  type: 'videos'
  videos: MessageMedia[]
}

export type FeedItemIconType = 'call-default' | 'call-missed' | 'call-answered'
export interface CallItemPart extends BaseItemPart {
  type: 'call'
  iconType: FeedItemIconType
  title: string
  subtitle: string
  duration: number | null
  transcript: string | null
  voicemail?: DecodableMessageMedia
  recordings?: DecodableMessageMedia[]
}

export type MediaItemPart =
  | AudioItemPart
  | ContactItemPart
  | FileItemPart
  | ImagesItemPart
  | VideosItemPart

export type ItemPart = MediaItemPart | TextItemPart | CallItemPart
export type ItemPartType = ItemPart['type']
export type MediaItemPartType = MediaItemPart['type']
export type MappedMedia = { [T in MediaItemPartType]: MessageMedia[] }

export interface ItemStatusError {
  type: 'error'
  text: string
  details: string
  canRetry?: boolean
}

export interface ItemStatusInfo {
  type: 'info'
  text: string
}

export type ItemStatus = ItemStatusError | ItemStatusInfo

export class ItemDisplay {
  hovered = false

  readonly dimensions = getDefaultItemDimensions({})
  readonly fullWidth: boolean = false
  readonly showTimestampAsPopover: boolean = false

  constructor(readonly item: Item) {
    makeObservable(this, {
      hovered: observable.ref,
      sourceAppearance: computed,
      highlighted: computed,
      showTimestampHeader: computed,
      showStatus: computed,
      isEmojiMessage: computed,
      estimatedHeight: computed,
    })
  }

  get sourceAppearance(): 'direct' | 'indirect' {
    return this.item.source.type === 'message' ? 'direct' : 'indirect'
  }

  get highlighted() {
    return false
  }

  get showStatus() {
    return Boolean(this.item.status)
  }

  get showTimestampHeader() {
    const { prev } = this.item
    return !prev || this.item.source.createdAt - prev.source.createdAt > hours(4)
  }

  get isEmojiMessage() {
    return this.item.isMessageWithBody ? isEmojiOnlyString(this.item.source.body) : false
  }

  get estimatedHeight() {
    let height = this.item.parts.reduce((final, i) => {
      if (i.type === 'audio') {
        final += 88
      } else if (i.type == 'call' || i.type == 'text') {
        final += 32
      } else if (i.type == 'images' || i.type == 'videos') {
        final += 250
      } else {
        final += 40
      }
      if (i.position === 'single') {
        final += 10
      } else if (i.position === 'top' || i.position === 'bottom') {
        final += 5
      }
      if (i.showSenderName) {
        final += 32
      }
      return final
    }, 0)

    if (this.showStatus) {
      height += 32
    }
    if (this.item.hasReactions) {
      height += 18
    }
    if (this.item.hasThread) {
      // TODO: could be better estimated
      height += 50
    }
    if (this.showTimestampHeader) {
      height += 99
    }
    return height
  }
}

export abstract class Item implements VirtualListItem {
  abstract currentUser: User
  abstract source: ItemSource
  abstract sender: Identity | null
  abstract senderId: string
  abstract member: Member | null
  abstract isOutgoing: boolean
  abstract isInternal: boolean
  abstract display: ItemDisplay
  abstract status: ItemStatus | null

  next: Item = null
  prev: Item = null

  readonly menu = new ActionsMenu(this)

  protected _mouseMoveTimer: number | null = null
  protected _mouseLeaveTimer: number | null = null
  protected _mouseLeaveRecent = false

  constructor() {
    makeObservable(this, {
      next: observable.ref,
      prev: observable.ref,
      memberIsMe: computed,
      sortWeight: computed,
      hasMedia: computed,
      hasReactions: computed,
      hasThread: computed,
      isUnread: computed,
      mappedMedia: computed,
      parts: computed,
      reactions: computed,
      isMessageWithBody: computed,
      copyableText: computed,
      handleBubbleMouseMove: action.bound,
      handleBubbleMouseEnter: action.bound,
      handleBubbleMouseLeave: action.bound,
    })
  }

  get id() {
    return this.source.id
  }

  get memberIsMe() {
    return this.member?.id == this.currentUser.id
  }

  get sortWeight() {
    return this.source.createdAt
  }

  get hasMedia() {
    return this.source.media?.length > 0
  }

  get hasReactions() {
    return this.source.reactions.length > 0
  }

  get hasThread() {
    return false
  }

  get isUnread() {
    // TODO
    return false
  }

  get isMessageWithBody() {
    return Boolean(this.source.type === 'message' && this.source.body)
  }

  get copyableText(): string | null {
    return this.isMessageWithBody ? this.source.body : this.callPart?.transcript
  }

  get mappedMedia(): MappedMedia {
    const media = this.source.media ?? []
    return media.reduce(
      (acc, m) => {
        const type = this.getMediaItemPartType(m)
        acc[type].push(m)
        return acc
      },
      { images: [], videos: [], audio: [], contact: [], file: [] },
    )
  }

  get parts(): ItemPart[] {
    const parts = this.createParts()
    this.augmentParts(parts)
    return parts
  }

  get callPart(): CallItemPart | null {
    return this.parts.find((part): part is CallItemPart => part.type === 'call') ?? null
  }

  get reactions(): IReactionGroup[] {
    const currentUserId = this.currentUser.id
    const groups = this.source.reactions.reduce((acc, value) => {
      const emoji = stripSkinVariation(value.body)
      const group = acc.get(emoji) ?? { variations: new Set(), reactions: [] }
      acc.set(emoji, {
        variations: new Set([...group.variations, value.body]),
        reactions: [...group.reactions, value],
      })
      return acc
    }, new Map<string, { variations: Set<string>; reactions: Reaction[] }>())

    return [...groups.entries()].map(([emoji, group]) => ({
      emoji,
      variations: [...group.variations],
      count: group.reactions.length,
      currentUserReacted: Boolean(
        group.reactions.find((r) => r.userId === currentUserId),
      ),
    }))
  }

  abstract handleThreadOpen(): void
  abstract handleReaction(body: string): void
  abstract handleCopy(): void
  abstract handleRetrySend(): void
  abstract handleDelete(): void
  abstract handleDeleteCallRecording(media: DecodableMessageMedia): void

  /**
   * Called when the `sender` of this feed item is selected.
   *
   * This can happen when the user clicks on an avatar within the feed.
   */
  abstract handleSenderSelected(): void

  protected abstract createCallParts(): CallItemPart[]

  handleBubbleMouseMove(event: React.MouseEvent) {
    this._handleBubbleMouseMove(event)
  }

  handleBubbleMouseEnter(event: React.MouseEvent) {
    this.display.hovered = true
    if (this._mouseLeaveRecent) {
      this.menu.open = true
    }
  }

  handleBubbleMouseLeave(event: React.MouseEvent) {
    this.display.hovered = false
    this.menu.open = false
    clearTimeout(this._mouseMoveTimer)
    this._mouseLeaveRecent = true
    this._mouseLeaveTimer = window.setTimeout(() => {
      this._mouseLeaveRecent = false
    }, this.menu.closeDelay)
  }

  protected _handleBubbleMouseMove = throttle(
    action('BubbleMouseMove', (event: React.MouseEvent) => {
      clearTimeout(this._mouseMoveTimer)
      if (this.menu.open) return
      this._mouseMoveTimer = window.setTimeout(
        action('BubbleMouseMoveTimeout', () => {
          this.menu.open = true
        }),
        100,
      )
    }),
    50,
    { trailing: false },
  )

  protected createParts(): ItemPart[] {
    return [
      ...this.createMediaParts(),
      ...this.createCallParts(),
      ...this.createTextParts(),
    ]
  }

  protected createMediaParts(): MediaItemPart[] {
    const { images, videos, audio, contact, file } = this.mappedMedia
    const parts: MediaItemPart[] = []
    if (this.source.type === 'message') {
      if (images.length > 0) parts.push({ type: 'images', images })
      if (videos.length > 0) parts.push({ type: 'videos', videos })
      parts.push(
        ...audio.map((audio): AudioItemPart => ({ type: 'audio', audio })),
        ...contact.map((contact): ContactItemPart => ({ type: 'contact', contact })),
        ...file.map((file): FileItemPart => ({ type: 'file', file })),
      )
    }

    return parts
  }

  protected createTextParts(): TextItemPart[] {
    return this.source.body ? [{ type: 'text', text: this.source.body }] : []
  }

  protected augmentParts(parts: ItemPart[]) {
    parts.forEach((part, index, array) => {
      part.key = `${this.id}-${index}`
      const hasPrev = Boolean(array[index - 1]) || Boolean(this.prev)
      const hasNext = Boolean(array[index + 1]) || Boolean(this.next)
      const prevPart = array[index - 1]
      const nextPart = array[index + 1]

      const lastSender = prevPart ? this.senderId : this.prev?.senderId
      const thisSender = this.senderId
      const nextSender = nextPart ? this.senderId : this.next?.senderId

      const lastTime = prevPart ? this.source.createdAt : this.prev?.source.createdAt
      const thisTime = this.source.createdAt
      const nextTime = nextPart ? this.source.createdAt : this.next?.source.createdAt

      const top =
        index === 0 &&
        (!hasPrev ||
          this.prev?.display.isEmojiMessage ||
          this.prev?.display.showStatus ||
          this.prev?.hasReactions ||
          this.prev?.hasThread ||
          this.display.showTimestampHeader ||
          this.hasThread ||
          lastSender != thisSender ||
          thisTime - lastTime > minutes(5))

      const bottom =
        index === parts.length - 1 &&
        (!hasNext ||
          this.next?.display.isEmojiMessage ||
          this.next?.display.showTimestampHeader ||
          this.next?.hasThread ||
          this.display.showStatus ||
          this.hasReactions ||
          this.hasThread ||
          thisSender != nextSender ||
          nextTime - thisTime > minutes(5))

      part.showSenderName =
        top &&
        (lastSender != thisSender ||
          this.display.showTimestampHeader ||
          this.prev?.display.showStatus)

      part.showSenderPhoto =
        bottom &&
        (this.hasThread ||
          nextSender != thisSender ||
          this.display.showStatus ||
          this.next?.hasThread ||
          this.next?.display.showTimestampHeader)

      if (top && bottom) {
        part.position = 'single'
      } else if (top) {
        part.position = 'top'
      } else if (bottom) {
        part.position = 'bottom'
      } else {
        part.position = 'middle'
      }
    })
    const lastPart = last(parts)
    if (lastPart) lastPart.showActionsMenu = true
  }

  protected getMediaItemPartType(media: MessageMedia): MediaItemPartType {
    const type = fileType(media.type, media.name)

    switch (type) {
      case 'image':
        return 'images'
      case 'video':
        return 'videos'
      case 'audio':
      case 'contact':
        return type
      default:
        return 'file'
    }
  }
}

export class ActionsMenu {
  readonly openDelay = 250 // ms
  readonly closeDelay = 500 // ms

  open = false

  constructor(readonly item: Item) {
    makeObservable(this, {
      open: observable.ref,
      close: action.bound,
    })
  }

  close() {
    this.open = false
  }
}

export interface FeedFeatures {
  threads: boolean
}

export default abstract class Controller<I extends Item> {
  abstract features: FeedFeatures
  abstract items: ImmutableCollection<I>

  /**
   * The display strategy to take when rendering the list of items.
   */
  abstract strategy: 'static' | 'virtual'

  readonly id = shortId()
  readonly debug = Debug(`op:feed:@${this.id}`)
  readonly disposeBag = new DisposeBag()
  readonly listRef: React.MutableRefObject<VirtualListStore<I>> = createRef()

  constructor() {
    makeObservable(this, {
      itemEstimatedHeight: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.items.list,
        () => this.setupCollection(),
        { name: 'ItemsChange', fireImmediately: true },
      ),
    )
  }

  abstract openMedia(media: MessageMedia): void
  abstract openEmojiPicker(props: Omit<EmojiPickerProps, 'open'>): void

  abstract fetchBefore(item: I): Promise<void>
  abstract fetchAfter(item: I): Promise<void>
  abstract loadMore(): void

  itemEstimatedHeight(item: I): number {
    return item.display.estimatedHeight
  }

  scrollToBottom() {
    this.listRef.current?.scrollToBottom()
  }

  scrollToIndex(index: number) {
    this.listRef.current?.scrollToIndex(index)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  protected setupCollection() {
    this.linkItems()
  }

  protected linkItems() {
    this.items.list.forEach((item, index) => {
      item.prev = this.items.list[index - 1]
      if (item.prev) {
        item.prev.next = item
      }
    })
  }
}
