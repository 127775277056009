import React, { forwardRef } from 'react'
import type { CSSProperties } from '@material-ui/core/styles/withStyles'
import { type Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

// Assets
const defaultDarkUrl = require('./assets/default-dark.svg').default
const defaultLightUrl = require('./assets/default-light.svg').default

const badgeMask = {
  small: require('./assets/badge-sm.svg').default,
  default: require('./assets/badge.svg').default,
}

const gradients = [
  require('./assets/gradient-0.svg').default,
  require('./assets/gradient-1.svg').default,
  require('./assets/gradient-2.svg').default,
  require('./assets/gradient-3.svg').default,
  require('./assets/gradient-4.svg').default,
  require('./assets/gradient-5.svg').default,
]

const mask = {
  tiny: require('./assets/mask-tiny.svg').default,
  small: require('./assets/mask-small.svg').default,
  medium: require('./assets/mask-medium.svg').default,
  large: require('./assets/mask-large.svg').default,
}

const status = {
  busy: require('./assets/busy.svg').default,
  active: require('./assets/active.svg').default,
  inactive: require('./assets/inactive.svg').default,
  activeSnoozed: require('./assets/active-snoozed.svg').default,
  activeSnoozedSmall: require('./assets/active-snoozed-small.svg').default,
  inactiveSnoozed: require('./assets/inactive-snoozed.svg').default,
  inactiveSnoozedSmall: require('./assets/inactive-snoozed-small.svg').default,
}

export type AvatarSize = 80 | 40 | 36 | 34 | 30 | 26 | 24 | 22 | 20 | 16

export interface AvatarProps {
  className?: string
  url?: string
  initials?: string
  gradientId?: string

  /**
   * The size of the avatar.
   */
  size?: AvatarSize

  /**
   * A URL to an icon that, if provided, is placed over any status indicator.
   *
   * If provided, a mask is automatically applied to the avatar.
   */
  badge?: string
  status?: 'activeSnoozed' | 'active' | 'inactive' | 'inactiveSnoozed' | 'busy'
  label?: string
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, size = 26, label, url, initials, status, badge, gradientId }, ref) => {
    const theme = useTheme()
    const styles = useStyles({ badge, size })

    const gradientUrl = initials && getGradientUrl(gradientId)

    const backgroundImageUrl =
      url ||
      gradientUrl ||
      theme.palette.op.match({
        dark: defaultDarkUrl,
        light: defaultLightUrl,
      })

    const showInitials = !url && gradientUrl

    return (
      <div
        ref={ref}
        role="img"
        aria-label={label}
        aria-hidden={!label}
        className={classNames(styles.root, className)}
      >
        <div
          className={classNames(styles.circle, {
            [styles.statusMask]: status,
            [styles.badgeMask]: badge,
          })}
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          {showInitials && initials}
        </div>

        {badge ? (
          <div className={styles.badge} />
        ) : status ? (
          <div className={classNames(styles.status, styles[status])} />
        ) : null}
      </div>
    )
  },
)

export default Avatar

const useStyles = makeStyles<
  Theme,
  Pick<Required<AvatarProps>, 'size'> & Pick<AvatarProps, 'badge'>
>((theme) => ({
  root: {
    flex: '0 0 auto',
    width: 'fit-content',
    position: 'relative',
    transition: theme.transitions.create(['opacity'], { duration: 192 }),
  },
  circle: ({ size }) => {
    return {
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.op.background.highlight(0.2),
      backgroundPosition: 'center center',
      color: theme.palette.op.white,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maskSize: 'contain',
      width: size,
      height: size,
      fontSize: getAvatarCssFontSize(size),
    }
  },
  statusMask: ({ size }) => ({
    maskImage: getAvatarStatusMaskUrl(size),
    borderRadius: 'unset',
  }),
  status: ({ size }) => ({
    ...getAvatarStatusStyles(size),
    backgroundSize: 'cover',
    backgroundPosition: 'left top',
    position: 'absolute',
    width: 12,
    height: 11,
  }),
  badge: ({ badge, size }) => ({
    ...getAvatarBadgeCssStyles(size),
    backgroundImage: `url(${badge})`,
    backgroundSize: 'cover',
    position: 'absolute',
  }),
  badgeMask: ({ size }) => ({
    maskImage: size < 34 ? `url('${badgeMask.small}')` : `url('${badgeMask.default}')`,
    borderRadius: 'unset',
  }),
  busy: { backgroundImage: `url('${status.busy}')` },
  active: { backgroundImage: `url('${status.active}')` },
  inactive: { backgroundImage: `url('${status.inactive}')` },
  activeSnoozed: {
    backgroundImage: `url('${status.activeSnoozed}')`,
  },
  inactiveSnoozed: {
    backgroundImage: `url('${status.inactiveSnoozed}')`,
  },
}))

export function getAvatarCssFontSize(size: AvatarSize): number {
  switch (size) {
    case 80:
      return 28

    case 40:
    case 36:
    case 34:
    case 30:
      return 14

    case 26:
    case 24:
      return 10

    case 22:
    case 20:
    case 16:
      return 6

    default:
      return 10
  }
}

function getAvatarBadgeCssStyles(size: AvatarSize): CSSProperties {
  switch (size) {
    case 80:
      return { width: 24, height: 24, bottom: 0, right: -2 }
    case 40:
    case 36:
    case 34:
      return {
        width: 14,
        height: 14,
        bottom: -2,
        right: -2,
      }
    case 30:
    case 26:
    case 24:
      return { width: 12, height: 12, bottom: 0, right: -2 }
    case 22:
    case 20:
    case 16:
      return { width: 11, height: 11, bottom: 0, right: -2 }
    default:
      return {}
  }
}

function getAvatarStatusStyles(size: AvatarSize): CSSProperties {
  switch (size) {
    case 80:
      return { transform: 'scale(1.75)', right: 2, bottom: 1 }
    case 40:
    case 36:
    case 34:
      return { right: -3, bottom: -3 }
    case 30:
      return { transform: 'scale(0.90)', right: -4, bottom: -4 }
    case 26:
    case 24:
      return { transform: 'scale(0.80)', right: -4, bottom: -3 }
    case 22:
    case 20:
    case 16:
      return { transform: 'scale(0.80)', right: -5, bottom: -4 }
    default:
      return {}
  }
}

function getAvatarStatusMaskUrl(size: AvatarSize): string {
  switch (size) {
    case 80:
      return `url('${mask.large}')`
    case 40:
    case 36:
    case 34:
    case 30:
      return `url('${mask.medium}')`
    case 26:
    case 24:
      return `url('${mask.small}')`
    case 22:
    case 20:
    case 16:
      return `url('${mask.tiny}')`
  }
}

function getGradientUrl(id?: string): string | null {
  if (!id) {
    return null
  }

  let sum = 0

  for (let i = 0; i < id.length; i++) {
    sum += id.charCodeAt(i)
  }

  return gradients[sum % gradients.length]
}
