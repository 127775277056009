// @ts-strict-ignore
import Transport from '.'
import config from '../../config'
import { toQueryString } from '../../lib'
import {
  Application,
  AvailablePhoneNumber,
  Invite,
  Organization,
  Paginated,
  PhoneNumber,
  PhoneNumberSettings,
  Recording,
  Role,
  RoleName,
  SearchPhoneNumberParams,
  SharedUserRole,
} from '../../types'
import {
  AcceptedReferral,
  AlertAssociations,
  CodablePhoneNumber,
  DecodableAlert,
  IBlocklist,
  IPresence,
  IUser,
  Presence,
  ReferralCode,
  IUserSettings,
  GroupModel,
  GroupMembership,
  SerializedMember,
} from '../model'
import { DomainModel } from '../model/DomainModel'
import { EntityPhoneNumber, IEntityPhoneNumber } from '../model/entity-phone-number'
import { HttpTransaction } from './transaction'

export default class AccountClient {
  admin = {
    phoneNumbers: (): Promise<IEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber` }),
      )
    },

    buyPhoneNumber: (
      number: string,
      assignTo?: string,
    ): Promise<IEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber`,
          body: { phoneNumber: { number, entityId: assignTo } },
        }),
      )
    },

    updatePhoneNumber: (
      phoneNumber: IEntityPhoneNumber,
    ): Promise<IEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumber.id}`,
          body: { phoneNumber },
        }),
      )
    },

    updatePhoneNumberSettings: (
      phoneNumberId: string,
      settings: PhoneNumberSettings,
    ): Promise<EntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/settings`,
          body: settings,
        }),
      )
    },

    addPhoneNumberUser: (
      phoneNumberId: string,
      userId: string,
      role: SharedUserRole,
    ): Promise<IEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/user`,
          body: { userId, role },
        }),
      )
    },

    removePhoneNumberUser: (
      phoneNumberId: string,
      userId: string,
    ): Promise<IEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/user`,
          body: { userId },
        }),
      )
    },

    reassignPhoneNumber: (
      phoneNumberId: string,
      ownerId: string,
    ): Promise<IEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/assign`,
          body: { ownerId },
        }),
      )
    },

    toggleInternational: (
      phoneNumberId: string,
      enabled: boolean,
    ): Promise<IEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}/international`,
          body: { enabled },
        }),
      )
    },

    deletePhoneNumber: (phoneNumberId: string): Promise<IEntityPhoneNumber> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}admin/phoneNumber/${phoneNumberId}`,
        }),
      )
    },

    entities: (): Promise<IEntityPhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}admin/entity` }),
      )
    },
  }

  alert = {
    paginated: (
      limit: number = 100,
    ): Promise<Paginated<DecodableAlert> & { associations: AlertAssociations }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          query: { limit },
        }),
      )
    },

    since: (since: Date): Promise<Paginated<DecodableAlert>> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          query: { since, includeDeleted: true, limit: 200 },
        }),
      )
    },

    read: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/read`,
          body: ids,
        }),
      )
    },

    unread: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/unread`,
          body: ids,
        }),
      )
    },

    open: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/opened`,
          body: ids,
        }),
      )
    },

    unopen: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}alert/unopened`,
          body: ids,
        }),
      )
    },

    delete: (ids: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}alert`,
          body: ids,
        }),
      )
    },
  }

  blocklist = {
    list: (): Promise<IBlocklist[]> => {
      return this.transport
        .queue(new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}blocklist` }))
        .then((a) => a.blocklist)
    },

    create: (phoneNumber: string): Promise<IBlocklist> => {
      return this.transport
        .queue(
          new HttpTransaction({
            method: 'post',
            url: `${config.ACCOUNT_SERVICE_URL}blocklist`,
            body: { phoneNumber },
          }),
        )
        .then((a) => a.blocklist)
    },

    delete: (id: string): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}blocklist/${id}`,
        }),
      )
    },
  }

  phoneNumbers = {
    list: (): Promise<PhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}phoneNumber` }),
      )
    },

    available: (filters: SearchPhoneNumberParams): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/available${toQueryString(
            filters,
          )}`,
        }),
      )
    },

    update: (phoneNumber: Partial<PhoneNumber>): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}`,
          body: { phoneNumber },
          retry: true,
        }),
      )
    },

    mute: (phoneNumber: Partial<PhoneNumber>): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}/mute`,
          body: { until: new Date(phoneNumber.mutedUntil) },
          retry: true,
        }),
      )
    },

    unmute: (phoneNumber: Partial<PhoneNumber>): Promise<AvailablePhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber/${phoneNumber.id}/mute`,
          retry: true,
        }),
      )
    },

    buy: (number: string, temporary: boolean): Promise<PhoneNumber[]> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}phoneNumber`,
          body: { phoneNumber: { number }, temporary },
        }),
      )
    },
  }

  invites = {
    get: (token: string): Promise<Invite> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}invite/${token}` }),
      )
    },

    accept: (token: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}invite/${token}/accept`,
        }),
      )
    },

    reject: (token: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}invite/${token}/reject`,
        }),
      )
    },

    list: (): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}invite` }),
      )
    },
  }

  organization = {
    list: (): Promise<Organization[]> => {
      // return this.transport.queue(
      //   new WebsocketTransaction({ type: 'Query', path: '/org/list' }),
      // )
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}org` }),
      )
    },

    get: (id: string): Promise<Organization> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}org/${id}` }),
      )
    },

    update: (org: Organization): Promise<Organization> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}org/${org.id}`,
          body: org,
        }),
      )
    },

    roles: (): Promise<Role[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}role` }),
      )
    },

    groups: {
      fetch: ({ includeDeleted = false }: { includeDeleted?: boolean }) => {
        return this.transport.queue(
          new HttpTransaction({
            url: `${config.ACCOUNT_SERVICE_URL}group?includeDeleted=${includeDeleted}`,
          }),
        )
      },

      create: (group: Partial<GroupModel>) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'put',
            url: `${config.ACCOUNT_SERVICE_URL}group`,
            body: group,
          }),
        )
      },
      update: (group: Pick<GroupModel, 'id'> & Partial<GroupModel>) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'put',
            url: `${config.ACCOUNT_SERVICE_URL}group/${group.id}`,
            body: group,
          }),
        )
      },

      delete: (id: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'delete',
            url: `${config.ACCOUNT_SERVICE_URL}group/${id}`,
          }),
        )
      },
      addUser: (groupId: string, userId: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'post',
            url: `${config.ACCOUNT_SERVICE_URL}group/${groupId}/user`,
            body: { userId, role: 'member' },
          }),
        )
      },
      deleteUser: (groupId: string, userId: string) => {
        return this.transport.queue(
          new HttpTransaction({
            method: 'delete',
            url: `${config.ACCOUNT_SERVICE_URL}group/${groupId}/user/${userId}`,
          }),
        )
      },
    },

    for: (orgId: string) => ({
      invites: {
        list: (): Promise<Invite[]> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite`,
            }),
          )
        },

        get: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}`,
            }),
          )
        },

        update: (
          id: string,
          body: { role: RoleName; phoneNumberId?: string },
        ): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'put',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${id}`,
              body,
            }),
          )
        },

        delete: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}`,
            }),
          )
        },

        send: (params: {
          email: string
          role: string
          phoneNumberId?: string
        }): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite`,
              body: params,
            }),
          )
        },

        sendBulk: (
          params: {
            email: string
            role: string
            phoneNumberId?: string
          }[],
        ): Promise<{ results: SerializedMember[] }> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/bulk`,
              body: { items: params },
            }),
          )
        },

        resend: (inviteId: string): Promise<Invite> => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/invite/${inviteId}/resend`,
            }),
          )
        },
      },

      domain: {
        add: (params: { verificationEmail: string }) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain`,
              body: params,
            }),
          )
        },

        fetch: (): Promise<{ results: DomainModel[] }> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain`,
            }),
          )
        },

        delete: (domainId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/${domainId}`,
            }),
          )
        },

        verify: (params: { email: string; code: string }) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/verify`,
              body: params,
            }),
          )
        },

        resendCode: (domainId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'post',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/domain/${domainId}/resendCode`,
            }),
          )
        },
      },

      presence: {
        list: (): Promise<Presence[]> => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/presence`,
            }),
          )
        },
      },

      member: {
        fetch: () => {
          return this.transport.queue(
            new HttpTransaction({
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/members`,
            }),
          )
        },

        delete: (memberId: string) => {
          return this.transport.queue(
            new HttpTransaction({
              method: 'delete',
              url: `${config.ACCOUNT_SERVICE_URL}org/${orgId}/member/${memberId}`,
            }),
          )
        },
      },
    }),
  }

  members = {
    list: (): Promise<SerializedMember[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}member` }),
      )
    },

    get: (id: string): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}member/${id}` }),
      )
    },

    put: (
      id: string,
      params: { role: RoleName; title?: string },
    ): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}member/${id}`,
          body: params,
        }),
      )
    },

    delete: (id: string): Promise<SerializedMember> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}member/${id}`,
        }),
      )
    },
  }

  presence = {
    get: (): Promise<Presence[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}presence`,
        }),
      )
    },

    put: (presence: Partial<IPresence>): Promise<Presence> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}presence`,
          method: 'put',
          body: {
            offlineUntil: presence.offlineUntil,
            status: presence.status,
            symbol: presence.symbol,
            text: presence.text,
          },
        }),
      )
    },

    doNotDisturb: (duration: number): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}availability`,
          method: 'post',
          body: { duration },
        }),
      )
    },

    clearDoNotDisturb: (): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}availability`,
          method: 'delete',
        }),
      )
    },
  }

  userSettings = {
    update: (settings: IUserSettings) => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}userSettings`,
          method: 'put',
          body: settings,
        }),
      )
    },
  }

  recording = {
    fetch: (): Promise<Recording[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}recording`,
        }),
      )
    },

    create: (recording: Partial<Recording>): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}recording`,
          body: recording,
        }),
      )
    },

    update: (recording: Recording): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.ACCOUNT_SERVICE_URL}recording/${recording.id}`,
          body: recording,
        }),
      )
    },

    delete: (recording: Recording): Promise<Recording> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.ACCOUNT_SERVICE_URL}recording/${recording.id}`,
        }),
      )
    },
  }

  application = {
    get: (clientId: string, redirectUrl: string): Promise<Application> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}application`,
          query: { client_id: clientId, redirect_url: redirectUrl },
        }),
      )
    },
  }

  referrals = {
    getCode: (): Promise<ReferralCode[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral`,
        }),
      )
    },

    getAcceptedReferrals: (): Promise<AcceptedReferral[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral/accepted`,
        }),
      )
    },

    accept: (code: string): Promise<AcceptedReferral> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.ACCOUNT_SERVICE_URL}referral/${code}/accept`,
          method: 'post',
        }),
      )
    },
  }

  workspace = {
    phoneNumbers: {
      list: (): Promise<CodablePhoneNumber[]> => {
        return this.transport.queue(
          new HttpTransaction({
            url: `${config.ACCOUNT_SERVICE_URL}workspace/phoneNumbers`,
          }),
        )
      },
    },
  }

  constructor(private transport: Transport) {}

  get = (): Promise<IUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account`,
      }),
    )
  }

  update = (user: Partial<IUser>): Promise<IUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account`,
        method: 'put',
        body: user,
        queue: user.id,
      }),
    )
  }

  verifyPhoneNumber = (phoneNumber: string): Promise<void> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/verify`,
        method: 'post',
        body: { phoneNumber },
        retry: false,
      }),
    )
  }

  checkPhoneNumberCode = (phoneNumber: string, code: string): Promise<IUser> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/verify/check`,
        method: 'post',
        body: { phoneNumber, code },
        retry: false,
      }),
    )
  }

  sendDownloadLink = (phoneNumber: string): Promise<any> => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/sendDownloadLink`,
        method: 'post',
        body: { phoneNumber },
        retry: false,
      }),
    )
  }

  associate = (data: {
    partnerKey: string
    firstName: string
    lastName: string
    email: string
  }) => {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.ACCOUNT_SERVICE_URL}account/associate`,
        method: 'post',
        body: data,
        retry: true,
      }),
    )
  }
}
