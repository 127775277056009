import React from 'react'
import cx from 'classnames'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import MuiTextareaAutosize, {
  TextareaAutosizeProps,
} from '@material-ui/core/TextareaAutosize'

interface TextareaAutoResizeProps extends TextareaAutosizeProps {
  ref?: React.Ref<HTMLTextAreaElement>
}

const TextareaAutosize: React.FC<TextareaAutoResizeProps> = React.forwardRef(function (
  { className, ...props },
  ref,
) {
  const styles = useStyles({})
  return (
    <MuiTextareaAutosize ref={ref} className={cx(className, styles.input)} {...props} />
  )
})

export default TextareaAutosize

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    background: theme.palette.op.gray[6],
    border: `1.5px solid ${alpha(theme.palette.op.gray[1], 0.2)}`,
    borderRadius: 5,
    boxSizing: 'border-box',
    color: theme.palette.op.text.primary,
    fontSize: '1rem',
    outline: 'none',
    padding: '0.75rem 1rem',
    position: 'relative',
    resize: 'none',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      easing: 'ease',
      duration: 192,
    }),

    '&::placeholder': {
      color: alpha(theme.palette.op.text.primary, 0.4),
    },

    '&:hover:not(:focus)': {
      border: `1.5px solid ${alpha(theme.palette.op.gray[1], 0.4)}`,
    },

    '&:focus': {
      border: `1.5px solid ${theme.palette.op.border.focus}`,
      boxShadow: `0 0 0 4px ${alpha(theme.palette.op.primary[2], 0.18)}`,
    },
  },
}))
