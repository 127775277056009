// @ts-strict-ignore
import React, { useState, ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'

interface KeyPadPopoverProps {
  rootRef: React.RefObject<HTMLDivElement>
  onKeyPressed: (digit: string) => void
}

const KeyPadPopover: React.FC<KeyPadPopoverProps> = function ({ rootRef, onKeyPressed }) {
  const styles = useStyles({})
  const [input, setInput] = useState('')

  useKeyboardShortcuts({
    name: 'dialer/keypad',
    node: rootRef.current,
    handler: (shortcut, event) => {
      if (keyMapping[shortcut]) {
        handleKey(keyMapping[shortcut])
        event.preventDefault()
      }
    },
  })

  const handleKey = (digit) => {
    setInput((i) => i + digit)
    onKeyPressed(digit)
  }

  return (
    <div className={styles.root}>
      <div className={styles.display}>
        <Typography variant="title3" color="primary" className={styles.title}>
          {input || <wbr />}
        </Typography>
      </div>
      <div className={styles.row}>
        <Key title="1" onClick={() => handleKey('1')} subtitle="" />
        <Key title="2" onClick={() => handleKey('2')} subtitle="ABC" />
        <Key title="3" onClick={() => handleKey('3')} subtitle="DEF" />
      </div>
      <div className={styles.row}>
        <Key title="4" onClick={() => handleKey('4')} subtitle="GHI" />
        <Key title="5" onClick={() => handleKey('5')} subtitle="JKL" />
        <Key title="6" onClick={() => handleKey('6')} subtitle="MNO" />
      </div>
      <div className={styles.row}>
        <Key title="7" onClick={() => handleKey('7')} subtitle="PQRS" />
        <Key title="8" onClick={() => handleKey('8')} subtitle="TUV" />
        <Key title="9" onClick={() => handleKey('9')} subtitle="WXYZ" />
      </div>
      <div className={styles.row}>
        <Key title="*" onClick={() => handleKey('*')} subtitle="" />
        <Key title="0" onClick={() => handleKey('0')} subtitle="+" />
        <Key title="#" onClick={() => handleKey('#')} subtitle="" />
      </div>
    </div>
  )
}

interface KeyProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  title: string
  subtitle: string
}

const Key: React.FC<KeyProps> = function ({ title, subtitle, className, ...props }) {
  const styles = useStyles({})
  return (
    <button {...props} className={cx(styles.key, className)}>
      <Typography
        component="div"
        variant="title3"
        color="textPrimary"
        className={styles.title}
      >
        {title}
      </Typography>
      <Typography
        component="div"
        variant="caption3"
        color="textTertiary"
        className={styles.subtitle}
      >
        {subtitle || <wbr />}
      </Typography>
    </button>
  )
}

export default KeyPadPopover

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  display: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px 0',

    '& $title': {
      fontSize: 24,
    },
  },
  row: {
    display: 'flex',
  },
  key: {
    flex: 1,
    cursor: 'pointer',
    padding: 6,
    border: 'none',
    outline: 'none',
    background: 'none',
    borderRadius: 5,
    transition: 'background 0.3s ease',

    '&:hover': {
      background: theme.palette.op.background.highlight(),
    },
  },
  title: {
    marginBottom: 4,
    fontSize: 20,
  },
  subtitle: {
    fontWeight: 550,
    letterSpacing: 3,
  },
}))

const keyMapping = {
  Digit0: '0',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Numpad0: '0',
  Numpad1: '1',
  Numpad2: '2',
  Numpad3: '3',
  Numpad4: '4',
  Numpad5: '5',
  Numpad6: '6',
  Numpad7: '7',
  Numpad8: '8',
  Numpad9: '9',
}
