import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import AudioPlayer from '../../../component/audio-player'
import FileIcon from '../../../component/file-icon'
import { fileType } from '../../../lib/file'
import { AudioItemPart, Item } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'

export type AudioMediaProps<I extends Item> = ItemPartProps<I, AudioItemPart>

const AudioMedia = function <I extends Item>({
  controller,
  item,
  audio,
  ...props
}: AudioMediaProps<I>) {
  const styles = useStyles({})
  const type = fileType(audio.type, audio.url)
  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props} className={styles.bubble}>
        <div className={styles.root}>
          <FileIcon type={type} className={styles.icon} />
          <div className={styles.body}>
            <div className={styles.name}>{audio.name ?? 'Unnamed'}</div>
          </div>
        </div>
        <div key={audio.url} className={styles.audioWrapper}>
          <AudioPlayer media={audio} className={styles.audio} />
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(AudioMedia)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      padding: 5,
    },
    icon: {
      margin: '0 7px',
    },
    body: {
      marginRight: 20,
    },
    name: {
      fontSize: '0.85rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    bubble: {
      userSelect: 'none',
      background: theme.palette.op.background.highlight(0.07),
      transition: theme.transitions.create(['background']),

      '&:hover': {
        background: theme.palette.op.background.highlight(0.15),
      },
    },
    audioWrapper: {
      height: 42,
      margin: 10,
      marginTop: 0,
      borderRadius: 10,
      background: theme.palette.op.background.highlight(0.05),
      userSelect: 'none',

      '& audio': {
        display: 'none',
      },
    },
    audio: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 5,
    },
  }),
  { name: AudioMedia.name },
)
