// @ts-strict-ignore
import Dexie from 'dexie'
import MainWorker from '../worker/main'
import Repository from '../worker/repository/base'
import AsyncStorage from './async'
import { createDatabase } from './indexeddb'
import SyncStorage from './sync'

export type StorageEngine = AsyncStorage | SyncStorage

export default class Storage {
  private indexedDb: Dexie

  constructor(private worker: MainWorker) {
    this.indexedDb = createDatabase()
  }

  async(classConstructor?: (data: any) => any): AsyncStorage {
    return new AsyncStorage(this.indexedDb.table('kv'), classConstructor)
  }

  sync<T = unknown>(): SyncStorage<T> {
    return new SyncStorage<T>()
  }

  async reset() {
    const auth = localStorage.getItem('AuthStore.session')
    localStorage.clear()
    localStorage.setItem('AuthStore.session', auth)
    if ('caches' in window) {
      const keys = await window.caches.keys()
      keys.forEach((key) => window.caches.delete(key))
    }
    return Promise.all(this.indexedDb.tables.map((t) => t.clear()))
  }

  async clearAll() {
    localStorage.clear()
    if ('caches' in window) {
      const keys = await window.caches.keys()
      keys.forEach((key) => window.caches.delete(key))
    }
    return this.indexedDb.delete().then(() => (this.indexedDb = createDatabase()))
  }

  exists(dbName: string): Promise<boolean> {
    return Dexie.exists(dbName)
  }

  delete(dbName: string): Promise<void> {
    return Dexie.delete(dbName)
  }

  table<T extends Repository>(collection: string): T {
    return this.worker.repo[collection]
  }
}
