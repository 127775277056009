import { BaseEditor, Editor } from 'slate'

export interface CustomEditor extends BaseEditor {}

export const withCustom = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor

  editor.isInline = (element) => (element.type === 'mention' ? true : isInline(element))
  editor.isVoid = (element) => (element.type === 'mention' ? true : isVoid(element))

  return editor
}
