// @ts-strict-ignore
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { DeepPopover } from '../../component/menu'
import { Char, Emoji, emojiSkinVariations, getEmojiChar } from '../../lib/emoji'
import useKeyStepper, { KeyMappings } from '../../lib/use-key-stepper'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { useAppStore } from '@src/app/context'

interface FitzpatrickScaleSelectorProps {
  emoji: Emoji
  onClose: () => void
  onSelect: (variation: Char) => void
}

const items = [undefined, ...emojiSkinVariations.map((variation) => variation.char)]

const FitzpatrickScaleSelector: React.FC<FitzpatrickScaleSelectorProps> = function ({
  emoji,
  onClose,
  onSelect,
}) {
  const styles = useStyles({})
  const [el, setEl] = useState<HTMLElement>(null)
  const { service } = useAppStore()

  useEffect(() => {
    if (!emoji) {
      setEl(null)
      return
    }
    setEl(document.getElementById(emoji.char))
  }, [emoji])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    keys: KeyMappings.HORIZONTAL,
    name: 'emoji-picker/fitzpatrick-scale-selector',
    handleSelect: (modifier) => {
      service.emoji.setSkinTone(modifier)

      onSelect(getEmojiChar(emoji, modifier))
    },
    deps: [emoji],
  })

  useKeyboardShortcuts({
    name: 'flitzpatrick-scale',
    node: document,
    handler: (shortcut, event) => {
      if (
        shortcut === 'Meta+ArrowUp' ||
        shortcut === 'ArrowUp' ||
        shortcut === 'ArrowDown' ||
        shortcut === 'Meta+ArrowDown' ||
        shortcut === 'Escape'
      ) {
        event.preventDefault()
      }
    },
  })

  return (
    <DeepPopover
      open={Boolean(el)}
      anchorEl={el}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={onClose}
    >
      {emoji && (
        <div className={styles.root}>
          {items.map((mod, index) => (
            <div
              {...getItemProps(index)}
              className={cx(styles.item, selectedIndex === index && styles.highlighted)}
              key={index}
            >
              {getEmojiChar(emoji, mod).char}
            </div>
          ))}
        </div>
      )}
    </DeepPopover>
  )
}

export default observer(FitzpatrickScaleSelector)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 220,
    display: 'flex',
    padding: 5,
  },
  item: {
    width: 35,
    height: 35,
    flex: '0 0 35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    borderRadius: 5,
    cursor: 'pointer',
  },
  highlighted: {
    background: theme.palette.op.background.highlight(0.25),
  },
}))
