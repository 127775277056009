// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import { NativeSelect } from '../../../component/select'
import Switch from '../../../component/switch'
import { languages } from '../../../lib/constants'
import { EntityPhoneNumber } from '../../../service/model'
import { AudioPlayer, Header, Item, Section } from '../common'

interface PhoneNumberVoicemailProps {
  phoneNumber: EntityPhoneNumber
}

const PhoneNumberVoicemail: React.FC<PhoneNumberVoicemailProps> = function ({
  phoneNumber,
}) {
  const { command, toast } = useAppStore()

  const transcriptEnabled = phoneNumber?.settings?.transcription?.enabled ?? true
  const filterProfanity = phoneNumber?.settings?.transcription?.profanityFilter ?? false
  const language = phoneNumber?.settings?.transcription?.languageCode ?? 'en-US'

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    phoneNumber.updateSettings({
      transcription: {
        ...phoneNumber.settings?.transcription,
        enabled: event.target.checked,
      },
    })
  }

  const handleProfanityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    phoneNumber.updateSettings({
      transcription: {
        ...phoneNumber.settings?.transcription,
        profanityFilter: event.target.checked,
      },
    })
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    phoneNumber.updateSettings({
      transcription: {
        ...phoneNumber.settings?.transcription,
        languageCode: event.target.value,
      },
    })
  }

  const handleChange = () => {
    command.present({
      name: 'set greeting',
      title: 'Create a voicemail greeting',
      description: 'This will be played to callers when you miss a call',
      defaultText: null,
      onComplete: (url) => {
        phoneNumber.setVoicemailUrl(url).catch(toast.showError)
        command.hide()
      },
    })
  }

  return (
    <Section>
      <Header title="Voicemail" subtitle="Customize this phone number’s voicemail" />
      <Item
        title="Voicemail audio"
        description="What is played when calls are missed"
        input={
          <Button variant="outlined" color="textPrimary" onClick={handleChange}>
            Change
          </Button>
        }
      >
        <AudioPlayer media={{ url: phoneNumber?.voicemailUrl, type: 'audio/mpeg' }} />
      </Item>
      <Item
        title="Transcribe voicemails"
        description="Use speech-to-text to transcribe voicemails"
        input={<Switch checked={transcriptEnabled} onChange={handleEnabledChange} />}
      />
      <Item
        title="Filter profanity"
        description="Remove profanity from voicemail transcriptions"
        input={<Switch checked={filterProfanity} onChange={handleProfanityChange} />}
      />
      <Item
        title="Transcript language"
        description="Determine the language for voicemail transcripts"
        input={
          <NativeSelect
            value={language}
            onChange={handleLanguageChange}
            style={{ maxWidth: 200 }}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </NativeSelect>
        }
      />
    </Section>
  )
}

export default observer(PhoneNumberVoicemail)
