import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ChevronLeftIcon } from '../../../component/icons/Tint/08'
import IconButton from '../../../component/icon-button'
import Typography from '@ui/Typography'
import { Call } from '../store'
import { OngoingCallMode } from '.'

interface CallHeaderProps {
  mode: OngoingCallMode
  call: Call
  handleBack(): void
}

const CallHeader: React.FC<CallHeaderProps> = function ({ mode, call, handleBack }) {
  const styles = useStyles({})
  return (
    <div className={styles.root}>
      {mode === 'transfer' ? (
        <>
          <IconButton
            icon={<ChevronLeftIcon />}
            size="small"
            className={styles.back}
            onClick={handleBack}
          />
          <Typography nowrap variant="caption1" fontWeight="regular">
            Transfer call
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="callout" className={styles.symbol}>
            {call.phoneNumber?.symbol}
          </Typography>
          <Typography nowrap variant="caption1" fontWeight="regular">
            {call.phoneNumber?.name}
          </Typography>
        </>
      )}
    </div>
  )
}

export default observer(CallHeader)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 12,
      padding: '9px 35px 9px 7px',
      boxShadow: `0 1.5px 0 0 ${theme.palette.op.border.common}`,
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
    },
    symbol: {
      marginRight: 6,
    },
    back: {
      marginRight: 5,
    },
  }),
  { name: CallHeader.name },
)
