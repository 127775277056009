import Transport from '.'
import config from '../../config'
import { CreateSlackAuthParams, IIntegration } from '../../types'
import { IContact, IIntegrationContact, Integration, LegacyIntegration } from '../model'
import { HttpTransaction } from './transaction'

export type HubspotCreateParams = {
  code: string
  redirectUri: string
  resourceIds: string[]
}

export default class IntegrationClient {
  constructor(private transport: Transport) {}

  getAll(): Promise<Integration[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.INTEGRATION_SERVICE_URL}integration`,
      }),
    )
  }

  getForPhoneNumber(phoneNumberId: string): Promise<IIntegration[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.COMMUNICATION_SERVICE_URL}integration/settings/${phoneNumberId}`,
      }),
    )
  }

  update(integration: Partial<Integration>): Promise<Integration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.INTEGRATION_SERVICE_URL}integration`,
        body: integration,
      }),
    )
  }

  createHubspot(body: HubspotCreateParams): Promise<IIntegration> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.INTEGRATION_SERVICE_URL}provider/hubspot`,
        body,
      }),
    )
  }

  delete(id: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.INTEGRATION_SERVICE_URL}integration/${id}`,
      }),
    )
  }

  person = {
    get: (
      identifiers: string[],
      integrationIds: string[],
    ): Promise<IIntegrationContact[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.INTEGRATION_SERVICE_URL}person`,
          query: {
            identifiers: identifiers.join(','),
            integrationIds: integrationIds.join(','),
          },
        }),
      )
    },

    create: (
      integration: Integration,
      contact: IContact,
    ): Promise<IIntegrationContact> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.INTEGRATION_SERVICE_URL}integration/${integration.id}/person`,
          body: contact,
        }),
      )
    },
  }

  legacy = {
    get: (phoneNumberId: string): Promise<LegacyIntegration[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${phoneNumberId}`,
        }),
      )
    },

    createSlack: (params: CreateSlackAuthParams): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/slack`,
          body: params,
        }),
      )
    },

    createEmail: (params: Partial<LegacyIntegration>): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/email`,
          body: params,
        }),
      )
    },

    update: ({
      id,
      ...settings
    }: Partial<LegacyIntegration>): Promise<LegacyIntegration> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${id}`,
          body: settings,
        }),
      )
    },

    delete: (settings: Partial<LegacyIntegration>): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/integration/settings/${settings.id}`,
        }),
      )
    },
  }
}
