import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export function useSize(
  el: React.MutableRefObject<HTMLElement>,
  onResize: (rect: DOMRect) => void,
  deps: any[],
) {
  useEffect(() => {
    if (!el.current) return
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        onResize(entry.target.getBoundingClientRect())
      }
    })
    resizeObserver.observe(el.current)
    return () => resizeObserver.unobserve(el.current)
  }, deps)
}
