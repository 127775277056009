import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import Button from '../../component/button-v2'
import Tooltip from '../../component/tooltip'
import Typography from '@ui/Typography'
import ErrorStore from './store'

interface ErrorViewProps {
  store: ErrorStore
}

const ErrorView: React.FC<ErrorViewProps> = function ({ store }) {
  const styles = useStyles({})
  const timeoutRef = useRef<any>(null)
  const [copyLabel, setCopyLabel] = useState('Click to copy')

  const handleCopy = () => {
    store.copyError()
    clearTimeout(timeoutRef.current)
    setCopyLabel('Copied!')
    timeoutRef.current = setTimeout(() => {
      setCopyLabel('Click to copy')
    }, 1000)
  }

  return (
    <div className={styles.root}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="largeTitle"
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          Oops...
        </Typography>
        <Typography
          variant="body"
          color="textSecondary"
          style={{ maxWidth: 350, textAlign: 'center' }}
        >
          Seems like something broke. We got a report of the problem. Reloading the app
          should restore your previous session.
        </Typography>
        <Tooltip title={copyLabel} placement="top">
          <div className={styles.error} onClick={handleCopy}>
            [{store.errorId}] {store.errorMessage}
          </div>
        </Tooltip>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={store.reload}
            style={{ marginRight: 10 }}
          >
            Reload
          </Button>
          <Button color="danger" variant="outlined" onClick={store.reset}>
            Reset all data and reload
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(ErrorView)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
  error: {
    fontFamily: 'monospace',
    padding: 10,
    marginTop: 20,
    border: `1.5px solid ${theme.palette.op.border.common}`,
    borderRadius: 5,
    width: 350,
    userSelect: 'none',
    cursor: 'pointer',

    '&:hover': {
      border: `1.5px solid ${theme.palette.op.primary[1]}`,
    },
  },
}))
