// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { AvailabilityHours } from '../../..'
import Button from '../../../component/button-v2'
import { NativeSelect } from '../../../component/select'
import Switch from '../../../component/switch'
import { defaultAvailability, timezones } from '../../../lib/constants'
import { EntityPhoneNumber } from '../../../service/model'
import { AudioPlayer, Header, Item, Section } from '../common'
import Schedule from '../schedule'

interface BusinessHoursProps {
  phoneNumber: EntityPhoneNumber
}

const BusinessHours: React.FC<BusinessHoursProps> = function ({ phoneNumber }) {
  const { toast, command } = useAppStore()
  const enabled = phoneNumber?.availabilityHours?.enabled ?? false

  const handleEnable = (event) => {
    phoneNumber
      .update({
        availabilityHours: {
          ...defaultAvailability,
          ...phoneNumber.availabilityHours,
          enabled: event.target.checked,
        },
      })
      .catch(toast.showError)
  }

  const handleTimezoneChange = (event) => {
    phoneNumber
      .update({
        availabilityHours: {
          enabled: phoneNumber.availabilityHours.enabled,
          timezone: event.target.value,
          schedule: phoneNumber.availabilityHours.schedule,
        },
      })
      .catch(toast.showError)
  }

  const handleScheduleChange = (availabilityHours: AvailabilityHours) => {
    phoneNumber.update({ availabilityHours }).catch(toast.showError)
  }

  const handleAwayVoicemail = () => {
    command.present({
      name: 'set greeting',
      title: 'Create an away voicemail greeting',
      description: 'This will be played to callers outside of your business hours',
      defaultText: null,
      onComplete: (url) => {
        command.hide()
        phoneNumber.setAwayVoicemailUrl(url).catch(toast.showError)
      },
    })
  }

  return (
    <Section>
      <Header
        title="Business hours"
        subtitle="Control how your phone number works at different times of day"
      />
      <Item
        title="Enable business hours"
        description="Enabling to set a custom schedule for your phone number"
        input={<Switch checked={enabled} onChange={handleEnable} />}
      />
      {enabled && (
        <>
          <Item
            title="Timezone"
            description="Set your timezone"
            input={
              <NativeSelect
                value={phoneNumber?.availabilityHours?.timezone}
                style={{ width: '100%' }}
                onChange={handleTimezoneChange}
              >
                {timezones.map((tz) => (
                  <option value={tz.name} key={tz.name}>
                    {tz.name}
                  </option>
                ))}
              </NativeSelect>
            }
          />
          <Item
            title="Schedule"
            description="Set the schedule. Calls within the hours are sent to the users assigned to this phone number and calls outside the hours are sent to voicemail."
          >
            <Schedule
              availability={phoneNumber?.availabilityHours}
              onChange={handleScheduleChange}
            />
          </Item>
          <Item
            title="After hour voicemail audio"
            description="What is played when calls are missed after business hours"
            input={
              <Button
                variant="outlined"
                color="textPrimary"
                onClick={handleAwayVoicemail}
              >
                Change
              </Button>
            }
          >
            <AudioPlayer
              media={{ url: phoneNumber?.awayVoicemailUrl, type: 'audio/mpeg' }}
            />
          </Item>
        </>
      )}
    </Section>
  )
}

export default observer(BusinessHours)
