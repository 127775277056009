// @ts-strict-ignore
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import InfoButton from './info-button'

interface HeatmapProps extends React.HTMLProps<HTMLDivElement> {
  axis: string[]
  values: { [key: string]: number[] }
  title: string
  info?: string
}

const Heatmap: React.FC<HeatmapProps> = function ({
  title,
  info,
  values,
  axis,
  className,
  ...props
}) {
  const styles = useStyles({})
  const theme = useTheme()

  const max = useMemo<number>(
    () => (values ? Math.max(...Object.values(values).map((v) => Math.max(...v))) : 0),
    [values],
  )

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.header}>
        <span className={styles.title}>
          {title}
          <InfoButton text={info} />
        </span>
      </div>
      <div key={nanoid()} className={styles.row}>
        <div className={cx(styles.col, styles.colLabel, styles.colCorner)} />
        {axis.map((v) => (
          <div key={nanoid()} className={cx(styles.col, styles.headerCol)}>
            {v}
          </div>
        ))}
      </div>
      {values &&
        Object.keys(values).map((key) => (
          <div key={nanoid()} className={styles.row}>
            <div className={cx(styles.col, styles.colLabel)}>{key}</div>
            {values[key].map((v) => (
              <div
                key={nanoid()}
                className={cx(styles.col, v === 0 && styles.faded)}
                style={{
                  backgroundColor: alpha(
                    theme.palette.op.legacy.heatmap.blue1,
                    1 - (max - v) / max,
                  ),
                }}
              >
                {v}
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}

export default Heatmap

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    padding: '1.5rem',
    background: theme.palette.op.background.highlight(0.02),
  },
  row: {
    display: 'flex',
  },
  title: {
    display: 'block',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    color: theme.palette.op.background.highlight(0.6),
  },
  col: {
    flex: 1,
    height: 40,
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
  },
  headerCol: {
    background: theme.palette.op.background.highlight(0.05),
    color: theme.palette.op.background.highlight(0.7),
  },
  colLabel: {
    textAlign: 'right',
    padding: '0 0.75rem',
    color: theme.palette.op.background.highlight(0.7),
    background: theme.palette.op.background.highlight(0.05),
    justifyContent: 'flex-end',
  },
  colCorner: {
    borderRight: 'none',
  },
  faded: {
    color: theme.palette.op.background.highlight(0.1),
  },
}))
