// @ts-strict-ignore
import { WindowEvent } from '@openphone/desktop-client'
import { action, makeObservable, observable } from 'mobx'
import { IUiStore } from '../..'
import { DisposeBag } from '../../lib/dispose'
import AppStore from '../store'

export class WindowsTitleBarController implements IUiStore {
  maximized = false
  minimized = false

  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    makeObservable<this, 'handleWindowEvent'>(this, {
      maximized: observable.ref,
      handleWindowEvent: action,
    })

    app.electron.on('window', this.handleWindowEvent)
    app.electron.window.isMaximized().then(action((max) => (this.maximized = max)))
    app.electron.window.isMinimized().then(action((min) => (this.minimized = min)))

    this.disposeBag.add(() => app.electron.off('window', this.handleWindowEvent))
  }

  maximize = () => {
    this.app.electron.window.maximize()
  }

  unmaximize = () => {
    this.app.electron.window.unmaximize()
  }

  minimize = () => {
    this.app.electron.window.minimize()
  }

  close = () => {
    this.app.electron.window.close()
  }

  tearDown = () => {
    this.disposeBag.dispose()
  }

  private handleWindowEvent = (event: WindowEvent) => {
    switch (event.type) {
      case 'maximize':
        this.maximized = true
        return
      case 'unmaximize':
        this.maximized = false
        return
      case 'minimize':
        this.minimized = true
        return
      case 'restore':
        this.minimized = false
        return
    }
  }
}
