// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import Mention from '../../mention'
import { DeleteIcon, ThreadCommentIcon } from '../../../component/icons/Tint/20/General'
import InputBar from '../../../component/input-bar'
import PopoverMenu, { MenuItem } from '../../../component/popover-menu'
import Typography from '@ui/Typography'
import { Contact, Note } from '../../../service/model'
import ContactNote from './note'

interface ContactNotesProps {
  contact: Contact
}

const ContactNotes: React.FC<ContactNotesProps> = function ({ contact }) {
  const ref = useRef<HTMLDivElement>(null)
  const styles = useStyles({})
  const [editingId, setEditingId] = useState(null)
  const { contacts, toast } = useAppStore()
  const [more, setMore] = useState<{ anchorEl: HTMLButtonElement; note: Note }>(null)
  const controller = contacts.contactInputBarRegistry.get(contact)

  const handleMore = (anchorEl: HTMLButtonElement, note: Note) => {
    setMore({ anchorEl, note })
  }

  const handleEdit = () => {
    setEditingId(more.note.id)
    setMore(null)
  }

  const handleDelete = () => {
    more.note.delete().catch(toast.showError)
    setMore(null)
  }

  const renderMention = useCallback((mention: string) => <Mention id={mention} />, [])

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.header}>
        <Typography variant="caption1" color="textSecondary" fontWeight="regular">
          Notes
        </Typography>
        <Typography
          variant="caption1"
          color="textTertiary"
          fontWeight="regular"
          style={{ marginLeft: 8 }}
        >
          {contact.notes?.length ?? 0}
        </Typography>
      </div>
      <PopoverMenu
        anchorEl={more?.anchorEl}
        open={Boolean(more)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMore(null)}
      >
        <MenuItem icon={<ThreadCommentIcon />} onClick={handleEdit}>
          Edit
        </MenuItem>
        <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
          Delete
        </MenuItem>
      </PopoverMenu>
      {contact.sortedNotes.map((note) => (
        <ContactNote
          key={note.id}
          note={note}
          editing={note.id === editingId}
          endEditing={() => setEditingId(null)}
          onMore={handleMore}
        />
      ))}
      <InputBar
        attachmentDropTargetRef={ref}
        controller={controller}
        colorScheme="note"
        placeholder="Write a note…"
        renderMention={renderMention}
        className={styles.input}
      />
    </div>
  )
}

export default observer(ContactNotes)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2rem 24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  input: {
    margin: '24px 0',
  },
}))
