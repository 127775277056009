const searchParamsWhitelist = [
  '_ga', // Google Analytics cross-domain tracking
  'gspk', // PartnerStack
  'gsxid', // Also PartnerStack
  'inviteCode',
  'state',
  'code',
  'at',
  'handlerUrl',
  'invite',
  'referral_code',
  'call',
  'number',
  'scope',
  'client_id',
  'redirect_uri',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
] as const

export default searchParamsWhitelist
