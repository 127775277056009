import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback, useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import Button from '../../component/button'
import TextField from '../../component/textfield-v2'
import { Card, CardContent, CardHeader } from './settings-card'
import { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from '../../component/icons/Tint/20/Other'
import { EnvelopeIcon } from '../../component/icons/Tint/20/General'
import IconButton from '../../component/icon-button'
import { MessageStatusCallOutIcon } from '../../component/icons/Tint/12/General'
import { observer } from 'mobx-react-lite'
import { colors } from '@src/theme'

const ReferralLink = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const styles = useStyles({ fullWidth })
  const { service, toast } = useAppStore()
  const referralLink = useMemo(
    () => `https://openph.one/referral/${service.referral.referralCode}`,
    [service.referral.referralCode],
  )
  const encodedLink = encodeURI(referralLink)

  useEffect(() => {
    if (!service.referral.referralCode) {
      service.referral.fetchCodes().catch(toast.showError)
    }
  }, [service.referral.referralCode, service.referral.fetchCodes])

  const handleCopyButton = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(referralLink)
      toast.show({
        message: 'Copied',
      })
    } catch (error) {
      toast.show({
        message: 'An error occurred while copying the text. Please try again',
      })
    }
  }, [toast.show, referralLink])

  const linkedinCopy = useMemo(
    () =>
      encodeURIComponent(
        `I finally found a business phone system built for modern teams! OpenPhone gives everyone on your team their own local number, or lets you collaborate across shared inboxes, with support for global calling and SMS, call recording, contact management, phone menus, automated responses, and more. Use my referral link and we'll both get $20 off! ${referralLink}`,
      ),
    [referralLink],
  )

  const twitterCopy = useMemo(
    () =>
      encodeURIComponent(
        "Get a business phone number your team can share! Local numbers, calling and SMS, contact management, a shared inbox, and more. Use my referral link and we'll both get $20 off!",
      ),
    [],
  )

  const emailSubjectCopy = useMemo(
    () => encodeURIComponent('Check out OpenPhone - a modern business phone system'),
    [],
  )

  const emailBodyCopy = useMemo(
    () =>
      encodeURIComponent(
        `Hi there,\r\n\nI've been using OpenPhone to power my business phone number, and I thought it would be useful for you, too.\r\n\nOpenPhone has reimagined the business phone so that everyone on your team can get their own local number that they can use via mobile app, desktop app, or the web. You can also share numbers with your team, so anyone can answer or reply calls or texts in a shared inbox that keeps everything organized and everyone on the same page!\r\n\nOpenPhone supports global calling and messaging, toll-free numbers, phone menus, automated replies, contact management, and more. OpenPhone is designed to grow with your business, from solopreneur to hundreds of people!\r\n\nReady to give it a shot? Use my link to sign up for a free 7-day trial and you'll get $20 off when you subscribe to a paid plan! ${referralLink}`,
      ),
    [referralLink],
  )

  const facebookCopy = useMemo(
    () =>
      encodeURIComponent(
        `Looking for a modern phone system designed for how you do business? I've been happy with OpenPhone! Invite your entire team to share phone numbers, with support for global calling and messaging, contact management, call recording, and more. Get $20 off using my referral link! ${referralLink}`,
      ),
    [referralLink],
  )

  return (
    <Card variant="elevation" className={styles.card}>
      <CardHeader
        title="Gift $20, earn $20"
        subheader="Invite your friends, family members, and business connections to join OpenPhone, and we'll give you both $20 in credit"
      />
      <CardContent className={styles.referrerLinkContent}>
        {service.referral.referralCode ? (
          <>
            <div className={styles.row}>
              <div className={styles.linkTextFieldWrapper}>
                <TextField
                  label="Your unique referral link"
                  value={referralLink}
                  disabled={true}
                  fullWidth={true}
                  rootClassName={styles.linkTextField}
                />
                <div className={styles.linkIconWrapper}>
                  <IconButton
                    size="small"
                    className={styles.linkIcon}
                    onClick={() => window.open(referralLink)}
                    icon={<MessageStatusCallOutIcon />}
                    title={'Open in a new window'}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyButton}
                className={styles.copyButton}
              >
                Copy link
              </Button>
            </div>
            <div className={styles.socialMediaRow}>
              <Typography
                variant="caption1"
                color="textSecondary"
                fontWeight="regular"
                component="label"
                className={styles.socialMediaLabel}
              >
                Share on social media
              </Typography>
              <div className={styles.iconsContainer}>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${facebookCopy}`}
                  target="_blank"
                  className={styles.facebookIcon}
                >
                  <FacebookIcon />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodedLink}&text=${twitterCopy}`}
                  target="_blank"
                  className={styles.twitterIcon}
                >
                  <TwitterIcon />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?url=${encodedLink}&title=${linkedinCopy}`}
                  target="_blank"
                  className={styles.linkedinIcon}
                >
                  <LinkedinIcon />
                </a>
                <a
                  href={`mailto:?subject=${emailSubjectCopy}&body=${emailBodyCopy}`}
                  target="_blank"
                  className={styles.emailIcon}
                >
                  <EnvelopeIcon />
                </a>
              </div>
            </div>
          </>
        ) : (
          <CircularProgress className={styles.circularProgress} />
        )}
      </CardContent>
    </Card>
  )
}

export default observer(ReferralLink)

const useStyles = makeStyles<Theme, { fullWidth: boolean }>((theme: Theme) => ({
  card: ({ fullWidth }) => ({
    ...(fullWidth && { maxWidth: '100%' }),
  }),
  row: {
    display: 'flex',
    border: 'none',
  },
  linkTextFieldWrapper: {
    display: 'flex',
    flex: 1,
    marginRight: 16,
  },
  linkTextField: {
    flex: 1,
    '& > input': {
      color: `${theme.palette.op.text.primary} !important`,
    },
  },
  linkIcon: {
    position: 'absolute',
    margin: '35px 0 0 -35px',
  },
  copyButton: {
    height: 45,
    alignSelf: 'flex-end',
  },
  socialMediaLabel: {
    padding: '0 0 10px 3px',
  },
  socialMediaRow: {
    marginTop: 24,
  },
  referrerLinkContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  circularProgress: {
    alignSelf: 'center',
  },
  facebookIcon: {
    backgroundColor: colors.facebookBrandBlue,
  },
  twitterIcon: {
    backgroundColor: colors.twitterBrandBlue,
  },
  linkedinIcon: {
    backgroundColor: colors.linkedinBrandBlue,
  },
  emailIcon: {
    backgroundColor: theme.palette.op.primary[1],
  },
  iconsContainer: {
    display: 'flex',
    paddingTop: 10,
    '& > a:not(:first-child)': {
      marginLeft: 10,
    },
    '& > a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 7,
      width: 40,
      height: 40,

      color: theme.palette.op.white,
      textDecoration: 'inherit',
    },
  },
  textFieldIcon: {
    color: theme.palette.op.primary['1'],
    cursor: 'pointer',
  },
  linkIconWrapper: {
    position: 'relative',
  },
}))
