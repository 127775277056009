// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '.'
import { AcceptedReferral, ReferralCode } from './model'

export default class ReferralStore {
  private _referralCodes: ReferralCode[] = []
  private _acceptedReferrals: AcceptedReferral[] = []

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get referralCode(): string {
    return this._referralCodes.length > 0
      ? this._referralCodes[this._referralCodes.length - 1].code
      : undefined
  }

  set referralCodes(codes: ReferralCode[]) {
    this._referralCodes = codes
  }

  get acceptedReferrals() {
    return this._acceptedReferrals
  }

  set acceptedReferrals(accepted: AcceptedReferral[]) {
    this._acceptedReferrals = accepted
  }

  fetchCodes() {
    return this.root.transport.account.referrals.getCode().then((codes) => {
      this.referralCodes = codes
      return codes
    })
  }

  fetchAccepted() {
    return this.root.transport.account.referrals
      .getAcceptedReferrals()
      .then((accepted) => {
        if (accepted !== this.acceptedReferrals) {
          this.acceptedReferrals = accepted
        }
        return accepted
      })
  }

  accept(code: string) {
    return this.root.transport.account.referrals.accept(code)
  }
}
