import { action, reaction, runInAction, toJS } from 'mobx'
import { StorageEngine } from '.'
import { isNonNull } from '../../lib/rx-operators'
import AsyncStorage from './async'

export declare type AnnotationsMap<T, AdditionalFields extends PropertyKey> = {
  [P in Exclude<keyof T, 'toString'>]?: StorageEngine
} & Record<AdditionalFields, StorageEngine>

export function makePersistable<T, AdditionalKeys extends PropertyKey = never>(
  target: T,
  name: string,
  properties: AnnotationsMap<T, AdditionalKeys>,
) {
  Object.keys(properties).forEach((property) => {
    const storage: StorageEngine = properties[property]
    const key = [name, property].filter(isNonNull).join('.')

    if (storage instanceof AsyncStorage) {
      storage.get(key).then(
        action((value) => {
          if (value) {
            target[property] = value
          }
        }),
      )
      reaction(
        () => {
          if (!target[property]) {
            return target[property]
          }
          if (typeof target[property] === 'object' && 'serialize' in target[property]) {
            return target[property].serialize()
          }
          return toJS(target[property])
        },
        (value) => {
          storage.set(value, key)
        },
        { name: `MakePersistable.${key}` },
      )
    } else {
      runInAction(() => {
        const value = storage.get(key)
        if (isNonNull(value)) {
          target[property] = value
        }
      })
      reaction(
        () => toJS(target[property]),
        (value) => {
          storage.set(key, value)
        },
        { name: `MakePersistable.${key}` },
      )
    }
  })
}
