// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { fileType } from '../../../../lib/file'
import { DecodableMessageMedia } from '../../../../service/model'
import { MediaRemoveIcon } from '../../../icons/NoTint/22'
import ContactMediaPreview from './contact'
import FileMediaPreview from './file'
import ImageMediaPreview from './image'
import VideoMediaPreview from './video'

export interface MediaPreviewProps {
  media: DecodableMessageMedia
  onOpen: (media: DecodableMessageMedia) => void
  onDelete: (media: DecodableMessageMedia) => void
}

const MediaPreview: React.FC<MediaPreviewProps> = function ({ media, onOpen, onDelete }) {
  const styles = useStyles({})
  const type = fileType(media.type, media.name)

  return (
    <div className={styles.root}>
      <button className={styles.delete} onClick={() => onDelete(media)}>
        <MediaRemoveIcon />
      </button>
      {type === 'image' ? (
        <ImageMediaPreview media={media} onOpen={onOpen} />
      ) : type === 'video' ? (
        <VideoMediaPreview media={media} onOpen={onOpen} />
      ) : type === 'contact' ? (
        <ContactMediaPreview media={media} />
      ) : (
        <FileMediaPreview media={media} />
      )}
    </div>
  )
}

export default MediaPreview

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: 3,
      position: 'relative',

      '&:hover': {
        '& $delete': {
          opacity: 1,
        },
      },
    },
    delete: {
      outline: 'none',
      position: 'absolute',
      right: 5,
      top: 5,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderRadius: '50%',
      width: 22,
      height: 22,
      border: 'none',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      opacity: 0,
      transition: theme.transitions.create(['background-color', 'opacity'], {
        duration: 300,
        easing: 'ease',
      }),

      '&:hover': {
        backgroundColor: theme.palette.op.black,
      },
    },
  }),
  { name: MediaPreview.name },
)
