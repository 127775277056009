import { makeStyles, Theme } from '@material-ui/core'
import { fonts } from '@src/theme'
import React from 'react'
import Checkbox from '../../../component/checkbox-v2'
import Typography from '@ui/Typography'
interface EventTypeOptionProps {
  isChecked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  name: string
  description: string
}

const EventTypeOption = ({
  isChecked,
  onChange,
  name,
  description,
}: EventTypeOptionProps) => {
  const styles = useStyles()
  const id = `event-${name}`
  return (
    <label className={styles.root}>
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        className={styles.checkbox}
        id={id}
      />
      <Typography variant="code" color="textPrimary" className={styles.name}>
        {name}
      </Typography>
      <Typography variant="caption2" color="textTertiary" className={styles.description}>
        {description}
      </Typography>
    </label>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'grid',
      gridTemplateAreas: `
        "checkbox name"
        ". description"
      `,
      gridTemplateColumns: 'min-content 1fr',
      columnGap: 12,
      rowGap: 4,
    },
    checkbox: {
      gridArea: 'checkbox',
      alignSelf: 'center',
    },
    name: {
      gridArea: 'name',
    },
    description: {
      gridArea: 'description',
    },
  }),
  { name: EventTypeOption.name },
)

export default EventTypeOption
