// @ts-strict-ignore
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { DisposeBag } from '../../../lib/dispose'
import { Emoji, getEmojis } from '../../../lib/emoji'
import { fuzzySearch } from '../../../lib/search'
import { Identity, ImmutableCollection, Snippet } from '../../../service/model'
import InputBarController from '../controller'
import { InputMenuMode } from '.'

const COMMANDS = [
  {
    mode: 'snippets',
    text: 'Insert or create a snippet',
  },
  {
    mode: 'giphy',
    text: 'Find and share a GIF',
  },
  // {
  //   mode: 'contact',
  //   text: 'Share a contact',
  // },
] as const

export type Command = typeof COMMANDS[number]
export type CommandMode = Command['mode']

export default class Controller {
  mode: InputMenuMode = null
  commandMode: CommandMode = null

  commandFilter: string = null
  emojiFilter: string = null
  mentionFilter: string = null
  snippetFilter: string = null

  protected disposeBag = new DisposeBag()

  constructor(readonly inputBar: InputBarController) {
    makeObservable(this, {
      mode: observable.ref,
      commandMode: observable.ref,
      commandFilter: observable.ref,
      emojiFilter: observable.ref,
      mentionFilter: observable.ref,
      snippetFilter: observable.ref,
      filteredCommands: computed,
      filteredEmojis: computed,
      filteredMentionTargets: computed,
      filteredSnippets: computed,
      handleSnippetSearchInputChange: action.bound,
      close: action.bound,
      closeCommands: action.bound,
      handleClose: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.mode,
        (currentMode) => {
          if (!currentMode) this.close()
        },
        { name: 'ModeChange' },
      ),

      reaction(
        () => this.filteredCommands,
        (currentFilteredCommands) => {
          if (currentFilteredCommands.length === 0) this.close()
        },
        { name: 'FilteredCommandsChange' },
      ),

      reaction(
        () => this.filteredEmojis,
        (currentFilteredEmojis) => {
          if (currentFilteredEmojis.length === 0) this.close()
        },
        { name: 'FilteredEmojisChange' },
      ),

      reaction(
        () => this.filteredMentionTargets,
        (currentFilteredMentionTargets) => {
          if (currentFilteredMentionTargets.length === 0) this.close()
        },
        { name: 'FilteredMentionTargetsChange' },
      ),
    )
  }

  get filteredCommands(): readonly Command[] {
    return this.commandFilter
      ? fuzzySearch(COMMANDS, this.commandFilter, ['mode'])
      : COMMANDS
  }

  get filteredEmojis(): readonly Emoji[] {
    return fuzzySearch(getEmojis().list, this.emojiFilter, ['shortName', 'keywords'])
  }

  get filteredMentionTargets(): readonly Identity[] {
    const targets = this.inputBar.mentionTargets
    const limit = 20

    return this.mentionFilter
      ? fuzzySearch(targets, this.mentionFilter, ['name'], { limit })
      : targets.slice(0, limit)
  }

  get filteredSnippets(): ImmutableCollection<Snippet> {
    const snippets = this.inputBar.snippets
    const limit = 250

    return new ImmutableCollection(
      this.snippetFilter
        ? fuzzySearch(snippets, this.snippetFilter, ['name', 'text'], { limit })
        : snippets.slice(0, limit),
    )
  }

  handleSnippetSearchInputChange(event: React.FormEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.snippetFilter = event.target.value
    }
  }

  close() {
    this.mode = null
    this.commandMode = null
    this.commandFilter = null
    this.emojiFilter = null
    this.mentionFilter = null
    this.snippetFilter = null
  }

  closeCommands() {
    this.commandMode = null
  }

  handleClose() {
    this.mode = null
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
