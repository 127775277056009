import Dexie from 'dexie'
import log from '../../lib/log'
import { DatabaseTable } from '../../types'

export default class AsyncStorage {
  constructor(
    protected table: DatabaseTable<any, string>,
    private classConstructor: (data: any) => any,
  ) {}

  get<T>(id: string): Promise<T> {
    return this.ensureConnection()
      .then(() => this.table.get(id))
      .then((data) => {
        if (!data) return data
        if (Array.isArray(data)) {
          return data.map((d) => (this.classConstructor ? this.classConstructor(d) : d))
        }
        return this.classConstructor ? this.classConstructor(data) : data
      })
  }

  set<T>(item: T, key: string): Promise<any> {
    return this.ensureConnection().then(() => this.table.put(item, key))
  }

  delete(id: string) {
    return this.ensureConnection().then(() => this.table.delete(id))
  }

  private ensureConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      const db = this.table.db as Dexie
      if (db.hasBeenClosed()) {
        log.warn('Database connection closed, reopening...')
        return db.open()
      } else {
        resolve()
      }
    })
  }
}
