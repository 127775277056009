"use strict";
// @ts-strict
Object.defineProperty(exports, "__esModule", { value: true });
// FIXME: Generic `C` should be a mapping of channels to their event types
// instead of just a type union of channels
class MessageBus {
    bus;
    listeners = new Map();
    constructor(name) {
        this.bus = new BroadcastChannel(name);
        this.bus.addEventListener('message', this.handleMessage.bind(this));
    }
    // FIXME: `data` should be a more restricted type, e.g. `string | object`
    /**
     * Send a message with the specified channel and data.
     *
     * Example:
     * ```js
     * bus.send({ channel: 'my-channel-name', data: 'Hello World!' });
     * ```
     *
     * Listeners to the `my-channel-name` channel will receive `Hello World!` as
     * the message's data.
     */
    send(channel, data) {
        this.bus.postMessage({ channel, data });
    }
    handleMessage(event) {
        const { channel, data } = event.data;
        if (!channel) {
            throw new Error("Channel not specified. Message's payload structure should be of an object with `channel` and `data` properties.");
        }
        const listeners = this.listeners.get(channel);
        if (!listeners)
            return;
        for (const listener of listeners) {
            listener(data);
        }
    }
    /**
     * Add a listener to the specified channel name.
     *
     * When a message is sent to the subscribed channel, the listener will be
     * called.
     *
     * Example:
     * ```js
     * bus.on('my-channel-name', (data) => console.log(data))
     * ```
     *
     * When a message is sent to the `my-channel-name` channel, the data will be
     * logged.
     */
    on(channel, listener) {
        this.listeners.set(channel, [...(this.listeners.get(channel) ?? []), listener]);
    }
    off(channel, listener) {
        const listenersOfChannel = this.listeners.get(channel);
        if (!listenersOfChannel)
            return;
        const filteredSubscribers = listenersOfChannel.filter((listenerOfChannel) => listenerOfChannel !== listener);
        this.listeners.set(channel, filteredSubscribers);
    }
}
exports.default = MessageBus;
