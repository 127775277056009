import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Token } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useOnboardingState } from '.'
import CreditCard from '../../component/credit-card'
import { Card, Disclaimer } from './common'

const Subscription: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  const store = useOnboardingState()

  const handleSubmit = (token: Token) => {
    store.upgrade(token)
  }

  return (
    <Card
      emoji="💳"
      title="Card details"
      description={
        <>
          Enter your card details to kick-start your free trial. Don’t worry, you won’t be
          charged a dime until your{' '}
          <Typography component="span" variant="inherit" color="textPrimary">
            free trial ends in 7 days
          </Typography>
          .
        </>
      }
    >
      <CreditCard
        loading={store.loading}
        actionTitle="Start your 7-day free trial"
        onSubmit={handleSubmit}
      />
      <Disclaimer
        emoji="📣"
        text="We hate nasty surprises. That's why we’ll email you a day before your trial is due to end and your card is due to be charged, just as a reminder."
      />
    </Card>
  )
}

export default observer(Subscription)

const useStyles = makeStyles((theme) => ({
  invoice: {
    background: theme.palette.op.gray[6],
    border: `1.5px solid ${theme.palette.op.border.common}`,
    padding: '1.5rem 1.5rem 3px',
    borderRadius: 5,
    marginBottom: 20,
  },
  due: {
    fontSize: '1.25rem',
    textAlign: 'right',
  },
  icon: {
    color: theme.palette.op.background.highlight(0.5),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px dashed ${theme.palette.divider}`,
    justifyContent: 'flex-start',
    padding: '10px 0',
  },
  featureTitle: {
    margin: '0 15px',
  },
  help: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
}))

const features = [
  {
    title: 'Unlimited calling and texting',
    tip: 'Send and receive unlimited SMS, MMS and calls using your OpenPhone number anywhere in the world.',
  },
  {
    title: 'Voicemail with transcription',
    tip: 'Receive unlimited voicemails along with transcriptions.',
  },
  {
    title: 'Call recording',
    tip: 'Record individual calls or turn on auto-recording for all your calls.',
  },
  {
    title: 'Shared phone numbers',
    tip: 'Respond to calls and messages as a team',
  },
  {
    title: 'Phone Menu/IVR/Extensions',
    tip: 'Set up a phone directory for callers. Eg - "Press 1 for Sales, Press 2 for Support"',
  },
  {
    title: 'Custom voicemail recording',
    tip: 'Record or upload your custom voicemail greeting.',
  },
  {
    title: 'Business hours',
    tip: 'Set business hours to never receive calls or messages when you are not working.',
  },
  {
    title: 'Business address book',
    tip: 'Dedicated business address book to keep your contacts organized',
  },
  {
    title: 'Email and Slack integrations',
    tip: 'Get your OpenPhone voicemail, text messages and missed calls in Slack or email',
  },
  {
    title: 'Saved replies',
    tip: 'Speed up your response time by creating and sending saved replies to common questions',
  },
  {
    title: 'Text message auto-replies',
    tip: 'Automatically respond to incoming texts, missed calls or voicemail with a pre-defined message',
  },
  {
    title: 'Available on Desktop and Mobile',
    tip: 'Install OpenPhone on your mobile device (iOS, Android) and desktop (Mac, Windows)',
  },
]
