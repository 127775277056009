// @ts-strict-ignore
import { action, computed, makeObservable } from 'mobx'
import { AvailabilityHours } from '../../..'
import { defaultAvailability } from '../../../lib/constants'
import { NotificationUnreadCount } from '../../../service/model'
import { IUiStore } from '../../../types'
import AppStore from '../../store'

export type ScheduleMode = 'custom' | 'everyday' | 'weekdays'

export class NotificationSettingsController implements IUiStore {
  constructor(private app: AppStore) {
    makeObservable(this, {
      userSettings: computed,
      appIconBadge: computed,
      includePreview: computed,
      scheduleEnabled: computed,
      sendNotificationsWhenUnavailable: computed,
      setSchedule: action,
      setAppIconBadge: action,
      toggleIncludePreview: action,
      toggleScheduleEnabled: action,
      toggleSendNotificationsWhenUnavailable: action,
    })
  }

  get userSettings() {
    return this.app.service.user.current.settings
  }

  get includePreview() {
    return this.userSettings.notifications?.includeMessagePreview ?? true
  }

  get sendNotificationsWhenUnavailable() {
    return (
      !this.userSettings.notifications?.snoozedDeliveryMethod ||
      this.userSettings.notifications?.snoozedDeliveryMethod === 'silent'
    )
  }

  get appIconBadge() {
    return this.userSettings.notifications?.unreadCount
  }

  get schedule() {
    return this.userSettings.notifications?.schedule
  }

  get scheduleEnabled() {
    return Boolean(this.userSettings.notifications?.schedule?.enabled)
  }

  get playNotificationSounds() {
    return this.userSettings.playNotificationSounds
  }

  setAppIconBadge = (value: NotificationUnreadCount) => {
    this.userSettings.notifications.unreadCount = value
  }

  toggleIncludePreview = () => {
    this.userSettings.notifications.includeMessagePreview = !this.includePreview
  }

  toggleSendNotificationsWhenUnavailable = () => {
    this.userSettings.notifications.snoozedDeliveryMethod = this
      .sendNotificationsWhenUnavailable
      ? 'none'
      : 'silent'
  }

  toggleScheduleEnabled = () => {
    this.userSettings.notifications.schedule ??= defaultAvailability
    this.userSettings.notifications.schedule.enabled = !this.scheduleEnabled
  }

  setTimezone = (timezone: string) => {
    this.userSettings.notifications.schedule = {
      ...this.userSettings.notifications.schedule,
      timezone,
    }
  }

  setSchedule = (schedule: AvailabilityHours) => {
    this.userSettings.notifications.schedule = schedule
  }

  togglePlayNotificationSounds = () => {
    this.userSettings.web.playNotificationSounds = !this.playNotificationSounds
  }

  tearDown() {}
}
