// @ts-strict-ignore
import React, { useState, useEffect } from 'react'
import { useEditor, useBackdropClick } from '.'
import { makeStyles, darken } from '@material-ui/core/styles'
import DayPicker from 'react-day-picker'
import useInputState from '../../../lib/use-input-state'
import keyboard from '../../../lib/keyboard'
import TextInput from '@ui/TextInput'
import { parseFromString } from '../../../lib'
import { DangerButton } from '../../../component/button'

export interface EditDateItemProps {
  defaultValue: Date | null
  clear?: boolean
  onSave: (date: Date) => void
}

const EditDateItem: React.FC<EditDateItemProps> = function ({
  clear,
  defaultValue,
  onSave,
}) {
  const styles = useStyles({})
  const [date, setDate] = useState(defaultValue || undefined)
  const [input, setInput] = useInputState('')
  const [, hide] = useEditor()

  const handleDayClick = (date: Date) => {
    onSave(date)
    hide()
  }

  const handleSave = () => {
    onSave(date)
    hide()
  }

  const handleClear = () => {
    onSave(null)
    hide()
  }

  useEffect(() => {
    const date = parseFromString(input, { forwardDate: true })
    if (date) {
      setDate(date)
    }
  }, [input])

  useBackdropClick(handleSave, [])

  return (
    <div className={styles.root}>
      <TextInput
        size={35}
        autoFocus
        placeholder="Type today, in 5 days, etc"
        value={input}
        onChange={setInput}
        className={styles.input}
        onKeyDown={keyboard.onEnter(handleSave)}
      />
      <DayPicker
        showOutsideDays
        onDayClick={handleDayClick}
        selectedDays={date}
        initialMonth={date || new Date()}
        month={date}
        classNames={{
          container: styles.container,
          wrapper: styles.wrapper,
          interactionDisabled: styles.interactionDisabled,

          navBar: styles.navBar,
          navButtonPrev: `${styles.navButton} ${styles.navButtonPrev}`,
          navButtonNext: `${styles.navButton} ${styles.navButtonNext}`,
          navButtonInteractionDisabled: styles.navButtonInteractionDisabled,

          months: styles.months,
          month: styles.month,
          caption: styles.caption,
          weekdays: styles.weekdays,
          weekdaysRow: styles.weekdaysRow,
          weekday: styles.weekday,
          weekNumber: styles.weekNumber,
          body: styles.body,
          week: styles.week,
          day: styles.day,

          footer: styles.footer,
          todayButton: styles.todayButton,

          /* default modifiers */
          today: styles.today,
          selected: styles.selected,
          disabled: styles.disabled,
          outside: styles.outside,
        }}
      />
      {clear && (
        <DangerButton
          style={{ margin: 10 }}
          onClick={handleClear}
          variant="outlined"
          color="primary"
          size="small"
        >
          Clear
        </DangerButton>
      )}
    </div>
  )
}

export default EditDateItem

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
  },
  container: {
    width: '100%',
    display: 'inline-block',
    fontSize: '0.8rem',
    color: theme.palette.text.primary,
  },
  wrapper: {
    outline: 'none',
    position: 'relative',
    flexDirection: 'row',
    paddingBottom: '1em',
    userSelect: 'none',
  },
  interactionDisabled: {
    cursor: 'default',

    '& $day': {
      cursor: 'default',
    },
  },
  navBar: {},
  navButton: {
    position: 'absolute',
    top: '1em',
    right: '1.5em',
    left: 'auto',
    display: 'inline-block',
    marginTop: '2px',
    width: '1.25em',
    height: '1.25em',
    backgroundPosition: 'center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    cursor: 'pointer',
    outline: 'none',
  },
  navButtonPrev: {
    marginRight: '1.5em',
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC")',
  },
  navButtonNext: {
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==")',
  },
  navButtonInteractionDisabled: {
    display: 'none',
  },
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'stretch',
    padding: '0 10px',
  },
  month: {
    display: 'table',
    width: '100%',
    marginTop: '1em',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    userSelect: 'none',
  },
  caption: {
    display: 'table-caption',
    marginBottom: '0.5em',
    padding: '0 0.5em',
    textAlign: 'left',

    '& > div': {
      fontWeight: 500,
      fontSize: '1.15em',
    },
  },
  weekdays: {
    display: 'table-header-group',
    marginTop: '1em',
  },
  weekdaysRow: {
    display: 'table-row',
  },
  weekday: {
    display: 'table-cell',
    padding: '0.5em',
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    textAlign: 'center',
    fontSize: '0.875em',

    '& abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none',
    },
  },
  weekNumber: {
    display: 'table-cell',
    padding: '0.5em',
    minWidth: '1em',
    borderRight: `1.5px solid ${theme.palette.op.legacy.dateEditor.weekNumberBorder}`,
    color: theme.palette.op.legacy.dateEditor.weekDayGray,
    verticalAlign: 'middle',
    textAlign: 'right',
    fontSize: '0.75em',
    cursor: 'pointer',
  },
  body: {
    display: 'table-row-group',
  },
  week: {
    display: 'table-row',
  },
  day: {
    display: 'table-cell',
    padding: '4px 5px',
    borderRadius: '5px',
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',

    '&:hover': {
      backgroundColor: theme.palette.op.hover.primary,
    },
  },
  footer: {
    paddingTop: '0.5em',
  },
  todayButton: {
    border: 'none',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    boxShadow: 'none',
    color: theme.palette.op.legacy.dateEditor.todayButton,
    fontSize: '0.875em',
    cursor: 'pointer',
  },
  today: {
    color: theme.palette.op.primary[3],
    fontWeight: 700,
  },
  selected: {
    color: 'white',
    background: theme.palette.op.primary[1],
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: darken(theme.palette.op.primary[1], 0.2),
    },
  },
  disabled: {
    color: theme.palette.op.legacy.dateEditor.disabledText,
    cursor: 'default',
  },
  outside: {
    color: theme.palette.text.hint,
    cursor: 'pointer',
  },
  input: {
    margin: 10,
  },
}))
