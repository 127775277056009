// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'
import Service from '..'
import { Model } from './base'
import { thumbnailUrl } from './utils'

export interface DecodableMessageMedia {
  id?: string
  name?: string
  type?: string
  file?: File
  url?: string
  thumbnailUrl?: string
}

export interface CodableMessageMedia {
  id: string
  type: string | null
  url: string | null
  name: string | null
}

export class MessageMedia implements DecodableMessageMedia, Model {
  id: string = uuid()
  type: string = null
  url: string = null
  file: File = null
  name: string = null
  uploadProgress: number = 0

  constructor(
    protected root: Service,
    attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>,
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get thumbnailUrl(): string {
    return thumbnailUrl(this.url, { width: 500, quality: 100 })
  }

  upload() {
    if (!this.file) return
    return this.root.conversation
      .upload(
        this.file,
        action((progress, total) => {
          this.uploadProgress = progress / total
        }),
      )
      .then(
        action((url) => {
          this.url = url
        }),
      )
  }

  deserialize(attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>) {
    if (attrs) {
      Object.assign(this, attrs)
    }
    return this
  }

  serialize(): CodableMessageMedia {
    return {
      id: this.id,
      type: this.type,
      url: this.url,
      name: this.name,
    }
  }
}
