import { config } from '@src/config'
import { useAsyncScript } from '../useAsyncScript'

const src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_API_KEY}&libraries=places`

function createPlacesScript() {
  const script = document.createElement('script')
  script.async = true
  script.src = src
  return script
}

const isPlacesAvailable = () => 'google' in window && !!google.maps?.places

export function useLoadPlacesApi() {
  const { loaded } = useAsyncScript({
    scriptCreator: createPlacesScript,
    getInitialState: isPlacesAvailable,
  })

  return { loaded }
}
