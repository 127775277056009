// @ts-strict-ignore
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useRef, useState } from 'react'
import Button from '../../../component/button-v2'
import TextField from '../../../component/textfield-v2'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'
import InviteeList from './InviteeList'
import { useAppStore } from '@src/app/context'
import { Invitation, RoleName } from '../../..'
import { Command } from '../common-v2'
import CommandHeader from '../common/header'
import CommandFooter from '../common/footer'
import SelectRole from './SelectRole'
import { isValidEmail } from '@openphone/lib'
import { observer } from 'mobx-react-lite'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'

const InviteMember = () => {
  const styles = useStyles()
  const ref = useRef<HTMLInputElement>(null)
  const [addMembersAs, setAddMembersAs] = useState<RoleName>('member')
  const { service, toast, command } = useAppStore()

  const [input, setInput] = useInputState('')
  const [membersToAdd, setMembersToAdd] = useState<Invitation[]>([])

  const handleSubmit = () => {
    service.member
      .inviteBulk(membersToAdd)
      .then(() => {
        toast.show({ message: 'The invitations have been sent!' })
        command.hide()
      })
      .catch(toast.showError)
  }

  const handleAddToList = () => {
    // Filter out:
    //   - emails that are already in the list
    //   - email that duplicated in the input string
    //   - invalid emails

    const emails = [
      ...new Set(
        input
          .split(',')
          .map((email) => email.trim())
          .filter(
            (email) =>
              isValidEmail(email) && !membersToAdd.find((row) => row.email === email),
          ),
      ),
    ]

    setMembersToAdd((prevState) => [
      ...prevState,
      ...emails.map(
        (email) =>
          ({
            email,
            role: addMembersAs,
          } as Invitation),
      ),
    ])

    setInput('')
    setAddMembersAs('member')
  }

  useKeyboardShortcuts({
    node: ref.current,
    name: 'command/invite-input',
    dep: [input],
    handler: (shortcut, event) => {
      if (shortcut === 'Enter') {
        handleAddToList()
        event.preventDefault()
      }
    },
  })

  const canAddToList =
    input.length > 0 && input.split(',').some((email) => isValidEmail(email.trim()))

  return (
    <Command width={672}>
      <CommandHeader
        title=" Invite your team"
        subtitle="Each new member by default gets their own local or toll-free number, which they
          can choose when signing up. Or you can choose to add them to an existing number."
      />
      <div className={styles.root}>
        <div className={styles.inputContainer}>
          <TextField
            autoComplete="off"
            autoFocus
            ref={ref}
            label="Email address"
            placeholder="Enter an email address..."
            value={input}
            onChange={setInput}
            helperText="Comma separate to add multiple emails at once"
            rootClassName={styles.input}
            className={styles.textField}
            endAdornment={() => (
              <SelectRole
                value={addMembersAs}
                onChange={(
                  event: React.ChangeEvent<{
                    name: string
                    value: RoleName
                  }>,
                ) => setAddMembersAs(event.target.value)}
                MenuProps={{
                  classes: { paper: styles.paperRoot },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={(value: string) => (
                  <Typography variant="footnote" className={styles.selectMember}>
                    {value}
                  </Typography>
                )}
              />
            )}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddToList}
            className={styles.addToListBtn}
            disabled={!canAddToList}
          >
            Add to list
          </Button>
        </div>
        <InviteeList items={membersToAdd} setItems={setMembersToAdd} />
      </div>
      <CommandFooter>
        <div className={styles.footer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={styles.submitBtn}
            disabled={membersToAdd.length === 0}
          >
            Send invites
          </Button>
        </div>
      </CommandFooter>
    </Command>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '16px 32px 16px 32px',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
    overflow: 'auto',
  },
  selectRoot: {
    border: 'none',
    background: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectMember: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  menuItemTitle: {
    textTransform: 'capitalize',
    color: theme.palette.op.gray[1],
  },
  menuItemDescription: {
    marginTop: 2,
    color: theme.palette.op.gray[3],
  },
  inputContainer: {
    marginTop: 16,
    display: 'flex',
  },
  input: {
    flex: 1,
  },
  textField: {
    height: 40,
  },
  addToListBtn: {
    alignSelf: 'center',
    marginLeft: 12,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitBtn: {
    alignSelf: 'flex-end',
  },
  paperRoot: {
    '& ul': {
      border: `1.5px solid ${theme.palette.op.border.common}`,
      padding: '4px 0px',
      borderRadius: 8,
    },
  },
}))

export default observer(InviteMember)
