// @ts-strict-ignore
import { action, computed, makeAutoObservable, makeObservable, observable } from 'mobx'
import { Connection, Device } from 'twilio-client'
import PhoneNumberSelectorController, {
  TransfereeDescriptor,
} from '../../component/phone-number-selector/controller'
import {
  Collection,
  compareByName,
  compareMembersByPresence,
  PhoneNumber,
} from '../../service/model'
import { TransferParams } from '../../service/transport/voice'
import { CallStatus } from '../../types'
import AppStore from '../store'

export class Call {
  readonly transferController = new CallTransferController(this.app, this)

  connection?: Connection = null
  error?: Connection.Error = null
  id?: string = null
  isMuted?: boolean = false
  isRecording?: boolean = false
  participants?: string[] = null
  phoneNumberId?: string = null
  startedAt?: number = null
  status?: CallStatus = null
  warnings = new Set<string>()
  direction: 'incoming' | 'outgoing' = null

  constructor(private app: AppStore, attrs: Partial<Call> = {}) {
    Object.assign(this, attrs)

    this.connection.on('accept', this.handleAccept)
    this.connection.on('cancel', this.handleDisconnect)
    this.connection.on('disconnect', this.handleDisconnect)
    this.connection.on('error', this.handleError)
    this.connection.on('mute', this.handleMute)
    this.connection.on('reconnecting', this.handleReconnecting)
    this.connection.on('reconnected', this.handleReconnected)
    this.connection.on('reject', this.handleDisconnect)
    this.connection.on('ringing', this.handleRinging)
    this.connection.on('sample', this.handleSample)
    this.connection.on('warning', this.handleWarning)
    this.connection.on('warning-cleared', this.handleWarningCleared)

    this.isRecording =
      this.connection.customParameters.get('is_recording') === 'true' ||
      this.phoneNumber?.settings?.autoRecord

    makeAutoObservable(this, {})
  }

  get sid() {
    return this.connection.customParameters.get('session_id')
  }

  get phoneNumber() {
    return this.app.service.phoneNumber.collection.get(this.phoneNumberId)
  }

  get isVerified(): boolean {
    return Boolean(this.connection.callerInfo?.isVerified)
  }

  get friendlyWarnings() {
    return [...this.warnings].map((key) => warningMapping[key]).join('\n')
  }

  sendDigits(digits: string) {
    return this.connection.sendDigits(digits)
  }

  toggleMute() {
    this.connection.mute(!this.connection.isMuted())
  }

  toggleRecord() {
    this.isRecording = !this.isRecording
    return this.app.service.voice
      .record(
        this.connection.parameters.CallSid,
        this.phoneNumberId,
        this.isRecording ? 'start' : 'stop',
      )
      .catch(
        action((error) => {
          this.isRecording = !this.isRecording
          this.app.toast.showError(error)
        }),
      )
  }

  transfer(params: TransferParams) {
    return this.app.service.voice.transfer(this.sid, params).then(() => {
      this.disconnect()
    })
  }

  accept() {
    this.connection.accept()
  }

  reject() {
    this.connection.reject()
    this.handleDisconnect()
  }

  disconnect() {
    this.connection.disconnect()
  }

  private handleAccept = action((connection: Connection) => {
    this.status = 'connected'
    this.startedAt = Date.now()
  })

  private handleDisconnect = () =>
    // TODO: The 'error' event may be emitted directly after a disconnect, so
    // this is a hack to show the error message and _then_ disconnect.
    setTimeout(
      action(() => {
        this.status = 'none'
        this.tearDown()
        this.app.voice.call.delete(this.id)
      }),
      0,
    )

  private handleError = action((error: Connection.Error) => {
    this.error = error
  })

  private handleMute = action((muted: boolean, connection: Connection) => {
    this.isMuted = muted
  })

  private handleReconnecting = action((error: Error) => {
    this.status = 'connecting'
  })

  private handleReconnected = action(() => {
    this.status = 'connected' //test
  })

  private handleRinging = action((hasEarlyMedia: boolean) => {
    this.status = 'connecting'
  })

  private handleSample = action(() => {})

  private handleWarning = action((name: string, data: any) => {
    /**
     * TODO: Apparently the warnings are not super accurate and cause confusion
     * so disabled them for now until we find a better way to detect actual issues
     * https://linear.app/openphone/issue/OP-1424/call-quality-issue-message-on-the-web-app-not-clear-clarity-of-the
     */
    // this.warnings.add(name)
  })

  private handleWarningCleared = action((name: string) => {
    this.warnings.delete(name)
  })

  private tearDown = () => {
    this.connection.off('accept', this.handleAccept)
    this.connection.off('cancel', this.handleDisconnect)
    this.connection.off('disconnect', this.handleDisconnect)
    this.connection.off('error', this.handleError)
    this.connection.off('mute', this.handleMute)
    this.connection.off('reconnecting', this.handleReconnecting)
    this.connection.off('reconnected', this.handleReconnected)
    this.connection.off('reject', this.handleDisconnect)
    this.connection.off('ringing', this.handleRinging)
    this.connection.off('sample', this.handleSample)
    this.connection.off('warning', this.handleWarning)
    this.connection.off('warning-cleared', this.handleWarningCleared)
  }
}

export default class VoiceUiStore {
  call = new Collection<Call>({ bindElements: true })
  device: Device = null
  audioDevices = new Map<string, MediaDeviceInfo>()
  inputDevices = new Map<string, MediaDeviceInfo>()
  ready: boolean = false
  error: Device.Error = null

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      device: false,
    })

    this.startDevice()
  }

  get hasOngoingCall() {
    return this.call.list.some((c) => c.status === 'connected')
  }

  get hasActiveCalls() {
    return this.call.length > 0
  }

  startCall = (from: PhoneNumber, to: string) => {
    const connection = this.device.connect({
      To: to,
      PhoneNumberId: from.id,
      UserId: this.root.service.user.current.id,
    })
    this.call.put(
      new Call(this.root, {
        id: connection.outboundConnectionId,
        connection,
        status: 'connecting',
        participants: [to],
        phoneNumberId: from.id,
        direction: 'outgoing',
      }),
    )
  }

  private startDevice() {
    this.root.service.voice.refreshToken().then(
      action((session) => {
        this.stopDevice()
        this.device = new Device(session.token, {
          allowIncomingWhileBusy: true,
          codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU],
          enableIceRestart: true,
          enableRingingState: true,
          fakeLocalDTMF: true,
          maxAverageBitrate: 24000,
          sounds: {
            disconnect: this.root.sound.url('callEnded'),
            outgoing: this.root.sound.url('callStarted'),
            incoming: this.root.sound.url('primaryRingtone'),
          },
        })
        this.device.on('ready', this.handleReady)
        this.device.on('error', this.handleError)
        this.device.on('offline', this.handleOffline)
        this.device.on('incoming', this.handleIncoming)
        this.device.audio.on('deviceChange', this.handleAudioDeviceChange)
      }),
    )
  }

  private handleReady = () => {
    this.ready = true
  }

  private handleError = (error: Device.Error) => {
    this.ready = false
    this.error = error

    // Invalid JWT token
    if (error.code === 31204 || error.code === 31205 || error.code === 9221) {
      this.startDevice()
    }
  }

  private handleOffline = () => {
    this.ready = false
    this.call.clear()
  }

  private handleIncoming = (connection: Connection) => {
    this.call.put(
      new Call(this.root, {
        id: this.callId(connection),
        connection,
        status: 'incoming',
        direction: 'incoming',
        participants: [connection.parameters.From],
        phoneNumberId: this.root.service.user.phoneNumbers.list.find(
          (n) => n.number === connection.customParameters.get('to_phone_number'),
        )?.id,
      }),
    )
  }

  private handleAudioDeviceChange = () => {
    this.audioDevices = this.device.audio.availableOutputDevices
    this.inputDevices = this.device.audio.availableInputDevices
  }

  private stopDevice = () => {
    if (!this.device) return
    this.device.off('ready', this.handleReady)
    this.device.off('error', this.handleError)
    this.device.off('offline', this.handleOffline)
    this.device.off('incoming', this.handleIncoming)
    this.device.audio.off('deviceChange', this.handleAudioDeviceChange)
    this.device.destroy()
    this.device = null
  }

  private callId(connection: Connection): string {
    return connection.outboundConnectionId || connection.parameters.CallSid
  }
}

export class CallTransferController extends PhoneNumberSelectorController {
  contacts = []
  readonly confirmDialog = new CallTransferConfirmDialogController(this.app, this.call)

  constructor(protected app: AppStore, protected call: Call) {
    super()
    makeObservable<this, 'reset'>(this, {
      currentUser: computed,
      currentPhoneNumber: computed,
      currentPhoneNumberMembers: computed,
      phoneNumbers: computed,
      members: computed,
      contacts: observable,
      handleSelect: action.bound,
      handleBack: action.bound,
      reset: action.bound,
    })
  }

  override get search() {
    return super.search
  }

  override set search(search: string) {
    super.search = search

    // Perform the search on contacts
    this.app.service.search.identities(search, 20, 'contacts').then(
      action((result) => {
        this.contacts = result.map((c) => c.identity)
      }),
    )
  }

  get currentUser() {
    return this.app.service.user.current.asMember
  }

  get currentPhoneNumber() {
    return this.call.phoneNumber
  }

  get currentPhoneNumberMembers() {
    return this.call.phoneNumber.members
      .filter(
        (member) => member.id !== this.call.connection.customParameters.get('callFromId'),
      )
      .sort(compareMembersByPresence)
  }

  get phoneNumbers() {
    return this.app.service.phoneNumber.collection.list
      .filter(
        (pn) => pn.users.length > 1 && pn.number !== this.call.connection.parameters.From,
      )
      .sort(compareByName)
  }

  get members() {
    return this.app.service.member.collection.list
      .filter(
        (member) =>
          member.phones.length > 0 &&
          member.id !== this.call.connection.customParameters.get('callFromId'),
      )
      .sort(compareMembersByPresence)
  }

  handleSelect(transferee: TransfereeDescriptor) {
    this.debug('transferee selected (descriptor: %O)', transferee)
    this.confirmDialog.open(transferee)
    this.closeMenu()
  }

  /**
   * When the user goes back to the dialer, we want to reset the controller.
   */
  handleBack() {
    this.reset()
  }

  protected reset() {
    this.search = null
    this.searchFocused = null
    this.menuIndex = null
    this.keyboardList.stepper.selectIndex(0)
    this.confirmDialog.close()
  }
}

export class CallTransferConfirmDialogController {
  protected _transferee: TransfereeDescriptor | null = null
  protected _open = false

  constructor(protected app: AppStore, protected call: Call) {
    makeObservable<this, '_transferee' | '_open'>(this, {
      _transferee: observable.ref,
      _open: observable.ref,
      transferee: computed,
      transfereeName: computed,
      isOpen: computed,
      open: action.bound,
      close: action.bound,
      handleClose: action.bound,
      handleConfirm: action.bound,
    })
  }

  get transferee() {
    return this._transferee
  }

  get transfereeName(): string | null {
    if (!this.transferee) return null
    return typeof this.transferee.to === 'string'
      ? this.transferee.to
      : this.transferee.to.name
  }

  get isOpen(): boolean {
    return this._open
  }

  open(transferee: TransfereeDescriptor) {
    this._transferee = transferee
    this._open = true
  }

  close() {
    this._transferee = null
    this._open = false
  }

  handleClose() {
    this.close()
  }

  handleConfirm() {
    if (!this.transferee) return

    const params = this.getTransferParams()

    this.call.transfer(params).then(() => {
      this.app.toast.show({ message: 'Call transferred.' })
    })

    this.close()
  }

  getTransferParams(): TransferParams {
    const { transferee } = this

    switch (transferee.type) {
      case 'inbox':
        return { phoneNumberId: transferee.to.id }
      case 'member':
        return { userId: transferee.to.id }
      case 'member-via-inbox':
        return { userId: transferee.to.id, phoneNumberId: transferee.via }
      case 'contact':
        return { phoneNumber: transferee.via }
      case 'number':
        return { phoneNumber: transferee.to }
    }
  }
}

const warningMapping = {
  'high-rtt':
    'High network latency detected. High latency can result in perceptible delays in audio.',
  'low-mos': 'Overall network conditions detected that could impact call quality.',
  'high-jitter':
    'High jitter detected. Jitter is the measure of variability at which packets arrive at the sensors. High jitter can result in audio quality problems on the call, such as crackling and choppy audio.',
  'high-packet-loss':
    'High packet loss detected. Packet loss is measured as the percentage of packets that were sent but not received. High packet loss can result in choppy audio or a dropped call.',
  'high-packets-lost-fraction': 'High packet loss fraction detected.',
  'low-bytes-received': 'No data bytes are being received at the app.',
  'low-bytes-sent': 'No data bytes are being sent from the app.',
  'ice-connectivity-lost':
    'The selected connection was lost and reconnection will be required. Retry happens automatially. If this persists, you might need to hang up and call again.',
}
