import Typography from '@ui/Typography'
import React, { memo, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Banner from '../../component/banner'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../component/dialog'

const image = require('./enable-notifications.jpg').default

interface NotificationBannerProps {}

const NotificationBanner: React.FC<NotificationBannerProps> = function ({}) {
  const { notification } = useAppStore()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [granted, setGranted] = useState(
    notification.permission === 'granted' || notification.permission === undefined,
  )

  const handleClick = () => {
    if (notification.permission === 'default') {
      notification.requestPermission().then((permission) => {
        setGranted(permission === 'granted')
      })
    } else if (notification.permission === 'denied') {
      setDialogOpen(true)
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return granted ? null : (
    <>
      <Banner tag="notifications" onClick={handleClick}>
        <Typography variant="callout">
          Click to enable notifications for calls and messages.
        </Typography>
      </Banner>
      <Dialog maxWidth="xs" open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Enable Notifications</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To enable, click on the lock icon in the address bar and in the drop down menu
            for notifications, choose Allow.
          </DialogContentText>
        </DialogContent>
        <img src={image} width="100%" />
      </Dialog>
    </>
  )
}

export default memo(NotificationBanner)
