// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import { useStore } from '../../lib/hooks'
import { Workspace } from './workspace'
import Account from './account'
import Company from './company'
import Header from './header'
import invite from './invite'
import Number from './number'
import NumberSource from './number-source'
import Password from './password'
import PendingInvites from './pending-invites'
import Plans from './plans'
import OnboardingUiState from './store'
import subscription from './subscription'
import Usage from './usage'
import Verify from './verify'
import VerifyCode from './verify-code'

const OnboardingStoreContext = React.createContext<OnboardingUiState>(undefined)

const Onboarding: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  const app = useAppStore()
  const state = useStore(() => new OnboardingUiState(app), [])

  return (
    <OnboardingStoreContext.Provider value={state}>
      <div className={styles.root}>
        <Header />
        <div className={styles.main}>
          <div className={styles.content}>
            <Switch>
              <Route path="/onboarding/account" component={Account} />
              <Route path="/onboarding/workspace" component={Workspace} />
              <Route path="/onboarding/company" component={Company} />
              <Route path="/onboarding/invite" component={invite} />
              <Route path="/onboarding/number-type" component={NumberSource} />
              <Route path="/onboarding/number" component={Number} />
              <Route path="/onboarding/password" component={Password} />
              <Route path="/onboarding/pendingInvites" component={PendingInvites} />
              <Route path="/onboarding/plans" component={Plans} />
              <Route path="/onboarding/subscription" component={subscription} />
              <Route path="/onboarding/usage" component={Usage} />
              <Route path="/onboarding/verify-code" component={VerifyCode} />
              <Route path="/onboarding/verify" component={Verify} />
            </Switch>
          </div>
        </div>
      </div>
    </OnboardingStoreContext.Provider>
  )
}

export default observer(Onboarding)

export function useOnboardingState(): OnboardingUiState {
  return useContext(OnboardingStoreContext)
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    background: theme.palette.op.background.primary,
  },
  main: {
    flex: 1,
    paddingBottom: '3em',
    paddingTop: 120,
    overflow: 'auto',

    '@media (max-width: 520px)': {
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0em auto',
    boxSizing: 'border-box',

    "& @include media('<=phone')": {
      padding: '1em 0 3em',
    },
  },
}))
