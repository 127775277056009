import { makeStyles, Theme } from '@material-ui/core/styles'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'
import { useAppStore } from '@src/app/context'
import { isMac } from '../..'
import BaseAudioPlayer, { AudioPlayerProps } from '../../component/audio-player'
import Button from '../../component/button'
import { ScrollView } from '../../component/scrollview'
import Typography from '@ui/Typography'

interface HeaderProps extends Omit<React.HTMLProps<HTMLDivElement>, 'title'> {
  title: string | ReactNode
  subtitle: string | ReactNode
  backTitle?: string
  onBack?: () => void
  actions?: ReactNode
}

export const Header: React.FC<HeaderProps> = observer(function ({
  title,
  subtitle,
  backTitle,
  onBack,
  actions,
  className,
  ...props
}) {
  const styles = useStyles({})
  const { history } = useAppStore()

  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  return (
    <div {...props} className={cx(className, styles.header)}>
      {backTitle && (
        <Button
          variant="text"
          size="small"
          className={styles.backButton}
          onClick={handleBack}
        >
          <BackIcon className={styles.backIcon} />
          <Typography variant="caption1" color="textSecondary" fontWeight="regular">
            {backTitle}
          </Typography>
        </Button>
      )}
      <div className={styles.headerContent}>
        <div>
          <Typography variant="title3" color="textPrimary" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="body" color="textSecondary">
            {subtitle}
          </Typography>
        </div>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
  )
})

interface PageProps extends React.HTMLProps<HTMLDivElement> {
  centered?: boolean
}

export const Page: React.FC<PageProps> = function ({
  centered,
  className,
  children,
  ...props
}) {
  const styles = useStyles()

  const pageProps = {
    className: cx(styles.page, centered ? styles.centered : null),
    children: (
      <div className={styles.container} {...props}>
        <div className={styles.innerContainer}>{children}</div>
      </div>
    ),
  }

  return centered ? <div {...pageProps} /> : <ScrollView {...pageProps} />
}

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  highlighted?: boolean
}

export const Section: React.FC<SectionProps> = function ({
  highlighted,
  className,
  ...props
}) {
  const styles = useStyles({})
  return (
    <div
      className={cx(styles.section, highlighted && styles.highlightedSection, className)}
      {...props}
    />
  )
}

interface ItemProps {
  title: React.ReactNode
  description: React.ReactNode
  vertical?: boolean
  input?: React.ReactNode
}

export const Item: React.FC<ItemProps> = function ({
  title,
  description,
  input,
  children,
}) {
  const styles = useStyles({})

  return (
    <div className={styles.item}>
      <div className={styles.main}>
        <div className={styles.itemBody}>
          <Typography variant="callout" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="footnote" color="textTertiary" className={styles.itemDesc}>
            {description}
          </Typography>
        </div>
        <div className={styles.accessory}>{input}</div>
      </div>
      {children && <div className={styles.preview}>{children}</div>}
    </div>
  )
}

export const AudioPlayer: React.FC<AudioPlayerProps> = function ({
  className,
  ...props
}) {
  const styles = useStyles({})
  return <BaseAudioPlayer {...props} className={cx(styles.audio, className)} />
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 6,
    userSelect: 'all',
  },
  backIcon: {
    marginRight: 10,
  },
  backButton: {
    marginBottom: 16,
  },
  actions: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'fit-content',
    '&>*': {
      maxHeight: 40,
    },
    '&>*:not(:first-child)': {
      marginTop: 10,
    },
  },
  page: {
    flex: 1,
  },
  centered: {
    '& $container': {
      display: 'flex',
      height: '100%',
    },

    '& $innerContainer': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  container: {
    maxWidth: 956,
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: '48px 40px',
  },
  innerContainer: {
    maxWidth: 624,
  },
  item: {
    padding: '24px 0',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  preview: {
    marginTop: 15,
  },
  verticalItem: {
    flexDirection: 'column',
    alignItems: 'stretch',

    '& $accessory': {
      marginTop: 14,
    },
  },
  itemBody: {
    flex: 1,
  },
  itemDesc: {
    marginTop: 4,
  },
  accessory: {
    marginLeft: 25,
  },
  section: {
    marginBottom: 64,
  },
  highlightedSection: {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -16,
      right: -16,
      left: -16,
      bottom: -16,
      background: theme.palette.op.hover.primary,
      borderRadius: 6,
      zIndex: -1,
    },
  },
  audio: {
    background: theme.palette.op.hover.primary,
    borderRadius: 5,
    padding: 3,
  },
}))
