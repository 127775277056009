// @ts-strict-ignore
import { ById } from '../types'

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null
}

export function isTruthy<T>(value: T): value is NonNullable<T> {
  return Boolean(value)
}

export function toValues<T>(obj: ById<T>): T[] {
  return obj ? Object.values(obj) : undefined
}
