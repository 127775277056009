import { useCallback, useEffect, useState } from 'react'
import { useLoadPlacesApi } from '../useLoadPlacesApi'

export function usePlacesAutocomplete(options?: google.maps.places.AutocompleteOptions) {
  const [input, setInput] = useState<HTMLInputElement | null>(null)
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const { loaded } = useLoadPlacesApi()

  const handleInputReference = useCallback((element?: HTMLInputElement) => {
    setInput(element ?? null)
  }, [])

  const clear = useCallback(() => {
    if (!input) return
    input.value = ''
  }, [input])

  const focus = useCallback(() => {
    if (!input) return
    input.focus()
  }, [input])

  useEffect(() => {
    if (!loaded || !input) return

    const instance = new google.maps.places.Autocomplete(input, options)

    setAutocomplete(instance)

    return () => {
      instance.unbindAll()
    }
  }, [loaded, input, options])

  return { autocomplete, ref: handleInputReference, clear, focus }
}
