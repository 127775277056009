// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import ReactionsComponent, { IReactionGroup } from '../../reactions'
import Row from './row'
import { Item } from '../controller'
import { ItemProps } from '.'

export type ReactionsProps<I extends Item> = Required<
  Pick<ItemProps<I>, 'controller' | 'item'>
>

const Reactions = function <I extends Item>({ controller, item }: ReactionsProps<I>) {
  const styles = useStyles({ item })

  const handleReactionGroupClicked = useCallback(
    (group: IReactionGroup) => item.handleReaction(group.emoji),
    [item],
  )

  return (
    <Row
      controller={controller}
      item={item}
      className={cx(styles.root, item.isOutgoing ? styles.rtl : null)}
    >
      <ReactionsComponent
        reactions={item.reactions}
        size={item.display.dimensions.reactionsHeight}
        onGroupClicked={handleReactionGroupClicked}
        className={styles.reactions}
      />
    </Row>
  )
}

export default observer(Reactions)

const useStyles = makeStyles<Theme, Partial<ReactionsProps<Item>>>(
  (theme) => ({
    root: {},
    rtl: {
      '& $reactions': {
        justifyContent: 'flex-end',
      },
    },
    reactions: ({ item }) => ({
      flexWrap: 'wrap',
      marginTop: item.display.dimensions.reactionsMarginTop,
      marginRight: item.display.dimensions.reactionsMarginHorizontal,
      marginBottom: item.display.dimensions.reactionsMarginBottom,
      marginLeft: item.display.dimensions.reactionsMarginHorizontal,
    }),
  }),
  { name: Reactions.name },
)
