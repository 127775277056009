import { ItemDimensions, getDefaultItemDimensions } from '../feed/dimensions'

export const itemDimensions: ItemDimensions = getDefaultItemDimensions({
  bubbleFontSize: 13,
  messagePaddingHorizontal: 12,
  messagePaddingVertical: 6,
  reactionsHeight: 20,
  avatarSize: 30,
  reactionsMarginTop: -12,
  reactionsMarginHorizontal: 45,
  senderNameMarginHorizontal: 48,
})

export interface ThreadDimensions {
  readonly rootPadding: number
  readonly headerHeight: number
  readonly headerPaddingHorizontal: number
  readonly previousMarginTop: number
  readonly previousMarginHorizontal: number
  readonly previousStackItemPaddingHorizontal: number
  readonly previousStackItemPaddingVertical: number
  readonly previousStackItemMarginTop: number
  readonly commentsMarginTop: number
  readonly commentsMarginBottom: number
  readonly commentsMarginHorizontal: number
  readonly footerMarginVertical: number
}

const rootPadding = 14
const footerMarginVertical = 10
const commentsMarginTop = footerMarginVertical - rootPadding
const previousStackItemMarginTop = -22

export const defaultThreadDimensions: ThreadDimensions = {
  rootPadding,
  headerHeight: 28,
  headerPaddingHorizontal: 8,
  previousMarginTop: rootPadding - commentsMarginTop - previousStackItemMarginTop,
  previousMarginHorizontal: itemDimensions.bubbleMarginHorizontal,
  previousStackItemPaddingHorizontal: 11,
  previousStackItemPaddingVertical: 6,
  previousStackItemMarginTop,
  commentsMarginTop: commentsMarginTop,
  commentsMarginBottom: rootPadding,
  commentsMarginHorizontal: rootPadding,
  footerMarginVertical: footerMarginVertical,
}
