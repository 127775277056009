import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import useKeyStepper from '../../../lib/use-key-stepper'
import Content from '../common/content'
import Item from '../common/item'

export type DestinationType =
  | 'repeat'
  | 'default'
  | 'external'
  | 'internal'
  | 'user'
  | 'voicemail'
  | 'audio'

const itemDetails = (type: DestinationType): { label: string; premium: boolean } => {
  switch (type) {
    case 'default':
      return { label: 'Let the call through', premium: false }
    case 'external':
      return { label: 'Forward to an outside phone number', premium: false }
    case 'internal':
      return { label: 'Forward to an OpenPhone number', premium: false }
    case 'user':
      return { label: 'Send to a member of the phone number', premium: true }
    case 'audio':
      return { label: 'Play an audio', premium: true }
    case 'voicemail':
      return { label: 'Send to voicemail', premium: true }
    case 'repeat':
      return { label: 'Repeat the options', premium: true }
  }
}

interface IvrDestinationOptionsProps {
  onSelect: (type: DestinationType) => void
  options: DestinationType[]
}

const defaultOptions: DestinationType[] = [
  'internal',
  'external',
  'default',
  'user',
  'voicemail',
  'audio',
]

const IvrDestinationOptions: React.FC<IvrDestinationOptionsProps> = function ({
  onSelect,
  options = defaultOptions,
}) {
  const styles = useStyles({})
  const { command, service } = useAppStore()
  const subscription = service.billing.subscription
  const { selectedIndex, getItemProps } = useKeyStepper({
    items: options,
    name: 'command/ivr-destination/options',
    handleSelect: (item) => {
      const details = itemDetails(item)
      if (details.premium && !subscription.isPremium) {
        command.present({ name: 'requires premium' })
      } else {
        onSelect(item)
      }
    },
  })

  const renderItem = (item: DestinationType, index: number) => {
    const details = itemDetails(item)
    return (
      <Item
        key={item}
        className={styles.item}
        {...getItemProps(index)}
        highlighted={index === selectedIndex}
      >
        <Typography variant="body2" color="textPrimary">
          {details.label}
        </Typography>
        {details.premium && !subscription.isPremium && (
          <Typography variant="caption" color="textSecondary">
            ⚡️ Premium
          </Typography>
        )}
      </Item>
    )
  }

  return <Content>{options.map(renderItem)}</Content>
}

export default observer(IvrDestinationOptions)

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
