// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import ForwardIcon from '@material-ui/icons/Forward'
import React, { useState } from 'react'
import { EntityPhoneNumber, Member, PhoneNumber } from '../../../service/model'
import { Command } from '../common-v2'
import Header from '../common/header'
import MemberPicker from '../common/member-picker'
import OpPhoneNumberSelector from '../op-phone-number-selector'
import PhoneNumberPicker from '../phone-number-selector'
import SetGreetingCommand from '../set-greeting'
import Options, { DestinationType } from './options'

export interface IvrDestinationProps {
  currentPhoneNumber: EntityPhoneNumber
  phoneNumbers: EntityPhoneNumber[]
  options?: DestinationType[]
  onAdd: () => void
  onSelect: (destination: string) => void
}

const IvrDestination: React.FC<IvrDestinationProps> = function ({
  currentPhoneNumber,
  phoneNumbers,
  options,
  onAdd,
  onSelect,
}) {
  const styles = useStyles({})
  const [type, setType] = useState<DestinationType>(null)

  const handleSelectType = (type: DestinationType) => {
    if (type === 'repeat') {
      onSelect(null)
    } else if (type === 'default') {
      onSelect(currentPhoneNumber.id)
    } else if (type === 'voicemail') {
      onSelect('VM')
    } else {
      setType(type)
    }
  }

  const handleExternalSet = (number: string) => {
    onSelect(number)
  }

  const handleInternalSet = (numbers: EntityPhoneNumber[]) => {
    onSelect(numbers[0].id)
  }

  const handleAudioSelected = (url) => {
    onSelect(url)
  }

  const handleMemberSelect = (member: Member) => {
    onSelect(member.id)
  }

  if (!type) {
    return (
      <Command>
        <Header
          title="Choose a destination"
          subtitle="Select where the callers should be routed to"
        />
        <Options options={options} onSelect={handleSelectType} />
      </Command>
    )
  } else if (type === 'external') {
    return (
      <Command>
        <Header
          title="Choose a destination"
          subtitle="Callers will be routed to this phone number"
        />
        <PhoneNumberPicker
          actionTitle="Forward"
          actionIcon={<ForwardIcon />}
          onSelect={handleExternalSet}
        />
      </Command>
    )
  } else if (type === 'internal') {
    return (
      <Command>
        <Header
          title="Choose a destination"
          subtitle="Callers will be routed to this phone number"
        />
        <OpPhoneNumberSelector
          phoneNumbers={phoneNumbers}
          onAdd={onAdd}
          onSelect={handleInternalSet}
        />
      </Command>
    )
  } else if (type === 'audio') {
    return (
      <SetGreetingCommand
        title="Set an audio"
        description="Set the audio you want to play when this option is selected. After the audio is played, the caller will hear the phone options again."
        onComplete={handleAudioSelected}
      />
    )
  } else if (type === 'user') {
    return (
      <Command>
        <Header
          title="Choose a destination"
          subtitle="Callers will be routed to this phone number"
        />
        <MemberPicker
          includeIds={currentPhoneNumber.users.map((u) => u.id)}
          onSelect={handleMemberSelect}
        />
      </Command>
    )
  }

  return null
}

export default IvrDestination

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
