// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import AudioPlayer from '../../../component/audio-player'
import { CircularProgress } from '../../../component/progress'
import TextareaAutosize from '../../../component/textarea-autoresize'
import { logError } from '../../../lib/log'
import useInputState from '../../../lib/use-input-state'
import Content from '../common/content'
import Footer from '../common/footer'
import Button from '../common/footer-button'

interface TextToSpeechProps {
  defaultText?: string
  onComplete: (url: string, text: string) => void
}

const TextToSpeech: React.FC<TextToSpeechProps> = function ({ defaultText, onComplete }) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const [changed, setChanged] = useState(Boolean(defaultText))
  const [text, setText] = useInputState(defaultText || '')
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)

  const handleTextChange = (e) => {
    setText(e)
    setChanged(true)
  }

  const handleConvert = () => {
    setLoading(true)
    setUrl(null)
    service.transport.communication
      .tts(text)
      .then((url) => {
        setChanged(false)
        setUrl(url)
      })
      .catch(logError)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSave = () => {
    onComplete(url, text)
  }

  return (
    <>
      <Content>
        <div className={styles.root}>
          <TextareaAutosize
            autoFocus
            value={text}
            onChange={handleTextChange}
            className={styles.input}
            placeholder="Write something that greets the caller and presents the phone menu options"
          />
          <Typography variant="caption" color="textSecondary" style={{ marginTop: 5 }}>
            You can use text or{' '}
            <a
              target="_blank"
              style={{ textDecoration: 'none', color: 'pink' }}
              href="https://developers.google.com/assistant/actions/reference/ssml"
            >
              ssml.
            </a>
          </Typography>
          {url && (
            <AudioPlayer
              media={{ url, type: 'audio/mpeg' }}
              style={{ marginTop: '2rem' }}
            />
          )}
        </div>
      </Content>
      <Footer
        actions={
          <>
            {loading ? (
              <div style={{ marginRight: 15, display: 'flex', alignItems: 'center' }}>
                <CircularProgress style={{ marginRight: 16 }} size={20} />
                <Typography variant="caption" color="textPrimary">
                  Converting...
                </Typography>
              </div>
            ) : (
              <>
                <Button
                  disabled={!changed || loading}
                  variant="contained"
                  onClick={handleConvert}
                >
                  Speak it
                </Button>
                {url && (
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={loading}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default TextToSpeech

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '1rem 2rem',
    flexDirection: 'column',
  },
  input: {
    resize: 'none',
    fontSize: '0.9rem',
    minHeight: 70,
  },
}))
