import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { isMac } from '../config'

interface HeaderProps extends React.HTMLProps<HTMLDivElement> {}

const Header: React.FC<HeaderProps> = function ({ className, ...props }) {
  const styles = useStyles({})
  return <div {...props} className={cx(styles.header, className)} />
}

const HeaderSpacer: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  return <div className={styles.spacer} />
}

export default Header
export { HeaderSpacer }

const useStyles = makeStyles((theme) => ({
  header: {
    outline: 'none',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 24px',
    justifyContent: 'space-between',
    '-webkit-app-region': isMac ? 'drag' : undefined,

    '& h1': {
      flex: 1,
    },

    '& > *': {
      marginRight: '0.25rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  spacer: {
    flex: 1,
  },
}))
