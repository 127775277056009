// @ts-strict-ignore
import {
  action,
  computed,
  makeObservable,
  makeAutoObservable,
  reaction,
  observable,
} from 'mobx'
import { Subscription } from 'rxjs'
import KeyboardListController from '../../component/keyboard-list/controller'
import { Collection, Contact, ContactView } from '../../service/model'
import Stepper from '../../lib/stepper'
import AppStore from '../store'
import {
  ContactInputBarRegistry,
  ContactNoteInputBarRegistry,
} from '../contact/input-bar'
import CompanyFilterController from './company-filter-controller'
import { logError } from '../../lib/log'
import { DisposeBag } from '../../lib/dispose'

export default class ContactsUiStore {
  readonly view = new ContactView()
  readonly contactInputBarRegistry = new ContactInputBarRegistry(this.app)
  readonly contactNoteInputBarRegistry = new ContactNoteInputBarRegistry(this.app)
  readonly companiesFilterController = new CompanyFilterController(this.app, this)
  readonly sortedContacts = new Collection<Contact>({
    filter: (contact) => {
      if (!this.view.filter(contact) && !contact.local) {
        return false
      }

      const search = this.search?.toLowerCase().trim()

      return (
        !search ||
        contact.name.toLowerCase().includes(search) ||
        contact.role?.toLocaleLowerCase().includes(search) ||
        contact.company?.toLocaleLowerCase().includes(search)
      )
    },
    compare: this.view.compare,
  })
  readonly keyboardList = new ContactsKeyboardListController(this.app, this)

  checked = new Set<string>()

  private _search: string = null
  private _loaded = false
  private disposeBag = new DisposeBag()
  private observerSubscription: Subscription

  constructor(private app: AppStore) {
    makeAutoObservable<this, 'disposeBag'>(this, {
      disposeBag: false,
    })

    /**
     * Sync local with remote
     */
    this.fetchRemote()

    this.disposeBag.add(
      reaction(
        () => [this.view.company, this.view.source, this.search],
        () => this.bindCollections(),
        { name: 'Contacts.FiltersChanged', fireImmediately: true },
      ),

      reaction(
        () => this.sortedContacts.list,
        () => {
          // Make sure that the `checked` items are always part of the visible list
          if (this.checked.size > 0) {
            this.checked.forEach((checkedId) => {
              if (!this.sortedContacts.has(checkedId)) {
                this.checked.delete(checkedId)
              }
            })
          }
        },
      ),

      // Fetch changes to contacts when data goes stale
      this.app.onDataNeedsRefresh.subscribe(() => {
        this.fetchRemote()
      }),
    )
  }

  get search() {
    return this._search
  }

  set search(search: string) {
    this._search = search
  }

  get data() {
    return this.sortedContacts
  }

  get list() {
    return this.data.list
  }

  get contact() {
    return this.list[this.keyboardList.stepper.index]
  }

  show(contactId?: string) {
    if (contactId) {
      this.setSelectedId(contactId)
    }
    this.app.history.push('/contacts')
  }

  loadDataIfNecessary() {
    if (this._loaded) return
    this._loaded = true
    this.app.service.contact.loadAll()
  }

  setSelectedId = (id: string) => {
    this.keyboardList.selectedId = id
    this.keyboardList.stepper.select(this.list.find((c) => c.id === id) ?? null)
  }

  setSearch = (search: string) => {
    this.search = search
  }

  toggleChecked = (id: string) => {
    if (this.checked.has(id)) {
      this.checked.delete(id)
    } else {
      this.checked.add(id)
    }
  }

  selectAll = () => {
    this.list.map((c) => c.id).forEach((id) => this.checked.add(id))
  }

  unselectAll = () => {
    this.checked.clear()
  }

  newContact = () => {
    if (this.contact?.local) return
    let existing = this.sortedContacts.find((c) => c.local)
    if (existing) {
      this.setSelectedId(existing.id)
      return
    }
    const contact = new Contact(this.app.service, { local: true })
    contact.addItem({ type: 'phone-number' })
    contact.addItem({ type: 'email' })
    contact.saveLocal()
    this.setSelectedId(contact.id)
  }

  bulkImport = (contacts: Contact[]) => {
    return this.app.service.contact.createBulk(contacts)
  }

  shareSelected = (shareIds: string[]) => {
    const ids = [...this.checked]
    this.app.service.contact
      .shareBulk(ids, shareIds, (progress) => {
        this.app.toast.showLoading(`Updating... (${Math.round(progress)}%)`)
      })
      .then(() => {
        this.app.toast.show({
          message: `${ids.length.toLocaleString('en')} contacts have been updated.`,
        })
      })
      .catch(this.app.toast.showError)
  }

  deleteSelected = () => {
    if (this.checked.size === this.sortedContacts.list.length) {
      this.view.reset()
    }
    this.app.service.contact.deleteBulk([...this.checked]).catch(this.app.toast.showError)
  }

  private bindCollections() {
    this.observerSubscription?.unsubscribe()
    this.observerSubscription = this.sortedContacts.bind(
      this.app.service.contact.collection,
    )
  }

  private fetchRemote() {
    return Promise.all([
      this.app.service.contact.fetchMissing().catch(logError),
      this.app.service.contact.fetchTemplates().catch(logError),
    ])
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}

export class ContactsKeyboardListController extends KeyboardListController<Contact> {
  readonly itemHeight = 40
  readonly itemHoverMode = 'independent'
  override readonly itemSpacing = { bottom: 2 }
  readonly stepper = new Stepper(this, { name: 'contacts' })

  selectedId: string = null

  constructor(protected app: AppStore, protected store: ContactsUiStore) {
    super()

    makeObservable(this, {
      selectedId: observable.ref,
      data: computed,
      handleEnter: action.bound,
    })

    reaction(
      () =>
        this.app.history.location.pathname.startsWith('/contacts') &&
        this.data.list.length &&
        this.data.get(this.selectedId)?.sortName,
      () => {
        if (this.stepper.index > this.data.list.length) {
          this.stepper.selectIndex(this.data.list.length - 1)
        }
        if (this.data.list[this.stepper.index]?.id !== this.selectedId) {
          this.stepper.select(
            this.data.list.find((c) => c.id === this.selectedId) ?? null,
          )
        }
      },
    )

    reaction(
      () => this.stepper.index,
      (index) => {
        this.selectedId = this.data.list[index]?.id
      },
      { name: 'Contacts.StepperIndexChanged', fireImmediately: true },
    )
  }

  get data() {
    return this.store.data
  }

  override handleEnter(item: Contact, index: number) {
    this.stepper.selectIndex(index)
  }
}
