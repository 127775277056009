// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { MessageMedia } from '../../service/model'

interface VideoViewerProps {
  media: MessageMedia
}

const VideoViewer: React.FC<VideoViewerProps> = function ({ media }) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)
  const mediaRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (media.url) {
      mediaRef.current.src = media.url
    } else if (media.file) {
      mediaRef.current.src = URL.createObjectURL(media.file)
    }
  }, [media])

  return (
    <div ref={rootRef} className={styles.root}>
      <video autoPlay ref={mediaRef} controls tabIndex={-1} className={styles.video} />
    </div>
  )
}

export default observer(VideoViewer)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    outline: 'none',
    maxWidth: 'calc(100% - 175px)',
    maxHeight: '100%',
  },
}))
