// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isValidEmail } from '@openphone/lib'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useOnboardingState } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import IconButton from '../../component/icon-button'
import { DeleteIcon } from '../../component/icons/Tint/16/General'
import { NativeSelect } from '../../component/select'
import TextField from '../../component/textfield-v2'
import Typography from '@ui/Typography'
import { removeAtIndex, replaceAtIndex } from '../../lib/collections'
import keyboard from '../../lib/keyboard'
import useInputState from '../../lib/use-input-state'
import { typography } from '../../theme'
import { Invitation } from '../../types'
import { Actions, Card } from './common'

interface InviteProps {}

const Invite: React.FC<InviteProps> = function ({}) {
  const styles = useStyles({})
  const { service, toast } = useAppStore()
  const state = useOnboardingState()
  const [email, setEmail] = useInputState('')
  const [invitations, setInvitations] = useState<Invitation[]>([])

  const handleSelect = (event: any, index: number) => {
    const { value } = event.target
    setInvitations(
      replaceAtIndex(invitations, { ...invitations[index], role: value }, index),
    )
  }

  const handleAddEmail = () => {
    const newEmail = email.trim()
    if (newEmail === service.user.current.email) {
      toast.showError(new Error("You can't invite yourself."))
    } else if (isValidEmail(newEmail)) {
      const isDuplicate = invitations.some((i) => i.email === newEmail)
      if (isDuplicate) {
        toast.showError(new Error('The email is already in the list.'))
      } else {
        setInvitations((invitations) => [
          ...invitations,
          { email, role: 'member', phoneNumberId: null },
        ])
      }
      setEmail('')
    } else {
      toast.showError(new Error('Email address is not valid.'))
    }
  }

  const handleDelete = (index: number) => {
    setInvitations((invitations) => removeAtIndex(invitations, index))
  }

  const handleSend = () => {
    state.sendInvitesAndAdvance(invitations)
  }

  const handleSkip = () => {
    state.advance('next')
  }

  return (
    <Card
      emoji="💌"
      title="Invite your team"
      description="Each member that accepts their invitation will get their own private OpenPhone number that they can choose."
    >
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField
          autoFocus
          size={48}
          label="Email"
          value={email}
          onChange={setEmail}
          placeholder="Email address..."
          rootStyle={{ flex: 1, marginRight: 16 }}
          onKeyDown={keyboard.onEnter(handleAddEmail)}
        />
        <Button
          height={48}
          variant="outlined"
          color="primary"
          onClick={handleAddEmail}
          disabled={!email}
        >
          Add
        </Button>
      </div>
      {invitations.length > 0 && (
        <div style={{ marginTop: 24 }}>
          {invitations.map((invitation, index) => (
            <div key={index} className={styles.item}>
              <Typography nowrap variant="footnote" style={{ marginRight: 'auto' }}>
                {invitation.email}
              </Typography>
              <NativeSelect
                value={invitation.role}
                onChange={(e) => handleSelect(e, index)}
                inputProps={{ className: styles.select }}
                style={{ flex: '0 0 auto' }}
              >
                <option value="owner">Owner</option>
                <option value="admin">Admin</option>
                <option value="member">Member</option>
              </NativeSelect>
              <IconButton
                title="Remove invite"
                tooltipPlacement="right"
                size="small"
                icon={<DeleteIcon />}
                style={{ marginLeft: 8, flex: '0 0 auto' }}
                onClick={() => handleDelete(index)}
              />
            </div>
          ))}
        </div>
      )}
      <Actions>
        <Button
          fullWidth
          disabled={invitations.length === 0}
          variant="contained"
          color="primary"
          onClick={handleSend}
        >
          Send invites
        </Button>
        <Button
          fullWidth
          variant="text"
          color="textSecondary"
          fontWeight="regular"
          height={36}
          onClick={handleSkip}
        >
          Skip for now
        </Button>
      </Actions>
    </Card>
  )
}

export default observer(Invite)

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
    borderRadius: 5,
    padding: '12px 11px 12px 16px',
    background: theme.palette.op.hover.primary,
  },
  select: {
    ...typography.footnote,
    color: theme.palette.op.text.secondary,
    height: 20,
    border: 'none',
    background: 'none',
    boxShadow: 'none !important',
    paddingRight: '30px !important',

    '&:hover, &:focus': {
      border: 'none !important',
      background: 'none !important',
      boxShadow: 'none !important',
    },
  },
}))
