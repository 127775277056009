import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import Typography from '@ui/Typography'
import Tooltip from '@src/component/tooltip'
import { ThreadResolvedIcon } from '@src/component/icons/Tint/16/General'
import { CircularProgress } from '@src/component/progress'
import { ImportStatus } from './controller'

function ImportStatus({
  status,
  canReimport,
  onReimport,
  onRetry,
}: {
  status: ImportStatus
  canReimport: boolean
  onReimport: () => void
  onRetry: () => void
}) {
  const styles = useStyles({ status })

  if (status === 'loading') {
    return (
      <Typography variant="footnote" className={styles.root}>
        <CircularProgress size={12} color="inherit" />
        <Tooltip
          title="This can take a bit of time depending on how many contacts there are to import. You can leave this page and return later to check the progress."
          className={styles.tooltip}
        >
          <span className={styles.status}>Importing your HubSpot contacts...</span>
        </Tooltip>
      </Typography>
    )
  }

  if (status === 'failed') {
    return (
      <Typography className={styles.root} variant="footnote">
        ⚠️
        <span className={styles.status}>Failed to import contacts.</span>
        <button className={styles.actionButton} onClick={onRetry}>
          Retry
        </button>
      </Typography>
    )
  }

  return (
    <Typography className={styles.root} variant="footnote">
      <ThreadResolvedIcon className={styles.successIcon} />
      <span className={styles.status}>HubSpot contacts imported.</span>
      <Tooltip
        title={
          canReimport
            ? 'This can take a bit of time depending on how many contacts there are to import.'
            : 'You can only re-import all your HubSpot contacts once per day'
        }
        className={styles.tooltip}
      >
        <div>
          <button
            className={styles.actionButton}
            disabled={!canReimport}
            onClick={onReimport}
          >
            Re-import all contacts
          </button>
        </div>
      </Tooltip>
    </Typography>
  )
}

export default ImportStatus

const useStyles = makeStyles<Theme, { status: ImportStatus }>((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    columnGap: 7,
  },

  tooltip: {
    maxWidth: 320,
    textAlign: 'center',
  },

  actionButton: {
    all: 'unset',
    cursor: 'pointer',
    color: theme.palette.op.primary[2],

    '&:disabled': {
      color: theme.palette.op.gray[4],
      pointerEvents: 'none',
    },
  },

  status: {
    color: ({ status }) => {
      if (status === 'loading') return theme.palette.op.gray[3]
      if (status === 'failed') return theme.palette.op.tag.yellow.text
      return theme.palette.op.gray[2]
    },
  },

  successIcon: {
    color: theme.palette.op.secondary.green1,
  },
}))
