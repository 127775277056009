import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Callout from '../../component/callout'
import Typography from '@ui/Typography'
import { useReaction } from '../../lib/hooks'
import AppStore from '../store'

interface UpdateProviderProps {
  app: AppStore
}

const UpdateProvider: React.FC<UpdateProviderProps> = function ({ app, children }) {
  const styles = useStyles({})

  useReaction(
    () => app.storesLoaded,
    (loaded) => {
      if (!loaded) return
      app.update.startChecking()
    },
    { fireImmediately: true },
  )

  return (
    <>
      {children}
      {app.update.showPrompt && (
        <Callout
          elevated
          className={styles.root}
          size="large"
          icon={<Typography variant="title1">🎁</Typography>}
          title="Updates available"
          body={`A new and better version of the app is installed and ready. ${
            app.update.strategy === 'electron'
              ? 'Relaunch the app to get all the changes.'
              : 'Reload the page to get all the changes.'
          }`}
          onDismiss={app.update.hidePrompt}
          actions={[
            { title: 'Maybe later', type: 'secondary', onClick: app.update.hidePrompt },
            {
              title: app.update.strategy === 'electron' ? 'Relaunch' : 'Reload',
              type: 'primary',
              onClick: app.update.installAndRestart,
            },
          ]}
        />
      )}
    </>
  )
}

export default observer(UpdateProvider)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    position: 'absolute',
    maxWidth: 450,
    right: 50,
    bottom: 50,
  },
}))
