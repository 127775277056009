import { makeStyles, styled, Theme } from '@material-ui/core'
import Switch from '@src/component/switch'
import Typography from '@ui/Typography'
import React, { type ReactNode } from 'react'
import cx from 'classnames'
interface CollapsibleFooterCardProps {
  children: ReactNode
}

const CollapsibleFooterCard = ({ children }: CollapsibleFooterCardProps) => {
  const styles = useStyles()
  return <div className={styles.root}>{children}</div>
}

interface CollapsibleFooterHeaderProps {
  title: string | ReactNode
  subtitle: string | ReactNode
  areAllSelected: boolean
  onAllSelected: (_: any, all: boolean) => void
  className?: string
}

export const CollapsibleFooterHeader = ({
  title,
  subtitle,
  areAllSelected,
  onAllSelected,
  className,
}: CollapsibleFooterHeaderProps) => {
  const styles = useStyles()
  return (
    <label className={cx(styles.header, className)}>
      <Typography variant="footnote" color="textPrimary" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="caption2" color="textSecondary" className={styles.subtitle}>
        {subtitle}
      </Typography>
      <Switch
        checked={areAllSelected}
        onChange={onAllSelected}
        className={styles.switch}
      />
    </label>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    gridTemplateAreas: `
        "title switch"
        "subtitle switch"
      `,
    alignItems: 'center',
  },
  title: {
    gridArea: 'title',
  },
  subtitle: {
    gridArea: 'subtitle',
  },
  switch: {
    gridArea: 'switch',
  },
}))

export default CollapsibleFooterCard
