import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/button-v2'
import { ReactionEmojis } from '@src/service/model'
import { getEmojiVariation, stripSkinVariation } from '@src/lib/emoji'

interface ReactionEmojisPickerProps {
  defaultEmojis: ReactionEmojis
  onChange: (emojis: ReactionEmojis) => void
  skinTone?: string
}

function ReactionEmojisPicker({
  defaultEmojis,
  onChange,
  skinTone,
}: ReactionEmojisPickerProps) {
  const styles = useGroupStyles()
  const [state, setState] = useState(defaultEmojis)

  const onChangeEmoji = (index: number, emoji: string) => {
    setState((current) => {
      const copy: ReactionEmojis = [...current]
      copy[index] = emoji

      return copy
    })
  }

  const initialRenderRef = useRef(true)

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false
      return
    }

    onChange(state)
  }, [state])

  return (
    <div className={styles.root}>
      {state.map((emoji, index) => {
        const emojiWithSkinTone = skinTone
          ? getEmojiVariation(stripSkinVariation(emoji), skinTone)
          : stripSkinVariation(emoji)

        return (
          <EmojiItem
            key={index}
            emoji={emojiWithSkinTone}
            onChange={(emoji) => onChangeEmoji(index, emoji)}
            skinTone={skinTone}
          />
        )
      })}
    </div>
  )
}

export default observer(ReactionEmojisPicker)

const useGroupStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

interface EmojiItemProps {
  skinTone?: string
  emoji: string
  onChange: (emoji: string) => void
}

const EmojiItem = observer(({ emoji, onChange, skinTone }: EmojiItemProps) => {
  const { showEmojiPicker } = useAppStore()
  const styles = useItemStyles({})
  const emojiRef = React.useRef<HTMLButtonElement>(null)

  return (
    <Button
      variant="outlined"
      color="textPrimary"
      className={styles.root}
      ref={emojiRef}
      onClick={() => {
        showEmojiPicker({
          anchorEl: emojiRef.current,
          onEmojiSelected: onChange,
          skinTone,
        })
      }}
    >
      {emoji}
    </Button>
  )
})

EmojiItem.displayName = 'EmojiItem'

const useItemStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 40,
    height: 40,
    padding: 0,
    fontSize: 21,

    '&:not(:first-child)': {
      marginLeft: 8,
    },
  },
}))
