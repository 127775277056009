import React, { useMemo } from 'react'
import Menu from './menu-v2'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiPopover, { PopoverProps as MuiPopoverProps } from '@material-ui/core/Popover'
import { global } from '../theme'
import { mergeClasses } from '@src/lib/util'

export interface PopoverMenuProps extends MuiPopoverProps {
  minWidth?: number
  maxWidth?: number
  size?: 'small' | 'medium'
}

const PopoverMenu: React.FC<PopoverMenuProps> = function ({
  minWidth,
  maxWidth,
  children,
  size,
  open,
  classes: classesProp,
  ...props
}) {
  const styles = useStyles({ minWidth, size, maxWidth })
  const classes = useMemo(() => mergeClasses(styles, classesProp), [styles, classesProp])

  return (
    <MuiPopover
      open={open}
      transitionDuration={open ? 200 : 0}
      classes={classes}
      {...props}
    >
      <Menu size={size}>{children}</Menu>
    </MuiPopover>
  )
}

export default PopoverMenu
export * from './menu-v2'

const useStyles = makeStyles<Theme, Partial<PopoverMenuProps>>((theme) => ({
  paper: ({ minWidth, maxWidth }) => ({
    ...global.popover(theme),
    minWidth,
    maxWidth,
  }),
}))
