// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useBackdropClick, useEditor } from '..'
import { ContactTemplateItem } from '../../../../service/model/contact-template-item'
import { TemplateTagItemOption } from '../../../../types'
import ColorPicker from './color-picker'
import TagList from './list'

export interface EditTagsProps {
  defaultValue: string[]
  templateItem: ContactTemplateItem
  onSave: (value: string[], newOption?: TemplateTagItemOption) => void
}

const EditTags: React.FC<EditTagsProps> = function ({
  defaultValue,
  templateItem,
  onSave,
}) {
  const [, hide] = useEditor()
  const [values, setValues] = useState<string[]>(defaultValue)
  const [newValue, setNewValue] = useState<string>(null)
  const [colorSelection, setColorSelection] = useState(false)

  useBackdropClick(() => {
    onSave(values)
    hide()
  }, [])

  const handleNew = (value: string) => {
    if (value === undefined) {
      hide()
    } else {
      setNewValue(value)
      setColorSelection(true)
    }
  }

  const handleSelect = (value: string) => {
    if (!defaultValue.includes(value)) {
      onSave([...defaultValue, value])
      hide()
    }
  }

  const handleColorSelection = (color: string) => {
    onSave([...defaultValue, newValue], { name: newValue, color })
    hide()
  }

  const handleDelete = (value: string) => {
    setValues(values.filter((v) => v !== value))
  }

  return colorSelection ? (
    <ColorPicker onSelect={handleColorSelection} />
  ) : (
    <TagList
      values={values}
      templateItem={templateItem}
      onNew={handleNew}
      onSelect={handleSelect}
      onDelete={handleDelete}
      onHide={hide}
    />
  )
}

export default observer(EditTags)
