// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import Service from '.'
import { IVoiceSession } from '../types'
import { TransferParams } from './transport/voice'

export default class VoiceStore {
  session: IVoiceSession

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  recordings = {
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.root.transport.voice.recordings.bulkDelete(activityId, recordingIds)
    },
  }

  refreshToken(): Promise<IVoiceSession> {
    return this.root.transport.voice.token().then(
      action((res) => {
        this.session = {
          token: res.token,
          expiry: Date.parse(res.expiry),
        }
        return this.session
      }),
    )
  }

  record(
    callSid: string,
    phoneNumberId: string,
    action: 'start' | 'pause' | 'resume' | 'stop',
  ) {
    return this.root.transport.voice.record(callSid, phoneNumberId, action)
  }

  transfer(callSid: string, params: TransferParams) {
    return this.root.transport.voice.transfer(callSid, params)
  }
}
