// @ts-strict-ignore
import Debug from 'debug'
import { fromEvent, Subscription } from 'rxjs'
import { isMacDesktop } from './device'
import { hasInputValue, isEditableElement, isElement } from './dom'
import useEffectBeforeMount from './use-effect-before-mount'

const debug = Debug('op:use-keyboard-shortcut')

type KeyboardHandler = (shortcut: string, event: KeyboardEvent) => void
export type FilterHandler = (shortcut: string, event: KeyboardEvent) => boolean

let metaKeyDown = false
const subscriptions = new WeakMap<
  Element | Document,
  {
    handlers: { name: string; handler: KeyboardHandler; filter: FilterHandler }[]
    subscription: Subscription
  }
>()

/**
 * Keep track of whether the Meta key is pressed or not
 */
fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
  if (event.key === 'Meta') {
    metaKeyDown = true
  }
})

fromEvent(document, 'keyup').subscribe((event: KeyboardEvent) => {
  if (event.key === 'Meta') {
    metaKeyDown = false
  }
})

/**
 * When you use command+tab or command+space that are OS level shortcuts, the browser
 * window loses focus and the 'keydown' event listener is not called. We need to reset
 * active keys on focus as a workaround.
 */
fromEvent(window, 'focus').subscribe(() => {
  metaKeyDown = false
})

/**
 * Detect Select All and reverse (Command+A)
 */
function handleSelectAll() {
  const isExtent = (node: unknown) => {
    return isElement(node) && node.classList.contains('extent')
  }

  // check the selection extends over two extent nodes (top and bottom)
  const s = window.getSelection()
  if (s.anchorNode === s.focusNode || !isExtent(s.anchorNode) || !isExtent(s.focusNode)) {
    return
  }

  // clear page's selection (this isn't perfect and a user may see
  // a flash of selection anyway- use selectstart + rAF to fix this)
  s.removeAllRanges()
}

document.addEventListener('selectionchange', handleSelectAll)

export interface DefaultWithInputOnScreenOptions {
  allowedShortcutsAlways?: string[]
  allowedShortcutsWhenEmpty?: string[]
}

export const defaultWithInputOnScreen =
  ({
    allowedShortcutsAlways = [],
    allowedShortcutsWhenEmpty = [],
  }: DefaultWithInputOnScreenOptions = {}) =>
  (shortcut: string, event: KeyboardEvent) => {
    if (event.defaultPrevented) return false
    if (!isEditableElement(event.target)) return true
    if (allowedShortcutsAlways.includes(shortcut)) return true

    const allowKeyboardWhenEmpty = event.target.hasAttribute(
      'data-allow-keyboard-when-empty',
    )

    return (
      !hasInputValue(event.target) &&
      allowedShortcutsWhenEmpty.includes(shortcut) &&
      allowKeyboardWhenEmpty
    )
  }

export const defaultFilter = (shortcut: string, event: KeyboardEvent) => {
  const skip =
    event.defaultPrevented ||
    (!event.metaKey &&
      [
        'Escape',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Enter',
        'NumpadEnter',
      ].includes(event.code) === false &&
      isEditableElement(event.target))
  return !skip
}

const useKeyboardShortcuts = (params: {
  name: string
  node: Element | Document
  handler: KeyboardHandler
  filter?: FilterHandler
  dep?: any[]
}) => {
  const { name, node, handler, filter: filterFunc, dep = [] } = params

  useEffectBeforeMount(() => {
    const element = node && 'current' in node ? (node as any).current : node

    if (!element) return

    const existing = subscriptions.get(element)
    if (existing) {
      existing.handlers.push({ name, handler, filter: filterFunc || defaultFilter })
    } else {
      const subscription = fromEvent(element, 'keydown').subscribe(
        (event: KeyboardEvent) => {
          if (event.key === 'Meta') {
            return
          }

          const subscription = subscriptions.get(element)
          let code = event.code

          if (code === 'NumpadEnter') {
            code = 'Enter'
          }

          const shortcut = (
            isMacDesktop()
              ? [
                  metaKeyDown ? 'Meta' : null,
                  event.shiftKey ? 'Shift' : null,
                  event.altKey ? 'Alt' : null,
                  event.ctrlKey ? 'Ctrl' : null,
                  code,
                ]
              : // Windows and Linux essentially don't have a "meta" key like
                // Mac's Command key. Instead, most (hopefully all) shortcuts
                // coded to use `Meta` can safely be remapped to Ctrl, so when we
                // construct the shortcut we treat the Ctrl key as the meta key.
                //
                // TODO: We should probably remove support for shortcuts that use
                // Ctrl and Alt and ONLY support Shift and Meta (Command for Mac,
                // Ctrl otherwise).
                [
                  event.ctrlKey ? 'Meta' : null,
                  event.shiftKey ? 'Shift' : null,
                  event.altKey ? 'Alt' : null,
                  code,
                ]
          )
            .filter((a) => a)
            .join('+')

          let names = []
          let defaultPreventedBy: string = null
          for (let i = subscription.handlers.length - 1; i >= 0; i--) {
            const handler = subscription.handlers[i]
            if (handler.filter(shortcut, event)) {
              handler.handler(shortcut, event)
              if (!defaultPreventedBy && event.defaultPrevented) {
                defaultPreventedBy = handler.name
              }
              names.push(handler.name)
            }
          }

          debug(
            `%O ran through %O on %O. defaultPrevented on %O`,
            shortcut,
            names,
            element,
            defaultPreventedBy || 'none',
          )
        },
      )
      subscriptions.set(element, {
        handlers: [{ name, handler, filter: filterFunc || defaultFilter }],
        subscription,
      })
    }

    return () => {
      const subscription = subscriptions.get(element)
      const newHandlers = subscription.handlers.filter((h) => h.handler !== handler)
      if (newHandlers.length === 0) {
        subscription.subscription.unsubscribe()
        subscriptions.delete(element)
      } else {
        subscriptions.set(element, {
          handlers: newHandlers,
          subscription: subscription.subscription,
        })
      }
    }
  }, [node, ...dep])
}

export default useKeyboardShortcuts
export { useKeyboardShortcuts }
