import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Header from '../onboarding/header'
import Typography from '@ui/Typography'
import TextInput from '@ui/TextInput'
import Button from '../../component/button-v2'
import { useLoginStore } from '.'
import { observer } from 'mobx-react-lite'
import useInputState from '../../lib/use-input-state'
import keyboard from '../../lib/keyboard'
import { useAppStore } from '@src/app/context'
import CheckEmail from '../../component/illustrations/CheckEmail'
import Illustration from '../../component/illustrations'

interface EmailCodeProps {}

const EmailCode: React.FC<EmailCodeProps> = function ({}) {
  const styles = useStyles({})
  const { history } = useAppStore()
  const loginStore = useLoginStore()
  const [code, setCode] = useInputState('')
  const disabled = !code

  const handleLogin = () => {
    loginStore.loginWithCode(code)
  }

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.card}>
        <Illustration illustration={CheckEmail} className={styles.image} />
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Check your email
        </Typography>
        <Typography variant="body" color="textSecondary">
          Enter the 6-digit code we just sent to{' '}
          <Typography component="span" variant="body" color="textPrimary">
            {loginStore.email}
          </Typography>{' '}
          to continue.
        </Typography>
        <TextInput
          autoComplete="off"
          autoFocus
          fullWidth
          inputMode="numeric"
          placeholder="6-digit code"
          size={45}
          value={code}
          onChange={setCode}
          onKeyDown={keyboard.onEnter(handleLogin)}
          style={{ marginTop: 48, textAlign: 'center' }}
        />
        <Button
          fullWidth
          disabled={disabled}
          loading={loginStore.loading}
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={handleLogin}
        >
          Continue
        </Button>
        <Button
          fullWidth
          variant="text"
          color="textSecondary"
          fontWeight="regular"
          height={36}
          style={{ marginTop: 8 }}
          onClick={history.goBack}
        >
          Go back
        </Button>
      </div>
    </div>
  )
}

export default observer(EmailCode)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 320,
    textAlign: 'center',
    margin: '70px auto 50px',
  },
  image: {
    width: 295,
    marginBottom: 8,
  },
}))
