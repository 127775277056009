// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { AppAvatarRow } from '@src/app/components'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { isMac } from '../../..'
import IconButton from '../../../component/icon-button'
import {
  CallIcon,
  InfoIcon,
  MarkDone1Icon,
  MarkUnreadIcon,
  MoreIcon,
} from '../../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../../component/popover-menu'
import Typography from '@ui/Typography'
import { formatted } from '../../../lib/phone-number'
import { Conversation } from '../../../service/conversation-store'
import { Participant } from '../../../service/model'
import { colors } from '../../../theme'
import Header, { HeaderSpacer } from '../../header'

interface MessagesHeaderProps {
  conversation: Conversation
  showDetailsButton: boolean
}

/*
 * Show phone number underneath of a name only if
 *   - it's not a group conversation
 *   - contact has a name
 */
const shouldShowPhoneNumber = (conversation: Conversation) => {
  const participant = conversation.participants[0]
  return (
    !conversation.isDirect &&
    !conversation.isGroup &&
    (participant?.contacts?.find((c) => c.name)?.fullName ||
      participant?.member?.name ||
      participant?.integrationContacts?.find((c) => c.name)?.name)
  )
}

const MessagesHeader: React.FC<MessagesHeaderProps> = function ({
  conversation,
  showDetailsButton,
}) {
  const { command, inboxes, voice } = useAppStore()
  const styles = useStyle({})
  const nameRef = useRef<HTMLDivElement>()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const inbox = inboxes.selected

  const handleCall = () => {
    voice.startCall(conversation.openPhoneNumber, conversation.phoneNumber)
  }

  const handleUnread = (e) => {
    e.stopPropagation()
    inbox.toggleRead(conversation)
  }

  const toggleDone = () => {
    inbox.toggleDone(conversation)
  }

  const handleCommand = (e: any) => {
    command.present({ name: 'app' })
  }

  const handleSelectParticipant = (participant: Participant) => {
    setPopoverOpen(false)
    inboxes.selected.setDetails(participant)
  }

  const handleParticipantClick = () => {
    if (conversation.participants.length > 1) {
      setPopoverOpen(true)
    } else {
      inboxes.selected.setDetails(conversation.participants[0])
    }
  }

  const showPhoneNumber = shouldShowPhoneNumber(conversation)

  if (!conversation) {
    return null
  }

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <div ref={nameRef} className={styles.name} onClick={handleParticipantClick}>
          <AppAvatarRow
            identities={conversation.participants}
            className={styles.avatarRow}
            size={34}
          />
          <div>
            <Typography
              className={styles.contactName}
              variant={showPhoneNumber ? 'callout' : 'subheadline'}
              fontWeight="semibold"
              color="textPrimary"
              nowrap
            >
              {conversation.friendlyName}
            </Typography>
            {showPhoneNumber && (
              <Typography
                variant="caption2"
                fontWeight="medium"
                color="textSecondary"
                nowrap
              >
                {formatted(conversation.phoneNumber)}
              </Typography>
            )}
          </div>
        </div>
        <PopoverMenu
          anchorEl={nameRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: -8, horizontal: 'left' }}
          open={popoverOpen}
          onClose={() => setPopoverOpen(false)}
        >
          {conversation.participants.map((p) => (
            <MenuItem
              key={p.id || p.phoneNumber}
              onClick={() => handleSelectParticipant(p)}
              icon={<AppAvatar identity={p} size={20} />}
            >
              {p.name}
            </MenuItem>
          ))}
        </PopoverMenu>
        <HeaderSpacer />
        <div className={styles.quickActions} id="message-quick-actions">
          <IconButton
            size="medium"
            id="conversation-mark-as-read"
            tabIndex={-1}
            onClick={handleUnread}
            title="Mark unread"
            shortcut="U"
            icon={<MarkUnreadIcon />}
          />
          {!conversation.directNumber && (
            <IconButton
              size="medium"
              color="default"
              id="conversation-mark-as-done"
              tabIndex={-1}
              onClick={toggleDone}
              title={conversation.isDone ? 'Mark as open' : 'Mark as done'}
              shortcut="E"
              className={cx(conversation.isDone && styles.doneActive)}
              icon={<MarkDone1Icon />}
            />
          )}
          {conversation.canCall && (
            <IconButton
              size="medium"
              color="default"
              id="conversation-call"
              tabIndex={-1}
              onClick={handleCall}
              title="Call"
              icon={<CallIcon />}
            />
          )}
          {showDetailsButton && (
            <IconButton
              size="medium"
              color="default"
              id="conversation-details"
              title="Show Details"
              tabIndex={-1}
              onClick={inboxes.openDetails}
              icon={<InfoIcon />}
            />
          )}
          <IconButton
            size="medium"
            color="default"
            id="conversation-more"
            title="More Commands"
            shortcut="Meta+K"
            tabIndex={-1}
            onClick={handleCommand}
            icon={<MoreIcon />}
          />
        </div>
      </Header>
    </div>
  )
}

export default observer(MessagesHeader)

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  header: {
    outline: 'none',
    position: 'relative',
    width: 'calc(100% - 4rem)',
    maxWidth: 600,
    margin: '0 auto',
    padding: 0,
    userSelect: 'text',
  },
  back: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    left: '2rem',
  },
  contactName: {
    marginRight: 15,
  },
  name: {
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  doneActive: {
    color: colors.green600,
  },
  blockedActive: {
    color: colors.red400,
  },
  quickActions: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > *': {
      marginLeft: 10,
    },
  },
  avatarRow: { marginRight: 11 },
}))
