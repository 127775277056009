// @ts-strict-ignore
import { makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import AddIcon from '@material-ui/icons/Add'
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { Page } from '@src/app/settings/common'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import Tooltip from '../../../component/tooltip'
import {
  daysBefore,
  formatDate,
  friendlyDate,
  friendlyTime,
  isToday,
} from '../../../lib/date'
import Typography from '@ui/Typography'
import { logError } from '../../../lib/log'
import { ById } from '../../../types'
import { Card, CardActions, CardContent, CardHeader } from '../settings-card'
import { BillingCancelSubscription, BillingPlanSection, Loading } from './components'
import UpdateCreditCard from './credit-card'
import Badge from '@ui/Badge'
import analytics from '@src/lib/analytics'

interface BillingGeneralRouteProps {}

const BillingGeneralRoute: React.FC<BillingGeneralRouteProps> = function ({}) {
  const theme = useTheme()
  const styles = useStyles({})
  const { command, service, showAlert, toast } = useAppStore()
  const [loading, setLoading] = useState<ById<boolean>>({})
  const [updateCC, setUpdateCC] = useState(false)

  const invoices = service.billing.invoices
  const card = service.billing.card
  const subscription = service.billing.subscription
  const canTransferCredits = subscription.prepaidCredits > 200

  const fetchSubscriptionInfo = () => {
    service.billing.fetchCards().catch(logError)
    service.billing.fetchInvoices().catch(logError)
    service.billing.fetchSubscription().catch(logError)
    service.billing.fetchUpcomingInvoice().catch(logError)
  }

  useEffect(fetchSubscriptionInfo, [])

  const handleCreditCardUpdate = (token: string) => {
    if (subscription.store === 'stripe') {
      service.billing
        .upsertCreditCard(token)
        .then((card) => {
          analytics.updatedPaymentInfo()
          toast.show({ message: 'Credit card updated!' })
        })
        .catch(logError)
    } else {
      const subscriptionType = subscription.store === 'ios' ? 'App Store' : 'Play Store'
      service.billing
        .upgrade({ token })
        .then(() => {
          analytics.updatedPaymentInfo()
          showAlert({
            title: 'Done!',
            body: `You have been converted to credit card payment. Don't forget to cancel your subscription on ${subscriptionType} or you will be double-charged after your current billing cycle`,
          })
        })
        .catch(toast.showError)
    }
  }

  const handleEndTrial = () => {
    setLoading((l) => ({ ...l, convertTrial: true }))
    service.billing
      .endTrial()
      .then(() => toast.show({ message: 'Your trial period has ended.' }))
      .catch(toast.showError)
      .finally(() => setLoading((l) => ({ ...l, convertTrial: false })))
  }

  const handleAddCredits = () => {
    if (subscription.trial) {
      showAlert({
        title: 'You are on trial',
        body: 'Adding credits to your account is not possible while you are on trial. Convert to a paid subscription to continue.',
        actions: [
          { type: 'primary', title: 'End Trial', onClick: () => handleEndTrial() },
          { title: 'Cancel' },
        ],
      })
    } else {
      command.present({ name: 'billing credits' })
    }
  }

  const handleSetAutoCharge = () => {
    command.present({
      name: 'billing auto charge',
      defaultAmount: subscription.autochargeAmount,
    })
  }

  const handleConvertCredits = () => {
    showAlert({
      title: 'Transfer credits',
      body: 'You are about to transfer your OpenPhone credits to be used towards your monthly subscription fee. This will reset your credits back to $0.00. You will see the discount applied from your credits on your future invoices from OpenPhone.',
      actions: [
        { type: 'primary', title: 'Transfer credits', onClick: transferCredits },
        { title: 'cancel' },
      ],
    })
  }

  const transferCredits = () => {
    setLoading((l) => ({ ...l, convertCredits: true }))
    toast.showLoading('Converting your credits...')
    service.billing
      .convertCredits(Math.floor(subscription.prepaidCredits))
      .then(() => toast.show({ message: 'Your credits have been converted.' }))
      .catch(toast.showError)
      .finally(() => {
        service.billing.fetchUpcomingInvoice().catch(logError)
        setLoading((l) => ({ ...l, convertCredits: false }))
      })
  }

  const trialEndsAt = subscription.currentPeriodExpiresAt
  const trialEndsToday = isToday(trialEndsAt)
  const trialEndsAtDate = friendlyDate(trialEndsAt, { upperFirst: false })
  const trialEndsAtTime = friendlyTime(trialEndsAt)
  const daysBeforeTrialEnds = daysBefore(trialEndsAt)

  return (
    <Page>
      {subscription?.trial && !subscription?.isCanceled ? (
        <div className={styles.trialBanner}>
          <Typography variant="emoji" style={{ flex: '0 0 auto' }}>
            👋
          </Typography>
          <div style={{ marginLeft: 14 }}>
            <Typography color="primary" variant="callout">
              You are currently on a trial
            </Typography>
            <Typography
              color="primary"
              style={{ opacity: 0.7, marginTop: 2 }}
              variant="footnote"
            >
              {trialEndsToday
                ? `Your trial ends today and your payment method will be charged your first invoice at ${trialEndsAtTime}.`
                : `You have ${daysBeforeTrialEnds} days remaining on your trial and your payment method will be charged your first invoice on ${trialEndsAtDate}.`}
            </Typography>
          </div>
        </div>
      ) : null}
      <Card variant="elevation" className={styles.row} style={{ marginTop: 24 }}>
        <BillingPlanSection />
      </Card>

      <Card variant="elevation" className={styles.row}>
        <CardContent className={styles.rowContent}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <div style={{ flex: 1, marginRight: 30 }}>
              <div className={styles.credits}>
                {subscription.localizedCredits} Credits
              </div>
              <Typography variant="caption1" color="textSecondary" fontWeight="regular">
                Credits are used for international calling and messaging as well as API
                initiated text messages.{' '}
                <a href="https://openphone.co/rates" target="_blank">
                  Check the rates here.
                </a>
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTop: `1.5px solid ${theme.palette.op.border.common}`,
              paddingTop: 17,
            }}
          >
            <div style={{ flex: 1, marginRight: 30 }}>
              <Typography variant="callout" fontWeight="bold" style={{ marginBottom: 4 }}>
                Auto-Charge
              </Typography>
              <Typography variant="caption1" color="textSecondary" fontWeight="regular">
                By enabling this feature your account will be auto-charged an amount
                specified by you when your credits drop below $0.
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {subscription.autochargeAmount > 0 ? (
                <span style={{ marginRight: 20 }}>
                  {subscription.localizedAutoCharge}
                </span>
              ) : (
                <Badge variant="secondary" label="Off" />
              )}
            </div>
          </div>
        </CardContent>
        <CardActions className={styles.rowActions}>
          <Button
            height={36}
            color="primary"
            variant="outlined"
            onClick={handleAddCredits}
          >
            Buy Credits
          </Button>
          {subscription.autochargeAmount > 0 ? (
            <Button
              height={36}
              color="primary"
              variant="outlined"
              onClick={handleSetAutoCharge}
            >
              Edit Auto-Charge
            </Button>
          ) : (
            <Button
              height={36}
              color="primary"
              variant="outlined"
              onClick={handleSetAutoCharge}
            >
              Enable Auto-Charge
            </Button>
          )}
          <Tooltip
            title={
              canTransferCredits
                ? 'Transfer your credits to be used towards your monthly subscription fee.'
                : "You don't have enough credits to use towards your monthly subscription fee."
            }
          >
            <span>
              <Button
                height={36}
                color="primary"
                variant="outlined"
                onClick={handleConvertCredits}
                style={{ marginRight: 10 }}
                disabled={!canTransferCredits || loading['convertCredits']}
              >
                Use Towards Subscription
              </Button>
            </span>
          </Tooltip>
        </CardActions>
        {/* <CardActions>
          <Button color="primary" variant="outlined" onClick={handleAddCredits}>
            Purchase Credits
          </Button>

          {subscription.autochargeAmount > 0 && (
            <Button color="primary" variant="outlined" onClick={handleTurnOffAutoCharge}>
              Turn off Auto-Charge
            </Button>
          )}
        </CardActions> */}
      </Card>

      <Card variant="elevation" className={styles.row}>
        <CardHeader
          title="Payment Method"
          subheader="This is the payment method to use for the monthly invoices"
          className={styles.rowHeader}
        />
        <CardContent className={styles.rowContent}>
          {loading['creditcard'] && <Loading title="Loading your credit card" />}
          {subscription?.store === 'ios' ? (
            <div className={styles.paymentMethod}>
              <AppleIcon fontSize="small" className={styles.icon} />
              <Typography color="textPrimary" variant="callout">
                You are paying with iTunes
              </Typography>
            </div>
          ) : subscription?.store === 'android' ? (
            <div className={styles.paymentMethod}>
              <AndroidIcon fontSize="small" className={styles.icon} />
              <Typography color="textPrimary" variant="callout">
                You are paying with Google Play
              </Typography>
            </div>
          ) : (
            card && (
              <div className={styles.paymentMethod}>
                <CreditCardIcon fontSize="small" className={styles.icon} />
                <Typography color="textPrimary" variant="callout">
                  Ending in {card.last4} expiring on {('0' + card.exp_month).slice(-2)}/
                  {card.exp_year}
                </Typography>
              </div>
            )
          )}
        </CardContent>
        <CardActions className={styles.rowActions}>
          {subscription?.store === 'stripe' ? (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setUpdateCC(true)}
            >
              Update Credit Card
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setUpdateCC(true)}
            >
              Switch to credit card
            </Button>
          )}
        </CardActions>
      </Card>

      {subscription?.store === 'stripe' && (
        <Card variant="elevation" className={styles.row}>
          <CardHeader
            title="Receipts"
            subheader="Your payment history for your subscription on OpenPhone"
            className={styles.rowHeader}
          />
          <CardContent className={styles.rowContent}>
            {invoices.map((invoice) => (
              <div key={invoice.id} className={styles.invoice}>
                <Typography variant="callout" color="textPrimary">
                  {formatDate(new Date(invoice.created * 1000))} - {invoice.number}
                </Typography>
                <Button
                  height={30}
                  variant="text"
                  onClick={() => window.open(invoice.hosted_invoice_url, '_blank')}
                >
                  View
                </Button>
              </div>
            ))}
          </CardContent>
        </Card>
      )}
      <BillingCancelSubscription />
      <UpdateCreditCard
        open={updateCC}
        setOpen={setUpdateCC}
        onSave={handleCreditCardUpdate}
      />
    </Page>
  )
}

export default observer(BillingGeneralRoute)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    marginBottom: 50,
    borderRadius: 0,
    boxShadow: `0 1.5px 0 0 ${theme.palette.op.border.common}`,
    backgroundColor: 'transparent',
  },
  rowHeader: {
    padding: 0,
  },
  rowContent: {
    padding: 0,
  },
  rowActions: {
    padding: '23px 0',
  },
  trialBanner: {
    display: 'flex',
    padding: '14px 16px',
    borderRadius: 8,
    backgroundColor: 'rgba(108, 59, 255, 0.08)',
  },
  planRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paymentMethod: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  invoice: {
    padding: '7px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  credits: {
    fontSize: 34,
    marginBottom: 6,

    '& span': {
      fontSize: 14,
    },
  },
}))

const Cell = withStyles((theme) => ({
  root: {
    padding: '10px 0',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  head: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  footer: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))(TableCell)
