// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { debounce } from '@material-ui/core'
import cx from 'classnames'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import Masonry from '../../../masonry'
import { MenuContent } from '../../../menu-v2'
import SearchInput from '../../../search-input'
import Typography from '@ui/Typography'
import { useReaction } from '../../../../lib/hooks'
import { throttle } from '../../../../lib/throttle'
import useKeyboardShortcuts from '../../../../lib/use-keyboard-shortcuts'
import Controller from '../../controller'
import { GiphyStore } from './giphy-store'
import type { Gif } from './giphy-store'
import { GiphyIcon } from '../../../icons/custom'

export { Gif }

export interface GiphyProps {
  controller: Controller
}

const gridHeight = 300
const gridColumns = 3
const gridCellPadding = 3

const Giphy: React.FC<GiphyProps> = function ({ controller }) {
  const styles = useStyles({})
  const searchRef = useRef<HTMLInputElement>(null)
  const viewportRef = useRef<HTMLDivElement>(null)
  const masonryRef = useRef<HTMLDivElement>(null)
  const [searchFocused, setSearchFocused] = useState(false)
  const apiKey = controller.giphyApiKey

  const store = useMemo(() => new GiphyStore(apiKey), [apiKey])

  useKeyboardShortcuts({
    name: 'Giphy',
    node: searchFocused ? searchRef.current : controller.editor.ref.current,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        controller.menu.closeCommands()
        event.preventDefault()
      }
    },
    dep: [controller, searchFocused],
  })

  const handleSearch = useCallback(
    debounce(
      action((event: React.FormEvent<HTMLInputElement>) => {
        store.search = searchRef.current.value
      }),
      500,
    ),
    [store],
  )

  const setHeights = useCallback(
    throttle(
      action(() => {
        const { offsetHeight } = masonryRef.current
        const { scrollTop } = viewportRef.current

        store.viewportHeight = gridHeight
        store.listHeight = offsetHeight
        store.scrollOffset = scrollTop
      }),
      50,
    ),
    [store],
  )

  useReaction(
    () => store.gifs,
    () => {
      setHeights()
    },
    { name: 'SetHeights' },
  )

  const createGifClickHandler = useCallback(
    (gif: Gif) => () => controller.handleSelectGif(gif),
    [controller],
  )

  const handleSearchFocus = useCallback(() => setSearchFocused(true), [])
  const handleSearchBlur = useCallback(() => setSearchFocused(false), [])

  const renderGif = useCallback(
    (gif: Gif) => (
      <div key={gif.id} className={styles.gif} onClick={createGifClickHandler(gif)}>
        <img alt={`${gif.title} (Rated ${gif.rating.toUpperCase()})`} src={gif.url} />
      </div>
    ),
    [createGifClickHandler],
  )

  return (
    <div className={styles.root}>
      <MenuContent className={cx(styles.header, styles.content)}>
        <GiphyIcon />
      </MenuContent>
      <MenuContent className={styles.content}>
        <SearchInput
          autoFocus
          ref={searchRef}
          className={styles.search}
          onChange={handleSearch}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
        />
      </MenuContent>
      <MenuContent
        ref={viewportRef}
        className={cx(styles.grid, styles.content)}
        onScroll={setHeights}
      >
        <Masonry
          ref={masonryRef}
          className={styles.masonry}
          columns={gridColumns}
          bricks={store.gifs}
          renderBrick={renderGif}
        />
        {store.error ? (
          <Typography color="error" variant="callout" className={styles.message}>
            {store.error}
          </Typography>
        ) : store.fetchEndReached ? (
          <Typography variant="callout" className={styles.message}>
            No more GIFs found.
          </Typography>
        ) : null}
      </MenuContent>
    </div>
  )
}

export default observer(Giphy)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    search: {
      flex: 1,
    },
    content: {
      '& > *': {
        margin: 8,
      },
    },
    grid: {
      display: 'block',
      height: gridHeight,
      overflowY: 'scroll',
      padding: '0 8px',
    },
    masonry: {
      margin: 0,
    },
    gif: {
      padding: gridCellPadding,
      cursor: 'pointer',

      '& img': {
        display: 'block',
        width: '100%',
        borderRadius: 5,
      },
    },
    message: {
      textAlign: 'center',
      margin: '10px 0',
    },
  }),
  { name: Giphy.name },
)
