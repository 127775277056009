// @ts-strict-ignore
import React, { useState } from 'react'
import { useEditor, useBackdropClick } from '.'
import keyboard from '../../../lib/keyboard'
import { toE164 } from '../../../lib/phone-number'
import ContactEditorInput from './input'
import Input from 'react-phone-number-input/input'

export interface EditPhoneNumberProps {
  defaultValue: string
  onSave: (number: string) => void
}

const EditPhoneNumber: React.FC<EditPhoneNumberProps> = function ({
  defaultValue,
  onSave,
}) {
  const [, hide] = useEditor()
  const [value, setValue] = useState(defaultValue ?? '')

  const handleSave = () => {
    onSave(toE164(value))
    hide()
  }

  useBackdropClick(handleSave, [value])

  return (
    <Input
      autoFocus
      type="text"
      defaultCountry="US"
      placeholder="e.g. (123) 456-7890"
      value={value}
      onChange={(value) => setValue(value as string)}
      onKeyDown={keyboard.onEnterOrTab(handleSave)}
      inputComponent={ContactEditorInput}
    />
  )
}

export default EditPhoneNumber
