// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { createCallbackState } from '@src/app/callback'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { isWeb } from '../../..'
import {
  EmailIntegration,
  EntityPhoneNumber,
  SlackIntegration,
} from '../../../service/model'
import { Header, Section } from '../common'
import AddIntegration from './add-integration'
import IntegrationRow from './integration-row'

interface PhoneNumberIntegrationsProps {
  phoneNumber: EntityPhoneNumber
}

const PhoneNumberIntegrations: React.FC<PhoneNumberIntegrationsProps> = function ({
  phoneNumber,
}) {
  const styles = useStyles({})
  const { service, history, toast, isElectron } = useAppStore()
  const user = service.user.current
  const location = history.location

  const slack = phoneNumber?.integrations.filter(
    (i) => i.type === 'slack',
  ) as SlackIntegration[]

  const email = phoneNumber?.integrations.filter(
    (i) => i.type === 'email',
  ) as EmailIntegration[]

  const [isWaitingForDeepLink, setIsWaitingForDeepLink] = useState(false)

  useEffect(() => {
    phoneNumber?.fetchIntegrations().catch(toast.showError)
  }, [phoneNumber?.id])

  const slackRedirectUri = `${window.location.origin}/callback/slack`

  useEffect(() => {
    if (!phoneNumber?.id) return

    const code = history.consumeQueryParam('code')

    if (code !== undefined) {
      service.integration.createSlack({
        code: code,
        phoneNumberId: phoneNumber.id,
        redirectUri: slackRedirectUri,
        events: {
          answeredIncomingCall: false,
          missedCallWithVoicemail: true,
          missedCallWithoutVoicemail: true,
          incomingMessage: true,
        },
      })
    }
  }, [phoneNumber?.id, history.query])

  const handleSlackConnect = () => {
    const url = new URL(`https://slack.com/oauth/v2/authorize`)
    url.searchParams.set('client_id', '231937630768.852740625425')
    url.searchParams.set('scope', 'incoming-webhook')
    url.searchParams.set('redirect_uri', slackRedirectUri)
    url.searchParams.set(
      'state',
      createCallbackState({
        desktop: isElectron,
        redirectTo: window.location.pathname,
      }),
    )

    if (isWeb) {
      document.location.href = url.toString()
    } else {
      const win = window.open(url)
      win.focus()
      setIsWaitingForDeepLink(true)
    }
  }

  const handleEmailConnect = () => {
    service.integration.createEmail({
      type: 'email',
      phoneNumberId: phoneNumber.id,
      to: [user.email],
      events: {
        answeredIncomingCall: false,
        incomingMessage: true,
        missedCallWithVoicemail: true,
        missedCallWithoutVoicemail: true,
      },
    })
  }

  return (
    <Section>
      <Header
        title="Integrations"
        subtitle="Connect your phone number to other systems to send data about calls, messages, and voicemails back and forth"
      />
      {slack?.length === 0 && (
        <AddIntegration
          type="slack"
          onClick={handleSlackConnect}
          isLoading={isWaitingForDeepLink}
        />
      )}
      {slack?.map((slack) => (
        <IntegrationRow key={slack.id} integration={slack} />
      ))}
      {email?.length === 0 && (
        <AddIntegration type="email" onClick={handleEmailConnect} />
      )}
      {email?.map((email) => (
        <IntegrationRow key={email.id} integration={email} />
      ))}
    </Section>
  )
}

export default observer(PhoneNumberIntegrations)

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    marginBottom: 50,
  },
  email: {},
}))
