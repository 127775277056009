// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '..'
import { formatted } from '../../lib/phone-number'
import { getInitials } from '../../lib/string'
import {
  Collection,
  Contact,
  Conversation,
  Identity,
  IntegrationContact,
  Member,
  Model,
} from './'

export class Participant implements Identity, Model {
  // Relations
  conversations = new Collection<Conversation>()

  constructor(private service: Service, public phoneNumber: string) {
    makeAutoObservable(this, {})
    this.service.contact.loadByNumber(phoneNumber)
    this.service.integration.loadByNumber(this.phoneNumber)
  }

  get id(): string {
    return this.member?.id || this.contact?.id || this.integrationContacts?.[0]?.id
  }

  get contacts(): Contact[] {
    return (
      this.service.contact
        .getByNumber(this.phoneNumber)
        ?.slice()
        .sort((a, b) => b.createdAt - a.createdAt) ?? []
    )
  }

  get contact(): Contact {
    return this.contacts.length > 0 ? this.contacts[0] : null
  }

  get integrationContacts(): IntegrationContact[] {
    return this.service.integration.getPeople(this.phoneNumber) ?? []
  }

  get member(): Member {
    return this.service.member.collection.list.find(
      (m) =>
        m.directNumber === this.phoneNumber ||
        m.phoneNumbers.find((p) => p.number === this.phoneNumber),
    )
  }

  get name(): string {
    return (
      this.member?.name ||
      this.contacts.find((c) => c.name)?.name ||
      this.integrationContacts.find((c) => c.name)?.name ||
      formatted(this.phoneNumber)
    )
  }

  get shortName(): string {
    return (
      this.member?.shortName ||
      this.contacts.find((c) => c.shortName)?.shortName ||
      this.integrationContacts.find((c) => c.shortName)?.shortName ||
      formatted(this.phoneNumber)
    )
  }

  get source(): string {
    return (
      this.integrationContacts.find((c) => c.source)?.source ||
      this.contacts.find((c) => c.source)?.source
    )
  }

  get initials(): string {
    return getInitials(
      this.member?.name ||
        this.contacts.find((c) => c.name)?.name ||
        this.integrationContacts.find((c) => c.name)?.name,
    )
  }

  get pictureUrl(): string {
    return (
      this.member?.pictureUrl ||
      this.contacts?.find((c) => c.pictureUrl)?.pictureUrl ||
      this.integrationContacts?.find((c) => c.pictureUrl)?.pictureUrl
    )
  }

  get phones() {
    return (
      this.member?.phones ||
      this.contact?.phones ||
      this.integrationContacts?.[0]?.phones ||
      []
    )
  }

  get emailAddresses(): string[] {
    return this.member
      ? this.member.emailAddresses
      : this.contact
      ? this.contact.emails.map((i) => i.value)
      : this.integrationContacts
      ? this.integrationContacts?.[0]?.emails
      : []
  }

  get isAnonymous() {
    if (!this.id) return true
    return (
      (!this.member || this.member.isAnonymous) &&
      (!this.contact || this.contact.isAnonymous) &&
      (!this.integrationContacts?.[0] || this.integrationContacts[0].isAnonymous)
    )
  }

  deserialize(json: any) {
    Object.assign(this, json)
    return this
  }

  serialize() {
    return {}
  }
}

export const isParticipant = (a: any): a is Participant => {
  return a instanceof Participant
}
