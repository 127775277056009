import React from 'react'
import { Identity } from '@src/service/model'
import AvatarRow, { type AvatarRowProps } from '@ui/AvatarRow'
import { observer } from 'mobx-react-lite'
import { AppAvatar } from '../app-avatar'

export interface AppAvatarRowProps extends Omit<AvatarRowProps, 'children'> {
  identities: Identity[]
  classNameForAvatar?: (identity: Identity) => string
}

const AppAvatarRow = observer(
  ({ identities, classNameForAvatar, ...props }: AppAvatarRowProps) => {
    return (
      <AvatarRow {...props}>
        {identities.map((identity) => (
          <AppAvatar
            key={identity['phoneNumber'] ?? identity.id}
            className={classNameForAvatar?.(identity)}
            identity={identity}
            hideStatus={true}
            hideBadge={identities.length > 1}
          />
        ))}
      </AvatarRow>
    )
  },
)

AppAvatarRow.displayName = 'AppAvatarRow'

export default AppAvatarRow
