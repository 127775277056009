// @ts-strict-ignore
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { Subject } from 'rxjs'
import AppStore from '../../../../store'
import { DisposeBag } from '../../../../../lib/dispose'
import { Comment, ImmutableCollection, MessageMedia } from '../../../../../service/model'
import {
  Controller,
  CommentFeedItem as BaseCommentFeedItem,
} from '../../../../../component/comments'
import ControllerRegistry, { CodableController } from '../../../../controller-registry'
import { canHandleMedia } from '../../../../media-viewer'
import { FeedItem } from '../../feed'

export class CommentFeedItem extends BaseCommentFeedItem {
  readonly source = this.comment

  constructor(protected app: AppStore, readonly comment: Comment) {
    super()

    makeObservable(this, {
      source: observable.ref,
      id: computed,
      currentUser: computed,
      member: computed,
      senderId: computed,
      sender: computed,
      handleCopy: action.bound,
      handleSenderSelected: action.bound,
      handleDelete: action.bound,
      handleReaction: action.bound,
    })
  }

  override get id() {
    return this.comment.id
  }

  get currentUser() {
    return this.app.service.user.current
  }

  get member() {
    return this.sender
  }

  get senderId() {
    return this.comment.userId
  }

  get sender() {
    return this.app.service.member.collection.get(this.senderId)
  }

  handleCopy() {
    if (this.copyableText) {
      navigator.clipboard.writeText(this.copyableText)
      this.app.toast.show({ message: 'Copied!' })
    }
  }

  handleSenderSelected() {
    this.app.inboxes.selected.setDetailsById(this.sender?.id)
  }

  handleDelete() {
    this.comment.activity.deleteComment(this.comment)
  }

  handleReaction(body: string) {
    this.comment.toggleReaction(body).catch(this.app.toast.showError)
  }
}

export interface SerializedComments {
  collapsed?: boolean
}

export class CommentsRegistry extends ControllerRegistry<
  CommentsController,
  SerializedComments,
  FeedItem
> {
  readonly name = 'CommentsRegistry'
  readonly disposables = new WeakMap<CommentsController, DisposeBag>()

  constructor(protected app: AppStore) {
    super({ storage: app.service.storage.sync(), cacheMax: 50 })
  }

  create(item: FeedItem): CommentsController {
    const controller = new CommentsController(this.app, item)
    const subscription = controller.states$.subscribe((state) => {
      this.save(controller, state)
    })
    const disposable = new DisposeBag(subscription)
    this.disposables.set(controller, disposable)
    return controller
  }

  getId(c: CommentsController | FeedItem): string {
    return c instanceof CommentsController ? c.item.id : c.id
  }

  onDispose(controller: CommentsController) {
    this.disposables.get(controller).dispose()
  }
}

export default class CommentsController
  extends Controller<CommentFeedItem>
  implements CodableController<SerializedComments>
{
  readonly states$ = new Subject<SerializedComments | null>()

  constructor(protected app: AppStore, readonly item: FeedItem) {
    super()

    makeObservable(this, {
      inputBarController: computed,
      items: computed,
      resolvedAt: computed,
      resolvedBy: computed,
      handleCancel: action.bound,
      resolve: action.bound,
      unresolve: action.bound,
      serialize: action.bound,
      deserialize: action.bound,
    })

    reaction(
      () => [this._collapsed, this._previousCommentCount],
      () => {
        this.states$.next(this.serialize())
      },
      { name: 'PersistController' },
    )
  }

  get inputBarController() {
    return this.app.conversation.threadInputBarRegistry.get(this.item.activity)
  }

  get items(): ImmutableCollection<CommentFeedItem> {
    const comments = this.item.activity.comments ?? []
    const items = comments.map((comment) => new CommentFeedItem(this.app, comment))

    return new ImmutableCollection<CommentFeedItem>(items, {
      compare: (a, b) => a.sortWeight - b.sortWeight,
    })
  }

  get resolvedAt() {
    return this.item.activity.resolvedAt
  }

  get resolvedBy() {
    return this.app.service.member.collection.get(this.item.activity.resolvedBy)
  }

  resolve() {
    this._collapsed = null
    this.item.activity.resolve()
  }

  unresolve() {
    this._collapsed = null
    this.item.activity.unresolve()
  }

  openMedia(media: MessageMedia) {
    const allMedia = this.item.activity.comments
      .flatMap((comment) => comment.media || [])
      .filter((m) => canHandleMedia(m.type, m.name))
    const index = allMedia.findIndex((m) => m.id === media.id)
    this.app.openMediaViewer(allMedia, index)
  }

  handleCancel() {
    this.item.thread.open = false
  }

  serialize(): SerializedComments | null {
    return this.resolved !== this.collapsed
      ? {
          collapsed: this.collapsed,
        }
      : null
  }

  deserialize(obj: SerializedComments) {
    if (!obj) return
    this._collapsed = obj.collapsed
  }
}
