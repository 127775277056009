import { LocationDescriptor } from 'history'
import React from 'react'
import {
  RedirectProps as RouterRedirectProps,
  useLocation,
  Redirect as RouterRedirect,
} from 'react-router-dom'

export interface RedirectProps extends RouterRedirectProps {}

export default function Redirect({ to, ...props }: RedirectProps) {
  const location = useLocation()

  let descriptor: LocationDescriptor = {
    ...location,
  }

  if (typeof to === 'object') {
    descriptor = {
      ...descriptor,
      ...to,
    }
  } else {
    const url = new URL(to, window.location.origin)
    descriptor.pathname = url.pathname
    descriptor.search = url.search
  }

  return <RouterRedirect to={descriptor} {...props} />
}
