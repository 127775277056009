// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useRef, useState } from 'react'
import { ContactType } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import { MergeIcon } from '../../component/icons/Tint/20/General'
import { ChevronDownIcon } from '../../component/icons/Tint/08'
import { IdentityItem, MenuItem } from '../../component/menu-v2'
import PopoverMenu from '../../component/popover-menu'
import Typography from '@ui/Typography'
import { compareString } from '../../lib'
import { Contact } from '../../service/model'
import { AppAvatarRow } from '@src/app/components'

interface DuplicateSelectorProps {
  matchingContacts: ContactType[]
  selectedContact: ContactType
  onSelect: (contact: ContactType) => void
}

const DuplicateSelector: React.FC<DuplicateSelectorProps> = function ({
  matchingContacts,
  selectedContact,
  onSelect,
}) {
  const styles = useStyles({})
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { service, toast } = useAppStore()
  const [open, setOpen] = useState(false)
  const sortedContacts = matchingContacts.sort((a, b) => compareString(a.id, b.id))
  const contacts = sortedContacts.filter((c): c is Contact => c instanceof Contact)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelect = (contact: ContactType) => {
    setOpen(false)
    onSelect(contact)
  }

  const handleMerge = () => {
    setOpen(false)
    toast.showLoading(`Merging ${contacts.length} contacts...`)
    const destination = selectedContact instanceof Contact ? selectedContact : contacts[0]
    service.contact
      .merge(
        destination,
        contacts.filter((c) => c.id !== selectedContact.id),
      )
      .then(() => toast.hide())
      .catch(toast.showError)
  }

  return (
    <div className={styles.root}>
      <Button
        fullWidth
        ref={buttonRef}
        variant="outlined"
        onClick={handleOpen}
        startIcon={<AppAvatarRow size={16} identities={sortedContacts} />}
        endIcon={<ChevronDownIcon />}
        className={styles.button}
      >
        <Typography
          variant="caption1"
          color="textSecondary"
          fontWeight="regular"
          className={styles.text}
        >
          Other matching contacts found
        </Typography>
      </Button>
      <PopoverMenu
        size="medium"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        transformOrigin={{ vertical: -5, horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        minWidth={buttonRef.current?.getBoundingClientRect().width}
      >
        {sortedContacts.map((contact) => (
          <IdentityItem
            selected={contact.id === selectedContact.id}
            key={contact.id}
            identity={contact}
            height="large"
            onClick={() => handleSelect(contact)}
          />
        ))}
        {contacts.length > 1 && (
          <MenuItem
            icon={
              <div className={styles.merge}>
                <MergeIcon />
              </div>
            }
            style={{ height: 56 }}
            onClick={handleMerge}
          >
            <Typography variant="footnote">Merge contacts</Typography>
            <Typography variant="caption2" color="textSecondary" style={{ marginTop: 2 }}>
              Intelligently merge all into a single contact.
            </Typography>
          </MenuItem>
        )}
      </PopoverMenu>
    </div>
  )
}

export default DuplicateSelector

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignSelf: 'stretch',
    marginTop: 20,
    marginBottom: -18,
  },
  button: {
    height: 38,
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    flex: 1,
    textAlign: 'left',
    marginLeft: 2,
  },
  merge: {
    width: 34,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.op.hover.primary,
    borderRadius: '50%',
  },
}))
