import { useEffect } from 'react'

export type EventListeners = {
  [K in keyof GlobalEventHandlersEventMap]?: (
    event: GlobalEventHandlersEventMap[K],
  ) => void
}

export default function useEventListeners<T extends EventTarget>(
  target: T,
  listeners: EventListeners,
  options?: boolean | AddEventListenerOptions,
  deps: any[] = [],
) {
  useEffect(() => {
    for (const [event, listener] of Object.entries(listeners)) {
      target.addEventListener(event, listener as EventListener, options)
    }

    return () => {
      for (const [event, listener] of Object.entries(listeners)) {
        target.removeEventListener(
          event,
          listener as EventListener,
          typeof options === 'boolean' ? options : options?.capture,
        )
      }
    }
  }, deps)
}
