import React from 'react'
import Button, { DangerButton } from '../../../component/button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../../component/dialog'
import TextField from '@ui/TextInput'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'

type ConfirmDeleteProps = {
  open: boolean
  setOpen: (open: boolean) => void
  onDelete: () => void
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = function ({
  open,
  setOpen,
  onDelete,
}) {
  const [confirmText, setConfirmText] = useInputState('')

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <Typography variant="body" color="textSecondary">
          By deleting your phone number, you will lose access to all messages and calls to
          and from this phone number.
          <br />
          <br />
          Type <b>delete</b> in the box below to continue:
        </Typography>
        <div style={{ marginTop: 15 }}>
          <TextField fullWidth autoFocus value={confirmText} onChange={setConfirmText} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="default" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <DangerButton
          variant="outlined"
          disabled={confirmText.toLowerCase() !== 'delete'}
          onClick={onDelete}
        >
          Delete
        </DangerButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDelete
