// @ts-strict-ignore
import { Theme, makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useSelected } from 'slate-react'
import { ElementProps, EmptyText } from '.'

export interface Mention {
  id: string
  name: string
}

export interface MentionSlateElement {
  type: 'mention'
  mention: Mention
  children: EmptyText[]
}

export interface MentionElementExtraProps {
  renderMention?: (mention: string) => React.ReactNode
}

const MentionElement: React.FC<
  ElementProps<MentionSlateElement> & MentionElementExtraProps
> = ({ attributes, children, element, renderMention: renderMentionProp }) => {
  const styles = useStyles({})
  const selected = useSelected()

  const renderMention = useCallback(
    (mention: Mention) =>
      renderMentionProp ? (
        renderMentionProp(mention.id)
      ) : (
        <span className={styles.mention}>@{mention.name}</span>
      ),
    [renderMentionProp],
  )

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={selected ? styles.selected : null}
    >
      {renderMention(element.mention)}
      {children}
    </span>
  )
}

export default MentionElement

const useStyles = makeStyles(
  (theme: Theme) => ({
    mention: {},
    selected: {},
  }),
  { name: MentionElement.name },
)
