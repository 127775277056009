import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppStore } from '@src/app/context'
import { useStore } from '@src/lib/hooks'
import Button from '@src/component/button-v2'
import { HubspotIcon } from '@src/component/icons/custom'
import { MessageIcon, ContactCardIcon } from '@src/component/icons/Tint/20/General'
import { Page } from '../../common'
import { Header } from '../components/header'
import { IntegrationStatus } from '../components/integration-status'
import { SectionHeader } from '../components/section-header'
import { SettingRow } from '../components/setting-row'
import { PhoneNumbers } from '../components/phone-numbers'
import { HowItWorksCard, type HowItWorksCardProps } from '../components/how-it-works-card'
import HubSpotController from './controller'
import CustomActivitySetting from './custom-activity'
import ImportStatus from './import-status'
import { DisconnectedLayout } from '../components/disconnected-layout'
import { ConnectedLayout } from '../components/connected-layout'

const pageInfo = {
  title: 'HubSpot',
  subtitle: 'Sync contact and activity data between OpenPhone and HubSpot',
}

const howItWorksInfo: Pick<HowItWorksCardProps, 'description' | 'features'> = {
  description:
    'At a high level, this integration allows for the flow of information, specifically contact and activity data, between your OpenPhone workspace and your HubSpot account.',
  features: [
    {
      title: 'Logs calls and messages',
      description:
        'Automatically log OpenPhone calls and messages, along with their recordings, to HubSpot under a matching contact or company.',
      icon: <MessageIcon />,
    },
    {
      title: 'Contacts',
      description:
        'Find and display matching HubSpot contacts within OpenPhone. If no match is found, it allows you to sync your OpenPhone contact to HubSpot with one click.',
      icon: <ContactCardIcon />,
    },
  ],
}

const Hubspot = function () {
  const styles = useStyles({})
  const app = useAppStore()
  const { service, history } = app
  const controller = useStore(() => new HubSpotController(app), [])
  const subscription = service.billing.subscription
  const phoneNumbers = service.organization.phoneNumber.collection

  useEffect(() => {
    const ids = phoneNumbers.list?.map((p) => p.id)

    if (ids && ids.length > 0) {
      const code = history.consumeQueryParam('code')

      if (code) {
        controller.createWithCode(code)
      }
    }
  }, [phoneNumbers.length, history.query])

  if (!controller.integration) {
    return (
      <Page>
        <DisconnectedLayout>
          <Header
            icon={<HubspotIcon />}
            title={pageInfo.title}
            subtitle={pageInfo.subtitle}
          />
          <HowItWorksCard
            description={howItWorksInfo.description}
            features={howItWorksInfo.features}
            action={
              subscription.isPremium ? (
                !controller.integration ? (
                  <Button onClick={controller.connect} fullWidth>
                    Connect to HubSpot
                  </Button>
                ) : null
              ) : (
                <Button onClick={() => history.push('/settings/billing/plans')} fullWidth>
                  Upgrade to Premium to use
                </Button>
              )
            }
          />
        </DisconnectedLayout>
      </Page>
    )
  }

  return (
    <Page>
      <ConnectedLayout>
        <Header
          icon={<HubspotIcon />}
          title={pageInfo.title}
          subtitle={pageInfo.subtitle}
          infoCard={({ onClose }) => (
            <HowItWorksCard
              description={howItWorksInfo.description}
              features={howItWorksInfo.features}
              onClose={onClose}
            />
          )}
        />
        <main className={styles.page}>
          <IntegrationStatus
            user={controller.integration.hubspotUser}
            onDisconnect={controller.delete}
            importStatus={
              controller.importEnabled ? (
                <ImportStatus
                  status={controller.importStatus}
                  canReimport={controller.canImport}
                  onReimport={controller.importContacts}
                  onRetry={controller.importContacts}
                />
              ) : null
            }
          />
          <section className={styles.section}>
            <SectionHeader
              title="Settings"
              subtitle="Manage activity settings for your HubSpot integration"
            />
            <div className={styles.settings}>
              <SettingRow
                title="Log calls"
                subtitle="Log incoming and outgoing calls to the matching contact or company"
                checked={!!controller.integration.logCalls?.enabled}
                onChange={controller.toggleLogCalls}
              />

              <SettingRow
                title="Log messages"
                subtitle="Log incoming and outgoing messages to the matching contact or company"
                checked={!!controller.integration.logMessages?.enabled}
                onChange={controller.toggleLogMessages}
              />

              {(controller.integration.logMessages?.enabled ||
                controller.integration.logCalls?.enabled) && (
                <CustomActivitySetting controller={controller} />
              )}
            </div>
          </section>
          <section className={styles.section}>
            <SectionHeader
              title="Phone numbers"
              subtitle="Select the phone numbers that should sync activities to HubSpot"
              action={
                <Button
                  variant="outlined"
                  color="textPrimary"
                  onClick={
                    controller.areAllActivated
                      ? controller.deactivateAllPhoneNumbers
                      : controller.activateAllPhoneNumbers
                  }
                >
                  {controller.areAllActivated ? 'Deactivate' : 'Activate'} all
                </Button>
              }
            />
            <PhoneNumbers
              activePhoneNumbers={controller.integration.resourceIds}
              onActivate={controller.activatePhoneNumber}
              onPause={controller.pausePhoneNumber}
            />
          </section>
        </main>
      </ConnectedLayout>
    </Page>
  )
}

export default observer(Hubspot)

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 64,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  settings: {
    marginBottom: 20,
  },
}))
