// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import {
  ActivityMentionAlert,
  ActivityReactionAlert,
  Alert,
  ContactNoteMentionAlert,
  ThreadCommentReactionAlert,
  ThreadMentionAlert,
  ThreadReplyAlert,
  ThreadResolutionAlert,
} from '../../service/model'
import Typography from '@ui/Typography'
import Mention from '../mention'
import FormattedText from '../../component/formatted-text'
import { friendlyDateTime } from '../../lib'
import { AppAvatar } from '@src/app/components'

interface AlertRowProps {
  alert: Alert
  onClick: (alert: Alert) => void
  flash?: boolean
}

const AlertRow: React.FC<AlertRowProps> = function ({ alert, flash, onClick }) {
  const styles = useStyles({})
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (flash) {
      ref.current.classList.add(styles.flash)
    }
  }, [flash])
  const renderMention = (mention: string, offset: number) => (
    <Mention key={offset} id={mention} />
  )

  const handleClick = () => {
    onClick(alert)
  }

  let action: string
  let context: string
  let body: string

  if (
    alert instanceof ActivityMentionAlert ||
    alert instanceof ContactNoteMentionAlert ||
    alert instanceof ThreadMentionAlert
  ) {
    action = 'mentioned you'
  } else if (
    alert instanceof ThreadCommentReactionAlert ||
    alert instanceof ActivityReactionAlert
  ) {
    action = `reacted with ${alert.data.body}`
  } else if (alert instanceof ThreadReplyAlert) {
    action = 'replied'
  } else if (alert instanceof ThreadResolutionAlert) {
    action = alert.isResolved ? 'resolved a thread' : 'marked thread as unresolved'
  }

  if (alert instanceof ContactNoteMentionAlert) {
    context = 'Contact note'
    body = alert.note?.text
  } else if (
    alert instanceof ActivityMentionAlert ||
    (alert instanceof ActivityReactionAlert && !alert.conversation?.isDirect)
  ) {
    context = 'Message'
    body = alert.activity?.body
  } else if (
    alert instanceof ThreadMentionAlert ||
    alert instanceof ThreadCommentReactionAlert ||
    alert instanceof ThreadReplyAlert
  ) {
    context = 'Internal thread'
    body = alert.comment?.body
  } else if (alert instanceof ActivityReactionAlert && alert.conversation?.isDirect) {
    context = 'Direct message'
    body = alert.activity?.body
  } else if (alert instanceof ThreadResolutionAlert) {
    context = 'Internal thread'
    body = alert.activity?.body
  }

  const time = friendlyDateTime(alert.createdAt)

  return (
    <div ref={ref} className={styles.root} onClick={handleClick}>
      <AppAvatar identity={alert.user} size={30} />
      <div className={styles.body}>
        <div className={styles.title}>
          <Typography variant="footnote" fontWeight="medium">
            {alert.user?.nameWithStatus}{' '}
            <Typography component="span" variant="footnote" fontWeight="regular">
              {action}
            </Typography>
          </Typography>
          {alert.isUnopened && <div className={styles.unread} />}
        </div>
        <Typography variant="caption2" color="textTertiary" style={{ marginTop: 4 }}>
          {time} · {context}
        </Typography>
        <Typography variant="footnote" color="textSecondary" className={styles.note}>
          <FormattedText value={body} inline renderMention={renderMention} />
        </Typography>
      </div>
    </div>
  )
}

export default AlertRow

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 12,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 2,
    borderRadius: 6,
    cursor: 'pointer',
    margin: '0 8px',

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  body: {
    flex: 1,
    marginLeft: 12,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  unread: {
    width: 8,
    height: 8,
    borderRadius: 4,
    background: theme.palette.op.secondary.blue,
    marginLeft: 25,
  },
  note: {
    marginTop: 6,
    padding: `1px 0 1px 10px`,
    borderLeft: `2px solid ${theme.palette.op.gray[4]}`,
  },
  flash: {
    animation: '$flash 2s',
  },
  '@keyframes flash': {
    '0%': { backgroundColor: alpha(theme.palette.op.secondary.yellow, 0.0) },
    '50%': { backgroundColor: alpha(theme.palette.op.secondary.yellow, 0.2) },
    '80%': { backgroundColor: alpha(theme.palette.op.secondary.yellow, 0.2) },
    '100%': { backgroundColor: alpha(theme.palette.op.secondary.yellow, 0.0) },
  },
}))
