// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import Unread from '../../../component/unread'
import { Conversation, Member } from '../../../service/model'
import SideMenuRow from '../row'

export interface DirectMessageRowProps {
  dm: { member: Member; conversation: Conversation }
  highlighted: boolean
  onClick: (dm: { member: Member; conversation: Conversation }) => void
}

const DirectMessageRow: React.FC<DirectMessageRowProps> = function ({
  dm,
  highlighted,
  onClick,
}) {
  const styles = useStyles({})
  const unread = dm.conversation?.unreadCount

  return (
    <SideMenuRow
      key={dm.member.id}
      highlighted={highlighted}
      onClick={() => onClick(dm)}
      icon={<AppAvatar size={20} identity={dm.member} />}
      accessory={() => unread > 0 && <Unread count={unread} />}
    >
      <div className={styles.rowBody}>
        <Typography nowrap variant="footnote" className={styles.name}>
          {dm.member.name}
        </Typography>
        {dm.member.statusSymbol && (
          <Tooltip placement="right" title={dm.member.statusText}>
            <span className={styles.statusEmoji}>{dm.member.statusSymbol}</span>
          </Tooltip>
        )}
      </div>
    </SideMenuRow>
  )
}

export default observer(DirectMessageRow)

const useStyles = makeStyles((theme: Theme) => ({
  rowBody: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    minWidth: 0,
  },
  name: {
    marginRight: 10,
    marginBottom: 2,
  },
  statusEmoji: {
    fontSize: '1.2rem',
  },
}))
