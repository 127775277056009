import { isNonNull } from './rx-operators'
import { isDate } from './date'

export const toQueryString = (obj: { [key: string]: any }): string => {
  if (!obj) {
    return ''
  }
  const params = Object.keys(obj)
    .map((key) => {
      let value = obj[key]
      if (value !== undefined && value !== null) {
        if (isDate(value)) {
          value = value.toISOString()
        }
        const parameter = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        return parameter
      } else {
        return null
      }
    })
    .filter(isNonNull)

  if (params.length > 0) {
    return `?${params.join('&')}`
  }
  return ''
}

export const fromQueryString = (string: string): { [key: string]: string } => {
  if (!string) return {}
  if (string.charAt(0) === '?' || string.charAt(0) === '#') {
    return fromQueryString(string.substr(1))
  }
  return string.split('&').reduce((hash, part) => {
    const [key, value] = part.split('=')
    hash[key] = decodeURIComponent(value)
    return hash
  }, {})
}

/**
 * returns the current url without all the path params
 */
export const getCurrentUrl = (): string => {
  return (
    location.protocol +
    '//' +
    location.hostname +
    (location.port ? ':' + location.port : '')
  )
}
