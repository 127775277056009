// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { AvailabilityHours } from '../..'
import { NativeSelect } from '../../component/select'
import Typography from '@ui/Typography'
import {
  days,
  defaultAvailability,
  timeOptions,
  defaultAvailabilityScheduleRange,
} from '../../lib/constants'
import { ScheduleMode } from './notifications/controller'

interface ScheduleProps {
  availability: AvailabilityHours
  onChange: (availability: AvailabilityHours) => void
}

const Schedule: React.FC<ScheduleProps> = function ({ availability, onChange }) {
  const styles = useStyles({})
  const [scheduleMode, setScheduleMode] = useState<ScheduleMode>('weekdays')

  const schedule = availability.schedule ?? defaultAvailability.schedule

  useEffect(() => {
    setScheduleMode(() => {
      if (!availability || !availability.enabled) return 'everyday'
      const firstDay = {
        start: schedule[0]?.start ?? defaultAvailabilityScheduleRange.start,
        end: schedule[0]?.end ?? defaultAvailabilityScheduleRange.end,
      }
      const everyday = schedule.every(
        (day) => day?.start === firstDay?.start && day?.end === firstDay?.end,
      )
      if (everyday) return 'everyday'
      const weekdays =
        !schedule[5] &&
        !schedule[6] &&
        schedule
          .slice(0, 5)
          .every((day) => day?.start === firstDay?.start && day?.end === firstDay?.end)

      if (weekdays) return 'weekdays'
      return 'custom'
    })
  }, [Boolean(availability)])

  const handleScheduleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const mode = event.target.value as ScheduleMode
    const firstDay = {
      start: schedule[0]?.start ?? defaultAvailabilityScheduleRange.start,
      end: schedule[0]?.end ?? defaultAvailabilityScheduleRange.end,
    }
    setScheduleMode(mode)
    if (mode === 'everyday') {
      onChange({
        ...availability,
        schedule: schedule.map(() => firstDay),
      })
    } else if (mode === 'weekdays') {
      onChange({
        ...availability,
        schedule: schedule.map((_, index) =>
          index < 5 ? firstDay : { start: null, end: firstDay.end },
        ),
      })
    }
  }

  const handleStartChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (scheduleMode === 'everyday') {
      onChange({
        ...availability,
        schedule: schedule.map((day) => ({
          start: event.target.value,
          end: day?.end ?? defaultAvailabilityScheduleRange.end,
        })),
      })
    } else if (scheduleMode === 'weekdays') {
      onChange({
        ...availability,
        schedule: schedule.map((day, index) =>
          index < 5
            ? {
                start: event.target.value,
                end: day?.end ?? defaultAvailabilityScheduleRange.end,
              }
            : { start: null, end: day?.end ?? defaultAvailabilityScheduleRange.end },
        ),
      })
    }
  }

  const handleEndChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (scheduleMode === 'everyday') {
      onChange({
        ...availability,
        schedule: schedule.map((day) => ({
          start: day?.start ?? defaultAvailabilityScheduleRange.start,
          end: event.target.value,
        })),
      })
    } else if (scheduleMode === 'weekdays') {
      onChange({
        ...availability,
        schedule: schedule.map((day, index) =>
          index < 5
            ? {
                start: day?.start ?? defaultAvailabilityScheduleRange.start,
                end: event.target.value,
              }
            : { start: null, end: day?.end ?? defaultAvailabilityScheduleRange.end },
        ),
      })
    }
  }

  const handleCustomStartChange = (dayIndex: number, start: string) => {
    onChange({
      ...availability,
      schedule: schedule.map((day, index) =>
        index === dayIndex
          ? { start, end: day?.end ?? defaultAvailabilityScheduleRange.end }
          : day,
      ),
    })
  }

  const handleCustomEndChange = (dayIndex: number, end: string) => {
    onChange({
      ...availability,
      schedule: schedule.map((day, index) =>
        index === dayIndex
          ? { start: day?.start ?? defaultAvailabilityScheduleRange.start, end }
          : day,
      ),
    })
  }

  return (
    <div>
      <div className={styles.schedule}>
        <NativeSelect
          value={scheduleMode}
          onChange={handleScheduleModeChange}
          style={{ flex: '0 0 150px' }}
        >
          <option value="everyday">Every day</option>
          <option value="weekdays">Weekdays</option>
          <option value="custom">Custom</option>
        </NativeSelect>
        {scheduleMode !== 'custom' && (
          <>
            <Typography
              variant="callout"
              color="textSecondary"
              style={{ margin: '0 10px' }}
            >
              from
            </Typography>
            <NativeSelect
              style={{ flex: 1 }}
              value={schedule[0]?.start ?? ''}
              onChange={handleStartChange}
            >
              {timeOptions.map((time) => (
                <option key={time.value} value={time.value}>
                  {time.label}
                </option>
              ))}
            </NativeSelect>
            <Typography
              variant="callout"
              color="textSecondary"
              style={{ margin: '0 10px' }}
            >
              to
            </Typography>
            <NativeSelect
              style={{ flex: 1 }}
              value={schedule[0]?.end ?? ''}
              onChange={handleEndChange}
            >
              {timeOptions.map((time) => (
                <option key={time.value} value={time.value}>
                  {time.label}
                </option>
              ))}
            </NativeSelect>
          </>
        )}
      </div>
      {scheduleMode === 'custom' && (
        <div style={{ marginTop: 20 }}>
          {days.map((day, index) => (
            <CustomScheduleRow
              key={day}
              day={index}
              label={day}
              start={schedule[index]?.start}
              end={schedule[index]?.end}
              onStartChange={handleCustomStartChange}
              onEndChange={handleCustomEndChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}

interface CustomScheduleRowProps {
  day: number
  label: string
  start: string
  end: string
  onStartChange: (day: number, start: string) => void
  onEndChange: (day: number, start: string) => void
}

const CustomScheduleRow: React.FC<CustomScheduleRowProps> = function ({
  day,
  label,
  start,
  end,
  onStartChange,
  onEndChange,
}) {
  const styles = useStyles({})

  const handleStartChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onStartChange(day, event.target.value || null)
  }

  const handleEndChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onEndChange(day, event.target.value)
  }

  return (
    <div className={styles.customRow}>
      <Typography variant="body" color="textSecondary" style={{ flex: '0 0 120px' }}>
        {label}
      </Typography>
      <NativeSelect
        style={{ flex: 1 }}
        size={35}
        value={start ?? ''}
        onChange={handleStartChange}
      >
        <option value="">None</option>
        {timeOptions.map((time) => (
          <option key={time.value} value={time.value}>
            {time.label}
          </option>
        ))}
      </NativeSelect>
      <Typography
        variant="callout"
        color="textSecondary"
        style={{ margin: '0 10px', visibility: start ? 'visible' : 'hidden' }}
      >
        to
      </Typography>
      <NativeSelect
        style={{ flex: 1, visibility: start ? 'visible' : 'hidden' }}
        value={end ?? ''}
        size={35}
        onChange={handleEndChange}
      >
        {timeOptions.map((time) => (
          <option key={time.value} value={time.value}>
            {time.label}
          </option>
        ))}
      </NativeSelect>
    </div>
  )
}

export default observer(Schedule)

const useStyles = makeStyles((theme: Theme) => ({
  schedule: {
    display: 'flex',
    alignItems: 'center',
  },
  customRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
}))
