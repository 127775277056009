// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { getInitials, parseDate } from '../../lib'
import { Membership } from '../../types'
import { Identity, IdentityPhone, Model } from '../model'

export class Organization implements Identity, Model {
  id: string = null
  name: string = null
  slug: string = null
  employees: string = null
  industry: string = null
  externalId: string = null
  membership: Membership = null
  pictureUrl: string = null
  analytics: {
    useCase: 'team' | 'individual'
    industry: string
    teamSize: string
    /**
     * Enrichment data from Clearbit
     *
     * @see https://dashboard.clearbit.com/docs#enrichment-api-company-api
     */
    enrichment: {
      category: { industry: string; subIndustry: string }
      metrics: { employees: number }
    }
  } = null
  createdAt: number = null
  updatedAt: number = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get phones(): IdentityPhone[] {
    return []
  }

  get emailAddresses(): string[] {
    return []
  }

  get shortName(): string {
    return this.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get isAnonymous() {
    return Boolean(this.pictureUrl)
  }

  update = (org: Partial<this>): Promise<this> => {
    Object.assign(this, org)
    return this.root.transport.account.organization
      .update(this.serialize())
      .then(this.deserialize)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      employees: this.employees,
      industry: this.industry,
      externalId: this.externalId,
      membership: toJS(this.membership),
      pictureUrl: this.pictureUrl,
      analytics: toJS(this.analytics),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}
