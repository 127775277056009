// @ts-strict-ignore
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useRef } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import InfoButton from './info-button'
import { nanoid } from 'nanoid'

interface DataKey {
  key: string
  name: string
  color: string
}

// @ts-ignore
interface ChartProps extends React.HTMLProps<HTMLDivElement> {
  data: any[]
  dataKeys: DataKey[]
  title: string
  info?: string
  total: number
  unit?: string
}

const Chart: React.FC<ChartProps> = function ({
  data,
  dataKeys,
  title,
  unit,
  info,
  total,
  className,
  ...props
}) {
  const theme = useTheme()
  const styles = useStyles({})
  const uniqueId = useRef(nanoid())

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div>
        <span className={styles.name}>
          {title}
          <InfoButton text={info} />
        </span>
        <span className={styles.value}>
          {total?.toLocaleString() ?? 0}{' '}
          {unit && <span className={styles.unit}>{unit}</span>}
        </span>
      </div>
      <ResponsiveContainer height={125}>
        <BarChart data={data} className={styles.chart}>
          <XAxis
            dataKey="key"
            tickLine={false}
            interval="preserveStartEnd"
            stroke={theme.palette.op.background.highlight(0.2)}
          />
          <Tooltip
            cursor={{
              fill: 'rgba(255, 255, 255, 0)',
              stroke: theme.palette.op.background.highlight(0.35),
              strokeWidth: 1,
              strokeDasharray: '2 2',
            }}
            content={<CustomTooltip dataKeys={dataKeys} />}
          />
          <defs>
            {dataKeys.map((k) => (
              <linearGradient
                id={`${uniqueId.current}${k.key}Color`}
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
                key={k.key}
                spreadMethod="reflect"
              >
                <stop offset="0" stopColor={alpha(k.color, 0.6)} />
                <stop offset="1" stopColor={alpha(k.color, 0.45)} />
              </linearGradient>
            ))}
          </defs>
          {dataKeys.map((k) => (
            <Bar
              key={k.key}
              dataKey={k.key}
              stackId={uniqueId.current}
              strokeWidth={0.5}
              stroke={k.color}
              fill={`url(#${uniqueId.current}${k.key}Color)`}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart

export const CustomTooltip: React.FC<any> = function ({ active, payload, dataKeys }) {
  const styles = useStyles({})
  const data = payload?.[0]?.payload.key
  const values = payload?.map((p) => {
    const dataKey = dataKeys.find((d) => d.key === p.name)
    return {
      name: dataKey.name,
      color: dataKey.color,
      value: p.value,
    }
  })
  return active && values ? (
    <div className={styles.tooltip}>
      <div className={styles.tooltipTitle}>{data}</div>
      {values.map((v, i) => (
        <div key={i} className={styles.tooltipValue}>
          <span className={styles.tooltipColor} style={{ background: v.color }} />
          <span>
            {v.name}: {v.value.toLocaleString()}
          </span>
        </div>
      ))}
    </div>
  ) : null
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1.5rem 1.5rem 1rem',
    boxSizing: 'border-box',
  },
  name: {
    display: 'block',
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.op.background.highlight(0.6),
  },
  value: {
    display: 'block',
    color: theme.palette.text.primary,
    fontSize: '1.75rem',
    lineHeight: '1.5em',
  },
  chart: {
    margin: '5px 0',
    fontSize: 12,
  },
  tooltip: {
    background: theme.palette.op.background.popover,
    boxShadow: theme.palette.op.shadow.tooltip,
    borderRadius: theme.palette.op.borderRadius.tooltip,
    padding: '7px 10px',
    color: theme.palette.text.primary,
    fontSize: '0.9rem',
  },
  tooltipTitle: {
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.text.hint,
  },
  tooltipValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '1.5em',
  },
  tooltipColor: {
    height: 8,
    width: 8,
    borderRadius: 2,
    marginRight: 8,
    display: 'block',
  },
  unit: {
    fontSize: '1rem',
  },
  info: {
    color: theme.palette.text.secondary,
    width: 13,
    height: 13,
    marginLeft: 10,
    opacity: 0.5,
    cursor: 'pointer',
    transition: theme.transitions.create(['opacity'], {
      duration: 0.2,
      easing: 'ease',
    }),

    '&:hover': {
      opacity: 1,
    },
  },
}))
