// @ts-strict-ignore
import Backoff from 'backoff'
import Debug from 'debug'
import { action, makeObservable, observable, reaction } from 'mobx'
import { Observable, Subject } from 'rxjs'
import { filter, share } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'
import Service from '..'
import { platform } from '../..'
import config from '../../config'
import { ConnectionError, MaintenanceError } from '../../lib/api/error-handler'
import log from '../../lib/log'
import { PersistedCollection, Session } from '../model'
import { TransactionRepository } from '../worker/repository'
import AccountClient from './account'
import AuthClient from './auth'
import BillingClient from './billing'
import CommunicationClient from './communication'
import ContactsClient from './contacts'
import IntegrationClient from './integration'
import ApiClient from './network/api'
import Connectivity from './network/connectivity'
import WebSocket from './network/websocket'
import ReportClient from './report'
import SnippetsClient from './snippet'
import { HttpTransaction, Transaction, WebsocketTransaction } from './transaction'
import VoiceClient from './voice'
import WebhooksClient from './webhooks'
import { WebSocketMessage } from './websocket'
import WorkspaceClient from './workspace'

export default class Transport {
  readonly client: ApiClient
  readonly connectivity: Connectivity
  readonly websocket: WebSocket

  private session: Session

  private transactions: PersistedCollection<Transaction, TransactionRepository>
  private blockedQueues = new Set<string>()
  private backoffs = new Map<string, Backoff>()

  private debug = Debug('op:transport')
  private webSocketEnabled = true

  online: boolean = true

  readonly account: AccountClient
  readonly auth: AuthClient
  readonly billing: BillingClient
  readonly communication: CommunicationClient
  readonly contacts: ContactsClient
  readonly integration: IntegrationClient
  readonly snippets: SnippetsClient
  readonly report: ReportClient
  readonly voice: VoiceClient
  readonly workspace: WorkspaceClient
  readonly webhooks: WebhooksClient

  onMessage: Observable<WebSocketMessage>

  constructor(private root: Service) {
    this.transactions = new PersistedCollection({
      table: this.root.storage.table('transaction'),
      classConstructor: (json): Transaction => {
        if (json.type === 'websocket') {
          return new WebsocketTransaction(json)
        } else {
          return new HttpTransaction(json)
        }
      },
      compare: (t1, t2) => t1.createdAt - t2.createdAt,
    })

    this.websocket = new WebSocket()
    this.connectivity = new Connectivity(this.websocket)
    this.client = new ApiClient()

    const onMessageSubject = new Subject<any>()
    this.onMessage = onMessageSubject.asObservable().pipe(share())

    this.client.onRefreshRequired = () =>
      root.auth.refreshToken(this.session.refreshToken)

    this.client.onRefreshRejected = () => {
      root.clearAllAndRestart()
    }

    this.websocket.on('open', this.handleSocketOpen)

    this.websocket.on('error', (error) => {
      log.debug(error.message, { error })
    })

    this.websocket.on('message', (data) => {
      if (this.webSocketEnabled) {
        onMessageSubject.next(data)
      }
    })

    this.websocket.on('unauthorized', () => {
      this.debug('Received unauthorized. Refreshing the token...')
      root.auth.refreshToken(this.session.refreshToken)
    })

    this.websocket.on('accessDenied', () => {
      root.clearAllAndRestart()
    })

    this.account = new AccountClient(this)
    this.auth = new AuthClient(this)
    this.billing = new BillingClient(this)
    this.communication = new CommunicationClient(this)
    this.contacts = new ContactsClient(this)
    this.integration = new IntegrationClient(this)
    this.report = new ReportClient(this)
    this.snippets = new SnippetsClient(this)
    this.voice = new VoiceClient(this)
    this.workspace = new WorkspaceClient(this)
    this.webhooks = new WebhooksClient(this)

    makeObservable<
      this,
      | 'websocket'
      | 'transactions'
      | 'runTransaction'
      | 'runPendingTransactions'
      | 'resetTransaction'
      | 'runNextTransactionInQueue'
      | 'getBackoff'
    >(this, {
      websocket: observable,
      transactions: observable,
      online: observable,
      queue: action,
      setSession: action,
      runTransaction: action,
      runPendingTransactions: action,
      resetTransaction: action,
      runNextTransactionInQueue: action,
      getBackoff: action,
    })

    reaction(
      () => this.connectivity.online,
      (online) => {
        this.debug('went %s', online ? 'online' : 'offline')
        this.online = online
        if (online) this.runPendingTransactions()
      },
      {
        name: 'TransportOnline',
        fireImmediately: true,
      },
    )
  }

  get onNotificationData() {
    return this.onMessage.pipe(
      filter(
        (m): m is Extract<WebSocketMessage, { _class: 'NotificationData' }> =>
          m._class === 'NotificationData',
      ),
    )
  }

  loadPendingTransactions() {
    this.transactions
      .performQuery((query) => query.all())
      .then(() => this.runPendingTransactions())
  }

  /**
   * Queue the transaction to be run as soon as possible
   */
  queue<T>(trx: Transaction<T>): Promise<T> {
    this.transactions.put(trx)
    this.runTransaction(trx)
    return trx.promise()
  }

  setSession(session: Session) {
    this.session = session
    this.client.idToken = this.session?.idToken
    this.websocket.accessToken = this.session?.idToken

    /**
     * When token is refreshed, if websocket connection is closed, start a new one.
     * Otherwise, send the updated token over as a command.
     */

    if (this.websocket.state === 'closed' && this.session?.idToken) {
      this.websocket.connect()
    } else if (this.websocket.state === 'open' && this.session?.idToken) {
      this.websocket.send({
        _class: 'Command',
        path: '/refresh',
        params: { token: this.session.idToken },
      })
    }
  }

  private runPendingTransactions() {
    this.transactions.list.forEach((trx) => {
      if (trx.isPending) {
        this.runTransaction(trx)
      }
    })
  }

  private runTransaction(trx: Transaction) {
    /**
     * If the queue for this trx is blocked, just return. Once the
     * queue is opened, this will be picked up by this.runNextTransactionInQueue
     */
    if (trx.queue && this.blockedQueues.has(trx.queue)) {
      return
    }

    let operation: Promise<any>

    if (trx instanceof HttpTransaction) {
      /**
       * If this is a retryable trx and network is down, leave it for later
       * when network is back up.
       */
      if (trx.retry && !this.online) {
        this.resetTransaction(trx, { discard: false })
        return
      }

      if (trx.queue) {
        this.blockedQueues.add(trx.queue)
      }

      /**
       * Run the transaction. If it fails and retry is not possible, fail with
       * the error.
       */
      operation = trx.run(this.client)
    } else if (trx instanceof WebsocketTransaction) {
      if (this.websocket.state !== 'open') {
        this.resetTransaction(trx, { discard: false })
        return
      }
      if (trx.queue) {
        this.blockedQueues.add(trx.queue)
      }
      operation = trx.run(this.websocket)
    }

    operation
      ?.then(
        action(() => {
          this.resetTransaction(trx, { discard: true })
        }),
      )
      .catch(
        action((error) => {
          if (
            trx.retry &&
            (error instanceof ConnectionError || error instanceof MaintenanceError)
          ) {
            trx.retryAttempts += 1
            const backoff = this.getBackoff(trx)
            backoff.backoff(error)
          } else {
            trx.reject(error)
            this.resetTransaction(trx, { discard: true })
            log.error(`Failed to execute transaction ${trx.id}`, { trx, error })
          }
        }),
      )
  }

  private runNextTransactionInQueue(trx: Transaction) {
    if (!trx.queue) return
    const next = this.transactions.list.find((t) => t.queue === trx.queue)
    if (next) {
      this.runTransaction(next)
    }
  }

  private resetTransaction(trx: Transaction, opts: { discard: boolean }) {
    this.blockedQueues.delete(trx.queue)
    this.backoffs.get(trx.id)?.reset()
    this.backoffs.delete(trx.id)

    if (opts.discard) {
      this.transactions.delete(trx)
      this.runNextTransactionInQueue(trx)
    }
  }

  private getBackoff(trx: Transaction): Backoff {
    if (this.backoffs.has(trx.id)) {
      return this.backoffs.get(trx.id)
    }

    const backoff = Backoff.exponential({
      factor: 2.0,
      initialDelay: 100,
      maxDelay: 60000,
      randomisationFactor: 0.4,
    })

    backoff.on('backoff', function (number, delay, error) {
      log.debug(`Retry #${number} in ${delay}ms`, { trx, error })
    })

    backoff.on('ready', () => {
      this.runTransaction(trx)
    })

    backoff.on('fail', (error) => {
      trx.reject(error)
      this.resetTransaction(trx, { discard: true })
      log.error(`Failed to execute transaction.`, { trx, error })
    })

    return backoff
  }

  private handleSocketOpen = () => {
    let uniqueId = window.localStorage.getItem('uid')
    if (!uniqueId) {
      uniqueId = uuid()
      window.localStorage.setItem('uid', uniqueId)
    }
    this.websocket.send({
      _class: 'Command',
      path: '/app',
      params: {
        uniqueId,
        platform: platform ?? 'browser',
        name: 'web',
        version: config.VERSION,
      },
    })
    this.websocket.send({
      path: '/push',
      params: {},
      _class: 'Observe',
    })
  }
}
