import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { isMac } from '../..'
import Button from '../../component/button'

type LoadingProps = {
  active: boolean
}

const Loading: React.FC<LoadingProps> = function ({ active }) {
  const styles = useStyles({})
  const { signOut, reset: resetState } = useAppStore()
  const [reset, setReset] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [mountTime] = useState(Date.now())

  useEffect(() => {
    if (active === true) return
    const remaining = 1500 - (Date.now() - mountTime)
    if (remaining < 0) {
      setHidden(true)
    } else {
      setTimeout(() => {
        setHidden(true)
      }, remaining)
    }
  }, [active])

  useEffect(() => {
    const id = setTimeout(() => {
      setReset(!hidden)
    }, 10000)
    return () => clearTimeout(id)
  }, [hidden])

  const handleReload = () => {
    resetState()
  }

  const handleSignout = () => {
    signOut()
  }

  return (
    <div className={cx(styles.root, hidden && styles.hidden)}>
      <AnimatingLogo />
      <div className={styles.message}>
        {reset && (
          <>
            <Typography variant="body2" color="textSecondary">
              This is taking too long, choose one of the options below to reset your
              session
            </Typography>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Button variant="text" color="primary" onClick={handleReload}>
                Reload account
              </Button>
              <Button variant="text" color="primary" onClick={handleSignout}>
                Log out
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Loading

export const AnimatingLogo: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="60"
      viewBox="0 0 648 471"
      style={{ overflow: 'visible' }}
    >
      <g fill="none" fillRule="evenodd">
        <defs>
          <mask id="left">
            <rect width="100%" height="100%" fill="white" />
            <circle
              cx="412.5"
              cy="235.5"
              r="235.5"
              fill={theme.palette.op.black}
              className={styles.rightMask}
            />
          </mask>
          <mask id="right">
            <rect width="100%" height="100%" fill="white" />
            <circle
              cx="176"
              cy="236"
              r="176"
              fill={theme.palette.op.black}
              className={styles.leftMask}
            />
          </mask>
        </defs>
        <circle
          className={styles.leftCircle}
          cx="176"
          cy="236"
          r="176"
          mask="url(#left)"
        />
        <circle
          cx="412.5"
          cy="235.5"
          r="235.5"
          fillRule="nonzero"
          mask="url(#right)"
          className={styles.rightCircle}
        />
      </g>
    </svg>
  )
}

const animationDistance = 150

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes leftSlide': {
    from: { transform: `translateX(-${animationDistance}px)` },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes rightSlide': {
    from: { transform: `translateX(${animationDistance}px)` },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes leftMaskSlide': {
    from: { transform: `translateX(-${animationDistance * 2}px)` },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes rightMaskSlide': {
    from: { transform: `translateX(${animationDistance * 2}px)` },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes hide': {
    '0%': { opacity: 1, transform: 'scale(1)' },
    '99%': { opacity: 0, transform: 'scale(1.25)' },
    '100%': { display: 'none' },
  },
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'absolute',
    background: theme.palette.op.background.popover,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.9rem',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    transition: 'opacity 0.3s ease',
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    '-webkit-app-region': 'no-drag',
  },
  message: {
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  leftMask: {
    animationFillMode: 'forwards',
    transform: `translateX(-${animationDistance * 2}px)`,
    animation: '$leftMaskSlide 1s ease 200ms',
  },
  leftCircle: {
    animationFillMode: 'forwards',
    transform: `translateX(-${animationDistance}px)`,
    animation: '$leftSlide 1s ease 200ms',
    fill: theme.palette.primary.main,
  },
  rightMask: {
    animationFillMode: 'forwards',
    transform: `translateX(${animationDistance * 2}px)`,
    animation: '$rightMaskSlide 1s ease 200ms',
  },
  rightCircle: {
    animationFillMode: 'forwards',
    transform: `translateX(${animationDistance}px)`,
    animation: '$rightSlide 1s ease 200ms',
    fill: theme.palette.primary.main,
  },
}))
