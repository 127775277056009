import React, { MutableRefObject, ReactNode, useEffect } from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { fonts } from '../../../theme'

interface TextfieldProps extends React.HTMLProps<HTMLInputElement> {
  innerRef?: MutableRefObject<HTMLInputElement>
  startIcon?: ReactNode
  endIcon?: ReactNode
  inputClassName?: string
  mono?: boolean
}

const Textfield: React.FC<TextfieldProps> = function ({
  innerRef,
  endIcon,
  className,
  inputClassName,
  style,
  startIcon,
  mono = true,
  ...props
}) {
  const styles = useStyles({ startIcon, mono })

  return (
    <div className={cx(styles.root, className)} style={style}>
      {startIcon && <div className={styles.icon}>{startIcon}</div>}
      <input className={cx(styles.input, inputClassName)} ref={innerRef} {...props} />
      {endIcon && <div className={styles.accessory}>{endIcon}</div>}
    </div>
  )
}

const MinimalTextField: React.FC<React.InputHTMLAttributes<HTMLInputElement>> =
  function ({ className, ...props }) {
    const styles = useStyles({})
    return <input type="text" className={cx(styles.minimalInput, className)} {...props} />
  }

export default Textfield
export { MinimalTextField }

const useStyles = makeStyles<Theme, TextfieldProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  input: ({ startIcon, mono }) => ({
    flex: 1,
    fontFamily: mono ? fonts.mono : undefined,
    fontWeight: 400,
    fontSize: '2rem',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: `20px 2rem 20px ${Boolean(startIcon) ? 'calc(2rem + 34px)' : '2rem'}`,
    color: theme.palette.text.primary,
    boxSizing: 'border-box',

    '&::placeholder': {
      color: theme.palette.op.text.tertiary,
      fontSize: '1.5rem',
    },
  }),
  icon: {
    width: 20,
    height: 20,
    marginRight: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    color: theme.palette.op.text.secondary,
    left: '2rem',
  },
  accessory: {
    marginRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  minimalInput: {
    outline: 'none',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    border: `1.5px solid rgba(255, 255, 255, 0.10)`,
    fontSize: '1rem',
    padding: '0.75rem 1rem',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      duration: '0.3s',
      easing: 'ease',
    }),
    color: theme.palette.text.primary,
    boxSizing: 'border-box',
    resize: 'none',

    '&::placeholder': {
      color: theme.palette.text.secondary,
    },

    '&:focus': {
      border: '1.5px solid rgba(255, 255, 255, 0.3)',
      boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.1)',
    },
  },
}))
