import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { IntegrationType } from '../../../types'
import { EmailIcon, SlackIcon } from '../../../component/icons/custom'
import Button from '../../../component/button-v2'

interface AddIntegrationProps {
  type: IntegrationType
  onClick: () => void
  isLoading?: boolean
}

const AddIntegration: React.FC<AddIntegrationProps> = function ({
  onClick,
  type,
  isLoading,
}) {
  const styles = useStyles({})

  return (
    <Button
      fullWidth
      align="start"
      color="primary"
      variant="ghost"
      onClick={onClick}
      className={styles.root}
      startIcon={type === 'slack' ? <SlackIcon /> : <EmailIcon />}
      loading={isLoading}
    >
      {type === 'slack' ? 'Connect to your Slack' : 'Connect to your email'}
    </Button>
  )
}

export default AddIntegration

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 16,
  },
}))
