import { makeStyles, Theme } from '@material-ui/core'
import React, { ReactNode } from 'react'

interface InfoCardProps {
  children: ReactNode
}

const InfoCard = ({ children }: InfoCardProps) => {
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <span className={styles.emoji}>ℹ️</span>
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.op.hover.primary,
    borderRadius: 7,
    color: theme.palette.op.gray[2],
    columnGap: 12,
    display: 'flex',
    fontSize: 13,
    fontWeight: 450,
    lineHeight: '16px',
    padding: '14px 16px',
  },
  emoji: {
    flexShrink: 0,
    fontSize: 15,
    lineHeight: '20px',
  },
}))

export default InfoCard
