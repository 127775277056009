import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { fuzzySearch } from '../../../lib/search'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { ContactView } from '../../../service/model'
import {
  Command,
  Content,
  EmptyListMessage,
  HeaderItem,
  InputHeader,
  SelectItem,
} from '../common-v2'

interface ContactSourceProps {
  view: ContactView
  onBack: () => void
}

type ItemType =
  | { type: 'item'; source: string; name?: string; count: number }
  | { type: 'header'; name: string }

const ContactSource: React.FC<ContactSourceProps> = function ({ view, onBack }) {
  const styles = useStyles({})
  const { service, command } = useAppStore()
  const [search, setSearch] = useInputState('')

  const items: ItemType[] = useMemo(() => {
    let op = 0
    let device: { [key: string]: number } = {}
    let google: { [key: string]: number } = {}
    let csv: { [key: string]: number } = {}
    let others: { [key: string]: number } = {}
    const items: ItemType[] = []

    service.contact.collection.list.forEach((c) => {
      if (!c.source) {
        op += 1
      } else if (c.source === 'device') {
        device[c.sourceName] ??= 0
        device[c.sourceName] += 1
      } else if (c.source === 'google-people') {
        google[c.sourceName] ??= 0
        google[c.sourceName] += 1
      } else if (c.source === 'csv') {
        csv[c.sourceName] ??= 0
        csv[c.sourceName] += 1
      } else {
        others[c.source] ??= 0
        others[c.source] += 1
      }
    })

    if (op) {
      items.push({ type: 'item', name: 'OpenPhone', source: 'op', count: op })
    }
    if (Object.keys(device).length > 0) {
      items.push(
        { type: 'header', name: 'Device' },
        ...Object.keys(device).map(
          (name): ItemType => ({
            type: 'item',
            source: 'device',
            name,
            count: device[name],
          }),
        ),
      )
    }
    if (Object.keys(google).length > 0) {
      items.push(
        { type: 'header', name: 'Google Contacts' },
        ...Object.keys(google).map(
          (name): ItemType => ({
            type: 'item',
            source: 'google-people',
            name,
            count: google[name],
          }),
        ),
      )
    }
    if (Object.keys(csv).length > 0) {
      items.push(
        { type: 'header', name: 'CSV Imports' },
        ...Object.keys(csv).map(
          (name): ItemType => ({
            type: 'item',
            source: 'csv',
            name,
            count: csv[name],
          }),
        ),
      )
    }
    if (Object.keys(others).length > 0) {
      items.push(
        { type: 'header', name: 'Others' },
        ...Object.keys(others).map(
          (source): ItemType => ({
            type: 'item',
            source,
            count: csv[source],
          }),
        ),
      )
    }
    return items
  }, [])

  const filteredItems = useMemo(() => {
    if (!search) return items
    return fuzzySearch(
      items.filter((i) => i.type === 'item'),
      search,
      ['source', 'name'] as any,
    )
  }, [items, search])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: filteredItems,
    skip: (item) => item?.type === 'header',
    name: 'command/contact-filters/sources',
    handleSelect: (item) => {
      if (item.type === 'item') {
        toggleSource(item)
      }
    },
  })

  const toggleSource = (source: ItemType) => {
    if (source.type === 'header') return
    view.toggleSource({ type: source.source, name: source.name })
  }

  return (
    <Command>
      <InputHeader
        autoFocus
        onBack={onBack}
        placeholder="Search for a source..."
        value={search}
        onChange={setSearch}
      />
      <Content>
        {filteredItems.map((item, index) =>
          item.type == 'header' ? (
            <HeaderItem {...getItemProps(index)} key={index}>
              {item.name}
            </HeaderItem>
          ) : (
            <SelectItem
              {...getItemProps(index)}
              key={index}
              checked={view.hasSource({ type: item.source, name: item.name })}
              selected={selectedIndex === index}
            >
              <div className={styles.row}>
                <Typography nowrap variant="footnote">
                  {item.name ? item.name : item.source}
                </Typography>
                <Typography
                  nowrap
                  variant="caption1"
                  color="textSecondary"
                  fontWeight="regular"
                  className={styles.count}
                >
                  {item.count}
                </Typography>
              </div>
            </SelectItem>
          ),
        )}
        {filteredItems.length === 0 && (
          <EmptyListMessage text="Nothing found matching your search query." />
        )}
      </Content>
    </Command>
  )
}

export default observer(ContactSource)

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  count: {
    marginLeft: 20,
    flex: '0 0 auto',
  },
}))
