// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import AudioPlayer from '../../../component/audio-player'
import { CallItemPart, FeedItemIconType, Item } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'
import { useAppStore } from '@src/app/context'
import {
  CallAnsweredIcon,
  CallDefaultIcon,
  CallMissedIcon,
} from '../../icons/Tint/20/General'
import FormattedText from '../../../component/formatted-text'

export type CallProps<I extends Item> = ItemPartProps<I, CallItemPart>

const renderIcon = (iconType: FeedItemIconType) => {
  if (iconType === 'call-default') {
    return <CallDefaultIcon />
  }

  if (iconType === 'call-missed') {
    return <CallMissedIcon />
  }

  if (iconType === 'call-answered') {
    return <CallAnsweredIcon />
  }
}

const getIconBackgroundColor = (
  theme: Theme,
  iconType: FeedItemIconType,
  isOutgoing: boolean,
) => {
  if (iconType === 'call-default') {
    return theme.palette.op.secondary.green1
  }

  if (iconType === 'call-missed') {
    return theme.palette.op.secondary.red1
  }

  if (iconType === 'call-answered' && !isOutgoing) {
    return theme.palette.op.gray[3]
  }

  if (iconType === 'call-answered' && isOutgoing) {
    return theme.palette.op.primary[1]
  }
}

const Call = function <I extends Item>({
  controller,
  item,
  title,
  iconType,
  subtitle,
  duration,
  transcript,
  voicemail,
  recordings,
  ...props
}: CallProps<I>) {
  const styles = useStyles({ item, iconType })
  const { service, inboxes } = useAppStore()
  const isAdmin = service.user.current.asMember.canAdminPhoneNumber(
    inboxes.selected.phoneNumber,
  )

  const renderMedia = () => {
    if (voicemail && voicemail.url) {
      return (
        <div className={styles.mediaContainer}>
          <span className={styles.heading}>Voicemail</span>
          <div key={voicemail.url} className={styles.audioWrapper}>
            <AudioPlayer
              media={voicemail}
              className={styles.audio}
              showOnlyDownloadAction={false}
            />
          </div>
        </div>
      )
    }
    if (recordings && recordings.length > 0) {
      return (
        <div className={styles.mediaContainer}>
          <span className={styles.heading}>
            {recordings?.length > 1 ? 'Recordings' : 'Recording'}
          </span>
          {recordings.map((m) => {
            const handleDelete = isAdmin
              ? () => item.handleDeleteCallRecording(m)
              : undefined
            return (
              <div key={m.url} className={styles.audioWrapper}>
                <AudioPlayer
                  media={m}
                  className={styles.audio}
                  showOnlyDownloadAction={false}
                  handleDelete={handleDelete}
                  onMenuClick={() => item.menu.close()}
                />
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props}>
        <div className={cx(styles.root)}>
          <div className={styles.header}>
            <div className={styles.iconContainer}>{renderIcon(iconType)}</div>
            <div className={styles.titleContainer}>
              <span className={styles.title}>{title}</span>
              <span className={styles.subtitle}>{subtitle}</span>
            </div>
          </div>
          {transcript ? (
            <>
              <div className={styles.transcript}>
                <span className={styles.heading}>Transcript</span>
                <div className={styles.transcriptBody}>
                  <FormattedText value={transcript} />
                </div>
              </div>
            </>
          ) : null}
        </div>
        {renderMedia()}
      </Bubble>
    </Row>
  )
}

export default observer(Call)

const useStyles = makeStyles<Theme, Partial<CallProps<Item>>>(
  (theme: Theme) => ({
    root: ({ item }) => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '12px 14px',
      fontSize: '0.85rem',
      userSelect: 'none',
      minHeight: item.display.dimensions.avatarSize,
    }),
    audioWrapper: {
      height: 42,
      marginBottom: 10,
      borderRadius: 10,
      background: theme.palette.op.background.highlight(0.05),
      userSelect: 'none',

      '& audio': {
        display: 'none',
      },
    },
    audio: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 5,
    },
    header: {
      display: 'flex',
      height: 36,
      width: '100%',
    },
    iconContainer: ({ iconType, item }) => ({
      height: 36,
      width: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: getIconBackgroundColor(theme, iconType, item.isOutgoing),
      marginRight: 10,
    }),
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 15,
      fontWeight: 550,
      lineHeight: 1.33,
    },
    subtitle: {
      fontSize: 13,
      fontWeight: 450,
      lineHeight: 1.23,
      opacity: 0.8,
    },
    transcript: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: '12px',
      fontSize: 14,
      userSelect: 'text',

      '& a': {
        color: 'inherit',
      },
    },
    heading: ({ item }) => ({
      opacity: 0.6,
      display: 'inline-block',
      fontSize: 11,
      fontWeight: 550,
      lineHeight: 1.27,
      padding: '4px 0',
      color: item.isOutgoing ? theme.palette.op.primary[3] : 'inherit',
    }),
    transcriptBody: ({ item }) => ({
      fontSize: 15,
      fontWeight: 450,
      lineHeight: 1.33,
      color: item.isOutgoing ? theme.palette.op.primary[3] : 'inherit',
    }),
    mediaContainer: {
      padding: '0 14px',
    },
  }),
  { name: Call.name },
)
