import axios from 'axios'
import Backoff from 'backoff'
import Debug from 'debug'
import { action, makeObservable, observable, reaction } from 'mobx'
import log from '../../../lib/log'
import { throttle } from '../../../lib/throttle'
import WebSocket from './websocket'

export default class Connectivity {
  online = true

  private lastHeartbeat = Date.now()
  private backoff: Backoff
  private debug = Debug('op:transport:connectivity')
  private request = axios.create({
    timeout: 20000,
  })

  get downtime() {
    return this.websocket.downtime
  }

  constructor(private websocket: WebSocket) {
    this.backoff = Backoff.exponential({
      factor: 2.0,
      initialDelay: 100,
      maxDelay: 20000,
      randomisationFactor: 0.4,
    })

    // If network is detected, check for internet connection
    if (window.navigator.onLine) {
      this.checkInternetConnection()
    }

    makeObservable(this, {
      online: observable,
    })

    reaction(
      () => this.websocket.isOpen,
      () => this.checkInternetConnection(),
    )

    reaction(
      () => this.online,
      (online) => this.debug(online ? 'online!' : 'offline!'),
    )

    setInterval(() => {
      this.lastHeartbeat = Date.now()
    }, 60_000)

    document.addEventListener(
      'visibilitychange',
      action(() => {
        const now = Date.now()
        if (document.visibilityState === 'visible' && this.lastHeartbeat < now - 90_000) {
          log.debug(
            `[NETWORK] Old heartbeat detected (${
              now - this.lastHeartbeat
            }ms ago), marking as offline and checking connection`,
          )
          this.online = false
          this.lastHeartbeat = now
          this.checkInternetConnection()
        }
      }),
    )

    // Called when a backoff timer is started.
    this.backoff.on(
      'backoff',
      action((_: any, delay: number) => {
        this.debug('connection could not be established, trying in %dms.', delay)
        this.online = false
      }),
    )

    // Called when a backoff timer ends. We want to try to reconnect
    // the WebSocket at this point.
    this.backoff.on('ready', (attempt: number) => {
      this.checkInternetConnection()
    })

    window.addEventListener('online', () => {
      this.backoff.reset()
      this.checkInternetConnection()
    })

    window.addEventListener(
      'offline',
      action(() => {
        this.online = false
      }),
    )
  }

  private checkInternetConnection = throttle(() => {
    this.debug('checking internet connection')
    this.request
      .get('https://openphoneapi.com/ping')
      .then(
        action(() => {
          this.online = true
          this.backoff.reset()
        }),
      )
      .catch(
        action(() => {
          this.online = false
          this.backoff.backoff()
        }),
      )
  }, 2_000)
}
