// @ts-strict-ignore
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../../component/icon-button'
import {
  CallIcon,
  CopyIcon,
  EnvelopeIcon,
  MessageIcon,
  NewWindowIcon,
} from '../../../component/icons/Tint/16/General'
import { formatted } from '../../../lib/phone-number'
import { ContactItemType } from '../../../types'
import { placeholders } from './custom-item'
import Item from './index'

interface ReadyOnlyItemProps {
  type: ContactItemType
  name: string
  value: string
}

const ReadyOnlyItem: React.FC<ReadyOnlyItemProps> = function ({ type, name, value }) {
  const valueRef = useRef<HTMLDivElement>(null)
  const { voice, inboxes, toast } = useAppStore()

  const handleCall = (event) => {
    voice.startCall(inboxes.selectedPhoneNumber, value)
    event.stopPropagation()
  }

  const handleMessage = (event) => {
    const inbox = inboxes.getOrSetPhoneNumberInbox()
    inbox.newConversation(value)
    event.stopPropagation()
  }

  const handleEmail = (event?: any) => {
    event?.stopPropagation()
    window.open(`mailto:${value}`)
  }

  const handleUrl = (event?: any) => {
    event?.stopPropagation()
    window.open(value)
  }

  const handleCopy = (event) => {
    let val: string = value
    if (type === 'phone-number') {
      val = formatted(value)
    }
    navigator.clipboard.writeText(val)
    toast.show({ message: `Copied to clipboard.` })
    event.stopPropagation()
  }

  const handleValueClick = () => {
    if (type === 'url') {
      handleUrl()
    } else if (type === 'email') {
      handleEmail()
    }
  }

  const renderActions = () => {
    const actions: React.ReactNode[] = []
    if (type === 'email') {
      actions.push(
        <IconButton
          key="email"
          title="Email"
          size="small"
          icon={<EnvelopeIcon />}
          onClick={handleEmail}
        />,
      )
    } else if (type === 'url') {
      actions.push(
        <IconButton
          key="link"
          size="small"
          title="Open in new window"
          icon={<NewWindowIcon />}
          onClick={handleUrl}
        />,
      )
    } else if (type === 'phone-number') {
      actions.push(
        <IconButton
          key="call"
          size="small"
          title="Call"
          icon={<CallIcon />}
          onClick={handleCall}
        />,
        <IconButton
          key="message"
          size="small"
          title="Message"
          icon={<MessageIcon />}
          onClick={handleMessage}
        />,
      )
    }
    if (typeof value === 'string') {
      actions.push(
        <IconButton
          key="copy"
          title="Copy"
          size="small"
          icon={<CopyIcon />}
          onClick={handleCopy}
        />,
      )
    }
    return actions
  }

  const renderValue = () => {
    if (type === 'phone-number') {
      return formatted(value)
    } else if (Object.keys(placeholders).includes(type)) {
      return value
    }
  }

  return (
    <Item
      type={type}
      name={name}
      valueRef={valueRef}
      disableName
      disableValue
      value={renderValue()}
      onValueClick={handleValueClick}
      actions={renderActions()}
    />
  )
}

export default observer(ReadyOnlyItem)
