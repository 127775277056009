// @ts-strict-ignore
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Identity, Member, Participant, thumbnailUrl } from '../../service/model'

export type AvatarSizeNumericValues =
  | 100
  | 80
  | 48
  | 40
  | 36
  | 34
  | 30
  | 25
  | 24
  | 22
  | 20
  | 16
  | 14
  | 12

export type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | AvatarSizeNumericValues

export interface AvatarProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  size?: AvatarSize
  identity?: Identity | { pictureUrl: string }
  hideStatus?: boolean
  displayBadge?: boolean
}

/**
 * @deprecated Use Avatar from `@ui/Avatar` or AppAvatar instead.
 */
const Avatar: React.ForwardRefRenderFunction<any, AvatarProps> = function (
  { size = 'small', identity, className, hideStatus, displayBadge = true, ...props },
  outerRef,
) {
  const theme = useTheme()
  const styles = useStyles({ size })
  const pictureUrl = identity?.pictureUrl
  const gradient = gradientUrl(identity)
  const backgroundImage =
    thumbnailUrl(pictureUrl, { width: getDimension(size) * 2, quality: 100 }) ||
    gradient ||
    theme.palette.op.match({
      dark: defaultDark,
      light: defaultLight,
    })

  const badge = (() => {
    if (!identity || !('source' in identity) || getDimension(size) < 34) return null
    if (identity.source === 'hubspot' && displayBadge) {
      return identity.source
    }
  })()

  const initials = (() => {
    if (identity && 'initials' in identity) return identity.initials
    return null
  })()

  const presence = (() => {
    if (identity instanceof Participant) {
      return identity.member?.presence
    } else if (identity instanceof Member) {
      return identity.presence
    }
    return null
  })()

  const status = (() => {
    if (!presence) return
    if (presence.onCall) {
      return 'busy'
    } else if (presence.status === 'online') {
      return presence.snoozed ? 'activeSnoozed' : 'active'
    } else {
      return presence.snoozed ? 'inactiveSnoozed' : 'inactive'
    }
  })()

  return (
    <div ref={outerRef} {...props} className={cx(styles.root, className)}>
      <div
        style={{ backgroundImage: `url('${backgroundImage}')` }}
        className={cx(
          styles.circle,
          presence && !hideStatus && styles.presenceMask,
          badge && styles.badgeMask,
        )}
      >
        {!pictureUrl && gradient && initials}
      </div>
      {badge ? (
        <div className={cx(styles.badge, styles[badge])} />
      ) : (
        !hideStatus && presence && <div className={cx(styles.presence, styles[status])} />
      )}
    </div>
  )
}

export default observer(Avatar, { forwardRef: true })

const gradientUrl = (identity: Identity | { pictureUrl: string }) => {
  if (!identity || !identity['id'] || (identity as Identity).isAnonymous) {
    return null
  }
  const id = identity['id']
  let sum = 0
  for (let i = 0; i < id.length; i++) {
    sum += id.charCodeAt(i)
  }
  return gradients[sum % 6]
}

const defaultDark = require('./default-dark.svg').default
const defaultLight = require('./default-light.svg').default

export const getDimension = (size: AvatarSize): number => {
  switch (size) {
    case 'large':
      return 80
    case 'medium':
      return 40
    case 'small':
      return 25
    case 'tiny':
      return 20
    default:
      return size
  }
}

export const getFontSize = (size: AvatarSize): number => {
  switch (size) {
    case 100:
    case 80:
    case 'large':
      return 28

    case 48:
    case 40:
    case 36:
    case 34:
    case 30:
    case 'medium':
      return 14

    case 25:
    case 'small':
      return 10

    case 24:
    case 22:
    case 20:
    case 16:
    case 14:
    case 12:
    case 'tiny':
      return 6

    default:
      return 10
  }
}

const getPresenceMask = (size: AvatarSize): string => {
  switch (size) {
    case 100:
    case 80:
    case 'large':
      return `url('${mask.large}')`

    case 48:
    case 40:
    case 36:
    case 34:
    case 30:
    case 'medium':
      return `url('${mask.medium}')`

    case 25:
    case 'small':
      return `url('${mask.small}')`

    case 24:
    case 22:
    case 20:
    case 16:
    case 14:
    case 12:
    case 'tiny':
      return `url('${mask.tiny}')`
  }
}

const getPresenceStyle = (size: AvatarSize): CSSProperties => {
  switch (size) {
    case 100:
    case 80:
    case 'large':
      return { transform: 'scale(1.75)', right: 2, bottom: 1 }

    case 48:
    case 40:
    case 36:
    case 34:
    case 'medium':
      return { right: -3, bottom: -3 }

    case 30:
      return { transform: 'scale(0.90)', right: -4, bottom: -4 }

    case 25:
    case 'small':
      return { transform: 'scale(0.80)', right: -4, bottom: -3 }

    case 24:
    case 22:
    case 20:
    case 16:
    case 14:
    case 12:
    case 'tiny':
      return { transform: 'scale(0.80)', right: -5, bottom: -4 }

    default:
      return {}
  }
}

const getBadgeSize = (size: AvatarSize): CSSProperties => {
  switch (size) {
    case 100:
    case 80:
    case 'large':
      return { width: 24, height: 24 }

    case 48:
    case 40:
    case 36:
    case 34:
    case 30:
    case 'medium':
      return { width: 14, height: 14 }

    case 25:
    case 'small':
      return { width: 12, height: 12 }

    case 24:
    case 22:
    case 20:
    case 16:
    case 14:
    case 12:
    case 'tiny':
      return { width: 12, height: 12 }

    default:
      return {}
  }
}

const badgeMask = {
  small: require('./badge-sm.svg').default,
  default: require('./badge.svg').default,
}

const badge = {
  hubspot: require('./hubspot.svg').default,
}

const gradients = [
  require('./gradient-0.svg').default,
  require('./gradient-1.svg').default,
  require('./gradient-2.svg').default,
  require('./gradient-3.svg').default,
  require('./gradient-4.svg').default,
  require('./gradient-5.svg').default,
]

const mask = {
  tiny: require('./mask-tiny.svg').default,
  small: require('./mask-small.svg').default,
  medium: require('./mask-medium.svg').default,
  large: require('./mask-large.svg').default,
}

const status = {
  busy: require('./busy.svg').default,
  active: require('./active.svg').default,
  inactive: require('./inactive.svg').default,
  activeSnoozed: require('./active-snoozed.svg').default,
  activeSnoozedSmall: require('./active-snoozed-small.svg').default,
  inactiveSnoozed: require('./inactive-snoozed.svg').default,
  inactiveSnoozedSmall: require('./inactive-snoozed-small.svg').default,
}

const useStyles = makeStyles<Theme, Partial<AvatarProps>>((theme: Theme) => ({
  root: {
    flex: '0 0 auto',
    width: 'fit-content',
    position: 'relative',
    transition: theme.transitions.create(['opacity'], { duration: 192 }),
  },
  circle: ({ size }) => {
    const dim = getDimension(size)
    return {
      borderRadius: '50%',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.op.background.highlight(0.2),
      backgroundPosition: 'center center',
      color: theme.palette.op.white,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maskSize: 'contain',
      width: dim,
      height: dim,
      fontSize: getFontSize(size),
    }
  },
  presenceMask: ({ size }) => ({
    maskImage: getPresenceMask(size),
    borderRadius: 'unset',
  }),
  presence: ({ size }) => ({
    ...getPresenceStyle(size),
    backgroundSize: 'cover',
    backgroundPosition: 'left top',
    position: 'absolute',
    width: 12,
    height: 11,
  }),
  badgeMask: ({ size }) => ({
    maskImage:
      getDimension(size) < 40
        ? `url('${badgeMask.small}')`
        : `url('${badgeMask.default}')`,
    borderRadius: 'unset',
  }),
  busy: { backgroundImage: `url('${status.busy}')` },
  active: { backgroundImage: `url('${status.active}')` },
  inactive: { backgroundImage: `url('${status.inactive}')` },
  activeSnoozed: {
    backgroundImage: `url('${status.activeSnoozed}')`,
  },
  inactiveSnoozed: {
    backgroundImage: `url('${status.inactiveSnoozed}')`,
  },
  badge: ({ size }) => ({
    ...getBadgeSize(size),
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: -2,
    right: -2,
  }),
  hubspot: {
    backgroundImage: `url('${badge.hubspot}')`,
  },
}))
