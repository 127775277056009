// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import FormattedText from '../../../component/formatted-text'
import { Item, TextItemPart } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'
import cx from 'classnames'

export type MessageProps<I extends Item> = ItemPartProps<I, TextItemPart>

const Message = function <I extends Item>({
  controller,
  item,
  text,
  renderMention: renderMentionProp,
  ...props
}: MessageProps<I>) {
  const styles = useStyles({ item })

  const renderMention = useCallback(
    (mention: string) => renderMentionProp(mention, item),
    [renderMentionProp, item],
  )

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble
        controller={controller}
        item={item}
        {...props}
        transparent={item.display.isEmojiMessage}
      >
        <div className={cx(styles.root, item.display.isEmojiMessage && styles.emoji)}>
          <FormattedText
            value={text}
            renderMention={renderMentionProp ? renderMention : null}
          />
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(Message)

const useStyles = makeStyles<Theme, Partial<MessageProps<Item>>>(
  (theme) => ({
    root: ({ item }) => ({
      padding: `${item.display.dimensions.messagePaddingVertical}px ${item.display.dimensions.messagePaddingHorizontal}px`,
      wordBreak: 'break-word',
      userSelect: 'text',
      minHeight: item.display.dimensions.avatarSize,

      '& a': {
        color: 'inherit',
      },
    }),
    emoji: ({ item }) => ({
      fontSize: item.display.dimensions.avatarSize,
      padding: 0,
      lineHeight: '1.1em',
    }),
  }),
  { name: Message.name },
)
