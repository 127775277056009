import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import TextInput from '@ui/TextInput'
import useInputState from '@src/lib/use-input-state'
import Callout from '@src/component/callout'
import { SettingRow } from '../components/setting-row'
import type HubSpotController from './controller'

function CustomActivitySetting({ controller }: { controller: HubSpotController }) {
  const [isCustomActivityOpened, setIsCustomActivityOpen] = useState(false)
  const styles = useStyles({ isOpen: isCustomActivityOpened })

  const [callActivityType, setCallActivityType] = useInputState('')
  const [messageActivityType, setMessageActivityType] = useInputState('')

  const handleActivityTypeBlur = () => {
    controller.updateActivityType(callActivityType, messageActivityType)
  }

  useEffect(() => {
    if (!controller.integration) return
    setCallActivityType(controller.integration.logCalls.activityType || '')
    setMessageActivityType(controller.integration.logMessages.activityType || '')
  }, [controller.integration])

  return (
    <div className={styles.customActivity}>
      <SettingRow
        title="Enable custom activity types"
        subtitle="Only enable if you have advanced knowledge of HubSpot"
        checked={isCustomActivityOpened}
        onChange={() => setIsCustomActivityOpen((prev) => !prev)}
      />
      {isCustomActivityOpened ? (
        <div className={styles.customActivitySetting}>
          <TextInput
            id="calls_type"
            placeholder="Calls"
            value={callActivityType}
            onChange={setCallActivityType}
            disabled={!controller.integration.logCalls.enabled}
            onBlur={handleActivityTypeBlur}
          />
          <TextInput
            id="message_type"
            value={messageActivityType}
            onChange={setMessageActivityType}
            placeholder="Message"
            disabled={!controller.integration.logMessages.enabled}
            onBlur={handleActivityTypeBlur}
          />
          <Callout
            className={styles.callout}
            color="yellow"
            icon={<>⚠️</>}
            body={
              <>
                Changing the default activity types can stop your integration from working
                if not set up correctly.{' '}
                <a
                  href="https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types"
                  target="_blank"
                >
                  Learn more
                </a>{' '}
                about custom activity types for HubSpot.
              </>
            }
          />
        </div>
      ) : null}
    </div>
  )
}

export default CustomActivitySetting

const useStyles = makeStyles<Theme, { isOpen: boolean }>((theme) => ({
  customActivity: ({ isOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 25,
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
    '& > label': {
      borderBottom: 0,
      width: '100%',
      paddingBottom: isOpen ? 14 : 0,
    },
  }),
  customActivitySetting: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 12,
    rowGap: 14,
  },
  callout: {
    gridColumn: '1 / -1',
  },
}))
