// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import Checkbox from '../../../component/checkbox'
import Item from '../common/item'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Typography from '@material-ui/core/Typography'
import { DAYS } from '../../../lib/date'
import useKeyStepper from '../../../lib/use-key-stepper'
import { replaceAtIndex } from '../../../lib/collections'
import Content from '../common/content'

export interface ScheduleProps {
  defaultDay?: number
  onSave: (days: boolean[]) => void
}

const Schedule: React.FC<ScheduleProps> = function ({ defaultDay, onSave }) {
  const styles = useStyles({})
  const defaultChecked = new Array(7)
  defaultChecked.fill(false)
  defaultChecked[defaultDay] = true
  const [checked, setChecked] = useState<boolean[]>(defaultChecked)

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: DAYS,
    name: 'command/set-schedule/days',
    handleChecked: (item, index) => {
      const active = Boolean(checked[index])
      setChecked(replaceAtIndex(checked, !active, index))
    },
    handleSelect: (item, index) => {
      const active = Boolean(checked[index])
      setChecked(replaceAtIndex(checked, !active, index))
    },
    deps: [checked],
  })

  const handleSave = () => {
    onSave(checked)
  }

  return (
    <>
      <Content>
        {DAYS.map((item, index) => (
          <Item key={item} {...getItemProps(index)} highlighted={index === selectedIndex}>
            <Checkbox checked={Boolean(checked[index])} />
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ marginLeft: '1rem' }}
            >
              {item}
            </Typography>
          </Item>
        ))}
      </Content>
      <Footer
        actions={
          <FooterButton variant="contained" onClick={handleSave}>
            Save Schedule
          </FooterButton>
        }
      />
    </>
  )
}

export default observer(Schedule)

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    flex: 1,
    padding: '20px 2rem 0',
  },
  inputWrapper: {
    display: 'flex',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
}))
