// @ts-strict-ignore
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useRef, useState } from 'react'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import Button from '../../../component/button-v2'
import TextField from '../../../component/textfield-v2'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'
import CommandFooter from '../common/footer'
import CommandHeader from '../common/header'
import { isValidEmail } from '@openphone/lib'
import { useAppStore } from '@src/app/context'

const AddDomainCommand = () => {
  const styles = useStyles()
  const ref = useRef<HTMLInputElement>()
  const [input, setInput] = useInputState('')
  const [loading, setLoading] = useState(false)
  const { toast, command, workspace, service } = useAppStore()

  const handleSubmit = () => {
    setLoading(true)
    workspace
      .addDomain(input.trim())
      .then(() => {
        if (input === service.user.current.email) {
          toast.show({ message: 'Domain added successfully.' })
          command.hide()
          return
        }
        command.present({ name: 'verify domain', email: input })
      })
      .catch(toast.showError)
      .finally(() => setLoading(false))
  }

  const isEmailValid = isValidEmail(input.trim())

  useKeyboardShortcuts({
    node: ref.current,
    name: 'command/add-domain',
    dep: [input, isEmailValid],
    handler: (shortcut) => {
      if (shortcut !== 'Enter') return
      if (!isEmailValid) {
        toast.showError(new Error('Email address does not look valid. Try again.'))
      } else {
        handleSubmit()
      }
    },
  })

  return (
    <Command width={672}>
      <CommandHeader
        title="Add an email domain"
        subtitle="Anyone with an email address at this whitelisted domain will be able to automatically sign up to this Workspace."
      />
      <div className={styles.content}>
        <TextField
          ref={ref}
          autoFocus
          label="Email address"
          placeholder="Enter an email address..."
          value={input}
          onChange={setInput}
          helperText="Enter an email address from the domain you would like to whitelist. If this is a different email from the one your account is linked to, we will email you a code to verify."
        />
      </div>
      <CommandFooter>
        <div className={styles.footer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={styles.submitBtn}
            disabled={!isEmailValid}
            loading={loading}
          >
            Add domain
          </Button>
        </div>
      </CommandFooter>
    </Command>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    content: {
      margin: '16px 32px 24px 32px',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
    },
    submitBtn: {
      alignSelf: 'flex-end',
    },
  }),
  { name: AddDomainCommand.name },
)

export default AddDomainCommand
