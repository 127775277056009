export const timezones = [
  { name: 'Pacific/Midway', offset: 'UTC-11:00' },
  { name: 'America/Adak', offset: 'UTC-10:00' },
  { name: 'Pacific/Honolulu', offset: 'UTC-10:00' },
  { name: 'Pacific/Marquesas', offset: 'UTC-09:30' },
  { name: 'America/Anchorage', offset: 'UTC-09:00' },
  { name: 'America/Tijuana', offset: 'UTC-08:00' },
  { name: 'America/Los_Angeles', offset: 'UTC-08:00' },
  { name: 'America/Phoenix', offset: 'UTC-07:00' },
  { name: 'America/Chihuahua', offset: 'UTC-07:00' },
  { name: 'America/Denver', offset: 'UTC-07:00' },
  { name: 'America/Belize', offset: 'UTC-06:00' },
  { name: 'America/Chicago', offset: 'UTC-06:00' },
  { name: 'Pacific/Easter', offset: 'UTC-06:00' },
  { name: 'America/Mexico_City', offset: 'UTC-06:00' },
  { name: 'America/Regina', offset: 'UTC-06:00' },
  { name: 'America/Bogota', offset: 'UTC-05:00' },
  { name: 'America/Cancun', offset: 'UTC-05:00' },
  { name: 'America/New_York', offset: 'UTC-05:00' },
  { name: 'America/Toronto', offset: 'UTC-05:00' },
  { name: 'America/Port-au-Prince', offset: 'UTC-05:00' },
  { name: 'America/Havana', offset: 'UTC-05:00' },
  { name: 'America/Indiana/Indianapolis', offset: 'UTC-05:00' },
  { name: 'America/Asuncion', offset: 'UTC-04:00' },
  { name: 'America/Halifax', offset: 'UTC-04:00' },
  { name: 'America/Caracas', offset: 'UTC-04:00' },
  { name: 'America/Cuiaba', offset: 'UTC-04:00' },
  { name: 'America/Manaus', offset: 'UTC-04:00' },
  { name: 'America/Santiago', offset: 'UTC-04:00' },
  { name: 'America/Grand_Turk', offset: 'UTC-04:00' },
  { name: 'America/St_Johns', offset: 'UTC-03:30' },
  { name: 'America/Fortaleza', offset: 'UTC-03:00' },
  { name: 'America/Sao_Paulo', offset: 'UTC-03:00' },
  { name: 'America/Cayenne', offset: 'UTC-03:00' },
  { name: 'America/Buenos_Aires', offset: 'UTC-03:00' },
  { name: 'America/Godthab', offset: 'UTC-03:00' },
  { name: 'America/Montevideo', offset: 'UTC-03:00' },
  { name: 'America/Miquelon', offset: 'UTC-03:00' },
  { name: 'America/Bahia', offset: 'UTC-03:00' },
  { name: 'America/Noronha', offset: 'UTC-02:00' },
  { name: 'Atlantic/Azores', offset: 'UTC-01:00' },
  { name: 'Atlantic/Cape_Verde', offset: 'UTC-01:00' },
  { name: 'Africa/Casablanca', offset: 'UTC' },
  { name: 'Europe/London', offset: 'UTC' },
  { name: 'Africa/Monrovia', offset: 'UTC' },
  { name: 'Europe/Amsterdam', offset: 'UTC+01:00' },
  { name: 'Europe/Belgrade', offset: 'UTC+01:00' },
  { name: 'Europe/Brussels', offset: 'UTC+01:00' },
  { name: 'Europe/Warsaw', offset: 'UTC+01:00' },
  { name: 'Africa/Algiers', offset: 'UTC+01:00' },
  { name: 'Africa/Windhoek', offset: 'UTC+01:00' },
  { name: 'Asia/Amman', offset: 'UTC+02:00' },
  { name: 'Europe/Athens', offset: 'UTC+02:00' },
  { name: 'Asia/Beirut', offset: 'UTC+02:00' },
  { name: 'Africa/Cairo', offset: 'UTC+02:00' },
  { name: 'Asia/Damascus', offset: 'UTC+02:00' },
  { name: 'Asia/Gaza', offset: 'UTC+02:00' },
  { name: 'Africa/Harare', offset: 'UTC+02:00' },
  { name: 'Europe/Helsinki', offset: 'UTC+02:00' },
  { name: 'Asia/Jerusalem', offset: 'UTC+02:00' },
  { name: 'Europe/Kaliningrad', offset: 'UTC+02:00' },
  { name: 'Africa/Tripoli', offset: 'UTC+02:00' },
  { name: 'Asia/Baghdad', offset: 'UTC+03:00' },
  { name: 'Asia/Istanbul', offset: 'UTC+03:00' },
  { name: 'Asia/Kuwait', offset: 'UTC+03:00' },
  { name: 'Europe/Minsk', offset: 'UTC+03:00' },
  { name: 'Europe/Moscow', offset: 'UTC+03:00' },
  { name: 'Africa/Nairobi', offset: 'UTC+03:00' },
  { name: 'Asia/Tehran', offset: 'UTC+03:30' },
  { name: 'Asia/Muscat', offset: 'UTC+04:00' },
  { name: 'Europe/Astrakhan', offset: 'UTC+04:00' },
  { name: 'Asia/Baku', offset: 'UTC+04:00' },
  { name: 'Europe/Samara', offset: 'UTC+04:00' },
  { name: 'Indian/Mauritius', offset: 'UTC+04:00' },
  { name: 'Asia/Tbilisi', offset: 'UTC+04:00' },
  { name: 'Asia/Yerevan', offset: 'UTC+04:00' },
  { name: 'Asia/Kabul', offset: 'UTC+04:30' },
  { name: 'Asia/Tashkent', offset: 'UTC+05:00' },
  { name: 'Asia/Yekaterinburg', offset: 'UTC+05:00' },
  { name: 'Asia/Karachi', offset: 'UTC+05:00' },
  { name: 'Asia/Kolkata', offset: 'UTC+05:30' },
  { name: 'Asia/Colombo', offset: 'UTC+05:30' },
  { name: 'Asia/Katmandu', offset: 'UTC+05:45' },
  { name: 'Asia/Almaty', offset: 'UTC+06:00' },
  { name: 'Asia/Dhaka', offset: 'UTC+06:00' },
  { name: 'Asia/Rangoon', offset: 'UTC+06:30' },
  { name: 'Asia/Novosibirsk', offset: 'UTC+07:00' },
  { name: 'Asia/Bangkok', offset: 'UTC+07:00' },
  { name: 'Asia/Barnaul', offset: 'UTC+07:00' },
  { name: 'Asia/Hovd', offset: 'UTC+07:00' },
  { name: 'Asia/Krasnoyarsk', offset: 'UTC+07:00' },
  { name: 'Asia/Tomsk', offset: 'UTC+07:00' },
  { name: 'Asia/Chongqing', offset: 'UTC+08:00' },
  { name: 'Asia/Irkutsk', offset: 'UTC+08:00' },
  { name: 'Asia/Kuala_Lumpur', offset: 'UTC+08:00' },
  { name: 'Australia/Perth', offset: 'UTC+08:00' },
  { name: 'Asia/Taipei', offset: 'UTC+08:00' },
  { name: 'Asia/Ulaanbaatar', offset: 'UTC+08:00' },
  { name: 'Asia/Pyongyang', offset: 'UTC+08:30' },
  { name: 'Australia/Eucla', offset: 'UTC+08:45' },
  { name: 'Asia/Chita', offset: 'UTC+09:00' },
  { name: 'Asia/Tokyo', offset: 'UTC+09:00' },
  { name: 'Asia/Seoul', offset: 'UTC+09:00' },
  { name: 'Asia/Yakutsk', offset: 'UTC+09:00' },
  { name: 'Australia/Adelaide', offset: 'UTC+09:30' },
  { name: 'Australia/Darwin', offset: 'UTC+09:30' },
  { name: 'Australia/Brisbane', offset: 'UTC+10:00' },
  { name: 'Australia/Canberra', offset: 'UTC+10:00' },
  { name: 'Pacific/Guam', offset: 'UTC+10:00' },
  { name: 'Australia/Hobart', offset: 'UTC+10:00' },
  { name: 'Asia/Vladivostok', offset: 'UTC+10:00' },
  { name: 'Australia/Lord_Howe', offset: 'UTC+10:30' },
  { name: 'Pacific/Bougainville', offset: 'UTC+11:00' },
  { name: 'Asia/Srednekolymsk', offset: 'UTC+11:00' },
  { name: 'Asia/Magadan', offset: 'UTC+11:00' },
  { name: 'Pacific/Norfolk', offset: 'UTC+11:00' },
  { name: 'Asia/Sakhalin', offset: 'UTC+11:00' },
  { name: 'Pacific/Guadalcanal', offset: 'UTC+11:00' },
  { name: 'Asia/Anadyr', offset: 'UTC+12:00' },
  { name: 'Pacific/Auckland', offset: 'UTC+12:00' },
  { name: 'Pacific/Fiji', offset: 'UTC+12:00' },
  { name: 'Pacific/Chatham', offset: 'UTC+12:45' },
  { name: 'Pacific/Tongatapu', offset: 'UTC+13:00' },
  { name: 'Pacific/Apia', offset: 'UTC+13:00' },
  { name: 'Pacific/Kiritimati', offset: 'UTC+14:00' },
]

export const languages = [
  { name: 'Afrikaans (South Africa)', code: 'af-ZA' },
  { name: 'Albanian (Albania)', code: 'sq-AL' },
  { name: 'Amharic (Ethiopia)', code: 'am-ET' },
  { name: 'Arabic (Algeria)', code: 'ar-DZ' },
  { name: 'Arabic (Bahrain)', code: 'ar-BH' },
  { name: 'Arabic (Egypt)', code: 'ar-EG' },
  { name: 'Arabic (Iraq)', code: 'ar-IQ' },
  { name: 'Arabic (Israel)', code: 'ar-IL' },
  { name: 'Arabic (Jordan)', code: 'ar-JO' },
  { name: 'Arabic (Kuwait)', code: 'ar-KW' },
  { name: 'Arabic (Lebanon)', code: 'ar-LB' },
  { name: 'Arabic (Morocco)', code: 'ar-MA' },
  { name: 'Arabic (Oman)', code: 'ar-OM' },
  { name: 'Arabic (Qatar)', code: 'ar-QA' },
  { name: 'Arabic (Saudi Arabia)', code: 'ar-SA' },
  { name: 'Arabic (State of Palestine)', code: 'ar-PS' },
  { name: 'Arabic (Tunisia)', code: 'ar-TN' },
  { name: 'Arabic (United Arab Emirates)', code: 'ar-AE' },
  { name: 'Arabic (Yemen)', code: 'ar-YE' },
  { name: 'Armenian (Armenia)', code: 'hy-AM' },
  { name: 'Azerbaijani (Azerbaijan)', code: 'az-AZ' },
  { name: 'Basque (Spain)', code: 'eu-ES' },
  { name: 'Bengali (Bangladesh)', code: 'bn-BD' },
  { name: 'Bengali (India)', code: 'bn-IN' },
  { name: 'Bosnian (Bosnia and Herzegovina)', code: 'bs-BA' },
  { name: 'Bulgarian (Bulgaria)', code: 'bg-BG' },
  { name: 'Burmese (Myanmar)', code: 'my-MM' },
  { name: 'Catalan (Spain)', code: 'ca-ES' },
  { name: 'Chinese, Cantonese (Traditional Hong Kong)', code: 'yue-Hant-HK' },
  { name: 'Chinese, Mandarin (Simplified, China)', code: 'zh (cmn-Hans-CN)' },
  { name: 'Chinese, Mandarin (Traditional, Taiwan)', code: 'zh-TW (cmn-Hant-TW)' },
  { name: 'Croatian (Croatia)', code: 'hr-HR' },
  { name: 'Czech (Czech Republic)', code: 'cs-CZ' },
  { name: 'Danish (Denmark)', code: 'da-DK' },
  { name: 'Dutch (Belgium)', code: 'nl-BE' },
  { name: 'Dutch (Netherlands)', code: 'nl-NL' },
  { name: 'English (Australia)', code: 'en-AU' },
  { name: 'English (Canada)', code: 'en-CA' },
  { name: 'English (Ghana)', code: 'en-GH' },
  { name: 'English (Hong Kong)', code: 'en-HK' },
  { name: 'English (India)', code: 'en-IN' },
  { name: 'English (Ireland)', code: 'en-IE' },
  { name: 'English (Kenya)', code: 'en-KE' },
  { name: 'English (New Zealand)', code: 'en-NZ' },
  { name: 'English (Nigeria)', code: 'en-NG' },
  { name: 'English (Pakistan)', code: 'en-PK' },
  { name: 'English (Philippines)', code: 'en-PH' },
  { name: 'English (Singapore)', code: 'en-SG' },
  { name: 'English (South Africa)', code: 'en-ZA' },
  { name: 'English (Tanzania)', code: 'en-TZ' },
  { name: 'English (United Kingdom)', code: 'en-GB' },
  { name: 'English (United States)', code: 'en-US' },
  { name: 'Estonian (Estonia)', code: 'et-EE' },
  { name: 'Filipino (Philippines)', code: 'fil-PH' },
  { name: 'Finnish (Finland)', code: 'fi-FI' },
  { name: 'French (Belgium)', code: 'fr-BE' },
  { name: 'French (Canada)', code: 'fr-CA' },
  { name: 'French (France)', code: 'fr-FR' },
  { name: 'French (Switzerland)', code: 'fr-CH' },
  { name: 'Galician (Spain)', code: 'gl-ES' },
  { name: 'Georgian (Georgia)', code: 'ka-GE' },
  { name: 'German (Austria)', code: 'de-AT' },
  { name: 'German (Germany)', code: 'de-DE' },
  { name: 'German (Switzerland)', code: 'de-CH' },
  { name: 'Greek (Greece)', code: 'el-GR' },
  { name: 'Gujarati (India)', code: 'gu-IN' },
  { name: 'Hebrew (Israel)', code: 'iw-IL' },
  { name: 'Hindi (India)', code: 'hi-IN' },
  { name: 'Hungarian (Hungary)', code: 'hu-HU' },
  { name: 'Icelandic (Iceland)', code: 'is-IS' },
  { name: 'Indonesian (Indonesia)', code: 'id-ID' },
  { name: 'Italian (Italy)', code: 'it-IT' },
  { name: 'Italian (Switzerland)', code: 'it-CH' },
  { name: 'Japanese (Japan)', code: 'ja-JP' },
  { name: 'Javanese (Indonesia)', code: 'jv-ID' },
  { name: 'Kannada (India)', code: 'kn-IN' },
  { name: 'Kazakh (Kazakhstan)', code: 'kk-KZ' },
  { name: 'Khmer (Cambodia)', code: 'km-KH' },
  { name: 'Korean (South Korea)', code: 'ko-KR' },
  { name: 'Lao (Laos)', code: 'lo-LA' },
  { name: 'Latvian (Latvia)', code: 'lv-LV' },
  { name: 'Lithuanian (Lithuania)', code: 'lt-LT' },
  { name: 'Macedonian (North Macedonia)', code: 'mk-MK' },
  { name: 'Malay (Malaysia)', code: 'ms-MY' },
  { name: 'Malayalam (India)', code: 'ml-IN' },
  { name: 'Marathi (India)', code: 'mr-IN' },
  { name: 'Mongolian (Mongolia)', code: 'mn-MN' },
  { name: 'Nepali (Nepal)', code: 'ne-NP' },
  { name: 'Norwegian Bokmål (Norway)', code: 'no-NO' },
  { name: 'Persian (Iran)', code: 'fa-IR' },
  { name: 'Polish (Poland)', code: 'pl-PL' },
  { name: 'Portuguese (Brazil)', code: 'pt-BR' },
  { name: 'Portuguese (Portugal)', code: 'pt-PT' },
  { name: 'Punjabi (Gurmukhi India)', code: 'pa-Guru-IN' },
  { name: 'Romanian (Romania)', code: 'ro-RO' },
  { name: 'Russian (Russia)', code: 'ru-RU' },
  { name: 'Serbian (Serbia)', code: 'sr-RS' },
  { name: 'Sinhala (Sri Lanka)', code: 'si-LK' },
  { name: 'Slovak (Slovakia)', code: 'sk-SK' },
  { name: 'Slovenian (Slovenia)', code: 'sl-SI' },
  { name: 'Spanish (Argentina)', code: 'es-AR' },
  { name: 'Spanish (Bolivia)', code: 'es-BO' },
  { name: 'Spanish (Chile)', code: 'es-CL' },
  { name: 'Spanish (Colombia)', code: 'es-CO' },
  { name: 'Spanish (Costa Rica)', code: 'es-CR' },
  { name: 'Spanish (Dominican Republic)', code: 'es-DO' },
  { name: 'Spanish (Ecuador)', code: 'es-EC' },
  { name: 'Spanish (El Salvador)', code: 'es-SV' },
  { name: 'Spanish (Guatemala)', code: 'es-GT' },
  { name: 'Spanish (Honduras)', code: 'es-HN' },
  { name: 'Spanish (Mexico)', code: 'es-MX' },
  { name: 'Spanish (Nicaragua)', code: 'es-NI' },
  { name: 'Spanish (Panama)', code: 'es-PA' },
  { name: 'Spanish (Paraguay)', code: 'es-PY' },
  { name: 'Spanish (Peru)', code: 'es-PE' },
  { name: 'Spanish (Puerto Rico)', code: 'es-PR' },
  { name: 'Spanish (Spain)', code: 'es-ES' },
  { name: 'Spanish (United States)', code: 'es-US' },
  { name: 'Spanish (Uruguay)', code: 'es-UY' },
  { name: 'Spanish (Venezuela)', code: 'es-VE' },
  { name: 'Sundanese (Indonesia)', code: 'su-ID' },
  { name: 'Swahili (Kenya)', code: 'sw-KE' },
  { name: 'Swahili (Tanzania)', code: 'sw-TZ' },
  { name: 'Swedish (Sweden)', code: 'sv-SE' },
  { name: 'Tamil (India)', code: 'ta-IN' },
  { name: 'Tamil (Malaysia)', code: 'ta-MY' },
  { name: 'Tamil (Singapore)', code: 'ta-SG' },
  { name: 'Tamil (Sri Lanka)', code: 'ta-LK' },
  { name: 'Telugu (India)', code: 'te-IN' },
  { name: 'Thai (Thailand)', code: 'th-TH' },
  { name: 'Turkish (Turkey)', code: 'tr-TR' },
  { name: 'Ukrainian (Ukraine)', code: 'uk-UA' },
  { name: 'Urdu (India)', code: 'ur-IN' },
  { name: 'Urdu (Pakistan)', code: 'ur-PK' },
  { name: 'Uzbek (Uzbekistan)', code: 'uz-UZ' },
  { name: 'Vietnamese (Vietnam)', code: 'vi-VN' },
  { name: 'Zulu (South Africa)', code: 'zu-ZA' },
]

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const timeOptions = [
  { value: '0000', label: '12:00 AM' },
  { value: '0030', label: '12:30 AM' },
  { value: '0100', label: '01:00 AM' },
  { value: '0130', label: '01:30 AM' },
  { value: '0200', label: '02:00 AM' },
  { value: '0230', label: '02:30 AM' },
  { value: '0300', label: '03:00 AM' },
  { value: '0330', label: '03:30 AM' },
  { value: '0400', label: '04:00 AM' },
  { value: '0430', label: '04:30 AM' },
  { value: '0500', label: '05:00 AM' },
  { value: '0530', label: '05:30 AM' },
  { value: '0600', label: '06:00 AM' },
  { value: '0630', label: '06:30 AM' },
  { value: '0700', label: '07:00 AM' },
  { value: '0730', label: '07:30 AM' },
  { value: '0800', label: '08:00 AM' },
  { value: '0830', label: '08:30 AM' },
  { value: '0900', label: '09:00 AM' },
  { value: '0930', label: '09:30 AM' },
  { value: '1000', label: '10:00 AM' },
  { value: '1030', label: '10:30 AM' },
  { value: '1100', label: '11:00 AM' },
  { value: '1130', label: '11:30 AM' },
  { value: '1200', label: '12:00 PM' },
  { value: '1230', label: '12:30 PM' },
  { value: '1300', label: '01:00 PM' },
  { value: '1330', label: '01:30 PM' },
  { value: '1400', label: '02:00 PM' },
  { value: '1430', label: '02:30 PM' },
  { value: '1500', label: '03:00 PM' },
  { value: '1530', label: '03:30 PM' },
  { value: '1600', label: '04:00 PM' },
  { value: '1630', label: '04:30 PM' },
  { value: '1700', label: '05:00 PM' },
  { value: '1730', label: '05:30 PM' },
  { value: '1800', label: '06:00 PM' },
  { value: '1830', label: '06:30 PM' },
  { value: '1900', label: '07:00 PM' },
  { value: '1930', label: '07:30 PM' },
  { value: '2000', label: '08:00 PM' },
  { value: '2030', label: '08:30 PM' },
  { value: '2100', label: '09:00 PM' },
  { value: '2130', label: '09:30 PM' },
  { value: '2200', label: '10:00 PM' },
  { value: '2230', label: '10:30 PM' },
  { value: '2300', label: '11:00 PM' },
  { value: '2330', label: '11:30 PM' },
]

export const defaultAvailabilityScheduleRange = { start: '0800', end: '2000' }

export const defaultAvailability = {
  enabled: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  schedule: [
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
    defaultAvailabilityScheduleRange,
  ],
}
