// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import { getRandomEmoji } from '../../lib/emoji'
import { GroupModel } from '../../service/model'
import { DomainModel } from '../../service/model/DomainModel'
import { WebhookModel } from '../../service/model/WebhookModel'
import AppStore from '../store'
import { v4 as uuid } from 'uuid'
import { FetchWebhookEventsRequest, WebhookEventsResultObject } from '@src/service'
import { logError } from '@src/lib/log'
import { PromiseStateStatus } from '@src/lib/useStatefulPromise'

class WorkspaceUiStore {
  domains: DomainModel[] = []
  app: AppStore
  private _draftGroup: GroupModel | null = null

  webhooksNetworkStatusState: PromiseStateStatus = PromiseStateStatus.Idle

  constructor(app: AppStore) {
    this.app = app
    makeAutoObservable(this, {})
    this.subscribeToWebhookEvents()
  }

  get draftGroup(): GroupModel {
    if (!this._draftGroup) {
      this._draftGroup = new GroupModel(this.app.service, {
        id: null,
        name: '',
        type: '1',
        symbol: getRandomEmoji().char,
        description: '',
        members: [],
      })
    }

    return this._draftGroup
  }

  get groups() {
    return this.app.service.workspace.groups.list.filter((group) => !group.deletedAt)
  }

  set draftGroup(value: GroupModel | null) {
    this._draftGroup = value
  }

  get webhooks() {
    return this.app.service.workspace.webhooks.list
  }

  addDomain(verificationEmail: string) {
    return this.app.service.workspace.addDomain(verificationEmail)
  }

  deleteDomain(domainId: string) {
    return this.app.service.workspace.deleteDomain(domainId)
  }

  fetchDomains() {
    return this.app.service.workspace.fetchDomains().then((response) => {
      this.domains = response.results
    })
  }

  resendCode(domainId: string) {
    return this.app.service.workspace.resendCode(domainId)
  }

  verifyDomain(email: string, code: string) {
    return this.app.service.workspace.verifyDomain(email, code)
  }

  saveDraft() {
    if (this._draftGroup) {
      this._draftGroup.save()
      this.app.service.workspace.groups.put(this._draftGroup)
      this._draftGroup = null
    }
  }

  createWebhook(data: Partial<WebhookModel>) {
    const webhook = new WebhookModel(this.app.service, {
      id: `WH${uuid()}`.replace(/-/g, ''),
      ...data,
      createdAt: new Date().toString(),
    })
    this.app.service.workspace.webhooks.put(webhook)
    return this.app.service.workspace.createWebhook(webhook.serialize())
  }

  fetchWebhooks() {
    this.webhooksNetworkStatusState = PromiseStateStatus.Loading
    return this.app.service.workspace
      .fetchWebhooks()
      .then(() => {
        this.webhooksNetworkStatusState = PromiseStateStatus.Success
      })
      .catch((error) => {
        this.webhooksNetworkStatusState = PromiseStateStatus.Failed
        logError(error)
      })
  }

  async sendTestRequest(id: string, data: Record<string, any>, requestName: string) {
    try {
      const response = await this.app.service.workspace.sendTestRequest(id, data)

      if ('message' in response) {
        throw new Error(response.message)
      }

      const statusCode = Number.isNaN(parseInt(response.statusCode, 10))
        ? response.statusCode
        : parseInt(response.statusCode, 10)

      this.app.command.present({
        name: 'test request details',
        request: {
          name: requestName,
          statusCode,
          date: response.headers.date,
          payload: JSON.stringify(data, null, 2),
          response: JSON.stringify(response.body, null, 2),
          webhookId: id,
        },
        isTestRequest: true,
      })
    } catch (e) {
      this.app.toast.showError(e)
    }
  }

  resendEvent(
    webhookId: string,
    eventId: string,
  ): Promise<WebhookEventsResultObject | null> {
    return this.app.service.workspace
      .resendEvent(webhookId, eventId)
      .then((response) => {
        this.app.toast.show({ message: 'The event has been resent successfully!' })
        return response
      })
      .catch((error) => {
        this.app.toast.showError(error)
        return null
      })
  }

  fetchWebhookEvents(params: FetchWebhookEventsRequest) {
    return this.app.service.workspace.fetchWebhookEvents(params)
  }

  private subscribeToWebhookEvents() {
    this.app.service.transport.onNotificationData.subscribe((message) => {
      switch (message.type) {
        case 'domain-update':
          return this.handleUpdateDomain(message.domain)
        case 'domain-delete':
          return this.handleDeleteDomain(message.domainId)
      }
    })
  }

  private handleUpdateDomain(domain: DomainModel) {
    const indexOfCurrentItem = this.domains.findIndex((d) => d.id === domain.id)
    if (indexOfCurrentItem > -1) {
      this.domains[indexOfCurrentItem] = domain
    } else {
      this.domains.push(domain)
      if (this.app.service.user.current.settings.web.onboardingCompleted) {
        this.app.command.present({
          name: 'verify domain',
          email: domain.verificationEmail,
        })
      }
    }
  }

  private handleDeleteDomain(id: string) {
    this.domains = this.domains.filter((domain) => domain.id !== id)
  }
}

export default WorkspaceUiStore
