import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { useAppStore } from '@src/app/context'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import Header from './header'
import List from './list'

interface ConversationsProps {
  compressed: boolean
}

const ConversationsProvider: React.FC<ConversationsProps> = function ({ compressed }) {
  const styles = useStyles({ compressed })
  const { inboxes, command } = useAppStore()

  useKeyboardShortcuts({
    name: 'inbox/phone-number',
    node: document,
    handler: (shortcut) => {
      if (shortcut === 'KeyN') {
        inboxes.selected.newConversation()
      } else if (shortcut === 'KeyC') {
        setTimeout(() => command.present({ name: 'dialer' }), 0)
      }
    },
  })

  return (
    inboxes.selected && (
      <div className={styles.root}>
        <Header compressed={compressed} />
        <List />
      </div>
    )
  )
}

export default observer(ConversationsProvider)

const useStyles = makeStyles<Theme, Partial<ConversationsProps>>((theme) => ({
  root: ({ compressed }) => ({
    flex: `0 1 ${compressed ? 320 : 376}px`,
    zIndex: 1,
    background: theme.palette.op.background.primary,
    borderRight: `1.5px solid ${theme.palette.op.border.common}`,
    minWidth: 0,
    boxSizing: 'border-box',
    height: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }),
}))
