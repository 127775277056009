// @ts-strict-ignore
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from '../config'
import { User } from '../service/model'

export function start(appType: 'client' | 'web_worker' | 'service_worker') {
  Bugsnag.start({
    apiKey: 'edb2b3aa80e491e8e8b8a2fbf9c6b021',
    plugins: [new BugsnagPluginReact()],
    appType,
    appVersion: config.FULL_VERSION,
    releaseStage: config.ENV,
  })
}

export function setUser(user: User) {
  if (!!config.IS_DEV) return
  Bugsnag.setUser(user.id, user.email, user.asMember.name)
}

export function createErrorBoundary(react: any) {
  return Bugsnag.getPlugin('react').createErrorBoundary(react)
}
