import { Subject } from 'rxjs'
import { v4 as uuid } from 'uuid'
import { WorkerRequest, WorkerResponse } from '.'
import log from '../../lib/log'
import Repository from './repository'
import BaseRepository from './repository/base'
import Service, { BaseService } from './service'
import { ServiceEvent } from './service'

/**
 * Abstracts away accessing the worker over a message bus. Makes the worker
 * feel as if it was on the same thread.
 *
 * e.g. use like worker.service.search.identities(x, y, z)
 */
type PendingRequest = { resolve: (value: any) => void; reject: (reason: any) => void }

export default class MainWorker {
  service: Omit<Service, 'event'>
  repo: Repository

  private worker: Worker
  private event$ = new Subject<ServiceEvent>()
  private serviceCache = new Map<string, BaseService>()
  private repoCache = new Map<string, BaseRepository>()
  private pendingRequests = new Map<string, PendingRequest>()

  constructor(workerName: string) {
    this.worker = new Worker(workerName)
    this.worker.addEventListener('message', this.onWorkerMessage.bind(this))
    this.worker.addEventListener('error', this.onWorkerError.bind(this))

    this.service = new Proxy({} as Service, {
      get: (_, name) => this.getService(name as string),
    })

    this.repo = new Proxy({} as Repository, {
      get: (_, name) => this.getRepository(name as string),
    })
  }

  get onEvent() {
    return this.event$.asObservable()
  }

  private onWorkerMessage(event: MessageEvent<WorkerResponse>) {
    switch (event.data.type) {
      case 'response':
        return this.handleResponse(event.data)
      case 'event':
        return this.handleEvent(event.data)
      default:
        log.error('Unrecognized worker message', event.data)
    }
  }

  private handleResponse(data: Extract<WorkerResponse, { type: 'response' }>) {
    const request = this.pendingRequests.get(data.requestId)
    if (request) {
      if ('error' in data) {
        request.reject(data.error)
      } else {
        request.resolve(data.result)
      }
      this.pendingRequests.delete(data.requestId)
    }
  }

  private handleEvent(event: Extract<WorkerResponse, { type: 'event' }>) {
    this.event$.next(event.data)
  }

  private onWorkerError(event: ErrorEvent) {}

  private getService(name: string) {
    if (this.serviceCache.has(name)) {
      return this.serviceCache.get(name)
    }
    const service = new Proxy(
      {},
      {
        get: (target, method, receiver) => {
          return (...query) => {
            const requestId = uuid()
            const promise = new Promise<any>((resolve, reject) => {
              this.pendingRequests.set(requestId, { resolve, reject })
            })
            try {
              this.worker.postMessage({
                type: 'service',
                requestId,
                name,
                method,
                query,
              } as WorkerRequest)
            } catch (e) {
              log.error('Failed to post message to worker', {
                error: e,
                name,
                method,
              })
            }
            return promise
          }
        },
      },
    )
    this.serviceCache.set(name, service)
    return service
  }

  private getRepository(collection: string) {
    if (this.repoCache.has(collection)) {
      return this.repoCache.get(collection)
    }
    const repo = new Proxy({} as BaseRepository, {
      get: (target, method, receiver) => {
        return (...query) => {
          const requestId = uuid()
          const promise = new Promise<any>((resolve, reject) => {
            this.pendingRequests.set(requestId, { resolve, reject })
          })
          try {
            this.worker.postMessage({
              type: 'repository',
              requestId,
              collection,
              method,
              query,
            })
          } catch (e) {
            log.error('Failed to post message to worker', {
              error: e,
              collection,
              method,
            })
          }
          return promise
        }
      },
    })
    this.repoCache.set(collection, repo)
    return repo
  }
}
