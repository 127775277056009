import { type MutableRefObject, useCallback, useEffect, useRef } from 'react'

/**
 * Compensates intrinsic height changes of an element due to
 * children being added or removed
 */
export function useInitialMinHeight({ enabled }: { enabled: boolean }): {
  ref: MutableRefObject<HTMLElement | null>
  recalculate: () => void
} {
  const elementRef = useRef<HTMLElement | null>(null)

  const setInitialMinHeight = useCallback(() => {
    if (!elementRef.current) return
    const initialHeight = elementRef.current.offsetHeight
    elementRef.current.style.minHeight = `${initialHeight}px`
  }, [])

  useEffect(() => {
    if (!enabled) return
    setInitialMinHeight()
  }, [enabled, setInitialMinHeight])

  return { ref: elementRef, recalculate: setInitialMinHeight }
}
