// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { forwardRef, useLayoutEffect, useRef } from 'react'
import Checkbox from '../../../component/checkbox-v2'
import Typography from '@ui/Typography'
import useCombinedRefs from '../../../lib/use-combined-ref'

interface IconItemProps extends React.HTMLProps<HTMLDivElement> {
  icon: React.ReactNode
  selected: boolean
  rootClassName?: string
}

export const IconItem: React.FC<IconItemProps> = function ({
  icon,
  children,
  className,
  selected,
  rootClassName,
  ...props
}) {
  const styles = useStyles({})
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }, [selected])

  return (
    <div
      ref={ref}
      {...props}
      className={cx(styles.root, selected && styles.selected, rootClassName)}
    >
      <Typography className={styles.icon} variant="body" color="textSecondary">
        {icon}
      </Typography>
      <Typography
        className={cx(styles.content, className)}
        variant="body"
        color="textPrimary"
        nowrap
      >
        {children}
      </Typography>
    </div>
  )
}

interface SelectItemProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onSelect'> {
  selected: boolean
  checked: boolean
  onChecked?: (checked: boolean) => void
}

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(function (
  { selected, checked, children, className, onChecked, ...props }: SelectItemProps,
  outerRef,
) {
  const styles = useStyles({})
  const ref = useCombinedRefs<HTMLDivElement>(outerRef)

  useLayoutEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }, [selected])

  const handleChecked = (event, checked) => {
    onChecked?.(checked)
    event.stopPropagation()
    event.target.blur()
  }

  return (
    <div
      ref={ref}
      {...props}
      className={cx(styles.root, selected && styles.selected, className)}
    >
      <Typography className={styles.icon} variant="body" color="textSecondary">
        <Checkbox checked={checked} onChange={handleChecked} />
      </Typography>
      <Typography className={styles.content} variant="body" color="textPrimary">
        {children}
      </Typography>
    </div>
  )
})

interface HeaderItemProps extends React.HTMLProps<HTMLDivElement> {}

export const HeaderItem: React.FC<HeaderItemProps> = function ({
  className,
  children,
  ...props
}) {
  const styles = useStyles({})

  return (
    <div {...props} className={cx(styles.header, className)}>
      <Typography variant="caption1" color="textTertiary" fontWeight="regular">
        {children}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 44,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    margin: '0 10px 2px',
    padding: '0 14px',
  },
  selected: {
    background: theme.palette.op.hover.primary,
  },
  content: {
    flex: 1,
    marginLeft: 14,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    padding: '16px 24px 10px',
  },
}))
