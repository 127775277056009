// @ts-strict-ignore
import { useState } from 'react'
import { formatPartial } from './phone-number'

type Setter = (event: React.FormEvent | string) => void

export default function useInputState(
  initialValue: string,
  formatter: (s: string) => string = null,
): [string, Setter] {
  const [state, setState] = useState(initialValue || '')

  const onChange = (event: React.FormEvent | string) => {
    if (event === undefined) return
    let value: string = (() => {
      if (typeof event === 'string') return event
      else return (event?.target as HTMLInputElement).value
    })()
    if (formatter) {
      value = formatter(value)
    }
    setState(value)
  }

  return [state, onChange]
}

const phoneNumberCharacters = /^[\d\s\(\)\+\-]+$/

export function usePhoneNumberInputState(initialValue: string): [string, Setter] {
  const [input, setInput] = useInputState(initialValue, (value) => {
    if (input.endsWith(')') && value.length === input.length - 1) {
      value = value.slice(0, -1)
    }

    if (!phoneNumberCharacters.test(value)) {
      return value
    }

    return formatPartial(value) || value
  })
  return [input, setInput]
}

export function useNumberInputState(initialValue: string): [string, Setter] {
  return useInputState(initialValue, (s) => (parseInt(s) ? `${parseInt(s)}` : ''))
}
