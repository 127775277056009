// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { Contact, ContactItem } from '../../service/model'
import { ContactTemplateItem } from '../../service/model/contact-template-item'
import { ContactItemType } from '../../types'
import AddNewItem from './add-new'
import CustomItem from './item/custom-item'
import DefaultItem from './item/default-item'
import VerticalSortable, { VerticalSortableItem } from '@ui/VerticalSortable'

interface ContactItemsProps {
  contact: Contact
}

const ContactItems: React.FC<ContactItemsProps> = function ({ contact }) {
  const styles = useStyles({})
  const { service, toast } = useAppStore()
  const [editingItemId, setEditingItemId] = useState<string>(null)

  const phoneNumbers = contact.items
    .filter((i) => i.type === 'phone-number')
    .sort((a, b) => a.createdAt - b.createdAt)

  const emails = contact.items
    .filter((i) => i.type === 'email')
    .sort((a, b) => a.createdAt - b.createdAt)

  const templateItems = service.contact.template.list
    .sort((i1, i2) => {
      if (i1.order == null) return 1
      if (i2.order == null) return -1
      return i1.order - i2.order
    })
    .filter((t) => t.isSupportedType)

  useEffect(() => {
    if (emails.length === 0) {
      contact.addItem({ type: 'email', isNew: true })
    }
  }, [contact.id, emails.length])

  const handleReorder = (oldIndex: number, newIndex: number) => {
    service.contact.reorderTemplates(oldIndex, newIndex)
  }

  const handleAddNew = action((type: ContactItemType) => {
    let item: ContactItem
    if (type === 'email' || type === 'phone-number') {
      item = contact.addItem({ type, value: null, isNew: true })
    } else {
      const lastOrder = templateItems[templateItems.length - 1]?.order ?? -1
      const template = new ContactTemplateItem(service, {
        type: type as any,
        order: lastOrder + 1,
        local: true,
        options: type === 'multi-select' ? [] : {},
      })
      service.contact.updateTemplate(template).catch(toast.showError)
      item = contact.getOrCreateItemForTemplate(template)
    }
    setEditingItemId(item.id)
  })

  const renderTemplateItem = (template: ContactTemplateItem) => {
    const item = contact.getOrCreateItemForTemplate(template)
    return (
      <CustomItem
        key={item.id}
        editingId={editingItemId}
        contact={contact}
        contactItem={item}
        defaultName={defaultNames[template.type]}
        placeholder={defaultPlaceholders[template.type]}
      />
    )
  }

  return (
    <div className={styles.root}>
      <DefaultItem contact={contact} item="company" />
      <DefaultItem contact={contact} item="role" />
      {phoneNumbers.map((item) => (
        <CustomItem
          key={item.id}
          contact={contact}
          contactItem={item}
          editingId={editingItemId}
          defaultName="Phone"
          placeholder="Set a number..."
        />
      ))}
      {emails.map((item) => (
        <CustomItem
          key={item.id}
          contact={contact}
          contactItem={item}
          editingId={editingItemId}
          defaultName="Email"
          placeholder="Set an email..."
        />
      ))}
      {templateItems.length > 0 && (
        <VerticalSortable items={templateItems} onReorder={handleReorder}>
          {templateItems.map((item) => (
            <VerticalSortableItem key={item.id} id={item.id} className={styles.draggable}>
              {renderTemplateItem(item)}
            </VerticalSortableItem>
          ))}
        </VerticalSortable>
      )}
      <AddNewItem onSelect={handleAddNew} />
    </div>
  )
}

export default observer(ContactItems)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 14px 0',
  },
  draggable: {
    /**
     * The component hides overflow by default which hides the drag indicators
     */
    overflow: 'visible !important',
  },
}))

const defaultNames: { [key in ContactItemType]: string } = {
  address: 'Address',
  'multi-select': 'Tags',
  boolean: 'Checkbox',
  company: 'Company',
  'phone-number': 'Phone',
  date: 'Date',
  email: 'Email',
  number: 'Number',
  role: 'Role',
  string: 'Text',
  url: 'URL',
}

const defaultPlaceholders: { [key in ContactItemType]: string } = {
  address: 'Set an address...',
  'multi-select': 'Set tags...',
  boolean: null,
  company: 'Set company...',
  'phone-number': 'Set a number...',
  date: 'Set a date...',
  email: 'Set an email...',
  number: 'Set a number...',
  role: 'Set a role',
  string: 'Set a text...',
  url: 'Set a URL...',
}
