import React from 'react'
import {
  Identity,
  isMember,
  isParticipant,
  Presence,
  thumbnailUrl,
  isIdentity,
} from '@src/service/model'
import { observer } from 'mobx-react-lite'
import Avatar, { AvatarSize, AvatarProps } from '@ui/Avatar'

const integrationBadges = {
  hubspot: require('./assets/hubspot.svg').default,
}

const badges = {
  'on-hold': require('./assets/on-hold.svg').default,
  muted: require('./assets/muted.svg').default,
}

interface AppAvatarProps {
  className?: string
  size?: AvatarSize
  identity?: Identity | Pick<Identity, 'pictureUrl'>

  /**
   * Override the badge of an avatar.
   *
   * If provided, this will override any badges an avatar may have, such as the
   * hubspot icon.
   */
  badge?: keyof typeof badges
  hideStatus?: boolean
  hideBadge?: boolean
}

const AppAvatar = observer(
  (
    {
      size,
      identity,
      badge: badgeProp,
      hideStatus,
      hideBadge,
      className,
    }: AppAvatarProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const pictureUrl = identity?.pictureUrl
    let url: AvatarProps['url'] =
      pictureUrl &&
      thumbnailUrl(pictureUrl, {
        width: size,
        quality: 100,
      })
    let status: AvatarProps['status']
    let badge: AvatarProps['badge']
    let label: AvatarProps['label']
    let gradientId: AvatarProps['gradientId']
    let initials: AvatarProps['initials']
    let presence: Presence | null = null

    if (isParticipant(identity)) {
      presence = identity.member?.presence
    } else if (isMember(identity)) {
      presence = identity.presence
    }

    if (presence) {
      if (presence.onCall) {
        status = 'busy'
      } else if (presence.status === 'online') {
        status = presence.snoozed ? 'activeSnoozed' : 'active'
      } else {
        status = presence.snoozed ? 'inactiveSnoozed' : 'inactive'
      }
    }

    if (isIdentity(identity)) {
      initials = identity.initials
      gradientId = identity.id
      label = `${identity.name}'s avatar`

      switch (identity.source) {
        case 'hubspot':
          badge = integrationBadges.hubspot
          break
      }
    }

    if (hideStatus) {
      status = undefined
    }

    if (badgeProp) {
      badge = badges[badgeProp]
    }

    if (hideBadge) {
      badge = undefined
    }

    const avatarProps: AvatarProps = {
      status,
      badge,
      label,
      gradientId,
      initials,
      url,
      size,
      className,
    }

    return <Avatar ref={ref} {...avatarProps} />
  },
  { forwardRef: true },
)

AppAvatar.displayName = 'AppAvatar'

export default AppAvatar
