// @ts-strict-ignore
import { action, makeAutoObservable, reaction } from 'mobx'
import { toQueryString } from '../../lib'
import { NotFoundError } from '../../lib/api/error-handler'
import { DisposeBag } from '../../lib/dispose'
import { IUiStore } from '../../types'
import analytics from '@src/lib/analytics'
import AppStore from '../store'
import { googleLogin } from './google-login'
import { isValidEmail } from '@openphone/lib'

type LoginStep = 'login' | 'signup' | 'code' | 'password' | 'forgot'

export class LoginUiStore implements IUiStore {
  email: string = null
  step: LoginStep = null
  loading: boolean = false
  googleLoading: boolean = false
  invite?: { email: string; name: string; role: string } = null
  referralCode?: string
  inviteCode?: string = null

  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    // FIXME: make LoginUiStore a permanent instance in AppStore
    this.app.login = this

    makeAutoObservable(this, {})

    this.fetchInvite()
    this.fetchCoupon()
    this.handleReferralCode()

    this.disposeBag.add(
      reaction(
        () => this.step,
        (step) => {
          switch (step) {
            case 'login':
              return this.app.history.push(`/login`)
            case 'signup': {
              const path = this.app.history.location.pathname
              const newPath =
                path.startsWith('/signup') || path.startsWith('/coupon')
                  ? path
                  : '/signup'
              return this.app.history.push(newPath)
            }
            case 'code':
              return this.mode === 'login'
                ? this.app.history.push('/login/code')
                : this.app.history.push('/signup/code')
            case 'password':
              return this.app.history.push('/login/password')
            case 'forgot':
              return this.app.history.push('/login/forgot')
          }
        },
      ),
    )

    /**
     * Set the initial step
     */
    this.step = this.mode
  }

  get mode() {
    const path = this.app.history.location.pathname
    return path.startsWith('/login') ? 'login' : 'signup'
  }

  get coupon() {
    return this.app.service.billing.coupon
  }

  setInitialStep = () => {
    this.step = this.mode
  }

  toggleMode = () => {
    if (this.mode === 'login') {
      this.step = 'signup'
    } else {
      this.step = 'login'
    }
  }

  setStep = (step: LoginStep) => {
    this.step = step
  }

  continueToCode = (email: string) => {
    if (!isValidEmail(email)) {
      return this.app.toast.showError(new Error('Email address is not valid.'))
    }

    this.loading = true
    this.email = email
    const operation =
      this.step === 'login'
        ? this.app.service.auth.startPasswordlessSignin(email)
        : this.app.service.auth.startPasswordlessRegister(email)

    operation
      .then(action(() => (this.step = 'code')))
      .catch((error) => {
        if (error instanceof NotFoundError) {
          this.app.showAlert({
            title: 'Email not found',
            body: `The email you entered (${email}) does not belong to an existing user. Would you like to create a new account instead?`,
            actions: [
              {
                title: 'Create a new account',
                type: 'primary',
                onClick: action(() => {
                  this.toggleMode()
                  this.continueToCode(email)
                }),
              },
              {
                title: 'Try another email',
              },
            ],
          })
        } else {
          this.app.toast.showError(error)
        }
      })
      .finally(this.stopLoading)
  }

  continueToPassword = (email: string) => {
    this.email = email
    this.step = 'password'
  }

  loginWithCode = (code: string) => {
    if (!this.email) {
      this.step = this.mode
      return
    }
    this.loading = true
    const operation =
      this.mode === 'login'
        ? this.app.service.auth
            .verifyPasswordlessSignin(this.email, code, this.inviteCode)
            .then(() => analytics.login('passwordless'))
        : this.app.service.auth
            .verifyPasswordlessRegister(
              this.email,
              code,
              this.inviteCode,
              this.referralCode,
            )
            .then((res) => {
              analytics.signup('passwordless')
              return res
            })
    operation.catch(this.app.toast.showError).finally(this.stopLoading)
  }

  loginWithPassword = (email: string, password: string) => {
    this.loading = true
    this.app.service.auth
      .passwordSignin(email, password, this.inviteCode)
      .then(() => analytics.login('password'))
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  loginWithGoogle = () => {
    if (this.app.isElectron) {
      this.app.electron.app.openExternal(`${window.location.origin}/native/login`)
    } else {
      this.googleLoading = true
      googleLogin()
        .then((auth): Promise<any> => {
          if (auth) {
            return this.mode === 'login'
              ? this.app.service.auth
                  .googleSignin(auth.accessToken, this.inviteCode)
                  .then(() => analytics.login('google'))
              : this.app.service.auth
                  .googleRegister(auth.accessToken, this.inviteCode)
                  .then((res) => {
                    analytics.signup('google')
                    return res
                  })
            // return operation.then(() => this.app.service.contact.googleSync(auth.code))
          } else {
            return Promise.resolve()
          }
        })
        .catch((error) => {
          if (error instanceof NotFoundError) {
            this.app.showAlert({
              title: 'Account not found',
              body: `This Google account does not belong to an existing OpenPhone user. Would you like to create a new account instead?`,
              actions: [
                {
                  title: 'Create a new account',
                  type: 'primary',
                  onClick: action(() => {
                    this.toggleMode()
                    this.loginWithGoogle()
                  }),
                },
                {
                  title: 'Try another account',
                },
              ],
            })
          } else {
            this.app.toast.showError
          }
        })
        .finally(this.stopLoading)
    }
  }

  resetPassword = (email: string) => {
    this.loading = true
    this.app.service.auth
      .forgotPassword(email)
      .then(() => {
        this.app.showAlert({
          title: 'Email sent...',
          body: 'Check your email for instructions on how to reset your password.',
        })
      })
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  tearDown() {
    this.disposeBag.dispose()
    // FIXME: make LoginUiStore a permanent instance in AppStore
    this.app.login = undefined
  }

  stopLoading = () => {
    this.loading = false
    this.googleLoading = false
  }

  private fetchInvite = () => {
    this.inviteCode = this.app.history.query.invite
    if (!this.inviteCode) return
    this.app.service.user
      .fetchInviteByToken(this.inviteCode)
      .then(action((invite) => (this.invite = invite)))
  }

  private fetchCoupon = () => {
    const parts = this.app.history.pathComponents
    if (parts[0] !== 'coupon') return
    this.app.service.billing.fetchCoupon(parts[1])
  }

  private handleReferralCode = () => {
    if (this.app.history.query.referral_code) {
      this.referralCode = this.app.history.query.referral_code
    }
  }
}
