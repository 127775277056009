// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import { Contact, Model } from '.'
import Service from '..'
import { parseDate } from '../../lib'
import ObjectID from '../../lib/object-id'
import { formatted } from '../../lib/phone-number'
import { ContactItemType } from '../../types'

export interface IContactItem {
  createdAt: number
  deletedAt: number
  id: string
  name: string
  templateKey: string
  type: ContactItemType
  updatedAt: number
  value: any
}

export class ContactItem implements IContactItem, Model {
  createdAt: number = Date.now()
  deletedAt: number = null
  id: string = ObjectID()
  name: string = null
  templateKey: string = null
  type: ContactItemType = null
  updatedAt: number = Date.now()
  value: any = null
  isNew?: boolean = false

  constructor(
    private service: Service,
    private contact: Contact,
    attrs: Partial<ContactItem> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get formatted() {
    const { type, value } = this
    if (type === 'phone-number') {
      return formatted(value)
    }
    return value
  }

  get template() {
    return this.service.contact.template.list.find((t) => t.key === this.templateKey)
  }

  get isEmpty() {
    if (!this.value) return true
    if (Array.isArray(this.value) && this.value.length === 0) return true
    return false
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    return this.contact.update()
  }

  delete() {
    this.contact.items = this.contact.items.filter((i) => i !== this)
    return this.contact.update()
  }

  deserialize(json: any) {
    if (json) {
      Object.assign(this, json)
      this.deletedAt = parseDate(json.deletedAt)
      this.createdAt = parseDate(json.createdAt)
      this.updatedAt = parseDate(json.updatedAt)
    }
    return this
  }

  serialize() {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      name: this.name,
      templateKey: this.templateKey,
      type: this.type,
      updatedAt: this.updatedAt,
      value: toJS(this.value),
    }
  }
}
