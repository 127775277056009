import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Illustration from '@src/component/illustrations'
import CheckEmail from '@src/component/illustrations/CheckEmail'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import TextField from '../../../component/textfield-v2'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'

export interface VerifyDomainCommandProps {
  email: string
}

const VerifyDomainCommand = ({ email }: VerifyDomainCommandProps) => {
  const styles = useStyles()
  const { toast, command, workspace } = useAppStore()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useInputState('')

  const handleVerify = () => {
    setLoading(true)
    workspace
      .verifyDomain(email, code)
      .then(() => {
        toast.show({ message: 'The domain has been verified!' })
        command.hide()
      })
      .catch(toast.showError)
      .finally(() => setLoading(false))
  }

  return (
    <Command width={672}>
      <div className={styles.content}>
        <Illustration illustration={CheckEmail} className={styles.image} />
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Check your email
        </Typography>
        <Typography variant="body" color="textSecondary" className={styles.description}>
          Enter the 6-digit code we just sent to{' '}
          <Typography component="span" variant="body" color="textPrimary">
            {`${email}`}
          </Typography>{' '}
          to verify
        </Typography>
        <div className={styles.formContainer}>
          <TextField
            autoComplete="off"
            autoFocus
            fullWidth
            inputMode="numeric"
            placeholder="6-digit code"
            size={45}
            value={code}
            onChange={setCode}
            //onKeyDown={keyboard.onEnter(handleLogin)}
            style={{ marginTop: 48, textAlign: 'center' }}
          />
          <Button
            fullWidth
            disabled={code.trim().length !== 6}
            loading={loading}
            //loading={loginStore.loading}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleVerify}
          >
            Verify
          </Button>
        </div>
      </div>
    </Command>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    content: {
      margin: '16px 32px 24px 32px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      width: 320,
      textAlign: 'center',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      width: 295,
      marginBottom: 8,
    },
    formContainer: {
      width: 320,
    },
  }),
  { name: VerifyDomainCommand.name },
)

export default VerifyDomainCommand
