import React, { useCallback } from 'react'
import { toE164 } from '../../lib/phone-number'
import {
  Link,
  TextPart,
  UrlMatch,
  EmailMatch,
  MentionMatch,
  PhoneMatch,
  getTextParts,
} from './lib'

export interface FormattedTextProps {
  value: string
  inline?: boolean
  dangerouslySetInnerHTML?: boolean
  renderMention?: (mention: string, offset: number) => React.ReactNode
}

const FormattedText: React.FC<FormattedTextProps> = function ({
  value,
  inline,
  dangerouslySetInnerHTML,
  renderMention: renderMentionProp,
}) {
  const renderLineContent = useCallback((line: string) => {
    const parts = getTextParts(line)
    return parts.map(renderTextPart)
  }, [])

  const renderTextPart = useCallback(
    (part: TextPart, index: number) => {
      switch (part.type) {
        case 'plain':
          return dangerouslySetInnerHTML ? (
            <span key={`plain${index}`} dangerouslySetInnerHTML={{ __html: part.text }} />
          ) : (
            <span key={`plain${index}`}>{part.text}</span>
          )
        case 'link':
          return renderLink(part.link)
        case 'mention':
          return renderMention(part.match)
      }
    },
    [dangerouslySetInnerHTML],
  )

  const renderLink = useCallback((link: Link) => {
    switch (link.type) {
      case 'url':
        return renderUrlLink(link.match)
      case 'email':
        return renderEmailLink(link.match)
      case 'phone':
        return renderPhoneLink(link.match)
    }
  }, [])

  const renderMention = useCallback(
    (match: MentionMatch) => {
      return renderMentionProp
        ? renderMentionProp(match.getMention(), match.getOffset())
        : match.getMatchedText()
    },
    [renderMentionProp],
  )

  const renderEmailLink = useCallback((match: EmailMatch) => {
    const email = match.getEmail()

    return (
      <a key={email} href={`mailto:${email}`}>
        {match.getMatchedText()}
      </a>
    )
  }, [])

  const renderPhoneLink = useCallback((match: PhoneMatch) => {
    const phoneNumber = toE164(match.getPhoneNumber())
    if (!phoneNumber) return match.getMatchedText()

    return (
      <a key={phoneNumber} href={`tel:${phoneNumber}`}>
        {match.getMatchedText()}
      </a>
    )
  }, [])

  const renderUrlLink = useCallback((match: UrlMatch) => {
    const url = match.getUrl()

    return (
      <a key={url} href={url} target="_blank">
        {match.getMatchedText()}
      </a>
    )
  }, [])

  const renderLine = useCallback((item: string, key = -1) => {
    return (
      <React.Fragment key={key}>
        <span style={{ lineHeight: '1.33em' }}>{renderLineContent(item)}</span>
        <br />
      </React.Fragment>
    )
  }, [])

  return (
    <>
      {value
        ? inline
          ? renderLine(value)
          : value.replace(/\n+$/, '').split('\n').map(renderLine)
        : null}
    </>
  )
}

export default React.memo(FormattedText)
