// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core'
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import TextField from '../../../component/textfield-v2'
import { Command, Footer } from '../common-v2'
import cx from 'classnames'
import Button from '../../../component/button-v2'
import IconButton from '../../../component/icon-button'
import { useAppStore } from '@src/app/context'
import { SelectSearch } from '../../../component'
import Typography from '@ui/Typography'
import { compareString } from '../../..'
import GroupMembers from './GroupMembers'
import { observer } from 'mobx-react-lite'
import { action } from 'mobx'
import PopoverMenu, { MenuItem } from '../../../component/popover-menu'
import { DeleteIcon } from '../../../component/icons/Tint/20/General'
import { MoreIcon } from '../../../component/icons/Tint/24/General'
import useInputState from '../../../lib/use-input-state'
import { AppAvatar } from '@src/app/components'

export interface EditGroupCommandProps {
  id?: string
}

const EditGroupCommand = ({ id }: EditGroupCommandProps) => {
  const { showEmojiPicker, service, workspace, command, toast } = useAppStore()
  const styles = useStyles()
  const group = workspace.groups.find((group) => group.id === id) ?? workspace.draftGroup
  const [openSelectSearch, setOpenSelectSearch] = useState(false)
  const emojiRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useInputState(group.name)

  const user = service.user.current.asMember
  const isDraft = !id

  const options = [
    // workaround to hide autocomplete warning regarding the selected option
    { name: 'default', value: 'default', id: null },
    ...service.member.collection.list
      .filter((member) => !group.membersIdentities.includes(member))
      .map((member) => ({
        name: member.name,
        value: member.name,
        id: member.id,
      }))
      .sort((member1, member2) =>
        compareString(member1.name, member2.name, { caseInsensitive: true }),
      ),
  ]

  const handleOnEmojiSelected = useCallback(
    action((emoji) => {
      group.symbol = emoji
      if (!isDraft) {
        group.save()
      }
    }),
    [isDraft],
  )

  const handleTogglePopover: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleAddMember = () => {
    setOpenSelectSearch(true)
  }

  const handleTextInputOnBlur = action(() => {
    if (!name && !isDraft) {
      toast.showError(new Error('Group name is required!'))
      return
    }

    group.name = name

    if (!isDraft) {
      group.save()
    }
  })

  const handleSaveGroup = useCallback(() => {
    if (!group.name) {
      toast.showError(new Error('Group name is required'))
      return
    }
    workspace.saveDraft()
    command.hide()
  }, [group.name])

  const handleAddUser = useCallback(
    action((value) => {
      group.membersIdentities = [...group.membersIdentities, value]

      if (!isDraft) {
        service.workspace.addUser(group.id, value.id)
      }
    }),
    [isDraft, group.members],
  )

  const handleDeleteGroup = () => {
    group.delete()
    toast.show({ message: 'The group has been deleted!' })
    command.hide()
  }

  const handleDeleteMember = useCallback(
    action((id: string) => {
      group.membersIdentities = group.membersIdentities.filter(
        (member) => member.id !== id,
      )
      if (!isDraft) {
        service.workspace.deleteUser(group.id, id)
      }
    }),
    [group.members, isDraft],
  )

  useEffect(
    () => () => {
      workspace.draftGroup = null
    },
    [],
  )

  return (
    <Command width={672} className={cx(!isDraft && styles.root)}>
      <div className={styles.header}>
        <div className={styles.leftHeaderContainer}>
          <IconButton
            icon={group.symbol}
            size={48}
            className={cx(styles.emoji, !user?.isAdmin && styles.emojiDisabled)}
            ref={emojiRef}
            disabled={!user?.isAdmin}
            onClick={() =>
              showEmojiPicker({
                anchorEl: emojiRef.current,
                onEmojiSelected: handleOnEmojiSelected,
              })
            }
          />
          <div>
            <TextField
              variant="text"
              size={24}
              autoFocus={!id}
              placeholder="Group name..."
              value={name}
              onChange={setName}
              className={styles.title}
              onBlur={handleTextInputOnBlur}
              disabled={!user?.isAdmin}
            />
            {(user?.isAdmin || (!user?.isAdmin && group.description?.length !== 0)) && (
              <TextField
                variant="text"
                size={20}
                placeholder="Add a description..."
                value={group.description}
                onBlur={() => {
                  if (!isDraft) {
                    group.save()
                  }
                }}
                disabled={!user?.isAdmin}
                onChange={action((event) => {
                  group.description = event.currentTarget.value
                })}
                className={styles.description}
              />
            )}
          </div>
        </div>
        <div style={{ position: 'relative', display: 'flex' }}>
          {user?.isAdmin && (
            <div>
              <Button variant="outlined" color="textPrimary" onClick={handleAddMember}>
                Add a member
              </Button>
              <SelectSearch
                rootClassName={styles.selectSearch}
                dropdownProps={{
                  placeholder: 'Search team members...',
                }}
                open={openSelectSearch}
                onClose={() => setOpenSelectSearch(false)}
                value={options[0]}
                options={options}
                onChange={handleAddUser}
                renderOption={({ option, index, rootOptionProps }) => {
                  if (!option.id) return null
                  const identity = service.member.collection.get(option.id)
                  return (
                    <div {...rootOptionProps} key={index} className={styles.optionRoot}>
                      <div className={styles.nameContainer}>
                        <AppAvatar size={20} identity={identity} />
                        <Typography
                          variant="footnote"
                          nowrap
                          className={styles.optionName}
                        >
                          {option.name}
                        </Typography>
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          )}
          {!isDraft && user?.isAdmin && (
            <span style={{ marginLeft: 8 }}>
              <IconButton
                size={40}
                variant="outlined"
                color="textPrimary"
                onClick={handleTogglePopover}
                icon={<MoreIcon className={styles.moreIcon} />}
                title="More"
              />
              <PopoverMenu
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: -8, horizontal: 'right' }}
                elevation={2}
              >
                <MenuItem
                  icon={<DeleteIcon className={styles.deleteIcon} />}
                  onClick={handleDeleteGroup}
                >
                  <Typography color="error">Delete group</Typography>
                </MenuItem>
              </PopoverMenu>
            </span>
          )}
        </div>
      </div>
      {group && (
        <GroupMembers
          items={group.membersIdentities}
          deleteMember={user?.isAdmin && handleDeleteMember}
        />
      )}
      {isDraft && (
        <Footer rootClassName={styles.footer}>
          <div className={styles.footerContainer}>
            <Button
              height={36}
              variant="text"
              color="textSecondary"
              onClick={() => command.hide()}
              className={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveGroup}
              height={36}
            >
              Save group
            </Button>
          </div>
        </Footer>
      )}
    </Command>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      fontSize: 21,
      color: theme.palette.op.text.primary,
      fontFamily: 'MatterSQ',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '32px 32px 6px 32px',
    },
    root: {
      paddingBottom: 26,
    },
    content: {
      border: 'none',
      padding: '0px 32px 16px 32px',
    },
    footer: {
      marginTop: 16,
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: 6,
    },
    description: {
      fontSize: 15,
      marginTop: 6,
      color: theme.palette.op.text.secondary,

      '&::placeholder': {
        color: theme.palette.op.text.secondary,
        opacity: 0.4,
      },
      '&::-webkit-input-placeholder': {
        color: theme.palette.op.text.secondary,
        opacity: 0.4,
      },

      '&::-moz-placeholder': {
        color: theme.palette.op.text.secondary,
        opacity: 0.4,
      },

      '&::-ms-placeholder': {
        color: theme.palette.op.text.secondary,
        opacity: 0.4,
      },
    },
    emoji: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      fontSize: '26.4px !important',
      boxShadow: `inset 0 0 0 1.5px ${alpha(theme.palette.op.border.common, 0.08)}`,
    },
    emojiDisabled: {
      color: 'inherit',
    },
    leftHeaderContainer: {
      display: 'flex',
      flex: 1,
    },
    selectSearch: {
      visibility: 'hidden',
      width: 0,
      height: 0,
      position: 'absolute',
      left: -100,
    },
    deleteIcon: {
      color: theme.palette.op.secondary.red2,
    },
    optionRoot: {
      marginTop: 2,
      padding: '7px 11px',
      width: 232,
      height: 30,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 5,
      cursor: 'pointer',

      '&[data-focus="true"]': {
        backgroundColor: theme.palette.op.hover.primary,
      },
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    optionName: {
      marginLeft: 8,
    },
    moreIcon: {
      color: theme.palette.op.text.primary,
    },
  }),
  { name: EditGroupCommand.name },
)

export default observer(EditGroupCommand)
