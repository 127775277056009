// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useRef } from 'react'
import Popover from '../../popover'
import Tooltip from '../../tooltip'
import { friendlyDateTime, friendlyTime } from '../../../lib'
import { global } from '../../../theme'
import { BaseItemPart, Item } from '../controller'
import { ItemProps } from '.'
import ActionsMenu from './actions-menu'

export interface BubbleProps<I extends Item> extends ItemProps<I>, BaseItemPart {
  className?: string
  transparent?: boolean
  children?: React.ReactNode
}

const Bubble = function <I extends Item>({
  controller,
  item,
  children,
  className,
  transparent,
  position,
  showActionsMenu,
}: BubbleProps<I>) {
  const styles = useStyles({ item })
  const ref = useRef<HTMLDivElement>(null)
  const createdAt = item.source.createdAt

  const margin = useMemo(() => {
    const margin = item.display.dimensions.bubbleWrapperMargin
    switch (position) {
      case 'top':
        return { marginTop: margin }
      case 'bottom':
        return { marginBottom: margin }
      case 'single':
        return { marginTop: margin, marginBottom: margin }
    }
  }, [position, item])

  const date = useMemo(() => friendlyDateTime(createdAt), [createdAt])
  const time = useMemo(() => friendlyTime(createdAt), [createdAt])

  return (
    <div
      ref={ref}
      className={cx(styles.root, item.isOutgoing ? styles.rtl : null, styles[position])}
      style={margin}
    >
      <div
        className={cx(
          styles.bubble,
          item.isOutgoing ? styles.outgoing : styles.incoming,
          item.isInternal ? styles.internal : null,
          item.display.highlighted ? styles.highlight : null,
          item.display.hovered ? styles.hover : null,
          transparent ? styles.transparent : null,
          className,
        )}
        onMouseMove={item.handleBubbleMouseMove}
        onMouseEnter={item.handleBubbleMouseEnter}
        onMouseLeave={item.handleBubbleMouseLeave}
      >
        {children}
        {showActionsMenu ? (
          <ActionsMenu
            controller={controller}
            item={item}
            className={styles.actionsMenu}
          />
        ) : null}
        {item.display.showTimestampAsPopover ? (
          <Popover
            open={item.display.hovered}
            openDelay
            closeDelay
            className={cx(styles.timestamp, styles.timestampPopover)}
          >
            {date}
          </Popover>
        ) : (
          <Tooltip placement={item.isOutgoing ? 'left' : 'right'} title={date}>
            <div className={styles.timestamp}>{time}</div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default observer(Bubble)

const useStyles = makeStyles<Theme, Partial<BubbleProps<Item>>>(
  (theme) => ({
    root: ({ item }) => ({
      position: 'relative',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      margin: `0 ${item.display.dimensions.bubbleMarginHorizontal}px`,

      '& > $bubble': {
        '& > $timestamp': {
          fontSize: 11,
          color: theme.palette.op.gray[3],
          whiteSpace: 'nowrap',
          position: 'absolute',
          bottom: `calc(50% - ${item.display.dimensions.timestampHeight / 2}px)`,
          display: 'none',
          pointerEvents: 'none',
        },

        '& > $timestampPopover': {
          display: 'block',
          bottom: `calc(50% - ${item.display.dimensions.timestampPopoverHeight / 2}px)`,
        },

        '&:hover': {
          '& > $timestampPopover': {
            display: 'block',
          },
        },
      },

      '&:hover': {
        '& > $bubble': {
          '& > $timestamp': {
            display: 'block',
          },
        },
      },
    }),
    rtl: ({ item }) => ({
      flexDirection: 'row-reverse',

      '& > $bubble > $actionsMenu': {
        right: item.display.dimensions.actionsMenuMarginHorizontal,
        left: 'unset',
      },
    }),
    bubble: ({ item }) => ({
      position: 'relative',
      borderRadius: 18,
      maxWidth: item.display.dimensions.bubbleMaxWidth,
      fontSize: item.display.dimensions.bubbleFontSize,
      fontWeight: 450,
      color: theme.palette.op.text.primary,
      backgroundColor: theme.palette.op.gray[5],

      '&$hover:before': {
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 'inherit',
        backgroundColor: theme.palette.op.hover.primary,
        pointerEvents: 'none',
        position: 'absolute',
      },

      '&$internal$hover:before': {
        backgroundColor: theme.palette.op.hover.thread,
      },
    }),
    hover: () => ({}),
    outgoing: ({ item }) => ({
      backgroundColor:
        item.display.sourceAppearance === 'indirect'
          ? theme.palette.op.primary[4]
          : theme.palette.op.primary[1],
      color:
        item.display.sourceAppearance === 'indirect'
          ? theme.palette.op.primary[3]
          : 'white',

      '&$bubble > $timestamp': {
        right: `calc(100% + ${item.display.dimensions.timestampMarginHorizontal}px)`,
      },
    }),
    incoming: ({ item }) => ({
      '&$bubble > $timestamp': {
        left: `calc(100% + ${item.display.dimensions.timestampMarginHorizontal}px)`,
      },
    }),
    internal: () => ({
      backgroundColor: theme.palette.op.note.bg,
      color: theme.palette.op.note.text,
    }),
    highlight: () => ({
      backgroundColor: theme.palette.op.note.highlight,
      color: theme.palette.op.gray[6],
    }),
    transparent: () => ({
      background: 'transparent',

      '&:hover:before': {
        opacity: 0,
      },
    }),
    top: () => ({
      '& > $outgoing': {
        borderBottomRightRadius: 4,
      },

      '& > $incoming': {
        borderBottomLeftRadius: 4,
      },
    }),
    bottom: () => ({
      '& > $outgoing': {
        borderTopRightRadius: 4,
      },

      '& > $incoming': {
        borderTopLeftRadius: 4,
      },
    }),
    middle: () => ({
      '& > $outgoing': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },

      '& > $incoming': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
    }),
    timestamp: () => ({}),
    timestampPopover: () => ({
      ...global.popover(theme),
      padding: '3px 5px',
      borderRadius: 4,
    }),
    comments: () => ({}),
    actionsMenu: ({ item }) => ({
      position: 'absolute',
      bottom: `calc(100% + ${item.display.dimensions.actionsMenuMarginVertical}px)`,
      left: item.display.dimensions.actionsMenuMarginHorizontal,
    }),
  }),
  { name: Bubble.name },
)
