// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import Avatar from '../../avatar'
import Tooltip from '../../tooltip'
import { BaseItemPart, Item } from '../controller'
import { ItemProps } from '.'

export interface RowProps<I extends Item> extends ItemProps<I>, BaseItemPart {
  className?: string
  children?: React.ReactNode
}

const Row = function <I extends Item>({
  item,
  className,
  showSenderName,
  showSenderPhoto,
  children,
}: RowProps<I>) {
  const styles = useStyles({ item })

  const padding = useMemo(() => {
    let paddingTop = 0
    let paddingBottom = 0
    if (item.sender && showSenderName) {
      paddingTop +=
        item.display.dimensions.senderNameHeight +
        item.display.dimensions.senderNameTop +
        2
    }
    if (item.isUnread) {
      paddingTop += 25
    }
    return {
      paddingTop: paddingTop || undefined,
      paddingBottom: paddingBottom || undefined,
    }
  }, [item, showSenderName])

  return (
    <div
      className={cx(
        styles.root,
        item.isOutgoing ? styles.rtl : null,
        item.display.fullWidth ? styles.fullWidth : null,
        className,
      )}
      style={padding}
    >
      {item.isUnread && (
        <div className={styles.unread}>
          <span>Unread</span>
        </div>
      )}
      {item.sender && showSenderPhoto && (
        <div className={styles.avatar}>
          <Tooltip
            placement={item.isOutgoing ? 'right' : 'left'}
            title={item.sender.name}
          >
            <Avatar
              hideStatus
              size={item.display.dimensions.avatarSize}
              identity={item.sender}
              onClick={item.handleSenderSelected}
            />
          </Tooltip>
        </div>
      )}
      {item.sender && showSenderName && (
        <div className={styles.senderName}>{item.sender.shortName}</div>
      )}
      {children}
    </div>
  )
}

export default observer(Row)

const useStyles = makeStyles<Theme, Partial<RowProps<Item>>>(
  (theme) => ({
    root: ({ item }) => ({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 4rem)',
      maxWidth: 600,
      margin: '0 auto',
      padding: `${item.display.dimensions.rowPaddingVertical}px 0`,
    }),
    rtl: ({ item }) => ({
      flexDirection: 'row-reverse',

      '& > $avatar': {
        right: 0,
        left: 'unset',
      },

      '& > $senderName': {
        right: item.display.dimensions.senderNameMarginHorizontal,
        left: 'unset',
      },
    }),
    fullWidth: () => ({
      width: 'unset',
      maxWidth: 'unset',
    }),
    avatar: () => ({
      position: 'absolute',
      left: 0,
      right: 'unset',
      bottom: 6,
      cursor: 'pointer',
    }),
    senderName: ({ item }) => ({
      position: 'absolute',
      top: item.display.dimensions.senderNameTop,
      left: item.display.dimensions.senderNameMarginHorizontal,
      fontSize: 11,
      color: theme.palette.op.gray[3],
    }),
    unread: ({ item }) => ({
      position: 'absolute',
      top: 15,
      left: item.display.dimensions.bubbleMarginHorizontal,
      right: item.display.dimensions.bubbleMarginHorizontal,
      fontSize: 10,
      fontWeight: 'bold',
      color: theme.palette.primary.light,
      textAlign: 'center',
      height: 0,

      '& > span': {
        display: 'block',
        marginTop: -6,
      },

      '&::before, &::after': {
        content: '""',
        height: 1,
        background: alpha(theme.palette.primary.light, 0.3),
        position: 'absolute',
        bottom: 0,
      },
      '&::before': {
        left: 0,
        right: 'calc(50% + 30px)',
      },
      '&::after': {
        right: 0,
        left: 'calc(50% + 30px)',
      },
    }),
  }),
  { name: Row.name },
)
