import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import React from 'react'

interface NoSelectionMessageProps {
  icon: React.ReactNode
  description: string
}

const NoSelectionMessage: React.FC<NoSelectionMessageProps> = ({ icon, description }) => {
  const styles = useStyles({})

  return (
    <div className={styles.root}>
      <span className={styles.icon}>{icon}</span>
      <Typography variant="body" className={styles.description}>
        {description}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.op.gray[4],
  },
  icon: {
    opacity: 0.75,
    lineHeight: 0,
    marginBottom: 24,
    transform: 'scale(2)',
    transformOrigin: 'top center',
  },
  description: {
    textAlign: 'center',
  },
}))

export default NoSelectionMessage
