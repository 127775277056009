import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useOnboardingState } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import TextField from '../../component/textfield-v2'
import keyboard from '../../lib/keyboard'
import { isValid as isValidNumber, toE164 } from '../../lib/phone-number'
import { usePhoneNumberInputState } from '../../lib/use-input-state'
import { Card } from './common'
import { makeStyles } from '@material-ui/core'
import ContactSupportMessage from './common/ContactSupportMessage'

const Verify: React.FC<{}> = function ({}) {
  const styles = useStyles()
  const { toast, service } = useAppStore()
  const state = useOnboardingState()
  const [isValid, setIsValid] = useState(false)
  const [phoneNumber, setPhoneNumber] = usePhoneNumberInputState('+1')
  const [showContactSupportMessage, setShowContactSupportMessage] = useState(false)

  const handleSend = () => {
    if (!isValid) {
      toast.showError(new Error(`${phoneNumber} is not a valid phone number.`))
      return
    }
    state.sendVerification(toE164(phoneNumber)).catch(() => {
      setShowContactSupportMessage(true)
    })
  }

  useEffect(() => {
    if (!service.user.current.needsPhoneVerification) {
      state.advance('skip')
    }
  }, [])

  useEffect(() => {
    setIsValid(isValidNumber(phoneNumber))
  }, [phoneNumber])

  return (
    <Card
      emoji="📱"
      title="Verify you’re real"
      description="Enter your mobile number to receive an SMS verification code. Carrier charges may apply."
    >
      <TextField
        label="Mobile phone number"
        autoFocus
        fullWidth
        value={phoneNumber}
        onChange={setPhoneNumber}
        onKeyPress={keyboard.onEnter(handleSend)}
      />

      <Button
        fullWidth
        color="primary"
        variant="contained"
        height={40}
        onClick={handleSend}
        loading={state.loading}
        className={styles.sendCode}
      >
        Send Code
      </Button>

      {showContactSupportMessage && <ContactSupportMessage />}
    </Card>
  )
}

export default observer(Verify)

const useStyles = makeStyles(() => ({
  sendCode: {
    marginTop: 20,
  },
}))
