import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Hubspot from './hubspot'
import IntegrationList from './list'
import Slack from './slack'
import Zapier from './zapier'

const Integrations = () => {
  return (
    <Switch>
      <Route path="/settings/integrations/hubspot" component={Hubspot} />
      <Route path="/settings/integrations/slack" component={Slack} />
      <Route path="/settings/integrations/zapier" component={Zapier} />
      <Route path="/settings/integrations" component={IntegrationList} />
    </Switch>
  )
}

export default Integrations
