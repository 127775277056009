export default {
  default: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  emoji: ['Apple Color Emoji', '-apple-system', 'sans-serif'].join(','),
  mono: ['Inconsolata', 'zeitung-mono', 'monospace'].join(','),
  jetBrainsMono: 'JetBrains Mono',
  header: [
    'MatterSq',
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
}
