// @ts-strict-ignore
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useStatefulPromise from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { RangeModifier } from 'react-day-picker'
import { useAppStore } from '@src/app/context'
import { ScrollView } from '../../component/scrollview'
import { formatDate } from '../../lib/date'
import { ReportGranularity } from '../../types'
import Header from '../header'
import AreaChart from './area-chart'
import DateFilter from './date-filter'
import Heatmap from './heatmap'
import UsersChart from './users-chart'

interface Datapoint {
  key: string
}

interface ValueDatapoint extends Datapoint {
  value: number
}

const Dashboard = function () {
  const styles = useStyles({})
  const { service, analytics, toast } = useAppStore()
  const theme = useTheme()

  useEffect(() => {
    service.organization.phoneNumber.fetch().catch(toast.showError)
  }, [])

  const [report, runReport] = useStatefulPromise(
    (ids: string[], range: RangeModifier, granularity: ReportGranularity) =>
      service.transport.report.users(ids, range, granularity),
  )

  useEffect(() => {
    if (analytics.selectedPhoneNumbers.length > 0) {
      runReport(analytics.selectedPhoneNumbers, analytics.range, analytics.granularity)
    }
  }, [analytics.selectedPhoneNumbers, analytics.range, analytics.granularity])

  const charts = useMemo(() => {
    if (!report.data) return null

    const unique = report.data.aggregations.uniquePeople.buckets.map((b) => ({
      key: formatDate(b.key),
      value: b.unique.value,
    }))

    const hourly = report.data.aggregations.activitiesByDay.buckets.reduce(
      (final, day) => {
        final[day.key.substr(0, 3)] = day.hours.buckets.map((b) => b.doc_count)
        return final
      },
      {
        MON: new Array(24).fill(0),
        TUE: new Array(24).fill(0),
        WED: new Array(24).fill(0),
        THU: new Array(24).fill(0),
        FRI: new Array(24).fill(0),
        SAT: new Array(24).fill(0),
        SUN: new Array(24).fill(0),
      },
    )

    const messagesSent: ValueDatapoint[] =
      report.data.aggregations.belongsTo.activities.buckets
        .find((b) => b.key === 'message')
        ?.direction.buckets.find((b) => b.key === 'outgoing')
        .histogram.buckets.map((b) => ({
          key: formatDate(b.key),
          value: b.doc_count,
        })) ?? []

    const messagesReceived: ValueDatapoint[] =
      report.data.aggregations.incoming.statuses.buckets
        .find((b) => b.key === 'received')
        ?.histogram.buckets.map((b) => ({
          key: formatDate(b.key),
          value: b.doc_count,
        })) ?? []

    const messages = {
      sent: normalize(messagesSent),
      received: normalize(messagesReceived),
      combined: combine(
        (d1, d2) => ({
          key: d1?.key ?? d2?.key,
          sent: d1?.value ?? 0,
          received: d2?.value ?? 0,
        }),
        messagesSent,
        messagesReceived,
      ),
    }

    const callsMissed =
      report.data.aggregations.incoming.statuses.buckets
        .find((b) => b.key === 'missed')
        ?.histogram.buckets.map((b) => ({
          key: formatDate(b.key),
          value: b.doc_count,
        })) ?? []

    const callsMade =
      report.data.aggregations.belongsTo.activities.buckets
        .find((b) => b.key === 'call')
        ?.direction.buckets.find((b) => b.key === 'outgoing')
        ?.histogram.buckets.map((b) => ({
          key: formatDate(b.key),
          value: b.doc_count,
        })) ?? []

    const callsAnswered =
      report.data.aggregations.incoming.statuses.buckets
        .find((b) => b.key === 'completed')
        ?.histogram.buckets.map((b) => ({
          key: formatDate(b.key),
          value: b.doc_count,
        })) ?? []

    const calls = combine(
      (missed, answered, made) => ({
        key: missed?.key ?? answered?.key ?? made?.key,
        missed: missed?.value ?? 0,
        answered: answered?.value ?? 0,
        made: made?.value ?? 0,
      }),
      callsMissed,
      callsAnswered,
      callsMade,
    )

    const duration =
      report.data.aggregations.belongsTo.activities.buckets
        .find((b) => b.key === 'call')
        ?.duration.buckets.map((b) => ({
          key: formatDate(b.key),
          value: Math.round(b.duration.value / 60),
        })) ?? []

    const users = report.data.aggregations.users.buckets.map((b) => ({
      id: b.key,
      data: b.histogram.buckets.map((b) => ({
        key: formatDate(b.key),
        value: b.doc_count,
      })),
    }))

    return {
      calls,
      duration: normalize(duration),
      hourOfWeekday: hourly,
      messages,
      uniquePeople: normalize(unique),
      users,
    }
  }, [report.data])

  return (
    <div className={styles.wrapper}>
      <Header>
        <DateFilter />
      </Header>
      <ScrollView className={styles.root}>
        <div className={styles.row}>
          <AreaChart
            data={charts?.messages.combined.data}
            dataKeys={[
              { key: 'sent', color: theme.palette.op.legacy.chart.green1, name: 'Sent' },
              {
                key: 'received',
                color: theme.palette.op.legacy.chart.blue1,
                name: 'Received',
              },
            ]}
            title="Messages"
            info={`Total number of outgoing and incoming messages per ${analytics.granularity}.`}
            total={charts?.messages.combined.total}
            className={styles.chart}
          />
          <AreaChart
            data={charts?.uniquePeople.data}
            dataKeys={[
              {
                key: 'value',
                color: theme.palette.op.legacy.chart.yellow1,
                name: 'Unique',
              },
            ]}
            title="Unique conversations"
            info={`Total number of unique conversations by this user per ${analytics.granularity}.`}
            total={charts?.uniquePeople.total}
            className={styles.chart}
          />
        </div>
        <div className={styles.row}>
          <AreaChart
            data={charts?.calls.data}
            dataKeys={[
              {
                key: 'made',
                color: theme.palette.op.legacy.chart.blue2,
                name: 'Outgoing (All)',
              },
              {
                key: 'answered',
                color: theme.palette.op.legacy.chart.green2,
                name: 'Incoming (Answered)',
              },
              {
                key: 'missed',
                color: theme.palette.op.legacy.chart.red1,
                name: 'Incoming (Missed)',
              },
            ]}
            title="Calls"
            info={`Total number of calls per ${analytics.granularity}.`}
            total={charts?.calls.total}
            className={styles.chart}
          />
          <AreaChart
            data={charts?.duration.data}
            dataKeys={[
              {
                key: 'value',
                color: theme.palette.op.legacy.chart.green2,
                name: 'Minutes',
              },
            ]}
            title="Time on the phone"
            info={`Total number of minutes per ${analytics.granularity}.`}
            total={charts?.duration.total}
            unit="min"
            className={styles.chart}
          />
        </div>
        <div className={styles.row}>
          <UsersChart
            title="Activities by user"
            info={`Total activities for each user by ${analytics.granularity}. An activity is a sent message, outgoing call or an answered incoming call.`}
            className={styles.chart}
            users={charts?.users}
          />
        </div>
        <div className={styles.row}>
          <Heatmap
            className={styles.chart}
            title="Activities per hour of day"
            info="Total activities per hour of each day of the week during the selected time period."
            values={charts?.hourOfWeekday}
            axis={[
              '12am',
              '1am',
              '2am',
              '3am',
              '4am',
              '5am',
              '6am',
              '7am',
              '8am',
              '9am',
              '10am',
              '11am',
              '12pm',
              '1pm',
              '2pm',
              '3pm',
              '4pm',
              '5pm',
              '6pm',
              '7pm',
              '8pm',
              '9pm',
              '10pm',
              '11pm',
            ]}
          />
        </div>
      </ScrollView>
    </div>
  )
}

export default observer(Dashboard)

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: 1,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    padding: '0 2rem 2rem',
    overflow: 'auto',
  },
  section: {
    marginTop: 40,
  },
  row: {
    marginTop: '2rem',
    display: 'flex',

    '&:first-child': {
      marginTop: 20,
    },
  },
  chart: {
    flex: 1,
    backgroundColor: theme.palette.op.background.highlight(0.03),
    borderRadius: 5,
    marginRight: '2rem',
    minWidth: 0,

    '&:last-child': {
      marginRight: 0,
    },
  },
  user: {
    padding: '2rem 2rem 0',
  },
  loading: {
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
  },
}))

function normalize<T extends ValueDatapoint>(data: T[]): { total: number; data: T[] } {
  return {
    total: data.reduce((f, b) => f + b.value, 0),
    data,
  }
}

function combine<T extends ValueDatapoint, R extends Datapoint>(
  transform: (...d: T[]) => R,
  ...d: T[][]
): { total: number; data: R[] } {
  const sorted = [...d].sort((a, b) => b.length - a.length)
  const total = d.reduce((f, b) => f + b.reduce((f, b) => f + b.value, 0), 0)
  return {
    total,
    data: sorted[0].map((b, i) => {
      const args = d.map((d) => d[i])
      return transform(...args)
    }),
  }
}
