import AppStore from '../store'
import { Deserializer } from '../../component/editor/lib'
import { Enrichment } from '../../service/model'

export default class InputBarDeserializer extends Deserializer {
  static getModelById(app: AppStore, id: string) {
    return id === app.service.organization.current.id
      ? app.service.organization.current
      : app.service.member.collection.get(id) ??
          app.service.phoneNumber.collection.find(
            (phoneNumber) => phoneNumber.groupId === id,
          )?.group ??
          app.service.workspace.groups.find((group) => group.id === id)
  }

  static getNameById(app: AppStore, id: string, enrichment: Enrichment) {
    const model = InputBarDeserializer.getModelById(app, id)
    return model ? model.name : enrichment?.tokens?.[id]?.replacement
  }

  constructor(protected app: AppStore, readonly enrichment: Enrichment) {
    super()
  }

  handleMention(id: string) {
    return { id, name: InputBarDeserializer.getNameById(this.app, id, this.enrichment) }
  }
}
