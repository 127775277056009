// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import VirtualList from '../virtual-list'
import Controller, { Item } from './controller'
import ItemComponent from './item'

export * from './controller'
export { Controller }

export interface FeedProps<I extends Item> extends React.HTMLProps<HTMLDivElement> {
  controller: Controller<I>
  pinToIndex?: number
  renderComments?(item: I): React.ReactNode
  renderMention?(mention: string, item: I): React.ReactNode
}

const itemPadding = { top: 0, bottom: 35 }

const Feed = function <I extends Item>({
  controller,
  pinToIndex,
  renderComments,
  renderMention,
  ...props
}: FeedProps<I>) {
  const styles = useStyles({})
  const itemContent = useCallback(
    (item: Item) => (
      <ItemComponent
        key={item.id}
        controller={controller}
        item={item}
        renderComments={renderComments}
        renderMention={renderMention}
      />
    ),
    [controller, renderComments, renderMention],
  )

  return controller.strategy === 'virtual' ? (
    <VirtualList
      {...props}
      name="feed"
      ref={controller.listRef}
      pinToBottom
      pinToIndex={pinToIndex}
      data={controller.items}
      itemContent={itemContent}
      listPadding={itemPadding}
      itemEstimatedHeight={controller.itemEstimatedHeight}
      onTopReached={controller.loadMore}
    />
  ) : (
    <div {...props}>{controller.items.list.map(itemContent)}</div>
  )
}

export default observer(Feed)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
  }),
  { name: Feed.name },
)
