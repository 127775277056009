import { makeStyles, Theme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { Command, Footer } from '../common-v2'
import { Header } from '@src/app/settings/common'
import Button from '@src/component/button-v2'
import Typography from '@ui/Typography'
import { ContactEventTypes, CallsAndMessagesEventTypes } from '@src/data'
import EventRow from './EventRow'
import { useAppStore } from '@src/app/context'
import { CircularProgress } from '@src/component/progress'
import { ScrollView } from '@src/component/scrollview'

export interface SendTestRequestCommandProps {
  id: string
}

const SendTestRequestCommand = ({ id }: SendTestRequestCommandProps) => {
  const styles = useStyles()
  const { workspace } = useAppStore()
  const [checkedValue, setCheckedValue] = useState<string>('')
  const [isSending, setSending] = useState(false)

  const handleSubmit = async () => {
    const data =
      CallsAndMessagesEventTypes.find((event) => event.name === checkedValue)
        ?.payloadSample ??
      ContactEventTypes.find((event) => event.name === checkedValue)?.payloadSample
    if (data) {
      try {
        setSending(true)
        await workspace.sendTestRequest(id, data, checkedValue)
      } finally {
        setSending(false)
      }
    }
  }

  const RenderSubmitBtn = () => (
    <Button disabled={isSending || !checkedValue} onClick={handleSubmit}>
      {' '}
      {isSending ? (
        <>
          <CircularProgress size={16} className={styles.progress} />
          Sending request...
        </>
      ) : (
        <>Send test request</>
      )}
    </Button>
  )

  return (
    <Command width={672} height="large">
      <ScrollView>
        <div className={styles.root}>
          <Header
            title="Send a test request"
            subtitle="Select and send a test event to a webhook endpoint."
            className={styles.header}
          />
          <div className={styles.content}>
            <Typography
              variant="caption1"
              color="textSecondary"
              className={styles.headline}
            >
              Calls & messages
            </Typography>
            {CallsAndMessagesEventTypes.map((eventType) => (
              <EventRow
                checkedValue={checkedValue}
                setCheckedValue={setCheckedValue}
                key={eventType.name}
                event={eventType}
                disabled={isSending}
              />
            ))}
            <Typography
              style={{ marginTop: 22 }}
              variant="caption1"
              color="textSecondary"
              className={styles.headline}
            >
              Contacts
            </Typography>
            {ContactEventTypes.map((eventType) => (
              <EventRow
                checkedValue={checkedValue}
                setCheckedValue={setCheckedValue}
                key={eventType.name}
                event={eventType}
                disabled={isSending}
              />
            ))}
          </div>
        </div>
      </ScrollView>
      <Footer>
        <div className={styles.footer}>
          <RenderSubmitBtn />
        </div>
      </Footer>
    </Command>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: { padding: '24px 0px' },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    header: {
      margin: '0px 24px',
    },
    headline: { margin: '0px 12px 11px 12px' },
    content: {
      margin: '27px 12px 0px 12px',
    },
    progress: {
      color: theme.palette.op.gray[4],
      marginRight: 10,
    },
  }),
  { name: SendTestRequestCommand.name },
)

export default SendTestRequestCommand
