// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useEffect } from 'react'
import { fromEvent } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { ScrollView } from '../../../component/scrollview'
import useCombinedRefs from '../../../lib/use-combined-ref'

interface ContentProps extends React.HTMLProps<HTMLDivElement> {}

const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ children, className, ref: _, ...props }, outerRef) => {
    const ref = useCombinedRefs<HTMLDivElement>(outerRef)
    const styles = useStyles({})

    useEffect(() => {
      const header = document.getElementById('commandHeader')
      const footer = document.getElementById('commandFooter')

      function setShadows(positions: string) {
        if (positions.includes('top')) {
          header?.classList.add(styles.topShadow)
        } else {
          header?.classList.remove(styles.topShadow)
        }
        if (positions.includes('bottom')) {
          footer?.classList.add(styles.bottomShadow)
        } else {
          footer?.classList.remove(styles.bottomShadow)
        }
      }

      setShadows(calculateShadows(ref.current))

      const subs = fromEvent(ref.current, 'scroll')
        .pipe(
          map((event) => calculateShadows(event.target as HTMLDivElement)),
          distinctUntilChanged(),
        )
        .subscribe(setShadows)

      return () => subs.unsubscribe()
    }, [children])

    return (
      <ScrollView
        {...props}
        id="commandContent"
        rootRef={ref}
        contentClassName={cx(styles.content, className)}
      >
        {children}
      </ScrollView>
    )
  },
)

function calculateShadows(el: HTMLDivElement): string {
  const top = el.scrollTop > 0
  const bottom = el.scrollHeight - el.scrollTop !== el.clientHeight
  return [top && 'top', bottom && 'bottom'].filter((a) => Boolean(a)).join()
}

export default Content

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: '10px 0',
  },
  topShadow: {
    boxShadow: '0px 12px 15px -9px #00000030',
  },
  bottomShadow: {
    boxShadow: '0px -12px 15px -9px #00000030',
  },
}))
