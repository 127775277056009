// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RecordIcon from '@material-ui/icons/FiberManualRecord'
import SaveIcon from '@material-ui/icons/Save'
import StopIcon from '@material-ui/icons/Stop'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Recorder from 'recorderjs'
import { v4 as uuid } from 'uuid'
import { useAppStore } from '@src/app/context'
import AudioPlayer from '../../../component/audio-player'
import { CircularProgress } from '../../../component/progress'
import { toHHMMSS } from '../../../lib/date'
import useKeyStepper from '../../../lib/use-key-stepper'
import Content from '../common/content'
import CommandItem from '../common/item'
import Textfield from '../common/textfield'

interface RecordGreetingProps {
  onComplete: (url: string, text: string) => void
}

type Option = 'record' | 'stop' | 'save'

const RecordGreeting: React.FC<RecordGreetingProps> = function ({ onComplete }) {
  const styles = useStyles({})
  const { service, toast } = useAppStore()
  const recorderRef = useRef<any>(null)
  const streamRef = useRef<MediaStream>(null)
  const [recording, setRecording] = useState(false)
  const [blob, setBlob] = useState<any>(null)
  const [blobUrl, setBlobUrl] = useState<any>(null)
  const [duration, setDuration] = useState<number>(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [startTime, setStartTime] = useState<number>(null)

  const [uploadState, runUpload] = useStatefulPromise((file: File) =>
    service.transport.communication.upload(file, (loaded, total) => {
      setUploadProgress(loaded / total)
    }),
  )

  useEffect(() => {
    if (!uploadState.data) return
    onComplete(uploadState.data, null)
  }, [uploadState.data])

  const items: Option[] = useMemo(
    () => (recording ? ['stop'] : blob ? ['save', 'record'] : ['record']),
    [recording, blob],
  )

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    deps: [blob],
    name: 'command/set-greeting/record',
    handleSelect: (item) => {
      if (item === 'record') {
        handleStartRecording()
      } else if (item === 'stop') {
        handleStopRecording()
      } else if (item === 'save') {
        runUpload(new File([blob], uuid(), { type: 'audio/wav' }))
      }
    },
  })

  useEffect(() => {
    if (!startTime) return
    const id = setInterval(() => {
      setDuration(Math.floor((Date.now() - startTime) / 1000))
    }, 1000)
    return () => clearInterval(id)
  }, [startTime])

  const handleStartRecording = () => {
    setBlob(null)
    setBlobUrl(null)
    const constraints = { audio: true, video: false }
    window.navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        streamRef.current = stream
        const audioContext = new AudioContext()
        const input = audioContext.createMediaStreamSource(stream)
        recorderRef.current = new Recorder(input)
        recorderRef.current.record()
        setStartTime(Date.now())
        setRecording(true)
      })
      .catch(toast.showError)
  }

  const handleStopRecording = () => {
    recorderRef.current.stop()
    streamRef.current.getAudioTracks()[0].stop()
    setRecording(false)
    setDuration(null)
    setStartTime(null)
    recorderRef.current.exportWAV((blob) => {
      setBlob(blob)
      setBlobUrl(URL.createObjectURL(blob))
    })
  }

  const loading = uploadState.status === PromiseStateStatus.Loading

  return (
    <Content>
      {blob ? (
        <div style={{ padding: '1.5rem 25px' }}>
          <AudioPlayer media={{ type: 'audio/wav', url: blobUrl }} />
        </div>
      ) : (
        <Textfield disabled value={startTime ? toHHMMSS(duration) : '--:--'} />
      )}
      {loading && (
        <div style={{ padding: '1rem 2rem', display: 'flex', alignItems: 'center' }}>
          <CircularProgress style={{ marginRight: 16 }} size={20} />
          <Typography variant="caption" color="textPrimary">
            Uploading ({Math.round(uploadProgress * 100)}%)...
          </Typography>
        </div>
      )}
      {!loading &&
        items.map((item, index) => (
          <CommandItem
            key={item}
            {...getItemProps(index)}
            highlighted={selectedIndex === index}
          >
            {item === 'record' ? (
              <>
                <RecordIcon color="inherit" fontSize="small" className={styles.icon} />
                <Typography variant="body1" color="textPrimary">
                  {blob ? 'Record another' : 'Start recording'}
                </Typography>
              </>
            ) : item === 'stop' ? (
              <>
                <StopIcon color="inherit" fontSize="small" className={styles.icon} />
                <Typography variant="body1" color="textPrimary">
                  Stop
                </Typography>
              </>
            ) : (
              <>
                <SaveIcon color="inherit" fontSize="small" className={styles.icon} />
                <Typography variant="body1" color="textPrimary">
                  Save
                </Typography>
              </>
            )}
          </CommandItem>
        ))}
    </Content>
  )
}

export default observer(RecordGreeting)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
}))
