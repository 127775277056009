// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Illustration from '@src/component/illustrations'
import EmptyActivity from '@src/component/illustrations/EmptyActivity'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../component/icon-button'
import { MarkDone2Icon } from '../../component/icons/Tint/20/General'
import { PopoverModal } from '../../component/menu'
import Tabs from '../../component/tabs'
import Typography from '@ui/Typography'
import VirtualList from '../../component/virtual-list'
import useWindowSize from '../../lib/use-window-size'
import { Alert } from '../../service/model'
import AlertRow from './row'

const maxHeight = 822

interface AlertsProps {
  anchorRef: React.RefObject<Element>
}

const listPadding = { top: 10, bottom: 10 }

const filters = [
  { value: 'all', name: 'All activity' },
  { value: 'threads', name: 'Internal threads' },
]

const Alerts: React.FC<AlertsProps> = function ({ anchorRef }) {
  const styles = useStyles({})
  const { alerts } = useAppStore()
  const windowSize = useWindowSize()
  const height = windowSize.height > maxHeight + 100 ? maxHeight : windowSize.height - 150
  const isEmpty = alerts.filteredCollection.length === 0

  const renderItem = useCallback((alert: Alert) => {
    return (
      <AlertRow
        key={alert.id}
        alert={alert}
        onClick={alerts.openAlert}
        flash={alert.id === alerts.pinnedAlertId}
      />
    )
  }, [])

  return (
    <PopoverModal
      open={alerts.open}
      anchorEl={anchorRef.current}
      onClose={alerts.hide}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={styles.root} style={{ height }}>
        <div className={styles.header}>
          <Tabs options={filters} value={alerts.filter} onChange={alerts.setFilter} />
          <IconButton
            onClick={alerts.markAllAsOpened}
            icon={<MarkDone2Icon />}
            title="Mark all as read"
          />
        </div>
        {isEmpty ? (
          <div className={styles.emptyState}>
            <Illustration
              illustration={EmptyActivity}
              className={styles.emptyStateImage}
            />
            <Typography variant="title3" style={{ marginTop: 8 }}>
              It's quiet in here...
            </Typography>
            <Typography variant="body" color="textSecondary" style={{ marginTop: 6 }}>
              You don't have any notifications at the moment, but once you do they will
              live here.
            </Typography>
          </div>
        ) : (
          <VirtualList
            name="alerts"
            data={alerts.filteredCollection}
            pinToIndex={alerts.pinToIndex}
            itemContent={renderItem}
            itemEstimatedHeight={80}
            listPadding={listPadding}
          />
        )}
      </div>
    </PopoverModal>
  )
}

export default observer(Alerts)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 432,
      maxHeight: 822,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      padding: 14,
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: `0 1.5px 0 0 ${theme.palette.op.border.common}`,
    },
    emptyState: {
      width: 336,
      textAlign: 'center',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emptyStateImage: {
      width: 295,
      height: 276,
      objectFit: 'contain',
    },
    list: {
      padding: 8,
      flex: 1,
      overflow: 'auto',
    },
  }),
  { name: 'Alerts' },
)
