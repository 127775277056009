// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import { Identity, IdentityPhone, Model } from '.'
import Service from '..'
import { getInitials } from '../../lib'
import { formatted } from '../../lib/phone-number'
import { isTruthy } from '../../lib/rx-operators'

export class IIntegrationContact {
  id: string
  identifiers: string[]
  orgId: string
  firstName: string
  lastName: string
  company: string
  role: string
  phoneNumbers: string[]
  emails: string[]
  externalId: string
  source: string
  url: string
}

export class IntegrationContact implements IIntegrationContact, Identity, Model {
  id: string = null
  identifiers: string[] = null
  orgId: string = null
  firstName: string = null
  lastName: string = null
  company: string = null
  role: string = null
  phoneNumbers: string[] = null
  emails: string[] = null
  externalId: string = null
  source: string = null
  url: string = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get pictureUrl(): string {
    return null
  }

  get name(): string {
    if (!this.firstName && !this.lastName) {
      if (this.company) return this.company
      if (this.phoneNumbers.length > 0) return formatted(this.phoneNumbers[0])
      if (this.emails.length > 0) return this.emails[0]
      return 'Unnamed'
    }
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.firstName, this.lastName].find(isTruthy)
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get phones(): IdentityPhone[] {
    return this.phoneNumbers.map((number) => ({
      id: null,
      name: null,
      symbol: null,
      number,
    }))
  }

  get emailAddresses(): string[] {
    return this.emails ?? []
  }

  get isAnonymous() {
    return false
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      identifiers: toJS(this.identifiers),
      orgId: this.orgId,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      role: this.role,
      phoneNumbers: toJS(this.phoneNumbers),
      emails: toJS(this.emails),
      externalId: this.externalId,
      source: this.source,
      url: this.url,
    }
  }
}

export const isIntegrationContact = (a: any): a is IntegrationContact => {
  return a instanceof IntegrationContact
}
