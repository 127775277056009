// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { AppAvatar } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button'
import GroupAvatar from '../../../component/group-avatar'
import Switch from '../../../component/switch'
import { Member, Organization, PhoneNumber, GroupModel } from '../../../service/model'
import Content from '../common/content'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'

export interface ListSharedUsersProps {
  ids: string[]
  onChange: (id: string[]) => void
  onInvite: () => void
  onClose: () => void
}

interface SharedOrg {
  type: 'org'
  entity: Organization
}

interface SharedGroup {
  type: 'group'
  entity: PhoneNumber
}

interface SharedMember {
  type: 'member'
  entity: Member
}

interface SharedUserGroup {
  type: 'user-group'
  entity: GroupModel
}

type SharedItem = SharedOrg | SharedGroup | SharedMember | SharedUserGroup

const ListSharedUsers: React.FC<ListSharedUsersProps> = function ({
  ids = [],
  onInvite,
  onChange,
  onClose,
}) {
  const styles = useStyles({})
  const { service, workspace } = useAppStore()
  const org = service.organization.current
  const currentUser = service.user.current
  const members = service.member.collection
  const phoneNumbers = service.user.phoneNumbers.list
  const [sharedWith, setSharedWith] = useState(ids)
  const memberCount = members.length

  const groups = phoneNumbers
    .filter((p) => p.users.length > 1)
    .map((g): SharedGroup => ({ type: 'group', entity: g }))

  const invitedMembers = sharedWith
    .filter((id) => id !== currentUser.id && members.get(id))
    .map((id): SharedMember => ({ type: 'member', entity: members.get(id) }))

  const userGroups: SharedUserGroup[] = workspace.groups.map((group) => ({
    type: 'user-group',
    entity: group,
  }))

  const items: SharedItem[] = [
    { type: 'org', entity: org },
    ...groups,
    ...userGroups,
    ...invitedMembers,
  ]

  const toggleShareId = (shareId: string) => {
    if (sharedWith.includes(shareId)) {
      handleChange(sharedWith.filter((id) => id !== shareId))
    } else {
      handleChange([...sharedWith, shareId])
    }
  }

  const handleChange = (ids: string[]) => {
    setSharedWith(ids)
    onChange(ids)
  }

  const renderOrg = (org: Organization) => {
    return (
      <div key={org.id} className={styles.item}>
        <AppAvatar identity={org} size={40} />
        <label htmlFor={`share-${org.id}`} className={styles.itemName}>
          <Typography variant="body2" color="textPrimary">
            {org.name || 'Your Workspace'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Everyone in your organization ({memberCount}{' '}
            {memberCount > 1 ? 'members' : 'member'})
          </Typography>
        </label>
        <Switch
          id={`share-${org.id}`}
          checked={sharedWith.includes(org.id)}
          onChange={() => toggleShareId(org.id)}
        />
      </div>
    )
  }

  const renderGroup = (phoneNumber: PhoneNumber) => {
    return (
      <div key={phoneNumber.groupId} className={styles.item}>
        <GroupAvatar size="medium" group={phoneNumber} />
        <label htmlFor={`share-${phoneNumber.groupId}`} className={styles.itemName}>
          <Typography variant="body2" color="textPrimary">
            {phoneNumber.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Everyone sharing {phoneNumber.formattedNumber} ({phoneNumber.users.length}{' '}
            {phoneNumber.users.length > 1 ? 'members' : 'member'})
          </Typography>
        </label>
        <Switch
          id={`share-${phoneNumber.groupId}`}
          checked={sharedWith.includes(phoneNumber.groupId)}
          onChange={() => toggleShareId(phoneNumber.groupId)}
        />
      </div>
    )
  }

  const renderUserGroup = (group: GroupModel) => {
    return (
      <div key={group.id} className={styles.item}>
        <GroupAvatar size="medium" group={group} />
        <label htmlFor={`share-${group.id}`} className={styles.itemName}>
          <Typography variant="body2" color="textPrimary">
            {group.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Everyone in your group ({group.members.length}{' '}
            {group.members.length > 1 ? 'members' : 'member'})
          </Typography>
        </label>
        <Switch
          id={`share-${group.id}`}
          checked={sharedWith.includes(group.id)}
          onChange={() => toggleShareId(group.id)}
        />
      </div>
    )
  }

  const renderMember = (member: Member) => {
    return (
      <div key={member.id} className={styles.item}>
        <AppAvatar identity={member} size={40} />
        <div className={styles.itemName}>
          <Typography variant="body2" color="textPrimary">
            {member.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {member.email}
          </Typography>
        </div>
        <Button variant="text" color="default" onClick={() => toggleShareId(member.id)}>
          Delete
        </Button>
      </div>
    )
  }

  return (
    <>
      <Content>
        {items.map((item) =>
          item.type === 'org'
            ? renderOrg(item.entity)
            : item.type === 'group'
            ? renderGroup(item.entity)
            : item.type === 'user-group'
            ? renderUserGroup(item.entity)
            : renderMember(item.entity),
        )}
      </Content>
      <Footer
        actions={
          <>
            <FooterButton variant="text" onClick={onInvite} className={styles.invite}>
              Invite a member
            </FooterButton>
            <FooterButton variant="contained" onClick={onClose}>
              Save
            </FooterButton>
          </>
        }
      />
    </>
  )
}

export default observer(ListSharedUsers)

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 2rem',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  itemName: {
    flex: 1,
    marginLeft: 12,
  },
  invite: {
    marginRight: 15,
  },
}))
