import { makeStyles, Theme } from '@material-ui/core'
import { WebhookDetailsRoute } from '@src/app/settings/webhooks'
import WebhooksListRoute from '@src/app/settings/webhooks/WebhooksTableRoute'
import { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React, { useLayoutEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useAppStore } from '@src/app/context'

interface WebhooksRouteProps {}

const WebhooksRoute = (props: WebhooksRouteProps) => {
  const { workspace } = useAppStore()
  const { path } = useRouteMatch()

  useLayoutEffect(() => {
    if (workspace.webhooksNetworkStatusState === PromiseStateStatus.Idle) {
      workspace.fetchWebhooks()
    }
  }, [workspace.webhooksNetworkStatusState])

  return (
    <>
      <Route path={`${path}/:id`} component={WebhookDetailsRoute} />
      <Route exact path={path} component={WebhooksListRoute} />
    </>
  )
}

export default observer(WebhooksRoute)
