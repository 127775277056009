// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '../..'
import { IHubspotCallDeposition } from '../../../types'
import { Model } from '../base'

export class HubspotIntegration implements Model {
  id: string = null
  type: 'hubspot' = 'hubspot'
  hubspotUser: string = null
  resourceIds: string[] = null
  refreshToken: string = null
  accessToken: string = null
  logCalls = { enabled: false, activityType: null }
  logMessages = { enabled: false, activityType: null }
  accessTokenExpiresAt: Date = null
  contactProperties: string[] = []
  callDepositions: IHubspotCallDeposition[] = []
  contactImport: {
    hasMore: boolean
    vidOffset: number
  } = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  update = (attrs: Partial<this>) => {
    Object.assign(this, attrs)
    return this.service.integration.update(this)
  }

  delete = () => {
    return this.service.integration.delete(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      hubspotUser: this.hubspotUser,
      resourceIds: toJS(this.resourceIds),
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      logCalls: toJS(this.logCalls),
      logMessages: toJS(this.logMessages),
      accessTokenExpiresAt: this.accessTokenExpiresAt,
      contactProperties: toJS(this.contactProperties),
      callDepositions: toJS(this.callDepositions),
      contactImport: toJS(this.contactImport),
    }
  }
}
