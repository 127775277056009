import MuiCard from '@material-ui/core/Card'
import type { CardProps } from '@material-ui/core/Card'
import MuiCardActions from '@material-ui/core/CardActions'
import MuiCardContent from '@material-ui/core/CardContent'
import MuiCardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import { mediaStyles } from '../../theme'

const Card = withStyles((theme) => ({
  root: mediaStyles({
    phone: {
      margin: '0 -1.5rem',
      borderRadius: 0,
    },
    common: {
      maxWidth: 630,
      background: theme.palette.op.background.popover,
      boxShadow: theme.palette.op.shadow.card,
      borderRadius: theme.palette.op.borderRadius.card,

      '& a': {
        color: theme.palette.primary.light,
        textDecoration: 'none',
      },
    },
  }),
}))(MuiCard)

const CardContent = withStyles((theme) => ({
  root: mediaStyles({
    phone: {
      padding: '16px 1.5rem',
    },
    desktop: {
      padding: '16px 2rem',
    },
  }),
}))(MuiCardContent)

const CardHeader = withStyles((theme) => ({
  root: mediaStyles({
    phone: {
      padding: '20px 1.5rem',
    },
    desktop: {
      padding: '20px 2rem',
    },
  }),
  title: {
    marginBottom: 5,
  },
  subheader: {
    fontSize: 14,
  },
}))(MuiCardHeader)

const CardActions = withStyles((theme) => ({
  root: mediaStyles({
    phone: {
      padding: '10px 1.5rem 25px',
    },
    desktop: {
      padding: '10px 2rem 25px',
    },
  }),
}))(MuiCardActions)

export { Card, CardContent, CardHeader, CardActions, CardProps }
