import { createTheme } from '@material-ui/core/styles'

import fonts from '../../fonts'

import palette from './palette'

export default createTheme({
  palette: {
    type: 'light',
    background: {
      default: palette.background.primary,
      paper: palette.background.popover,
    },
    primary: { light: palette.primary[2], main: palette.primary[1] },
    secondary: { main: palette.secondary.green2 },
    text: { primary: palette.gray[1], secondary: palette.gray[2] },
    error: { main: palette.secondary.red1 },
    op: palette,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: fonts.default,
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1.5px solid rgba(0, 0, 0, 0.15)`,
        },
      },
    },
  },
})
