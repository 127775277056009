// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SegmentControlProps } from './control'
import useKeyStepper, { KeyMapping, KeyMappings } from '../../lib/use-key-stepper'

export interface SegmentProps {
  gap?: number
  disabled?: boolean
  value?: number
  keys?: KeyMapping
  onChange?(index: number): void
}

const Segment: React.FC<SegmentProps> = function ({
  gap = 3,
  disabled = false,
  value = 0,
  keys = KeyMappings.HORIZONTAL,
  onChange,
  children: childrenProp,
}) {
  const count = React.Children.count(childrenProp)

  const items = useMemo(
    () =>
      Array(count)
        .fill(null)
        .map((_, i) => i),
    [count],
  )

  const handleChange = (index: number) => {
    onChange?.(index)
  }

  const { getItemProps, setSelectedIndex, selectedIndex } = useKeyStepper({
    name: 'Segment',
    items,
    keys,
    selectOnMouseMove: false,
    handleSelect: (item) => {
      handleChange(item)
    },
  })

  const styles = useStyles({ gap, selectedIndex })

  useEffect(() => setSelectedIndex(value), [value])

  const children = React.Children.map(childrenProp, (child, i) => {
    if (!React.isValidElement<SegmentControlProps>(child)) return null
    return React.cloneElement(child, {
      disabled,
      selected: selectedIndex === i,
      ...getItemProps(i),
    })
  })

  return (
    <div className={styles.root}>
      {disabled ? null : <div className={styles.indicator} />}
      {children}
    </div>
  )
}

export default observer(Segment)

const useStyles = makeStyles<Theme, Partial<SegmentProps> & { selectedIndex: number }>(
  (theme) => ({
    root: ({ gap }) => ({
      position: 'relative',
      display: 'flex',
      backgroundColor: theme.palette.op.gray[5],
      borderRadius: 6,
      padding: gap,

      '& > *:not(:last-child)': {
        marginRight: gap * 2,
      },
    }),
    indicator: ({ gap, selectedIndex }) => ({
      position: 'absolute',
      top: gap,
      left: gap,
      backgroundColor: theme.palette.op.background.popover,
      borderRadius: 4,
      transform: `translateX(${selectedIndex * (30 + gap * 2)}px)`,
      transition: theme.transitions.create(['transform'], {
        duration: 200,
        easing: 'ease',
      }),
      width: 30,
      height: 30,
    }),
  }),
  { name: Segment.name },
)
