import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountIcon from '@material-ui/icons/AccountCircle'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import ExitIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import useKeyStepper from '../../lib/use-key-stepper'
import { AppAvatar } from '@src/app/components'
import { Command } from './common-v2'
import Content from './common/content'
import Header from './common/header'
import Item from './common/item'

export interface AccountCommandProps {}

type ItemType = 'account' | 'signout' | 'settings' | 'invite'

const items: ItemType[] = ['account', 'invite', 'settings', 'signout']

const AccountCommand: React.FC<AccountCommandProps> = function ({}) {
  const styles = useStyles({})
  const { command, service, history, signOut } = useAppStore()
  const user = service.user.current

  const itemTitle = (item: ItemType): string => {
    switch (item) {
      case 'account':
        return 'Update your profile'
      case 'invite':
        return 'Invite your team'
      case 'settings':
        return 'Settings'
      case 'signout':
        return 'Sign out'
    }
  }

  const itemIcon = (item: ItemType): React.ReactNode => {
    switch (item) {
      case 'account':
        return <AccountIcon color="inherit" className={styles.icon} />
      case 'invite':
        return <AddIcon color="inherit" className={styles.icon} />
      case 'settings':
        return <SettingsIcon color="inherit" className={styles.icon} />
      case 'signout':
        return <ExitIcon color="inherit" className={styles.icon} />
    }
  }

  const { getItemProps, selectedIndex } = useKeyStepper({
    items,
    name: 'command/account',
    handleSelect: (item) => {
      switch (item) {
        case 'account':
          history.push('/settings/account')
          return command.hide()
        case 'invite':
          return command.present({ name: 'invite member' })
        case 'settings':
          history.push('/settings/members')
          return command.hide()
        case 'signout':
          signOut()
          return command.hide()
      }
    },
  })

  return (
    <Command>
      <Header
        title={user.asMember.name}
        subtitle={user.email}
        trailingIcon={<AppAvatar identity={user.asMember} />}
      />
      <Content>
        <div className={styles.list}>
          {items.map((item, index) => (
            <Item
              key={item}
              {...getItemProps(index)}
              className={styles.row}
              highlighted={selectedIndex === index}
            >
              {itemIcon(item)}
              <Typography variant="body1" color="textPrimary">
                {itemTitle(item)}
              </Typography>
            </Item>
          ))}
        </div>
      </Content>
    </Command>
  )
}

export default observer(AccountCommand)

const useStyles = makeStyles((theme: Theme) => ({
  list: {},
  row: {
    height: 55,
    color: theme.palette.text.primary,
  },
  icon: {
    marginRight: 16,
  },
}))
