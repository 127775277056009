import { Theme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles/withStyles'

/**
 * @deprecated Use theme styles directly instead
 */
export const global: { [key in 'popover']: (theme: Theme) => CSSProperties } = {
  popover: (theme) => ({
    background: theme.palette.op.background.popover,
    boxShadow: theme.palette.op.shadow.popup,
    borderRadius: theme.palette.op.borderRadius.popup,
  }),
}

/**
 * @deprecated
 */
export const colors = {
  primary: '#232ed1',
  white: '#fff',
  black: '#000',
  blue: '#232ed1',
  red: '#db3232',
  pink: '#ff0088',
  purple: '#880dff',
  lightBlue: '#edf3fa',
  purplishBlue: '#3d11c5',
  veryLightGray: '#f2f2f2',
  lightGray: '#ddd',
  mediumLightGray: '#bfbfbf',
  mediumGray: '#878787',
  mediumDarkGray: '#404040',
  darkGray: '#202020',
  grayBackground: '#f8fafd',
  separatorColor: '#d3d3d3',
  dolphinGray: '#5e647a',
  blue600T: '#007fff',

  green: '#00b997',
  green800T: '#00865a',

  secondaryBlue: '#8adeff',
  secondaryYellow: '#ffc146',
  secondaryRed: '#e85c5c',

  tertiaryColor1: '#c9d9e8',
  tertiaryColor2: '#949eab',
  tertiaryColor3: '#6e737d',

  // Palette
  spaceGray100: '#717d92',
  spaceGray200: '#627088',
  spaceGray300: '#596780',
  spaceGray400: '#4e5d78',
  spaceGray500: '#455571',
  spaceGray600: '#364766',
  spaceGray700: '#283a5b',
  spaceGray800: '#14284b',
  spaceGray900: '#0a1f44',

  gray100: '#fafbfb',
  gray200: '#f7f8f9',
  gray300: '#f1f2f4',
  gray400: '#e1e4e8',
  gray500: '#c9ced6',
  gray600: '#b0b7c3',
  gray700: '#a7aebb',
  gray800: '#98a1b1',
  gray900: '#8a94a6',

  blue100: '#f8fbff',
  blue200: '#eef7ff',
  blue300: '#dceeff',
  blue400: '#b3daff',
  blue500: '#83c3fe',
  blue600: '#4ba7fe',
  blue700: '#0284fe',
  blue800: '#026dd6',
  blue900: '#01408f',

  red100: '#fff8f8',
  red200: '#ffeaea',
  red300: '#ffc2c2',
  red400: '#ff8d8d',
  red500: '#ff5d5d',
  red600: '#f03d3d',
  red700: '#dd2727',
  red800: '#bd0303',
  red900: '#a50000',

  green100: '#eafcf7',
  green200: '#cff8eb',
  green300: '#92eccf',
  green400: '#6ddfba',
  green500: '#3ed3a3',
  green600: '#22c993',
  green700: '#0bb07b',
  green800: '#00865a',
  green900: '#006242',

  yellow100: '#fffcf5',
  yellow200: '#fff6e4',
  yellow300: '#ffecc7',
  yellow400: '#ffdc99',
  yellow500: '#ffca65',
  yellow600: '#ffbb38',
  yellow700: '#ffad0d',
  yellow800: '#ff8f00',
  yellow900: '#f07300',

  googleBrandBlue: '#4285f4',
  facebookBrandBlue: '#4267B2',
  twitterBrandBlue: '#1DA1F2',
  linkedinBrandBlue: '#2867B2',
}
