import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { observer } from 'mobx-react-lite'
import useKeyStepper, { KeyMappings } from '../../../../lib/use-key-stepper'
import Controller from '../../controller'
import MentionsItem from './item'

export interface MentionsProps {
  controller: Controller
}

const Mentions = function ({ controller }: MentionsProps) {
  const styles = useStyles({})
  const filteredTargets = controller.menu.filteredMentionTargets

  const { getItemProps, selectedIndex } = useKeyStepper({
    node: controller.editor.ref.current,
    keys: { ...KeyMappings.VERTICAL, select: ['Enter', 'Tab'] },
    filter: (_, e) => !e.defaultPrevented,
    name: 'component/input-bar/input-menu/mentions',
    items: filteredTargets,
    handleSelect: controller.handleSelectMention,
    deps: [controller],
  })

  return filteredTargets.length > 0 ? (
    <>
      {filteredTargets.map((identity, index) => (
        <MentionsItem
          {...getItemProps(index)}
          key={identity.id}
          target={identity}
          highlighted={selectedIndex === index}
          style={{ height: 56 }}
        />
      ))}
    </>
  ) : null
}

export default observer(Mentions)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    command: {
      display: 'flex',
    },
    mode: {
      flex: 1,
    },
    empty: {
      textAlign: 'center',
    },
  }),
  { name: Mentions.name },
)
