import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import React, { forwardRef } from 'react'
import cx from 'classnames'
import { typography } from '@src/theme'

/**
 * @deprecated
 */
export type DeprecatedInputSize = 20 | 24 | 48

export type InputSize = 35 | 45 | DeprecatedInputSize

export interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'size' | 'label'> {
  /**
   * If `true`, the text input will fill its container.
   */
  fullWidth?: boolean

  /**
   * The height of the text input.
   *
   * Possible values (other sizes are deprecated):
   *   - `45`: "medium" size (default)
   *   - `35`: "small" size
   *
   * @default 45
   */
  size?: InputSize

  /**
   * The appearance of the text input.
   *
   * Possible values:
   *   - `outlined`: shows a border around the text input and a box-shadow on hover (default)
   *   - `text`: only the content of the input is displayed, padding is respected
   *
   * @default outlined
   */
  variant?: 'outlined' | 'text'
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, fullWidth, variant = 'outlined', size = 45, ...props }, ref) => {
    const styles = useStyles({ size, fullWidth, variant })

    return <input {...props} ref={ref} className={cx(className, styles.root)} />
  },
)

export default TextInput

const useStyles = makeStyles<
  Theme,
  Required<Pick<TextInputProps, 'size'>> & Omit<TextInputProps, 'size'>
>((theme) => ({
  root: ({ size, fullWidth, variant }) => ({
    ...(size <= 40 ? typography.footnote : typography.body),
    background: 'transparent',
    border:
      variant === 'outlined'
        ? `1.5px solid ${alpha(theme.palette.op.gray[1], 0.2)}`
        : 'inherit',
    borderRadius: 5,
    color: theme.palette.op.text.primary,
    outline: 'none',
    padding: '0 16px',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      easing: 'ease',
      duration: 240,
    }),
    height: size,
    width: fullWidth ? '100%' : 'auto',

    '&::placeholder': {
      color: alpha(theme.palette.op.text.primary, 0.4),
    },

    '&:disabled': {
      color: theme.palette.op.text.tertiary,
    },

    '&:hover:not(:disabled):not(:focus)': {
      border:
        variant === 'outlined'
          ? `1.5px solid ${alpha(theme.palette.op.gray[1], 0.4)}`
          : 'inherit',
    },

    '&:focus': {
      border:
        variant === 'outlined' ? `1.5px solid ${theme.palette.op.primary[2]}` : 'inherit',
      boxShadow:
        variant === 'outlined'
          ? `0 0 0 4px ${alpha(theme.palette.op.primary[2], 0.18)}`
          : 'inherit',
    },
  }),
}))
