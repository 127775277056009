import { makeStyles } from '@material-ui/core'
import Illustration from '@src/component/illustrations'
import AccountFlagged from '@src/component/illustrations/AccountFlagged'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { useReaction } from '../lib/hooks'
import { Card, Disclaimer } from './onboarding/common'
import Header from './onboarding/header'

const FlaggedAccount: React.FC<{}> = function () {
  const styles = useStyles({})
  const { service, history } = useAppStore()
  const isDenied = service.billing.subscription.isReviewRejected

  useReaction(
    () => ({
      isApproved: service.billing.subscription.isReviewApproved,
      isActive: service.billing.subscription.isActive,
    }),
    ({ isApproved, isActive }) => {
      if (isApproved && isActive) {
        history.push('/')
      }
    },
    { fireImmediately: true },
  )

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.main}>
        <div className={styles.content}>
          <Card
            emoji={<Illustration illustration={AccountFlagged} style={{ width: 295 }} />}
            title={isDenied ? 'Account blocked...' : 'Pending review...'}
            description={
              isDenied
                ? 'Your account was rejected after a manual review. If you think this decision is incorrect, contact support@openphone.co.'
                : 'Your account has been flagged for review. A member of our team has been notified to review your profile.'
            }
          >
            {!isDenied && (
              <Disclaimer
                emoji="⏰"
                text="Expected time to review is less than 24 hours. We will notify you via email as soon as our review is done."
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default observer(FlaggedAccount)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    background: theme.palette.op.background.primary,
  },
  main: {
    flex: 1,
    paddingBottom: '3em',
    paddingTop: 120,
    overflow: 'auto',

    '@media (max-width: 520px)': {
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0em auto',
    boxSizing: 'border-box',

    "& @include media('<=phone')": {
      padding: '1em 0 3em',
    },
  },
}))
