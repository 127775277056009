// @ts-strict-ignore
import DialpadIcon from '@material-ui/icons/DialpadOutlined'
import React, { ChangeEvent, useState } from 'react'
import useStyles from './styles'

type ContainsFilterProps = {
  value: string
  onChange: (value: string) => void
}

const ContainsFilter: React.FC<ContainsFilterProps> = function ({ value, onChange }) {
  const styles = useStyles({})
  const [warning, setWarning] = useState<string>(null)

  const onValueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value.length === 1) {
      setWarning('Too short')
    } else if (value.length > 4) {
      setWarning('Too long')
    } else {
      setWarning(null)
    }
    onChange(value)
  }

  return (
    <div className={styles.filter}>
      <DialpadIcon fontSize="small" className={styles.icon} />
      <input
        value={value}
        type="text"
        placeholder="Filter by digits or phrases"
        onChange={onValueChanged}
      />
      {warning && <span className={styles.warning}>{warning}</span>}
    </div>
  )
}

export default ContainsFilter
