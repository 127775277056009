import { makeStyles, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { isMac } from '../../../config'
import IconButton from '../../../component/icon-button'
import { ComposeIcon, DialIcon } from '../../../component/icons/custom'
import Header, { HeaderSpacer } from '../../header'
import Inboxes from './inboxes'

interface ConversationHeaderProps extends React.HTMLProps<HTMLDivElement> {
  compressed: boolean
}

const ConversationHeader: React.FC<ConversationHeaderProps> = function ({
  compressed,
  className,
  ...props
}) {
  const theme = useTheme()
  const styles = useStyles({})
  const { inboxes, command } = useAppStore()

  const handleNewConversation = () => {
    inboxes.selected.newConversation()
  }

  const handleCall = () => {
    command.present({ name: 'dialer' })
  }

  return (
    <div className={styles.root}>
      <Header {...props} className={cx(styles.header, className)}>
        <Inboxes compressed={compressed} />
        <HeaderSpacer />
        <div id="inbox-actions" className={styles.actions}>
          <IconButton
            id="dial-button"
            size="medium"
            color={theme.palette.op.secondary.green2}
            tabIndex={0}
            onClick={handleCall}
            title="Make a Call"
            shortcut="C"
            style={{ marginRight: 15 }}
            icon={<DialIcon width={19} height={19} />}
          />
          <IconButton
            id="message-button"
            size="medium"
            color={theme.palette.op.secondary.green2}
            tabIndex={0}
            icon={<ComposeIcon />}
            onClick={handleNewConversation}
            title="Send a Message"
            shortcut="N"
          />
        </div>
      </Header>
    </div>
  )
}

export default observer(ConversationHeader)

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  header: {
    padding: '0 1rem 0 1.5rem',
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  avatars: {
    marginLeft: 40,
  },
  actions: {
    display: 'flex',
  },
}))
