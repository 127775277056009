// @ts-strict-ignore
import { ContactTemplateItem } from './contact-template-item'
import { colors } from '../../theme'

export const colorForOption = (
  name: string,
  templateItem: ContactTemplateItem,
): string => {
  let option = templateItem.options.find((opt) => opt.name === name)
  return option?.color ?? colors.veryLightGray
}

export function fullName(entity: { firstName: string; lastName: string }): string {
  if (!entity) {
    return null
  }
  return [entity.firstName, entity.lastName].filter((name) => name).join(' ')
}

import { AvailabilitySchedule, PhoneNumber } from '../../types'
import config from '../../config'
import { compareString, toQueryString } from '../../lib'

export const friendlySchedule = (schedule: AvailabilitySchedule): string => {
  if (!schedule) {
    return 'Closed'
  } else if (schedule.start === schedule.end) {
    return 'Open all day'
  }
  const formatted = (time: string) => {
    if (time === '0000') {
      return 'midnight'
    }
    let [hour, minute] = [parseInt(time.slice(0, 2)), parseInt(time.slice(2))]
    const am = hour < 12

    if (hour > 12) {
      hour -= 12
    }

    return `${hour}:` + `${minute}`.padStart(2, '0') + (am ? 'am' : 'pm')
  }
  return `${formatted(schedule.start)} to ${formatted(schedule.end)}`
}

export const thumbnailUrl = (
  url: string,
  opts: { width?: number; height?: number; quality?: number },
): string => {
  if (url?.startsWith(config.STATIC_URL)) {
    const query = toQueryString(opts)
    return `${config.FILES_URL}${url.substr(config.STATIC_URL.length)}${query}`
  }
  return url
}

export const buffer = <T>(
  handler: (objs: T[]) => void,
  opts: { duration: number; maxSize?: number } = { duration: 0 },
) => {
  let timeout = null
  let objects = []
  return function (obj: T) {
    clearTimeout(timeout)
    objects.push(obj)
    if (opts.maxSize && objects.length >= opts.maxSize) {
      handler(objects)
      objects = []
    } else {
      timeout = setTimeout(() => {
        handler(objects)
        objects = []
      }, opts.duration)
    }
  }
}

export const compareByName = (
  a: { name: string; sortName?: string },
  b: { name: string; sortName?: string },
): number =>
  compareString(a.sortName ?? a.name, b.sortName ?? b.name, { caseInsensitive: true })
