import { getEmojiVariation } from '@src/lib/emoji'
import { runInAction } from 'mobx'
import Service from './index'

export default class EmojiService {
  constructor(private readonly root: Service) {}

  get skinTone() {
    return this.root.user.current?.settings?.preferences.defaultSkinTone
  }

  setSkinTone(value: string | undefined) {
    const settings = this.root.user.current?.settings

    if (!settings) {
      return
    }

    runInAction(() => {
      settings.preferences.defaultSkinTone = value
    })
  }

  getEmojiWithSkinTone(emoji: string): string {
    const variation = this.skinTone

    return variation ? getEmojiVariation(emoji, variation) : emoji
  }
}
