import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'

interface CommandFooterProps {
  actions?: ReactNode
}

const CommandFooter: React.FC<CommandFooterProps> = function ({ actions, children }) {
  const styles = useStyles({})
  return (
    <div className={styles.footer} id="commandFooter">
      <div className={styles.main}>{children}</div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}

export default CommandFooter

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    padding: '16px 2rem',
    display: 'flex',
    alignItems: 'flex-start',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
    transition: theme.transitions.create(['box-shadow'], {
      duration: 200,
      easing: 'ease',
    }),
  },
  main: {
    flex: '1 0 auto',
  },
  actions: {
    marginLeft: 15,
  },
}))
