// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import Service from '.'
import { formatDate } from '../lib'
import { NotFoundError } from '../lib/api/error-handler'
import { AvailablePhoneNumber, SearchPhoneNumberParams } from '../types'
import { EntityPhoneNumber, IEntityPhoneNumber, PersistedCollection } from './model'
import { EntityPhoneNumberRepository } from './worker/repository'

export default class EntityPhoneNumberStore {
  collection: PersistedCollection<EntityPhoneNumber, EntityPhoneNumberRepository>

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('entityPhoneNumber'),
      classConstructor: () => new EntityPhoneNumber(service),
    })

    makeAutoObservable(this, {})
  }

  fetch() {
    this.collection.performQuery((repo) => repo.all())
    return this.service.transport.account.admin
      .phoneNumbers()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
  }

  update(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .updatePhoneNumber(phoneNumber.serialize())
      .then(this.collection.load)
  }

  updateSettings(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .updatePhoneNumberSettings(phoneNumber.id, phoneNumber.serialize().settings)
      .then(this.collection.load)
  }

  toggleInternational(phoneNumber: EntityPhoneNumber): Promise<IEntityPhoneNumber> {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .toggleInternational(phoneNumber.id, phoneNumber.settings.international.enabled)
      .then(this.collection.load)
      .then((objs) => objs[0])
  }

  addUser(phoneNumber: EntityPhoneNumber, userId: string) {
    this.collection.put(phoneNumber)
    const user = phoneNumber.users.find((u) => u.id === userId)
    return this.service.transport.account.admin
      .addPhoneNumberUser(phoneNumber.id, userId, user.role)
      .then(this.collection.load)
  }

  removeUser(phoneNumber: EntityPhoneNumber, userId: string) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .removePhoneNumberUser(phoneNumber.id, userId)
      .then(this.collection.load)
  }

  reassign(phoneNumber: EntityPhoneNumber, ownerId: string) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .reassignPhoneNumber(phoneNumber.id, ownerId)
      .then(this.collection.load)
  }

  search(params: SearchPhoneNumberParams): Promise<AvailablePhoneNumber[]> {
    return this.service.transport.account.phoneNumbers.available(params)
  }

  buy(number: string, assignTo?: string): Promise<EntityPhoneNumber> {
    return this.service.transport.account.admin
      .buyPhoneNumber(number, assignTo)
      .then(this.collection.load)
      .then((objs) => objs[0])
  }

  fetchIvr(phoneNumber: EntityPhoneNumber) {
    return this.service.transport.communication.ivr
      .get(phoneNumber.id)
      .then(
        action((res) => {
          phoneNumber.ivr = res
          this.collection.put(phoneNumber)
        }),
      )
      .catch(
        action((e) => {
          if (e instanceof NotFoundError) {
            phoneNumber.ivr = null
            this.collection.put(phoneNumber)
            return
          }
          throw e
        }),
      )
  }

  setIvr(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.communication.ivr.set(phoneNumber.serialize().ivr)
  }

  deleteIvr(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.communication.ivr.delete(phoneNumber.id)
  }

  setVoicemail(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.recording
      .create({
        url: phoneNumber.voicemailUrl,
        type: 'voicemail',
        name: `Recording ${formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}`,
      })
      .then(
        action((recording) => {
          phoneNumber.voicemailId = recording.id
          return this.update(phoneNumber)
        }),
      )
  }

  setAwayVoicemail(phoneNumber: EntityPhoneNumber) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.recording
      .create({
        url: phoneNumber.awayVoicemailUrl,
        type: 'away-voicemail',
        name: `Recording ${formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}`,
      })
      .then(
        action((recording) => {
          phoneNumber.awayVoicemailId = recording.id
          return this.update(phoneNumber)
        }),
      )
  }

  delete(phoneNumber: EntityPhoneNumber) {
    this.collection.delete(phoneNumber)
    return this.service.transport.account.admin.deletePhoneNumber(phoneNumber.id)
  }
}
