import { makeAutoObservable } from 'mobx'
import Service from '.'
import { Collection, Participant } from './model'

export default class ParticipantStore {
  readonly collection = new Collection<Participant>({
    idKey: 'phoneNumber',
    bindElements: true,
  })

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get(phoneNumber: string) {
    return this.collection.get(phoneNumber)
  }

  getOrCreate(phoneNumber: string) {
    if (!this.collection.has(phoneNumber)) {
      this.collection.put(new Participant(this.root, phoneNumber))
    }
    return this.get(phoneNumber)
  }
}
