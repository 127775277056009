import React, { HTMLAttributes } from 'react'

const CheckboxActivePart = ({ id, ...props }: HTMLAttributes<HTMLOrSVGElement>) => {
  const filterId = `checkbox_activefull-a-${id}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <filter id={filterId} colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="20" height="20" />
        <path
          fill="currentColor"
          d="M5,2 L15,2 C16.6568542,2 18,3.34314575 18,5 L18,15 C18,16.6568542 16.6568542,18 15,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,5 C2,3.34314575 3.34314575,2 5,2 Z"
        />
        <g filter={`url(#${filterId})`} transform="translate(2 2)">
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M11.5,7.125 C11.9832492,7.125 12.375,7.51675084 12.375,8 C12.375,8.44607614 12.0412005,8.81418923 11.6097581,8.86818251 L11.5,8.875 L4.5,8.875 C4.01675084,8.875 3.625,8.48324916 3.625,8 C3.625,7.55392386 3.95879954,7.18581077 4.39024186,7.13181749 L4.5,7.125 L11.5,7.125 Z"
          />
        </g>
      </g>
    </svg>
  )
}
export default CheckboxActivePart
