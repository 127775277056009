// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

type UnreadColor = 'red' | 'blue' | 'gray'

interface UnreadProps {
  color?: UnreadColor
  count: number
}

const Unread: React.FC<UnreadProps> = function ({ color = 'red', count }) {
  const styles = useStyles({ color })
  return count > 0 ? <div className={styles.root}>{count}</div> : null
}

export default Unread

const useStyles = makeStyles<Theme, Partial<UnreadProps>>((theme) => ({
  root: ({ color: clr }) => ({
    backgroundColor: getColor(theme, clr),
    padding: '0 5px',
    borderRadius: 20,
    fontSize: 9,
    fontWeight: 750,
    minWidth: 16,
    height: 16,
    marginLeft: 15,
    color: theme.palette.op.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '10px',
  }),
}))

const getColor = (theme: Theme, clr: UnreadColor): string => {
  switch (clr) {
    case 'red':
      return theme.palette.op.secondary.red1
    case 'blue':
      return theme.palette.op.secondary.blue
    case 'gray':
      return theme.palette.op.gray[3]
  }
}
