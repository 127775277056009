import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import Logo from '../../component/logo'

type HeaderProps = {}

const Header: React.FC<HeaderProps> = function ({}) {
  const { service, signOut } = useAppStore()
  const styles = useStyles({})

  return (
    <div className={styles.root}>
      <Logo />
      {service.auth.hasSession && (
        <Button variant="text" size="small" onClick={() => signOut()}>
          Sign out
        </Button>
      )}
    </div>
  )
}

export default observer(Header)

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 32,
    marginBottom: '2em',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,

    '@media (max-width: 520px)': {
      position: 'initial',
      marginBottom: 0,
    },
  },
}))
