// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { isValidEmail } from '@openphone/lib'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import Key from '../../../component/keyboard-key'
import { formatted } from '../../../lib/phone-number'
import { fuzzySearch } from '../../../lib/search'
import { highlight } from '../../../lib/string'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { EntityPhoneNumber, Member } from '../../../service/model'
import { SharedUserRole } from '../../../types'
import { Command } from '../common-v2'
import Content from '../common/content'
import CommandFooter from '../common/footer'
import CommandHeader from '../common/header'
import CommandItem from '../common/item'
import TextField from '../common/textfield'
import { AppAvatar } from '@src/app/components'

export type OnInvite = (id: string, email: string, role: SharedUserRole) => void

export interface SharePhoneNumberCommandProps {
  phoneNumber: EntityPhoneNumber
  onInvite: OnInvite
}

const SharePhoneNumberCommand: React.FC<SharePhoneNumberCommandProps> = function ({
  phoneNumber,
  onInvite,
}) {
  const styles = useStyles({})
  const { command, service, toast } = useAppStore()
  const [input, setInput] = useInputState('')
  const members = service.member.collection

  const items = useMemo(() => {
    const activeMembers = members.list.filter((member) => {
      const alreadyInvited = phoneNumber.users.map((u) => u.id).includes(member.id)
      return member.status !== 'invited' && !alreadyInvited
    })
    if (!input) {
      return activeMembers
    } else {
      return fuzzySearch(activeMembers, input, ['firstName', 'lastName', 'email'])
    }
  }, [members, input, phoneNumber])

  const handleSelect = (member: Member) => {
    if (member) {
      command.present({ name: 'share phone number role', member, email: null, onInvite })
    } else if (!isValidEmail(input)) {
      toast.show({
        message: "Email address is invalid or user doesn't exist.",
      })
    } else {
      command.present({
        name: 'share phone number role',
        member: null,
        email: input,
        onInvite,
      })
    }
  }

  const { getItemProps, selectedIndex } = useKeyStepper({
    items,
    name: 'command/share-number',
    handleSelect,
  })

  return (
    <Command>
      <CommandHeader title={`Share ${formatted(phoneNumber.number)}`} />
      <TextField
        autoFocus
        value={input}
        onChange={setInput}
        placeholder="Type a name or an email"
      />
      <Content>
        <div className={styles.list}>
          {items.map((item, index) => (
            <CommandItem
              key={item.id}
              className={styles.row}
              {...getItemProps(index)}
              highlighted={index === selectedIndex}
            >
              <AppAvatar identity={item} size={20} className={styles.avatar} />
              <Typography
                variant="body2"
                color="textPrimary"
                dangerouslySetInnerHTML={{ __html: highlight(item.name, input) }}
              />
              <div className={styles.spacer} />
              <Typography
                variant="caption"
                color="textSecondary"
                dangerouslySetInnerHTML={{ __html: highlight(item.email, input) }}
              />
            </CommandItem>
          ))}
        </div>
      </Content>
      <CommandFooter>
        <Typography variant="caption" color="textSecondary">
          Press <Key name="Enter" /> to invite.
        </Typography>
      </CommandFooter>
    </Command>
  )
}

export default observer(SharePhoneNumberCommand)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  list: {
    marginBottom: 10,
  },
  row: {
    height: 45,
  },
  avatar: {
    marginRight: '1rem',
  },
  spacer: {
    flex: '1',
  },
}))
