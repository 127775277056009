import { useEffect, useRef } from 'react'

function stopPropagation(event: KeyboardEvent) {
  const navigationKeys = [
    'Tab',
    'Space',
    'Enter',
    'ArrowRight',
    'ArrowLeft',
    'ArrowDown',
    'ArrowUp',
  ]
  if (navigationKeys.includes(event.code)) {
    event.stopPropagation()
  }
}

export default function usePreventKeyboardShortcut<T extends HTMLElement>() {
  const rootRef = useRef<T | null>(null)

  useEffect(() => {
    rootRef.current?.addEventListener('keydown', stopPropagation, {})
    return () => {
      rootRef.current?.removeEventListener('keydown', stopPropagation)
    }
  }, [])

  return rootRef
}
