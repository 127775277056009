import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { typography } from '../theme'

const Dialog = withStyles((theme) => ({
  paper: {
    minWidth: '460px',
    background: theme.palette.op.background.popover,
    boxShadow: `0 8px 88px 0 rgba(0, 0, 0, 0.45),
      inset 0 0 0 1.5px ${theme.palette.op.border.common}`,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    top: 'calc(100vh / 5)',
    borderRadius: 10,
    margin: '32px 0',
  },
}))(MuiDialog)

const DialogTitle = withStyles((theme) => ({
  root: {
    padding: '2rem 2rem 0px',
  },
}))(MuiDialogTitle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '4px 2rem 5px',
  },
}))(MuiDialogContent)

const DialogContentText = withStyles((theme) => ({
  root: {
    padding: '8px 0',
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
}))(MuiDialogContentText)

const DialogActions = withStyles((theme) => ({
  root: {
    padding: '24px 2rem 25px',
  },
}))(MuiDialogActions)

export default Dialog
export { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText }
