import Table from '@material-ui/core/Table'
import MuiTableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core/styles'
import { typography } from '../theme'

const TableCell = withStyles((theme) => ({
  root: {
    padding: '16px 0',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  head: {
    ...typography.caption1,
    color: theme.palette.op.text.tertiary,
    paddingBottom: 6,
    border: 'none',
  },
}))(MuiTableCell)

export { Table, TableCell, TableHead, TableBody, TableRow }
