// @ts-strict-ignore
import { debounce } from '@material-ui/core'
import { action, computed, makeObservable, observable, override, reaction } from 'mobx'
import { Subject } from 'rxjs'
import AppStore from '../store'
import config from '../../config'
import { EmojiPickerProps } from '../../component/emoji-picker'
import { Controller, Message, Selection, SlateEditor } from '../../component/input-bar'
import { fileType } from '../../lib/file'
import {
  DecodableMessageMedia,
  Identity,
  MessageMedia,
  Model,
  Snippet,
} from '../../service/model'
import { CodableController } from '../controller-registry'

export type Draft = Partial<Message> & { readonly messageSerialized?: string }

export interface SerializedInputBar {
  readonly draft: Draft | null
  readonly selection: Selection | null
  readonly modelId: string
}

export default abstract class InputBarController<T extends Model>
  extends Controller
  implements CodableController<SerializedInputBar>
{
  abstract model: T

  readonly giphyApiKey = config.GIPHY_API_KEY
  readonly states$ = new Subject<SerializedInputBar | null>()

  constructor(protected app: AppStore, slateEditor: SlateEditor) {
    super(slateEditor)

    makeObservable(this, {
      giphyApiKey: observable,
      messageMedia: computed,
      snippets: computed,
      mentionTargets: computed,
      saveDraft: action.bound,
      loadDraft: action.bound,
      openMedia: action.bound,
      handleNewSnippet: action.bound,
      handleEditSnippet: action.bound,
      serialize: action.bound,
      deserialize: action.bound,
      clear: override,
    })

    this.disposeBag.add(
      reaction(
        () => this.editor.focused,
        (currentFocused) => {
          if (!currentFocused) {
            this.saveDraft()
          }
        },
        { name: `SaveDraftOnEditorBlur@${this.id}` },
      ),
      reaction(
        () => [this.message, this.selection],
        debounce(() => this.saveDraft(), 3000),
        { name: `SaveAsDraft@${this.id}` },
      ),
    )
  }

  get messageMedia(): MessageMedia[] {
    return this.media.map((m) => new MessageMedia(this.app.service, m))
  }

  get snippets(): Snippet[] {
    return this.app.service.snippet.collection.list
  }

  get mentionTargets(): Identity[] {
    const org = this.app.service.organization.current
    const group = this.app.inboxes.selected?.phoneNumber?.group
    const groups = this.app.workspace.groups

    return [
      ...(org ? [org] : []),
      ...(group ? [group] : []),
      ...groups,
      ...this.app.service.member.collection.list,
    ]
  }

  openEmojiPicker(props: Omit<EmojiPickerProps, 'open'>) {
    this.app.showEmojiPicker(props)
  }

  saveDraft() {
    this.states$.next(this.serialize())
  }

  loadDraft({ message, media }: Draft) {
    this.message = message ? message : []
    this.media = media ? [...media] : []
    this.debug('draft loaded (message: %O) (media: %O)', message, media)
  }

  handleNewSnippet() {
    const sharedWith = [this.app.service.user.current.id]
    const groupId = this.app.inboxes.selected.phoneNumber?.groupId
    if (groupId) {
      sharedWith.push(groupId)
    }
    this.editSnippet(new Snippet(this.app.service, { isNew: true }))
  }

  handleEditSnippet(snippet: Snippet) {
    this.editSnippet(snippet)
  }

  openMedia(media: DecodableMessageMedia) {
    const index = this.media.indexOf(media)
    const viewableMedia = this.messageMedia.filter((m) => {
      const type = fileType(m.type, m.name)
      return type === 'image' || type === 'video'
    })
    this.app.openMediaViewer(viewableMedia, index)
  }

  serialize(): SerializedInputBar | null {
    const draft = this.messageSerialized
      ? {
          message: this.message,
          messageSerialized: this.messageSerialized,
        }
      : null

    const { selection } = this

    return draft && selection
      ? {
          selection,
          draft,
          modelId: this.model.id,
        }
      : null
  }

  deserialize(obj: SerializedInputBar) {
    if (!obj) return
    if (obj.draft) this.loadDraft(obj.draft)
    if (obj.selection) this.selection = obj.selection
  }

  override clear() {
    super.clear()
    this.saveDraft() // erase any saved drafts
  }

  override getEmojiWithSkinTone(emoji: string): string {
    return this.app.service.emoji.getEmojiWithSkinTone(emoji)
  }

  protected editSnippet(snippet: Snippet) {
    this.app.command.present({
      name: 'edit snippet',
      snippet,
      onSave: (snippet) => {
        snippet.save()
        this.app.command.hide()
      },
      onShare: this.handleSnippetShare.bind(this),
      onDelete: (snippet) => {
        snippet.delete()
        this.app.command.hide()
      },
    })
  }

  protected handleSnippetShare(snippet: Snippet) {
    this.app.command.present({
      name: 'edit shared with',
      title: 'Share Snippet',
      subtitle: 'Share this snippet with other people or groups in your workspace.',
      ids: snippet.sharedWith,
      onSave: (sharedWith) => {
        snippet.sharedWith = sharedWith
        this.editSnippet(snippet)
        !snippet.isNew && snippet.save()
      },
    })
  }
}
