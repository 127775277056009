import Transport from '.'
import config from '../../config'
import { whitelist } from '../../lib'
import { Contact, Paginated } from '../../types'
import { IContact, IContactSettings, Note } from '../model'
import { IContactTemplateItem } from '../model/contact-template-item'
import { INote } from '../model/note'
import { HttpTransaction } from './transaction'

export default class ContactsClient {
  constructor(private transport: Transport) {}

  fetch(query: {
    since?: Date
    limit?: number
    lastId?: string
    includeDeleted?: boolean
  }): Promise<Paginated<Contact>> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.CONTACT_SERVICE_URL}contact`, query }),
    )
  }

  googleSync(code: string, redirectUri: string): Promise<any> {
    code = encodeURIComponent(code)
    redirectUri = encodeURIComponent(redirectUri)
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.CONTACT_SERVICE_URL}contact/people/sync?code=${code}&redirectUri=${redirectUri}`,
        method: 'post',
      }),
    )
  }

  createBulk(contacts: IContact[]) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.CONTACT_SERVICE_URL}contact`,
        body: contacts,
        queue: 'contact-import',
        retry: true,
      }),
    )
  }

  async update(contact: IContact): Promise<any> {
    const cont = whitelist(contact, [
      'id',
      'firstName',
      'lastName',
      'company',
      'role',
      'items',
      'location',
      'pictureUrl',
      'source',
      'sourceName',
      'userId',
      'sharedWith',
    ])
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/${contact.id}`,
        body: cont,
        queue: contact.id,
        retry: true,
      }),
    )
  }

  merge(id: string, withIds: string[]): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/${id}/merge`,
        body: withIds,
        queue: id,
        retry: true,
      }),
    )
  }

  delete(id: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.CONTACT_SERVICE_URL}contact/${id}`,
        queue: id,
        retry: true,
      }),
    )
  }

  shareBulk(ids: string[], sharedIds: string[]): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/share`,
        body: { contactIds: ids, sharedIds },
        queue: 'bulk-contact-share',
        retry: true,
      }),
    )
  }

  deleteBulk(ids: string[]): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.CONTACT_SERVICE_URL}contact`,
        body: ids,
        queue: 'contact-delete',
        retry: true,
      }),
    )
  }

  note = {
    put: (contactId: string, note: INote): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note`,
          body: note,
          queue: contactId,
          retry: true,
        }),
      )
    },

    deleteNote: (contactId: string, noteId: string): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note/${noteId}`,
          queue: contactId,
          retry: true,
        }),
      )
    },
  }

  template = {
    fetch: (): Promise<IContactTemplateItem[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.CONTACT_SERVICE_URL}contact/template` }),
      )
    },

    put: (item: IContactTemplateItem): Promise<IContactTemplateItem> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.CONTACT_SERVICE_URL}contact/template/${item.id}`,
          body: item,
          queue: item.id,
          retry: true,
        }),
      )
    },

    create: (item: IContactTemplateItem): Promise<IContactTemplateItem> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/template`,
          body: item,
          queue: item.id,
          retry: true,
        }),
      )
    },

    delete: (item: IContactTemplateItem): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/template/${item.id}`,
          queue: item.id,
          retry: true,
        }),
      )
    },
  }

  settings = {
    fetch: () => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.CONTACT_SERVICE_URL}contact/settings`,
        }),
      )
    },

    resync: (settings: IContactSettings) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/people/sync?source=${settings.source}`,
          retry: false,
        }),
      )
    },

    delete: (id: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/settings/${id}`,
          retry: true,
        }),
      )
    },
  }
}
