import React, { CSSProperties } from 'react'
import cx from 'classnames'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'

export interface BadgeProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'purple'
    | 'purple-light'
    | 'gray'
  icon?: React.ReactNode
  label: string
  style?: CSSProperties
  className?: string
}

const Badge: React.FC<BadgeProps> = ({
  variant = 'primary',
  icon,
  label,
  style,
  className,
}) => {
  const styles = useStyles({})
  return (
    <span className={cx(styles.root, styles[variant], className)} style={style}>
      {icon} {label}
    </span>
  )
}

export default Badge

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    padding: '5px 7px',
    height: 24,
    borderRadius: 5,
    fontSize: 11,
    fontWeight: 550,
    backgroundColor: theme.palette.op.tag.purple.bg,
    color: theme.palette.op.tag.purple.text,
  },
  secondary: {
    backgroundColor: theme.palette.op.gray[3],
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  success: {
    backgroundColor: alpha(theme.palette.op.secondary.green2, 0.12),
    color: theme.palette.op.secondary.green2,
  },
  danger: {
    backgroundColor: alpha(theme.palette.op.secondary.red2, 0.08),
    color: theme.palette.op.secondary.red2,
  },
  warning: {
    backgroundColor: theme.palette.op.tag.orange.bg,
    color: theme.palette.op.tag.orange.text,
  },
  purple: {
    backgroundColor: theme.palette.op.primary[1],
    color: theme.palette.op.white,
  },
  'purple-light': {
    backgroundColor: alpha(theme.palette.op.primary[3], 0.1),
    color: theme.palette.op.primary[3],
  },
  gray: {
    backgroundColor: theme.palette.op.hover.primary,
    color: theme.palette.op.gray[2],
  },
}))
