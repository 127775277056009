// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import { Item } from '../controller'
import Row from './row'
import { ItemProps } from '.'

export type CommentsProps<I extends Item> = Required<
  Pick<ItemProps<I>, 'controller' | 'item' | 'renderComments'>
>

const Comments = function <I extends Item>({
  controller,
  item,
  renderComments,
}: CommentsProps<I>) {
  const styles = useStyles({ item })

  return (
    <Row controller={controller} item={item}>
      <div className={cx(styles.root, item.isOutgoing ? styles.rtl : null)}>
        {renderComments(item)}
      </div>
    </Row>
  )
}

export default Comments

const useStyles = makeStyles<Theme, Partial<CommentsProps<Item>>>(
  (theme) => ({
    root: ({ item }) => ({
      width: item.display.dimensions.threadWidth,
      marginTop: item.display.dimensions.threadMargin.top,
      marginBottom: item.display.dimensions.threadMargin.bottom,
      marginLeft: item.display.dimensions.threadMarginHorizontal,
    }),
    rtl: ({ item }) => ({
      marginLeft: 'unset',
      marginRight: item.display.dimensions.threadMarginHorizontal,
    }),
  }),
  { name: Comments.name },
)
