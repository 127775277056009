import { useRef, useState } from 'react'
import { Lambda } from '../types'

export default function useCountdown(): [number, (duration: number) => void, Lambda] {
  const [countdown, setCountdown] = useState(0)
  const intervalRef = useRef<any>()

  const start = (duration: number) => {
    setCountdown(duration)
    clearInterval(intervalRef.current)

    let c = duration
    intervalRef.current = setInterval(() => {
      setCountdown((c) => {
        if (c <= 1) {
          clearInterval(intervalRef.current)
        }
        return c - 1
      })
    }, 1000)
  }

  const reset = () => {
    setCountdown(0)
  }

  return [countdown, start, reset]
}
