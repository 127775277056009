import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
export interface TabsProps {
  options: { name: string; value: string }[]
  value: string
  onChange: (tab: string) => void
}

const Tabs: React.FC<TabsProps> = function ({ options, value, onChange }) {
  const styles = useStyles({})

  return (
    <ul className={styles.root}>
      {options.map((option) => (
        <Typography
          key={option.value}
          component="li"
          variant="footnote"
          onClick={() => onChange(option.value)}
          className={cx(styles.item, value === option.value && styles.selected)}
          color="inherit"
        >
          {option.name}
        </Typography>
      ))}
    </ul>
  )
}

export default Tabs

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    listStyle: 'none',
  },
  item: {
    marginRight: 2,
    borderRadius: 5,
    padding: '6px 10px',
    cursor: 'pointer',
    color: theme.palette.op.text.secondary,

    '&:hover': {
      color: theme.palette.op.text.primary,
    },
  },
  selected: {
    background: theme.palette.op.hover.primary,
    color: theme.palette.op.text.primary,
  },
}))
