import AppStore from '@src/app/store'
import { toE164 } from '@src/lib/phone-number'

export default abstract class ProtocolHandler {
  /**
   * The protocol for this handler (excluding the colon).
   */
  abstract readonly protocol: string

  /**
   * Whether this protocol handler works on web.
   */
  abstract readonly web: boolean

  /**
   * Whether this protocol handler works on Electron.
   */
  abstract readonly desktop: boolean

  constructor(protected app: AppStore) {}

  abstract handle(url: URL): void

  protected normalizePhoneNumber(number: string): string {
    return toE164(decodeURIComponent(number))
  }
}
