// @ts-strict-ignore
import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'

interface KeyProps extends React.HTMLAttributes<HTMLSpanElement> {
  name?: string
  dark?: boolean
}

const Key: React.FC<KeyProps> = function ({ dark, name, className, ...props }) {
  const styles = useStyles({ dark })
  if (name.toLowerCase() === 'shift') {
    name = '⇧'
  } else if (name.toLowerCase() === 'meta') {
    name = '⌘'
  }
  return (
    <span {...props} className={cx(styles.root, className)}>
      {name}
    </span>
  )
}

export default Key

const useStyles = makeStyles<Theme, KeyProps>((theme) => ({
  root: {
    color: theme.palette.op.match({
      dark: alpha(theme.palette.op.white, 0.6),
      light: alpha(theme.palette.op.black, 0.8),
    }),
    display: 'inline-block',
    padding: '1px 4px',
    // prettier-ignore
    background: props => props.dark 
      ? theme.palette.op.match({
        dark: alpha(theme.palette.op.black, 0.55),
        light: alpha(theme.palette.op.white, 0.55),
      })
      : theme.palette.op.match({
        dark: alpha(theme.palette.op.black,0.18),
        light: alpha(theme.palette.op.white, 0.25),
      }),
    borderRadius: 3,
    fontWeight: 500,
  },
}))
