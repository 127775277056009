import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { PhoneNumber } from '../../service/model'
import { fromNowV2 } from '../../lib'
import PickDuration from './pick-duration'

export interface MutePhoneNumberProps {
  phoneNumber: PhoneNumber
}

const MutePhoneNumber: React.FC<MutePhoneNumberProps> = function ({ phoneNumber }) {
  const { command, toast } = useAppStore()

  const handleSet = (date: Date) => {
    command.hide()
    phoneNumber.mute(date)
    toast.show({
      message: `Muted ${fromNowV2(date)}.`,
    })
  }

  return (
    <PickDuration
      title={`Muting ${phoneNumber.formattedNumber}`}
      description="Turn off calls and notifications for a period of time"
      onSet={handleSet}
    />
  )
}

export default MutePhoneNumber
