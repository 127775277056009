import { useCallback, useEffect, useState } from 'react'

interface UseAsyncScriptOptions {
  scriptCreator: () => HTMLScriptElement
  getInitialState?: () => boolean
  position?: 'head' | 'body'
}

export function useAsyncScript({
  scriptCreator,
  getInitialState,
  position = 'head',
}: UseAsyncScriptOptions) {
  const [loaded, setLoaded] = useState(getInitialState ?? false)

  const appendScript = useCallback(
    (script: HTMLScriptElement) => {
      if (position === 'head') {
        document.head.appendChild(script)
      }

      document.body.appendChild(script)
    },
    [position],
  )

  useEffect(() => {
    if (loaded) return
    const script = scriptCreator()
    script.onload = () => {
      setLoaded(true)
    }
    appendScript(script)
  }, [scriptCreator, loaded])

  return { loaded }
}
