// @ts-strict-ignore
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import React, { createRef } from 'react'
import {
  Descendant,
  Editor,
  Node,
  Point,
  Range,
  Selection,
  Transforms,
  createEditor,
} from 'slate'
import { ReactEditor, withReact } from 'slate-react'
import { HistoryEditor, withHistory } from 'slate-history'
import { DisposeBag } from '../../lib/dispose'
import shortId from '../../lib/short-id'
import { START_SELECTION, Serializer, createTextSelection } from './lib'
import { withCustom } from './plugin'
import { CustomSlateText } from './elements'

export interface TextTarget {
  readonly type: 'command' | 'emoji' | 'mention'
  readonly text: string
  readonly selection: Readonly<Selection>
}

export default abstract class EditorController {
  /**
   * Whether or not the editor has focus.
   */
  focused: boolean = false

  /**
   * The Slate editor value.
   *
   * This is used directly by the Slate context. It is modified by the
   * `handleChange` callback when the user inputs text or other Slate values.
   */
  value: readonly Readonly<Descendant>[] = [
    { type: 'paragraph', children: [{ text: '' }] },
  ]

  /**
   * The most recent selection within the Slate editor.
   *
   * This is NOT used directly by the Slate context. Slate maintains its own
   * selection descriptor which can only be changed with Slate transforms. The
   * difference between the two is that Slate's internal selection is `null`
   * when the editor is not focused.
   */
  _selection: Readonly<Selection> = null

  /**
   * The current "text target" identified by the current selection.
   *
   * Text targets are entities that we recognize within Slate values such as
   * commands, mentions, and emoji slugs. If `null`, no recognizable target
   * exists at the current selection.
   *
   * @see `handleTargetChange`
   */
  target: TextTarget | null = null

  readonly id = shortId()
  readonly debug = Debug(`op:editor:@${this.id}`)
  readonly ref: React.MutableRefObject<HTMLElement> = createRef()
  readonly commandRegex = /^\/\w*$/
  readonly emojiSlugRegex = /^:[_+\w]+:?$/
  readonly mentionRegex = /^@\S[\s\w]*$/

  protected blurTimer?: number
  protected disposeBag = new DisposeBag()

  static createEditor(): Editor {
    return withCustom(withHistory(withReact(createEditor())))
  }

  constructor(readonly editor: Editor) {
    makeObservable(this, {
      focused: observable.ref,
      value: observable.ref,
      _selection: observable.ref,
      target: observable.ref,
      ref: observable.shallow,
      valueSerialized: computed,
      rendered: computed,
      isEmpty: computed,
      blur: action.bound,
      clearTarget: action.bound,
      focus: action.bound,
      handleBlur: action.bound,
      handleChange: action.bound,
      handleCursorRange: action.bound,
      handleEnterKeyDown: action.bound,
      handleEscapeKeyDown: action.bound,
      handleFocus: action.bound,
      handleKeyDown: action.bound,
      insertMention: action.bound,
      insertText: action.bound,
      reset: action.bound,
      resetHistory: action.bound,
      resetValue: action.bound,
      resetSelection: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.value,
        (currentValue) => {
          this.debug('value change (value: %O)', currentValue)

          if (currentValue.length === 0) {
            // It is possible to set `value` to an empty array, but slate doesn't
            // like it, so reset it to an empty value that slate does like.
            this.value = [{ type: 'paragraph', children: [{ text: '' }] }]
          }
        },
        { name: 'EditorValueChange', fireImmediately: true },
      ),

      reaction(
        () => this._selection,
        (currentSelection) => {
          this.debug('selection change (selection: %O)', currentSelection)

          if (!currentSelection || !this.editor.selection) {
            HistoryEditor.withoutSaving(this.editor, () =>
              Transforms.select(this.editor, START_SELECTION),
            )
          } else if (!Range.equals(currentSelection, this.editor.selection)) {
            HistoryEditor.withoutSaving(this.editor, () =>
              Transforms.select(this.editor, currentSelection),
            )
          }
        },
        { name: 'EditorSelectionChange', fireImmediately: true },
      ),

      reaction(
        () => [this.value, this._selection] as const,
        ([, currentSelection]) => {
          if (currentSelection) this.handleCursorRange(currentSelection)
        },
        { name: 'EditorChange' },
      ),

      reaction(
        () => this.target,
        (currentTarget) => {
          this.debug('target change (target: %O)', currentTarget)
          this.handleTargetChange(currentTarget)
        },
        { name: 'TargetChange' },
      ),
    )
  }

  /**
   * Handle the behavior when special text is recognized by the editor.
   *
   * Special text can be commands, emoji slugs, and mentions.
   *
   * Commands: recognized as a forward slash (/) (if it is not immediately
   * preceded by a word character) and any word characters up to the next word
   * boundary. If `null`, the command is no longer selected in the editor or
   * has been deleted.
   *
   * Emoji slugs: recognized as a colon (:) with one or more word characters
   * and an optional ending colon. If `null`, the emoji slug is no longer
   * selected in the editor or has been deleted.
   *
   * Mentions: recognized as "at" symbol (@) with one or more word characters
   * and spaces. If `null`, the mention is no longer selected in the editor or
   * has been deleted.
   */
  abstract handleTargetChange(target: TextTarget | null): void

  /**
   * Handle the behavior when the Enter key is pressed (without any modifier keys).
   */
  abstract handleEnter(): void

  get rendered(): boolean {
    return !!this.ref.current
  }

  get selection(): Selection {
    return this.editor.selection ?? this._selection ?? START_SELECTION
  }

  set selection(selection: Selection) {
    runInAction(() => {
      this._selection = selection
    })
  }

  get valueSerialized(): string {
    return this.serializeBlocks(this.value)
  }

  get isEmpty(): boolean {
    return !this.valueSerialized
  }

  focus() {
    ReactEditor.focus(this.editor)
  }

  blur() {
    ReactEditor.blur(this.editor)
  }

  serializeBlocks(blocks: readonly Descendant[]): string {
    return new Serializer().serialize(blocks)
  }

  clearTarget() {
    if (!this.target) return
    const { selection } = this.target
    Transforms.delete(this.editor, { at: selection })
    this.target = null
  }

  updateTargetText(text: string) {
    if (!this.target) return

    const { selection } = this.target

    Transforms.select(this.editor, selection)
    Transforms.delete(this.editor, { at: selection })

    // Remove the focus part of the selection so that the cursor
    // is not moved off limits when inserting the text.
    Transforms.select(this.editor, selection.anchor)
    Transforms.insertText(this.editor, text, { at: selection.anchor })
  }

  insertText(text: string) {
    Transforms.insertText(this.editor, text, { at: this.selection })
  }

  insertMention(id: string, name: string) {
    Transforms.insertNodes(this.editor, {
      type: 'mention',
      mention: { id, name },
      children: [{ text: '' }],
    })
    Transforms.move(this.editor)
    this.insertText(' ')
  }

  startCommand() {
    const node = Node.leaf(this.editor, this.selection.focus.path)
    const target = this.matchCommand(node, this.selection.focus)

    if (target) {
      this.target = target
    } else {
      this.clearTarget()
      this.insertText('/')
    }
  }

  startMention() {
    const node = Node.leaf(this.editor, this.selection.focus.path)
    const target = this.matchMention(node, this.selection.focus)

    if (target) {
      this.target = target
    } else {
      this.clearTarget()
      this.insertText('@')
    }
  }

  handleChange(value: readonly Descendant[]) {
    this.value = value
    if (this.editor.selection) this._selection = this.editor.selection
  }

  handleFocus(event: FocusEvent): void {
    window.clearTimeout(this.blurTimer)
    this.focused = true
    this.debug('focus event from editor (focused: %O)', this.focused)
  }

  handleBlur(event: FocusEvent): void {
    const timeout = 100

    this.debug(
      'blur event from editor, waiting %Oms for re-focus event (focused: %O)',
      timeout,
      this.focused,
    )

    window.clearTimeout(this.blurTimer)
    this.blurTimer = window.setTimeout(
      action(() => {
        this.focused = false
        this.debug('no re-focus, set un-focused (focused: %O)', this.focused)
      }),
      timeout,
    )
  }

  handleCursorRange(range: Range) {
    this.target = null

    if (!Range.isCollapsed(range)) return

    const { focus: point } = range
    const { path } = point
    const node = Node.leaf(this.editor, path)

    this.target = this.findTextTarget(node, point, [
      this.matchCommand.bind(this),
      this.matchMention.bind(this),
      this.matchEmojiSlug.bind(this),
    ])
  }

  protected findTextTarget(
    node: CustomSlateText,
    point: Point,
    matchers: ((node: CustomSlateText, point: Point) => TextTarget | null)[],
  ): TextTarget | null {
    for (const matcher of matchers) {
      const target = matcher(node, point)
      if (target) return target
    }

    return null
  }

  protected matchCommand(node: CustomSlateText, point: Point): TextTarget | null {
    const { path, offset } = point
    const atIndex = node.text.lastIndexOf('/', offset - 1)

    if (atIndex >= 0) {
      const text = node.text.substring(atIndex, offset)
      const prevChar = node.text.charAt(atIndex - 1)

      if ((prevChar === '' || prevChar === ' ') && text.match(this.commandRegex)) {
        return {
          type: 'command',
          text,
          selection: createTextSelection(path, atIndex, offset),
        }
      }
    }

    return null
  }

  protected matchMention(node: CustomSlateText, point: Point): TextTarget | null {
    const { path, offset } = point
    const atIndex = node.text.lastIndexOf('@', offset - 1)

    if (atIndex >= 0) {
      const text = node.text.substring(atIndex, offset)

      if (text.match(this.mentionRegex) || text === '@') {
        return {
          type: 'mention',
          text,
          selection: createTextSelection(path, atIndex, offset),
        }
      }
    }

    return null
  }

  protected matchEmojiSlug(node: CustomSlateText, point: Point): TextTarget | null {
    const { path, offset } = point
    const atIndex = node.text.lastIndexOf(':', offset - 2)

    if (atIndex >= 0) {
      const text = node.text.substring(atIndex, offset)

      if (text.match(this.emojiSlugRegex)) {
        return {
          type: 'emoji',
          text,
          selection: createTextSelection(path, atIndex, offset),
        }
      }
    }

    return null
  }

  handleKeyDown(event: React.KeyboardEvent): void {
    // The default behavior was prevented (likely in useKeyboardShortcuts),
    // which means we shouldn't handle this input
    if (event.defaultPrevented) return

    const { key, metaKey, shiftKey, altKey, ctrlKey } = event

    this.debug('keydown (key: %O) (modifiers: %O)', key, {
      metaKey,
      shiftKey,
      altKey,
      ctrlKey,
    })

    switch (key) {
      case 'Enter':
        return this.handleEnterKeyDown(event)
      case 'Escape':
        return this.handleEscapeKeyDown()
    }
  }

  handleEnterKeyDown(event: React.KeyboardEvent) {
    if (!event.altKey && !event.shiftKey) {
      event.preventDefault()
      this.handleEnter()
    }
  }

  handleEscapeKeyDown() {
    this.blur()
  }

  reset() {
    this.resetHistory()
    this.resetValue()
    this.resetSelection()
  }

  resetHistory() {
    this.editor.history = { undos: [], redos: [] }
  }

  resetValue() {
    this.value = [{ type: 'paragraph', children: [{ text: '' }] }]
  }

  resetSelection() {
    this.selection = null
  }

  tearDown() {
    this.reset()
    this.disposeBag.dispose()
  }
}
