// @ts-strict-ignore
import Service from '.'
import { Identity } from './model'
import MainWorker from './worker/main'

export type SearchResult = {
  identity: Identity
  matchedPhoneNumber?: string
}

export default class SearchStore {
  constructor(private root: Service, private worker: MainWorker) {
    this.worker.service.search.hydrate()
  }

  async identities(
    query: string,
    limit: number = 20,
    type: 'all' | 'members' | 'contacts' = 'all',
  ): Promise<SearchResult[]> {
    const result = await this.worker.service.search.identities(query, limit, type)
    const contacts = result.filter((r) => r.t === 'contact').map((r) => r.i)
    const members = result.filter((r) => r.t === 'member').map((r) => r.i)
    await Promise.all([
      this.root.contact.collection.load(contacts),
      this.root.member.collection.load(members),
    ])
    return result.map((r) => ({
      matchedPhoneNumber: r.m,
      identity:
        r.t === 'contact'
          ? this.root.contact.collection.get(r.i.id)
          : this.root.member.collection.get(r.i.id),
    }))
  }

  companies(query: string, limit: number = 10): Promise<string[]> {
    return this.worker.service.search.companies(query, limit)
  }
}
