// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@src/component/button-v2'
import cx from 'classnames'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import {
  AddIcon,
  ArrowDownIcon,
  DNDIcon,
  GiftIcon,
  HelpIcon,
  LogOutIcon,
  MegaphoneIcon,
  MessageIcon,
  PresenceIcon,
  SettingsIcon,
  DownloadIcon,
  RetryIcon,
  EmojiIcon,
} from '../../component/icons/Tint/20/General'
import PopoverMenu, {
  FooterItem,
  HeaderItem,
  MenuItem,
  SeparatorItem,
} from '../../component/popover-menu'
import Typography from '@ui/Typography'
import config from '../../config'
import { AppAvatar } from '@src/app/components'
import analytics from '@src/lib/analytics'

interface AccountMenuButtonProps {}

const AccountMenuButton: React.FC<AccountMenuButtonProps> = function ({}) {
  const styles = useStyles({})
  const { service, command, signOut, history, inboxes, toast, update, isElectron } =
    useAppStore()
  const anchorEl = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const currentUser = service.user.current.asMember
  const isActive = currentUser?.presence?.status === 'online'
  const isSnoozed = currentUser?.presence?.snoozed
  const isStatusEmpty = !Boolean(currentUser?.presence?.text)

  const handleWorkspace = () => {
    analytics.click('Workspace settings', '/settings/members')
    setOpen(false)
    history.push('/settings/members')
  }

  const handleAccount = () => {
    analytics.click('Account settings', '/settings/account')
    setOpen(false)
    history.push('/settings/account')
  }

  const handleReferrals = () => {
    analytics.click('Referrals', '/settings/referrals')
    setOpen(false)
    history.push('/settings/referrals')
  }

  const handleStatus = () => {
    analytics.click('Set status')
    command.present({
      name: 'member status',
      presence: currentUser.presence,
    })
  }

  const handleOffline = () => {
    const { presence } = service.user.current.asMember
    analytics.click(`Set presence as ${!presence.isOnline ? 'active' : 'away'}`)
    setOpen(false)
    presence.setOnline(!presence.isOnline).catch(toast.showError)
  }

  const handleDnD = useCallback(() => {
    setOpen(false)
    analytics.click(`Turn ${isSnoozed ? 'off' : 'on'} do not disturb`)

    if (isSnoozed) {
      currentUser.presence.unsnooze().catch(toast.showError)
      toast.show({ message: 'Do not disturb turned off.' })
    } else {
      command.present({ name: 'do not disturb' })
    }
  }, [isSnoozed])

  const handleInvite = useCallback(() => {
    analytics.click('Invite team')
    setOpen(false)
    command.present({ name: 'invite member' })
  }, [])

  const handleDownload = useCallback(() => {
    analytics.click('Download software', new URL('https://www.openphone.co/downloads'))
    setOpen(false)
    window.open('https://www.openphone.co/downloads')
  }, [])

  const handleSignOut = useCallback(() => {
    analytics.click('Sign out')
    setOpen(false)
    signOut()
  }, [])

  const handleSubmitFeedback = action(async () => {
    analytics.click('Submit feedback')
    setOpen(false)
    await inboxes.submitFeedback()
  })

  const handleHelp = useCallback(() => {
    analytics.click('Help and support', new URL('https://help.openphone.co/'))
    window.open('https://help.openphone.co/')
  }, [])

  const handleWhatsNew = useCallback(() => {
    analytics.click(`What's new`, new URL('https://updates.openphone.co/'))
    window.open('https://updates.openphone.co/')
  }, [])

  const handleOpen = useCallback(() => {
    analytics.click('Open account menu')
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    analytics.click('Close account menu')
    setOpen(false)
  }, [])

  const handleCheckForUpdates = useCallback(() => {
    analytics.click('Check for updates')
    setOpen(false)
    toast.showLoading('Checking for updates...')
    update.checkForUpdate().then((available) => {
      if (!available) {
        toast.show({ message: 'Your version is up to date!' })
      } else {
        toast.hide()
      }
    })
  }, [])

  const handleInstallUpdates = useCallback(() => {
    analytics.click('Install updates')
    setOpen(false)
    update.installAndRestart()
  }, [])

  useEffect(() => {
    if (!open) {
      anchorEl.current.blur()
    }
  }, [open])

  return (
    <>
      <div
        tabIndex={-1}
        role="button"
        ref={anchorEl}
        onClick={handleOpen}
        className={styles.root}
      >
        <div className={styles.accountButton}>
          <AppAvatar identity={currentUser} />
          <Typography variant="callout" nowrap className={styles.userName}>
            {currentUser?.name}
          </Typography>
          {currentUser?.statusSymbol && (
            <span title={currentUser?.statusText} className={styles.statusEmoji}>
              {currentUser?.statusSymbol}
            </span>
          )}
        </div>
      </div>
      <PopoverMenu
        anchorEl={anchorEl.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={styles.account}>
          <AppAvatar identity={currentUser} size={40} />
          <div style={{ marginLeft: 16, overflow: 'hidden' }}>
            <Typography nowrap>{currentUser.name}</Typography>
            <div className={styles.presence}>
              {isSnoozed ? 'Do not disturb' : isActive ? 'Active' : 'Away'}
            </div>
          </div>
        </div>
        <div className={styles.statusContainer}>
          <Button
            className={cx(styles.status, isStatusEmpty && styles.statusEmpty)}
            color="textPrimary"
            variant="outlined"
            onClick={handleStatus}
          >
            <div className={styles.statusIcon}>
              {currentUser?.presence?.symbol || <EmojiIcon />}
            </div>
            <Typography nowrap variant="callout" className={styles.statusText}>
              {currentUser?.presence?.text || 'Set your status'}
            </Typography>
          </Button>
        </div>
        <MenuItem icon={<PresenceIcon />} onClick={handleOffline}>
          {isActive ? (
            <>
              Set yourself as <b>away</b>
            </>
          ) : (
            <>
              Set yourself as <b>active</b>
            </>
          )}
        </MenuItem>
        <MenuItem icon={<DNDIcon />} onClick={handleDnD}>
          <div>{isSnoozed ? 'Turn off "Do not disturb"' : 'Do not disturb'}</div>
        </MenuItem>
        <MenuItem icon={<SettingsIcon />} onClick={handleAccount}>
          Edit account
        </MenuItem>
        <MenuItem icon={<GiftIcon />} onClick={handleReferrals}>
          Referrals
        </MenuItem>
        {currentUser?.isBilling ? (
          <>
            <SeparatorItem />
            <HeaderItem>Workspace</HeaderItem>
            <MenuItem icon={<SettingsIcon />} onClick={handleWorkspace}>
              Workspace settings
            </MenuItem>
          </>
        ) : null}
        <MenuItem icon={<AddIcon />} onClick={handleInvite}>
          Invite your team
        </MenuItem>
        <SeparatorItem />
        {!isElectron && (
          <MenuItem icon={<ArrowDownIcon />} onClick={handleDownload}>
            Download
          </MenuItem>
        )}
        {update.updateAvailable ? (
          <MenuItem icon={<DownloadIcon />} onClick={handleInstallUpdates}>
            Install updates
          </MenuItem>
        ) : (
          <MenuItem icon={<RetryIcon />} onClick={handleCheckForUpdates}>
            Check for updates
          </MenuItem>
        )}
        <MenuItem icon={<HelpIcon />} onClick={handleHelp}>
          Help and support
        </MenuItem>
        <MenuItem icon={<MessageIcon />} onClick={handleSubmitFeedback}>
          Submit Feedback
        </MenuItem>
        <MenuItem icon={<MegaphoneIcon />} onClick={handleWhatsNew}>
          What's new
        </MenuItem>
        <SeparatorItem />
        <MenuItem icon={<LogOutIcon />} onClick={handleSignOut}>
          Sign out
        </MenuItem>
        <SeparatorItem />
        <FooterItem>Version {config.VERSION}</FooterItem>
      </PopoverMenu>
    </>
  )
}

export default observer(AccountMenuButton)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '7px 10px',
    marginLeft: 10,
    borderRadius: 5,
    outline: 'none',
    flex: 1,
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  list: {
    outline: 'none',
    width: 260,
  },
  accountButton: {
    display: 'flex',
    alignItems: 'center',
  },
  account: {
    outline: 'none',
    padding: '10px 14px',
    width: 246,
    display: 'flex',
  },
  userName: {
    marginLeft: 11,
  },
  presence: {
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    marginTop: 3,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  statusContainer: {
    padding: '7px 9px 10px',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    width: '100%',
    padding: 0,
    height: 38,
  },
  statusIcon: {
    width: 20,
    height: 20,
    flex: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    marginLeft: '14px',
  },
  statusText: {
    margin: '0 12px',
    fontSize: 13,
  },
  statusEmpty: {
    color: theme.palette.op.background.highlight(0.5),
  },
  statusEmoji: {
    fontSize: '1.2rem',
    marginLeft: 8,
  },
  dndValue: {
    fontSize: 12,
    marginTop: 2,
    color: theme.palette.text.secondary,
  },
}))
