import MuiListSubheader from '@material-ui/core/ListSubheader'
import Menu from '@material-ui/core/Menu'
import type { MenuProps } from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiMenuList from '@material-ui/core/MenuList'
import MuiPopover from '@material-ui/core/Popover'
import type { PopoverProps } from '@material-ui/core/Popover'
import MuiSelect from '@material-ui/core/Select'
import type { SelectProps } from '@material-ui/core/Select'
import { darken, Theme, useTheme, withStyles } from '@material-ui/core/styles'
import React from 'react'

export default withStyles((theme: Theme) => ({
  paper: {
    background: theme.palette.op.background.popover,
  },
}))(Menu)

const MenuItem = withStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    display: 'block',
    textOverflow: 'ellipsis',
  },
}))(MuiMenuItem)

const ListSubheader = withStyles((theme: Theme) => ({
  root: {
    fontSize: 12,
    outline: 'none',
    textTransform: 'uppercase',
  },
}))(MuiListSubheader)

const Select2: React.FC<SelectProps> = ({ MenuProps, ...props }) => {
  const theme = useTheme()
  return (
    <MuiSelect
      MenuProps={{
        ...MenuProps,
        MenuListProps: {
          ...MenuProps?.MenuListProps,
          style: {
            background: darken(theme.palette.op.background.popover, 0.2),
            ...MenuProps?.MenuListProps?.style,
          },
        },
      }}
      {...props}
    />
  )
}

const Select = withStyles((theme: Theme) => ({
  select: {
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(Select2)

const SelectSmall = withStyles((theme: Theme) => ({
  select: {
    fontSize: '0.875rem !important',
    padding: '10px 30px 10px 16px !important',
    minHeight: 'initial',

    '&:focus': {
      borderRadius: 4,
    },
  },
  icon: {
    right: 5,
  },
}))(Select2)

const TinySelect = withStyles((theme: Theme) => ({
  select: {
    fontSize: '0.875rem',
    padding: '4px 10px 4px 10px !important',
    textAlign: 'center',

    '&:focus': {
      borderRadius: 4,
    },
  },
  icon: {
    display: 'none',
  },
}))(Select2)

const MenuList = withStyles((theme: Theme) => ({
  root: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}))(MuiMenuList)

const Popover = withStyles((theme) => ({
  paper: {
    background: theme.palette.op.background.popover,
    boxShadow: theme.palette.op.shadow.popup,
    borderRadius: theme.palette.op.borderRadius.popup,
  },
}))(MuiPopover)

const PopoverModal = withStyles((theme) => ({
  paper: {
    background: theme.palette.op.background.popover,
    boxShadow: theme.palette.op.shadow.popup,
    borderRadius: theme.palette.op.borderRadius.popup,
  },
}))(MuiPopover)

const DeepPopover = withStyles((theme) => ({
  paper: {
    background: theme.palette.op.background.deepPopover,
    boxShadow: `0px 20px 100px -7px rgba(0,0,0,0.4),
      0px 24px 38px 3px rgba(0,0,0,0.14),
      0px 9px 46px 8px rgba(0,0,0,0.12)`,
  },
}))(MuiPopover)

export {
  DeepPopover,
  ListSubheader,
  MenuItem,
  MenuList,
  MenuProps,
  Popover,
  PopoverProps,
  PopoverModal,
  Select,
  SelectSmall,
  TinySelect,
}
