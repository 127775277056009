import Transport from '.'
import config from '../../config'
import { Coupon, CreditCard, Invoice, Subscription } from '../../types'
import { HttpTransaction } from './transaction'

export type CapabilityType =
  | 'reports'
  | 'voicemailTranscription'
  | 'autoCallRecording'
  | 'snippets'
  | 'googleContacts'
  | 'auditLog'
  | 'roundRobinCalling'
  | 'autoReplies'
  | 'callTransfer'
  | 'maxPhoneNumberUsers'
  | 'internationalMessaging'
  | 'callRecording'
  | 'conferenceCalling'
  | 'emailIntegration'
  | 'callerId'
  | 'apiMessaging'
  | 'hubspot'
  | 'calling'
  | 'ivr'
  | 'messaging'
  | 'slackIntegration'
  | 'internationalCalling'
  | 'showOnCallStatus'

export interface Capability {
  description: string
  type: CapabilityType
  enabled: boolean
  included: number
  includedDuration: number | null
  includedType: string | null
}

export type UpgradeParams = {
  token?: string
  plan?: 'standard' | 'premium'
  coupon?: string
  annual?: boolean
}

export default class BillingClient {
  constructor(private transport: Transport) {}

  capabilities(): Promise<Capability[]> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}capability` }),
    )
  }

  subscription(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}subscription` }),
    )
  }

  upcomingInvoice(): Promise<Invoice> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}invoice/upcoming` }),
    )
  }

  upgrade(params: UpgradeParams): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
        body: params,
      }),
    )
  }

  convertCredits(amount: number): Promise<{ subscription: Subscription }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/convert`,
        body: { amount },
      }),
    )
  }

  addCredits(amount: number): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/credit`,
        body: { amount },
      }),
    )
  }

  autoCharge(amount: number): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/autoCharge`,
        body: { amount },
      }),
    )
  }

  restart(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
      }),
    )
  }

  endTrial(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/endTrial`,
      }),
    )
  }

  cancel(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/cancel`,
      }),
    )
  }

  creditCard(): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}card` }),
    )
  }

  upsertCreditCard(token: string): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}card`,
        body: { token },
      }),
    )
  }

  invoices(): Promise<Invoice[]> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}invoice` }),
    )
  }

  coupon(code: string): Promise<Coupon> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}coupon/${code}` }),
    )
  }
}
