import fonts from './fonts'

export type TypographyVariant =
  | 'emoji'
  | 'largeTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'subheadline'
  | 'body'
  | 'callout'
  | 'footnote'
  | 'caption1'
  | 'caption2'
  | 'caption3'
  | 'code'

const typography: { [key in TypographyVariant]: any } = {
  emoji: {
    fontSize: 20,
    lineHeight: '22px',
    fontFamily: fonts.emoji,
  },
  largeTitle: {
    fontSize: 35,
    lineHeight: '40px',
    fontFamily: fonts.header,
  },
  title1: {
    fontSize: 29,
    lineHeight: '32px',
    fontFamily: fonts.header,
  },
  title2: {
    fontSize: 25,
    lineHeight: '28px',
    fontFamily: fonts.header,
  },
  title3: {
    fontSize: 21,
    lineHeight: '24px',
    fontFamily: fonts.header,
  },
  headline: {
    fontSize: 17,
    lineHeight: '24px',
    fontWeight: 450,
  },
  subheadline: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 450,
  },
  body: {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 450,
  },
  callout: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 450,
  },
  footnote: {
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: 450,
  },
  caption1: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 550,
  },
  caption2: {
    fontSize: 11,
    lineHeight: '14px',
    fontWeight: 450,
  },
  caption3: {
    fontSize: 10,
    lineHeight: '11px',
    fontWeight: 750,
  },
  code: {
    fontSize: 13,
    lineHeight: '16px',
    fontFamily: fonts.jetBrainsMono,
  },
}

export default typography
