import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import React from 'react'
import { Identity } from '../service/model'
import Avatar, { AvatarSize } from './avatar'

interface UserTagProps {
  size?: AvatarSize
  identity: Identity
  subtext?: string
}

const UserTag: React.FC<UserTagProps> = function ({ identity, size = 'small', subtext }) {
  const styles = useStyles({})
  return (
    <div className={styles.root}>
      <Avatar identity={identity} size={size} className={styles.picture} />
      <div>
        <div className={cx(styles.name, styles[size])}>{identity.name}</div>
        {subtext && (
          <Typography variant="caption" color="textSecondary" className={styles.subtext}>
            {subtext}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default UserTag

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,

    '&$medium': {
      fontSize: '1.5rem',
    },
  },
  picture: {
    marginRight: '1rem',
  },
  subtext: {
    marginTop: 2,
  },
  medium: {},
}))
