// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Avatar from '../../../avatar'
import { fromFile, fromUrl } from '../../../../lib/vcard'
import { DecodableMessageMedia } from '../../../../service/model'

export interface ContactMediaPreviewProps {
  media: DecodableMessageMedia
}

const ContactMediaPreview: React.FC<ContactMediaPreviewProps> = function ({ media }) {
  const styles = useStyles({})
  const [name, setName] = useState<string>(null)
  const [company, setCompany] = useState<string>(null)
  const [photo, setPhoto] = useState<string>(null)

  useEffect(() => {
    const loader = media.url ? fromUrl(media.url) : fromFile(media.file)
    loader.then((card) => {
      setName(card.fn)
      setCompany(card.org)
      setPhoto(card.photo)
    })
  }, [media])

  return (
    <div className={styles.root}>
      <Avatar identity={{ pictureUrl: photo }} style={{ marginRight: 10 }} />
      <div className={styles.body}>
        <div className={styles.name}>{name}</div>
        {company && <div className={styles.company}>{company}</div>}
      </div>
    </div>
  )
}

export default ContactMediaPreview

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: theme.palette.op.background.highlight(0.07),
      borderRadius: 5,
      padding: 8,
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      margin: '0 7px',
    },
    body: {
      maxWidth: 150,
      marginRight: 20,
    },
    name: {
      fontSize: '0.80rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    company: {
      fontSize: '0.6rem',
      marginTop: 2,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
    },
  }),
  { name: ContactMediaPreview.name },
)
