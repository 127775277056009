// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { searchTags } from '../../../../lib/search'
import useInputState from '../../../../lib/use-input-state'
import useKeyStepper from '../../../../lib/use-key-stepper'
import { colorForOption } from '../../../../service/model'
import { ContactTemplateItem } from '../../../../service/model/contact-template-item'
import { TemplateTagItemOption } from '../../../../types'
import Tag from '../../item/tag'
import { List, ListItem } from '../list'

export interface TagListProps {
  values: string[]
  templateItem: ContactTemplateItem
  onNew: (value: string) => void
  onSelect: (value: string) => void
  onDelete: (value: string) => void
  onHide: () => void
}

const TagList: React.FC<TagListProps> = function ({
  values,
  templateItem,
  onNew,
  onSelect,
  onDelete,
  onHide,
}) {
  const styles = useStyles({})
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [input, setInput] = useInputState('')
  const [options, setOptions] = useState<TemplateTagItemOption[]>(
    templateItem.options ?? [],
  )

  useEffect(() => {
    const value = input.trim()
    searchTags(value, templateItem.options ?? []).then((options) => {
      if (!value || options.find((opt) => opt.name === value)) {
        setOptions(options ?? [])
      } else {
        setOptions([null, ...options])
      }
    })
  }, [input, templateItem])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: options,
    node: rootRef.current,
    defaultSelectedIndex: -1,
    name: 'contact/editor/tags/list',
    handleSelect: (item) => {
      if (item) {
        onSelect(item.name)
      } else if (input) {
        onNew(input)
      } else {
        onHide()
      }
    },
  })

  const handleSave = () => {
    const value = input.trim()
    if (value) {
      onNew(value)
    } else {
      // FIXME: don't use `undefined` to indicate a lack of a value
      onNew(undefined)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Backspace' &&
      inputRef.current.selectionStart === 0 &&
      values.length > 0
    ) {
      onDelete(values[values.length - 1])
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter' || event.key === 'Tab') {
      handleSave()
    }
  }

  return (
    <div ref={rootRef}>
      <div className={styles.root}>
        {values.map((value, index) => (
          <Tag
            key={`${value}${index}`}
            name={value}
            color={colorForOption(value, templateItem)}
            onDelete={() => onDelete(value)}
          />
        ))}
        <input
          autoFocus
          ref={inputRef}
          value={input}
          placeholder="Add a tag"
          onChange={setInput}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          className={styles.input}
        />
      </div>
      {options?.length > 0 && (
        <List>
          {options.map((item, index) => (
            <ListItem
              key={item?.name}
              className={styles.row}
              isSelected={selectedIndex === index}
              {...getItemProps(index)}
            >
              {item === null ? (
                <>
                  <div className={cx(styles.color, styles.addNewIcon)}>
                    <AddIcon fontSize="inherit" color="inherit" />
                  </div>
                  <span>
                    Create <b>{input}</b>
                  </span>
                </>
              ) : (
                <>
                  <i
                    className={styles.color}
                    style={{ backgroundColor: colorForOption(item.name, templateItem) }}
                  />
                  <span>{item.name}</span>
                </>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default observer(TagList)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '7px 15px',
  },
  input: {
    flex: 1,
    minWidth: 50,
    border: 'none',
    outline: 'none',
    fontSize: '0.8rem',
    background: 'none',
    marginLeft: 5,
    color: theme.palette.text.primary,
    padding: '4px 0',

    '&::placeholder': {
      color: theme.palette.op.text.tertiary,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewIcon: {
    color: theme.palette.text.primary,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '18px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    backgroundColor: theme.palette.op.background.highlight(0.2),
  },
  color: {
    display: 'inline-block',
    width: 15,
    height: 15,
    borderRadius: 4,
    marginRight: 10,
  },
}))
