import { alpha } from '@material-ui/core/styles'

import { black, white } from '../colors'
import { OpenPhonePalette, createThemeMatch } from '../common'
import { borderRadius, contactTags, legacy } from '../shared/palette'

const primary = {
  1: '#6333FF',
  2: '#7438FF',
  3: '#622BFA',
  4: '#F7F2FD',
}

const secondary = {
  green1: '#00D15B',
  green2: '#00BB55',
  blue: '#00A0FF',
  yellow: '#FFDC59',
  orange: '#FFA10A',
  red1: '#FE4100',
  red2: '#EE3B00',
}

const gray = {
  1: '#222224',
  2: '#646466',
  3: '#A1A1A3',
  4: '#C6C6C8',
  5: '#F3F3F5',
  6: '#FBFBFC',
}

const note = {
  text: '#784F00',
  label: '#CD8E12',
  highlight: '#F5A800',
  bg: '#FAF6E6',
}

const mentionTheirName = '#008BFA'
const mentionMyName = '#008EFF'

const mention = {
  theirName: mentionTheirName,
  theirBg: alpha(mentionTheirName, 0.09),
  myName: mentionMyName,
  myBg: alpha('#FFD946', 0.39),
  chatBubbleBg: alpha(white, 0.17),
}

const hoverPrimaryBase = '#BCBCC9'
const tagPurpleBgBase = '#A684FF'
const tagGreenBgBase = '#63E49B'
const tagBlueBgBase = '#5DB8FF'
const tagYellowBgBase = '#E6CA61'
const tagOrangeBgBase = '#FFB642'
const tagRedBgBase = '#FF906A'

const palette: OpenPhonePalette = {
  black,
  white,
  titleBar: {
    // TODO: adapt these to light mode
    background: '#232333',
    closeHover: '#C43F1C',
  },

  background: {
    primary: white,
    popover: white,
    deepPopover: white,
    error: '#FFEBE5',
    highlight: (a = 0.06) => alpha(black, a),
  },
  primary,
  secondary,
  gray,
  mention,
  tag: {
    purple: {
      bg: alpha(tagPurpleBgBase, 0.18),
      bgHover: alpha(tagPurpleBgBase, 0.24),
      text: '#541AF0',
    },
    green: {
      bg: alpha(tagGreenBgBase, 0.18),
      bgHover: alpha(tagGreenBgBase, 0.24),
      text: 'rgba(0, 166, 63)',
    },
    blue: {
      bg: alpha(tagBlueBgBase, 0.18),
      bgHover: alpha(tagBlueBgBase, 0.24),
      text: '#0081E7',
    },
    yellow: {
      bg: alpha(tagYellowBgBase, 0.18),
      bgHover: alpha(tagYellowBgBase, 0.24),
      text: '#875A03',
    },
    orange: {
      bg: alpha(tagOrangeBgBase, 0.18),
      bgHover: alpha(tagOrangeBgBase, 0.24),
      text: '#D87503',
    },
    red: {
      bg: alpha(tagRedBgBase, 0.18),
      bgHover: alpha(tagRedBgBase, 0.24),
      text: '#E42500',
    },
  },
  note,
  text: {
    primary: gray[1],
    secondary: gray[2],
    tertiary: gray[3],
    placeholder: alpha(gray[1], 0.4),
    subtitle: '#72819C',
  },
  hover: {
    primary: alpha(hoverPrimaryBase, 0.18),
    darker: alpha(hoverPrimaryBase, 0.4),
    thread: alpha(note.highlight, 0.23),
    selected: alpha(tagPurpleBgBase, 0.09),
  },
  border: {
    common: alpha(gray[1], 0.06),
    focus: alpha(primary[2], 0.6),
  },
  shadow: {
    tooltip: `0 1px 3px 0 ${alpha(black, 0.08)}, 0 0 0 1px ${alpha(gray[1], 0.05)}`,
    popup: `0 2px 8px 0 ${alpha(black, 0.11)}, 0 0 0 1px ${alpha(gray[1], 0.05)}`,
    modal: `0 8px 64px 0 ${alpha(black, 0.22)}, 0 0 0 1px ${alpha(gray[1], 0.04)}`,
    textField: {
      inactive: `inset 0 0 0 1.5px ${alpha(gray[1], 0.12)}`,
      hoverPurple:
        `0 0 0 4px ${alpha(primary[2], 0.1)},` +
        `inset 0 0 0 1.5px ${alpha(primary[2], 0.6)}`,
      hoverNoteThread:
        `0 0 0 4px ${alpha(note.highlight, 0.24)},` +
        `inset 0 0 0 1.5px ${alpha(note.highlight, 0.8)}`,
    },
    dropdown: {
      inactive:
        `0 1.5px 4px 0 ${alpha(black, 0.06)},` +
        `inset 0 0 0 1.5px ${alpha(gray[1], 0.14)}`,
      hover:
        `0 1.5px 4px 0 ${alpha(black, 0.06)},` +
        `inset 0 0 0 1.5px ${alpha(gray[1], 0.16)}`,
      active: `inset 0 0 0 1.5px ${alpha(primary[2], 0.6)}`,
      disabled: `inset 0 0 0 1.5px ${alpha(gray[1], 0.08)}`,
    },
    comments: `0 0 0 1.5px ${alpha(gray[1], 0.09)}`,
    card: `0 0 0 1.5px ${alpha(gray[1], 0.09)}`,
  },
  borderRadius,
  contactTags,
  legacy,

  match: createThemeMatch('light'),
}

export default palette
