// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button, { DangerButton } from '../../component/button'
import { CircularProgress } from '../../component/progress'
import Typography from '@ui/Typography'
import { Snackbar, SnackbarContent } from '../../component/snackbar'
import { ToastAction } from './ToastUiStore'
import { darkTheme, lightTheme } from '@src/theme'
import { OpenToastState } from '.'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type StyleProps = {
  type: OpenToastState['type']
}

const Toast: React.FC<{}> = function ({}) {
  const { toast } = useAppStore()

  const {
    type = 'info',
    message = '',
    actions = [],
    duration = 6000,
  } = toast.state.open ? toast.state : {}

  const styles = useStyles({ type })

  const handleToastAction = (handler?: () => void) => () => {
    handler?.()
    toast.hide()
  }

  const ToastButton = type === 'error' ? DangerButton : Button

  const renderActionButton = (action: ToastAction) => {
    const ActionButton = action.type === 'destructive' ? DangerButton : ToastButton

    return (
      <ActionButton
        variant="text"
        size="small"
        onClick={handleToastAction(action.onClick)}
      >
        {action.title}
      </ActionButton>
    )
  }

  const renderActions = () => {
    if (type === 'special') return null
    if (actions.length > 0) {
      return actions.map(renderActionButton)
    } else {
      return (
        <ToastButton
          variant="text"
          size="small"
          color="inherit"
          onClick={toast.hide}
          className={styles.toastButton}
        >
          Close
        </ToastButton>
      )
    }
  }

  return (
    <Snackbar
      open={toast.state.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={type === 'loading' ? null : duration}
      transitionDuration={0}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          toast.hide()
        }
      }}
    >
      <SnackbarContent
        className={styles.root}
        type={type}
        message={
          <div className={styles.toastContent}>
            {type === 'loading' ? (
              <>
                <CircularProgress className={styles.toastLoading} size={14} />
                <div>{message}</div>
              </>
            ) : type === 'special' ? (
              <div>{message}</div>
            ) : (
              <Typography
                variant="body"
                color={type === 'error' ? 'error' : 'inherit'}
                fontWeight={type === 'error' ? 'medium' : 'regular'}
                style={{ fontSize: 'inherit ' }}
              >
                {message}
              </Typography>
            )}
          </div>
        }
        action={renderActions()}
      />
    </Snackbar>
  )
}

export default observer(Toast)

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ type }) => {
    if (type === 'special') return null

    const background = (() => {
      if (type === 'error') return theme.palette.op.background.error

      return theme.palette.op.match({
        dark: lightTheme.palette.op.gray[6],
        light: darkTheme.palette.op.gray[6],
      })
    })()

    return {
      border: 0,
      paddingBlock: '7px',
      borderRadius: '7px',
      boxShadow:
        type === 'error' ? `inset 0 0 0 2px ${theme.palette.op.tag.red.bg}` : 'none',
      background,
    }
  },
  toastContent: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    color: ({ type }) => {
      if (type === 'special') return null

      if (type === 'error') return theme.palette.op.tag.red.text

      return theme.palette.op.match({
        dark: lightTheme.palette.op.gray[1],
        light: darkTheme.palette.op.gray[1],
      })
    },
  },
  toastLoading: {
    marginRight: 10,
  },
  toastButton: ({ type }) => {
    if (type === 'special') return null

    const common: CSSProperties = {
      fontWeight: 550,
    }

    if (type === 'error') {
      return {
        ...common,
        color: 'inherit',
        '&:hover, &:focus': {
          color: 'inherit',
          background: theme.palette.op.tag.red.bg,
        },
      }
    }

    return {
      ...common,
      color: theme.palette.op.match({
        dark: lightTheme.palette.op.gray[2],
        light: darkTheme.palette.op.gray[2],
      }),
      '&:hover, &:focus': {
        background: theme.palette.op.match({
          dark: lightTheme.palette.op.hover.primary,
          light: darkTheme.palette.op.hover.primary,
        }),
        color: theme.palette.op.match({
          dark: lightTheme.palette.op.gray[1],
          light: darkTheme.palette.op.gray[1],
        }),
      },
    }
  },
}))
