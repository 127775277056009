import React from 'react'
import Key from '../../component/keyboard-key'
import Typography from '@ui/Typography'
import { Member } from '@src/service/model'
import { Command } from './common-v2'
import Content from './common/content'
import CommandFooter from './common/footer'
import Header from './common/header'
import MemberPicker from './common/member-picker'

export interface SelectMemberCommandProps {
  title: string
  subtitle?: string
  excludeIds?: string[]
  onSelect: (member: Member) => void
}

const SelectMemberCommand: React.FC<SelectMemberCommandProps> = function ({
  title,
  subtitle,
  onSelect,
  excludeIds,
}) {
  return (
    <Command>
      <Header title={title} subtitle={subtitle} />
      <Content>
        <MemberPicker onSelect={onSelect} excludeIds={excludeIds} />
      </Content>
      <CommandFooter>
        <Typography variant="caption1" color="textSecondary" fontWeight="regular">
          Press <Key name="Enter" /> to select.
        </Typography>
      </CommandFooter>
    </Command>
  )
}

export default SelectMemberCommand
