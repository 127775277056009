import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Typography from '@ui/Typography'
interface FeatureRowProps {
  title: string
  desc: string
  icon: React.ReactNode
}

const FeatureRow: React.FC<FeatureRowProps> = ({ title, desc, icon }) => {
  const styles = useStyles({})

  return (
    <div className={styles.featureRow}>
      <div className={styles.featureIcon}>{icon}</div>
      <Typography variant="callout" color="textPrimary">
        {title}
      </Typography>
      <Typography
        variant="footnote"
        color="textTertiary"
        className={styles.featureDescription}
      >
        {desc}
      </Typography>
    </div>
  )
}

export default FeatureRow

const useStyles = makeStyles((theme) => ({
  featureRow: {
    alignItems: 'center',
    columnGap: 12,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    rowGap: 4,
  },
  featureIcon: {
    color: theme.palette.primary.light,
    display: 'flex',
    flexShrink: 0,
  },
  featureDescription: {
    gridColumn: 2,
  },
}))
