import React from 'react'
import FileSelector from './common/file-selector'
import Header from './common/header'
import Content from './common/content'
import { Command } from './common-v2'

export interface SelectFileCommandProps {
  accept: string[]
  title: string
  description: string
  onSelect: (file: File) => void
}

const SelectFileCommand: React.FC<SelectFileCommandProps> = function ({
  accept,
  title,
  description,
  onSelect,
}) {
  return (
    <Command>
      <Header title={title} />
      <Content>
        <FileSelector accept={accept} placeholder={description} onComplete={onSelect} />
      </Content>
    </Command>
  )
}

export default SelectFileCommand
