// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '../../../../button-v2'
import { MenuContent } from '../../../../menu-v2'
import SearchInput from '../../../../search-input'
import Typography from '@ui/Typography'
import VirtualList from '../../../../virtual-list'
import useKeyStepper from '../../../../../lib/use-key-stepper'
import useKeyboardShortcuts from '../../../../../lib/use-keyboard-shortcuts'
import { Snippet } from '../../../../../service/model'
import Controller from '../../../controller'
import SnippetComponent from './snippet'

export interface SnippetsProps {
  controller: Controller
}

const Snippets: React.FC<SnippetsProps> = function ({ controller }) {
  const styles = useStyles({})
  const searchRef = useRef<HTMLInputElement>(null)
  const [searchFocused, setSearchFocused] = useState(false)
  const snippets = controller.menu.filteredSnippets

  useKeyboardShortcuts({
    name: 'Snippets',
    node: searchFocused ? searchRef.current : controller.editor.ref.current,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        controller.menu.closeCommands()
        event.preventDefault()
      }
    },
    dep: [controller, searchFocused],
  })

  const { getItemProps, selectedIndex } = useKeyStepper({
    node: searchFocused ? searchRef.current : controller.editor.ref.current,
    filter: (_, e) => !e.defaultPrevented,
    items: snippets.list,
    name: 'component/input-bar/input-menu/snippets',
    handleSelect: (item) => controller.handleSelectSnippet(item),
    deps: [controller, searchFocused],
  })

  const handleSearchFocus = useCallback(() => setSearchFocused(true), [])
  const handleSearchBlur = useCallback(() => setSearchFocused(false), [])

  const renderSnippet = (snippet: Snippet, index: number) => {
    return (
      <SnippetComponent
        controller={controller}
        snippet={snippet}
        highlighted={selectedIndex === index}
        {...getItemProps(index)}
      />
    )
  }

  return (
    <div className={styles.root}>
      <MenuContent className={styles.header}>
        <SearchInput
          autoFocus
          ref={searchRef}
          className={styles.search}
          onChange={controller.menu.handleSnippetSearchInputChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
        />
        <Button variant="outlined" height={36} onClick={controller.handleNewSnippet}>
          Create snippet
        </Button>
      </MenuContent>
      {snippets.length > 0 ? (
        <div className={styles.list}>
          <VirtualList
            data={snippets}
            itemContent={renderSnippet}
            itemEstimatedHeight={56}
          />
        </div>
      ) : (
        <MenuContent className={styles.zero}>
          <Typography color="textSecondary">
            {controller.snippets.length > 0
              ? 'No results found.'
              : 'Is there a message you send frequently? Save it here for later.'}
          </Typography>
        </MenuContent>
      )}
    </div>
  )
}

export default observer(Snippets)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,

      '& > *': {
        margin: 8,
      },
    },
    search: {
      width: 'auto',
      flex: 1,
    },
    list: {
      display: 'flex',
      maxHeight: 224,
    },
    zero: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 16,
      paddingBottom: 16,
    },
  }),
  { name: Snippets.name },
)
