import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@material-ui/core/Drawer'

interface DrawerProps extends MuiDrawerProps {}

const Drawer: React.FC<DrawerProps> = function ({ ...props }) {
  const classes = useStyles({})
  return <MuiDrawer BackdropProps={{ classes }} classes={classes} {...props} />
}

export default Drawer

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'none',
  },
  paper: {
    background: theme.palette.op.background.primary,
    borderLeft: `1.5px solid ${theme.palette.op.border.common}`,
    boxShadow: `0 8px 64px 0 rgba(0, 0, 0, 0.22), 
      0 0 0 1.5px ${theme.palette.op.border.common}`,
  },
}))
