import Dexie from 'dexie'

const name = 'openphone-v3.0.0'

export function createDatabase() {
  let indexedDb = new Dexie(name)
  indexedDb.version(1).stores({
    activity: '&id,conversationId,createdAt',
    blocklist: '&id',
    contact: '&id,*_phoneNumbers',
    contactSettings: '&id',
    contactTemplateItem: '&id',
    conversation: '&id,phoneNumber,phoneNumberId,directNumberId,lastActivityAt',
    directNumber: '&id',
    entityPhoneNumber: '&id',
    integration: '&id',
    integrationContact: '&id,*identifiers',
    kv: '',
    member: '&id',
    phoneNumber: '&id',
    presence: '&userId',
    snippet: '&id',
  })
  indexedDb.version(2).stores({
    transaction: '&id',
  })
  indexedDb.version(3).stores({
    alert: '&id',
  })
  indexedDb.version(4).stores({
    contact: '&id,*_phoneNumbers,sortName',
    conversation:
      '&id,phoneNumber,phoneNumberId,directNumberId,lastActivityAt,unreadCount',
  })
  indexedDb.version(5).stores({
    groups: '&id',
    webhooks: '&id',
  })
  return indexedDb
}
