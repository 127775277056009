// @ts-strict-ignore
import { get, makeAutoObservable, toJS } from 'mobx'
import { v4 as uuid } from 'uuid'
import Service from '..'
import { parseDate, replaceAtIndex } from '../../lib'
import { ValidationError } from '../../lib/api/error-handler'
import { minutes } from '../../lib/date'
import { formatted } from '../../lib/phone-number'
import { isTruthy } from '../../lib/rx-operators'
import { ById, ConversationParticipantStatus } from '../../types'
import { Activity, Collection, IActivity, Member, MessageMedia, Model } from './'

export interface MemberPresence {
  member: Member
  status: ConversationParticipantStatus
}

export class Conversation implements IConversation, Model {
  id: string = `CN${uuid()}`.replace(/-/g, '')
  createdAt: number = null
  deletedAt: number = null
  directNumberId: string = null
  lastActivityId: string = null
  lastActivityAt: number = null
  lastSeenAt: number = null
  mutedUntil: number = null
  name: string = null
  phoneNumber: string = null
  phoneNumberId: string = null
  snoozedUntil: number = null
  unreadActivities: ById<boolean> = {}
  unreadCount: number = null
  updatedAt: number = null
  userId: string = null

  // Relations
  readonly activities = new Collection<Activity>({
    compare: (a, b) => a.createdAt - b.createdAt,
  })

  // Local
  isNew: boolean = null

  constructor(private root: Service, attrs: Partial<Conversation> = {}) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  /**
   * We don't support calls on direct or group conversations
   */
  get canCall() {
    return !this.directNumberId && !this.isGroup
  }

  get openPhoneNumber() {
    return this.root.phoneNumber.collection.get(this.phoneNumberId)
  }

  get directNumber() {
    return this.root.user.current.directNumbers.get(this.directNumberId)
  }

  get presenceEnabled() {
    return this.openPhoneNumber?.isShared && !this.isNew
  }

  get participants() {
    return (
      this.phoneNumber
        ?.split(',')
        .filter(isTruthy)
        .map((p) => this.root.participant.getOrCreate(p)) ?? []
    )
  }

  get isBlocked() {
    return this.root.blocklist.byPhoneNumber[this.phoneNumber]
  }

  get isGroup(): boolean {
    return this.participants.length > 1
  }

  get isUnread(): boolean {
    return this.unreadCount > 0
  }

  get isDone(): boolean {
    return this.snoozedUntil > Date.now()
  }

  get isArchived(): boolean {
    return Boolean(this.deletedAt)
  }

  get isDirect(): boolean {
    return Boolean(this.directNumberId)
  }

  get friendlyName(): string {
    if (this.name) return this.name
    return this.participants
      .map((participant) => {
        const name =
          this.participants.length === 1 ? participant.name : participant.shortName
        return name || formatted(participant.phoneNumber)
      })
      .join(', ')
  }

  get lastActivity(): Activity {
    return this.root.activity.get(this.lastActivityId)
  }

  get presence(): MemberPresence[] {
    const userId = this.root.user.current.id
    const users = get(this.root.conversation.presence, this.id) ?? {}
    return Object.keys(users)
      .filter((id) => id !== userId)
      .map((id) => ({
        member: this.root.member.collection.get(id),
        status: users[id].status,
      }))
  }

  addParticipant = (phoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be changed for an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    this.phoneNumber = [...phoneNumbers, phoneNumber].filter(isTruthy).join(',')
    this.save()
  }

  addParticipants = (phoneNumbers: string[]) => {
    phoneNumbers.forEach((phoneNumber) => this.addParticipant(phoneNumber))
  }

  removeParticipant = (phoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be chnaged for an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    this.phoneNumber = phoneNumbers
      .filter((n) => n !== phoneNumber)
      .filter(isTruthy)
      .join(',')
    this.save()
  }

  replaceParticipant = (newPhoneNumber: string, oldPhoneNumber: string) => {
    if (!this.isNew) {
      throw new ValidationError(
        'Participants cannot be changed in an ongoing conversation',
      )
    }
    const phoneNumbers = this.phoneNumber?.split(',') ?? []
    const existingIndex = phoneNumbers.indexOf(oldPhoneNumber)
    this.phoneNumber = replaceAtIndex(phoneNumbers, newPhoneNumber, existingIndex)
      .filter(isTruthy)
      .join(',')
    this.save()
  }

  toggleRead = () => {
    return this.unreadCount > 0 ? this.markAsRead() : this.markAsUnread()
  }

  markAsRead = async () => {
    this.unreadCount = 0
    this.unreadActivities = {}
    this.save()
    if (!this.isNew) {
      return this.root.conversation.markAsRead(this.id)
    }
  }

  markAsUnread = async () => {
    this.unreadCount = 1
    if (this.lastActivity) {
      this.unreadActivities = { [this.lastActivity.id]: true }
    }
    this.save()
    if (!this.isNew) {
      return this.root.conversation.markAsUnread(this.id)
    }
  }

  toggleDone = () => {
    return this.snoozedUntil > Date.now() ? this.markAsUndone() : this.markAsDone()
  }

  markAsDone = async () => {
    const duration = 525949200
    this.snoozedUntil = Date.now() + minutes(duration)
    this.save()
    if (!this.isNew) {
      return this.root.conversation.snooze(this.id, duration)
    }
  }

  markAsUndone = async () => {
    this.snoozedUntil = null
    this.save()
    if (!this.isNew) {
      return this.root.conversation.unsnooze(this.id)
    }
  }

  archive = () => {
    return this.root.conversation.archive(this.id)
  }

  save() {
    this.root.conversation.collection.put(this)
  }

  toggleBlock(): Promise<any> {
    if (this.isBlocked) {
      return this.root.blocklist.unblock(this.phoneNumber)
    } else {
      return this.root.blocklist.block(this.phoneNumber)
    }
  }

  delete() {
    this.root.conversation.collection.delete(this)
  }

  send = async (body: string, media: MessageMedia[]) => {
    if (this.isNew) {
      this.isNew = false
      this.root.conversation.collection.put(this)
    }
    const activity = new Activity(this.root, {
      body,
      conversationId: this.id,
      createdAt: Date.now(),
      createdBy: this.root.user.current.id,
      direction: 'outgoing',
      media,
      status: 'queued',
      type: 'message',
      updatedAt: Date.now(),
    })
    return activity.send()
  }

  deserialize = ({ lastActivity, participants, ...json }: any) => {
    Object.assign(this, json)
    this.lastActivityAt = parseDate(json.lastActivityAt)
    this.lastSeenAt = parseDate(json.lastSeenAt)
    this.updatedAt = parseDate(json.updatedAt)
    this.createdAt = parseDate(json.createdAt)
    this.deletedAt = parseDate(json.deletedAt)
    this.snoozedUntil = parseDate(json.snoozedUntil)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      directNumberId: this.directNumberId,
      id: this.id,
      isNew: this.isNew,
      lastActivityAt: this.lastActivityAt,
      lastActivityId: this.lastActivityId,
      lastSeenAt: this.lastSeenAt,
      mutedUntil: this.mutedUntil,
      name: this.name,
      phoneNumber: this.phoneNumber,
      phoneNumberId: this.phoneNumberId,
      snoozedUntil: this.snoozedUntil,
      unreadActivities: toJS(this.unreadActivities),
      unreadCount: this.unreadCount,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  tearDown() {
    this.phoneNumberId = null
    this.directNumberId = null
    this.root.activity.collection.deleteBulk(this.activities.list.map((a) => a.id))
    this.activities.clear()
  }
}

export interface IConversation {
  createdAt: number
  deletedAt: number
  directNumberId: string
  id: string
  isNew: boolean
  lastActivity?: IActivity
  lastActivityAt: number
  lastActivityId: string
  lastSeenAt: number
  mutedUntil: number
  name: string
  phoneNumber: string
  phoneNumberId: string
  snoozedUntil: number
  unreadActivities: ById<boolean>
  unreadCount: number
  updatedAt: number
  userId: string
}
