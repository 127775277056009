// @ts-strict-ignore
import { action, makeAutoObservable, reaction } from 'mobx'
import { fuzzySearch } from '../../lib/search'
import { Identity, PhoneNumber } from '../../service/model'
import { ActivitySearchResult } from '../../types'
import AppStore from '../store'

interface SearchQuery {
  in?: PhoneNumber[]
  from?: Identity[]
  term?: string
}

export type SearchItemType =
  | { type: 'header'; title: string }
  | { type: 'search'; term: string }
  | { type: 'in'; phoneNumber: PhoneNumber }
  | { type: 'from'; identity: Identity }
  | { type: 'identity'; identity: Identity }

export default class SearchUiStore {
  query: SearchQuery = {
    in: [],
    from: [],
    term: '',
  }

  loading: boolean = false
  results: ActivitySearchResult[] = null
  selectedResultIndex: number = null
  inputToken: string = null
  identity: Identity = null
  private identitySuggestions: Identity[] = []
  private fromSuggestions: Identity[] = []
  private inboxSuggestions: PhoneNumber[] = []

  constructor(private app: AppStore) {
    makeAutoObservable(this, {})

    reaction(
      () => this.query?.term,
      (term) => {
        if (!term) {
          this.inboxSuggestions = []
          this.fromSuggestions = []
          this.identitySuggestions = []
          this.inputToken = null
          return
        }

        /**
         * Show contact suggestions
         */
        if (this.query.from.length === 0 && this.query.in.length === 0) {
          this.app.service.search.identities(term, 5).then(
            action((result) => {
              this.identitySuggestions = result.map((r) => r.identity)
            }),
          )
        }

        this.inputToken = ''

        /**
         * Only show from suggestions if the no from is already added
         */
        if (this.query.from.length === 0 && 'from:'.startsWith(term)) {
          this.inputToken = 'from:'
        }
        if (
          this.query.from.length === 0 &&
          ('from'.startsWith(term) || term.startsWith('from'))
        ) {
          const name = /from:?(.+)/gm.exec(term)?.[1]?.trim()
          if (!name) {
            this.fromSuggestions = this.app.service.contact.collection.list.slice(0, 5)
          } else {
            this.app.service.search.identities(name, 5).then(
              action((result) => {
                this.fromSuggestions = result.map((r) => r.identity)
              }),
            )
          }
        }

        /**
         * Check for inbox matches
         */
        if ('in:'.startsWith(term)) {
          this.inputToken = 'in:'
        }
        if ('in:'.startsWith(term) || term.startsWith('in:')) {
          const number = /in:?(.+)/gm.exec(term)?.[1]?.trim()
          if (!number) {
            this.inboxSuggestions = app.service.user.phoneNumbers.list
          } else {
            this.inboxSuggestions = fuzzySearch(
              app.service.user.phoneNumbers.list,
              number,
              ['name'],
            )
          }
        }
      },
    )
  }

  get isEmptyQuery() {
    return (
      !this.query ||
      (!this.query.term && !this.query.in?.length && !this.query.from?.length)
    )
  }

  get suggestions(): SearchItemType[] {
    if (!this.query.term) {
      return []
    }
    return [
      { type: 'search', term: this.query.term },
      ...this.identitySuggestions.map(
        (identity): SearchItemType => ({ type: 'identity', identity }),
      ),
      ...this.fromSuggestions.map(
        (identity): SearchItemType => ({ type: 'from', identity }),
      ),
      ...this.inboxSuggestions.map(
        (phoneNumber): SearchItemType => ({ type: 'in', phoneNumber }),
      ),
    ]
  }

  autoFillInput = () => {
    this.query.term = this.inputToken
    this.inputToken = null
  }

  setTerm = (term: string) => {
    this.query ??= { in: null, from: null, term: null }
    this.query.term = term
  }

  addInbox = (phoneNumber: PhoneNumber) => {
    this.query.in.push(phoneNumber)
  }

  removeInbox = (phoneNumber: PhoneNumber) => {
    this.query.in = this.query.in.filter((p) => p !== phoneNumber)
  }

  addFrom = (identity: Identity) => {
    this.query.from.push(identity)
  }

  removeFrom = (identity: Identity) => {
    this.query.from = this.query.from.filter((id) => id !== identity)
  }

  clearResults = () => {
    this.results = null
    this.selectedResultIndex = null
  }

  clearSearch = () => {
    this.query = {
      from: [],
      in: [],
      term: '',
    }
    this.loading = false
    this.results = null
    this.selectedResultIndex = null
    this.inputToken = null
    this.identity = null
    this.identitySuggestions = []
    this.fromSuggestions = []
    this.inboxSuggestions = []
  }

  runSearch = () => {
    this.loading = true
    this.app.service.conversation
      .search({
        query: this.query.term,
        phoneNumbers: this.query.from.flatMap((c) => c.phones.map((p) => p.number)),
        phoneNumberIds: this.query.in.map((pn) => pn.id),
      })
      .then(
        action((result) => {
          this.results = result.sort((a, b) => b._score - a._score)
        }),
      )
      .catch(this.app.toast.showError)
      .finally(
        action(() => {
          this.loading = false
        }),
      )
  }

  setIdentity = (identity: Identity) => {
    this.identity = identity
  }

  openResult = (result: ActivitySearchResult) => {
    this.app.service.conversation
      .findOne({
        phoneNumber: result._source.phoneNumber,
        phoneNumberId: result._source.phoneNumberId,
        directNumberId: result._source.directNumberId,
      })
      .then(action((conv) => this.app.inboxes.openConversation(conv, result._id)))
      .catch(this.app.toast.showError)
  }
}
