import Transport from '.'
import config from '../../config'
import { HttpTransaction } from './transaction'

export type TransferParams =
  | { phoneNumber: string }
  | { phoneNumberId: string }
  | { userId: string }

export default class VoiceClient {
  constructor(private transport: Transport) {}

  recordings = {
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}call/recording`,
          body: {
            activityId,
            recordingIds,
          },
        }),
      )
    },
  }

  token(): Promise<{ expiry: string; token: string }> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.COMMUNICATION_SERVICE_URL}voice/token`,
      }),
    )
  }

  record(
    callSid: string,
    phoneNumberId: string,
    action: 'start' | 'pause' | 'resume' | 'stop',
  ) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.COMMUNICATION_SERVICE_URL}call/${callSid}/record`,
        body: { action, phoneNumberId },
      }),
    )
  }

  transfer(callSid: string, params: TransferParams) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.COMMUNICATION_SERVICE_URL}call/${callSid}/transfer`,
        body: params,
      }),
    )
  }
}
