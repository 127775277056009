import { observer } from 'mobx-react-lite'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PhoneNumber from '../phone-number'
import List from './list'

const PhoneNumbers = function ({}) {
  return (
    <Switch>
      <Route path="/settings/phone-numbers/:id" component={PhoneNumber} />
      <Route path="/settings/phone-numbers" component={List} />
    </Switch>
  )
}

export default observer(PhoneNumbers)
