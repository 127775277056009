// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import TextInput from '@ui/TextInput'
import { isTruthy } from '../../lib/rx-operators'
import useInputState from '../../lib/use-input-state'
import useKeyStepper from '../../lib/use-key-stepper'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import { Command } from './common-v2'
import Content from './common/content'
import Header from './common/header'
import Item, { HeaderItem } from './common/item'

export interface RejectWithMessageProps {
  onSelect: (message: string) => void
}

const RejectWithMessage: React.FC<RejectWithMessageProps> = function ({ onSelect }) {
  const styles = useStyles({})
  const ref = useRef<HTMLInputElement>(null)
  const [input, setInput] = useInputState('')
  const { service, voice, toast } = useAppStore()
  const user = service.user.current.asMember

  const items = useMemo(
    () =>
      [
        Boolean(user.presence.text) &&
          `${user.presence.text} ${user.presence.symbol}. Can I call you back?`,
        voice.hasOngoingCall && "I'm on another call. Can I call you back?",
        "Sorry, can't talk right now. Can I call you back?",
        "Sorry, I don't have you in my contacts. Who is this?",
      ].filter(isTruthy) as string[],
    [voice.hasOngoingCall, user.presence.text, user.presence.symbol],
  )

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'command/reject-with-message',
    handleSelect: (item) => {
      onSelect(item)
    },
  })

  useKeyboardShortcuts({
    node: ref.current,
    name: 'command/reject-with-message/input',
    dep: [input],
    handler: (shortcut, event) => {
      if (shortcut === 'Enter') {
        handleSend()
        event.preventDefault()
      }
    },
  })

  const handleSend = () => {
    if (input) {
      onSelect(input)
    } else {
      toast.showError(new Error('Message is empty.'))
    }
  }

  return (
    <Command>
      <Header title="Send a message" subtitle="Draft or choose a rejection message." />
      <Content>
        <HeaderItem>Suggestions</HeaderItem>
        {items.map((item, index) => (
          <Item
            key={index}
            {...getItemProps(index)}
            highlighted={index === selectedIndex}
          >
            {item}
          </Item>
        ))}
        <HeaderItem>Custom</HeaderItem>
        <div className={styles.custom}>
          <TextInput
            ref={ref}
            value={input}
            onChange={setInput}
            placeholder="Write a message..."
            className={styles.input}
          />
          <Button height={45} onClick={handleSend}>
            Send
          </Button>
        </div>
      </Content>
    </Command>
  )
}

export default observer(RejectWithMessage)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  custom: {
    display: 'flex',
    padding: '0 2rem 20px',
  },
  input: {
    flex: 1,
    marginRight: 15,
  },
}))
