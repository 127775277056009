// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { Contact, IntegrationContact, Member } from '../../service/model'
import ItemEditor from './editor'
import ContactHeader from './header'
import ContactItems from './items'
import ContactNotes from './notes'
import ReadonlyItems from './readonly-items'

export type ContactType = Contact | Member | IntegrationContact

interface ContactDetailsProps {
  contact: ContactType
  matchingContacts?: ContactType[]
  onSelect?: (contact: ContactType) => void
}

const ContactDetails: React.FC<ContactDetailsProps> = function ({
  contact,
  matchingContacts,
  onSelect,
}) {
  const styles = useStyles({})
  return (
    <ItemEditor>
      <ContactHeader
        onContactSelect={onSelect}
        matchingContacts={matchingContacts}
        contact={contact}
      />
      {contact instanceof Contact ? (
        <ContactItems contact={contact} />
      ) : (
        <ReadonlyItems identity={contact} />
      )}
      {contact instanceof Contact && <ContactNotes contact={contact} />}
    </ItemEditor>
  )
}

export default ContactDetails

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

export const isEditable = (contact: ContactType): contact is Contact => {
  return contact instanceof Contact
}
