import React from 'react'
import ActionIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import TriggerIcon from '@material-ui/icons/FlashOnOutlined'
import { ZapierIcon } from '@src/component/icons/custom'
import Button from '@src/component/button-v2'
import { Page } from '../common'
import { Header } from './components/header'
import { HowItWorksCard } from './components/how-it-works-card'
import { DisconnectedLayout } from './components/disconnected-layout'

const Zapier = () => {
  const handleAdd = function () {
    window.open('https://zapier.com/apps/openphone/integrations', '_blank')
  }

  return (
    <Page>
      <DisconnectedLayout>
        <Header
          icon={<ZapierIcon />}
          title="Zapier"
          subtitle="Automate your workflows by connecting your OpenPhone account to any compatible Zapier application"
        />
        <HowItWorksCard
          description="OpenPhone exposes a variety of triggers and actions to help you create zaps that automate your workflows"
          features={[
            {
              icon: <TriggerIcon />,
              title: 'Triggers',
              description:
                'OpenPhone can notify Zapier of events within your workspace revolving around conversations and contacts. For example, you can subscribe to missed call triggers or be notified when a new contact is updated or created.',
            },
            {
              icon: <ActionIcon />,
              title: 'Actions',
              description:
                'Send a message or update or create a contact on OpenPhone based on triggers coming from other apps on Zapier.',
            },
          ]}
          action={
            <Button onClick={handleAdd} fullWidth>
              Add a Zap
            </Button>
          }
        />
      </DisconnectedLayout>
    </Page>
  )
}

export default Zapier
