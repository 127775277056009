// @ts-strict-ignore
import { action, makeObservable, observable } from 'mobx'
import AppStore from '../store'
import { DisposeBag } from '../../lib/dispose'
import { Model } from '../../service/model'
import ControllerRegistry, { ControllerRegistryOptions } from '../controller-registry'
import InputBarController, { SerializedInputBar } from './InputBarController'

export interface InputBarRegistryOptions<T extends InputBarController<I>, I extends Model>
  extends Partial<ControllerRegistryOptions<SerializedInputBar>> {
  classConstructor: (model: I) => T
}

export default abstract class InputBarRegistry<
  T extends InputBarController<I>,
  I extends Model,
> extends ControllerRegistry<T, SerializedInputBar, I> {
  readonly disposables = new WeakMap<T, DisposeBag>()
  readonly draftMessages: { [key: string]: string } = {}
  readonly classConstructor: (model: I) => T

  constructor(
    protected app: AppStore,
    { classConstructor, ...options }: InputBarRegistryOptions<T, I>,
  ) {
    super({ storage: app.service.storage.sync(), ...options })
    this.classConstructor = classConstructor

    makeObservable(this, {
      draftMessages: observable,
      populateDraftMessage: action,
      save: action,
    })
  }

  abstract isController(controller: unknown): controller is T
  abstract isModel(model: unknown): model is I

  populateDraftMessage(model: I) {
    const id = this.toStorageId(model)
    const obj = this.storage.get(id)
    this.draftMessages[model.id] = obj?.draft?.messageSerialized || null
  }

  create(model: I): T {
    const controller = this.classConstructor(model)
    const subscription = controller.states$.subscribe((state) => {
      this.save(controller, state)
    })
    const disposable = new DisposeBag(subscription)
    this.disposables.set(controller, disposable)
    return controller
  }

  override save(controller: T | null, serialized: SerializedInputBar) {
    if (!controller) return
    this.draftMessages[controller.model.id] = controller.messageSerialized
    super.save(controller, serialized)
  }

  getId(c: T | I): string {
    return this.isController(c) ? c.model.id : this.isModel(c) ? c.id : null
  }

  onDispose(controller: T) {
    this.disposables.get(controller).dispose()
  }
}
