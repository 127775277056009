import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import ReactionGroup from './group'
import type { IReactionGroup } from './group'

export { IReactionGroup }

export type ReactionSize = 20 | 24

export interface ReactionsProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  reactions: IReactionGroup[]
  size: ReactionSize
  onGroupClicked?(group: IReactionGroup): void
}

const Reactions: React.FC<ReactionsProps> = function ({
  reactions,
  size,
  onGroupClicked,
  ...props
}) {
  const styles = useStyles({ size })

  const createGroupClickedHandler = (group: IReactionGroup) => () => {
    onGroupClicked?.(group)
  }

  return (
    <div {...props} className={cx(styles.root, props.className)}>
      {reactions.map((group) => (
        <ReactionGroup
          key={group.emoji}
          size={size}
          {...group}
          onClick={createGroupClickedHandler(group)}
        />
      ))}
    </div>
  )
}

export default Reactions

const useStyles = makeStyles<Theme, Partial<ReactionsProps>>(
  (theme) => ({
    root: {
      display: 'flex',
      userSelect: 'none',
      lineHeight: ({ size }) => `${size}px`,
    },
  }),
  { name: Reactions.name },
)
