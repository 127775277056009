import Chip from '@material-ui/core/Chip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { useAppStore } from '@src/app/context'
import UserTag from '../../../component/user-tag'
import { fuzzySearch } from '../../../lib/search'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Member } from '../../../service/model'
import Content from '../common/content'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Item from '../common/item'
import TagListInput from '../common/taglist-input'

interface InviteContactUserCommandProps {
  onSave: (ids: string[]) => void
}

const InviteContactUserCommand: React.FC<InviteContactUserCommandProps> = function ({
  onSave,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const members = service.member.collection.list
  const [input, setInput] = useInputState('')
  const [shareWith, setShareWith] = useState<Member[]>([])

  const items = useMemo(() => {
    if (!input) return members
    return fuzzySearch(members, input, ['email', 'name'])
  }, [members, input])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    defaultSelectedIndex: 0,
    name: 'command/shared-width/invite',
    handleSelect: (item) => {
      if (!item) return
      if (!shareWith.find((m) => m.id === item.id)) {
        setShareWith([...shareWith, item])
        setInput('')
      }
    },
  })

  const handleDelete = (member: Member) => {
    setShareWith(shareWith.filter((s) => s.id !== member.id))
  }

  const handleSave = () => {
    onSave(shareWith.map((m) => m.id))
  }

  const renderTag = (member: Member) => {
    return (
      <Chip
        key={member.id}
        avatar={<AppAvatar identity={member} size={20} className={styles.avatar} />}
        label={member.name}
        onDelete={() => handleDelete(member)}
      />
    )
  }

  return (
    <>
      <Content>
        <TagListInput
          autoFocus
          items={shareWith}
          renderTag={renderTag}
          value={input}
          onChange={setInput}
          placeholder="Find a member"
        />
        <div className={styles.list}>
          {items.map((item, index) => (
            <Item
              key={item.id}
              {...getItemProps(index)}
              highlighted={selectedIndex === index}
            >
              <UserTag size="tiny" identity={item} />
            </Item>
          ))}
        </div>
      </Content>
      <Footer
        actions={
          <FooterButton variant="contained" onClick={handleSave}>
            Save
          </FooterButton>
        }
      />
    </>
  )
}

export default observer(InviteContactUserCommand)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  avatar: { flex: 'auto' },
  list: {
    maxHeight: 320,
    overflowY: 'auto',
  },
}))
