// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../../component/icon-button'
import { XIcon } from '../../../../component/icons/Tint/20/General'
import useKeyboardShortcuts from '../../../../lib/use-keyboard-shortcuts'
import { Conversation, Participant } from '../../../../service/model'
import ConversationUiStore from '../store'
import ParticipantInput, { ParticipantInputMethods } from './input'
import ParticipantChip from './participant'

interface AddParticipantsProps {
  store: ConversationUiStore
  conversation: Conversation
  onDiscard: (conversation: Conversation) => void
}

const AddParticipants: React.FC<AddParticipantsProps> = function ({
  store,
  conversation,
  onDiscard,
}) {
  const styles = useStyles({})
  const inputRef = useRef<ParticipantInputMethods>(null)
  const [editing, setEditing] = useState<Participant>(null)
  const participants = conversation.participants

  useKeyboardShortcuts({
    name: 'add-participants',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        event.preventDefault()
        onDiscard(conversation)
      }
    },
    dep: [onDiscard],
  })

  useEffect(() => {
    handleFocus()
  }, [])

  const handleSet = (number: string, oldNumber: string, type: 'click' | 'enter') => {
    if (type === 'enter') handleFocus()
    if (oldNumber) setEditing(null)
    store.addParticipant(number, oldNumber)
  }

  const handleDeleteLast = () => {
    store.deleteLastParticipant()
    handleFocus()
  }

  const handleDeleteParticipant = (participant: Participant) => {
    store.deleteParticipant(participant)
    handleFocus()
  }

  const handleFocus = () => {
    setTimeout(() => {
      if (!inputRef.current) {
        handleFocus()
        return
      }
      inputRef.current?.focus()
    }, 5)
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.label}>To:</div>
        <div className={styles.capsules}>
          {participants.map((p) =>
            p === editing ? (
              <ParticipantInput
                key={p.phoneNumber}
                defaultValue={p.phoneNumber}
                onSet={handleSet}
                onDeleteLast={handleDeleteLast}
              />
            ) : (
              <ParticipantChip
                key={p.phoneNumber}
                participant={p}
                onDelete={handleDeleteParticipant}
                onClick={() => setEditing(p)}
              />
            ),
          )}
          {participants.length < 9 && (
            <ParticipantInput
              ref={inputRef}
              onSet={handleSet}
              onDeleteLast={handleDeleteLast}
            />
          )}
          <div className={styles.cursor} onClick={handleFocus} />
        </div>
        <IconButton
          tabIndex={-1}
          title="Discard"
          shortcut="Esc"
          size="medium"
          color="default"
          onClick={() => onDiscard(conversation)}
          icon={<XIcon />}
          style={{ marginTop: 15 }}
        />
      </div>
    </div>
  )
}

export default observer(AddParticipants)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  wrapper: {
    maxWidth: 600,
    minHeight: 60,
    display: 'flex',
    alignItems: 'flex-start',
    width: 'calc(100% - 4rem)',
    margin: '0 auto',
  },
  label: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginRight: 10,
  },
  capsules: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,
    marginBottom: 10,
    minWidth: 0,
  },
  cursor: {
    flex: 1,
    cursor: 'text',
  },
}))
