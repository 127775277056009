import React from 'react'
import Typography from '@ui/Typography'
import { makeStyles } from '@material-ui/core'
import IconButton from '@src/component/icon-button'
import { ChevronLeftIcon, ChevronRightIcon } from '@src/component/icons/Tint/08'

interface PaginationProps {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  hasNextPage: boolean
  hasPrevPage: boolean
  handleNextPage: () => void
  handlePrevPage: () => void
}

function Pagination({
  currentPage,
  itemsPerPage = 10,
  totalItems,
  hasNextPage,
  hasPrevPage,
  handleNextPage,
  handlePrevPage,
}: PaginationProps) {
  const styles = useStyles()

  const from = (currentPage - 1) * itemsPerPage + 1
  const to = Math.min((currentPage - 1) * itemsPerPage + itemsPerPage, totalItems)

  return (
    <div className={styles.tableFooter}>
      <div className={styles.tableFooterLeftColumn}>
        <Typography variant="footnote" color="textTertiary">
          {`Showing ${from} - ${to} of ${totalItems}`}
        </Typography>
      </div>
      {(hasNextPage || hasPrevPage) && (
        <div className={styles.tableFooterRightColumn}>
          <IconButton
            title="Previous"
            onClick={handlePrevPage}
            icon={<ChevronLeftIcon />}
            disabled={!hasPrevPage}
          />
          <IconButton
            title="Next"
            onClick={handleNextPage}
            icon={<ChevronRightIcon />}
            disabled={!hasNextPage}
          />
        </div>
      )}
    </div>
  )
}

export default Pagination

const useStyles = makeStyles({
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
  },
  tableFooterRightColumn: {
    display: 'flex',
    columnGap: 6,
  },
  tableFooterLeftColumn: {
    flex: 1,
  },
})
