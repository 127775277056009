// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import { parseDate } from '../../lib'
import { Model } from './base'

export class Invite implements Model {
  id: string = null
  email: string = null
  org: {
    name: string
    pictureUrl: string
    numMembers?: number
  } = null
  role: string = null
  sentBy: string = null
  token: string = null
  updatedAt: number = null
  createdAt: number = null

  constructor() {
    makeAutoObservable(this, {})
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      email: this.email,
      org: this.org,
      role: this.role,
      sentBy: this.sentBy,
      token: this.token,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
    }
  }
}
