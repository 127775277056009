// @ts-strict-ignore
type SPEC = {
  readonly radix: number
  readonly unit: string[]
}

export type FileType =
  | 'audio'
  | 'blank'
  | 'contact'
  | 'csv'
  | 'doc'
  | 'image'
  | 'pdf'
  | 'presentation'
  | 'spreadsheet'
  | 'text'
  | 'video'
  | 'zip'

const si = { radix: 1e3, unit: ['b', 'kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'] }
const iec = {
  radix: 1024,
  unit: ['b', 'Kib', 'Mib', 'Gib', 'Tib', 'Pib', 'Eib', 'Zib', 'Yib'],
}
const jedec = { radix: 1024, unit: ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'] }

export const SPECS: Record<string, SPEC> = {
  si,
  iec,
  jedec,
}

export function fileSize(bytes: number, fixed = 1, spec?: string): string {
  bytes = Math.abs(bytes)

  const { radix, unit } = SPECS[spec] || SPECS['jedec']

  let loop = 0

  // calculate
  while (bytes >= radix) {
    bytes /= radix
    ++loop
  }
  return `${bytes.toFixed(fixed)} ${unit[loop]}`
}

export function fileType(contentType: string, path: string): FileType {
  if (!contentType) return 'blank'

  const type = contentType.split(';')[0]
  const ext = path?.split('.').pop()

  switch (type) {
    case 'application/x-pdf':
    case 'application/pdf':
      return 'pdf'

    case 'text/csv':
      return 'csv'

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'spreadsheet'

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'presentation'

    case 'application/msword':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return 'doc'

    case 'application/zip':
      return 'zip'

    case 'text/vcard':
    case 'text/x-vcard':
      return 'contact'

    case 'text/directory':
      if (ext === 'vcf') {
        return 'contact'
      }
  }

  if (contentType.startsWith('audio/')) {
    return 'audio'
  } else if (contentType.startsWith('image/')) {
    return 'image'
  } else if (contentType.startsWith('video/')) {
    return 'video'
  }

  return 'blank'
}
