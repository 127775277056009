// @ts-strict-ignore
import { makeStyles, TableRow, Theme } from '@material-ui/core'
import WebhookEventsTableCell from '@src/app/settings/webhooks/events-table/WebhookEventsTableCell'
import PopoverMenu from '@src/component/popover-menu'
import Typography from '@ui/Typography'
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { InfoIcon, MoreIcon, ReplaceIcon } from '@src/component/icons/Tint/20/General'
import IconButton from '@src/component/icon-button'
import { MenuItem } from '@src/component/menu-v2'
import { dayjs, formatDate } from '@src/lib'
import { useAppStore } from '@src/app/context'
import { getStatusCodeColor, getStatusBackgroundColor, HttpStatusCodes } from '@src/data'
import { WebhookEventsResultObject } from '@src/service'
import { fonts } from '@src/theme'
import { Link, useRouteMatch } from 'react-router-dom'
import { getLastHttpCallFromEvent } from './WebhookEventsTable'
import Tooltip from '@src/component/tooltip'

interface WebhookEventsTableRowProps {
  event: WebhookEventsResultObject
  handleResendEvent: () => Promise<void>
}

const WebhookEventsTableRow = ({
  event,
  handleResendEvent,
}: WebhookEventsTableRowProps) => {
  const newestHttpCall = useMemo(() => getLastHttpCallFromEvent(event), [event])

  const statusCode = Number.isNaN(parseInt(newestHttpCall.statusCode, 10))
    ? null
    : parseInt(newestHttpCall.statusCode, 10)

  const timeToNextRun = useMemo(() => {
    return event.nextRun ? `Automatically retries ${dayjs(event.nextRun).fromNow()}` : ''
  }, [newestHttpCall, event.nextRun])

  const [isRetrying, setIsRetrying] = useState(false)

  const styles = useStyles({ statusCode: isRetrying ? 0 : statusCode })
  const [anchorEl, setAnchorEl] = useState(null)
  const { history } = useAppStore()

  const { url } = useRouteMatch()

  const handleTogglePopover: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleOpenEventDetails = useCallback(() => {
    setAnchorEl(null)
    history.push(`/settings/webhooks/${event.webhookId}/event/${event.id}`)
  }, [event, statusCode, history])

  const handleRetry = useCallback(async () => {
    setAnchorEl(null)
    setIsRetrying(true)
    await handleResendEvent()
    setIsRetrying(false)
  }, [handleResendEvent])

  return (
    <TableRow>
      <WebhookEventsTableCell>
        <Link to={`${url}/event/${event.id}`} className={styles.link}>
          <Typography variant="footnote" color="textPrimary" className={styles.eventType}>
            {event?.data?.object?.type}
          </Typography>
        </Link>
      </WebhookEventsTableCell>
      <WebhookEventsTableCell>
        <div className={styles.dateTimeRow}>
          <Typography variant="footnote" color="textSecondary">
            {formatDate(newestHttpCall.executedAt, 'MMM D, YYYY')}
          </Typography>
          &nbsp;
          <Typography variant="footnote" color="textTertiary">
            {formatDate(newestHttpCall.executedAt, 'HH:mm')}
          </Typography>
        </div>
      </WebhookEventsTableCell>
      <WebhookEventsTableCell>
        <Tooltip title={timeToNextRun}>
          <Typography variant="footnote" color="textSecondary" className={styles.status}>
            {!isRetrying && statusCode ? `• ${statusCode}` : 'In Retry'}
          </Typography>
        </Tooltip>
      </WebhookEventsTableCell>
      <WebhookEventsTableCell>
        <Typography variant="footnote" color="textSecondary">
          <span className={styles.moreBtnContainer}>
            <IconButton
              onClick={handleTogglePopover}
              icon={<MoreIcon />}
              title="Actions"
            />
          </span>
          <PopoverMenu
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: -8, horizontal: 'right' }}
            elevation={2}
          >
            <MenuItem icon={<InfoIcon />} onClick={handleOpenEventDetails}>
              <Typography>Event details</Typography>
            </MenuItem>
            <MenuItem icon={<ReplaceIcon />} onClick={handleRetry}>
              <Typography>Retry request</Typography>
            </MenuItem>
          </PopoverMenu>
        </Typography>
      </WebhookEventsTableCell>
    </TableRow>
  )
}

const useStyles = makeStyles<Theme, { statusCode: number | undefined }>(
  (theme: Theme) => ({
    root: {},
    status: ({ statusCode }) => ({
      color: getStatusCodeColor(theme, statusCode),
      backgroundColor: getStatusBackgroundColor(theme, statusCode),
      padding: '5px 7px',
      borderRadius: 5,
      width: 'fit-content',
    }),
    moreBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dateTimeRow: {
      display: 'flex',
      alignItems: 'center',
    },
    eventType: {
      fontFamily: fonts.jetBrainsMono,
    },
    link: {
      textDecoration: 'none',
      outline: 'none',

      '&:focus-visible': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: WebhookEventsTableRow.name },
)

export default WebhookEventsTableRow
