// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import InputBar from '../input-bar'
import { friendlyDate } from '../../lib/date'
import Feed from '../feed'
import { PadlockIcon } from '../icons/Tint/12/General'
import Typography from '@ui/Typography'
import Controller, { CommentFeedItem } from './controller'
import { defaultThreadDimensions } from './dimensions'
import CommentsHeader from './header'
import Collapse from '@ui/Collapse'

export { Controller, CommentFeedItem }

export interface CommentsProps<I extends CommentFeedItem> {
  controller: Controller<I>
  renderMention?(mention: string, item?: I): React.ReactNode
}

const Comments = function <I extends CommentFeedItem>({
  controller,
  renderMention,
}: CommentsProps<I>) {
  const styles = useStyles({ collapsed: controller.collapsed })
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref} className={styles.root}>
      {controller.items.length > 0 ? (
        <CommentsHeader controller={controller} className={styles.header} />
      ) : null}
      <Collapse collapsed={controller.collapsed}>
        {controller.items.length > 0 ? (
          <div className={styles.comments}>
            {controller.previousCommentCount > 0 ? (
              <div
                className={styles.previous}
                onClick={controller.handleShowPreviousCommentsClick}
              >
                <div className={styles.previousStack}>
                  <Typography variant="caption1" color="inherit" fontWeight="regular">
                    &nbsp;
                  </Typography>
                  <Typography variant="caption1" color="inherit" fontWeight="regular">
                    &nbsp;
                  </Typography>
                  <Typography variant="caption1" color="inherit" fontWeight="regular">
                    {controller.previousCommentCount} previous comment
                    {controller.previousCommentCount === 1 ? '' : 's'}
                  </Typography>
                </div>
              </div>
            ) : null}
            <Feed controller={controller.feedController} renderMention={renderMention} />
            {controller.resolved ? (
              <Typography
                variant="caption2"
                color="textTertiary"
                className={styles.status}
              >
                {controller.resolvedBy.name} resolved this thread{' '}
                {friendlyDate(controller.resolvedAt, { upperFirst: false })}
              </Typography>
            ) : null}
          </div>
        ) : null}
        <div className={styles.input}>
          <InputBar
            attachmentDropTargetRef={ref}
            controller={controller.inputBarController}
            variant="collapsed"
            colorScheme="note"
            placeholder={
              controller.items.length === 0
                ? 'Write an internal comment…'
                : 'Reply internally…'
            }
            renderMention={renderMention}
          />
          {controller.items.length === 0 ? (
            <div className={styles.footer}>
              <div className={styles.footerMessage}>
                <PadlockIcon className={styles.footerIcon} />
                <Typography variant="caption2" color="textSecondary">
                  Threads are only visible to your workspace
                </Typography>
              </div>
              <div className={styles.cancel} onClick={controller.handleCancel}>
                <Typography variant="caption2" color="textTertiary">
                  Cancel
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      </Collapse>
    </div>
  )
}

export default observer(Comments)

const useStyles = makeStyles<Theme, { collapsed: boolean }>(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.op.match({
        light: theme.palette.op.background.popover,
        dark: theme.palette.op.background.primary,
      }),
      boxShadow: theme.palette.op.shadow.comments,
      borderRadius: ({ collapsed }) =>
        collapsed
          ? theme.palette.op.borderRadius.comments.closed
          : theme.palette.op.borderRadius.comments.open,
      transition: theme.transitions.create(['border-radius'], {
        duration: 200,
        easing: 'ease',
      }),
    },
    comments: {
      margin: `${defaultThreadDimensions.commentsMarginTop}px ${defaultThreadDimensions.commentsMarginHorizontal}px ${defaultThreadDimensions.commentsMarginBottom}px`,
    },
    previous: {
      display: 'flex',
      color: theme.palette.op.note.label,
      margin: `${defaultThreadDimensions.previousMarginTop}px ${defaultThreadDimensions.previousMarginHorizontal}px 0`,
      cursor: 'pointer',
    },
    previousStack: {
      display: 'flex',
      flexDirection: 'column-reverse',

      '& > *': {
        backgroundColor: theme.palette.background.default,
        marginTop: defaultThreadDimensions.previousStackItemMarginTop,
        padding: `${defaultThreadDimensions.previousStackItemPaddingVertical}px ${defaultThreadDimensions.previousStackItemPaddingHorizontal}px`,
        boxShadow: `0 1px 1px 0 ${alpha(
          theme.palette.op.note.text,
          0.06,
        )}, inset 0 0 0 1px ${alpha(theme.palette.op.note.label, 0.22)}`,
        borderRadius: 15,
      },
    },
    input: {
      margin: defaultThreadDimensions.rootPadding,
    },
    status: {
      margin: defaultThreadDimensions.rootPadding,
      textAlign: 'center',
    },
    header: {
      height: defaultThreadDimensions.headerHeight,
      boxShadow: ({ collapsed }) =>
        collapsed ? null : `0 1.5px 0 0 ${theme.palette.op.border.common}`,
      padding: `0 ${defaultThreadDimensions.headerPaddingHorizontal}px`,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      margin: `${defaultThreadDimensions.footerMarginVertical}px 0`,
    },
    footerMessage: {
      display: 'flex',
      flex: 1,
    },
    footerIcon: {
      width: 12,
      height: 12,
      color: theme.palette.op.note.highlight,
      marginRight: 4,
    },
    cancel: {
      cursor: 'pointer',
    },
  }),
  { name: Comments.name },
)
