// @ts-strict-ignore
import { useEffect, useRef } from 'react'

/**
 * Create a timeout bound to the lifetime of the component
 *
 * Returns two functions:
 *  - The first starts a timer for `handler` and cancels the existing timer (if necessary)
 *  - The second will cancel the timer
 */
export default function useTimeout(
  duration: number,
  handler: () => void,
  deps: any[] = [],
): [start: () => void, cancel: () => void] {
  const timeoutRef = useRef<number>(null)

  useEffect(() => cancel, deps)

  const cancel = () => window.clearTimeout(timeoutRef.current)
  const start = () => {
    cancel()
    timeoutRef.current = window.setTimeout(handler, duration)
  }

  return [start, cancel]
}
