import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import cx from 'classnames'

export interface SelectToggleProps {
  onToggle: (option: string) => void
  options: { value: string; title: string }[]
  value: string
}

const SelectToggle: React.FC<SelectToggleProps> = ({ onToggle, options, value }) => {
  const styles = useStyles({})

  return (
    <div className={styles.swapButtons}>
      {options.map((option) => (
        <div
          key={option.value}
          className={cx(styles.swapBtn, {
            [styles.swapBtnActive]: option.value === value,
          })}
          onClick={() => {
            onToggle(option.value)
          }}
        >
          {option.title}
        </div>
      ))}
    </div>
  )
}

export default SelectToggle

const useStyles = makeStyles(
  (theme) => ({
    swapButtons: {
      width: '216px',
      cursor: 'pointer',
      padding: '4px',
      borderRadius: '7px',
      backgroundColor: theme.palette.op.hover.primary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    swapBtn: {
      width: '104px',
      padding: '6px',
      textAlign: 'center',
      borderRadius: 4,
      color: theme.palette.op.gray[3],
      fontSize: 14,
      fontWeight: 450,
    },
    swapBtnActive: {
      backgroundColor: theme.palette.op.background.primary,
      color: theme.palette.op.gray[1],
      fontWeight: 550,
    },
  }),
  { name: 'SelectToggle' },
)
