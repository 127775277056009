import { makeStyles, Theme } from '@material-ui/core'
import { useAppStore } from '@src/app/context'
import { Card, CardActions, CardContent } from '@src/app/settings/settings-card'
import Button from '@src/component/button-v2'
import IconButton from '@src/component/icon-button'
import { PremiumIcon } from '@src/component/icons/Tint/12/General'
import { XIcon } from '@src/component/icons/Tint/16/General'
import { daysBefore, friendlyDate, toFormattedDollars } from '@src/lib'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import Loading from './Loading'
import UpgradeToPremiumIllustration from '@src/component/illustrations/UpgradeToPremium'
import Illustration from '@src/component/illustrations'
import { action } from 'mobx'
import { Typography } from '@ui/Typography'

interface BillingPlanSectionProps {}

const BillingPlanSection = (props: BillingPlanSectionProps) => {
  const styles = useStyles()
  const [loading, setLoading] = useState(false)
  const { service, toast, history } = useAppStore()

  const subscription = service.billing.subscription
  const upcomingInvoice = service.billing.upcomingInvoice

  const currentPeriodExpiresAtFormatted = friendlyDate(
    subscription.currentPeriodExpiresAt,
  )

  const handleRestart = () => {
    setLoading(true)
    service.billing
      .restart()
      .then(() => toast.show({ message: 'Great to have you back!' }))
      .catch(toast.showError)
      .finally(() => setLoading(false))
  }

  const handleEndTrial = () => {
    setLoading(true)
    service.billing
      .endTrial()
      .then(() => toast.show({ message: 'Your trial period has ended.' }))
      .catch(toast.showError)
      .finally(() => setLoading(false))
  }

  const daysBeforeTrialEnd = daysBefore(subscription.currentPeriodExpiresAt)

  const handleUpgrade = () => history.push('/settings/billing/plans')

  const handleCloseBanner = action(() => {
    service.billing.showPremiumBanner = false
  })

  const showPremiumBanner =
    !subscription.isCanceled &&
    !subscription.isPremium &&
    service.billing.showPremiumBanner

  return (
    <div>
      <div>
        <Typography variant="title3" color="textPrimary" fontWeight="semibold">
          {subscription.isPremium ? 'OpenPhone Premium Plan' : 'OpenPhone Standard Plan'}
        </Typography>

        {subscription.isCanceled ? (
          <Typography
            style={{ marginTop: 6 }}
            variant="body"
            color="textSecondary"
          >{`Your subscription will not renew after ${currentPeriodExpiresAtFormatted}.`}</Typography>
        ) : (
          <div style={{ marginTop: 6, display: 'flex' }}>
            <Typography variant="body" color="textSecondary">
              {'You will next be charged ' +
                (subscription?.trial ? 'your first monthly invoice ' : '' + 'on')}
            </Typography>
            &nbsp;
            <Typography variant="body">{currentPeriodExpiresAtFormatted}</Typography>
          </div>
        )}
      </div>
      {showPremiumBanner && (
        <UpgradeToPremium handleUpgrade={handleUpgrade} onClose={handleCloseBanner} />
      )}
      <CardContent className={styles.rowContent}>
        {subscription?.store === 'stripe' ? (
          <div>
            {loading && <Loading title="Loading your subscription data" />}
            {upcomingInvoice && (
              <div className={styles.content}>
                {upcomingInvoice.lines.data.map((line) => (
                  <div className={styles.planRow} key={line.id}>
                    <Typography variant="callout" color="textPrimary">
                      {line.description}
                    </Typography>
                    <Typography>{toFormattedDollars(line.amount)}</Typography>
                  </div>
                ))}
                {upcomingInvoice.discount?.coupon && (
                  <div className={styles.planRow}>
                    <Typography variant="callout" color="textPrimary">
                      Discount ({upcomingInvoice.discount.coupon.name})
                    </Typography>
                    <Typography variant="callout" color="textPrimary">
                      {upcomingInvoice.discount.coupon.percent_off}% off for{' '}
                      {upcomingInvoice.discount.coupon.duration_in_months} months
                    </Typography>
                  </div>
                )}
                {upcomingInvoice.starting_balance < 0 && (
                  <div className={styles.planRow}>
                    <Typography variant="callout" color="textPrimary">
                      Credit
                    </Typography>
                    <Typography variant="callout" color="textPrimary">
                      {toFormattedDollars(upcomingInvoice.starting_balance)}
                    </Typography>
                  </div>
                )}
                <div className={styles.planRow}>
                  <Typography variant="callout" color="textPrimary" fontWeight="semibold">
                    Total
                  </Typography>
                  <Typography variant="callout" color="textPrimary" fontWeight="semibold">
                    {toFormattedDollars(upcomingInvoice.amount_due)}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Typography variant="body" color="textPrimary">
            Your subscription is managed by{' '}
            {subscription?.store === 'ios' ? 'Apple' : 'Google'}. To see the details or
            make any changes, visit{' '}
            {subscription?.store === 'ios' ? 'App Store' : 'Google Play'} on your phone.
          </Typography>
        )}
      </CardContent>
      <CardActions className={styles.rowActions}>
        {subscription?.isCanceled ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRestart}
            disabled={loading['restart']}
          >
            Restart your subscription
          </Button>
        ) : !subscription?.trial ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => history.push('/settings/billing/plans')}
          >
            Change your plan
          </Button>
        ) : null}
        {subscription?.trial && !subscription?.isCanceled && (
          <div className={styles.switchToPaid}>
            <Button
              color="primary"
              onClick={handleEndTrial}
              disabled={loading['convertTrial']}
            >
              Switch to paid plan now
            </Button>
            <Typography
              className={styles.switchToPaidHint}
              variant="footnote"
              color="textTertiary"
            >
              {`Don’t want to wait ${daysBeforeTrialEnd} days? Start your paid plan now!`}
            </Typography>
          </div>
        )}
      </CardActions>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    rowHeader: {
      padding: 0,
    },
    rowContent: {
      padding: 0,
    },
    rowActions: {
      padding: '23px 0',
    },
    content: {
      marginTop: 24,
    },
    planRow: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 36,
      padding: '9px 0',
    },
    switchToPaid: {
      display: 'flex',
      alignItems: 'center',
    },
    switchToPaidHint: {
      marginLeft: 32,
    },
  }),
  { name: BillingPlanSection.name },
)

const UpgradeToPremium = ({
  onClose,
  handleUpgrade,
}: {
  onClose: () => void
  handleUpgrade: () => void
}) => {
  const styles = useUpgradeToPremiumStyles()

  return (
    <Card className={styles.root}>
      <div style={{ minWidth: 106, minHeight: 106 }}>
        <Illustration
          illustration={UpgradeToPremiumIllustration}
          width={106}
          height={106}
        />
      </div>

      <div className={styles.rightColumn}>
        <Typography variant="title3" color="textPrimary" fontWeight="semibold">
          Upgrade to Premium
        </Typography>
        <Typography variant="body" color="textSecondary" className={styles.description}>
          Supercharge your workspace and unlock advanced features such as call transfers,
          HubSpot integration, detailed analytics, and much more!
        </Typography>
        <Button onClick={handleUpgrade} startIcon={<PremiumIcon />}>
          Upgrade now
        </Button>
      </div>
      <IconButton icon={<XIcon />} onClick={onClose} />
    </Card>
  )
}

const useUpgradeToPremiumStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      padding: 24,
      margin: '24px 1.5px',
    },
    rightColumn: {
      marginLeft: 25,
    },
    description: {
      margin: '6px 0px 14px 0px',
    },
  }),
  { name: UpgradeToPremium.name },
)

export default observer(BillingPlanSection)
