import React from 'react'
import Popover, { PopoverMenuProps } from '../../component/popover-menu'
import EmojiList from './list'

export interface EmojiPickerProps extends PopoverMenuProps {
  skinTone?: string
  onEmojiSelected?: (emoji: string) => void
}

const EmojiPicker: React.FC<EmojiPickerProps> = function ({
  onEmojiSelected,
  skinTone,
  ...props
}) {
  return (
    <Popover tabIndex={-1} {...props}>
      <EmojiList onEmojiSelected={onEmojiSelected} skinTone={skinTone} />
    </Popover>
  )
}

export default EmojiPicker
