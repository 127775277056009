// @ts-strict-ignore
import { WebhooksPageTableCell as Cell } from './WebhooksPageTableCell'
import { makeStyles, TableRow, Theme } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useState } from 'react'
import IconButton from '../../../../component/icon-button'
import {
  DeleteIcon,
  MoreIcon,
  WebhookIcon,
} from '../../../../component/icons/Tint/20/General'
import PopoverMenu, { MenuItem } from '../../../../component/popover-menu'
import Switch from '../../../../component/switch'
import Typography from '@ui/Typography'
import { formatDate } from '../../../../lib'
import { WebhookModel } from '../../../../service/model/WebhookModel'
import { useAppStore } from '@src/app/context'
import { GeneralCopyIcon } from '../../../../component/icons/Tint/16/General'
import cx from 'classnames'
import Tooltip from '../../../../component/tooltip'
import { action } from 'mobx'
import useIsOverflowing from '@src/lib/useIsOverflowing'
import { Link } from 'react-router-dom'

interface NewComponentProps {
  webhook: WebhookModel
}

const WebhooksPageTableRow = ({ webhook }: NewComponentProps) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { toast, history } = useAppStore()

  const handleTogglePopover: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleEnabledSwitch = action(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      webhook.enabled = checked
      webhook.save()
    },
  )

  const handleDelete = () => {
    webhook.delete()
    toast.show({ message: 'The webhooks has been deleted!' })
  }

  return (
    <TableRow classes={{ root: styles.root }}>
      <Cell>
        <URLCell webhook={webhook} />
      </Cell>
      <Cell align="left" width="106">
        <LabelCell label={webhook.label} />
      </Cell>
      <Cell align="left" width="106">
        <div>
          <Typography variant="footnote" color="textTertiary">
            {formatDate(webhook.createdAt, 'MMM D, YYYY')}
          </Typography>
        </div>
      </Cell>
      <Cell align="center" width="50">
        <div>
          <Switch checked={webhook.enabled} onChange={handleEnabledSwitch} />
        </div>
      </Cell>
      <Cell align="left" width="50">
        <span className={styles.actions}>
          <IconButton onClick={handleTogglePopover} icon={<MoreIcon />} title="Actions" />
        </span>
        <PopoverMenu
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
          elevation={2}
          classes={{ paper: styles.popOver }}
        >
          <MenuItem
            icon={<WebhookIcon />}
            onClick={() => history.push(`/settings/webhooks/${webhook.id}`)}
          >
            <Typography variant="footnote">Webhook details</Typography>
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon className={styles.deleteIcon} />}
            onClick={handleDelete}
          >
            <Typography color="error" variant="footnote">
              Delete webhook
            </Typography>
          </MenuItem>
        </PopoverMenu>
      </Cell>
    </TableRow>
  )
}

const URLCell = ({ webhook }: NewComponentProps) => {
  const styles = useStyles()
  const { isOverflowing, ref } = useIsOverflowing()
  const { toast, history } = useAppStore()

  const handleCopyUrlToClipboard = () => {
    navigator.clipboard.writeText(webhook.url)
    toast.show({ message: `Copied to clipboard.` })
  }

  return (
    <div className={styles.url}>
      <Link to={`/settings/webhooks/${webhook.id}`} className={styles.webhookLink}>
        <Tooltip title={isOverflowing ? webhook.url : ''} placement="bottom-start">
          <Typography variant="footnote" nowrap ref={ref}>
            {webhook.url}
          </Typography>
        </Tooltip>
      </Link>
      <IconButton
        icon={<GeneralCopyIcon />}
        className={styles.copyIcon}
        title="Copy URL"
        onClick={handleCopyUrlToClipboard}
      />
    </div>
  )
}

const LabelCell = ({ label }: { label?: string }) => {
  const styles = useStyles()
  const { isOverflowing, ref } = useIsOverflowing()
  return (
    <div className={styles.label}>
      <Tooltip title={label && isOverflowing ? label : null}>
        <Typography variant="footnote" color="textSecondary" nowrap ref={ref}>
          {!label ? '–' : label}
        </Typography>
      </Tooltip>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: '2px 0px',
      borderRadius: 7,
      '&:hover, &:focus-within': {
        background: theme.palette.op.hover.primary,
      },
    },
    deleteIcon: {
      color: theme.palette.op.secondary.red2,
    },
    webhookLink: {
      textDecoration: 'none',
      outline: 'none',
      padding: '10px 0',
      flex: 1,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      maxHeight: 40,
      width: '100%',
      columnGap: 4,
    },
    label: {
      maxWidth: 100,
    },
    copyIcon: {
      opacity: 0,
      '$root:hover &': {
        opacity: 1,
      },
      '&:focus-visible': {
        opacity: 1,
      },
    },
    actions: {
      display: 'flex',
    },
    popOver: {
      width: 210,
    },
  }),
  { name: WebhooksPageTableRow.name },
)

export default observer(WebhooksPageTableRow)
