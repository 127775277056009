import React, { useState } from 'react'
import cx from 'classnames'
import { colors } from '../../theme'
import { makeStyles, useTheme, Theme, darken } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { BannerConfig, getBannerConfig, setBannerConfig } from '../../lib/user-config'

interface BannerProps extends React.HTMLProps<HTMLDivElement> {
  tag?: keyof BannerConfig
  onClick: () => void
}

const Banner: React.FC<BannerProps> = function ({
  tag,
  onClick,
  children,
  className,
  ...props
}) {
  const theme = useTheme()
  const styles = useStyles(theme)
  const [show, setShow] = useState(tag ? getBannerConfig(tag) : true)

  const handleClose = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    setShow(false)
    if (tag) {
      setBannerConfig(tag, false)
    }
  }

  return show ? (
    <div {...props} className={cx(styles.root, className)} onClick={onClick}>
      {children}
      <IconButton className={styles.close} size="small" onClick={handleClose}>
        <CloseIcon color="inherit" fontSize="small" />
      </IconButton>
    </div>
  ) : null
}

export default Banner

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    outline: 'none',
    border: 'none',
    margin: 0,
    textAlign: 'center',
    position: 'relative',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.op.primary[1],
    color: theme.palette.getContrastText(theme.palette.op.primary[1]),
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    zIndex: 1,

    '&:hover, &:focus': {
      background: darken(theme.palette.op.primary[1], 0.2),
    },
  },
  close: {
    color: 'inherit',
    position: 'absolute',
    top: 1,
    right: 16,
  },
}))
