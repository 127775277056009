import { RoleName } from '../../..'

export const RolesOptions: { name: string; value: RoleName; description: string }[] = [
  {
    name: 'member',
    value: 'member',
    description:
      'Can manage their own account and have phone numbers shared with them. Cannot manage the Workspace.',
  },
  {
    name: 'admin',
    value: 'admin',
    description:
      'Can manage the Workspace, add or remove people from phone numbers, and edit roles. Cannot add or remove Owners.',
  },
  {
    name: 'owner',
    value: 'owner',
    description:
      'Same as Admin, plus can add other Owners and has total access over the Workspace.',
  },
]
