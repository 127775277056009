// @ts-strict-ignore
import type { ModalProps } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { Reducer, useContext, useEffect, useReducer, useRef } from 'react'
import { Popover } from '../../../component/menu'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import EditCompanyName, { EditCompanyNameProps } from './company'
import EditDate, { EditDateItemProps } from './date'
import EditContactItemName, { EditContactItemNameProps } from './name'
import EditPhoneNumber, { EditPhoneNumberProps } from './phone-number'
import EditStringItem, { EditStringItemProps } from './string'
import EditTags, { EditTagsProps } from './tags'

type Command =
  | ({ name: 'edit name'; onDiscard?: () => void } & EditContactItemNameProps)
  | ({ name: 'edit company'; onDiscard?: () => void } & EditCompanyNameProps)
  | ({ name: 'edit phone number'; onDiscard?: () => void } & EditPhoneNumberProps)
  | ({ name: 'edit string'; onDiscard?: () => void } & EditStringItemProps)
  | ({ name: 'edit tags'; onDiscard?: () => void } & EditTagsProps)
  | ({ name: 'edit date'; clear?: boolean; onDiscard?: () => void } & EditDateItemProps)

type Action =
  | { type: 'show editor'; anchorEl: Element; command: Command }
  | { type: 'backdrop clicked' }
  | { type: 'hide editor' }

type Dispatch = React.Dispatch<Action>

interface State {
  open: boolean
  backdropClicked: boolean
  anchorEl: Element
  command: Command
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'show editor':
      return {
        open: true,
        backdropClicked: false,
        anchorEl: action.anchorEl,
        command: action.command,
      }
    case 'backdrop clicked':
      return { ...state, backdropClicked: true }
    case 'hide editor':
      return { open: false, backdropClicked: false, anchorEl: null, command: null }
    default:
      return state
  }
}

const ContactEditorStateContext = React.createContext<State>(undefined)
const ContactEditorDispatchContext = React.createContext<Dispatch>(undefined)

const ContactEditor: React.FC<{}> = function ({ children }) {
  const styles = useStyles({})
  const ref = useRef<HTMLDivElement>(null)
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    backdropClicked: false,
    anchorEl: null,
    command: null,
  })

  const open = Boolean(state.anchorEl)

  useKeyboardShortcuts({
    name: 'editor',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape' && open) {
        event.preventDefault()
        state.command.onDiscard && state.command.onDiscard()
        dispatch({ type: 'hide editor' })
      }
    },
    dep: [open],
  })

  const handleBackdropClick = () => {
    dispatch({ type: 'backdrop clicked' })
  }

  const handleClose: ModalProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') {
      handleBackdropClick()
    }
  }

  let width = state.anchorEl?.getBoundingClientRect().width + 10 || 210
  if (state.command?.name === 'edit date') {
    width = Math.max(250, width)
  }

  return (
    <ContactEditorStateContext.Provider value={state}>
      <ContactEditorDispatchContext.Provider value={dispatch}>
        <Popover
          open={open}
          anchorEl={state.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleClose}
          disablePortal
          className={styles.root}
          disableEscapeKeyDown
          transitionDuration={0}
          BackdropProps={{
            style: { backgroundColor: 'none' },
          }}
        >
          <div ref={ref} className={styles.popover} style={{ width }}>
            {state.command?.name === 'edit name' ? (
              <EditContactItemName {...state.command} />
            ) : state.command?.name === 'edit phone number' ? (
              <EditPhoneNumber {...state.command} />
            ) : state.command?.name === 'edit string' ? (
              <EditStringItem {...state.command} />
            ) : state.command?.name === 'edit company' ? (
              <EditCompanyName {...state.command} />
            ) : state.command?.name === 'edit tags' ? (
              <EditTags {...state.command} />
            ) : state.command?.name === 'edit date' ? (
              <EditDate {...state.command} />
            ) : null}
          </div>
        </Popover>
        {children}
      </ContactEditorDispatchContext.Provider>
    </ContactEditorStateContext.Provider>
  )
}

export default observer(ContactEditor)

export function useEditor(): [(anchor: Element, command: Command) => void, () => void] {
  const dispatch = useContext(ContactEditorDispatchContext)
  const run = (anchorEl, command) => dispatch({ type: 'show editor', anchorEl, command })
  const close = () => dispatch({ type: 'hide editor' })
  return [run, close]
}

export function useBackdropClick(handle: () => void, deps: any[]): void {
  const state = useContext(ContactEditorStateContext)
  useEffect(() => {
    if (state.backdropClicked) {
      handle()
    }
  }, [state.backdropClicked, ...deps])
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    outline: 'none',
    marginTop: -3,
    marginLeft: 8,
  },
  popover: {
    outline: 'none',
  },
}))
