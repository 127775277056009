// @ts-strict-ignore
import { action, makeAutoObservable } from 'mobx'
import Service from '.'
import { PersistedCollection, Snippet } from './model'
import { SnippetRepository } from './worker/repository'

export default class SnippetsService {
  collection: PersistedCollection<Snippet, SnippetRepository> = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('snippet'),
      classConstructor: () => new Snippet(root),
    })

    makeAutoObservable(this, {})

    this.subscribeToWebSocket()
  }

  fetch = () => {
    this.collection.performQuery((repo) => repo.all())
    return this.root.transport.snippets
      .list()
      .then(action((res) => this.collection.load(res, { deleteOthers: true })))
  }

  save = (snippet: Snippet) => {
    this.root.snippet.collection.put(snippet)
    return this.root.transport.snippets.put(snippet.serialize())
  }

  delete = (snippet: Snippet) => {
    this.collection.delete(snippet)
    return this.root.transport.snippets.delete(snippet)
  }

  private subscribeToWebSocket() {
    this.root.transport.onMessage.subscribe((message: any) => {
      if (!('type' in message)) return
      if (message.type === 'canned-message-update') {
        this.collection.load(message.message)
      } else if (message.type === 'canned-message-delete') {
        this.collection.delete(message.message.id)
      }
    })
  }
}
