import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import TextInput from '@ui/TextInput'
import useInputState from '../../lib/use-input-state'
import { AppAvatar } from '@src/app/components'
import { Header, Item, Page } from './common'

interface CompanyProps {}

const Company: React.FC<CompanyProps> = function ({}) {
  const { command, service } = useAppStore()
  const org = service.organization.current
  const [name, setName] = useInputState(org.name || '')

  useEffect(() => {
    setName(org.name || '')
  }, [])

  const handleSave = () => {
    if (org.name == name) return
    org.update({ name })
  }

  const handleOpenUpload = () => {
    command.present({
      name: 'upload',
      accept: ['image/jpeg', 'image/png', 'image/gif'],
      title: 'Upload a new photo',
      description: 'Drag and drop an image here, or click to select a file.',
      onComplete: onPhotoUploaded,
    })
  }

  const onPhotoUploaded = (url: string) => {
    org.update({ pictureUrl: url })
    command.hide()
  }

  return (
    <Page>
      <Header title="Workspace" subtitle="Edit your workspace details" />
      <div>
        <Item
          title="Logo"
          description="Upload a logo for your workspace"
          input={
            <Button variant="text" onClick={handleOpenUpload}>
              <AppAvatar size={80} identity={org} />
            </Button>
          }
        />
        <Item
          title="Workspace name"
          description="This could be your company, team, department, etc."
          input={<TextInput value={name} onChange={setName} onBlur={handleSave} />}
        />
      </div>
    </Page>
  )
}

export default observer(Company)
