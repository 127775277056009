import React from 'react'
import { Route, Router as ReactRouter, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import ThirdpartyAuthorize from '../authorize'
import Callback from '../callback'
import Flagged from '../flagged'
import Login from '../login'
import NativeLogin from '../login/native'
import Main from '../main'
import Onboarding from '../onboarding'
import DialogRouter from './DialogRouter'
import Redirect from './Redirect'

const Router = () => {
  const app = useAppStore()

  return (
    <ReactRouter history={app.history.history}>
      <Switch>
        <Route path={'/native/login'} component={NativeLogin} />
        {!app.isLoggedIn && (
          <Route
            path={['/login', '/login/code', '/signup', '/coupon']}
            component={Login}
          />
        )}
        <Route path="/callback/:provider" component={Callback} />
        {!app.isLoggedIn && <Redirect to="/login" />}
        {(!app.essentialsLoaded || !app.storesLoaded) && <Route />}
        <Route path="/authorize" component={ThirdpartyAuthorize} />
        <Route path="/flagged" component={Flagged} />
        {app.isAccountFlagged && <Redirect to="/flagged" />}
        <Route path="/onboarding" component={Onboarding} />
        {app.essentialsLoaded && app.needsOnboarding && <Redirect to="/onboarding" />}
        {app.isElectron && <Route path="/_/dialog" component={DialogRouter} />}
        <Route path="/" component={Main} />
      </Switch>
    </ReactRouter>
  )
}

export default observer(Router)
