import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import { ArrowRightIcon } from '../../../component/icons/Tint/20/General'
import useKeyStepper from '../../../lib/use-key-stepper'

type Item = { title: string; emoji: string }
interface ListProps {
  items: Item[]
  onSelect: (item: Item) => void
}

export const List: React.FC<ListProps> = observer(function ({ items, onSelect }) {
  const styles = useStyles({})

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'onboarding/common/list',
    handleSelect: (item) => {
      onSelect(item)
    },
  })

  return (
    <div className={styles.root}>
      {items.map((item, index) => (
        <div
          {...getItemProps(index)}
          key={item.title}
          className={cx(styles.item, selectedIndex === index && styles.selected)}
        >
          <Typography variant="emoji">{item.emoji}</Typography>
          <Typography variant="body" className={styles.body}>
            {item.title}
          </Typography>
          {selectedIndex === index && (
            <Typography variant="inherit" color="textSecondary" style={{ lineHeight: 0 }}>
              <ArrowRightIcon />
            </Typography>
          )}
        </div>
      ))}
    </div>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 48,
    marginBottom: 2,
    padding: '0 16px',
    borderRadius: 8,
  },
  body: {
    margin: '0 auto 0 14px',
  },
  selected: {
    background: theme.palette.op.hover.primary,
  },
}))
