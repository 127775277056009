// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import { AddCircleIcon } from '../../../component/icons/custom'
import SideMenuRow from '../row'
import SideMenuSectionHeader from '../section-header'
import DirectMessageRow from './row'

const DirectMessages = function ({}) {
  const styles = useStyles({})
  const { sideMenu, conversation } = useAppStore()
  const selectedId = conversation.current?.id

  return (
    <>
      <SideMenuSectionHeader className={styles.root}>Your team</SideMenuSectionHeader>
      {sideMenu.dms.map((dm) => (
        <DirectMessageRow
          key={dm.member.id}
          dm={dm}
          highlighted={
            sideMenu.isInbox && selectedId && selectedId === dm.conversation?.id
          }
          onClick={sideMenu.handleDirectMessage}
        />
      ))}
      <SideMenuRow
        className={styles.actionRow}
        onClick={sideMenu.handleInvite}
        icon={<AddCircleIcon className={styles.actionIcon} />}
      >
        Invite your team
      </SideMenuRow>
    </>
  )
}

export default observer(DirectMessages)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 16,
  },
  actionRow: {
    fontSize: '0.8rem',
    color: theme.palette.primary.light,
  },
  actionIcon: {
    color: theme.palette.primary.light,
  },
  youTag: {
    marginRight: 6,
  },
}))
