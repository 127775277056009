import { makeStyles, Theme } from '@material-ui/core/styles'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import React from 'react'
import { useAppStore } from '@src/app/context'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'
import Footer from '../common/footer'
import Button from '../common/footer-button'
import Header from '../common/header'
import Textfield from '../common/textfield'

export interface BillingCreditsCommandProps {
  defaultAmount?: number
}

const BillingCreditsCommand: React.FC<BillingCreditsCommandProps> = function ({}) {
  const { command, service, toast } = useAppStore()
  const [amount, setAmount] = useInputState('', (s) => {
    if (!s) return s
    const amount = parseInt(s)
    if (isNaN(amount)) {
      return ''
    }
    return `${amount}`
  })

  const handlePurchase = () => {
    command.hide()
    toast.showLoading('Purchasing credits...')
    const credits = parseInt(amount)
    service.billing
      .addCredits(credits * 100)
      .then(() => toast.hide())
      .catch(toast.showError)
  }

  return (
    <Command>
      <Header
        title="Purchase Credits"
        subtitle="Add credits to your account to use for international calling and messaging."
      />
      <Textfield
        autoFocus
        placeholder="Enter the amount (e.g. $10.00)"
        value={amount}
        onChange={setAmount}
      />
      <Footer
        actions={
          <Button
            onClick={handlePurchase}
            startIcon={<CreditCardIcon />}
            variant="contained"
          >
            Purchase
          </Button>
        }
      />
    </Command>
  )
}

export default BillingCreditsCommand
