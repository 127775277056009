import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import { typography } from '../../../theme'

interface ListProps {}

const List: React.FC<ListProps> = function ({ children }) {
  const styles = useStyles({})
  return <div className={styles.root}>{children}</div>
}

interface ListItemProps extends React.HTMLProps<HTMLDivElement> {
  isSelected: boolean
}

const ListItem: React.FC<ListItemProps> = function ({
  isSelected,
  children,
  className,
  ...props
}) {
  const styles = useStyles({})
  return (
    <div {...props} className={cx(styles.item, isSelected && styles.selected, className)}>
      {children}
    </div>
  )
}

export { List, ListItem }

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 4,
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
  },
  item: {
    ...typography.footnote,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 10px',
    color: theme.palette.op.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: 5,
  },
  selected: {
    background: theme.palette.op.hover.primary,
  },
}))
