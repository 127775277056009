// @ts-strict-ignore
import cx from 'classnames'
import React, { useMemo } from 'react'
import { FileType } from '../lib/file'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  width?: number
  height?: number
  type: FileType
}

const Icon: React.FC<IconProps> = React.forwardRef(
  ({ width, height, type, className, ...props }, ref) => {
    const {
      width: vbWidth,
      height: vbHeight,
      path,
    } = useMemo(() => {
      return icon[type]
    }, [type])

    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={width || vbWidth}
        height={height || vbHeight}
        viewBox={`0 0 ${vbWidth} ${vbHeight}`}
        fill="currentColor"
        {...props}
        className={cx(className, 'icon')}
      >
        <g>{path}</g>
      </svg>
    )
  },
)

export default Icon

const icon: {
  [key in FileType]: {
    width: number
    height: number
    path: React.SVGProps<SVGPathElement>
  }
} = {
  audio: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" stroke="#F1B300" transform="translate(2.5)">
        <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
        <path d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5" />
        <g transform="translate(4.929 7.857)">
          <circle cx="1.571" cy="8.643" r="1.5" />
          <circle cx="7.071" cy="7.143" r="1.5" />
          <path
            strokeLinecap="round"
            d="M8.57142857,6.81290109 L8.57142857,2.14175851 C8.22767857,-1.08001588 3.21951512,1.62887254 2.57179478,3.10965247 L2.57179478,7.50809152"
          />
        </g>
      </g>
    ),
  },
  blank: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" transform="translate(2.5)">
        <path
          stroke="#8E9FB0"
          d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z"
        />
        <path
          stroke="#8FA0B1"
          d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5 L18.2857143,6.5"
        />
      </g>
    ),
  },
  contact: {
    width: 24,
    height: 24,
    path: null,
  },
  doc: {
    width: 24,
    height: 24,
    path: (
      <>
        <defs>
          <linearGradient id="word-copy-a" x1="50%" x2="50%" y1="-18.287%" y2="111.524%">
            <stop offset="0%" stopColor="#FFF" stopOpacity="0" />
            <stop offset="100%" stopColor="#759ED5" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(2.5)">
          <path
            stroke="#518FE2"
            d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z"
          />
          <g fillRule="nonzero" transform="translate(3 7)">
            <path
              fill="url(#word-copy-a)"
              d="M12,5 C12.5522847,5 13,5.44771525 13,6 L13,12 C13,12.5522847 12.5522847,13 12,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,9.6 L8.6,9.6 C9.11283584,9.6 9.53550716,9.21395981 9.59327227,8.71662113 L9.6,8.6 L9.6,4.999 L12,5 Z"
            />
            <path
              fill="#518FE2"
              d="M8,0 C8.55228475,-1.01453063e-16 9,0.44771525 9,1 L9,8 C9,8.55228475 8.55228475,9 8,9 L1,9 C0.44771525,9 6.76353751e-17,8.55228475 0,8 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L8,0 Z M7.53212186,2.00094585 L7.44442392,2.00296176 C7.24281666,2.02553168 7.06701992,2.1703675 7.01492875,2.37873219 L7.01492875,2.37873219 L6.288,5.28 L4.91611883,3.22059321 L4.8565922,3.14694346 C4.64084384,2.92599423 4.26378212,2.95054414 4.08388117,3.22059321 L4.08388117,3.22059321 L2.711,5.28 L1.98507125,2.37873219 L1.95545806,2.2934938 C1.8578149,2.07747681 1.61686326,1.95539598 1.37873219,2.01492875 C1.11083473,2.08190311 0.947954386,2.35337036 1.01492875,2.62126781 L1.01492875,2.62126781 L2.01492875,6.62126781 L2.0441,6.70649802 C2.1979344,7.05384718 2.69261004,7.11271826 2.91611883,6.77720952 L2.91611883,6.77720952 L4.5,4.4 L6.08388117,6.77720952 L6.13999517,6.84768199 C6.40160058,7.12314355 6.88729531,7.01237158 6.98507125,6.62126781 L6.98507125,6.62126781 L7.98507125,2.62126781 L7.99905415,2.53212186 C8.01455347,2.29556891 7.85939888,2.07446152 7.62126781,2.01492875 L7.62126781,2.01492875 L7.53212186,2.00094585 Z"
            />
          </g>
          <path
            stroke="#518FE2"
            d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5 L18.2857143,6.5"
          />
        </g>
      </>
    ),
  },
  csv: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd">
        <g stroke="#4DAD76" transform="translate(2.5)">
          <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
          <path d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5" />
        </g>
        <line
          x1="6.5"
          x2="17.5"
          y1="12.929"
          y2="12.929"
          stroke="#4DAD76"
          strokeLinecap="square"
        />
        <line
          x1="6.5"
          x2="16.5"
          y1="14.5"
          y2="14.5"
          stroke="#4DAD76"
          strokeLinecap="square"
          transform="rotate(90 11.5 14.5)"
        />
        <line
          x1="6.5"
          x2="17.5"
          y1="16.071"
          y2="16.071"
          stroke="#4DAD76"
          strokeLinecap="square"
        />
        <polygon fill="#4DAD76" points="7 10 17 10 17 13 7 13" opacity=".12" />
        <path
          stroke="#4DAD76"
          strokeLinecap="square"
          d="M7.5,9.5 L16.5,9.5 C17.0522847,9.5 17.5,9.94771525 17.5,10.5 L17.5,18.5 C17.5,19.0522847 17.0522847,19.5 16.5,19.5 L7.5,19.5 C6.94771525,19.5 6.5,19.0522847 6.5,18.5 L6.5,10.5 C6.5,9.94771525 6.94771525,9.5 7.5,9.5 Z"
        />
      </g>
    ),
  },
  pdf: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" stroke="#F86767" transform="translate(2.5)">
        <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
        <path d="M6.72588439 15.7863977C9.57460992 10.1985893 10.149738 7.27318697 8.45126878 7.01019078 5.90356488 6.61569649 11.9364191 17.8225676 13.8111333 14.943266 15.6858475 12.0639644 2.95986238 16.3362289 4.06859423 18.1793142 4.8077488 19.4080377 5.69351219 18.6103989 6.72588439 15.7863977zM13.5 1L13.5 4.5C13.5 5.6045695 14.3954305 6.5 15.5 6.5L18.2857143 6.5 18.2857143 6.5" />
      </g>
    ),
  },
  presentation: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd">
        <g stroke="#E46E59" transform="translate(2.5)">
          <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
          <path d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5" />
        </g>
        <line
          x1="6.5"
          x2="17.5"
          y1="9.5"
          y2="9.5"
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          width="9"
          height="7.5"
          x="7.5"
          y="9.567"
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="12"
          x2="12"
          y1="8.5"
          y2="9.5"
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="13.486"
          x2="14.064"
          y1="17.026"
          y2="19.679"
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="rotate(-20 13.775 18.352)"
        />
        <line
          x1="9.936"
          x2="10.514"
          y1="17.026"
          y2="19.679"
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="scale(-1 1) rotate(-20 0 76.341)"
        />
        <polyline
          stroke="#E46E59"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="7.567 15.267 10.1 13.367 12.633 14.633 16.433 11.467"
        />
      </g>
    ),
  },
  spreadsheet: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd">
        <g stroke="#4DAD76" transform="translate(2.5)">
          <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
          <path d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5" />
        </g>
        <line
          x1="6.5"
          x2="17.5"
          y1="12.929"
          y2="12.929"
          stroke="#4DAD76"
          strokeLinecap="square"
        />
        <line
          x1="6.5"
          x2="16.5"
          y1="14.5"
          y2="14.5"
          stroke="#4DAD76"
          strokeLinecap="square"
          transform="rotate(90 11.5 14.5)"
        />
        <line
          x1="6.5"
          x2="17.5"
          y1="16.071"
          y2="16.071"
          stroke="#4DAD76"
          strokeLinecap="square"
        />
        <polygon fill="#4DAD76" points="7 10 17 10 17 13 7 13" opacity=".12" />
        <path
          stroke="#4DAD76"
          strokeLinecap="square"
          d="M7.5,9.5 L16.5,9.5 C17.0522847,9.5 17.5,9.94771525 17.5,10.5 L17.5,18.5 C17.5,19.0522847 17.0522847,19.5 16.5,19.5 L7.5,19.5 C6.94771525,19.5 6.5,19.0522847 6.5,18.5 L6.5,10.5 C6.5,9.94771525 6.94771525,9.5 7.5,9.5 Z"
        />
      </g>
    ),
  },
  zip: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd">
        <g stroke="#8D9063" transform="translate(2.5)">
          <path d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z" />
          <path d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5" />
        </g>
        <line
          x1="10.5"
          x2="13.5"
          y1="3.5"
          y2="3.5"
          stroke="#8D9063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polygon
          fill="#8D9063"
          points="10.5 13.5 13.5 13.5 13.5 17.5 10.5 17.5"
          opacity=".12"
        />
        <polygon
          stroke="#8D9063"
          strokeLinejoin="round"
          points="10.5 13.5 13.5 13.5 13.5 17.5 10.5 17.5"
        />
        <line
          x1="10.5"
          x2="13.5"
          y1="5.5"
          y2="5.5"
          stroke="#8D9063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="10.5"
          x2="13.5"
          y1="7.5"
          y2="7.5"
          stroke="#8D9063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="10.5"
          x2="13.5"
          y1="9.5"
          y2="9.5"
          stroke="#8D9063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="12"
          x2="12"
          y1="1.5"
          y2="12.5"
          stroke="#8D9063"
          strokeLinecap="square"
        />
        <line
          x1="10.5"
          x2="13.5"
          y1="11.5"
          y2="11.5"
          stroke="#8D9063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    ),
  },
  image: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" transform="translate(2.5)">
        <path
          stroke="#67C0BE"
          d="M16,23.5 L3,23.5 C1.61928813,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928813,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z"
        />
        <circle cx="7" cy="10" r="2" stroke="#67C0BE" />
        <path
          stroke="#67C0BE"
          strokeLinecap="round"
          d="M0.705810547,20.3648682 L3.88840771,16.8963757 C5.34530734,15.3086015 7.79814543,15.1603133 9.43571525,16.561009 L9.93722725,16.9899774 L9.93722725,16.9899774 L12.075023,15.0839384 C13.6893666,13.6446045 16.1554207,13.7491711 17.6421263,15.3199969 L18.2857143,16 L18.2857143,16"
        />
        <line
          x1="9.972"
          x2="7.826"
          y1="16.923"
          y2="18.945"
          stroke="#67C0BE"
          strokeLinecap="round"
        />
        <path
          stroke="#67C0C0"
          d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5 L18.2857143,6.5"
        />
      </g>
    ),
  },
  text: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" transform="translate(2.5)">
        <path
          stroke="#8E9FB0"
          d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z"
        />
        <rect
          width="9"
          height="1"
          x="5"
          y="10"
          fill="#8E9FB0"
          fillRule="nonzero"
          rx=".5"
        />
        <rect
          width="9"
          height="1"
          x="5"
          y="13"
          fill="#8E9FB0"
          fillRule="nonzero"
          rx=".5"
        />
        <rect
          width="6"
          height="1"
          x="5"
          y="16"
          fill="#8E9FB0"
          fillRule="nonzero"
          rx=".5"
        />
        <path
          stroke="#8FA0B1"
          d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5 L18.2857143,6.5"
        />
      </g>
    ),
  },
  video: {
    width: 24,
    height: 24,
    path: (
      <g fill="none" fillRule="evenodd" transform="translate(2.5)">
        <path
          stroke="#737EB0"
          d="M16,23.5 L3,23.5 C1.61928812,23.5 0.5,22.3807119 0.5,21 L0.5,3 C0.5,1.61928812 1.61928812,0.5 3,0.5 L13.2780577,0.5 C14.0152309,0.5 14.7148259,0.82534565 15.1898392,1.38907124 L17.9117815,4.61935616 C18.291661,5.07018093 18.5,5.64075002 18.5,6.23028493 L18.5,21 C18.5,22.3807119 17.3807119,23.5 16,23.5 Z"
        />
        <path
          fill="#737EB0"
          fillRule="nonzero"
          d="M2 17.5L2 19.5C2 19.7761424 2.22385763 20 2.5 20 2.77614237 20 3 19.7761424 3 19.5L3 17.5C3 17.2238576 2.77614237 17 2.5 17 2.22385763 17 2 17.2238576 2 17.5zM2 13.5L2 15.5C2 15.7761424 2.22385763 16 2.5 16 2.77614237 16 3 15.7761424 3 15.5L3 13.5C3 13.2238576 2.77614237 13 2.5 13 2.22385763 13 2 13.2238576 2 13.5zM2 9.5L2 11.5C2 11.7761424 2.22385763 12 2.5 12 2.77614237 12 3 11.7761424 3 11.5L3 9.5C3 9.22385763 2.77614237 9 2.5 9 2.22385763 9 2 9.22385763 2 9.5zM2 5.5L2 7.5C2 7.77614237 2.22385763 8 2.5 8 2.77614237 8 3 7.77614237 3 7.5L3 5.5C3 5.22385763 2.77614237 5 2.5 5 2.22385763 5 2 5.22385763 2 5.5zM16 17.5L16 19.5C16 19.7761424 16.2238576 20 16.5 20 16.7761424 20 17 19.7761424 17 19.5L17 17.5C17 17.2238576 16.7761424 17 16.5 17 16.2238576 17 16 17.2238576 16 17.5zM16 13.5L16 15.5C16 15.7761424 16.2238576 16 16.5 16 16.7761424 16 17 15.7761424 17 15.5L17 13.5C17 13.2238576 16.7761424 13 16.5 13 16.2238576 13 16 13.2238576 16 13.5zM16 9.5L16 11.5C16 11.7761424 16.2238576 12 16.5 12 16.7761424 12 17 11.7761424 17 11.5L17 9.5C17 9.22385763 16.7761424 9 16.5 9 16.2238576 9 16 9.22385763 16 9.5z"
        />
        <path
          stroke="#737EB0"
          strokeLinejoin="bevel"
          d="M9.0547002,10.0364668 L12.2512766,12.1675177 C12.7108052,12.4738702 12.8349791,13.0947395 12.5286267,13.5542682 C12.4553023,13.6642547 12.3608994,13.7586123 12.2508779,13.8318839 L9.0543015,15.9607235 C8.5946261,16.2668557 7.97381632,16.1423844 7.66768405,15.682709 C7.55834154,15.5185249 7.5,15.3256693 7.5,15.1284075 L7.5,10.8685171 C7.5,10.3162323 7.94771525,9.86851709 8.5,9.86851709 C8.69742499,9.86851709 8.89043268,9.92695512 9.0547002,10.0364668 Z"
        />
        <path
          stroke="#737FB2"
          d="M13.5,1 L13.5,4.5 C13.5,5.6045695 14.3954305,6.5 15.5,6.5 L18.2857143,6.5 L18.2857143,6.5"
        />
      </g>
    ),
  },
}
