import React, { useMemo } from 'react'
import { MenuItem } from '../../../component/menu-v2'
import { Select } from '../../../component/select'
import Typography from '@ui/Typography'
import { RoleName } from '../../../types'
import { RolesOptions } from './RolesOptions'
import cx from 'classnames'
import { SelectProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CheckBoxTickIcon } from '../../../component/icons/Tint/16/General'
import { useAppStore } from '@src/app/context'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const SelectRole = (props: SelectProps) => {
  const styles = useStyles()
  const { service } = useAppStore()
  const user = service.user.current.asMember
  const roleOptionsFiltered = useMemo(
    () => RolesOptions.filter((role) => user.canManage(role.value as RoleName)),
    [],
  )

  return (
    <Select
      IconComponent={ArrowDropDownIcon}
      size={35}
      classes={{ root: styles.selectRoot, icon: styles.icon }}
      {...props}
    >
      {roleOptionsFiltered.map((role) => {
        const isSelected = props.value === role.value
        return (
          <MenuItem
            key={role.value}
            value={role.value}
            className={cx(styles.selectItem, isSelected && styles.selectItemSelected)}
          >
            <div className={styles.selectItemContainer}>
              <div className={styles.selectItemName}>
                <Typography
                  variant="footnote"
                  className={cx(styles.menuItemTitle, isSelected && styles.selectedText)}
                >
                  {role.name}
                </Typography>
                <Typography
                  variant="caption1"
                  className={cx(
                    styles.menuItemDescription,
                    // this is gross. talk to Cai
                    isSelected && styles.selectedText,
                    isSelected && styles.opacity,
                  )}
                >
                  {role.description}
                </Typography>
              </div>
              {isSelected && <CheckBoxTickIcon />}
            </div>
          </MenuItem>
        )
      })}
    </Select>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    selectRoot: {
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    menuItemTitle: {
      textTransform: 'capitalize',
      color: theme.palette.op.gray[1],
    },
    menuItemDescription: {
      marginTop: 2,
      color: theme.palette.op.gray[3],
    },
    selectedText: {
      color: theme.palette.op.tag.purple.text,
    },
    opacity: {
      opacity: 0.6,
    },

    selectItem: {
      '&:hover': {
        backgroundColor: theme.palette.op.tag.purple.bgHover,
      },
    },
    selectItemSelected: {
      backgroundColor: theme.palette.op.tag.purple.bg,
    },
    selectItemContainer: {
      padding: '7px 10px',
      width: 264,
      marginTop: 2,
      display: 'flex',
    },
    selectItemName: {
      flex: 1,
    },
    icon: {
      color: 'inherit',
    },
  }),
  { name: SelectRole.name },
)

export default SelectRole
