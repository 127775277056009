import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useRef } from 'react'
import { List, ListItem } from '../list'
import useKeyStepper from '../../../../lib/use-key-stepper'

interface ColorPickerProps {
  onSelect: (color: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = function ({ onSelect }) {
  const styles = useStyles({})
  const theme = useTheme()

  const colors = useMemo(() => {
    return [
      { color: theme.palette.op.contactTags.green1, name: 'Green' },
      { color: theme.palette.op.contactTags.red1, name: 'Red' },
      { color: theme.palette.op.contactTags.blue1, name: 'Blue' },
      { color: theme.palette.op.contactTags.orange1, name: 'Orange' },
      { color: theme.palette.op.contactTags.cherokee1, name: 'Cherokee' },
      { color: theme.palette.op.contactTags.turquoise1, name: 'Turquoise' },
      { color: theme.palette.op.contactTags.mauve1, name: 'Mauve' },
      { color: theme.palette.op.contactTags.purple1, name: 'Purple' },
      { color: theme.palette.op.contactTags.monaLisa1, name: 'Mona Lisa' },
      { color: theme.palette.op.contactTags.gray1, name: 'Gray' },
    ]
  }, [theme])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: colors,
    defaultSelectedIndex: 0,
    name: 'contact/editor/tags',
    handleSelect: (item) => {
      if (item) {
        onSelect(item.color)
      }
    },
  })

  return (
    <div>
      <List>
        {colors.map((color, index) => (
          <ListItem
            className={styles.row}
            isSelected={selectedIndex === index}
            key={color.color}
            {...getItemProps(index)}
          >
            <i className={styles.color} style={{ backgroundColor: color.color }} />
            <span>{color.name}</span>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default observer(ColorPicker)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  color: {
    display: 'inline-block',
    width: 15,
    height: 15,
    borderRadius: 4,
    marginRight: 15,
  },
}))
