// @ts-strict-ignore
import { ElectronClient } from '@openphone/desktop-client'
import { makePersistable } from '@src/service/storage/persistable'
import { StorageThemeKey } from '@src/theme'
import Debug from 'debug'
import { Location, LocationState } from 'history'
import Cookies from 'js-cookie'
import { action, makeAutoObservable, reaction, when } from 'mobx'
import React from 'react'
import { asyncScheduler, Observable, of } from 'rxjs'
import { filter, mergeMap, throttleTime } from 'rxjs/operators'
import { Environment, config } from '../config'
import { fromQueryString, toCamelCase } from '../lib'
import analytics from '../lib/analytics'
import { errorTitle } from '../lib/api/error-handler'
import { setUser } from '../lib/crash-reporting'
import log, { logError } from '../lib/log'
import Service from '../service'
import { MessageMedia, User, Organization } from '../service/model'
import { ExtendedHistory } from '../types'
import MainServiceWorker from '../worker'
import { AlertsUiStore } from './alerts/store'
import { AnalyticsUiStore } from './analytics'
import CommandUiStore from './command/store'
import ContactsUiStore from './contacts/store'
import { LoginUiStore } from './login/store'
import { EmojiPickerProps } from './emoji-picker'
import ConversationUiStore from './inbox/conversation/store'
import InboxesUiStore from './inbox/store'
import { NotificationController } from './notification'
import { URLController } from './url'
import SearchUiStore from './search/store'
import SideMenuUiSore from './side-menu/store'
import { Sound } from './sound'
import { ToastUiStore } from './toast'
import { UpdateController } from './update/controller'
import VoiceUiStore from './voice/store'
import { WorkspaceUiStore } from './workspace'
import {
  LocationSearch,
  LocationSearchParam,
  stripSearchParam,
  stripUnknownParams,
} from './router'

export interface AlertAction {
  title: string
  type?: 'primary' | 'secondary' | 'destructive'
  onClick?: () => void
}

export type AlertState = {
  open: boolean
  title?: string
  body?: React.ReactNode
  actions?: AlertAction[]
}

const HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL = 'HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL'

export default class AppStore {
  readonly command: CommandUiStore
  readonly history: HistoryManager

  readonly debug = {
    enable(value?: string) {
      Debug.enable(typeof value === 'string' ? value : '*')
    },
    disable() {
      Debug.disable()
    },
  }

  alerts: AlertsUiStore
  contacts: ContactsUiStore
  conversation: ConversationUiStore
  analytics: AnalyticsUiStore
  inboxes: InboxesUiStore
  // FIXME: make LoginUiStore a permanent instance in AppStore
  login?: LoginUiStore
  search: SearchUiStore
  sideMenu: SideMenuUiSore
  voice: VoiceUiStore
  toast: ToastUiStore
  workspace: WorkspaceUiStore

  sound = new Sound()

  alert: AlertState = { open: false }
  config = config
  confetti = false
  darkMode = true
  emojiPicker: EmojiPickerProps = { open: false }
  mediaViewer: { media: MessageMedia[]; index: number } = null
  storesLoaded = false
  serviceWorker = new MainServiceWorker()
  url = new URLController(this)
  update = new UpdateController(this)
  notification = new NotificationController(this)
  themeKey: StorageThemeKey = 'system'

  constructor(
    public electron: ElectronClient | undefined,
    history: ExtendedHistory,
    public service: Service,
  ) {
    this.history = new HistoryManager(history)
    this.command = new CommandUiStore(this)
    this.toast = new ToastUiStore(this)

    makeAutoObservable(this, {
      config: false,
      debug: false,
      electron: false,
      history: false,
      service: false,
      sound: false,
      setThemeKey: action.bound,
    })

    makePersistable(this, 'AppStore', {
      themeKey: this.service.storage.sync(),
    })

    when(
      () => service.auth.hasSession,
      () => {
        this.loadEssentials().then(() => this.service.transport.loadPendingTransactions())
      },
    )

    reaction(
      () => service.organization.current?.id && service.transport.online,
      (shouldRun) => {
        if (shouldRun) {
          service.member.fetchPresence().catch(logError)
        }
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.essentialsLoaded,
      (loaded) => {
        if (loaded) {
          this.identify(this.service.user.current, this.service.organization.current)
          this.alerts = new AlertsUiStore(this)
          this.contacts = new ContactsUiStore(this)
          this.conversation = new ConversationUiStore(this)
          this.inboxes = new InboxesUiStore(this)
          this.search = new SearchUiStore(this)
          this.sideMenu = new SideMenuUiSore(this)
          this.voice = new VoiceUiStore(this)
          this.analytics = new AnalyticsUiStore(this)
          this.workspace = new WorkspaceUiStore(this)
          this.storesLoaded = true
          this.loadDeployPreviewer()
          this.url.checkHandlerUrl()
        } else {
          this.alerts = null
          this.contacts = null
          this.conversation = null
          this.inboxes = null
          this.search = null
          this.sideMenu = null
          this.voice = null
          this.analytics = null
          this.storesLoaded = false
        }
      },
      { fireImmediately: true },
    )

    this.electron?.on('tray', (event) => {
      if (event.type === 'click') {
        this.electron?.window.focus()
        this.electron?.window.show()
      }
    })

    if (this.is('windows')) {
      this.electron?.app.createTray()
    }

    this.setAsDefaultTelProtocol()
  }

  get isElectron() {
    return Boolean(this.electron)
  }

  get isLoggedIn(): boolean {
    return Boolean(this.service.auth.session?.idToken)
  }

  get isFocused() {
    return document.hasFocus()
  }

  get essentialsLoaded(): boolean {
    return (
      Boolean(this.service.user.current) &&
      Boolean(this.service.user.phoneNumbers) &&
      Boolean(this.service.organization.current) &&
      Boolean(this.service.member.collection.length > 0) &&
      Boolean(this.service.billing.capabilities) &&
      Boolean(this.service.billing.subscription)
    )
  }

  get needsOnboarding(): boolean {
    const user = this.service.user.current
    const phoneNumbers = this.service.user.phoneNumbers
    if (this.service.phoneNumber.loaded === false) {
      return undefined
    }
    return (
      (this.service.phoneNumber.loaded && phoneNumbers.length === 0) ||
      (user && !user?.firstName && !user?.lastName) ||
      this.hasPendingInvites
    )
  }

  get isAccountFlagged(): boolean {
    return this.service.billing.subscription?.isFlagged
  }

  get hasPendingInvites(): boolean {
    return this.service.user.invites.length > 0
  }

  get onDataNeedsRefresh(): Observable<void> {
    return this.service.transport.connectivity.downtime.pipe(
      // Fitler shorter downtimes
      filter((downtime) => downtime > 5_000),
      // Map to void
      mergeMap(() => of(undefined as void)),
      // Throttle so we don't spam the backend
      throttleTime(60_000, asyncScheduler, { leading: true, trailing: true }),
    )
  }

  is(platform: 'web' | 'mac' | 'windows') {
    const current = ((): 'web' | 'mac' | 'windows' => {
      switch (this.electron?.platform) {
        case 'darwin':
          return 'mac'
        case 'win32':
          return 'windows'
        default:
          return 'web'
      }
    })()
    return current === platform
  }

  setThemeKey(themeKey: StorageThemeKey) {
    this.themeKey = themeKey
  }

  showAlert = (state: Omit<AlertState, 'open'>): void => {
    this.alert = { open: true, ...state }
  }

  showErrorAlert = (error: Error): void => {
    this.sound.play('error')
    this.alert = { open: true, title: errorTitle(error), body: error.message }
  }

  hideAlert = () => {
    this.alert = { ...this.alert, open: false }
  }

  showEmojiPicker = (props: Omit<EmojiPickerProps, 'open'>) => {
    this.emojiPicker = { open: true, ...props }
  }

  hideEmojiPicker = () => {
    this.emojiPicker = { open: false }
  }

  openMediaViewer = (media: MessageMedia[], index: number) => {
    this.mediaViewer = { media, index }
  }

  closeMediaViewer = () => {
    this.mediaViewer = null
  }

  reset = () => {
    this.alert = { open: false }
    this.emojiPicker = { open: false }
    this.serviceWorker.unregister().then(() => this.service.reset())
  }

  signOut = (evnOverride?: Environment) => {
    this.alert = { open: false }
    this.emojiPicker = { open: false }
    this.serviceWorker
      .unregister()
      .then(() => this.service.clearAllAndRestart(evnOverride))
  }

  showConfetti = () => {
    this.confetti = true
  }

  focus() {
    if (this.isElectron) {
      this.electron.window.show()
      this.electron.window.focus()
    } else {
      window.focus()
    }
  }

  private loadEssentials() {
    return Promise.all([
      this.service.user.fetch().catch(logError),
      this.service.user.fetchInvites().catch(logError),
      this.service.phoneNumber.fetch().catch(logError),
      this.service.member.fetch().catch(logError),
      this.service.organization.fetch().catch(logError),
      this.service.organization.fetchRoles().catch(logError),
      this.service.workspace.fetchUserGroups().catch(logError),
      this.service.billing.fetchCapabilities().catch(logError),
      this.service.billing.fetchSubscription().catch(logError),
      this.service.snippet.fetch().catch(logError),
      this.service.blocklist.fetch().catch(logError),
      this.service.integration.fetchAll().catch(logError),
    ])
  }

  private identify(user: User, organization: Organization) {
    // Set user for crash reporting
    setUser(user)

    // Set user for logs
    log.setUser(user)

    analytics.identify(user.id, {
      orgId: organization.id,
      orgPlan: this.service.billing.subscription.type,
      orgAutoCharge: this.service.billing.subscription.autochargeAmount > 0,
      orgIndustry: organization.analytics.enrichment?.category?.industry,
      orgSubIndustry: organization.analytics.enrichment?.category?.subIndustry,
      orgSize: organization.analytics.enrichment?.metrics?.employees?.toString(),
      email: user.email,
      name: user.asMember.name,
      selfIndustry: organization.industry,
      role: user.analytics.enrichment?.employment?.role,
      subRole: user.analytics?.enrichment?.employment?.subRole,
    })

    // Set UTM parameters
    let savedAnalytics = Cookies.get('op_analytics')
    let gaId = Cookies.get('_ga')
    savedAnalytics = savedAnalytics ? toCamelCase(JSON.parse(savedAnalytics)) : {}
    let newAnalytics: { [key: string]: any } = {}
    Object.keys(savedAnalytics).forEach((key) => {
      if (savedAnalytics[key] && user.analytics?.[key] !== savedAnalytics[key]) {
        newAnalytics[key] = savedAnalytics[key]
      }
    })

    if (!user.analytics.ga) {
      newAnalytics.ga = gaId
    }

    if (Object.keys(newAnalytics).length > 0) {
      user.update({
        analytics: { ...user.analytics, ...newAnalytics },
      })
    }
  }

  private setAsDefaultTelProtocol() {
    const hasBeenSetAlready = localStorage.getItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL)
    if (this.isElectron && !hasBeenSetAlready) {
      this.electron.app.registerTelProtocol()
      localStorage.setItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL, '1')
    }
  }

  private loadDeployPreviewer() {
    if (this.isElectron && this.service.user.current.isOpenPhoneOrganizationMember) {
      this.electron.app.initDeployPreviewer()
    }
  }
}

// FIXME: `push()` and `replace()` both expect `path` to not include query
// parameters, but in some instances we pass them in, in which case by setting
// `pathname` AND `search` it actually includes it twice, e.g.
// ?invite=abc?invite=abc`. I set `keepSearch` to false for these instances,
// but we need to handle this better, maybe by merging any existing query
// params before overriding `search`?
class HistoryManager {
  location: Location<LocationState> = null
  private stackSize = 0

  constructor(readonly history: ExtendedHistory) {
    this.location = history.location

    makeAutoObservable(this, {})

    history.listen(
      action((location, action) => {
        this.location = location
        if (action === 'PUSH') {
          this.stackSize += 1
        } else if (action === 'POP') {
          this.stackSize -= 1
        }
      }),
    )
  }

  get pathComponents() {
    return this.location.pathname.split('/').slice(1)
  }
  get canGoBack() {
    return this.stackSize > 0
  }

  /**
   * Returns everything after the ? in the url as a hash, so ?a=1&b2
   * turns into { a: 1, b: 2 }
   */
  get query(): LocationSearch {
    return fromQueryString(this.location.search) as LocationSearch
  }

  push = (path: string, keepSearch = true) => {
    if (keepSearch) {
      this.history.push({
        pathname: path,
        search: stripUnknownParams(this.location.search).toString(),
      })
    } else {
      this.history.push(path)
    }
  }

  replace = (path: string, keepSearch = true) => {
    if (keepSearch) {
      this.history.replace({
        pathname: path,
        search: stripUnknownParams(this.location.search).toString(),
      })
    } else {
      this.history.replace(path)
    }
  }

  consumeQueryParam = (param: LocationSearchParam): string | undefined => {
    const value = this.query[param]

    if (value) {
      const search = stripSearchParam(this.location.search, param)

      this.history.replace({
        ...this.location,
        search: search.toString(),
      })
    }

    return value
  }

  goBack = () => {
    this.history.goBack()
  }

  goForward = () => {
    this.history.goForward()
  }

  observe = () => {
    return this.history.observe()
  }
}
