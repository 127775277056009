import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import Button from '../component/button'
import { toQueryString } from '../lib'
import { AnimatingLogo } from './loading'
import { Redirect } from '@src/app/router'

interface CallbackProps extends RouteComponentProps<{ provider: string }> {}

type CallbackState =
  | {
      /**
       * If set to true, we'll attempt to redirect to the electron app using a deep link.
       */
      desktop?: boolean
      redirectTo: string
    }
  | string

const Callback: React.FC<CallbackProps> = function ({ match }) {
  const styles = useStyles({})
  const { history } = useAppStore()

  const query = history.query

  if (query.state) {
    query.state = atob(query.state)
  }

  const { state, ...params } = query
  const parsedState = state ? parseState(state) : null

  const createRedirectTo = (redirectTo: string) => {
    return `${redirectTo}${toQueryString({
      ...params,
      provider: match.params.provider,
    })}`
  }

  if (typeof parsedState === 'string' && parsedState.startsWith('/')) {
    const redirectTo = createRedirectTo(parsedState)
    return <Redirect to={redirectTo} />
  }

  if (parsedState && typeof parsedState !== 'string') {
    const redirectTo = createRedirectTo(parsedState.redirectTo)

    if (parsedState.desktop) {
      return <RedirectInElectronApp to={redirectTo} />
    }

    return <Redirect to={redirectTo} />
  }

  const error =
    "We can't quite figure out how you ended up on this screen. If you were adding an integration, try going through the flow again."

  return (
    <div className={styles.root}>
      <div style={{ padding: '4rem 2rem 0' }}>{error}</div>
      <Button
        style={{ marginTop: 40 }}
        variant="text"
        color="primary"
        onClick={() => history.push('/inbox')}
      >
        Go back to inbox
      </Button>
    </div>
  )
}

export default Callback

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

function parseState(state: string): CallbackState {
  try {
    return JSON.parse(state)
  } catch {
    return state
  }
}

function RedirectInElectronApp({ to }: { to: string }) {
  const styles = useStyles({})

  useEffect(() => {
    const toWithoutStartingSlash = to.startsWith('/') ? to.slice(1) : to

    window.location.href = `openphone://${toWithoutStartingSlash}`
  }, [])

  return (
    <div className={styles.root}>
      <AnimatingLogo />
      <Typography style={{ marginTop: 30 }} variant="body" color="textSecondary">
        Opening the desktop app...
      </Typography>
      <Button
        style={{ marginTop: 10 }}
        variant="text"
        color="primary"
        onClick={() => window.close()}
      >
        Close window
      </Button>
    </div>
  )
}

export function createCallbackState(state: CallbackState): string {
  if (typeof state === 'string') {
    return btoa(state)
  }

  return btoa(JSON.stringify(state))
}
