import React, { useRef } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ImageViewer from './image-viewer'
import VideoViewer from './video-viewer'
import DocViewer from './doc-viewer'
import { fileType } from '../../lib/file'
import PdfViewer from './pdf-viewer'
import { MessageMedia } from '../../service/model'
import { observer } from 'mobx-react-lite'

interface MediaPreviewProps {
  media: MessageMedia
}

const MediaPreview: React.FC<MediaPreviewProps> = function ({ media }) {
  const styles = useStyles({})
  const type = fileType(media.type, media?.['url'] || media.name)

  switch (type) {
    case 'image':
      return <ImageViewer media={media} />
    case 'video':
      return <VideoViewer media={media} />
    case 'doc':
    case 'spreadsheet':
    case 'presentation':
      return <DocViewer media={media} />
    case 'pdf':
      return <PdfViewer media={media} />
    default:
      return null
  }
}

export default observer(MediaPreview)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
