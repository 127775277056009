import React, { useMemo } from 'react'
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Checkbox_ActiveFullIcon,
  Checkbox_ActivePartIcon,
  Checkbox_DefaultIcon,
} from './icons'
import { nanoid } from 'nanoid'

export interface CheckboxProps extends MuiCheckboxProps {}

const Checkbox: React.FC<CheckboxProps> = function ({ id, ...props }) {
  const classes = useStyles({})
  const iconId = useMemo(() => id ?? nanoid(3), [id])
  return (
    <MuiCheckbox
      icon={<Checkbox_DefaultIcon />}
      checkedIcon={<Checkbox_ActiveFullIcon id={iconId} className={classes.icon} />}
      indeterminateIcon={<Checkbox_ActivePartIcon id={iconId} className={classes.icon} />}
      classes={{ root: classes.root }}
      id={id}
      {...props}
    />
  )
}

export default Checkbox

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    borderRadius: 5,

    '&:hover': {
      backgroundColor: theme.palette.op.hover.primary,
    },

    '&.Mui-focusVisible': {
      transition: `box-shadow 200ms ease`,
      boxShadow: theme.palette.op.shadow.textField.hoverPurple,
    },
  },
  icon: {
    color: theme.palette.op.primary[2],
  },
}))
