import { makeStyles, Theme } from '@material-ui/core/styles'
import ArrowForward from '@material-ui/icons/ArrowForward'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import { fonts } from '../../theme'

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = function ({}) {
  const styles = useStyles({})
  const { history } = useAppStore()

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div style={{ fontSize: '3rem' }}>📊 👀</div>
        <div className={styles.title}>Get insight</div>
        <div className={styles.body}>
          How much time did your team spend talking to customers last week? What's the
          busiest time of the week for you? Upgrade to premium to unlock dashboard
          analytics.
        </div>
        <div>
          <Button
            style={{ marginTop: 20 }}
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => history.push('/settings/billing/plans')}
          >
            Compare Plans
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Dashboard

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: 400,
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    fontFamily: fonts.header,
    margin: '10px 0',
  },
  body: {
    fontSize: 14,
    margin: '10px 0',
    lineHeight: 1.5,
    color: theme.palette.text.secondary,
  },
}))
