import { makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import KeyboardList from '../../../component/keyboard-list'
import { Conversation } from '../../../service/model'
import ConversationRow from './row'

interface ListProps {}

const ConversationList: React.FC<ListProps> = function () {
  const styles = useStyles({})
  const { inboxes } = useAppStore()
  const inbox = inboxes.selected

  const rowRenderer = (convo: Conversation, index: number) => {
    return (
      <ConversationRow
        index={index}
        conversation={convo}
        onSelect={inbox.setSelectedConversation}
      />
    )
  }

  return (
    <KeyboardList
      name="conversations"
      controller={inbox.keyboardList}
      renderItem={rowRenderer}
      className={styles.root}
    />
  )
}

export default observer(ConversationList)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
}))
