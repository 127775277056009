// @ts-strict-ignore
import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Card, Disclaimer, List } from './common'
import { useOnboardingState } from '.'

interface NumberSourceProps {}

const items = [
  { emoji: '🎁', title: 'Brand new number', port: false },
  { emoji: '➡️', title: 'Port existing number', port: true },
]
const NumberSource: React.FC<NumberSourceProps> = function ({}) {
  const styles = useStyles({})
  const store = useOnboardingState()

  const handleSelect = (item: typeof items[0]) => {
    store.numberTypeSelected(item.port)
  }

  return (
    <Card
      emoji="📞"
      title="Phone number"
      description="Would you like a new OpenPhone number, or want to port your existing number over?"
    >
      <List onSelect={handleSelect} items={items} />
      <Disclaimer
        style={{ marginTop: 0, paddingTop: 10 }}
        transparent
        text="If you choose to port your existing number, you will be given a temporary OpenPhone number to test things out with during the porting process."
      />
    </Card>
  )
}

export default NumberSource

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
