// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { isHTMLElement } from '../../lib/dom'
import { useReaction } from '../../lib/hooks'
import { throttle } from '../../lib/throttle'
import useCombinedRefs from '../../lib/use-combined-ref'
import MasonryStore, { Brick } from './store'

export interface MasonryProps<T extends Brick>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'width' | 'height'> {
  columns: number
  bricks: readonly T[]
  renderBrick(brick: T): React.ReactNode
}

const Masonry = function <T extends Brick>(
  { columns, bricks, renderBrick, className, ...props }: MasonryProps<T>,
  ref?: React.ForwardedRef<HTMLDivElement>,
) {
  const styles = useStyles({})
  const gridRef = useCombinedRefs<HTMLDivElement>(ref)
  const store = useMemo(() => new MasonryStore(bricks, columns), [bricks, columns])

  useReaction(
    () => store.layout,
    ({ bricks, grid }) => {
      const nodes = gridRef.current.childNodes
      bricks.forEach(({ x, y, width, height }, index) => {
        const el = nodes.item(index)
        if (!isHTMLElement(el)) return
        el.style.position = 'absolute'
        el.style.transform = `translate(${x}px, ${y}px)`
        el.style.width = `${width}px`
        el.style.height = `${height}px`
      })
      gridRef.current.style.height = `${grid.height}px`
    },
    { name: 'MasonryLayoutChange' },
    [store],
  )

  useEffect(
    action(() => {
      store.gridWidth = gridRef.current.offsetWidth
      const observer = new ResizeObserver(
        throttle(
          action(() => {
            store.gridWidth = gridRef.current.offsetWidth
          }),
          50,
        ),
      )
      observer.observe(gridRef.current)
      return () => observer.disconnect()
    }),
    [store],
  )

  return (
    <div {...props} ref={gridRef} className={cx(styles.root, className)}>
      {bricks.map(renderBrick)}
    </div>
  )
}

export default observer(Masonry, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      width: '100%',
    },
  }),
  { name: Masonry.name },
)
