import { withStyles, TableCell } from '@material-ui/core'

export const WebhooksPageTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    height: 40,
    maxHeight: 40,
    padding: 0,
  },
  head: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  footer: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))(TableCell)
