import { computed, makeObservable } from 'mobx'
import AppStore from '../store'
import { Controller } from '../../component/input-bar'
import { memoize } from '../../lib/fn'
import { Contact, Note } from '../../service/model'
import { InputBarController, InputBarRegistry } from '../input-bar'

const getEditor = memoize(() => Controller.createSlateEditor())

export class ContactInputBarRegistry extends InputBarRegistry<
  ContactInputBarController,
  Contact
> {
  readonly name = 'ContactInputBarRegistry'

  constructor(app: AppStore) {
    super(app, {
      classConstructor: (contact) => new ContactInputBarController(app, contact),
      cacheMax: 1,
    })
  }

  isController(controller: unknown): controller is ContactInputBarController {
    return controller instanceof ContactInputBarController
  }

  isModel(model: unknown): model is Contact {
    return model instanceof Contact
  }
}

export class ContactInputBarController extends InputBarController<Contact> {
  readonly features = { attachments: false, commands: false, mentions: true }

  constructor(app: AppStore, readonly contact: Contact) {
    super(app, getEditor())

    makeObservable(this, {
      model: computed,
    })

    this.disposeBag.add(
      this.messages$.subscribe(() => {
        if (contact.local) {
          contact.update().catch(this.app.toast.showError)
        }
        contact.addNote(this.messageSerialized).catch(this.app.toast.showError)
      }),
    )
  }

  get model() {
    return this.contact
  }
}

export class ContactNoteInputBarRegistry extends InputBarRegistry<
  ContactNoteInputBarController,
  Note
> {
  readonly name = 'ContactNoteInputBarRegistry'

  constructor(app: AppStore) {
    super(app, {
      classConstructor: (note) => new ContactNoteInputBarController(app, note),
      cacheMax: 1,
    })
  }

  isController(controller: unknown): controller is ContactNoteInputBarController {
    return controller instanceof ContactNoteInputBarController
  }

  isModel(model: unknown): model is Note {
    return model instanceof Note
  }
}

export class ContactNoteInputBarController extends InputBarController<Note> {
  readonly features = { attachments: false, commands: false, mentions: true }

  constructor(app: AppStore, readonly note: Note) {
    super(app, InputBarController.createSlateEditor())

    makeObservable(this, {
      model: computed,
    })

    this.disposeBag.add(
      this.messages$.subscribe(() => {
        note.update(this.messageSerialized).catch(this.app.toast.showError)
      }),
    )
  }

  get model() {
    return this.note
  }
}
