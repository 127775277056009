// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '.'
import { Invitation } from '../types'
import { Collection, Member, PersistedCollection, Presence } from './model'
import { MemberRepository, PresenceRepository } from './worker/repository'
import analytics from '@src/lib/analytics'

export default class MemberStore {
  collection: PersistedCollection<Member, MemberRepository>
  presence: PersistedCollection<Presence, PresenceRepository>
  orgMember = new Collection<Member>({ bindElements: true })

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('member'),
      classConstructor: () => new Member(service),
    })
    this.presence = new PersistedCollection({
      table: service.storage.table('presence'),
      classConstructor: () => new Presence(service),
    })

    makeAutoObservable(this, {})
    this.subscribeToWebSocket()
  }

  get(id: string) {
    return this.collection.get(id)
  }

  fetch() {
    this.collection.performQuery((repo) => repo.all())
    return this.service.transport.account.members
      .list()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
  }

  setRole(member: Member): Promise<any> {
    this.orgMember.put(member)
    if (member.status === 'invited') {
      return this.service.transport.account.organization
        .for(this.service.organization.current.id)
        .invites.update(member.id, { role: member.role })
    } else {
      return this.service.transport.account.members.put(member.id, { role: member.role })
    }
  }

  fetchAdmin() {
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .member.fetch()
      .then((res) =>
        this.orgMember.putBulk(
          res.map((json) => new Member(this.service).deserialize(json)),
        ),
      )
  }

  fetchPresence() {
    this.presence.performQuery((repo) => repo.all())
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .presence.list()
      .then((res) => this.presence.load(res, { deleteOthers: true }))
  }

  updatePresence(presence: Presence) {
    this.presence.put(presence)
    return this.service.transport.account.presence.put(presence.serialize())
  }

  setDoNotDisturb(presence: Presence) {
    if (presence.snoozedUntil > Date.now()) {
      const duration = (presence.snoozedUntil - Date.now()) / 1000 / 60
      return this.service.transport.account.presence.doNotDisturb(duration)
    } else {
      return this.service.transport.account.presence.clearDoNotDisturb()
    }
  }

  invite = (invite: Invitation) => {
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.send(invite)
      .then((res) => {
        analytics.invited()
        return this.orgMember.put(new Member(this.service).deserialize(res))
      })
  }

  inviteBulk = (invites: Invitation[]) => {
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.sendBulk(invites)
      .then((res) => {
        analytics.invited()
        return res
      })
  }

  uninvite = (inviteId: string) => {
    this.orgMember.delete(inviteId)
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.delete(inviteId)
  }

  delete = (member: Member) => {
    this.collection.delete(member)
    this.orgMember.delete(member)
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .member.delete(member.id)
  }

  private subscribeToWebSocket() {
    this.service.transport.onMessage.subscribe((message: any) => {
      switch (message.type) {
        case 'user-presence-update':
          return this.presence.load(message.presence)
        case 'member-update':
          return this.collection.load(message.member)
      }
    })
  }
}
