// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../../component/icon-button'
import { ChevronLeftIcon } from '../../../component/icons/Tint/20/General'
import { ScrollView } from '../../../component/scrollview'
import { Participant } from '../../../service/model/participant'
import ParticipantDetails from './participant'
import Recipients from './recipients'

interface InboxDetailsViewProps {
  compressed: boolean
}

const InboxDetailsView: React.FC<InboxDetailsViewProps> = function ({ compressed }) {
  const styles = useStyles({ compressed })
  const { inboxes, conversation } = useAppStore()
  const isGroup = conversation.current?.isGroup
  const identity = inboxes.selected?.details

  const handleBack = () => {
    inboxes.selected.setDetails(null)
  }

  return (
    <ScrollView className={styles.root}>
      {identity && isGroup && (
        <div className={styles.header}>
          <IconButton
            title="Back"
            size="medium"
            icon={<ChevronLeftIcon />}
            onClick={handleBack}
          />
        </div>
      )}
      {identity ? <ParticipantDetails /> : <Recipients />}
    </ScrollView>
  )
}

export default observer(InboxDetailsView)

const useStyles = makeStyles<Theme, Partial<InboxDetailsViewProps>>((theme) => ({
  root: {
    width: ({ compressed }) => (compressed ? 330 : 376),
    position: 'relative',
  },
  header: {
    height: 60,
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
}))
