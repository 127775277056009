import React, { useCallback, useRef } from 'react'
import useEventListeners from '@src/lib/useEventListeners'

export interface ClickAwayListenerChildProps {
  ref: React.RefObject<Element>
  onClickCapture?: (event: React.SyntheticEvent) => void
  onTouchEndCapture?: (event: React.SyntheticEvent) => void
}

export type ClickAwayListenerChild = React.ReactElement<ClickAwayListenerChildProps>

export interface ClickAwayListenerProps {
  children: ClickAwayListenerChild
  onClickAway: () => void
}

export const ClickAwayListener = ({
  children: child,
  onClickAway,
}: ClickAwayListenerProps) => {
  const childRef = useRef<Element>(null)
  const syntheticEventRef = useRef(false)

  const handleClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      setTimeout(() => {
        const insideReactTree = syntheticEventRef.current
        syntheticEventRef.current = false

        const path = event.composedPath()

        if (!path.includes(childRef.current as EventTarget) && !insideReactTree) {
          onClickAway()
          return
        }
      }, 0)
    },
    [onClickAway],
  )

  useEventListeners(
    document.body,
    {
      click: handleClickAway,
      touchend: handleClickAway,
    },
    { capture: true },
    [handleClickAway],
  )

  const createSyntheticHandler =
    (eventName: 'onClickCapture' | 'onTouchEndCapture') =>
    (event: React.SyntheticEvent) => {
      syntheticEventRef.current = true

      const propHandler = child.props[eventName]

      if (propHandler) {
        propHandler(event as React.SyntheticEvent)
      }
    }

  const childProps: Required<ClickAwayListenerChildProps> = {
    ref: childRef,
    onClickCapture: createSyntheticHandler('onClickCapture'),
    onTouchEndCapture: createSyntheticHandler('onTouchEndCapture'),
  }

  return <>{React.cloneElement(child, childProps)}</>
}

export default ClickAwayListener
