import { makeStyles, Theme } from '@material-ui/core/styles'
import useTitle from '@src/lib/use-title'
import { colors } from '@src/theme'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useLoginStore } from '.'
import Button from '../../component/button-v2'
import { GoogleIcon, LogomarkIcon } from '../../component/icons/Tint/Misc'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import keyboard from '../../lib/keyboard'
import useInputState from '../../lib/use-input-state'
import { Disclaimer } from '../onboarding/common'

interface LoginOptionsProps {}

const LoginOptions: React.FC<LoginOptionsProps> = function ({}) {
  const styles = useStyles({})
  const loginStore = useLoginStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')

  useEffect(() => {
    loginStore.setInitialStep()
  }, [])

  const handleCode = () => {
    loginStore.continueToCode(email)
  }

  const handlePassword = (event) => {
    event.preventDefault()
    loginStore.continueToPassword(email)
  }

  const loginTitle = 'Log in'
  const signUpTitle = 'Sign up and start your free trial'

  useTitle(loginStore.mode === 'signup' ? signUpTitle : loginTitle)

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <LogomarkIcon />
            {loginStore.mode === 'signup' && loginStore.coupon ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Redeem your <br />
                  {loginStore.coupon.name} offer
                </Typography>
                <Typography
                  variant="subheadline"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Save {loginStore.coupon.percent_off}% for{' '}
                  {loginStore.coupon.duration_in_months} months
                </Typography>
              </>
            ) : loginStore.invite ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Join {loginStore.invite.name ?? 'your team'} on OpenPhone
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Create an account to start.
                </Typography>
              </>
            ) : loginStore.mode === 'signup' ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Your business phone, reimagined.
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Create an account and start your free trial.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Welcome back!
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  It’s great to see you 👋
                  <br />
                  Log in to your account below.
                </Typography>
              </>
            )}

            <Button
              height={48}
              fullWidth
              className={styles.googleLogin}
              loading={loginStore.googleLoading}
              disabled={loginStore.loading}
              onClick={loginStore.loginWithGoogle}
              startIcon={<GoogleIcon />}
            >
              Continue with Google
            </Button>

            <Typography
              variant="footnote"
              color="textSecondary"
              style={{ marginTop: 32, marginBottom: 10 }}
            >
              Or continue with email
            </Typography>
            <TextInput
              autoFocus
              fullWidth
              value={email}
              placeholder="Email address..."
              onChange={setEmail}
              onKeyDown={keyboard.onEnter(handleCode)}
            />
            <Button
              fullWidth
              disabled={!email}
              loading={loginStore.loading}
              variant="contained"
              color="primary"
              height={40}
              style={{ marginTop: 12 }}
              onClick={handleCode}
            >
              Continue
            </Button>

            {loginStore.mode === 'login' && (
              <Disclaimer
                emoji="🧙‍♀️"
                text={
                  <div>
                    We will email you a magic code to log in password-free. Or if you
                    prefer, you can log in with your{' '}
                    <a href="/login/password" onClick={handlePassword}>
                      password instead
                    </a>
                    .
                  </div>
                }
              />
            )}

            {loginStore.mode === 'login' ? (
              <div style={{ marginTop: 32, marginBottom: 4 }}>
                <Typography variant="footnote" color="textSecondary">
                  Don’t have an account yet?
                </Typography>
                <Button
                  height={30}
                  variant="text"
                  color="primary"
                  fontWeight="regular"
                  onClick={loginStore.toggleMode}
                >
                  Sign up
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: 32, marginBottom: 4 }}>
                <Typography variant="footnote" color="textSecondary">
                  Already have an account?
                </Typography>
                <Button
                  height={30}
                  variant="text"
                  color="primary"
                  fontWeight="regular"
                  onClick={loginStore.toggleMode}
                >
                  Log back in
                </Button>
              </div>
            )}
          </div>

          <Typography
            variant="caption1"
            color="textTertiary"
            fontWeight="regular"
            className={styles.terms}
          >
            By continuing, you acknowledge that you have read, understood, and agree to
            OpenPhone's{' '}
            <a href="https://www.openphone.co/terms" target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.openphone.co/privacy" target="_blank">
              Privacy Policy
            </a>
            .
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default observer(LoginOptions)

const gradients = [
  require('../../assets/gradient-background-1.jpg').default,
  require('../../assets/gradient-background-2.jpg').default,
]

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    height: '100%',
    backgroundSize: 'cover',
    background: `url(${gradients[1]})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
  },
  wrapper: {
    margin: 'auto 0',

    '@media (max-width: 700px)': {
      margin: 0,
      width: '100%',
      height: '100%',
    },
  },
  card: {
    width: 672,
    borderRadius: 20,
    background: theme.palette.op.background.primary,
    boxShadow: '0 8px 64px 0 rgba(0, 0, 0, 0.22)',
    margin: '50px 0',
    padding: 40,

    '@media (max-width: 700px)': {
      width: '100%',
      margin: 0,
      borderRadius: 0,
      minHeight: '100%',
    },
  },
  cardContent: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    textAlign: 'center',
  },
  googleLogin: {
    background: colors.googleBrandBlue,
    marginTop: 40,
    color: theme.palette.op.white,
  },
  terms: {
    marginTop: 64,
    maxWidth: 400,
    margin: '0 auto',
    textAlign: 'center',

    '& a': {
      color: theme.palette.op.text.tertiary,
    },
  },
}))
