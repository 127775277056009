import { makeStyles, Theme } from '@material-ui/core'
import { CircularProgress } from '@src/component/progress'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React, { useLayoutEffect } from 'react'
import { useAppStore } from '@src/app/context'
import { WebhooksPage } from './page'
import WebhooksWelcomePage from './WebhooksWelcomePage'

interface WebhooksTableRouteProps {}

const WebhooksTableRoute = (props: WebhooksTableRouteProps) => {
  const { workspace } = useAppStore()
  const styles = useStyles()

  if (workspace.webhooks.length === 0) {
    return workspace.webhooksNetworkStatusState === PromiseStateStatus.Idle ||
      workspace.webhooksNetworkStatusState === PromiseStateStatus.Loading ? (
      <div className={styles.progress}>
        <CircularProgress />
      </div>
    ) : (
      <WebhooksWelcomePage />
    )
  }

  return <WebhooksPage />
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    progress: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: WebhooksTableRoute.name },
)

export default observer(WebhooksTableRoute)
