// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useRef, useState } from 'react'
import { ContactType, isEditable } from '.'
import { useAppStore } from '@src/app/context'
import IconButton from '../../component/icon-button'
import { DeleteIcon, Share1Icon } from '../../component/icons/Tint/20/General'
import {
  CallIcon,
  EnvelopeIcon,
  MessageIcon,
  MoreIcon,
} from '../../component/icons/Tint/24/General'
import Menu, { MenuItem } from '../../component/popover-menu'
import Tooltip from '../../component/tooltip'
import Typography from '@ui/Typography'
import { Contact, isContact } from '../../service/model'
import { AppAvatar } from '@src/app/components'
import DuplicateSelector from './duplicate-selector'
import EditName from './edit-name'

interface ContactHeaderProps {
  contact: ContactType
  matchingContacts: ContactType[]
  onContactSelect: (contact: ContactType) => void
}

const ContactHeader: React.FC<ContactHeaderProps> = function ({
  matchingContacts,
  contact,
  onContactSelect,
}) {
  const styles = useStyles({})
  const { command, inboxes, voice, service, toast } = useAppStore()
  const moreRef = useRef<HTMLButtonElement>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null)
  const [editing, setEditing] = useState(false)
  const name = isContact(contact) ? contact.fullName : contact.name

  const handleUpload = () => {
    if (!isEditable(contact)) return
    command.present({
      name: 'upload',
      title: 'Set an image',
      description: `Upload an image for ${contact.name}`,
      accept: ['image/jpeg', 'image/png', 'image/gif'],
      onComplete: (pictureUrl) => {
        command.hide()
        contact.update({ pictureUrl }).catch(toast.showError)
      },
    })
  }

  const handleCall = () => {
    const from = inboxes.selectedPhoneNumber
    const to = contact.phones[0]?.number
    if (to) {
      voice.startCall(from, to)
    }
  }

  const handleMessage = () => {
    const phone = contact.phones[0]?.number
    if (phone) {
      inboxes.getOrSetPhoneNumberInbox().newConversation(phone)
    }
  }

  const handleEmail = () => {
    const email = contact.emailAddresses[0]
    if (email) {
      window.open(`mailto:${email}`)
    }
  }

  const handleEditName = () => {
    setEditing(true)
  }

  const handleDelete = () => {
    if (!isEditable(contact)) return
    handleHideMenu()
    contact.delete().catch(toast.showError)
  }

  const handleShare = () => {
    if (!isEditable(contact)) return
    handleHideMenu()
    command.present({
      name: 'edit shared with',
      ids: contact.sharedWith,
      title: 'Share Contact',
      subtitle: 'Give other people in your org access to this contact',
      onSave: (sharedWith) => {
        command.hide()
        contact.update({ sharedWith }).catch(toast.showError)
      },
    })
  }

  const handleAddToExisting = (c: Contact) => {
    if (!isEditable(contact)) return
    const item = contact.phoneNumbers[0]
    if (item) {
      c.addItem({ type: 'phone-number', value: item.value })
      c.update().catch(toast.showError)
    }
  }

  const handleShowMenu = () => {
    setMenuAnchorEl(moreRef.current)
  }

  const handleHideMenu = () => {
    setMenuAnchorEl(null)
  }

  return (
    <div className={styles.root}>
      <AvatarWrapper contact={contact} handleUpload={handleUpload}>
        <AppAvatar identity={contact} size={80} />
      </AvatarWrapper>
      {editing && isEditable(contact) ? (
        <EditName
          contact={contact}
          onDone={() => setEditing(false)}
          onAddToExisting={handleAddToExisting}
        />
      ) : (
        <EditNameWrapper contact={contact}>
          <Typography
            color={name ? 'textPrimary' : 'placeholder'}
            variant="title2"
            className={styles.name}
            onClick={handleEditName}
            nowrap
          >
            {name || 'Add a name...'}
          </Typography>
        </EditNameWrapper>
      )}
      <div className={styles.actions}>
        <Menu
          disablePortal
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
          anchorEl={moreRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -5, horizontal: 'right' }}
        >
          <MenuItem icon={<Share1Icon />} onClick={handleShare}>
            Share
          </MenuItem>
          {isEditable(contact) && !contact.local && (
            <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
              Delete
            </MenuItem>
          )}
        </Menu>

        <Menu
          disablePortal
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
          anchorEl={moreRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -5, horizontal: 'right' }}
        >
          <MenuItem icon={<Share1Icon />} onClick={handleShare}>
            Share
          </MenuItem>
          {isEditable(contact) && !contact.local && (
            <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
              Delete
            </MenuItem>
          )}
        </Menu>

        <IconButton
          opaque
          rounded
          size="large"
          title={contact.shortName ? `Call ${contact.shortName}` : 'Call'}
          icon={<CallIcon />}
          onClick={handleCall}
        />
        <IconButton
          opaque
          rounded
          size="large"
          title={contact.shortName ? `Message ${contact.shortName}` : 'Message'}
          icon={<MessageIcon />}
          onClick={handleMessage}
        />
        {contact.emailAddresses.length > 0 && (
          <IconButton
            opaque
            rounded
            size="large"
            title={contact.shortName ? `Email ${contact.shortName}` : 'Email'}
            icon={<EnvelopeIcon />}
            onClick={handleEmail}
          />
        )}
        {isEditable(contact) && !contact.local && (
          <IconButton
            opaque
            rounded
            size="large"
            title="More Actions"
            icon={<MoreIcon />}
            ref={moreRef}
            onClick={handleShowMenu}
          />
        )}
      </div>
      {matchingContacts?.length > 1 && (
        <DuplicateSelector
          selectedContact={contact}
          matchingContacts={matchingContacts}
          onSelect={onContactSelect}
        />
      )}
    </div>
  )
}

const AvatarWrapper = ({
  children,
  contact,
  handleUpload,
}: PropsWithChildren<{ contact: ContactType; handleUpload: () => void }>) => {
  const styles = useStyles()
  if (!isEditable(contact)) return <>{children}</>
  return (
    <Tooltip title="Upload an image" placement="bottom">
      <div onClick={handleUpload} className={styles.avatarPicker}>
        {children}
      </div>
    </Tooltip>
  )
}

const EditNameWrapper = ({
  contact,
  children,
}: PropsWithChildren<{ contact: ContactType }>) => {
  const styles = useStyles()
  if (!isEditable(contact)) return <>{children}</>
  return (
    <Tooltip title="Edit name" placement="top">
      <div className={styles.editableName}>{children}</div>
    </Tooltip>
  )
}

export default observer(ContactHeader)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2rem 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  avatarPicker: {
    cursor: 'pointer',
  },
  name: {
    alignSelf: 'stretch',
    height: 30,
    marginTop: 14,
    textAlign: 'center',
  },
  editableName: {
    cursor: 'pointer',

    '&>*:hover': {
      color: theme.palette.op.primary[3],
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,

    '& > *': {
      marginRight: 24,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}))
