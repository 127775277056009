import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { isValid } from '../../../../lib/phone-number'
import { Participant } from '../../../../service/model'

interface ParticipantChipProps {
  participant: Participant
  onClick: (participant: Participant) => void
  onDelete: (participant: Participant) => void
}

const ParticipantChip: React.FC<ParticipantChipProps> = function ({
  participant,
  onClick,
  onDelete,
}) {
  const styles = useStyles({})
  const invalid = !isValid(participant.phoneNumber)

  const handleClick = () => {
    onClick(participant)
  }

  return (
    <div className={cx(styles.root, invalid && styles.invalid)} onClick={handleClick}>
      {participant.name}
      <div onClick={() => onDelete(participant)} className={styles.delete}>
        <CloseIcon style={{ transform: 'scale(0.65)' }} />
      </div>
    </div>
  )
}

export default observer(ParticipantChip)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1.5px solid transparent`,
    borderRadius: 3,
    cursor: 'pointer',
    fontSize: 14,
    padding: '4px 28px 4px 10px',
    marginRight: 2,
    position: 'relative',
    whiteSpace: 'nowrap',
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    margin: '1px 0',
    transition: theme.transitions.create(['background', 'border-color'], {
      duration: 100,
      easing: 'ease',
    }),

    '&:after': {
      content: '""',
      position: 'absolute',
      right: 8,
      top: 10,
      width: 6,
      height: 6,
      borderRadius: '50%',
      pointerEvents: 'none',
      background: theme.palette.op.background.highlight(0.2),
    },

    '&:hover': {
      background: theme.palette.op.background.highlight(0.1),
      borderColor: theme.palette.op.background.highlight(0.2),

      '&:after': {
        opacity: 0,
      },

      '& $delete': {
        opacity: 1,
      },
    },
  },
  invalid: {
    background: theme.palette.error.main,

    '&:after': {
      opacity: 0,
    },

    '&:hover': {
      background: theme.palette.error.main,
    },

    '& $delete': {
      opacity: 1,
    },
  },
  delete: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    padding: '0 2px',
    opacity: 0,
  },
}))
