// @ts-strict-ignore
import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Header from '../common/header'
import Options, { GreetingOption } from './options'
import Record from './record'
import Upload from './upload'
import TextToSpeech from './tts'
import { Command } from '../common-v2'

export interface SetGreetingCommandProps {
  title: string
  description: string
  defaultText?: string
  onComplete: (url: string, text: string) => void
}

const SetGreetingCommand: React.FC<SetGreetingCommandProps> = function ({
  title,
  description,
  defaultText,
  onComplete,
}) {
  const styles = useStyles({})
  const [option, setOption] = useState<GreetingOption>(null)

  return (
    <Command>
      <Header title={title} subtitle={description} />
      {!option && <Options onSelect={setOption} />}
      {option === 'upload' && <Upload onComplete={onComplete} />}
      {option === 'record' && <Record onComplete={onComplete} />}
      {option === 'text-to-speach' && (
        <TextToSpeech defaultText={defaultText} onComplete={onComplete} />
      )}
    </Command>
  )
}

export default SetGreetingCommand

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
}))
