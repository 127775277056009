// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { fromEvent } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { useAppStore } from '@src/app/context'
import Checkbox from '../../component/checkbox-v2'
import { SearchIcon } from '../../component/icons/Tint/20/General'
import Typography from '@ui/Typography'
import { useObservable } from '../../lib/hooks'
import useKeyboardShortcuts, {
  defaultWithInputOnScreen,
} from '../../lib/use-keyboard-shortcuts'
import { typography } from '../../theme'

interface SearchBarProps {}

const SearchBar: React.FC<SearchBarProps> = function ({}) {
  const styles = useStyles({})
  const { contacts } = useAppStore()
  const searchRef = useRef<HTMLInputElement>(null)

  const allSelected =
    contacts.list.length > 0 && contacts.list.every((c) => contacts.checked.has(c.id))

  const someSelected =
    contacts.list.length > 0 && contacts.list.some((c) => contacts.checked.has(c.id))

  useObservable(
    () =>
      fromEvent(searchRef.current, 'input').pipe(
        map((e: any): string => e.target.value),
        debounceTime(500),
      ),
    (search) => {
      contacts.setSearch(search)
    },
  )

  useKeyboardShortcuts({
    node: document,
    name: 'ContactSelectAll',
    filter: defaultWithInputOnScreen({ allowedShortcutsAlways: ['Escape'] }),
    handler: (shortcut, event) => {
      if (shortcut === 'Meta+KeyA') {
        contacts.selectAll()
        event.preventDefault()
      } else if (shortcut === 'Escape' && someSelected) {
        contacts.unselectAll()
        event.preventDefault()
      }
    },
    dep: [someSelected],
  })

  const handleSelectChanged = () => {
    if (someSelected) {
      contacts.unselectAll()
    } else {
      contacts.selectAll()
    }
  }

  return (
    <div className={styles.root}>
      <Checkbox
        indeterminate={!allSelected && someSelected}
        checked={allSelected}
        onChange={handleSelectChanged}
      />
      <Typography color="textSecondary" className={styles.icon}>
        <SearchIcon />
      </Typography>
      <input
        type="text"
        placeholder="Search"
        defaultValue={contacts.search}
        ref={searchRef}
        className={styles.search}
      />
    </div>
  )
}

export default observer(SearchBar)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '0 22px',
  },
  icon: {
    width: 24,
    height: 20,
    marginLeft: 14,
    marginRight: 10,
  },
  search: {
    ...typography.body,
    color: theme.palette.op.text.primary,
    flex: 1,
    background: 'none',
    alignSelf: 'stretch',
    border: 'none',
    outline: 'none',
    padding: 0,
    margin: 0,
  },
}))
