// @ts-strict-ignore
import React from 'react'
import cx from 'classnames'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'

interface CommandProps extends React.HTMLProps<HTMLDivElement> {
  width?: number
  height?: 'default' | 'large'
}

export const Command: React.FC<CommandProps> = React.forwardRef(function (
  { width = 512, height = 'default', className, ...props },
  ref,
) {
  const styles = useStyles({ width, height })
  return (
    <div ref={ref} className={styles.container}>
      <div {...props} className={cx(styles.root, className)} />
    </div>
  )
})

const useStyles = makeStyles<Theme, CommandProps>((theme) => ({
  container: ({ width }) => ({
    width,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    borderRadius: 5,
    margin: '100px auto',
  }),
  root: ({ height }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight:
      height === 'default' ? 'min(672px, calc(100vh - 200px))' : 'calc(100vh - 200px)',
    outline: 'none',
    position: 'relative',
    padding: 0,
    background: theme.palette.op.background.popover,
    boxShadow: theme.palette.op.shadow.modal,
    borderRadius: theme.palette.op.borderRadius.modal,
    boxSizing: 'border-box',
  }),
}))
