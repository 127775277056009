import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { SearchIcon } from '../../../component/icons/Tint/16/General'
import TextField from '../../../component/textfield-v2'
import { fuzzySearch } from '../../../lib/search'
import useInputState from '../../../lib/use-input-state'
import { Member } from '../../../service/model'
import GroupMemberRow from './GroupMemberRow'
import GroupMemberCell from './GroupMemberCell'
import Typography from '@ui/Typography'
interface GroupMembersProps {
  items: Member[]
  deleteMember?: (memberId: string) => void
}

const GroupMembers = ({ items, deleteMember }: GroupMembersProps) => {
  const [searchFilter, setSearchFilter] = useInputState('')
  const styles = useStyles()
  const [filteredItems, setFilteredItems] = useState(items)

  useEffect(() => {
    if (items.length !== filteredItems.length) {
      setFilteredItems(items)
    }
  }, [items])

  useEffect(() => {
    if (searchFilter.length === 0) {
      setFilteredItems(items)
    } else {
      setFilteredItems(fuzzySearch(items, searchFilter, ['name', 'email']))
    }
  }, [searchFilter])

  if (items.length === 0) {
    return null
  }

  return (
    <div className={styles.root}>
      <TextField
        rootClassName={styles.searchBar}
        size={35}
        startAdornment={() => <SearchIcon />}
        value={searchFilter}
        onChange={setSearchFilter}
        placeholder="Search name or email..."
      />
      {filteredItems.length !== 0 ? (
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <GroupMemberCell padding="none">Name</GroupMemberCell>
                <GroupMemberCell align="left">Email</GroupMemberCell>
                <GroupMemberCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody className={styles.body}>
              {filteredItems.map((member) => (
                <GroupMemberRow
                  key={member.id}
                  member={member}
                  deleteMember={deleteMember}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="footnote" color="textTertiary" className={styles.noResults}>
          {'No results found matching your search query.'}
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '10px 32px 0px 32px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    searchBar: {
      marginBottom: 8,
    },
    tableContainer: {
      overflow: 'auto',
      flex: 1,
    },
    body: {
      overflow: 'auto',
    },
    actionRow: {
      fontSize: '0.8rem',
      color: theme.palette.primary.light,
    },
    actionIcon: {
      color: theme.palette.primary.light,
    },
    youTag: {
      marginRight: 6,
    },
    noResults: {
      marginTop: 10,
    },
  }),
  { name: GroupMembers.name },
)

export default observer(GroupMembers)
