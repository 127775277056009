import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { CSSProperties } from 'react'
import { typography, TypographyVariant } from '@src/theme'

export type TypographyColor =
  | 'inherit'
  | 'primary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'error'
  | 'placeholder'

export type TypographyWeight = 'regular' | 'medium' | 'semibold' | 'bold'

export type TypographyTransform =
  | 'none'
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'initial'
  | 'inherit'

export interface TypographyProps<C extends React.ElementType> extends React.HTMLProps<C> {
  /**
   * The component that will wrap the text. If none is provided it will default to `<div>`.
   */
  component?: C

  /**
   * The design system variant of the text.
   *
   * @default body
   */
  variant?: TypographyVariant | 'inherit'

  /**
   * The design system color of the text.
   *
   * @default inherit
   */
  color?: TypographyColor

  /**
   * If true, the text will display an ellipsis instead of wrapping.
   *
   * @default false
   */
  nowrap?: boolean

  /**
   * The design system font weight of the text.
   *
   * - `regular`: 450
   * - `medium`: 550
   * - `semibold`: 650
   * - `bold`: 750
   *
   * @default regular
   */
  fontWeight?: TypographyWeight

  /**
   * @default none
   */
  textTransform?: TypographyTransform
}

export const Typography = React.forwardRef(
  <T extends React.ElementType>(
    {
      component,
      variant = 'body',
      color = 'inherit',
      fontWeight = 'regular',
      textTransform = 'none',
      className,
      nowrap,
      ...props
    }: TypographyProps<T>,
    outerRef,
  ) => {
    const styles = useStyles({ color, textTransform, variant, nowrap, fontWeight })

    const Component: React.ElementType = component ?? 'div'
    return <Component ref={outerRef} className={cx(styles.root, className)} {...props} />
  },
)

export default Typography

const useStyles = makeStyles<
  Theme,
  Required<
    Pick<TypographyProps<any>, 'color' | 'variant' | 'textTransform' | 'fontWeight'>
  > &
    Pick<TypographyProps<any>, 'nowrap'>
>((theme) => ({
  root: ({ color, variant, textTransform, nowrap, fontWeight }) => ({
    ...getVariantStyles(theme, variant),
    color: getColor(theme, color),
    ...typography[variant],
    textTransform,
    fontWeight: getWeight(fontWeight),
    ...(nowrap
      ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }
      : {}),
  }),
}))

const getColor = (theme: Theme, color: TypographyColor): string => {
  switch (color) {
    case 'primary':
      return theme.palette.op.primary[2]
    case 'textPrimary':
      return theme.palette.op.text.primary
    case 'textSecondary':
      return theme.palette.op.text.secondary
    case 'textTertiary':
      return theme.palette.op.text.tertiary
    case 'error':
      return theme.palette.op.secondary.red2
    case 'placeholder':
      return theme.palette.op.text.placeholder
    default:
      return color
  }
}

export const getWeight = (weight: TypographyWeight): number => {
  switch (weight) {
    case 'regular':
      return 450
    case 'medium':
      return 550
    case 'semibold':
      return 650
    case 'bold':
      return 750
  }
}

const getVariantStyles = (
  theme: Theme,
  variant: TypographyProps<any>['variant'],
): CSSProperties | undefined => {
  if (variant === 'code') {
    return {
      borderRadius: 4,
      color: theme.palette.op.gray[1],
      width: 'max-content',
      padding: '3px 5px',
      background: theme.palette.op.hover.primary,
    }
  }
  return undefined
}
