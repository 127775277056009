import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { Header, Page } from './common'
import { Card, CardContent, CardHeader } from './settings-card'
import { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ReferralLink from './referral-link'

const Referrals = () => {
  const styles = useStyles({})
  const { service, toast } = useAppStore()

  useEffect(() => {
    service.referral.fetchAccepted().catch(toast.showError)
  }, [service.referral.fetchAccepted])

  return (
    <Page>
      <Header title="Referrals" subtitle="Manage your OpenPhone referrals here" />
      <div className={styles.card}>
        <ReferralLink />
      </div>
      {service.referral.acceptedReferrals.length > 0 && (
        <>
          <Card variant="elevation">
            <CardHeader
              title="Rewards"
              subheader="Credits are received once your friend finishes their free trial and moves to a paid OpenPhone subscription plan"
            />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Cell padding="none" colSpan={2}>
                        Name
                      </Cell>
                      <Cell padding="none" colSpan={2}>
                        Email
                      </Cell>
                      <Cell padding="none" colSpan={2}>
                        Status
                      </Cell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={styles.tableBody}>
                    {service.referral.acceptedReferrals.map((acceptedReferral) => {
                      return (
                        <TableRow key={acceptedReferral.id}>
                          <Cell colSpan={2}>
                            {acceptedReferral.user.firstName &&
                            acceptedReferral.user.lastName
                              ? `${acceptedReferral.user.firstName} ${acceptedReferral.user.lastName}`
                              : 'No name'}
                          </Cell>
                          <Cell colSpan={2}>{`${acceptedReferral.user.email}`}</Cell>
                          <Cell
                            colSpan={2}
                            className={cx(
                              acceptedReferral.fulfilledAt && styles.completedColor,
                            )}
                          >
                            {acceptedReferral.canceledAt
                              ? 'Cancelled'
                              : acceptedReferral.fulfilledAt
                              ? 'Completed'
                              : 'Pending'}
                          </Cell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <a
            href="https://www.openphone.co/terms"
            target="_blank"
            className={styles.termsLink}
          >
            <Typography
              variant="caption1"
              color="textSecondary"
              fontWeight="regular"
              component="label"
              className={styles.rewardsAndConditionsText}
            >
              Rewards terms & conditions
            </Typography>
          </a>
        </>
      )}
    </Page>
  )
}

export default observer(Referrals)

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: 24,
    marginBottom: 50,
  },
  completedColor: {
    color: theme.palette.op.secondary.green2,
  },
  rewardsAndConditionsText: {
    display: 'block',
    margin: '1em 0',
    padding: '0 0 10px 3px',
  },
  tableBody: {
    '& > tr:not(:first-of-type)': {
      borderTop: `1.5px solid ${theme.palette.op.border.common}`,
    },
  },
  termsLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
}))

const Cell = withStyles((theme) => ({
  root: {
    padding: '10px 0',
    border: 'none',
  },
  head: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  },
  footer: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))(TableCell)
