import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import mergeWith from 'lodash/fp/mergeWith'
import { chunk } from '.'

/**
 * Given an array of data, it breaks the arrow into smaller chunks and
 * passes them through to the callback function. After every run, the next batch
 * is scheduled on the next available run loop.
 *
 * This is used to process very large arrays of data without blocking the
 * UI thread for a long time.
 */
export function runInBatches<T, R>(
  data: T[],
  batchSize: number,
  handler: (batch: T[]) => R[],
): Promise<R[]> {
  return new Promise<R[]>((resolve, reject) => {
    const result: R[] = []
    const runs = chunk(data, batchSize)

    const run = () => {
      const batch = runs.pop()
      if (!batch) {
        resolve(result)
        return
      }
      result.push(...handler(batch))

      if (typeof window != 'undefined' && window.requestIdleCallback) {
        window.requestIdleCallback(run, { timeout: 1000 })
      } else {
        setTimeout(run, 0)
      }
    }

    run()
  })
}

export function wait(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const mergeClasses = mergeWith(
  (source: string | undefined, other: string | undefined) => {
    if (typeof source === 'string' && typeof other === 'string') {
      return `${source} ${other}`
    }
  },
)
