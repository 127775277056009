import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { fonts } from '@src/theme'

const Code = ({ content }: { content: string }) => {
  const styles = useStyles()
  return (
    <SyntaxHighlighter
      language="json"
      showLineNumbers
      useInlineStyles={false}
      lineNumberStyle={{ minWidth: '5ch' }}
      style={{}}
      className={styles.code}
    >
      {content}
    </SyntaxHighlighter>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  code: {
    margin: 0,
    padding: '10px 0',
    borderRadius: 8,
    border: `1px solid ${theme.palette.op.gray[5]}`,
    backgroundColor: theme.palette.op.gray[6],
    minHeight: 44,
    overflow: 'auto',
    fontFamily: fonts.jetBrainsMono,
    color: theme.palette.op.gray[1],

    '& *': {
      fontFamily: 'inherit',
      fontSize: 13,
      lineHeight: '24px',
      color: 'inherit',
      userSelect: 'text',
    },

    '& .linenumber': {
      color: theme.palette.op.gray[3],
      position: 'sticky',
      left: 0,
      background: theme.palette.op.gray[6],
      paddingLeft: 10,
    },

    '& .token.string': {
      color: theme.palette.op.secondary.green2,
    },

    '& .token.null.keyword': {
      color: theme.palette.op.secondary.orange,
    },
  },
}))

export default Code
