// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/button-v2'
import TextField from '@src/component/textfield-v2'
import useInputState from '@src/lib/use-input-state'
import Badge from '@ui/Badge'
import { Typography } from '@ui/Typography'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import analytics from '@src/lib/analytics'

const BillingCancelSubscription = () => {
  const styles = useStyles()
  const { service, toast, inboxes, showAlert } = useAppStore()
  const [input, setInput] = useInputState('')
  const subscription = service.billing.subscription
  const isTrial = subscription?.trial

  const handleCancel = () => {
    service.billing
      .cancel()
      .then(() => {
        analytics.cancellation()
        setInput('')
        toast.show({
          message: isTrial
            ? 'Your trial has ended.'
            : 'Your subscription has been canceled.',
        })
      })
      .catch(toast.showError)
  }

  const isCancelSubscriptionDisabled = isTrial
    ? input.toLowerCase().trim() !== 'end trial'
    : input.toLowerCase().trim() !== 'cancel subscription'

  const handleLearnMore = useCallback(() => {
    showAlert({
      title: isTrial ? 'Ending your trial' : 'Canceling your subscription',
      body: <AlertBody isTrial={isTrial} />,
      actions: [{ title: 'Ok got it' }],
    })
  }, [isTrial])

  return (
    <>
      {!subscription?.isCanceled && (
        <div>
          <Badge label="Danger zone" variant="danger" />
          <Typography
            variant="title3"
            color="textPrimary"
            fontWeight="semibold"
            style={{ margin: '6px 0' }}
          >
            {isTrial ? 'End your trial' : 'Cancel subscription'}
          </Typography>
          <Typography variant="body" color="textSecondary" style={{ marginBottom: 20 }}>
            {isTrial
              ? 'When you end your trial, it will remain active until the end of the 7-day free period. After that, you and everyone in your workspace will lose access to all phone numbers and conversation data.'
              : 'When you cancel your subscription, it will remain active until the end of your current billing cycle. After that, you and everyone in your workspace will lose access to all phone numbers and conversation data.'}{' '}
            <span className={styles.getInTouch} onClick={handleLearnMore}>
              Learn more.
            </span>
          </Typography>
          <div className={styles.actionContainer}>
            <TextField
              placeholder={isTrial ? 'End trial' : 'Cancel subscription'}
              helperText={
                isTrial
                  ? 'Enter “end trial” to continue'
                  : 'Enter “cancel subscription” to continue'
              }
              value={input}
              onChange={setInput}
              rootClassName={styles.textFieldRoot}
              className={styles.textFieldInput}
            />
            <Button
              disabled={isCancelSubscriptionDisabled}
              variant="outlined"
              onClick={handleCancel}
              color="danger"
              height={40}
            >
              {isTrial ? 'End trial' : 'Cancel Subscription'}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

const AlertBody = ({ isTrial }: { isTrial: boolean }) => {
  const styles = useAlertBodyStyles()
  return (
    <>
      <Typography variant="callout" color="textSecondary">
        {isTrial
          ? 'When you end your trial, it will remain active until the end of the 7-day free period. After that, you and everyone in your workspace will lose access to all phone numbers and conversation data.'
          : 'When you cancel your subscription, it will remain active until the end of your current billing cycle. After that, you and everyone in your workspace will lose access to all phone numbers and conversation data.'}
      </Typography>
      <Typography className={styles.textBlock} variant="callout" color="textSecondary">
        {isTrial
          ? 'Once this happens, you will have 30 days to reactivate your account and subscribe to a plan, regaining access to your numbers and data. However, after the 30 day period, all data is permanently deleted and can not be recovered. All phone numbers will also be released for others to claim.'
          : 'Once this happens, you will have 30 days to reactivate your account and regain access to your numbers and data. However, after the 30 day period, all data is permanently deleted and can not be recovered. All phone numbers will also be released for others to claim.'}
      </Typography>
      <Typography variant="callout" color="textSecondary" className={styles.textBlock}>
        We hate to see you go, so if you want to keep using OpenPhone but something is not
        working out for you right now, please{' '}
        <a href="mailto:support@openphone.co" className={styles.getInTouch}>
          get in touch.
        </a>
      </Typography>
    </>
  )
}

const useAlertBodyStyles = makeStyles(
  (theme: Theme) => ({
    textBlock: {
      marginTop: 18,
    },
    getInTouch: {
      color: theme.palette.op.primary[2],
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
  { name: AlertBody.name },
)

const useStyles = makeStyles(
  (theme: Theme) => ({
    actionContainer: {
      display: 'flex',
    },
    textFieldRoot: {
      flex: 1,
      marginRight: 12,
    },
    textFieldInput: {
      height: 40,
    },
    getInTouch: {
      color: theme.palette.op.primary[2],
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
  { name: BillingCancelSubscription.name },
)

export default observer(BillingCancelSubscription)
