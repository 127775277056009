// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import Avatar from '../../../component/avatar'
import { CancelReason } from '../../../lib/api/client'
import { noop } from '../../../lib/fn'
import { VCard, fromFile, fromUrl } from '../../../lib/vcard'
import { ContactItemPart, Item } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'

export type ContactMediaProps<I extends Item> = ItemPartProps<I, ContactItemPart>

const ContactMedia = function <I extends Item>({
  controller,
  item,
  contact,
  ...props
}: ContactMediaProps<I>) {
  const styles = useStyles({})
  const [{ fn: name, photo, org: company }, setCard] = useState<Partial<VCard>>({})

  useEffect(() => {
    if (contact.url) {
      const loader = fromUrl(contact.url)
      loader.then(setCard, noop)
      return () => loader.cancel(CancelReason.ComponentUnmount)
    } else if (contact.file) {
      fromFile(contact.file).then(setCard)
    }
  }, [contact])

  const handleContactClick = useCallback(
    () => controller.openMedia(contact),
    [controller, contact],
  )

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props} className={styles.bubble}>
        <div className={styles.root} onClick={handleContactClick}>
          <Avatar identity={{ pictureUrl: photo }} />
          <div className={styles.body}>
            <div className={styles.name}>{name}</div>
            {company && <div className={styles.company}>{company}</div>}
          </div>
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(ContactMedia)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      cursor: 'pointer',
    },
    icon: {
      margin: '0 7px',
    },
    body: {
      margin: '0px 15px 0 10px',
    },
    name: {
      fontSize: '0.85rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    company: {
      fontSize: '0.6rem',
      marginTop: 2,
      color: theme.palette.text.secondary,
    },
    bubble: {
      userSelect: 'none',
      background: theme.palette.op.background.highlight(0.07),
      transition: theme.transitions.create(['background']),

      '&:hover': {
        background: theme.palette.op.background.highlight(0.15),
      },
    },
  }),
  { name: ContactMedia.name },
)
