// @ts-strict-ignore
import React, { useEffect } from 'react'
import Chip from '@material-ui/core/Chip'
import LocationIcon from '@material-ui/icons/RoomOutlined'
import useStyles from './styles'
import { usePlacesAutocomplete } from '@src/lib/usePlacesAutocomplete'

export type Location = {
  locality: string
  region: string
  country: string
}

type LocationFilterProps = {
  onChange: (location: Location) => void
  location: Location
}

const autocompleteOptions: google.maps.places.AutocompleteOptions = {
  types: ['(cities)'],
  componentRestrictions: { country: ['us', 'ca'] },
}

const LocationFilter: React.FC<LocationFilterProps> = function ({ onChange, location }) {
  const styles = useStyles({})
  const { autocomplete, ref, clear, focus } = usePlacesAutocomplete(autocompleteOptions)

  const handleChipDelete = () => {
    onChange(null)
    clear()
    setTimeout(() => focus())
  }

  useEffect(() => {
    if (!autocomplete || location !== null) return

    const listener = autocomplete.addListener('place_changed', () => {
      const components = autocomplete.getPlace().address_components

      if (!components) {
        onChange(null)
      } else {
        const place = {
          locality: components.find((c) => c.types.includes('locality')).long_name,
          region: components.find((c) => c.types.includes('administrative_area_level_1'))
            .short_name,
          country: components.find((c) => c.types.includes('country')).short_name,
        }
        onChange(place)
      }
    })

    return () => {
      listener?.remove()
    }
  }, [location, autocomplete])

  return (
    <div className={styles.filter}>
      <LocationIcon fontSize="small" className={styles.icon} />
      <div className={styles.searchWrapper}>
        <input
          type="text"
          autoFocus={true}
          ref={ref}
          spellCheck={false}
          placeholder="Enter a city name"
          className={location ? styles.hideSearchInput : null}
        />
        {location ? (
          <Chip
            label={`${location.locality}, ${location.region}`}
            onDelete={handleChipDelete}
            className={styles.locationChip}
          />
        ) : null}
      </div>
    </div>
  )
}

export default LocationFilter
