// @ts-strict-ignore
import { parseDate } from '../../lib'
import { Model } from '.'
import Service from '..'
import { makeAutoObservable } from 'mobx'
import { formatted } from '../../lib/phone-number'

export interface IBlocklist {
  createdAt: number
  id: string
  phoneNumber: string
  updatedAt: number
  userId: string
}

export class Blocklist implements IBlocklist, Model {
  createdAt: number = null
  id: string = null
  phoneNumber: string = null
  updatedAt: number = null
  userId: string = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get formatted() {
    return formatted(this.phoneNumber)
  }

  delete = () => {
    return this.service.blocklist.delete(this.id)
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      phoneNumber: this.phoneNumber,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  deserialize = (json) => {
    Object.assign(this, json)
    this.updatedAt = parseDate(json.updatedAt)
    this.createdAt = parseDate(json.createdAt)
    return this
  }
}
