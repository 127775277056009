import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import { formatDate } from '../../lib/date'
import { logError } from '../../lib/log'
import { Invite } from '../../service/model'
import { AppAvatar } from '@src/app/components'
import { Card } from './common'

interface PendingInvitesProps {}

const PendingInvites: React.FC<PendingInvitesProps> = function ({}) {
  const styles = useStyles({})
  const [disabled, setDisabled] = useState(false)
  const { service, toast } = useAppStore()
  const invites = service.user.invites.list

  const handleAccept = (invite: Invite) => {
    toast.showLoading(
      `Moving you to ${invite.org ? invite.org.name : 'the new workspace'}...`,
    )
    setDisabled(true)
    service.user
      .acceptInvite(invite)
      .then(() => window.location.reload())
      .catch(logError)
      .finally(() => {
        toast.hide()
        setDisabled(false)
      })
  }

  const handleReject = (invite: Invite) => {
    service.user.rejectInvite(invite)
  }

  return (
    <Card
      emoji="💌"
      title="Pending invites"
      description={`You have ${invites?.length ?? 0} pending ${
        invites?.length === 1 ? 'invite' : 'invites'
      }. Accepting an invite will move all your data into the new organization.`}
    >
      {invites?.map((invite) => (
        <div key={invite.id} className={styles.item}>
          <div className={styles.header}>
            <AppAvatar identity={invite.org} size={40} className={styles.avatar} />
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 'bold' }}
                variant="subtitle1"
                color="textPrimary"
              >
                {invite.org.name ?? 'Unnamed Workspace'}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {invite.org.numMembers}{' '}
                {invite.org.numMembers === 1 ? 'member' : 'members'} - sent on{' '}
                {formatDate(invite.createdAt, 'MMM D, YYYY')}
              </Typography>
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              height={36}
              variant="text"
              color="danger"
              onClick={(e) => handleReject(invite)}
              disabled={disabled}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              color="primary"
              height={36}
              onClick={() => handleAccept(invite)}
              style={{ marginLeft: 15 }}
              disabled={disabled}
            >
              Accept
            </Button>
          </div>
        </div>
      ))}
    </Card>
  )
}

export default observer(PendingInvites)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    borderRadius: 5,
    padding: '1rem',
    background: theme.palette.op.gray[6],
    border: `1.5px solid ${theme.palette.op.border.common}`,
    marginBottom: 5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 25,
  },
  avatar: { marginRight: 15 },
}))
