import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import { DecodableMessageMedia } from '../../../../service/model'

export interface VideoMediaPreviewProps {
  media: DecodableMessageMedia
  onOpen: (media: DecodableMessageMedia) => void
}

const VideoMediaPreview: React.FC<VideoMediaPreviewProps> = function ({ media, onOpen }) {
  const styles = useStyles({})
  const mediaRef = useRef<any>(null)

  useEffect(() => {
    if (media.url) {
      mediaRef.current.src = media.url
    } else if (media.file) {
      mediaRef.current.src = URL.createObjectURL(media.file)
    }
  }, [media])

  const handleClick = function () {
    onOpen(media)
  }

  return (
    <div className={styles.root} onClick={handleClick}>
      <video id="main-video" ref={mediaRef} className={styles.video} />
    </div>
  )
}

export default VideoMediaPreview

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 75,
      height: 75,
      borderRadius: 7,
      cursor: 'pointer',
    },
    video: {
      width: '100%',
      height: '100%',
      borderRadius: 7,
      pointerEvents: 'none',
    },
  }),
  { name: VideoMediaPreview.name },
)
