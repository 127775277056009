import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'
import { Typography } from '@ui/Typography'
import { darkTheme, lightTheme } from '@src/theme'
import React, { useMemo } from 'react'
import { mergeClasses } from '..'

interface TooltipProps extends Omit<MuiTooltipProps, 'classes'> {
  shortcut?: string
  disabled?: boolean
}

const Tooltip: React.FC<TooltipProps> = function ({
  shortcut,
  disabled,
  className,
  ...props
}) {
  const tooltipStyles = useTooltipStyles({})
  const classes = useMemo(
    () => mergeClasses(tooltipStyles, { tooltip: className }),
    [tooltipStyles, className],
  )
  const isDisabled = disabled || !props.title
  return (
    <MuiTooltip
      {...props}
      disableFocusListener={isDisabled}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
      classes={classes}
      enterDelay={props.enterDelay ?? 150}
      title={<TooltipContent title={props.title} shortcut={shortcut} />}
    />
  )
}

interface TooltipContentProps {
  title: React.ReactNode
  shortcut?: string
}

const TooltipContent: React.FC<TooltipContentProps> = function ({ title, shortcut }) {
  const styles = useStyles({})
  const keys = (shortcut?.split('+') || []).map((key) => {
    if (key.toLowerCase() === 'shift') {
      return '⇧'
    } else if (key.toLowerCase() === 'meta') {
      return '⌘'
    }
    return key
  })

  return (
    <div className={styles.content}>
      <Typography
        variant="caption1"
        color="inherit"
        fontWeight="regular"
        style={{ margin: '8px 0', fontSize: 'inherit', fontWeight: 'inherit' }}
      >
        {title}
      </Typography>
      {shortcut && (
        <div className={styles.shortcut}>
          {keys.map((key) => (
            <Typography
              key={key}
              variant="caption3"
              fontWeight="semibold"
              className={styles.key}
            >
              {key}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

export default Tooltip

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    fontSize: '11px',
    fontWeight: 550,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 312,
    minHeight: 30,
    padding: '0 10px',
    color: theme.palette.op.match({
      light: darkTheme.palette.op.gray[1],
      dark: lightTheme.palette.op.gray[1],
    }),
  },
  shortcut: {
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
  },
  key: {
    alignItems: 'center',
    background: theme.palette.op.match({
      light: darkTheme.palette.op.hover.darker,
      dark: lightTheme.palette.op.hover.darker,
    }),
    color: 'inherit',
    opacity: 0.8,
    borderRadius: 3,
    display: 'flex',
    fontWeight: 650,
    justifyContent: 'center',
    marginLeft: 4,
    minWidth: 16,
    height: 16,
    padding: '0 4px',
  },
}))

const useTooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.op.match({
      light: darkTheme.palette.op.gray[6],
      dark: lightTheme.palette.op.gray[6],
    }),
    boxShadow: theme.palette.op.shadow.tooltip,
    borderRadius: theme.palette.op.borderRadius.tooltip,
    margin: 8,
    maxWidth: 220,
    padding: 0,
  },
}))
