// @ts-strict-ignore
import { action, flow, makeAutoObservable, remove } from 'mobx'
import Service from '.'
import { chunk, insertAtIndex, removeAtIndex } from '../lib/collections'
import shortId from '../lib/short-id'
import { ById, PageInfo } from '../types'
import { buffer, Contact, ContactSettings, Note, PersistedCollection } from './model'
import { ContactTemplateItem } from './model/contact-template-item'
import { makePersistable } from './storage/persistable'
import {
  ContactRepository,
  ContactSettingsRepository,
  ContactTemplateItemRepository,
} from './worker/repository'
import {
  GooglePeopleSyncProgressState,
  GooglePeopleSyncProgressNotification,
} from './transport/websocket'

export default class ContactStore {
  readonly collection: PersistedCollection<Contact, ContactRepository>
  readonly template: PersistedCollection<
    ContactTemplateItem,
    ContactTemplateItemRepository
  >
  readonly settings: PersistedCollection<ContactSettings, ContactSettingsRepository>

  private byNumber: ById<Set<Contact>> = {}
  private contactNumbers: ById<string[]> = {}

  private pageInfo: PageInfo = null
  private lastFetchedAt: number = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('contact'),
      classConstructor: () => new Contact(root),
    })
    this.template = new PersistedCollection({
      table: this.root.storage.table('contactTemplateItem'),
      classConstructor: () => new ContactTemplateItem(root),
    })
    this.settings = new PersistedCollection({
      table: this.root.storage.table('contactSettings'),
      classConstructor: () => new ContactSettings(root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'ContactStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.handleWebsocket()
    this.handleIndexByPhoneNumber()
  }

  get sortedTemplates() {
    return this.template.list.sort((i1, i2) => {
      if (i1.order == null) return 1
      if (i2.order == null) return -1
      return i1.order - i2.order
    })
  }

  get imports(): { name: string; createdAt: number }[] {
    const map: { [key: string]: { name: string; createdAt: number } } = {}
    this.collection.list.forEach((c) => {
      if (c.source === 'csv') {
        const existing = map[c.sourceName]
        map[c.sourceName] = {
          name: c.sourceName,
          createdAt: existing?.createdAt ?? c.createdAt,
        }
      }
    })
    return Object.values(map)
  }

  get(id: string) {
    return this.collection.get(id)
  }

  getByNumber(number: string) {
    this.loadByNumber(number)
    return this.byNumber[number] ? Array.from(this.byNumber[number]) : []
  }

  loadAll = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  loadByNumbers = (numbers: string[]) => {
    const load = numbers.filter((number) => !this.byNumber[number])
    return this.collection.performQuery((repo) => repo.getByPhoneNumbers(load))
  }

  loadByNumber = buffer(this.loadByNumbers)

  googleSync = (token: string, redirectUri: string = 'postmessage'): Promise<any> => {
    return this.root.transport.contacts.googleSync(token, redirectUri)
  }

  fetchMissing = (): Promise<any> => {
    const self = this
    if (this.pageInfo?.hasNextPage === false && this.lastFetchedAt) {
      return this.root.transport.contacts
        .fetch({ since: new Date(this.lastFetchedAt), includeDeleted: true })
        .then(
          flow(function* (resp) {
            const contacts = yield self.load(resp)
            self.lastFetchedAt = Math.max(
              ...contacts.map((c) => c.updatedAt),
              self.lastFetchedAt + 1,
            )
          }),
        )
    } else if (this.pageInfo?.hasNextPage !== false) {
      return this.root.transport.contacts
        .fetch({ limit: 200, lastId: this.pageInfo?.endId })
        .then(
          flow(function* (resp) {
            const contacts = yield self.load(resp.result)
            self.pageInfo = resp.pageInfo
            self.lastFetchedAt = Math.max(
              ...contacts.map((c) => c.updatedAt),
              self.lastFetchedAt + 1,
            )
          }),
        )
        .then(() => this.fetchMissing())
    }
  }

  async createBulk(contacts: Contact[]) {
    await this.collection.load(contacts)
    return Promise.all(
      chunk(contacts, 100).map((contacts) =>
        this.root.transport.contacts.createBulk(contacts.map((c) => c.toJSON())),
      ),
    )
  }

  update = (contact: Contact) => {
    contact.local = false
    this.collection.put(contact)
    return this.root.transport.contacts.update(contact.toJSON())
  }

  merge = (contact: Contact, withContacts: Contact[]) => {
    // TODO: offline support
    return this.root.transport.contacts.merge(
      contact.id,
      withContacts.map((c) => c.id),
    )
  }

  delete = async (contact: Contact) => {
    this.collection.delete(contact)
    if (!contact.local) {
      return this.root.transport.contacts.delete(contact.id)
    }
  }

  deleteBySource = (source: string, sourceName: string) => {
    const ids = this.collection.list
      .filter((c) => c.source === source && c.sourceName === sourceName)
      .map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteAll = () => {
    const ids = this.collection.list.map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteBulk = (ids: string[]) => {
    if (!ids || ids.length === 0) return
    this.collection.deleteBulk(ids)
    return Promise.all(
      chunk(ids, 500).map((ids) => this.root.transport.contacts.deleteBulk(ids)),
    )
  }

  shareBulk = async (
    contactIds: string[],
    shareIds: string[],
    progress: (progress: number) => void,
  ) => {
    progress(0)
    const batches = chunk(contactIds, 250)
    for (let i = 0; i < batches.length; i++) {
      const ids = batches[i]
      await this.root.transport.contacts.shareBulk(ids, shareIds)
      progress((i + 1) / (batches.length - 1))
    }
  }

  putNote = (note: Note) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.put(note.contact.id, note.toJSON())
  }

  deleteNote = (note: Note) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.deleteNote(note.contact.id, note.id)
  }

  fetchTemplates = () => {
    this.template.performQuery((repo) => repo.all())
    return this.root.transport.contacts.template
      .fetch()
      .then(action((res) => this.template.load(res, { deleteOthers: true })))
  }

  updateTemplate = (template: ContactTemplateItem) => {
    template.local = false
    this.template.put(template)
    return this.root.transport.contacts.template
      .put(template.serialize())
      .then(this.template.load)
  }

  deleteTemplate = async (template: ContactTemplateItem) => {
    this.template.delete(template)
    if (!template.local) {
      return this.root.transport.contacts.template.delete(template.serialize())
    }
  }

  reorderTemplates = (from: number, to: number) => {
    const sorted = [...this.sortedTemplates]
    const item = sorted[from]
    insertAtIndex(removeAtIndex(sorted, from), item, to).map((t, order) => {
      t.update({ order })
    })
  }

  fetchSettings = () => {
    this.settings.performQuery((repo) => repo.all())
    return this.root.transport.contacts.settings
      .fetch()
      .then((res) => this.settings.load(res, { deleteOthers: true }))
  }

  deleteSettings = (id: string) => {
    this.settings.delete(id)
    return this.root.transport.contacts.settings.delete(id)
  }

  private handleWebsocket() {
    this.root.transport.onMessage.subscribe((msg: any) => {
      switch (msg.type) {
        case 'contact-update':
        case 'contact-note-update':
        case 'contact-note-delete':
          return this.collection.load(msg.contact)

        case 'contact-delete':
          return this.collection.delete(msg.contact.id)

        case 'bulk-operation-complete': {
          if (msg.collection === 'contacts') {
            this.fetchMissing()
          }
          return
        }

        case 'template-update':
          return this.template.load(msg.template)
        case 'template-delete':
          return this.template.delete(msg.template.id)

        case 'google-people-sync-progress':
          return this.handleGooglePeopleSyncProgressNotification(msg)
      }
    })
  }

  private handleGooglePeopleSyncProgressNotification(
    msg: GooglePeopleSyncProgressNotification,
  ) {
    const settings = this.settings.list.find((s) => s.source === msg.status.source)
    if (!settings) return
    settings.resyncStatus = msg.status.state
  }

  /**
   * As contacts are added/updated/deleted from the collection, this
   * function keeps map of phone numbers to contacts for fast lookup
   */
  private handleIndexByPhoneNumber() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((contact) => {
            this.contactNumbers[contact.id] ??= []
            const numbers = this.contactNumbers[contact.id]
            numbers.forEach((number) => {
              this.byNumber[number]?.delete(contact)
            })
            contact.phoneNumbers.forEach((item) => {
              const number = item.value
              this.byNumber[number] ??= new Set()
              if (!this.byNumber[number].has(contact)) {
                this.byNumber[number].add(contact)
              }
            })
            this.contactNumbers[contact.id] = contact.phoneNumbers.map((i) => i.value)
          })
        } else if (event.type == 'delete') {
          event.objects.forEach((object) => {
            const numbers = this.contactNumbers[object.id]
            numbers?.forEach((number) => {
              if (!this.byNumber[number]) return
              const existing = Array.from(this.byNumber[number]).find(
                (c) => c.id === object.id,
              )
              this.byNumber[number].delete(existing)
            })
            remove(this.contactNumbers, object.id)
          })
        }
      }),
    )
  }

  load(contacts: any) {
    if (!contacts) return
    this.collection.deleteBulk(contacts.filter((c) => c.deletedAt))
    return this.collection.load(contacts.filter((c) => !c.deletedAt))
  }
}
