// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { isValidEmail } from '@openphone/lib'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button'
import IconButton from '../../../component/icon-button'
import { EmailIcon, SlackIcon } from '../../../component/icons/custom'
import { MoreIcon } from '../../../component/icons/Tint/20/General'
import Menu, { MenuItem } from '../../../component/popover-menu'
import Switch from '../../../component/switch'
import TextareaAutoresize from '../../../component/textarea-autoresize'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'
import { LegacyIntegration } from '../../../service/model'
import { IntegrationEvent } from '../../../types'

interface IntegrationRowProps {
  integration?: LegacyIntegration
}

const IntegrationRow: React.FC<IntegrationRowProps> = function ({ integration }) {
  const styles = useStyles({})
  const { showAlert } = useAppStore()
  const [editEmails, setEditEmails] = useState(false)
  const [emails, setEmails] = useInputState('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null)

  const events = {
    incomingMessage: {
      name: 'Messages',
      desc: `Send incoming messages to my ${
        integration.type === 'email' ? 'email' : 'channel'
      }`,
      enabled: integration?.events.incomingMessage,
    },
    missedCallWithoutVoicemail: {
      name: 'Missed calls',
      desc: `Send missed calls to my ${
        integration.type === 'email' ? 'email' : 'channel'
      }`,
      enabled: integration?.events.missedCallWithoutVoicemail,
    },
    missedCallWithVoicemail: {
      name: 'Voicemails',
      desc: 'Send voicemails along with their transcription',
      enabled: integration?.events.missedCallWithVoicemail,
    },
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    setAnchorEl(null)
    showAlert({
      title: integration.type === 'slack' ? 'Delete Slack' : 'Delete Email',
      body:
        integration.type === 'slack'
          ? 'You are about to delete your Slack integration. Are you sure?'
          : 'You are about to delete your email integration. Are you sure?',
      actions: [
        {
          type: 'destructive',
          title: 'Yes, delete',
          onClick: () => integration.delete(),
        },
        {
          title: 'No, cancel',
        },
      ],
    })
  }

  const toggleEvent = (key: keyof IntegrationEvent) => {
    integration.update({
      events: { ...integration.events, [key]: !integration.events[key] },
    })
  }

  const handleEditEmails = () => {
    if (integration.type !== 'email') return
    setEmails(integration.to.join(', '))
    setEditEmails(true)
    setAnchorEl(null)
  }

  const handleEmailSave = () => {
    const emailAddresses = emails
      .split(',')
      .map((e) => e?.trim())
      .filter((e) => e)

    const invalidEmail = emailAddresses.find((e) => !isValidEmail(e))
    if (invalidEmail) {
      alert({
        title: 'Invalid email',
        body: `The email address "${invalidEmail}" does not look correct.`,
      })
    } else {
      integration.update({ to: emailAddresses })
      setEditEmails(false)
    }
  }

  const renderEditEmail = () => {
    return (
      <div style={{ width: '100%' }}>
        <Typography
          component="label"
          variant="caption1"
          color="textSecondary"
          fontWeight="regular"
          htmlFor="edit-emails"
          style={{ marginBottom: 10, display: 'block' }}
        >
          You can enter multiple comma separated email addresses
        </Typography>
        <TextareaAutoresize
          id="edit-emails"
          autoFocus
          value={emails}
          onChange={setEmails}
          style={{ width: '100%' }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: '10px 0',
          }}
        >
          <Button
            size="small"
            variant="text"
            color="default"
            style={{ marginRight: 15 }}
            onClick={() => setEditEmails(false)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleEmailSave}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {editEmails ? (
          renderEditEmail()
        ) : (
          <>
            {integration.type === 'email' ? (
              <EmailIcon className={styles.icon} />
            ) : (
              <SlackIcon className={styles.icon} />
            )}
            <div className={styles.body}>
              {integration.type === 'email' ? (
                <Typography variant="body">
                  Sending to {integration.to.join(', ')}
                </Typography>
              ) : (
                <Typography variant="body">
                  Connected to <b>{integration.channel}</b>
                </Typography>
              )}
            </div>
            <IconButton size="medium" icon={<MoreIcon />} onClick={handleMenuClick} />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {integration.type === 'email' && (
                <MenuItem onClick={handleEditEmails}>Edit email</MenuItem>
              )}
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </>
        )}
      </div>
      <div className={styles.events}>
        {Object.keys(events).map((key) => (
          <div className={styles.event} key={key}>
            <label htmlFor={`${key}-switch`} className={styles.body}>
              <Typography variant="body">{events[key].name}</Typography>
              <Typography
                color="textSecondary"
                variant="caption1"
                fontWeight="regular"
                style={{ marginTop: 3 }}
              >
                {events[key].desc}
              </Typography>
            </label>
            <Switch
              id={`${key}-switch`}
              size="small"
              color="primary"
              checked={integration.events[key]}
              onChange={() => toggleEvent(key as any)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(IntegrationRow)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 16,
    padding: 20,
    borderRadius: 9,
    background: theme.palette.op.hover.primary,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 16,
    fill: theme.palette.primary.light,
  },
  body: {
    flex: 1,
  },
  events: {
    marginTop: 10,
  },
  event: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
    justifyContent: 'space-between',
  },
}))
