import config from '../../config'
import { AuthenticationError } from '../../lib/api/error-handler'

const googleAuth = new Promise<any>((resolve) => {
  const init = () => {
    const gapi = (window as any).gapi
    if (!gapi) {
      setTimeout(init, 200)
      return
    }
    gapi.load('auth2', () => resolve(gapi.auth2))
  }
  init()
})

export const googleLogin = (): Promise<{ accessToken: string; code: string }> => {
  return googleAuth
    .then(
      (auth) =>
        new Promise<any>((resolve, reject) =>
          auth.authorize(
            {
              client_id: config.GOOGLE_CLIENT_ID,
              scope: 'profile https://www.googleapis.com/auth/userinfo.email ',
              response_type: 'code permission',
              prompt: 'select_account consent',
            },
            function (response) {
              if (response.error) {
                // An error happened!
                reject(response.error)
                return
              }
              // The user authorized the application for the scopes requested.
              var accessToken = response.access_token
              var code = response.code
              resolve({ accessToken, code })
              // You can also now use gapi.client to perform authenticated requests.
            },
          ),
        ),
    )
    .catch((e) => {
      if (e !== 'popup_closed_by_user') {
        throw e
      }
    })
}

export const redirectGoogleLogin = () => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/native/login`,
    response_type: 'token',
    scope: 'email profile',
    prompt: 'select_account',
    include_granted_scopes: 'false',
  }

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}

export const redirectGoogleContactLogin = () => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/contacts`,
    response_type: 'code',
    scope:
      'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly',
    prompt: 'select_account consent',
    include_granted_scopes: 'false',
    access_type: 'offline',
  }

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}
