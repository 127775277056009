// @ts-strict
import { useAppStore } from '@src/app/context'
import { useEffect, useState } from 'react'

const LOCAL_STORAGE_PARTNER_STACK_KEY = 'PartnerStack.key'

export function useStorePartnerStackKey() {
  const { history } = useAppStore()

  useEffect(() => {
    const partnerStackKey = history.consumeQueryParam('gspk')

    if (!partnerStackKey) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_PARTNER_STACK_KEY, partnerStackKey)
  }, [history.consumeQueryParam])
}

export function useRestorePartnerStackKey() {
  const [partnerStackKey] = useState<string | null>(() =>
    localStorage.getItem(LOCAL_STORAGE_PARTNER_STACK_KEY),
  )

  function clear() {
    localStorage.removeItem(LOCAL_STORAGE_PARTNER_STACK_KEY)
  }

  return [partnerStackKey, clear] as const
}
