// @ts-strict-ignore
import axios, { AxiosRequestConfig, Canceler } from 'axios'
import Backoff from 'backoff'
import { Session } from '../../types'
import { EventEmitter } from 'events'
import errorHandler from './error-handler'
import config from '../../config'
import log from '../../lib/log'

declare interface ApiClient {
  on(event: 'session_updated', listener: (session: Session) => void): this
  on(event: 'session_invalid', listener: (error: Error) => void): this
}

export type ClientState = 'authenticated' | 'reauthenticating' | 'unauthenticated'

export type RequestConfig = Omit<AxiosRequestConfig, 'cancelToken'>
export type RequestPromise<T> = Promise<T> & { cancel: Canceler }

export const CancelReason = {
  ComponentUnmount: 'component unmount',
}

class ApiClient extends EventEmitter {
  private readonly backoff = Backoff.exponential({
    factor: 2.0,
    initialDelay: 100,
    maxDelay: 60000,
    randomisationFactor: 0.4,
  })
  private isAlreadyFetchingAccessToken = false
  private requests: ((error?: any) => void)[] = []
  private request = axios.create({
    timeout: 20000,
  })
  private state: ClientState

  session: Session

  constructor(session: Session = null) {
    super()

    this.session = session
    this.state = session ? 'authenticated' : 'unauthenticated'

    // Called when a backoff timer is started.
    this.backoff.on('backoff', (_: any, delay: number) => {
      if (this.state === 'reauthenticating') {
        log.info(`Will attempt to refresh the token in ${delay}ms`)
      }
    })

    // Called when a backoff timer ends. We want to try to refresh the token
    // at this point
    this.backoff.on('ready', (attempt: number) => {
      if (this.state === 'reauthenticating') {
        log.info(`Refreshing the login token. Attempt #${attempt}.`)
        this.refreshSession()
      }
    })

    /**
     * Add the authorization header to every request if the url is an
     * OpenPhone service.
     */
    this.request.interceptors.request.use((requestConfig) => {
      const accessToken = this.session?.accessToken
      const requestUrl = new URL(requestConfig.url)
      const isOpenPhoneService =
        !!requestUrl.host.match(/openphone|localhost/) && !requestUrl.host.match(/auth0/)
      if (accessToken && isOpenPhoneService) {
        requestConfig.headers.Authorization = accessToken
      }
      if (isOpenPhoneService) {
        requestConfig.headers['x-op-device'] = 'web'
        requestConfig.headers['x-op-device-id'] = ''
        requestConfig.headers['x-op-app'] = 'OpenPhone'
        requestConfig.headers['x-op-version'] = config.VERSION
      }
      return requestConfig
    })

    /**
     * Refresh accessToken if a request fails due to an
     * expired JWT token.
     */
    this.request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          this.session?.refreshToken &&
          error.response &&
          error.response.status === 401 &&
          error.response.config.url.startsWith(config.AUTH_SERVICE_URL) === false
        ) {
          /**
           * Create a Promise that will be returned by this interceptor
           * that will add the retry request to the request queue once it's
           * executed. These callbacks will eventually be called once the
           * call to refresh the JWT token completes.
           */
          const retryCallback = new Promise((resolve, reject) => {
            this.requests.push((err) => {
              if (err) {
                return reject(err)
              }
              this.request(error.response.config).then(resolve).catch(reject)
            })
          })

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshSession()
              .then(() => {
                this.requests.forEach((r) => r())
                this.requests = []
              })
              .catch(() => {
                this.requests.forEach((r) => r(error))
                this.requests = []
              })
              .finally(() => {
                this.isAlreadyFetchingAccessToken = false
              })
          }
          return retryCallback
        }
        return Promise.reject(error)
      },
    )
  }

  refreshSession(refreshToken?: string): Promise<Session> {
    this.state = 'reauthenticating'
    refreshToken = refreshToken ?? this.session?.refreshToken

    if (!refreshToken) {
      const error = new Error('No refresh token found')
      this.resetSession(error)
      return Promise.reject(error)
    }

    return this.request
      .post(`${config.AUTH_SERVICE_URL}refresh`, { refreshToken })
      .then((response) => {
        this.setSession({
          accessToken: response.data.id_token,
          refreshToken: response.data.refresh_token,
          expiresAt: Date.now() + response.data.expires_in * 1000,
        })
        return this.session
      })
      .catch((error) => {
        const jsonError = log.toJsonString(error)
        log.debug(`refresh token called failed due to ${jsonError}`)
        log.debug(`resposne status: ${error.response?.status}`)
        log.debug(`status: ${error.status}`)
        if (error.response?.status === 401 || error.response?.status === 429) {
          this.resetSession(error)
        } else {
          this.backoff.backoff()
        }
        throw error
      })
  }

  private setSession(session: Session) {
    this.session = session
    this.state = 'authenticated'
    this.backoff.reset()
    this.emit('session_updated', this.session)
  }

  private resetSession(error: any) {
    this.session = null
    this.state = 'unauthenticated'
    this.backoff.reset()
    this.emit('session_invalid', error)
  }

  perform<T>(config: RequestConfig): RequestPromise<T> {
    const source = axios.CancelToken.source()
    const p: any = this.request({ ...config, cancelToken: source.token })
      .then((response) => response.data)
      .catch(errorHandler)
    p.cancel = source.cancel
    return p
  }

  get<T = any>(url: string, options?: RequestConfig): RequestPromise<T> {
    return this.perform({
      url,
      method: 'GET',
      ...options,
    })
  }

  post<T = any>(url: string, data?: any, options?: RequestConfig): RequestPromise<T> {
    return this.perform({
      url,
      method: 'POST',
      data,
      ...options,
    })
  }

  put<T = any>(url: string, data?: any, options?: RequestConfig): RequestPromise<T> {
    return this.perform({
      url,
      method: 'PUT',
      data,
      ...options,
    })
  }

  patch<T = any>(url: string, data?: any, options?: RequestConfig): RequestPromise<T> {
    return this.perform({
      url,
      method: 'PATCH',
      data,
      ...options,
    })
  }

  delete<T = any>(url: string, options?: RequestConfig): RequestPromise<T> {
    return this.perform({
      url,
      method: 'DELETE',
      ...options,
    })
  }
}

export default ApiClient
