// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import { compareByName, Contact } from '.'
import { parseDate } from '../../lib'

type SourceType = { type: string; name?: string }

export class ContactView {
  type = 'contact'
  sort: 'alphabetical' | 'created' = 'alphabetical'
  sortDirection: 'asc' | 'desc' = 'asc'
  company?: string[] = []
  role?: string[] = []
  source?: SourceType[] = []
  items?: { [key: string]: any[] }

  createdAt = Date.now()
  updatedAt = Date.now()

  constructor(attrs?: Partial<ContactView>) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  filter = (contact: Contact): boolean => {
    return (
      this.matchCompany(contact) &&
      this.matchRole(contact) &&
      this.matchSource(contact) &&
      this.matchItems(contact)
    )
  }

  compare = (c1: Contact, c2: Contact): number => {
    if (this.sort === 'alphabetical') {
      return compareByName(c1, c2)
    } else {
      return c1.createdAt - c2.createdAt
    }
  }

  hasSource = (source: SourceType): boolean => {
    return Boolean(
      this.source?.find((f) => f.type == source.type && f.name == source.name),
    )
  }

  toggleSource = (source: SourceType) => {
    let filter = this.source?.find((f) => f.type == source.type && f.name == source.name)
    if (filter) {
      this.source = this.source.filter((s) => s !== filter)
    } else {
      this.source ??= []
      this.source = [...this.source, source]
    }
  }

  deserialize(json) {
    if (!json) return this
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = () => {
    return {
      type: this.type,
      company: this.company,
      role: this.role,
      source: this.source,
      items: toJS(this.items),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  reset() {
    this.company = null
    this.role = null
    this.source = null
    this.items = null
  }

  private matchCompany(contact: Contact) {
    if (!this.company || this.company.length === 0) {
      return true
    }
    return this.company.includes(contact.company)
  }

  private matchRole(contact: Contact) {
    if (!this.role || this.role.length === 0) {
      return true
    }
    return this.role.includes(contact.role)
  }

  private matchSource(contact: Contact) {
    if (!this.source || this.source.length === 0) {
      return true
    }
    return this.source.some((sourceFilter) => {
      /*
       * The phone numbers that are coming from OpenPhone have `source` field equals to `null`
       * Same logic drives the filters under `web/src/app/command/contact-filters/sources.tsx`
       */
      if (sourceFilter.type === 'op' && !contact.source) {
        return true
      }
      if (sourceFilter.name) {
        return (
          sourceFilter.name === contact.sourceName && sourceFilter.type === contact.source
        )
      }
      return sourceFilter.type === contact.source
    })
  }

  private matchItems(contact: Contact) {
    if (!this.items || Object.keys(this.items).length === 0) {
      return true
    }
    return Object.keys(this.items).every((templateId) => {
      const possibleValues = this.items[templateId]
      const item = contact.items.find((i) => i.template.id === templateId)
      if (Array.isArray(item.value)) {
        return item.value.some((value) => possibleValues.includes(value))
      } else if (item.value) {
        return possibleValues.includes(item.value)
      }
    })
  }
}
