import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import { Command } from './common-v2'
import Content from './common/content'
import FileSelector from './common/file-selector'
import Header from './common/header'

export interface UploadCommandProps {
  accept: string[]
  title: string
  description: string
  onComplete: (url: string) => void
}

const UploadCommand: React.FC<UploadCommandProps> = function ({
  accept,
  title,
  description,
  onComplete,
}) {
  const { service } = useAppStore()
  const [uploadState, runUpload] = useStatefulPromise((file: File) =>
    service.transport.communication.upload(file),
  )

  useEffect(() => {
    if (!uploadState.data) return
    onComplete(uploadState.data)
  }, [uploadState.data])

  return (
    <Command>
      <Header title={title} />
      <Content>
        <FileSelector
          accept={accept}
          placeholder={description}
          loading={uploadState.status === PromiseStateStatus.Loading}
          errorMessage={uploadState.error?.message}
          onComplete={runUpload}
        />
      </Content>
    </Command>
  )
}

export default UploadCommand
