// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import IconButton from '../icon-button'

export interface SegmentControlProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: React.ReactNode
  title?: string

  // TODO: hide somehow?
  selected?: boolean
}

const SegmentControl: React.FC<SegmentControlProps> = React.forwardRef(function (
  { icon, title, selected, ...props },
  ref,
) {
  const styles = useStyles({})

  return (
    <IconButton
      ref={ref}
      tabIndex={-1}
      className={styles.root}
      color={selected ? 'primary' : null}
      icon={icon}
      title={title}
      tooltipPlacement="top"
      {...props}
    />
  )
})

export default SegmentControl

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&::before': {
        opacity: 0,
      },

      '&:hover, &:focus': {
        '&::before': {
          opacity: 0,
        },
      },
    },
  }),
  { name: SegmentControl.name },
)
