// @ts-strict-ignore
/// <reference path="./declarations.d.ts" />

import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import { fromEvent } from 'rxjs'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Descendant } from 'slate'
import { Editable, RenderElementProps, Slate } from 'slate-react'
import { useObservable } from '../../lib/hooks'
import Controller from './controller'
import DefaultElement, { MentionElement } from './elements'

export interface EditorProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLDivElement>,
    | 'value'
    | 'defaultValue'
    | 'placeholder'
    | 'onChange'
    | 'onKeyDown'
    | 'onKeyDownCapture'
    | 'onKeyPress'
    | 'onKeyPressCapture'
    | 'onKeyUp'
    | 'onKeyUpCapture'
  > {
  controller: Controller
  placeholder?: string
  color?: string
  renderMention?: (mention: string) => React.ReactNode
}

const Editor: React.FC<EditorProps> = function ({
  controller,
  placeholder,
  color,
  renderMention,
  ...props
}) {
  const styles = useStyles({ color })
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(
    action(() => {
      // TODO: https://github.com/ianstormtaylor/slate/issues/4082
      const el = containerRef.current.querySelector('[contenteditable]') as HTMLElement
      controller.ref.current = el
      controller.focused = document.activeElement === el
    }),
    [controller],
  )

  useObservable(
    () => fromEvent(controller.ref.current, 'focus'),
    controller.handleFocus,
    [controller],
  )

  useObservable(() => fromEvent(controller.ref.current, 'blur'), controller.handleBlur, [
    controller,
  ])

  // TODO: https://github.com/ianstormtaylor/slate/issues/4220
  const renderPlaceholder = () => {
    return placeholder && controller.isEmpty ? (
      <span className={cx(styles.placeholder, props.className)}>{placeholder}</span>
    ) : null
  }

  const renderElement = useCallback(
    ({ element, ...props }: RenderElementProps): React.ReactElement => {
      switch (element.type) {
        case 'mention':
          return (
            <MentionElement element={element} renderMention={renderMention} {...props} />
          )
        default:
          return <DefaultElement element={element} {...props} />
      }
    },
    [renderMention],
  )

  return (
    <div ref={containerRef} className={styles.root}>
      {renderPlaceholder()}
      <Slate
        editor={controller.editor}
        value={controller.value as Descendant[]}
        onChange={controller.handleChange}
      >
        <Editable
          {...props}
          className={cx(props.className, styles.editor)}
          data-allow-keyboard-when-empty
          onKeyDown={controller.handleKeyDown}
          renderElement={renderElement}
        />
      </Slate>
    </div>
  )
}

export default observer(Editor)

const useStyles = makeStyles<Theme, Partial<EditorProps>>(
  (theme) => ({
    root: {
      position: 'relative',
    },
    editor: ({ color }) => ({
      fontSize: 15,
      lineHeight: '20px',
      caretColor: color ?? theme.palette.primary.light,
    }),
    placeholder: {
      position: 'absolute',
      opacity: 0.4,
      transition: theme.transitions.create(['opacity'], {
        easing: 'ease',
        duration: 240,
      }),
    },
  }),
  { name: Editor.name },
)
