import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import { SearchIcon } from '../../../component/icons/Tint/20/General'
import { fuzzySearch } from '../../../lib/search'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Command, Content, EmptyListMessage, IconItem, InputHeader } from '../common-v2'
import { FilterType } from './'

interface FiltersProps {
  setFilterType: (type: FilterType) => void
}

const items: { type: FilterType; title: string }[] = [
  { type: 'source', title: 'Filter by source' },
  { type: 'company', title: 'Filter by company' },
]

const Filters: React.FC<FiltersProps> = function ({ setFilterType }) {
  const styles = useStyles({})
  const [search, setSearch] = useInputState('')

  const filteredItems = useMemo(() => {
    if (!search) return items
    return fuzzySearch(items, search, ['title'])
  }, [search])

  const { selectedIndex, getItemProps } = useKeyStepper({
    items: filteredItems,
    name: 'command/contact-filters/filters',
    handleSelect: (item) => {
      setFilterType(item.type)
    },
  })

  return (
    <Command>
      <InputHeader
        autoFocus
        value={search}
        onChange={setSearch}
        placeholder="Search for a filter..."
      />
      <Content>
        {filteredItems.map((item, index) => (
          <IconItem
            key={item.type}
            icon={<SearchIcon />}
            selected={index === selectedIndex}
            {...getItemProps(index)}
          >
            {item.title}
          </IconItem>
        ))}
        {filteredItems.length === 0 && (
          <EmptyListMessage text="Nothing found matching your search query." />
        )}
      </Content>
    </Command>
  )
}

export default observer(Filters)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
