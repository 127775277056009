import { makeStyles, Theme } from '@material-ui/core'
import cx from 'classnames'
import useTransition from '../../lib/use-transition'
import React, { useMemo, useRef } from 'react'

const duration = 200

export interface CollapseProps {
  collapsed: boolean
}

export const Collapse: React.FC<CollapseProps> = function ({ collapsed, children }) {
  const styles = useStyles({})
  const state = useTransition(!collapsed, duration)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const style = useMemo(
    () => ({
      height:
        state === 'entering' || state === 'exit'
          ? wrapperRef.current?.clientHeight
          : undefined,
    }),
    [state],
  )

  return (
    <div className={cx(styles.root, styles[state])} style={style}>
      <div ref={wrapperRef} className={styles.wrapper}>
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  )
}

export default Collapse

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      transition: theme.transitions.create(['height'], { duration, easing: 'ease' }),
      overflow: 'hidden',
      height: 0,
    },
    enter: {},
    entering: {},
    entered: {
      overflow: 'visible',
      height: 'auto',
    },
    exit: {},
    exiting: {},
    exited: {
      visibility: 'hidden',
    },
    wrapper: {
      display: 'flex',
    },
    inner: {
      width: '100%',
    },
  }),
  { name: Collapse.name },
)
