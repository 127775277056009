// @ts-strict-ignore
import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Header from '../common/header'
import Schedule from './schedule'
import Days from './days'
import { AvailabilitySchedule } from '../../../types'
import { Command } from '../common-v2'

export interface SetScheduleProps {
  defaultValue?: AvailabilitySchedule
  defaultDay?: number
  onSave: (schedule: AvailabilitySchedule, days: boolean[]) => void
}

const SetSchedule: React.FC<SetScheduleProps> = function ({
  defaultValue,
  defaultDay,
  onSave,
}) {
  const styles = useStyles({})
  const [state, setState] = useState<'schedule' | 'days'>('schedule')
  const [time, setTime] = useState<AvailabilitySchedule>(defaultValue)

  const handleTimeSet = (schedule: AvailabilitySchedule) => {
    setTime(schedule)
    setState('days')
  }

  const handleDaysSet = (days: boolean[]) => {
    onSave(time, days)
  }

  return (
    <Command>
      <Header title="Set the schedule" subtitle="Choose your operating hours" />
      {state === 'schedule' ? (
        <Schedule defaultValue={defaultValue} onSelect={handleTimeSet} />
      ) : (
        <Days defaultDay={defaultDay} onSave={handleDaysSet} />
      )}
    </Command>
  )
}

export default SetSchedule

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
