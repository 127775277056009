import React from 'react'
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { useTheme, withStyles } from '@material-ui/core/styles'

interface CheckboxIconProps {
  checkedState?: 'checked' | 'indeterminate' | 'unchecked'
}

const CheckboxIcon: React.FC<CheckboxIconProps> = function ({ checkedState }) {
  const theme = useTheme()
  return checkedState === 'checked' ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        fill={theme.palette.op.secondary.green2}
        fillRule="evenodd"
        d="M13,0 C14.6568542,-3.04359188e-16 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 2.02906125e-16,14.6568542 0,13 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L13,0 Z M12.5827133,4 L6.54357115,10.2387152 L3.24012589,7.5364605 L2,9.1566765 L6.00175077,12.4294226 L6.6999729,13 L14,5.45873182 L12.5827133,4 Z"
      />
    </svg>
  ) : checkedState === 'indeterminate' ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        fill={theme.palette.op.secondary.green2}
        fillRule="evenodd"
        d="M13,0 C14.6568542,-3.04359188e-16 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 2.02906125e-16,14.6568542 0,13 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L13,0 Z M13,7 L3,7 L3,9 L13,9 L13,7 Z"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect
        width="15"
        height="15"
        x=".5"
        y=".5"
        fill={theme.palette.op.gray[1]}
        fillOpacity=".1"
        fillRule="evenodd"
        stroke={theme.palette.op.gray[1]}
        strokeOpacity=".2"
        rx="3"
      />
    </svg>
  )
}

const Checkbox = withStyles((theme) => ({
  colorSecondary: {
    '&$checked': {
      color: theme.palette.op.secondary.green2,
    },
    '&:hover': {
      backgroundColor: theme.palette.op.background.highlight(0.05),
    },
  },
  checked: {},
}))((props: CheckboxProps) => (
  <MuiCheckbox
    indeterminateIcon={<CheckboxIcon checkedState="indeterminate" />}
    checkedIcon={<CheckboxIcon checkedState="checked" />}
    icon={<CheckboxIcon />}
    {...props}
  />
))

export default Checkbox
