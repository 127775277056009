// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { AppAvatar } from '@src/app/components'
import React, { MutableRefObject } from 'react'
import { highlight } from '../../../lib/string'
import { Identity } from '../../../service/model'
import CommandItem from '../common/item'

interface RowProps extends React.HTMLProps<HTMLDivElement> {
  identity: Identity
  secondaryLabel?: string
  highlighted?: boolean
  innerRef?: MutableRefObject<HTMLDivElement>
  query?: string
}

const Row: React.FC<RowProps> = function ({
  identity,
  secondaryLabel,
  highlighted,
  innerRef,
  query,
  ...props
}) {
  const styles = useStyles({})

  return (
    <CommandItem
      {...props}
      className={styles.root}
      innerRef={innerRef}
      highlighted={highlighted}
    >
      <div className={styles.name}>
        <AppAvatar identity={identity} className={styles.rightMargin} />
        <Typography
          variant="body2"
          color="textPrimary"
          className={styles.rightMargin}
          dangerouslySetInnerHTML={{ __html: highlight(identity.name, query) }}
        />
      </div>
      {secondaryLabel && (
        <Typography
          variant="caption"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: highlight(secondaryLabel, query) }}
        />
      )}
    </CommandItem>
  )
}

export default Row

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'space-between',
    minHeight: 50,
  },
  name: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  rightMargin: {
    marginRight: 15,
  },
}))
