import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { PhoneNumber } from '../../../service/model'
import Typography from '@ui/Typography'
interface CallHeaderProps {
  phoneNumber: PhoneNumber
}

const CallHeader: React.FC<CallHeaderProps> = function ({ phoneNumber }) {
  const styles = useStyles({})
  return (
    <div className={styles.root}>
      <Typography variant="callout" className={styles.symbol}>
        {phoneNumber?.symbol}
      </Typography>
      <Typography nowrap variant="caption1" fontWeight="regular">
        {phoneNumber?.name}
      </Typography>
    </div>
  )
}

export default observer(CallHeader)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 12,
      padding: '9px 35px 9px 14px',
      boxShadow: `0 1.5px 0 0 ${theme.palette.op.border.common}`,
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
    },
    symbol: {
      marginRight: 6,
    },
  }),
  { name: CallHeader.name },
)
