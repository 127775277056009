import { EventTypes } from '@src/data/EventTypes'

export const ContactEventTypes = [
  {
    name: EventTypes['contact.updated'],
    description: 'Occurs whenever contact details are updated.',
    payloadSample: {
      id: 'EVe844e47e9fa4494d9acfa1144839ed94',
      object: 'event',
      createdAt: '2022-01-24T19:44:09.579Z',
      apiVersion: 'v2',
      type: 'contact.updated',
      data: {
        object: {
          id: 'CT61eeff33f3b14cfe6358cb52',
          object: 'contact',
          firstName: 'Jane',
          lastName: 'Smith',
          company: 'Comp Inc',
          role: 'Agent',
          pictureUrl: null,
          fields: [
            {
              name: 'Phone',
              type: 'phone-number',
              value: '+18005550100',
            },
            {
              name: 'Email',
              type: 'email',
              value: null,
            },
            {
              name: 'Prop1',
              type: 'string',
              value: 'Value12',
            },
          ],
          notes: [
            {
              text: '@USu5AsEHuQ mynote 🙂',
              enrichment: {
                taggedIds: {
                  groupIds: [],
                  userIds: ['USu5AsEHuQ'],
                  orgIds: [],
                },
                tokens: {
                  USu5AsEHuQ: {
                    token: 'USu5AsEHuQ',
                    replacement: 'Chris Scott',
                    type: 'mention',
                    locations: [
                      {
                        startIndex: 1,
                        endIndex: 11,
                      },
                    ],
                  },
                },
              },
              createdAt: '2022-01-24T19:35:38.323Z',
              updatedAt: '2022-01-24T19:35:38.323Z',
              userId: 'USu5AsEHuQ',
            },
          ],
          sharedWith: ['USu5AsEHuQ'],
          createdAt: '2022-01-24T19:35:38.318Z',
          updatedAt: '2022-01-24T19:44:09.565Z',
          userId: 'USu5AsEHuQ',
        },
      },
    },
  },
  {
    name: EventTypes['contact.deleted'],
    description: 'Occurs whenever contact is deleted.',
    payloadSample: {
      id: 'EVe844e47e9fa4494d9acfa1144839ed94',
      object: 'event',
      createdAt: '2022-01-24T19:44:09.579Z',
      apiVersion: 'v2',
      type: 'contact.deleted',
      data: {
        object: {
          id: 'CT61eeff33f3b14cfe6358cb52',
          object: 'contact',
          firstName: 'Jane',
          lastName: 'Smith',
          company: 'Comp Inc',
          role: 'Agent',
          pictureUrl: null,
          fields: [
            {
              name: 'Phone',
              type: 'phone-number',
              value: '+18005550100',
            },
            {
              name: 'Email',
              type: 'email',
              value: null,
            },
            {
              name: 'Prop1',
              type: 'string',
              value: 'Value12',
            },
          ],
          notes: [
            {
              text: '@USu5AsEHuQ mynote 🙂',
              enrichment: {
                taggedIds: {
                  groupIds: [],
                  userIds: ['USu5AsEHuQ'],
                  orgIds: [],
                },
                tokens: {
                  USu5AsEHuQ: {
                    token: 'USu5AsEHuQ',
                    replacement: 'Chris Scott',
                    type: 'mention',
                    locations: [
                      {
                        startIndex: 1,
                        endIndex: 11,
                      },
                    ],
                  },
                },
              },
              createdAt: '2022-01-24T19:35:38.323Z',
              updatedAt: '2022-01-24T19:35:38.323Z',
              userId: 'USu5AsEHuQ',
            },
          ],
          sharedWith: ['USu5AsEHuQ'],
          createdAt: '2022-01-24T19:35:38.318Z',
          updatedAt: '2022-01-24T19:44:09.565Z',
          userId: 'USu5AsEHuQ',
        },
      },
    },
  },
]
