import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Identity, PhoneNumber } from '../../../service/model'
import Typography from '@ui/Typography'
import { observer } from 'mobx-react-lite'
import { AppAvatar } from '@src/app/components'

interface InboxTagProps extends React.HTMLProps<HTMLDivElement> {
  phoneNumber: PhoneNumber
}

export const InboxTag = observer<InboxTagProps, HTMLDivElement>(
  function ({ phoneNumber, className, onClick, ...props }, ref) {
    const styles = useStyles({})
    return (
      <div
        {...props}
        ref={ref}
        onClick={onClick}
        className={cx(styles.root, onClick && styles.clickable, className)}
      >
        <Typography variant="footnote" className={styles.emoji}>
          {phoneNumber.symbol}
        </Typography>
        <Typography variant="footnote" color="inherit">
          in: {phoneNumber.name}
        </Typography>
      </div>
    )
  },
  { forwardRef: true },
)

interface FromTagProps extends React.HTMLProps<HTMLDivElement> {
  identity: Identity
}

export const FromTag = observer<FromTagProps, HTMLDivElement>(
  function ({ identity, className, onClick, ...props }, ref) {
    const styles = useStyles({})
    return (
      <div
        {...props}
        ref={ref}
        onClick={onClick}
        className={cx(styles.root, onClick && styles.clickable, className)}
      >
        <AppAvatar identity={identity} size={16} className={styles.emoji} />
        <Typography variant="footnote" color="inherit">
          from: {identity.name}
        </Typography>
      </div>
    )
  },
  { forwardRef: true },
)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.op.tag.purple.bg,
    color: theme.palette.op.tag.purple.text,
    padding: '0 8px',
    borderRadius: 5,
    height: 28,
    width: 'fit-content',
    flex: '0 0 auto',
  },
  clickable: {
    cursor: 'pointer',
    transition: theme.transitions.create(['background'], { duration: 200 }),

    '&:hover': {
      background: theme.palette.op.tag.purple.bgHover,
    },
  },
  emoji: {
    marginRight: 7,
  },
}))
