import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import { AvatarSize, getDimension } from './avatar'
import { computeRelativeFontSize } from './utils'

interface GroupAvatarProps {
  group: { symbol: string }
  size?: AvatarSize
}

const GroupAvatar: React.FC<GroupAvatarProps> = function ({ group, size = 'small' }) {
  const styles = useStyles({})
  const dimension = getDimension(size)
  const fontSize = computeRelativeFontSize(dimension)

  return (
    <div className={styles.root} style={{ width: dimension, height: dimension }}>
      <Typography variant="emoji" style={{ fontSize }}>
        {group.symbol}
      </Typography>
    </div>
  )
}

export default GroupAvatar

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: theme.palette.op.hover.primary,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: GroupAvatar.name },
)
