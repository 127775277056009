// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import ObjectID from '../../lib/object-id'
import { Model } from './'

export class Snippet implements Model {
  id: string = ObjectID()
  userId: string = null
  name: string = null
  text: string = null
  sharedWith: string[] = []
  createdAt: number = null
  updatedAt: number = null

  // Local
  isNew = false

  constructor(private root: Service, attrs: Partial<Snippet> = {}) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  update = (attributes: Partial<Snippet>) => {
    Object.assign(this, attributes)
    this.save()
  }

  save = () => {
    this.isNew = false
    this.root.snippet.save(this)
  }

  delete = () => {
    this.root.snippet.delete(this)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.updatedAt = parseDate(json.updatedAt) || this.updatedAt
    this.createdAt = parseDate(json.createdAt) || this.createdAt
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      userId: this.userId,
      name: this.name,
      text: this.text,
      sharedWith: toJS(this.sharedWith),
    }
  }
}
