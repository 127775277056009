// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { CheckBoxTickIcon } from '../../../component/icons/Tint/16/General'
import Typography from '@ui/Typography'
import { Collapsible, RenderOptionProps, SelectSearch } from '@src/component'
import { useAppStore } from '@src/app/context'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import EventTypeOption from './EventTypeOption'
import Tooltip from '@src/component/tooltip'
import useIsOverflowing from '@src/lib/useIsOverflowing'
import CollapsibleFooterCard, { CollapsibleFooterHeader } from './CollapsibleFooterCard'
import SelectSearchTrigger from './SelectSearchTrigger'
import InfoCard from './InfoCard'
import { CallsAndMessagesEventTypes } from '@src/data'

interface EventTypesProps {
  callsAndMessagesEventTypes: string[]
  setCallsAndMessagesEventTypes: React.Dispatch<React.SetStateAction<string[]>>
  phoneNumbers: string[]
  setPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>
  phoneNumbersCardDescriptionRoot?: string
}

type PhoneNumberOptionType = {
  name: string
  value: string
  symbol: string
}

const CallsAndMessagesEvents = ({
  callsAndMessagesEventTypes,
  setCallsAndMessagesEventTypes,
  phoneNumbers,
  setPhoneNumbers,
  phoneNumbersCardDescriptionRoot,
}: EventTypesProps) => {
  const styles = useStyles()
  const { service } = useAppStore()

  const phoneNumbersOptions: PhoneNumberOptionType[] =
    service.phoneNumber.collection.list.map((phoneNumber) => ({
      name: phoneNumber.name,
      symbol: phoneNumber.symbol,
      value: phoneNumber.id,
    }))

  const isAllSelected = phoneNumbersOptions.length === phoneNumbers.length

  const selectedPhoneNumbers = phoneNumbersOptions.filter((option) =>
    phoneNumbers.includes(option.value),
  )

  const handlePhoneNumberSelected = (options: PhoneNumberOptionType[]) => {
    setPhoneNumbers(options.map((option) => option.value))
  }

  const handleOnChange =
    (eventName: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setCallsAndMessagesEventTypes((eventTypes) => [...eventTypes, eventName])
      } else {
        setCallsAndMessagesEventTypes((eventTypes) =>
          eventTypes.filter((event) => event !== eventName),
        )
      }
    }

  const handleOptionName = (option: PhoneNumberOptionType[]) => {
    if (option.length === 0) {
      return 'Select phone numbers...'
    }

    if (isAllSelected) {
      return 'All phone numbers'
    }

    return `${option.length} phone ` + (option.length === 1 ? 'number' : 'numbers')
  }

  const handleSelectAllPhoneNumbers = (_, selectAll: boolean) => {
    if (selectAll) {
      setPhoneNumbers(phoneNumbersOptions.map((option) => option.value))
    } else {
      setPhoneNumbers([])
    }
  }

  const handleRenderOption = ({
    option,
    index,
    rootOptionProps,
  }: RenderOptionProps<PhoneNumberOptionType>) => {
    // ok. this is hack. but no idea about a better one. mui you know 🤷
    const isSelected = rootOptionProps['aria-selected']
    const { isOverflowing, ref: optionRef } = useIsOverflowing()

    return (
      <div
        {...rootOptionProps}
        key={index}
        className={cx(
          styles.numberPhoneNumberOption,
          isSelected && styles.numberPhoneNumberOptionSelected,
        )}
      >
        <div style={{ display: 'flex' }}>
          <Typography
            variant="footnote"
            nowrap
            style={{
              paddingRight: 9,
              boxSizing: 'content-box',
              flexShrink: 0,
            }}
          >
            {option.symbol}
          </Typography>

          <Tooltip title={isOverflowing ? option.name : null}>
            <Typography
              variant="footnote"
              nowrap
              className={cx(isSelected && styles.numberPhoneNumberOptionSelectedTxt)}
              ref={optionRef}
            >
              {option.name}
            </Typography>
          </Tooltip>
        </div>
        {isSelected && (
          <CheckBoxTickIcon className={styles.numberPhoneNumberOptionSelectedIcon} />
        )}
      </div>
    )
  }

  const numberOfSelectedCallsAndMessagesEventTypes = callsAndMessagesEventTypes.length

  return (
    <Collapsible
      title={() => (
        <div className={styles.title}>
          <Typography variant="callout" color="textPrimary">
            Calls & messages
          </Typography>
          <Typography variant="footnote" color="placeholder">
            Listen to events based on call and message activity
          </Typography>
        </div>
      )}
      actionText={
        numberOfSelectedCallsAndMessagesEventTypes > 0
          ? `${numberOfSelectedCallsAndMessagesEventTypes} selected`
          : undefined
      }
    >
      <>
        {CallsAndMessagesEventTypes.map((event) => (
          <EventTypeOption
            key={event.name}
            name={event.name}
            description={event.description}
            isChecked={callsAndMessagesEventTypes.includes(event.name)}
            onChange={handleOnChange(event.name)}
          />
        ))}
        <CollapsibleFooterCard>
          <CollapsibleFooterHeader
            title="Receive updates from all phone numbers"
            subtitle="Turn this off to manually select which numbers to receive updates from"
            areAllSelected={selectedPhoneNumbers.length === phoneNumbersOptions.length}
            onAllSelected={handleSelectAllPhoneNumbers}
          />
          {selectedPhoneNumbers.length === phoneNumbersOptions.length ? (
            <InfoCard>
              The webhook will receive updates from all phone numbers in your workspace
              including new phone numbers as they are added
            </InfoCard>
          ) : (
            <SelectSearch
              dropdownProps={{
                placeholder: 'Search phone numbers...',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              multiple={true}
              value={selectedPhoneNumbers}
              getOptionName={handleOptionName}
              onChange={handlePhoneNumberSelected}
              options={phoneNumbersOptions}
              renderOption={handleRenderOption}
              renderTrigger={SelectSearchTrigger}
            />
          )}
        </CollapsibleFooterCard>
      </>
    </Collapsible>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
    },
    icon: {
      marginRight: 12,
    },
    selectSearchFooter: {
      boxShadow: `0 -1.5px 0 0 ${theme.palette.op.border.common}`,
      padding: '11px 14px',
      marginTop: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    numberPhoneNumberOption: {
      marginTop: 2,
      padding: '7px 11px',
      width: 232,
      height: 30,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 5,
      cursor: 'pointer',

      '&[data-focus="true"]': {
        backgroundColor: theme.palette.op.tag.purple.bgHover,
      },
    },
    numberPhoneNumberOptionSelected: {
      backgroundColor: theme.palette.op.tag.purple.bg,
    },
    numberPhoneNumberOptionSelectedTxt: {
      color: theme.palette.op.tag.purple.text,
    },
    numberPhoneNumberOptionSelectedIcon: {
      color: theme.palette.op.tag.purple.text,
      flexShrink: 0,
    },
  }),
  { name: CallsAndMessagesEvents.name },
)

export default observer(CallsAndMessagesEvents)
