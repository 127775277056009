import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useLoginStore } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import TextField from '../../component/textfield-v2'
import Tooltip from '../../component/tooltip'
import Typography from '@ui/Typography'
import keyboard from '../../lib/keyboard'
import useInputState from '../../lib/use-input-state'
import Header from '../onboarding/header'

interface EmailCodeProps {}

const EmailCode: React.FC<EmailCodeProps> = function ({}) {
  const styles = useStyles({})
  const { history } = useAppStore()
  const loginStore = useLoginStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')
  const [password, setPassword] = useInputState('')
  const disabled = !email || !password

  const handleForgot = () => {
    loginStore.setStep('forgot')
  }

  const handleSignIn = () => {
    if (disabled) return
    loginStore.loginWithPassword(email, password)
  }

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.card}>
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Welcome back!
        </Typography>
        <Typography variant="body" color="textSecondary">
          Enter your email address and password to log back in to your account.
        </Typography>

        <div style={{ marginTop: 48, textAlign: 'left' }}>
          <TextField
            autoFocus={!email}
            type="email"
            fullWidth
            label="Email"
            size={45}
            value={email}
            onChange={setEmail}
          />
          <TextField
            fullWidth
            autoFocus={Boolean(email)}
            type="password"
            label="Password"
            size={45}
            rootStyle={{ marginTop: 20 }}
            value={password}
            onChange={setPassword}
            onKeyDown={keyboard.onEnter(handleSignIn)}
          />
          <Button
            fullWidth
            height={40}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleSignIn}
            disabled={disabled}
            loading={loginStore.loading}
          >
            Continue
          </Button>
          <Tooltip disabled={Boolean(email)} title="Enter an email first">
            <span>
              <Button
                fullWidth
                height={36}
                variant="text"
                color="textSecondary"
                style={{ marginTop: 8 }}
                onClick={handleForgot}
                disabled={!email}
              >
                Forgot your password?
              </Button>
            </span>
          </Tooltip>
          <Button
            fullWidth
            height={36}
            variant="text"
            color="textSecondary"
            fontWeight="regular"
            style={{ marginTop: 8 }}
            onClick={history.goBack}
          >
            Go back
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(EmailCode)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 320,
    textAlign: 'center',
    margin: '128px auto 50px',
  },
}))
