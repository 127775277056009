import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useOnboardingState } from '.'
import Button from '../../component/button-v2'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import keyboard from '../../lib/keyboard'
import { formatted } from '../../lib/phone-number'
import useCountdown from '../../lib/use-countdown'
import useInputState from '../../lib/use-input-state'
import { Card } from './common'
import ContactSupportMessage from './common/ContactSupportMessage'

interface VerifyCodeProps {}

const VerifyCode: React.FC<VerifyCodeProps> = function ({}) {
  const state = useOnboardingState()
  const [code, setCode] = useInputState('')
  const [countdown, startCountdown] = useCountdown()
  const [showContactSupportMessage, setShowContactSupportMessage] = useState(false)

  useEffect(() => {
    startCountdown(30)
  }, [])

  const handleVerify = () => {
    state.verifyNumber(code).catch(() => setShowContactSupportMessage(true))
  }

  const handleResend = () => {
    startCountdown(60)

    state
      .sendVerification(state.verifiedPhoneNumber, false)
      .catch(() => setShowContactSupportMessage(true))
  }

  return (
    <Card
      emoji="🔢"
      title="Enter the code"
      description={
        <div>
          We just sent a verification code to{' '}
          <Typography variant="inherit" color="textPrimary">
            {formatted(state.verifiedPhoneNumber)}
          </Typography>
        </div>
      }
    >
      <TextInput
        placeholder="Verification code"
        value={code}
        autoFocus
        fullWidth
        onChange={setCode}
        onKeyPress={keyboard.onEnter(handleVerify)}
        style={{ textAlign: 'center' }}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        height={40}
        onClick={handleVerify}
        disabled={code.length < 4}
        loading={state.loading}
        style={{ marginTop: 20 }}
      >
        Verify
      </Button>
      <Button
        fullWidth
        variant="text"
        color="textSecondary"
        fontWeight="regular"
        height={36}
        onClick={handleResend}
        disabled={countdown > 0}
        style={{ marginTop: 8 }}
      >
        Resend code
        {countdown > 0 ? ` (${countdown} sec)` : ``}
      </Button>

      {showContactSupportMessage && <ContactSupportMessage />}
    </Card>
  )
}

export default observer(VerifyCode)
