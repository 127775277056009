// @ts-strict-ignore
import { action, makeAutoObservable, toJS } from 'mobx'
import { v4 as uuid } from 'uuid'
import Service from '..'
import { parseDate, removeAtIndex } from '../../lib'
import { stripSkinVariation } from '../../lib/emoji'
import { formatted } from '../../lib/phone-number'
import { ActivityStatus, MessageDirection } from '../../types'
import { Model } from './base'
import { Comment } from './comment'
import { Conversation } from './conversation'
import { MessageMedia } from './media'
import { Reaction } from './reaction'

export interface ActivityError {
  code?: number
  message?: string
  logLevel?: string
  secondaryMessage?: string
  logType?: string
  docs?: string
  causes?: string
  solutions?: string
  description?: string
}

export class Activity implements IActivity, Model {
  id: string = `AC${uuid()}`.replace(/-/g, '')
  answeredAt: number = null
  answeredBy: string = null
  body: string = null
  clientId: string = null
  completedAt: number = null
  conversationId: string = null
  createdAt: number = Date.now()
  createdBy: string = null
  direction: MessageDirection = null
  duration: number = null
  enrichment: Enrichment = null
  error: ActivityError = null
  from: string = null
  initiatedAt: number = null
  media: MessageMedia[] = []
  recordingUrl: string = null
  sentAt: number = null
  status: ActivityStatus = null
  to: string = null
  transcript: string = null
  type: 'message' | 'call' | 'voicemail' | 'loading' = null
  updatedAt: number = Date.now()
  resolvedAt: number = null
  resolvedBy: string = null
  sid: string = null

  // Pagination params
  beforeId?: string = null
  afterId?: string = null

  // Relations
  conversation: Conversation = null
  reactions: Reaction[] = []
  comments: Comment[] = []

  constructor(private root: Service, attrs?: Partial<Activity>) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get isPaginationPlaceholder() {
    return this.afterId || this.beforeId
  }

  get isOutgoing(): boolean {
    return this.direction === 'outgoing'
  }

  get preview(): string {
    const userId = this.isOutgoing ? this.createdBy : this.answeredBy
    const isMe = userId === this.root.user.current.id
    const member = this.root.member.collection.get(userId)

    let from = ''
    if (this.isOutgoing) {
      from = isMe || !member ? 'You' : member?.firstName
    } else {
      const fromParticipant = this.conversation?.participants.find(
        (p) => p.phoneNumber === this.from,
      )
      from = fromParticipant?.shortName || formatted(this.from)
    }

    if (this.type === 'call') {
      if (this.status === 'failed') {
        return 'Call failed'
      } else if (this.status === 'ringing' || this.status === 'in-progress') {
        if (isMe || !member) {
          return 'Call in progress...'
        } else {
          return `${member.firstName} on a call...`
        }
      } else if (!this.answeredAt) {
        return this.isOutgoing ? 'Missed your call' : 'Missed call'
      }
      return this.isOutgoing ? `${from} called` : 'Called you'
    }
    if (this.type === 'message' && this.media?.length > 0) {
      const attch = this.friendlyAttachment()
      if (this.body) {
        return `${attch}: ${this.body}`
      }
      return attch
    } else if (this.type === 'message') {
      const body = this.body || ''
      if (this.isOutgoing || this.conversation?.isGroup) {
        return `${from}: ${body}`
      } else {
        return body
      }
    } else if (this.type === 'voicemail') {
      return this.transcript ? `Voicemail: ${this.transcript}` : 'Voicemail'
    }
    return null
  }

  get isMissedCall() {
    if (this.type !== 'call') return false
    return (
      this.status === 'busy' ||
      this.status === 'no-answer' ||
      (this.status === 'completed' && !this.answeredAt)
    )
  }

  send = (): Promise<Activity> => {
    this.status = 'queued'
    this.save()

    const conv = this.root.conversation.collection.get(this.conversationId)
    conv.lastActivityId = this.id
    conv.lastActivityAt = this.createdAt
    conv.save()

    // Upload the media first and then send the message
    return Promise.all(this.media.map((m) => m.upload()))
      .then(() => this.root.activity.send(this))
      .catch(
        action((error) => {
          this.status = 'failed'
          this.error = {
            code: 400,
            message: 'Failed to send',
            secondaryMessage: error.message,
          }
          this.save()
          return this
        }),
      )
  }

  get recordings() {
    return !this.media && this.recordingUrl
      ? [{ url: this.recordingUrl, type: 'audio/mpeg' }]
      : this.media
  }

  save() {
    this.root.activity.collection.put(this)
  }

  delete = () => {
    this.root.activity.collection.delete(this)
  }

  deleteRecordings(ids: string[]) {
    this.root.voice.recordings.bulkDelete(this.id, ids)
    this.media = this.media.filter((item) => !ids.includes(item.id))
  }

  friendlyAttachment = (): string => {
    let attch = ''
    if (this.media.length > 1) {
      const types = this.media.map((m) => m.type.split('/')[0])
      const allTypesTheSame = types.every((v) => v === types[0])
      if (!allTypesTheSame) {
        attch = `${this.media.length} attachments`
      } else if (this.media[0].type.startsWith('image/')) {
        attch = `${this.media.length} images`
      } else if (this.media[0].type.startsWith('audio/')) {
        attch = `${this.media.length} audio`
      } else if (this.media[0].type.startsWith('video/')) {
        attch = `${this.media.length} videos`
      } else {
        attch = `${this.media.length} attachments`
      }
    } else {
      if (this.media[0].type === 'image/gif') {
        attch = 'GIF'
      } else if (this.media[0].type.startsWith('image/')) {
        attch = 'Image'
      } else if (this.media[0].type.startsWith('audio/')) {
        attch = 'Audio'
      } else if (this.media[0].type.startsWith('video/')) {
        attch = 'Video'
      } else {
        attch = 'Attachment'
      }
    }
    return attch
  }

  fetchBefore() {
    return this.root.activity.fetchBefore(this)
  }

  fetchAfter() {
    return this.root.activity.fetchAfter(this)
  }

  getReaction(emoji: string) {
    const body = this.root.emoji.getEmojiWithSkinTone(emoji)
    return this.reactions.find(
      (r) =>
        stripSkinVariation(r.body) === stripSkinVariation(body) &&
        r.userId === this.root.user.current.id,
    )
  }

  addReaction(reaction: Reaction) {
    this.reactions.push(reaction)
    return this.root.activity.addReaction(reaction)
  }

  deleteReaction(reaction: Reaction) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.root.activity.deleteReaction(reaction)
  }

  toggleReaction(emoji: string) {
    const reaction = this.getReaction(emoji)
    if (reaction) {
      return this.deleteReaction(reaction)
    } else {
      const body = this.root.emoji.getEmojiWithSkinTone(emoji)
      return this.addReaction(
        new Reaction(this.root, this, { body, userId: this.root.user.current.id }),
      )
    }
  }

  addComment(comment: Comment) {
    this.comments.push(comment)
    return Promise.all(comment.media.map((m) => m.upload())).then(() =>
      this.root.activity.addComment(comment),
    )
  }

  deleteComment(comment: Comment) {
    const commentIndex = this.comments.findIndex((c) => c === comment)
    this.comments = removeAtIndex(this.comments, commentIndex)
    return this.root.activity.deleteComment(comment)
  }

  sendComment(body: string, media: MessageMedia[]) {
    return this.addComment(
      new Comment(this.root, this, { body, media, userId: this.root.user.current.id }),
    )
  }

  resolve() {
    this.resolvedAt = Date.now()
    this.resolvedBy = this.root.user.current.id
    return this.root.activity.resolve(this)
  }

  unresolve() {
    this.resolvedAt = null
    this.resolvedBy = null
    this.root.activity.unresolve(this)
  }

  deserialize = (attrs: any) => {
    if (attrs) {
      const { media, comments, reactions, meta, ...json } = attrs
      Object.assign(this, json)
      this.media = media?.map((m) => new MessageMedia(this.root).deserialize(m)) ?? []
      this.reactions = reactions?.map?.((r) => new Reaction(this.root, this, r)) ?? []
      this.comments = comments?.map?.((c) => new Comment(this.root, this, c)) ?? []
      this.answeredAt = parseDate(json.answeredAt || this.answeredAt)
      this.completedAt = parseDate(json.completedAt || this.completedAt)
      this.createdAt = parseDate(json.createdAt || this.createdAt)
      this.initiatedAt = parseDate(json.initiatedAt || this.initiatedAt)
      this.sentAt = parseDate(json.sentAt || this.sentAt)
      this.updatedAt = parseDate(json.updatedAt || this.updatedAt)
      if (meta) {
        this.resolvedAt = parseDate(meta.resolvedAt)
        this.resolvedBy = meta.resolvedBy
      }
    }
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      sid: this.sid,
      answeredAt: this.answeredAt,
      answeredBy: this.answeredBy,
      body: this.body,
      clientId: this.clientId,
      completedAt: this.completedAt,
      conversationId: this.conversationId,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      direction: this.direction,
      duration: this.duration,
      enrichment: toJS(this.enrichment),
      error: toJS(this.error),
      from: this.from,
      initiatedAt: this.initiatedAt,
      media: this.media.map((m) => m.serialize()),
      reactions: this.reactions.map((r) => r.serialize()),
      comments: this.comments.map((c) => c.serialize()),
      recordingUrl: this.recordingUrl,
      sentAt: this.sentAt,
      status: this.status,
      to: this.to,
      transcript: this.transcript,
      type: this.type,
      updatedAt: this.updatedAt,
      resolvedAt: this.resolvedAt,
      resolvedBy: this.resolvedBy,
      beforeId: this.beforeId,
      afterId: this.afterId,
    }
  }

  tearDown = () => {}
}

export interface Enrichment {
  taggedIds: {
    groupIds: string[]
    orgIds: string[]
    userIds: string[]
  }
  tokens: {
    [key: string]: {
      locations: { startIndex: number; endIndex: number }[]
      replacement: string
      token: string
      type: 'mention'
    }
  }
}

export interface IActivity {
  id: string
  answeredAt: number
  answeredBy: string
  body: string
  clientId: string
  completedAt: number
  conversationId: string
  createdAt: number
  createdBy: string
  direction: MessageDirection
  duration: number
  enrichment: Enrichment
  error: ActivityError
  from: string
  initiatedAt: number
  media: MessageMedia[]
  recordingUrl: string
  sentAt: number
  status: ActivityStatus
  to: string
  transcript: string
  type: 'message' | 'call' | 'voicemail' | 'loading'
  updatedAt: number
}

export interface IBaseAlert<T> {
  id: string
  userId: string
  readAt: number
  data: T
  updatedAt: number
  createdAt: number
}
