// @ts-strict-ignore
import { logError } from '@src/lib/log'
import { ExportType, ReportExport } from '@src/service/transport/report'
import { ExportUpdateNotification } from '@src/service/transport/websocket'
import { makeAutoObservable, reaction, when } from 'mobx'
import { RangeModifier } from 'react-day-picker'
import { dayjs } from '../../lib/date'
import { ReportGranularity } from '../../types'
import AppStore from '../store'

export default class AnalyticsUiStore {
  private _range: RangeModifier = {
    from: dayjs().startOf('month').toDate(),
    to: new Date(),
  }
  private _exportType: ExportType = 'month-to-date'
  private _selectedPhoneNumbers: string[] = []
  private _granularity: ReportGranularity = 'day'

  exports: ReportExport[] = []

  constructor(private root: AppStore) {
    this.subscribeToWebhookEvents()

    makeAutoObservable(this, {})

    reaction(
      () => root.service.organization.phoneNumber.collection.list,
      () => {
        if (this.selectedPhoneNumbers.length === 0) {
          this.selectedPhoneNumbers =
            root.service.organization.phoneNumber.collection.list.map((p) => p.id)
        }
      },
    )

    when(
      () => root.service.auth.hasSession,
      () => {
        this.fetchExports()
      },
    )
  }

  get granularity() {
    return this._granularity
  }

  set granularity(value: ReportGranularity) {
    this._granularity = value
  }

  get selectedPhoneNumbers() {
    return this._selectedPhoneNumbers
  }

  set selectedPhoneNumbers(values: string[]) {
    this._selectedPhoneNumbers = values
  }

  get range() {
    return this._range
  }

  get exportType() {
    return this._exportType
  }

  set range(value: RangeModifier) {
    this._range = value
  }

  set exportType(value: ExportType) {
    this._exportType = value
  }

  get isProcessingExport() {
    return this.exports.some(
      (exportItem) =>
        exportItem.status === 'processing' || exportItem.status === 'pending',
    )
  }

  async fetchExports() {
    try {
      const exportList = await this.root.service.transport.report.export.list()

      this.exports = exportList
    } catch (e) {
      logError(e)
    }
  }

  async clearUserExports() {
    try {
      await this.root.service.transport.report.export.clearUserExports()

      this.exports = []
    } catch (e) {
      logError(e)
    }
  }

  private handleExportUpdate(message: ExportUpdateNotification) {
    const currentItem = this.exports.find((item) => item.id === message.id)

    if (!currentItem) {
      this.exports = [message, ...this.exports]
      return
    }

    this.exports = this.exports.map((exportItem) => {
      if (exportItem.id === message.id) {
        return {
          ...exportItem,
          ...message,
        }
      }

      return exportItem
    })
  }

  private subscribeToWebhookEvents() {
    this.root.service.transport.onNotificationData.subscribe((message) => {
      switch (message.type) {
        case 'export-update':
          return this.handleExportUpdate(message)
      }
    })
  }
}
