// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import UploadIcon from '@material-ui/icons/BackupOutlined'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import Button, { DangerButton } from '../../component/button'
import { GoogleIcon } from '../../component/icons/Tint/Misc'
import Typography from '@ui/Typography'
import { friendlyDateTime } from '../../lib'
import { ContactSettings } from '../../service/model'
import { redirectGoogleContactLogin } from '../login/google-login'
import { Header, Page } from './common'
import { Card, CardActions, CardContent, CardHeader } from './settings-card'

interface ContactsProps {}

const Contacts: React.FC<ContactsProps> = function ({}) {
  const styles = useStyles({})
  const { command, showAlert, service, toast } = useAppStore()
  const settings = service.contact.settings.list
  const imports = service.contact.imports

  useEffect(() => {
    service.contact.fetchSettings().catch(toast.showError)
  }, [])

  const handleDeleteAll = () => {
    showAlert({
      title: 'Delete all contacts',
      body: 'Are you sure you want to delete all your contacts from OpenPhone?',
      actions: [
        {
          type: 'destructive',
          title: 'Yes, delete',
          onClick: () => {
            toast.show({
              message: 'Contacts deleted. Hope you got to say bye!',
            })
            service.contact.deleteAll().catch(toast.showError)
          },
        },
        {
          title: 'No, cancel',
        },
      ],
    })
  }

  const handleImport = () => {
    command.present({
      name: 'import contacts',
      onComplete: (contacts, name) => {
        command.hide()
        service.contact.createBulk(contacts).catch(toast.showError)
        toast.show({ message: `${name} imported!` })
      },
    })
  }

  const handleDeleteImport = (name: string) => {
    showAlert({
      title: 'Delete imported contacts',
      body: `Are you sure you want to delete all contacts imported under "${name}"?`,
      actions: [
        {
          type: 'destructive',
          title: 'Yes, delete',
          onClick: () => {
            toast.show({
              message: 'Contacts deleted. Hope you got to say bye!',
            })
            service.contact.deleteBySource('csv', name).catch(toast.showError)
          },
        },
        {
          title: 'No, cancel',
        },
      ],
    })
  }

  const handleIntegrationDelete = (settings: ContactSettings) => {
    showAlert({
      title: 'Delete integration',
      body: 'Deleting this integration will stop OpenPhone from pulling contacts from this source going forward. Are you sure?',
      actions: [
        {
          type: 'destructive',
          title: 'Yes, delete',
          onClick: () => {
            toast.show({ message: 'Integration deleted!' })
            service.contact.deleteSettings(settings.id).catch(toast.showError)
          },
        },
        {
          title: 'No, cancel',
        },
      ],
    })
  }

  const handleResync = (settings: ContactSettings) => {
    settings.resync().catch(toast.showError)
  }

  const handleNewIntegration = () => {
    redirectGoogleContactLogin()
  }

  return (
    <Page>
      <Header title="Contacts" subtitle="Manage the settings" />
      <Card variant="elevation" className={styles.row} style={{ marginTop: 24 }}>
        <CardHeader
          title="Integrations"
          subheader="Bring your contacts into OpenPhone by integrating us with other solutions you use"
        />
        <CardContent>
          {settings === null ? (
            <Typography variant="callout" color="textSecondary">
              Loading integrations...
            </Typography>
          ) : settings.length === 0 ? (
            <Typography variant="callout" color="textSecondary">
              You have no integrations around contacts.
            </Typography>
          ) : (
            settings.map((settings) => (
              <div key={settings.id} className={styles.integration}>
                <GoogleIcon />
                <div style={{ margin: '0 1rem', flex: 1 }}>
                  <Typography variant="caption2" color="textSecondary">
                    Google Contacts
                  </Typography>
                  <Typography variant="body" color="textPrimary">
                    {settings.source}
                  </Typography>
                </div>
                <DangerButton
                  size="small"
                  variant="text"
                  color="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => handleIntegrationDelete(settings)}
                >
                  Delete
                </DangerButton>
                <Button
                  disabled={settings.resyncStatus === 'started'}
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => handleResync(settings)}
                >
                  Resync
                </Button>
              </div>
            ))
          )}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="outlined"
            onClick={handleNewIntegration}
          >
            Add Google Contacts
          </Button>
        </CardActions>
      </Card>

      <Card variant="elevation" className={styles.row}>
        <CardHeader
          title="CSV Imports"
          subheader="Import contacts in bulk through a CSV file"
        />
        <CardContent>
          {imports.length === 0 ? (
            <Typography variant="caption1" color="textSecondary" fontWeight="regular">
              You will see a list of your uploaded CSVs here.
            </Typography>
          ) : (
            imports.map((csvImport) => (
              <div key={csvImport.name} className={styles.integration}>
                <Typography
                  style={{ marginRight: '1rem', flex: 1 }}
                  nowrap
                  variant="body"
                  color="textPrimary"
                >
                  {csvImport.name}
                </Typography>
                <DangerButton
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => handleDeleteImport(csvImport.name)}
                >
                  Delete
                </DangerButton>
              </div>
            ))
          )}
        </CardContent>
        <CardActions>
          <Button
            startIcon={<UploadIcon />}
            color="primary"
            variant="outlined"
            onClick={handleImport}
          >
            Import a CSV
          </Button>
        </CardActions>
      </Card>

      <div>
        <Typography
          textTransform="uppercase"
          component="div"
          variant="callout"
          color="textPrimary"
          className={styles.dangerInset}
        >
          danger zone
        </Typography>
        <Typography
          component="div"
          variant="caption1"
          color="textSecondary"
          fontWeight="regular"
          className={styles.dangerInset}
        >
          This will permanently delete all your contacts from OpenPhone.
        </Typography>
        <DangerButton onClick={handleDeleteAll}>Delete All Contacts</DangerButton>
      </div>
    </Page>
  )
}

export default observer(Contacts)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    marginBottom: 50,
  },
  integration: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 1rem',
  },
  dangerInset: {
    padding: '5px 7px 12px',
  },
}))
