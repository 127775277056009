// @ts-strict-ignore
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useOnboardingState } from '.'
import { useAppStore } from '@src/app/context'
import { Card, Disclaimer, List } from './common'

type UsageProps = {}

const items = [
  {
    title: 'For my team',
    value: 'work',
    description: "I'll be using OpenPhone for a team.",
    emoji: '🏢',
  },
  {
    title: 'Just for me',
    value: 'personal',
    description: "I'll be using OpenPhone only for myself.",
    emoji: '🙋',
  },
]

const Usage: React.FC<UsageProps> = function ({}) {
  const styles = useStyles({})
  const { service, history } = useAppStore()
  const store = useOnboardingState()
  const org = service.organization.current
  const user = service.user.current

  const continuePressed = (usage: 'work' | 'personal') => {
    if (usage !== 'work') {
      org.update({
        name: null,
        industry: null,
        employees: null,
        membership: {
          ...org.membership,
          title: 'individual',
        },
        analytics: {
          ...org.analytics,
          useCase: 'individual',
        },
      })
    }
    store.advance(usage)
  }

  const handleSkip = () => {
    store.advance('skip')
  }

  const handleSelect = (item) => {
    continuePressed(item.value as any)
  }

  return (
    <Card
      emoji="👋🏼"
      title={`Welcome ${user.firstName}!`}
      description="It’s great to have you with us! To help us optimize your experience, tell us what you plan to use OpenPhone for."
    >
      <List onSelect={handleSelect} items={items} />
      <Disclaimer
        style={{ marginTop: 0, paddingTop: 10 }}
        transparent
        text="OpenPhone was built to grow with you. Even if you choose to start for yourself, you will still be able to invite your teammates later on if you wish."
      />
    </Card>
  )
}

export default Usage

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: 5,
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.op.hover.primary,
  },
}))
