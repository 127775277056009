import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { OnInvite } from '.'
import { useAppStore } from '@src/app/context'
import useKeyStepper from '../../../lib/use-key-stepper'
import Typography from '@ui/Typography'
import Content from '../common/content'
import CommandHeader from '../common/header'
import CommandItem from '../common/item'
import { Member } from '../../../service/model'
import { SharedUserRole } from '../../../types'
import { Command } from '../common-v2'

export interface ShareNumberRoleCommandProps {
  email: string
  member: Member
  onInvite: OnInvite
}

const sharedRoles: SharedUserRole[] = ['admin', 'member']

const ShareNumberRoleCommand: React.FC<ShareNumberRoleCommandProps> = function ({
  email,
  member,
  onInvite,
}) {
  const styles = useStyles({})
  const { command, service } = useAppStore()
  const name = member ? member.name : email
  const user = service.user.current.asMember
  const items = useMemo(
    () => sharedRoles.filter((role) => user.canInvite(role)),
    [user.role],
  )
  const handleSelect = (role: SharedUserRole) => {
    onInvite(member?.id, email, role)
    command.hide()
  }

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    handleSelect,
    defaultSelectedIndex: 1,
    name: 'command/share-number/role',
  })

  return (
    <Command>
      <CommandHeader title="Choose a role" subtitle={`Inviting ${name}`} />
      <Content>
        {items.map((item, index) => (
          <CommandItem
            key={item}
            {...getItemProps(index)}
            highlighted={index === selectedIndex}
            className={styles.row}
          >
            <div>
              <Typography variant="body" color="textPrimary" className={styles.roleName}>
                {item}
              </Typography>
              <Typography variant="caption1" color="textSecondary" fontWeight="regular">
                {item === 'admin'
                  ? 'Can call and message and manage settings.'
                  : 'Can call and message.'}
              </Typography>
            </div>
          </CommandItem>
        ))}
      </Content>
    </Command>
  )
}

export default observer(ShareNumberRoleCommand)

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: 70,
  },
  roleName: {
    display: 'block',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
}))
