import { computed, makeObservable, reaction } from 'mobx'
import AppStore from '../../store'
import { throttle } from '../../../lib/throttle'
import { memoize } from '../../../lib/fn'
import { Activity, Conversation } from '../../../service/model'
import { Controller } from '../../../component/input-bar'
import { InputBarController, InputBarRegistry } from '../../input-bar'

const getEditor = memoize(() => Controller.createSlateEditor())

export class ConversationInputBarRegistry extends InputBarRegistry<
  ConversationInputBarController,
  Conversation
> {
  readonly name = 'ConversationInputBarRegistry'

  constructor(app: AppStore) {
    super(app, {
      classConstructor: (conversation) =>
        new ConversationInputBarController(app, conversation),
      // FIXME: Switch back to 1 to solve the serialization/deserialization
      // issue patched by https://github.com/OpenPhone/web/pull/304
      cacheMax: 10,
    })
  }

  isController(controller: unknown): controller is ConversationInputBarController {
    return controller instanceof ConversationInputBarController
  }

  isModel(model: unknown): model is Conversation {
    return model instanceof Conversation
  }
}

export class ConversationInputBarController extends InputBarController<Conversation> {
  constructor(app: AppStore, readonly conversation: Conversation) {
    super(app, getEditor())

    makeObservable(this, {
      features: computed,
      model: computed,
    })

    this.disposeBag.add(
      reaction(
        () => this.message,
        throttle(() => this.app.conversation.setTyping(!this.isEmpty), 500),
        { name: 'SetTyping' },
      ),

      this.messages$.subscribe(() => {
        this.app.conversation
          .send(this.messageSerialized, this.messageMedia)
          .catch(this.app.toast.showError)
      }),
    )
  }

  override get isSendEnabled() {
    return !this.isEmpty && !this.menu.mode && this.conversation.participants.length !== 0
  }

  get features() {
    return {
      attachments: true,
      commands: true,
      mentions: this.app.inboxes.selected === this.app.inboxes.dm,
    }
  }

  get model() {
    return this.conversation
  }
}

export class CommentsInputBarRegistry extends InputBarRegistry<
  CommentsInputBarController,
  Activity
> {
  readonly name = 'CommentsInputBarRegistry'

  constructor(app: AppStore) {
    super(app, {
      classConstructor: (activity) => new CommentsInputBarController(this.app, activity),
      cacheMax: 50,
    })
  }

  isController(controller: unknown): controller is CommentsInputBarController {
    return controller instanceof CommentsInputBarController
  }

  isModel(model: unknown): model is Activity {
    return model instanceof Activity
  }
}

export class CommentsInputBarController extends InputBarController<Activity> {
  readonly features = { attachments: true, commands: true, mentions: true }

  constructor(app: AppStore, readonly activity: Activity) {
    super(app, InputBarController.createSlateEditor())

    makeObservable(this, {
      model: computed,
    })

    this.disposeBag.add(
      this.messages$.subscribe(() => {
        this.activity
          .sendComment(this.messageSerialized, this.messageMedia)
          .catch(this.app.toast.showError)
      }),
    )
  }

  get model() {
    return this.activity
  }
}
