import React from 'react'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import {
  emojiSkinVariations,
  getEmojiChar,
  getEmojis,
  SkinVariation,
} from '@src/lib/emoji'
import classNames from 'classnames'

const items = [undefined, ...emojiSkinVariations]

interface EmojiSkinTonePickerProps {
  emoji: string
  defaultSkinTone?: string
  onSelect: (modifier: string | undefined) => void
}

const itemPrefix = `tone-`

function EmojiSkinTonePicker({
  emoji,
  onSelect,
  defaultSkinTone,
}: EmojiSkinTonePickerProps) {
  const styles = useStyles()
  const emojiObject = getEmojis().get(emoji)

  if (!emojiObject) {
    throw new Error('Emoji not found')
  }

  return (
    <div className={styles.root}>
      {items.map((variation?: SkinVariation) => {
        const modifier = variation?.char
        const id = `${itemPrefix}${modifier}`
        const emojiChar = getEmojiChar(emojiObject, modifier)
        const label = variation?.name ?? 'Default Skin Tone'

        return (
          <div key={id} className={styles.item}>
            <input
              type="radio"
              name="emoji-skin-tone-picker"
              id={id}
              className={classNames(styles.input, styles.visuallyHidden)}
              value={id}
              onChange={() => onSelect(modifier)}
              defaultChecked={defaultSkinTone === modifier}
            />

            <label className={styles.label} htmlFor={id}>
              <span className={styles.emoji} role="img" aria-label={label}>
                {emojiChar.char}
              </span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default observer(EmojiSkinTonePicker)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },

  item: {
    display: 'inline-flex',

    '&:not(:first-child)': {
      marginLeft: 8,
    },
  },

  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 7,
    width: 40,
    height: 40,
    padding: 0,
    border: `1.5px solid ${alpha(theme.palette.op.text.primary, 0.18)}`,
    boxShadow: theme.palette.op.match({
      light: '0 1.5px 4px 0 rgba(0, 0, 0, 0.06)',
      dark: 'unset',
    }),
    '&:hover': {
      backgroundColor: theme.palette.op.hover.primary,
    },
  },

  input: {
    '&:checked + label': {
      borderColor: theme.palette.op.primary[1],
    },

    '&:focus + label': {
      borderColor: theme.palette.op.primary[2],
    },
  },

  emoji: {
    fontSize: 21,
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    color: '#000',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
