import React, { useEffect } from 'react'

/**
 * Given an element, this hook simplifies listening to drag events on that element.
 */
export default function useDraggable(
  props: {
    node: React.MutableRefObject<HTMLDivElement>
    onDrag: (x: number, y: number) => void
    onStart?: () => void
    onEnd?: () => void
  },
  dep: any[],
) {
  useEffect(() => {
    let x = 0
    let y = 0
    let lastX = 0
    let lastY = 0
    const elem = props.node.current
    elem.addEventListener('mousedown', dragMouseDown)

    function dragMouseDown(e: MouseEvent) {
      e = e || (window.event as MouseEvent)
      e.preventDefault()
      // get the mouse cursor position at startup:
      lastX = e.clientX
      lastY = e.clientY
      document.addEventListener('mouseup', closeDragElement)
      // call a function whenever the cursor moves:
      document.addEventListener('mousemove', elementDrag)
      props.onStart?.()
    }

    function elementDrag(e: MouseEvent) {
      e = e || (window.event as MouseEvent)
      e.preventDefault()
      // calculate the new cursor position:
      x = lastX - e.clientX
      y = lastY - e.clientY
      lastX = e.clientX
      lastY = e.clientY
      const offsetLeft = elem.offsetLeft - x
      const offsetTop = elem.offsetTop - y
      props.onDrag(offsetLeft, offsetTop)
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.removeEventListener('mouseup', closeDragElement)
      document.removeEventListener('mousemove', elementDrag)
      props.onEnd?.()
    }

    return closeDragElement
  }, dep)
}
