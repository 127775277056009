/// <reference types="@openphone/desktop-client" />

const OP_ENV = process.env.OP_ENV || 'development'
const VERSION = process.env.OP_VERSION || 'N/A'
const FULL_VERSION = process.env.OP_FULL_VERSION || 'N/A'

export const platform = typeof window !== 'undefined' ? window._e?.platform : undefined
export const isWeb = !platform
export const isMac = platform === 'darwin'
export const isWindows = platform === 'win32'

export type Environment = 'development' | 'production'
export type LogEnv = 'dev' | 'prod'

export interface Config {
  ENV: Environment
  VERSION: string
  FULL_VERSION: string
  ACCOUNT_SERVICE_URL: string
  AUTH_SERVICE_URL: string
  BILLING_SERVICE_URL: string
  COMMUNICATION_SERVICE_URL: string
  CONTACT_SERVICE_URL: string
  INTEGRATION_SERVICE_URL: string
  REPORT_SERVICE_URL: string
  WEBSOCKET_SERVICE_URL: string
  API_SERVICE_URL: string
  AUTH0_BASE_URL: string
  AUTH0_CLIENT_ID: string
  GIPHY_API_KEY: string
  GOOGLE_ANALYTICS_ID: string
  GOOGLE_CLIENT_ID: string
  GOOGLE_MAPS_API_KEY: string
  HUBSPOT_CLIENT_ID: string
  LOG_ENV: LogEnv
  LOG_KEY: string
  IS_DEV: string
  STRIPE_API_KEY: string
  STATIC_URL: string
  FILES_URL: string
}

export const development: Config = {
  ENV: 'development',
  VERSION,
  FULL_VERSION,
  AUTH_SERVICE_URL: 'https://auth.openphone.dev/v2/',
  ACCOUNT_SERVICE_URL: 'https://account.openphone.dev/v2/',
  BILLING_SERVICE_URL: 'https://billing.openphone.dev/v2/',
  COMMUNICATION_SERVICE_URL: 'https://communication.openphone.dev/v2/',
  CONTACT_SERVICE_URL: 'https://contact.openphone.dev/v2/',
  INTEGRATION_SERVICE_URL: 'https://integration.openphone.dev/v2/',
  REPORT_SERVICE_URL: 'https://reports.openphone.dev/v2/',
  WEBSOCKET_SERVICE_URL: 'wss://ws.openphone.dev/v4/',
  API_SERVICE_URL: 'https://api.openphone.dev/v3/',
  GIPHY_API_KEY: 'FfBBeb6l464EfWZby04LxpgLNuypLNJ4',
  GOOGLE_ANALYTICS_ID: 'UA-103292266-1',
  GOOGLE_CLIENT_ID:
    '1068653975143-rntm48ibm9pdd1cnor38dh6j5ur6bh2a.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyD6M_8iPl3TV4Zps8tUYUwYVnYMs1Cl2CQ',
  HUBSPOT_CLIENT_ID: '2224d52a-c6a0-48f1-93c8-d586ea491a03',
  LOG_ENV: 'dev',
  LOG_KEY: 'dbf914014adf7ef669f3d648be831f4f',
  AUTH0_BASE_URL: 'https://openphone-dev.auth0.com/',
  AUTH0_CLIENT_ID: 'NUyavpZolu3slN1324y6qzkSrnHjwvVS',
  IS_DEV: '1',
  STATIC_URL: 'https://opstatics-dev.s3.amazonaws.com',
  FILES_URL: 'https://files.openphone.co/dev/a',
  STRIPE_API_KEY: 'pk_test_iZakIeQzdW4mZScP1v1Io6O0',
}

export const production: Config = {
  ENV: 'production',
  VERSION,
  FULL_VERSION,
  ACCOUNT_SERVICE_URL: 'https://account.openphoneapi.com/v2/',
  AUTH_SERVICE_URL: 'https://auth.openphoneapi.com/v2/',
  BILLING_SERVICE_URL: 'https://billing.openphoneapi.com/v2/',
  COMMUNICATION_SERVICE_URL: 'https://communication.openphoneapi.com/v2/',
  CONTACT_SERVICE_URL: 'https://contact.openphoneapi.com/v2/',
  INTEGRATION_SERVICE_URL: 'https://integration.openphoneapi.com/v2/',
  REPORT_SERVICE_URL: 'https://reports.openphoneapi.com/v2/',
  WEBSOCKET_SERVICE_URL: 'wss://ws.openphoneapi.com/v4/',
  API_SERVICE_URL: 'https://api.openphoneapi.com/v3/',
  AUTH0_BASE_URL: 'https://auth0.openphoneapi.com/',
  AUTH0_CLIENT_ID: 'b2yEyee59rmdWzxDqD4Asm0LIM6MUS1c',
  GIPHY_API_KEY: 'FfBBeb6l464EfWZby04LxpgLNuypLNJ4',
  GOOGLE_ANALYTICS_ID: 'UA-103292266-1',
  GOOGLE_CLIENT_ID:
    '557674652047-4cr80imaminia4a8ipv8j2mm6dch31ll.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyD6M_8iPl3TV4Zps8tUYUwYVnYMs1Cl2CQ',
  HUBSPOT_CLIENT_ID: '41a86e1a-6f46-4884-9180-1271db80dc22',
  LOG_ENV: 'prod',
  LOG_KEY: 'dbf914014adf7ef669f3d648be831f4f',
  IS_DEV: '',
  STATIC_URL: 'https://opstatics.s3.amazonaws.com',
  FILES_URL: 'https://files.openphone.co/a',
  STRIPE_API_KEY: 'pk_live_jOcn8a7ScOdBUWhux1KJoM4K',
}

const configs: { [key in Environment]: Config } = {
  development,
  production,
}

export const ENV_OVERRIDE_KEY = 'env-override'

let envOverride: Environment | undefined

/**
 * The config could be included form the Worker which has no window
 * context.
 * TODO: Find a better way to store env overrides.
 */
if (typeof window !== 'undefined') {
  envOverride = window.localStorage.getItem(ENV_OVERRIDE_KEY) as Environment
}

const environmentVariableOverrides: { [K in keyof Config]: Config[K] | '' } = {
  ENV: (process.env.OP_ENV as Environment | undefined) ?? '',
  VERSION: process.env.OP_VERSION ?? '',
  FULL_VERSION: process.env.OP_FULL_VERSION ?? '',
  ACCOUNT_SERVICE_URL: process.env.OP_ACCOUNT_SERVICE_URL ?? '',
  AUTH_SERVICE_URL: process.env.OP_AUTH_SERVICE_URL ?? '',
  BILLING_SERVICE_URL: process.env.OP_BILLING_SERVICE_URL ?? '',
  COMMUNICATION_SERVICE_URL: process.env.OP_COMMUNICATION_SERVICE_URL ?? '',
  CONTACT_SERVICE_URL: process.env.OP_CONTACT_SERVICE_URL ?? '',
  INTEGRATION_SERVICE_URL: process.env.OP_INTEGRATION_SERVICE_URL ?? '',
  REPORT_SERVICE_URL: process.env.OP_REPORT_SERVICE_URL ?? '',
  WEBSOCKET_SERVICE_URL: process.env.OP_WEBSOCKET_SERVICE_URL ?? '',
  API_SERVICE_URL: process.env.OP_API_SERVICE_URL ?? '',
  AUTH0_BASE_URL: process.env.OP_AUTH0_BASE_URL ?? '',
  AUTH0_CLIENT_ID: process.env.OP_AUTH0_CLIENT_ID ?? '',
  GIPHY_API_KEY: process.env.OP_GIPHY_API_KEY ?? '',
  GOOGLE_ANALYTICS_ID: process.env.OP_GOOGLE_ANALYTICS_ID ?? '',
  GOOGLE_CLIENT_ID: process.env.OP_GOOGLE_CLIENT_ID ?? '',
  GOOGLE_MAPS_API_KEY: process.env.OP_GOOGLE_MAPS_API_KEY ?? '',
  HUBSPOT_CLIENT_ID: process.env.OP_HUBSPOT_CLIENT_ID ?? '',
  LOG_ENV: (process.env.OP_LOG_ENV as LogEnv | undefined) ?? '',
  LOG_KEY: process.env.OP_LOG_KEY ?? '',
  IS_DEV: process.env.OP_IS_DEV ?? '',
  STATIC_URL: process.env.OP_STATIC_URL ?? '',
  FILES_URL: process.env.OP_FILES_URL ?? '',
  STRIPE_API_KEY: process.env.OP_STRIPE_API_KEY ?? '',
}

// Webpack replaces the environment variables above with a falsy value if not
// provided, so let's delete the keys so they don't override truthy values.
for (const [key, value] of Object.entries(environmentVariableOverrides)) {
  if (!value) {
    delete environmentVariableOverrides[key]
  }
}

export const config: Config = {
  ...configs[envOverride ?? OP_ENV],
  ...environmentVariableOverrides,
}

export default config
