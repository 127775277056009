import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

interface CommandHeaderProps {
  title: ReactNode
  subtitle?: ReactNode
  trailingIcon?: ReactNode
  stepper?: ReactNode
}

const CommandHeader: React.FC<CommandHeaderProps> = function ({
  title,
  subtitle,
  stepper,
  trailingIcon,
}) {
  const styles = useStyles({})
  return (
    <>
      <div className={styles.root} id="commandHeader">
        <Typography
          variant="overline"
          color="textPrimary"
          style={{ fontWeight: 'bold', fontSize: '0.9rem', lineHeight: 2 }}
        >
          {title}
        </Typography>
        {subtitle && (
          <div className={styles.subtitle}>
            {typeof subtitle === 'string' ? (
              <Typography variant="caption" color="inherit">
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </div>
        )}
        {trailingIcon && <div className={styles.trailingIcon}>{trailingIcon}</div>}
      </div>
      <div className={styles.stepper}>{stepper}</div>
    </>
  )
}

export default CommandHeader

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '0.9rem',
    padding: '1rem 2rem',
    position: 'relative',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(['box-shadow'], {
      duration: 200,
      easing: 'ease',
    }),
  },
  stepper: {
    borderBottom: '1.5px solid transparent',
    borderBottomColor: theme.palette.op.border.common,
  },
  trailingIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.palette.op.text.subtitle,
  },
}))
