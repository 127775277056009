import lightBase from './illustration-sign-up-port-number-light.png'
import light2x from './illustration-sign-up-port-number-light@2x.png'
import light3x from './illustration-sign-up-port-number-light@3x.png'
import darkBase from './illustration-sign-up-port-number-dark.png'
import dark2x from './illustration-sign-up-port-number-dark@2x.png'
import dark3x from './illustration-sign-up-port-number-dark@3x.png'

export default {
  light: {
    base: lightBase,
    x2: light2x,
    x3: light3x,
  },
  dark: {
    base: darkBase,
    x2: dark2x,
    x3: dark3x,
  },
}
