// @ts-strict-ignore
import { makeAutoObservable, observable } from 'mobx'
import { EditSnippetProps } from './edit-snippet'
import AppStore from '../store'
import { AccountCommandProps } from './account'
import { AppCommandProps } from './app'
import { BillingAutoChargeCommandProps } from './billing/auto-charge'
import { BillingCreditsCommandProps } from './billing/credits'
import { ChangeEmailCommandProps } from './change-email'
import { EmailCodeCommandProps } from './change-email/code'
import { ChangeEnvCommandProps } from './change-env'
import { ContactFilterCommandProps } from './contact-filters'
import { DialerCommandProps } from './dialer'
import { DoNotDisturbProps } from './do-not-disturb'
import { ForwardCallsProps } from './forward-calls'
import { ImportContactsProps } from './import-contacts'
import { IvrDestinationProps } from './ivr-destination'
import { MemberStatusCommandProps } from './member-status'
import { MutePhoneNumberProps } from './mute-phone-number'
import { PickOrgPhoneNumberProps } from './pick-org-phone-number'
import { PickUserPhoneNumberProps } from './pick-user-phone-number'
import { RejectWithMessageProps } from './reject-with-message'
import { RequiresPremiumProps } from './requires-premium'
import { SearchCommandProps } from './search'
import { SelectFileCommandProps } from './select-file'
import { SelectMemberCommandProps } from './select-member'
import { SetGreetingCommandProps } from './set-greeting'
import { SetScheduleProps } from './set-schedule'
import { SharePhoneNumberCommandProps } from './share-number'
import { ShareNumberRoleCommandProps } from './share-number/role'
import { SharedWithCommandProps } from './shared-with'
import { UploadCommandProps } from './upload'
import { VerifyDomainCommandProps } from './verify-domain/VerifyDomainCommand'
import { EditGroupCommandProps } from './edit-group/EditGroupCommand'
import {
  SendTestRequestCommandProps,
  TestRequestDetailsCommandProps,
} from '@src/app/command/send-test-request'

type Command =
  | ({ name: 'account' } & AccountCommandProps)
  | ({ name: 'app' } & AppCommandProps)
  | ({ name: 'billing auto charge' } & BillingAutoChargeCommandProps)
  | ({ name: 'billing credits' } & BillingCreditsCommandProps)
  | ({ name: 'change email code' } & EmailCodeCommandProps)
  | ({ name: 'change email' } & ChangeEmailCommandProps)
  | ({ name: 'change env' } & ChangeEnvCommandProps)
  | ({ name: 'contact filters' } & ContactFilterCommandProps)
  | ({ name: 'dialer' } & DialerCommandProps)
  | ({ name: 'do not disturb' } & DoNotDisturbProps)
  | ({ name: 'edit shared with' } & SharedWithCommandProps)
  | ({ name: 'edit snippet' } & EditSnippetProps)
  | ({ name: 'forward calls' } & ForwardCallsProps)
  | ({ name: 'import contacts' } & ImportContactsProps)
  | { name: 'invite member' }
  | { name: 'add domain' }
  | ({ name: 'verify domain' } & VerifyDomainCommandProps)
  | ({ name: 'member status' } & MemberStatusCommandProps)
  | ({ name: 'mute phone number' } & MutePhoneNumberProps)
  | ({ name: 'pick org phone number' } & PickOrgPhoneNumberProps)
  | ({ name: 'pick user phone number' } & PickUserPhoneNumberProps)
  | ({ name: 'reject call with message' } & RejectWithMessageProps)
  | ({ name: 'requires premium' } & RequiresPremiumProps)
  | ({ name: 'search' } & SearchCommandProps)
  | ({ name: 'select file' } & SelectFileCommandProps)
  | ({ name: 'select ivr destination' } & IvrDestinationProps)
  | ({ name: 'select member' } & SelectMemberCommandProps)
  | ({ name: 'set greeting' } & SetGreetingCommandProps)
  | ({ name: 'set schedule' } & SetScheduleProps)
  | ({ name: 'share phone number role' } & ShareNumberRoleCommandProps)
  | ({ name: 'share phone number' } & SharePhoneNumberCommandProps)
  | ({ name: 'upload' } & UploadCommandProps)
  | { name: 'referral' }
  | { name: 'report export' }
  | ({ name: 'edit group' } & EditGroupCommandProps)
  | { name: 'create webhook' }
  | ({ name: 'send test request' } & SendTestRequestCommandProps)
  | ({ name: 'test request details' } & TestRequestDetailsCommandProps)

export default class CommandUiStore {
  command: Command = null

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      command: observable.ref,
    })
  }

  get open(): boolean {
    return Boolean(this.command)
  }

  present = (command: Command) => {
    this.command = command
  }

  hide = () => {
    this.command = null
  }
}
