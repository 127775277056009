import React, { RefObject, useRef } from 'react'

export type MultipleRefs<T, Key extends string> = { [K in Key]: RefObject<T> }

export default function useMultipleRefs<T, K extends string>(
  keys: K[],
): RefObject<MultipleRefs<T, K>> {
  const ref = useRef<MultipleRefs<T, K>>({} as any) // TODO

  for (const key of keys) {
    ref.current[key] = React.createRef<T>()
  }

  return ref
}
