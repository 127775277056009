// @ts-strict-ignore
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { useAppStore } from '@src/app/context'
import UserTag from '../../component/user-tag'
import { CustomTooltip } from './area-chart'
import InfoButton from './info-button'

interface UsersChartProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  info?: string
  users: { id: string; data: { key: string; value: number }[] }[]
}

const UsersChart: React.FC<UsersChartProps> = function ({
  title,
  info,
  users,
  className,
  ...props
}) {
  const theme = useTheme()
  const styles = useStyles({})
  const { service } = useAppStore()
  const members = service.member.collection

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.header}>
        <span className={styles.title}>
          {title}
          <InfoButton text={info} />
        </span>
      </div>
      <div className={styles.charts}>
        {users
          ?.filter((u) => members.get(u.id))
          .map((u) => (
            <div key={u.id} className={styles.chart}>
              <div style={{ marginBottom: 10 }}>
                <UserTag size="tiny" identity={members.get(u.id)} />
              </div>
              <ResponsiveContainer height={125}>
                <BarChart data={u.data} className={styles.chart}>
                  <XAxis
                    dataKey="key"
                    tickLine={false}
                    interval="preserveStartEnd"
                    stroke={theme.palette.op.background.highlight(0.2)}
                  />
                  <Tooltip
                    cursor={{
                      fill: 'rgba(255, 255, 255, 0)',
                      stroke: theme.palette.op.background.highlight(0.35),
                      strokeWidth: 1,
                      strokeDasharray: '2 2',
                    }}
                    content={
                      <CustomTooltip
                        dataKeys={[
                          {
                            color: theme.palette.op.legacy.chart.green2,
                            key: 'value',
                            name: 'Activities',
                          },
                        ]}
                      />
                    }
                  />
                  <defs>
                    <linearGradient
                      id={`${u.id}Color`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="100%"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stopColor={theme.palette.op.legacy.chart.green2} />
                      <stop
                        offset="1"
                        stopColor={alpha(theme.palette.op.legacy.chart.green2, 0.5)}
                      />
                    </linearGradient>
                  </defs>
                  <Bar
                    dataKey="value"
                    strokeWidth={0.5}
                    stroke={theme.palette.op.legacy.chart.green2}
                    fill={`url(#${u.id}Color)`}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ))}
      </div>
    </div>
  )
}

export default UsersChart

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  charts: {
    margin: '1.5rem 1.5rem 0',
  },
  chart: {
    fontSize: 12,
    marginBottom: '1.5rem',
  },
  header: {
    padding: '1.5rem',
    background: theme.palette.op.background.highlight(0.02),
  },
  title: {
    display: 'block',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    color: theme.palette.op.background.highlight(0.6),
  },
}))
