// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { Collapsible, RenderOptionProps, SelectSearch } from '@src/component'
import Avatar from '@src/component/avatar'
import { useAppStore } from '@src/app/context'
import EventTypeOption from './EventTypeOption'
import Typography from '@ui/Typography'
import { Identity } from '../../../service/model'
import cx from 'classnames'
import { CheckBoxTickIcon } from '../../../component/icons/Tint/16/General'
import { observer } from 'mobx-react-lite'
import { compareString } from '../../../lib'
import useIsOverflowing from '@src/lib/useIsOverflowing'
import Tooltip from '@src/component/tooltip'
import CollapsibleFooterCard, { CollapsibleFooterHeader } from './CollapsibleFooterCard'
import SelectSearchTrigger from './SelectSearchTrigger'
import InfoCard from './InfoCard'
import { ContactEventTypes } from '@src/data'

type ContactOptionType = {
  name: string
  value: string
  symbol?: string
  identity?: Identity
}

interface ContactEventsProps {
  contactsAndGroups: string[]
  setContactsAndGroups: React.Dispatch<React.SetStateAction<string[]>>
  contactEventTypes: string[]
  setContactEventTypes: React.Dispatch<React.SetStateAction<string[]>>
  contactsCardDescriptionRoot?: string
}

const EXPANDER_VALUE = 'EXPAND_' as const
type ExpanderValue = `${typeof EXPANDER_VALUE}${string}`
const INITIAL_MAX_GROUPS_DISPLAYED = 3
const GROUPS_EXPANDER_VALUE: ExpanderValue = 'EXPAND_GROUPS'

const ContactEvents = ({
  contactsAndGroups,
  setContactsAndGroups,
  contactEventTypes,
  setContactEventTypes,
  contactsCardDescriptionRoot,
}: ContactEventsProps) => {
  const { service, workspace } = useAppStore()

  const styles = useStyles()

  const [maxGroupsDisplayed, setMaxGroupsDisplayed] = useState(
    INITIAL_MAX_GROUPS_DISPLAYED,
  )

  const rawGroupsOptions = useMemo(
    () =>
      workspace.groups
        .map((group) => ({
          name: group.name,
          value: group.id,
          symbol: group.symbol,
        }))
        .sort((group1, group2) =>
          compareString(group1.name, group2.name, { caseInsensitive: true }),
        ),
    [workspace.groups],
  )

  const contactsOptions = useMemo(
    () =>
      service.member.collection.list
        .map((member) => ({
          name: member.name,
          value: member.id,
          identity: member,
        }))
        .sort((member1, member2) =>
          compareString(member1.name, member2.name, { caseInsensitive: true }),
        ),
    [service.member.collection.list],
  )

  const options: ContactOptionType[] = useMemo(() => {
    const delta = rawGroupsOptions.length - maxGroupsDisplayed

    const groupOptions = rawGroupsOptions.slice(0, maxGroupsDisplayed)

    if (delta > 0) {
      const expandOption = {
        name: `${delta} more group${delta > 1 ? 's' : ''}`,
        value: GROUPS_EXPANDER_VALUE,
        symbol: '...',
      }
      groupOptions.push(expandOption)
    }

    return [...groupOptions, ...contactsOptions]
  }, [contactsOptions, maxGroupsDisplayed, rawGroupsOptions])

  const isAllSelected =
    contactsOptions.length + rawGroupsOptions.length === contactsAndGroups.length

  const selectedContactsAndGroups = options.filter((option) =>
    contactsAndGroups.includes(option.value),
  )

  const handleUsersAndGroupsSelected = (options: ContactOptionType[]) => {
    /**
     * As there's no way to place a button in the search selector we fake
     * a button by creating a new option type with an specific value,
     * GROUP_EXPANDER_VALUE
     *
     * If among the options selected is present an option whose value is
     * GROUP_EXPANDER_VALUE we know it was clicked, therefore we can increase
     * the limit of groups displayed and filter it from the actual options
     */
    const hasExpandGroups = options.find(
      (option) => option.value === GROUPS_EXPANDER_VALUE,
    )

    if (hasExpandGroups) {
      setMaxGroupsDisplayed((current) => current + INITIAL_MAX_GROUPS_DISPLAYED)
    }

    const newSelectedOptions = options
      .filter((option) => !option.value.includes(EXPANDER_VALUE))
      .map((option) => option.value)

    setContactsAndGroups(newSelectedOptions)
  }

  const handleUsersAndGroupsOptionName = (option: ContactOptionType[]): string => {
    const selectedUsersNumber = selectedContactsAndGroups.filter(
      (selected) => selected.identity,
    ).length
    const selectedGroupsNumber = selectedContactsAndGroups.filter(
      (selected) => selected.symbol,
    ).length

    const groupsLabel = selectedGroupsNumber === 1 ? 'group' : 'groups'
    const usersLabel = selectedUsersNumber === 1 ? 'user' : 'users'

    if (option.length === 0) {
      return 'Select'
    }

    if (isAllSelected) {
      return 'All users and groups'
    }

    if (selectedUsersNumber === 0) {
      return `${selectedGroupsNumber} ${groupsLabel}`
    }

    if (selectedGroupsNumber === 0) {
      return `${selectedUsersNumber} ${usersLabel}`
    }

    return `${selectedUsersNumber} ${usersLabel} · ${selectedGroupsNumber} ${groupsLabel}`
  }

  const handleSelectAllUsersAndGroups = (_, selectAll: boolean) => {
    if (selectAll) {
      setContactsAndGroups(
        [...rawGroupsOptions, ...contactsOptions].map((option) => option.value),
      )
    } else {
      setContactsAndGroups([])
    }
  }

  const handleContactsOnChange =
    (eventName: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setContactEventTypes((eventTypes) => [...eventTypes, eventName])
      } else {
        setContactEventTypes((eventTypes) =>
          eventTypes.filter((event) => event !== eventName),
        )
      }
    }

  const handleRenderOptionUsersAndGroups = ({
    option,
    index,
    rootOptionProps,
  }: RenderOptionProps<ContactOptionType>) => {
    // ok. this is hack. but no idea about a better one. mui you know 🤷
    const isSelected = rootOptionProps['aria-selected']
    const isExpander = option.value.includes(EXPANDER_VALUE)
    const isGroup = 'symbol' in option
    const { isOverflowing, ref: optionRef } = useIsOverflowing()

    return (
      <div
        {...rootOptionProps}
        key={index}
        className={cx(
          styles.contactsOption,
          isSelected && styles.contactsOptionSelected,
          isExpander && styles.contactOptionsExpander,
          isGroup && styles.contactOptionsGroup,
        )}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {option.symbol ? (
            <Typography variant="footnote" className={styles.emoji}>
              {option.symbol}
            </Typography>
          ) : (
            <span className={styles.avatar}>
              <Avatar size="tiny" identity={option.identity} />
            </span>
          )}

          <Tooltip title={isOverflowing ? option.name : null}>
            <Typography
              variant="footnote"
              nowrap
              className={cx(
                styles.optionName,
                isSelected && styles.contactsOptionSelectedTxt,
              )}
              ref={optionRef}
            >
              {option.name}
            </Typography>
          </Tooltip>
        </div>

        {isSelected && <CheckBoxTickIcon className={styles.contactsOptionSelectedIcon} />}
      </div>
    )
  }

  const numberOfSelectedContactEventTypes = contactEventTypes.length
  return (
    <Collapsible
      rootClassName={styles.contactsRoot}
      title={() => (
        <div className={styles.title}>
          <Typography variant="callout" color="textPrimary">
            Contacts
          </Typography>
          <Typography variant="footnote" color="placeholder">
            Listen to events based on changes to contacts
          </Typography>
        </div>
      )}
      actionText={
        numberOfSelectedContactEventTypes > 0
          ? `${numberOfSelectedContactEventTypes} selected`
          : undefined
      }
    >
      <>
        {ContactEventTypes.map((event) => (
          <EventTypeOption
            key={event.name}
            name={event.name}
            description={event.description}
            isChecked={contactEventTypes.includes(event.name)}
            onChange={handleContactsOnChange(event.name)}
          />
        ))}
        <CollapsibleFooterCard>
          <CollapsibleFooterHeader
            title="Receive updates from all users"
            subtitle="Turn this off to manually select which users to receive updates from"
            areAllSelected={isAllSelected}
            onAllSelected={handleSelectAllUsersAndGroups}
          />
          {isAllSelected ? (
            <InfoCard>
              The webhook will receive updates from all users in your workspace including
              new users as they are added
            </InfoCard>
          ) : (
            <SelectSearch
              dropdownProps={{
                placeholder: 'Search users or groups…',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              multiple={true}
              value={selectedContactsAndGroups}
              getOptionName={handleUsersAndGroupsOptionName}
              onChange={handleUsersAndGroupsSelected}
              options={options}
              renderOption={handleRenderOptionUsersAndGroups}
              renderTrigger={SelectSearchTrigger}
            />
          )}
        </CollapsibleFooterCard>
      </>
    </Collapsible>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    icon: {
      marginRight: 12,
    },
    selectSearchFooter: {
      boxShadow: `0 -1.5px 0 0 ${theme.palette.op.border.common}`,
      padding: '11px 14px',
      marginTop: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    contactsOption: {
      marginTop: 2,
      padding: '7px 11px',
      width: 232,
      height: 30,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 5,
      cursor: 'pointer',

      '&[data-focus="true"]': {
        backgroundColor: theme.palette.op.tag.purple.bgHover,
      },
    },
    contactsOptionSelected: {
      backgroundColor: theme.palette.op.tag.purple.bg,
    },
    contactOptionsExpander: {
      color: theme.palette.op.gray[2],
      background: 'none',
      position: 'relative',
      '&[data-focus="true"]': {
        background: 'none',
      },
    },
    contactOptionsGroup: {
      '& + :not(&)': {
        position: 'relative',
        marginTop: 12,
        '&:before': {
          content: '""',
          position: 'absolute',
          height: '1.5px',
          background: theme.palette.op.gray[5],
          top: -6,
          left: -4,
          width: `calc(100% + 8px)`,
        },
      },
    },
    contactsOptionSelectedTxt: {
      color: theme.palette.op.tag.purple.text,
    },
    contactsOptionSelectedIcon: {
      color: theme.palette.op.tag.purple.text,
    },
    optionName: {},
    contactsRoot: {
      marginTop: 2,
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
    },
    avatar: { paddingRight: 8 },
    emoji: {
      boxSizing: 'content-box',
      paddingRight: 8,
      height: 18,
      width: 18,
    },
  }),
  { name: ContactEvents.name },
)

export default observer(ContactEvents)
