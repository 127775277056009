// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import ObjectID from '../../lib/object-id'
import { Model } from './base'

const supportedTypes = [
  'address',
  'boolean',
  'date',
  'multi-select',
  'number',
  'string',
  'url',
]

type TemplateType =
  | 'address'
  | 'boolean'
  | 'date'
  | 'multi-select'
  | 'number'
  | 'string'
  | 'url'

export interface IContactTemplateItem {
  createdAt?: number
  id?: string
  key?: string
  name?: string
  options?: any
  order?: number
  orgId?: string
  type: TemplateType
  updatedAt?: number
}

export class ContactTemplateItem implements IContactTemplateItem, Model {
  createdAt: number = Date.now()
  id: string = ObjectID()
  key: string = null
  name: string = null
  options: any = {}
  order: number = null
  orgId: string = null
  type: TemplateType = null
  updatedAt: number = Date.now()
  local: boolean = false

  constructor(private service: Service, attrs: Partial<ContactTemplateItem> = {}) {
    Object.assign(this, { id: ObjectID(), key: ObjectID() }, attrs)
    this.setDefaultOption(this.type)
    makeAutoObservable(this, {})
  }

  get isSupportedType() {
    return supportedTypes.includes(this.type)
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    return this.service.contact.updateTemplate(this)
  }

  delete() {
    return this.service.contact.deleteTemplate(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    this.setDefaultOption(json.type, json.options)
    this.updatedAt = parseDate(json.updatedAt)
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      key: this.key,
      name: this.name,
      options: toJS(this.options),
      order: this.order,
      orgId: this.orgId,
      type: this.type,
      updatedAt: this.updatedAt,
    }
  }

  private setDefaultOption(type: TemplateType, options?: any) {
    if (options || this.options) {
      this.options = options || this.options
    } else if (type === 'multi-select') {
      this.options = []
    } else {
      this.options = {}
    }
  }
}
