// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Drawer from '../../component/drawer'
import useKeyboardShortcuts, {
  defaultWithInputOnScreen,
} from '../../lib/use-keyboard-shortcuts'
import { useSize } from '../../lib/use-size'
import Conversation from './conversation'
import ConversationList from './conversations'
import InboxDetailsView from './details'

interface InboxViewProps {}

const InboxView: React.FC<InboxViewProps> = function ({}) {
  const styles = useStyles({})
  const { inboxes } = useAppStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const conversationRef = useRef<HTMLDivElement>(null)
  const [compressed, setCompressed] = useState(false)

  useKeyboardShortcuts({
    node: document,
    name: 'open-details',
    filter: defaultWithInputOnScreen({
      allowedShortcutsWhenEmpty: ['ArrowRight', 'ArrowLeft'],
    }),
    handler: (shortcut, event) => {
      if (!compressed) return

      if (shortcut === 'ArrowRight') {
        inboxes.detailsOpen ? inboxes.closeDetails() : inboxes.openDetails()
        event.preventDefault()
      } else if (shortcut === 'ArrowLeft' && inboxes.detailsOpen) {
        inboxes.closeDetails()
        event.preventDefault()
      }
    },
    dep: [compressed],
  })

  useSize(
    rootRef,
    (size) => {
      const shouldBeCompressed = size.width < 1150
      if (shouldBeCompressed !== compressed) {
        setCompressed(shouldBeCompressed)
      }
    },
    [compressed],
  )

  return (
    <div ref={rootRef} className={styles.root}>
      {!inboxes.selected?.directNumber && <ConversationList compressed={compressed} />}
      <div className={styles.conversation} ref={conversationRef}>
        <Conversation showDetailsButton={compressed} />
        {!compressed ? (
          <InboxDetailsView compressed={compressed} />
        ) : (
          <Drawer
            anchor="right"
            open={inboxes.detailsOpen}
            onClose={inboxes.closeDetails}
          >
            <InboxDetailsView compressed={false} />
          </Drawer>
        )}
      </div>
    </div>
  )
}

export default observer(InboxView)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    minWidth: 0,
  },
  conversation: {
    flex: 1,
    display: 'flex',
  },
}))
