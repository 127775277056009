import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { useOnboardingState } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import Checkbox from '../../component/checkbox-v2'
import { NativeSelect } from '../../component/select'
import { Input, Label } from '../../component/textfield'
import TextField from '../../component/textfield-v2'
import Typography from '@ui/Typography'
import useInputState from '../../lib/use-input-state'
import { Card } from './common'

const Company: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const state = useOnboardingState()
  const [name, setName] = useInputState(state.org.name ?? '')
  const [industry, setIndustry] = useState(state.org.industry ?? '')
  const [title, setTitle] = useState(state.org.membership?.title || '')
  const [companySize, setCompanySize] = useState(state.org.employees ?? '')

  const [shouldWhitelistDomain, setShouldWhitelistDomain] = useState(true)

  const canAddDomain =
    !service.user.current.analytics?.clearbit?.email?.freeProvider &&
    !service.user.current.analytics?.clearbit?.email?.disposable &&
    !service.user.current.analytics?.clearbit?.email?.blacklisted
  const domainName = useMemo(
    () =>
      service.user.current.email.substring(
        service.user.current.email.lastIndexOf('@') + 1,
      ),
    [service.user.current.email],
  )

  const handleChange = (setter: (v: string) => void) => (event) => {
    setter(event.target.value)
  }

  const continuePressed = () => {
    let domain: string | undefined = undefined

    if (canAddDomain && shouldWhitelistDomain) {
      domain = service.user.current.email
    }

    state.updateOrgAndAdvance(
      {
        name,
        membership: {
          ...state.org.membership,
          title,
        },
        analytics: {
          ...state.org.analytics,
          useCase: 'team',
          industry,
          teamSize: companySize,
        },
      },
      domain,
    )
  }

  return (
    <Card
      emoji="🏬"
      title="Your company"
      description="Tell us about your company. You will be able to invite your teammates in the next step."
    >
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 24 }}>
        <TextField label="Workspace name" autoFocus value={name} onChange={setName} />

        <FormControl className={styles.form}>
          <Label shrink id="size">
            Team size
          </Label>
          <NativeSelect
            id="size"
            required
            value={companySize}
            input={<Input />}
            onChange={handleChange(setCompanySize)}
          >
            <option value="" disabled hidden>
              Select your option
            </option>
            {employeeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </NativeSelect>
        </FormControl>

        <FormControl className={styles.form}>
          <Label shrink id="industry">
            Industry (optional)
          </Label>
          <NativeSelect
            id="industry"
            required
            input={<Input />}
            value={industry}
            onChange={handleChange(setIndustry)}
          >
            <option value="" disabled hidden>
              Select your option
            </option>
            {industryOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.icon} {option.label}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={styles.form}>
          <Label shrink id="role">
            Your role (optional)
          </Label>
          <NativeSelect
            required
            id="role"
            value={title}
            input={<Input />}
            onChange={handleChange(setTitle)}
          >
            <option value="" disabled hidden>
              Select your option
            </option>
            {roleOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.icon} {option.label}
              </option>
            ))}
          </NativeSelect>
        </FormControl>

        {canAddDomain && (
          <FormControl className={styles.domainContainer}>
            <Checkbox
              checked={shouldWhitelistDomain}
              onChange={() => setShouldWhitelistDomain((value) => !value)}
            />
            <Typography
              color="textSecondary"
              variant="footnote"
              style={{ marginLeft: 11 }}
              onClick={() => setShouldWhitelistDomain((value) => !value)}
            >
              Allow anyone with an{' '}
              <Typography
                color="textPrimary"
                variant="footnote"
                style={{ display: 'inline-block' }}
                fontWeight="bold"
              >
                {domainName}
              </Typography>{' '}
              email to sign up and join your Workspace
            </Typography>
          </FormControl>
        )}
      </div>
      <Button
        fullWidth
        onClick={continuePressed}
        variant="contained"
        color="primary"
        disabled={!name || !companySize}
      >
        Continue
      </Button>
    </Card>
  )
}

export default observer(Company)

const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: 24,
    marginTop: 20,
  },
  domainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 20,
  },
}))

const employeeOptions = [
  {
    label: '2-5',
    value: '2-5',
  },
  {
    label: '6-9',
    value: '6-9',
  },
  {
    label: '10-49',
    value: '10-49',
  },
  {
    label: '50+',
    value: '50+',
  },
]

const industryOptions = [
  {
    icon: '🎨',
    label: 'Creative Agency',
    value: 'creative agency',
  },
  {
    icon: '🛒',
    label: 'E-commerce',
    value: 'ecommerce',
  },
  {
    icon: '💵',
    label: 'Financial Services',
    value: 'financial services',
  },
  {
    icon: '💊',
    label: 'Health / Wellness',
    value: 'health',
  },
  {
    icon: '⚖️',
    label: 'Legal',
    value: 'legal',
  },
  {
    icon: '🛍',
    label: 'Retail',
    value: 'retail',
  },
  {
    icon: '🏡',
    label: 'Real Estate',
    value: 'real estate',
  },
  {
    icon: '📱',
    label: 'Technology',
    value: 'technology',
  },
  {
    icon: '👷',
    label: 'Trade Contractor',
    value: 'trade contractor',
  },
  {
    icon: '🤔',
    label: 'Other',
    value: 'other',
  },
]

const roleOptions = [
  {
    icon: '💵',
    label: 'Accounting / Finance',
    value: 'finance',
  },
  {
    icon: '🤝',
    label: 'Business Development',
    value: 'business dev',
  },
  {
    icon: '🎯',
    label: 'Owner / Founder / CEO',
    value: 'owner or ceo',
  },
  {
    icon: '📞',
    label: 'Customer Support',
    value: 'customer support',
  },
  {
    icon: '🎉',
    label: 'Events',
    value: 'events',
  },
  {
    icon: '⚖️',
    label: 'Legal',
    value: 'legal',
  },
  {
    icon: '📣',
    label: 'Marketing',
    value: 'marketing',
  },
  {
    icon: '⚙️',
    label: 'Operations',
    value: 'operations',
  },
  {
    icon: '☕️',
    label: 'Recruiting',
    value: 'recruiting',
  },
  {
    icon: '📈',
    label: 'Sales',
    value: 'sales',
  },
  {
    icon: '🤔',
    label: 'Other',
    value: 'other',
  },
]
