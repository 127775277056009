// @ts-strict-ignore
import { ModalProps } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useAppStore } from '@src/app/context'
import EmojiPicker from '../component/emoji-picker'
import type { EmojiPickerProps } from '../component/emoji-picker'

export { EmojiPickerProps }

const AppEmojiPicker: React.FC<{}> = function ({}) {
  const { emojiPicker, hideEmojiPicker, service } = useAppStore()
  const { onClose, onEmojiSelected, ...props } = emojiPicker

  const handleEmojiSelected = useCallback(
    (emoji: string) => {
      onEmojiSelected?.(emoji)
      hideEmojiPicker()
    },
    [onEmojiSelected, hideEmojiPicker],
  )

  const handleClose = useCallback<ModalProps['onClose']>(
    (...args) => {
      hideEmojiPicker()
      onClose?.(...args)
    },
    [hideEmojiPicker, onClose],
  )

  return (
    <EmojiPicker
      {...props}
      onClose={handleClose}
      onEmojiSelected={handleEmojiSelected}
      skinTone={service.emoji.skinTone}
    />
  )
}

export default observer(AppEmojiPicker)
