// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import Checkbox from '../../component/checkbox'
import { formatted } from '../../lib/phone-number'
import { searchPhoneNumbers } from '../../lib/search'
import { highlight } from '../../lib/string'
import useInputState from '../../lib/use-input-state'
import useKeyStepper from '../../lib/use-key-stepper'
import { EntityPhoneNumber, PhoneNumber } from '../../service/model'
import Content from './common/content'
import Footer from './common/footer'
import FooterButton from './common/footer-button'
import Item from './common/item'
import Textfield from './common/textfield'
import { AppAvatarGroup } from '@src/app/components'

export interface OpPhoneNumberSelectorProps<T extends EntityPhoneNumber | PhoneNumber> {
  phoneNumbers: T[]
  multi?: boolean
  initialSelectedIds?: string[]
  onAdd: () => void
  onSelect: (phoneNumbers: T[]) => void
}

const OpPhoneNumberSelector: <T extends EntityPhoneNumber | PhoneNumber>(
  p: OpPhoneNumberSelectorProps<T>,
) => React.ReactElement<OpPhoneNumberSelectorProps<T>> = function ({
  phoneNumbers,
  multi,
  initialSelectedIds = [],
  onAdd,
  onSelect,
}) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const members = service.member.collection
  const [items, setItems] = useState<typeof phoneNumbers>([])
  const [search, setSearch] = useInputState('')
  const [selected, setSelected] = useState<string[]>(initialSelectedIds)

  const { getItemProps, selectedIndex } = useKeyStepper({
    items,
    name: 'command/op-phone-number-selector',
    handleSelect: (number) => {
      if (multi) {
        handleSelectedChanged(number.id)
      } else if (number !== null) {
        onSelect([number])
      }
    },
  })

  useEffect(() => {
    if (!search) return setItems(phoneNumbers)
    searchPhoneNumbers(search, phoneNumbers).then(setItems)
  }, [search, phoneNumbers])

  const handleSelectedChanged = function (id: string) {
    if (selected.indexOf(id) > -1) {
      setSelected(selected.filter((i) => i !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelect = function () {
    onSelect(phoneNumbers.filter((p) => selected.includes(p.id)))
  }

  const allSelected = phoneNumbers.every((p) => selected.includes(p.id))

  const handleSelectAll = function () {
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(phoneNumbers.map((p) => p.id))
    }
  }

  return phoneNumbers.length === 0 ? (
    <>
      <Content>
        <div className={styles.empty}>
          <Typography variant="body1" color="textPrimary">
            You don't have any other numbers in your workspace.
          </Typography>
        </div>
      </Content>
      <Footer
        actions={
          <FooterButton variant="contained" color="primary" onClick={onAdd}>
            Purchase a new number
          </FooterButton>
        }
      />
    </>
  ) : (
    <>
      <Textfield autoFocus placeholder="Search" value={search} onChange={setSearch} />
      <Content>
        {items.map((phoneNumber, index) => (
          <Item
            {...getItemProps(index)}
            key={phoneNumber.id}
            highlighted={selectedIndex === index}
          >
            <div className={styles.item}>
              {multi && (
                <Checkbox
                  className={styles.checkbox}
                  checked={selected.indexOf(phoneNumber.id) > -1}
                  onChange={() => handleSelectedChanged(phoneNumber.id)}
                />
              )}
              <AppAvatarGroup
                identities={phoneNumber.members}
                className={styles.avatar}
              />
              <div style={{ flex: 1, minWidth: 0 }}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={styles.names}
                  dangerouslySetInnerHTML={{
                    __html: highlight(phoneNumber.userNames, search),
                  }}
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  dangerouslySetInnerHTML={{
                    __html: highlight(phoneNumber.name, search),
                  }}
                />
              </div>
              <Typography
                variant="caption"
                color="textPrimary"
                className={styles.number}
                dangerouslySetInnerHTML={{
                  __html: highlight(formatted(phoneNumber.number), search),
                }}
              />
            </div>
          </Item>
        ))}
      </Content>
      {multi && (
        <Footer
          actions={
            <FooterButton
              disabled={selected.length === 0}
              variant="contained"
              color="primary"
              onClick={handleSelect}
            >
              {selected.length > 0 ? `Select ${selected.length} numbers` : 'Select'}
            </FooterButton>
          }
        >
          {multi && (
            <Button color="secondary" onClick={handleSelectAll}>
              {allSelected ? 'Unselect All' : 'Select All'}
            </Button>
          )}
        </Footer>
      )}
    </>
  )
}

export default observer(OpPhoneNumberSelector)

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: 15,
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
  },
  checkbox: {
    margin: '0 15px 0 -7px',
  },
  names: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  number: {
    marginLeft: 16,
    flex: '0 0 120px',
    textAlign: 'right',
  },
  empty: {
    padding: '1rem 2rem',
  },
}))
