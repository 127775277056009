// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useImperativeHandle, useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import { InputBarDeserializer } from '../../input-bar'
import Comments from '../../../component/comments'
import Feed from '../../../component/feed'
import { useDebouncedValue, useReaction } from '../../../lib/hooks'
import Mention from '../../mention'
import { CommentFeedItem } from './activity/comments/controller'
import { ConversationFeedController, FeedItem } from './feed'

interface ActivityListProps {}

export interface ActivityListMethods {
  scrollToBottom(): void
}

const ActivityList: React.ForwardRefRenderFunction<
  ActivityListMethods,
  ActivityListProps
> = function ({}, outerRef) {
  const styles = useStyles({})
  const app = useAppStore()
  const { conversation: conversationStore } = app
  const conversation = useDebouncedValue(() => conversationStore.current)
  const controller = useMemo(() => new ConversationFeedController(app), [])

  useReaction(
    () => conversationStore.anchorIndex,
    (index) => {
      if (index !== null && index >= 0) {
        controller.scrollToIndex(conversationStore.anchorIndex)
      }
    },
    { fireImmediately: true },
    [controller, conversationStore],
  )

  useImperativeHandle(
    outerRef,
    () => ({
      scrollToBottom: () => controller.scrollToBottom(),
    }),
    [controller, conversationStore],
  )

  const renderMention = useCallback(
    <I extends CommentFeedItem | FeedItem>(mention: string, item?: I) => (
      <Mention
        key={mention}
        className={item?.isOutgoing ? styles.outgoingMention : null}
        id={mention}
        fallback={
          item
            ? InputBarDeserializer.getNameById(app, mention, item.source.enrichment)
            : null
        }
      />
    ),
    [],
  )

  const renderComments = useCallback(
    (item: FeedItem) => (
      <Comments controller={item.thread.controller} renderMention={renderMention} />
    ),
    [renderMention],
  )

  return (
    <Feed
      controller={controller}
      pinToIndex={conversationStore.anchorIndex}
      renderComments={renderComments}
      renderMention={conversation.isDirect ? renderMention : null}
      onClick={conversationStore.markRead}
    />
  )
}

export default observer(ActivityList, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    outgoingMention: {
      backgroundColor: theme.palette.op.mention.chatBubbleBg,
      color: theme.palette.op.white,
    },
  }),
  { name: ActivityList.name },
)
