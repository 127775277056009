import { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'

const useWindowSize = () => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect((): (() => void) | void => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    const sub = fromEvent(window, 'resize').subscribe(handler)

    return () => {
      sub.unsubscribe()
    }
  }, [])

  return state
}

export default useWindowSize
