import config from '../../config'
import ApiClient from './client'
import type { RequestPromise, RequestConfig } from './client'
export { RequestConfig, RequestPromise }

export const client = new ApiClient()

export function upload(
  file: File,
  onProgress?: (loaded: number, total: number) => void,
): Promise<string> {
  return client
    .post(`${config.COMMUNICATION_SERVICE_URL}/upload/url`, { type: file.type })
    .then((url) => {
      const config: RequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progress: ProgressEvent) => {
          onProgress && onProgress(progress.loaded, progress.total)
        },
      }
      return client.put(url, file, config).then(() => url.split('?')[0])
    })
}
