// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import Input from '@ui/TextInput'
import Typography from '@ui/Typography'
import { compareString } from '../../lib'
import { fuzzySearch } from '../../lib/search'
import useInputState from '../../lib/use-input-state'
import { Blocklist } from '../../service/model'
import { Header, Page } from './common'
import { Card } from './settings-card'

interface BlocklistViewProps {}

const BlocklistView: React.FC<BlocklistViewProps> = function ({}) {
  const styles = useStyles({})
  const { service, toast } = useAppStore()
  const [search, setSearch] = useInputState('')
  const blocklist = service.blocklist.collection.list

  const filtered = useMemo(() => {
    if (!search) {
      return blocklist.sort((a, b) => compareString(a.phoneNumber, b.phoneNumber))
    }
    return fuzzySearch(blocklist, search, ['formatted'])
  }, [blocklist, search])

  const rowRenderer = (item: Blocklist) => {
    return (
      <div key={item.id} className={styles.row}>
        <Typography variant="callout" color="textPrimary">
          {item.formatted}
        </Typography>
        <Button
          size="small"
          className={styles.deleteButton}
          onClick={() => item.delete().catch(toast.showError)}
        >
          Remove
        </Button>
      </div>
    )
  }

  return (
    <Page className={styles.root}>
      <Header
        title="Blocklist"
        subtitle="Your list of blocked phone numbers"
        style={{ marginBottom: '1rem' }}
      />
      <Card elevation={3}>
        <div className={styles.searchBar}>
          <Input
            size={35}
            fullWidth
            value={search}
            onChange={setSearch}
            placeholder="Search for a number"
          />
        </div>

        <div style={{ paddingBottom: 20 }}>
          {filtered.length === 0 ? (
            <Typography
              variant="callout"
              color="textSecondary"
              className={styles.emptyList}
            >
              No one's blocked, woohoo 🌞
            </Typography>
          ) : (
            filtered.map(rowRenderer)
          )}
        </div>
      </Card>
    </Page>
  )
}

export default observer(BlocklistView)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    marginBottom: '1rem',
  },
  searchBar: {
    padding: '2rem 2rem 20px',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    padding: '0 2rem',

    '&:hover $deleteButton': {
      visibility: 'visible',
    },
  },
  deleteButton: {
    visibility: 'hidden',
    marginLeft: 10,
  },
  emptyList: {
    padding: '0 2rem',
  },
}))
