// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '../..'
import { Model } from '../base'

export class WebhookIntegration implements Model {
  id: string = null
  type: 'webhook' = 'webhook'
  orgId: string = null
  userId: string = null
  url: string = null
  method: 'post' | 'get' | 'put' = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      orgId: this.orgId,
      userId: this.userId,
      url: this.url,
      method: this.method,
    }
  }
}
