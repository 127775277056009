// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { MutableRefObject, useLayoutEffect } from 'react'
import useCombinedRefs from '../../../lib/use-combined-ref'

export interface ItemProps extends React.HTMLProps<HTMLDivElement> {
  hover?: boolean
  highlighted?: boolean
  innerRef?: MutableRefObject<HTMLDivElement>
  icon?: React.ReactNode
}

const Item: React.FC<ItemProps> = function ({
  className,
  children,
  icon,
  hover,
  innerRef,
  highlighted,
  ...props
}) {
  const styles = useStyles({})
  const ref = useCombinedRefs<HTMLDivElement>(innerRef)

  useLayoutEffect(() => {
    if (highlighted) {
      ref.current.scrollIntoView({ block: 'nearest' })
    }
  }, [highlighted])

  return (
    <div
      {...props}
      ref={ref}
      className={cx(
        styles.root,
        hover && styles.hover,
        highlighted && styles.highlighted,
        className,
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </div>
  )
}

interface CommandHeaderItemProps extends React.HTMLProps<HTMLDivElement> {}

export const HeaderItem: React.FC<CommandHeaderItemProps> = function ({
  className,
  ...props
}) {
  const styles = useStyles({})
  return <div {...props} className={cx(styles.header, className)} />
}

export default Item

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 45,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 2rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transformStyle: 'preserve-3d',
    fontSize: 15,
    margin: '1px 0',

    '& em': {
      color: theme.palette.op.match({
        dark: theme.palette.op.primary[3],
        light: theme.palette.op.legacy.commandItem.purple1,
      }),
      fontWeight: theme.palette.op.match({
        dark: 400,
        light: 600,
      }),
      fontStyle: 'normal',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 10,
      left: 10,
      bottom: 0,
      background: theme.palette.op.hover.primary,
      opacity: 0,
      borderRadius: 5,
      transform: 'translateZ(-1px)',
    },
  },
  icon: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 14,
    color: theme.palette.op.text.secondary,
  },
  highlighted: {
    '&:before': {
      opacity: 1,
    },
  },
  hover: {
    '&:hover:before': {
      opacity: 1,
    },
  },
  header: {
    fontSize: '0.80rem',
    color: theme.palette.op.text.secondary,
    padding: '25px 2rem 10px',
  },
}))
