// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import ClosedIcon from '@material-ui/icons/NightsStayOutlined'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import OpenIcon from '@material-ui/icons/WbSunnyOutlined'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import keyboard from '../../../lib/keyboard'
import useInputState from '../../../lib/use-input-state'
import { AvailabilitySchedule } from '../../../types'
import Content from '../common/content'
import Item from '../common/item'
import TextField from '../common/textfield'

export interface ScheduleProps {
  defaultValue?: AvailabilitySchedule
  onSelect: (schedule: AvailabilitySchedule) => void
}

const timeToValue = (time: string) => {
  return time.slice(0, 2) + ':' + time.slice(2)
}

const Schedule: React.FC<ScheduleProps> = function ({ defaultValue, onSelect }) {
  const styles = useStyles({})

  let defaultStart: string, defaultEnd: string
  if (!defaultValue || defaultValue?.start === defaultValue.end) {
    defaultStart = '0800'
    defaultEnd = '2000'
  } else {
    defaultStart = defaultValue.start
    defaultEnd = defaultValue.end
  }

  const [start, setStart] = useInputState(timeToValue(defaultStart))
  const [end, setEnd] = useInputState(timeToValue(defaultEnd))

  const saveTime = () => {
    const s = start.replace(/:/g, '')
    const e = end.replace(/:/g, '')
    onSelect({ start: s, end: e })
  }

  const items = [
    {
      icon: <SaveIcon color="action" fontSize="small" />,
      title: 'Save',
      onSelect: saveTime,
    },
    {
      icon: <ClosedIcon color="action" fontSize="small" />,
      title: 'Closed all day',
      onSelect: () => {
        onSelect(null)
      },
    },
    {
      icon: <OpenIcon color="action" fontSize="small" />,
      title: 'Open all day',
      onSelect: () => {
        onSelect({ start: '0000', end: '0000' })
      },
    },
  ]

  return (
    <Content>
      <div style={{ display: 'flex' }}>
        <Typography
          component="label"
          variant="footnote"
          className={styles.label}
          color="textSecondary"
          htmlFor="start-time"
        >
          Start
        </Typography>
        <Typography
          component="label"
          variant="footnote"
          className={styles.label}
          color="textSecondary"
          htmlFor="end-time"
        >
          End
        </Typography>
      </div>
      <div className={styles.inputWrapper}>
        <TextField
          id="start-time"
          type="time"
          className={styles.input}
          value={start}
          onChange={setStart}
          autoFocus
          step="600"
        />
        <TextField
          id="end-time"
          type="time"
          className={styles.input}
          value={end}
          onChange={setEnd}
          step="600"
          onKeyDown={keyboard.onEnter(saveTime)}
        />
      </div>
      <div>
        {items.map((item) => (
          <Item key={item.title} hover onClick={item.onSelect}>
            {item.icon}
            <Typography variant="body" color="textPrimary" style={{ marginLeft: '1rem' }}>
              {item.title}
            </Typography>
          </Item>
        ))}
      </div>
    </Content>
  )
}

export default Schedule

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    flex: 1,
    padding: '20px 2rem 0',
  },
  inputWrapper: {
    display: 'flex',
    paddingBottom: 30,
  },
  input: {
    flex: 1,

    '& input': {
      margin: '0 2rem',
      padding: '10px 0',
      borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
    },
  },
}))
