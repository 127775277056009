import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
interface CardProps extends React.HTMLProps<HTMLDivElement> {
  emoji: React.ReactNode
  title: string
  description: React.ReactNode
  contentClassName?: string
}

export const Card: React.FC<CardProps> = function ({
  emoji,
  title,
  description,
  children,
  className,
  contentClassName,
  ...props
}) {
  const styles = useStyles({})
  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.header}>
        {emoji && (
          <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
            {emoji}
          </Typography>
        )}
        {title && (
          <Typography variant="largeTitle" style={{ marginBottom: 10 }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
      <div className={cx(styles.content, contentClassName)}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    marginBottom: 40,
    width: 320,
  },
  content: {
    width: 320,
  },
}))
