// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useBackdropClick, useEditor } from '.'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import { logError } from '../../../lib/log'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import Input from './input'
import { List, ListItem } from './list'

export interface EditCompanyNameProps {
  defaultValue: string
  onSave: (name: string) => void
}

const EditCompanyName: React.FC<EditCompanyNameProps> = function ({
  defaultValue,
  onSave,
}) {
  const [, hide] = useEditor()
  const { service } = useAppStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const [input, setInput] = useInputState(defaultValue)
  const [names, setNames] = useState<string[]>([])

  const handleSave = () => {
    onSave(input)
    hide()
  }

  useBackdropClick(handleSave, [input])

  const { getItemProps, selectedIndex } = useKeyStepper({
    items: names,
    node: rootRef.current,
    defaultSelectedIndex: 0,
    name: 'contact/editor/company',
    handleSelect: (item) => {
      if (item === null) {
        onSave(input)
      } else {
        onSave(item)
      }
      hide()
    },
  })

  useEffect(() => {
    service.search
      .companies(input, 5)
      .then((names) => {
        if (names.includes(input) || !input) {
          setNames(names)
        } else {
          setNames([null, ...names])
        }
      })
      .catch(logError)
  }, [input])

  return (
    <div ref={rootRef}>
      <Input
        autoFocus
        type="text"
        value={input}
        placeholder="Enter a name"
        onChange={setInput}
      />
      {names.length > 0 && (
        <List>
          {names.map((name, index) => (
            <ListItem
              key={name}
              isSelected={selectedIndex === index}
              {...getItemProps(index)}
            >
              {name === null ? (
                <Typography nowrap color="textPrimary" variant="footnote">
                  <Typography component="span" color="textSecondary" variant="inherit">
                    Set to
                  </Typography>{' '}
                  <b>{input}</b>
                </Typography>
              ) : (
                <Typography nowrap color="textPrimary" variant="footnote">
                  {name}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default observer(EditCompanyName)
