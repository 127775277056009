// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useLayoutEffect } from 'react'
import { formatted } from '../lib/phone-number'
import useCombinedRefs from '../lib/use-combined-ref'
import { Identity } from '../service/model'
import { typography } from '../theme'
import Avatar, { AvatarSize } from './avatar'
import GroupAvatar from './group-avatar'
import Typography from '@ui/Typography'

export interface MenuProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'target'> {
  size?: 'small' | 'medium' | 'large'
}

export type MenuItemHeight = 'small' | 'medium' | 'large'

export interface MenuItemProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'target'> {
  icon?: React.ReactNode
  selected?: boolean
  highlighted?: boolean
  height?: MenuItemHeight
  applyHoverStyles?: boolean
}

export interface MenuContentProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'target'> {
  height?: MenuItemHeight
}

export interface IdentityItemProps extends Omit<MenuItemProps, 'icon'> {
  identity?: Identity
  showPhoneNumbers?: boolean
}

export const Menu: React.FC<MenuProps> = React.forwardRef(function (
  { className, size = 'small', ...props },
  ref,
) {
  const styles = useStyles({ size })
  return <div ref={ref} {...props} className={cx(className, styles[size], styles.root)} />
})

export const MenuContent: React.FC<MenuContentProps> = React.forwardRef(function (
  { children, className, height, style, ...props },
  ref,
) {
  const styles = useStyles({})

  if (height) {
    children = <div className={styles.body}>{children}</div>
  }

  return (
    <div
      ref={ref}
      {...props}
      style={{ minHeight: height ? getHeight(height) : null, ...style }}
      className={cx(className, styles.content)}
    >
      {children}
    </div>
  )
})

export const MenuItem: React.FC<MenuItemProps> = React.forwardRef(function (
  {
    children,
    className,
    icon,
    highlighted,
    selected,
    height,
    applyHoverStyles = true,
    style,
    ...props
  },
  outerRef,
) {
  const styles = useStyles({})
  const ref = useCombinedRefs<HTMLDivElement>(outerRef)

  useLayoutEffect(() => {
    if (highlighted) ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' })
  }, [highlighted])

  if (typeof children === 'string') {
    children = (
      <Typography nowrap variant="inherit" color={selected ? 'inherit' : 'textPrimary'}>
        {children}
      </Typography>
    )
  } else {
    children = <div className={styles.body}>{children}</div>
  }

  return (
    <div
      {...props}
      ref={ref}
      style={{ minHeight: getHeight(height), ...style }}
      className={cx(
        className,
        selected ? styles.selected : null,
        highlighted ? styles.highlighted : null,
        styles.content,
        styles.item,
        applyHoverStyles ? styles.itemHover : null,
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </div>
  )
})

export const SeparatorItem: React.FC<React.HTMLProps<HTMLDivElement>> = function ({
  className,
  ...props
}) {
  const styles = useStyles({})
  return <div {...props} className={styles.separator} />
}

export const HeaderItem: React.FC<MenuContentProps> = function ({
  children,
  height = 'small',
  ...props
}) {
  const styles = useStyles({})
  return (
    <MenuContent {...props} height={height}>
      <Typography variant="caption2" color="textTertiary" className={styles.header}>
        {children}
      </Typography>
    </MenuContent>
  )
}

export const FooterItem: React.FC<MenuContentProps> = function ({
  children,
  height = 'small',
  ...props
}) {
  const styles = useStyles({})
  return (
    <MenuContent {...props} height={height}>
      <Typography variant="caption2" color="textTertiary" className={styles.footer}>
        {children}
      </Typography>
    </MenuContent>
  )
}

export const IdentityItem: React.FC<IdentityItemProps> = React.forwardRef(function (
  { children, className, selected, identity, showPhoneNumbers = true, ...props },
  outerRef,
) {
  const styles = useStyles({})
  const avatarHeight = getAvatarHeight(props.height)

  return (
    <MenuItem
      ref={outerRef}
      {...props}
      selected={selected}
      icon={
        identity.pictureSymbol ? (
          <GroupAvatar group={{ symbol: identity.pictureSymbol }} size={avatarHeight} />
        ) : (
          <Avatar identity={identity} size={avatarHeight} />
        )
      }
    >
      <div style={{ display: 'flex' }}>
        <Typography
          nowrap
          variant="footnote"
          fontWeight="medium"
          color={selected ? 'inherit' : 'textPrimary'}
          style={{ flex: 1 }}
        >
          {identity.name}
        </Typography>
        {showPhoneNumbers ? (
          <Typography
            nowrap
            variant="caption2"
            color={selected ? 'inherit' : 'textSecondary'}
          >
            {identity.phones.length > 1
              ? `${identity.phones.length} numbers`
              : formatted(identity.phones[0]?.number)}
          </Typography>
        ) : null}
      </div>
    </MenuItem>
  )
})

export default Menu

const useStyles = makeStyles<Theme, MenuProps>((theme: Theme) => ({
  root: {
    minWidth: 146,
    padding: '4px 0',

    '& > $content:last-child': {
      marginBottom: 0,
    },
  },
  medium: {
    padding: '8px 0',

    '& $content': {
      marginLeft: 8,
      marginRight: 8,
      ...typography.callout,
    },

    '& $item': {
      minHeight: 40,
      padding: '0 12px',
    },

    '& $header': {
      padding: '0 14px',
    },

    '& $separator': {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  content: {
    ...typography.footnote,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.op.text.primary,
    margin: '0 4px 2px',
  },
  item: {
    cursor: 'pointer',
    borderRadius: 5,
    padding: '0 10px',
  },
  itemHover: {
    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  selected: {
    color: theme.palette.op.primary[2],
  },
  highlighted: {
    background: theme.palette.op.hover.primary,
  },
  icon: {
    color: theme.palette.op.text.secondary,
    marginRight: 12,
    lineHeight: 0,
  },
  body: {
    flex: 1,
  },
  separator: {
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
    margin: '4px 0',
  },
  header: {
    padding: '0 10px',
  },
  footer: {
    padding: '0 10px',
  },
}))

const getHeight = (height: MenuItemHeight): number => {
  switch (height) {
    case 'large':
      return 56
    case 'medium':
      return 40
    case 'small':
    default:
      return 30
  }
}

const getAvatarHeight = (height: MenuItemHeight): AvatarSize => {
  switch (height) {
    case 'large':
      return 34
    case 'medium':
      return 25
    case 'small':
    default:
      return 20
  }
}
