// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import useStatefulPromise, { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import Content from '../common/content'
import FileSelector from '../common/file-selector'

interface UploadGreetingProps {
  onComplete: (url: string, text: string) => void
}

const UploadGreeting: React.FC<UploadGreetingProps> = function ({ onComplete }) {
  const styles = useStyles({})
  const { service } = useAppStore()

  const [uploadState, runUpload] = useStatefulPromise((file: File) => {
    return service.transport.communication.upload(file)
  })

  useEffect(() => {
    if (!uploadState.data) return
    onComplete(uploadState.data, null)
  }, [uploadState.data])

  return (
    <Content>
      <FileSelector
        accept={['audio/mpeg', 'audio/mp3']}
        placeholder="Select an mp3 file to upload"
        loading={uploadState.status === PromiseStateStatus.Loading}
        errorMessage={uploadState.error?.message}
        onComplete={runUpload}
      />
    </Content>
  )
}

export default UploadGreeting

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
