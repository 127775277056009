import { useEffect, EffectCallback, DependencyList, useRef, useMemo } from 'react'

export default function useEffectBeforeMount(
  effect: EffectCallback,
  deps?: DependencyList,
) {
  const cleanup = useRef<(() => void) | void>()

  useMemo(() => {
    cleanup.current = effect()
  }, deps)

  useEffect(() => {
    return cleanup.current
  }, deps)
}
