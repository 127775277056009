// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import { Model } from './'

export class Subscription implements Model {
  annual?: boolean = null
  autochargeAmount: number = null
  private canceledAt?: number = null
  cancellationReason?: string = null
  creditCardFingerprint?: string = null
  currentPeriodExpiresAt: number = null
  currentPeriodStartedAt: number = null
  id: string = null
  meta: any = null
  orgId?: string = null
  originalStartedAt: number = null
  phoneNumbers?: number = null
  prepaidCredits: number = null
  receipt?: string = null
  store?: 'stripe' | 'ios' | 'android' = null
  stripeCustomerId?: string = null
  totalCredits?: number = null
  transactionId?: string = null
  trial?: boolean = null
  type?: string = null
  usedCredits?: number = null
  version?: number = null
  reviewStatus?: 'auto-approved' | 'needs-review' | 'rejected' | 'approved' = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  /**
   * Whether or not the subscription is active.
   *
   * A subscription is active if it is either standard or premium and the
   * current period has not yet expired. Even trial subscriptions are active.
   */
  get isActive(): boolean {
    return this.type !== 'free' && this.currentPeriodExpiresAt > Date.now()
  }

  /**
   * Whether or not the subscription was canceled by the user.
   */
  get isCanceled(): boolean {
    return Boolean(this.canceledAt)
  }

  /**
   * Whether or not the subscription is premium.
   *
   * A subscription is premium if the user is on the premium plan and active.
   */
  get isPremium(): boolean {
    return this.type === 'premium' && this.isActive
  }

  get localizedCredits() {
    return (this.prepaidCredits / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get localizedAutoCharge() {
    return (this.autochargeAmount / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get needsReview() {
    return this.reviewStatus === 'needs-review'
  }

  get isReviewRejected() {
    return this.reviewStatus === 'rejected'
  }

  get isReviewApproved() {
    return this.reviewStatus === 'approved' || this.reviewStatus === 'auto-approved'
  }

  get isFlagged() {
    return this.needsReview || this.isReviewRejected
  }

  get isLegacy() {
    const version = this.version ?? 2
    return version < 3
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.canceledAt = parseDate(json.canceledAt)
    this.currentPeriodExpiresAt = parseDate(json.currentPeriodExpiresAt)
    this.currentPeriodStartedAt = parseDate(json.currentPeriodStartedAt)
    this.originalStartedAt = parseDate(json.originalStartedAt)
    return this
  }

  serialize = () => {
    return {
      annual: this.annual,
      autochargeAmount: this.autochargeAmount,
      canceledAt: this.canceledAt,
      cancellationReason: this.cancellationReason,
      creditCardFingerprint: this.creditCardFingerprint,
      currentPeriodExpiresAt: this.currentPeriodExpiresAt,
      currentPeriodStartedAt: this.currentPeriodStartedAt,
      reviewStatus: this.reviewStatus,
      id: this.id,
      meta: toJS(this.meta),
      orgId: this.orgId,
      originalStartedAt: this.originalStartedAt,
      phoneNumbers: this.phoneNumbers,
      prepaidCredits: this.prepaidCredits,
      receipt: this.receipt,
      store: this.store,
      stripeCustomerId: this.stripeCustomerId,
      totalCredits: this.totalCredits,
      transactionId: this.transactionId,
      trial: this.trial,
      type: this.type,
      usedCredits: this.usedCredits,
      version: this.version,
    }
  }
}
