import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { friendlyDateTime } from '../../../lib/date'

interface HeaderProps extends React.HTMLProps<HTMLDivElement> {
  timestamp: number
}

const Header: React.FC<HeaderProps> = function ({ timestamp, className, ...props }) {
  const styles = useStyles({})
  return (
    <div {...props} className={cx(styles.root, className)}>
      <span className={styles.text}>{friendlyDateTime(timestamp)}</span>
    </div>
  )
}

export default observer(Header)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '3rem 0 1.75rem',
      fontSize: 11,
      textAlign: 'center',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      userSelect: 'none',
      padding: '4px 1rem',
      color: theme.palette.op.gray[3],
      borderRadius: 20,
    },
    line: {
      flex: 1,
      borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
    },
  }),
  { name: Header.name },
)
