import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Textfield from './textfield'

interface TagListInputProps extends React.HTMLProps<HTMLInputElement> {
  items: any[]
  renderTag: (item: any) => React.ReactNode
}

const TagListInput: React.FC<TagListInputProps> = function ({
  items,
  renderTag,
  className,
  ...props
}) {
  const styles = useStyles({})
  return (
    <>
      {items.length > 0 && (
        <div className={styles.root}>
          {items.map(renderTag)}
          <div className={styles.spacer} />
        </div>
      )}
      <Textfield className={styles.input} {...props} />
    </>
  )
}

export default TagListInput

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px 2rem 0',
  },
  input: {
    flex: 1,
    width: 'auto',
    fontSize: '1rem',
    padding: '5px 0',
    fontFamily: 'inherit',

    '&::placeholder': {
      fontSize: '1rem',
    },
  },
  spacer: {
    flex: '0 0 15px',
  },
}))
