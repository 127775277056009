import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ScrollView } from '../../../component/scrollview'

interface ContentProps extends React.HTMLProps<HTMLDivElement> {}

export const Content: React.FC<ContentProps> = function ({ ref, className, ...props }) {
  const styles = useStyles({})
  return <ScrollView className={cx(styles.root, className)} {...props} />
}

const useStyles = makeStyles<Theme, ContentProps>((theme: Theme) => ({
  root: {
    padding: '10px 0',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,

    '&:first-child': {
      borderTop: 'none',
    },
  },
}))
