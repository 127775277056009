// @ts-strict-ignore
import { Descendant, Element, Path, Point, Range, Selection, Text } from 'slate'
import { DeepReadonly } from '../../types'
import { last } from '../../lib/collections'
import { CustomSlateText, MentionSlateElement, PlainText } from './elements'
import { Mention } from './elements/mention'

export const START_POINT: Point = { path: [0, 0], offset: 0 }
export const START_SELECTION: Selection = { anchor: START_POINT, focus: START_POINT }

export class Serializer {
  /**
   * Convert message blocks into a string.
   */
  serialize(descendants: readonly DeepReadonly<Descendant>[]): string {
    return descendants
      .reduce((acc, value) => acc + this.serializeDescendant(value), '')
      .trim()
  }

  protected serializeDescendant(descendant: DeepReadonly<Descendant>): string {
    if (Element.isElement(descendant)) {
      return this.serializeElement(descendant)
    } else if (Text.isText(descendant)) {
      return this.serializeText(descendant)
    }
  }

  protected serializeElement(element: DeepReadonly<Element>): string {
    switch (element.type) {
      case 'paragraph':
        return (
          element.children.reduce(
            (acc, value) => acc + this.serializeDescendant(value),
            '',
          ) + '\n'
        )
      case 'mention':
        return `@${element.mention.id}`
    }
  }

  protected serializeText(text: DeepReadonly<Text>): string {
    return text.text
  }
}

interface MentionIndex {
  id: string
  index: number
}

export abstract class Deserializer {
  /**
   * Provide a display name for a mention by an Id.
   *
   * If the ID is unknown, return `null`.
   */
  abstract handleMention(id: string): Mention | null

  /**
   * Convert a serialized string into message blocks.
   */
  deserialize(body: string): DeepReadonly<Descendant>[] {
    // TODO: probably makes sense to be implemented as a lexer
    return body.split('\n').flatMap((line) => this.deserializeLine(line))
  }

  protected deserializeLine(line: string): DeepReadonly<Descendant> {
    const children: DeepReadonly<Descendant>[] = []
    const mentions = this.parseMentionIndexes(line)
    if (mentions.length > 0) {
      for (let i = 0; i < mentions.length; i++) {
        const previousMention = mentions[i - 1]
        const start = previousMention
          ? previousMention.index + previousMention.id.length + 1
          : 0
        const { id, index: end } = mentions[i]
        children.push({
          text: line.substring(start, end),
        })
        children.push({
          type: 'mention',
          mention: this.handleMention(id),
          children: [{ text: '' }],
        })
      }
      const lastMention = last(mentions)
      if (lastMention) {
        children.push({
          text: line.substring(lastMention.index + lastMention.id.length + 1),
        })
      }
    } else {
      children.push({ text: line })
    }
    return { type: 'paragraph', children }
  }

  protected parseMentionIndexes(line: string): MentionIndex[] {
    const mentionRegex = /@[\w]+/g
    const mentions: MentionIndex[] = []
    let m: RegExpExecArray
    while ((m = mentionRegex.exec(line)) !== null) {
      const { 0: id, index } = m
      mentions.push({ id: id.substring(1), index })
    }
    return mentions
  }
}

export function createTextSelection(
  path: Path,
  anchorOffset: number,
  focusOffset: number,
): Selection {
  return {
    anchor: { path, offset: anchorOffset },
    focus: { path, offset: focusOffset },
  }
}

export function isAtBeginning(selection: DeepReadonly<Selection>): boolean {
  // TODO: slate types
  return Range.equals(selection as any, START_SELECTION)
}
