import Button from '../../../component/button'
import { withStyles, darken } from '@material-ui/core/styles'

export default withStyles((theme) => ({
  root: {},
  contained: {
    boxShadow: 'none',
    color: theme.palette.getContrastText(theme.palette.op.primary[1]),
    background: theme.palette.op.primary[1],

    '&:hover': {
      boxShadow: 'none',
      background: darken(theme.palette.op.primary[1], 0.2),
    },
  },
  text: {
    color: theme.palette.op.primary[1],
  },
}))(Button)
