// @ts-strict-ignore
import Modal from '@material-ui/core/Modal'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { useAppStore } from '@src/app/context'
import { isEditableElement } from '../../lib/dom'
import useKeyboardShortcuts from '../../lib/use-keyboard-shortcuts'
import EditSnippetCommand from './edit-snippet'
import AccountCommand from './account'
import AppCommand from './app'
import BillingAutoChargeCommand from './billing/auto-charge'
import BillingCreditsCommand from './billing/credits'
import ChangeEmailCommand from './change-email'
import EmailCodeCommand from './change-email/code'
import ChangeEnvCommand from './change-env'
import ContactFilterCommand from './contact-filters'
import DialerCommand from './dialer'
import DoNotDisturbCommand from './do-not-disturb'
import ForwardCallsCommand from './forward-calls'
import ImportContacts from './import-contacts'
import IvrDestinationCommand from './ivr-destination'
import MemberStatusCommand from './member-status'
import MutePhoneNumber from './mute-phone-number'
import PickOrgPhoneNumberCommand from './pick-org-phone-number'
import PickUserPhoneNumberCommand from './pick-user-phone-number'
import RejectWithMessageCommand from './reject-with-message'
import RequiresPremiumCommand from './requires-premium'
import SearchCommand from './search'
import SelectFileCommand from './select-file'
import SelectMemberCommand from './select-member'
import SetGreetingCommand from './set-greeting'
import SetScheduleCommand from './set-schedule'
import SharePhoneNumberCommand from './share-number'
import ShareNumberRoleCommand from './share-number/role'
import SharedWithCommand from './shared-with'
import UploadCommand from './upload'
import { ReferralCommand } from './referral'
import InviteMember from './invite-member/InviteMember'
import { AddDomainCommand } from './add-domain'
import { VerifyDomainCommand } from './verify-domain'
import ReportExportCommand from './report-export'
import { EditGroupCommand } from './edit-group'
import { CreateWebhookCommand } from './create-webhook'
import { SendTestRequestCommand, TestRequestDetailsCommand } from './send-test-request'

// prettier-ignore
const components = {
  'account': AccountCommand,
  'app': AppCommand,
  'billing auto charge': BillingAutoChargeCommand,
  'billing credits': BillingCreditsCommand,
  'change email code': EmailCodeCommand,
  'change email': ChangeEmailCommand,
  'change env': ChangeEnvCommand,
  'contact filters': ContactFilterCommand,
  'dialer': DialerCommand,
  'do not disturb': DoNotDisturbCommand,
  'edit shared with': SharedWithCommand,
  'edit snippet': EditSnippetCommand,
  'forward calls': ForwardCallsCommand,
  'import contacts': ImportContacts,
  'invite member': InviteMember,
  'add domain': AddDomainCommand,
  'verify domain': VerifyDomainCommand,
  'member status': MemberStatusCommand,
  'mute phone number': MutePhoneNumber,
  'pick org phone number': PickOrgPhoneNumberCommand,
  'pick user phone number': PickUserPhoneNumberCommand,
  'reject call with message': RejectWithMessageCommand,
  'requires premium': RequiresPremiumCommand,
  'search': SearchCommand,
  'select file': SelectFileCommand,
  'select ivr destination': IvrDestinationCommand,
  'select member': SelectMemberCommand,
  'set greeting': SetGreetingCommand,
  'set schedule': SetScheduleCommand,
  'share phone number role': ShareNumberRoleCommand,
  'share phone number': SharePhoneNumberCommand,
  'upload': UploadCommand,
  'referral': ReferralCommand,
  'edit group': EditGroupCommand,
  'create webhook': CreateWebhookCommand,
  'send test request': SendTestRequestCommand,
  'test request details': TestRequestDetailsCommand,
  'report export': ReportExportCommand,
}

const Backdrop: React.FC<React.HTMLProps<HTMLDivElement>> = function ({ ...props }) {
  const styles = useStyles({})
  return <div className={styles.backdrop} {...props} />
}

const CommandModal = () => {
  const styles = useStyles({})
  const app = useAppStore()

  useKeyboardShortcuts({
    name: 'command',
    node: document,
    filter: (shortcut, event) =>
      !event.defaultPrevented &&
      (shortcut === 'Escape' || !isEditableElement(event.target)),
    handler: (shortcut, event) => {
      if (shortcut === 'Escape' && app.command.open) {
        event.preventDefault()
        app.command.hide()
      } else if (app.command.open) {
        // When modal is open, prevent all keyboard events
        event.preventDefault()
      }
    },
    dep: [],
  })

  const Component: FC<any> = components[app.command.command?.name]

  return (
    <Modal
      open={app.command.open}
      onClose={app.command.hide}
      className={styles.modal}
      BackdropComponent={Backdrop as any}
      disableEscapeKeyDown
    >
      <div className={styles.root}>
        {Component && <Component {...app.command.command} />}
      </div>
    </Modal>
  )
}

export default observer(CommandModal)

const useStyles = makeStyles(
  (theme: Theme) => ({
    modal: {
      overflow: 'auto',
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    topSpacing: {
      flex: '1 1',
    },
    bottomSpacing: {
      flex: '2 1',
    },
    root: {
      outline: 'none',
    },
  }),
  { name: CommandModal.name },
)
