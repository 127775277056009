import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import Illustration from '@src/component/illustrations'
import { Page } from '../common'
import Button from '../../../component/button-v2'
import { useAppStore } from '@src/app/context'
import { NoDataMessage } from '@src/app/components'

interface WebhooksWelcomePageProps {}

const WebhooksWelcomePage = (props: WebhooksWelcomePageProps) => {
  const styles = useStyles()
  const { command } = useAppStore()
  return (
    <Page centered>
      <NoDataMessage
        icon="🛠"
        title="Add your first webhook"
        description="Receive HTTP requests whenever an event is triggered"
        actions={
          <Button
            variant="contained"
            className={styles.createBtb}
            onClick={() => command.present({ name: 'create webhook' })}
          >
            Create webhook
          </Button>
        }
      />
    </Page>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    createBtb: {
      marginTop: 24,
      width: 'fit-content',
    },
    illustrationContainer: {
      width: 355,
      height: 288,
      marginBottom: 64,
    },
  }),
  { name: WebhooksWelcomePage.name },
)

export default WebhooksWelcomePage
