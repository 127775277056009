import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import Commands from './commands'
import Emojis from './emojis'
import Mentions from './mentions'
import { Menu } from '../../menu-v2'
import { global } from '../../../theme'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import Controller from '../controller'

export type InputMenuMode = 'commands' | 'emojis' | 'mentions' | null

export interface InputMenuProps {
  controller: Controller
}

const InputMenu = function ({ controller }: InputMenuProps) {
  const styles = useStyles({})
  const currentMode = controller.menu.mode

  useKeyboardShortcuts({
    name: 'InputMenu',
    node: controller.editor.ref.current,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        controller.menu.close()
        event.preventDefault()
      }
    },
    dep: [controller],
  })

  const renderContents = useCallback(() => {
    switch (currentMode) {
      case 'commands':
        return <Commands controller={controller} />
      case 'emojis':
        return <Emojis controller={controller} />
      case 'mentions':
        return <Mentions controller={controller} />
    }
  }, [controller, currentMode])

  return currentMode ? (
    <Menu
      size="medium"
      className={cx(styles.root, currentMode === 'mentions' ? styles.mentions : null)}
    >
      {renderContents()}
    </Menu>
  ) : null
}

export default observer(InputMenu)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...global.popover(theme),
      position: 'absolute',
      bottom: 12,
      left: 0,
      right: 0,
    },
    mentions: {
      maxHeight: 248,
      overflowY: 'scroll',
    },
  }),
  { name: InputMenu.name },
)
