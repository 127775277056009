// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { Command, Content, EmptyListMessage, InputHeader } from '../../common-v2'
import KeyboardList from '../../../../component/keyboard-list'
import CompanyFilterController, { CompanyView } from './controller'
import CompanyItem from './item'

export interface CompanyFilterProps {
  controller: CompanyFilterController
  onBack: () => void
}

const CompanyFilter: React.FC<CompanyFilterProps> = ({ controller, onBack }) => {
  const styles = useStyles()
  const ref = useRef()

  const renderRow = (company: CompanyView, index: number) => (
    <CompanyItem
      key={company.id}
      controller={controller}
      company={company}
      index={index}
    />
  )

  return (
    <Command>
      <InputHeader
        ref={ref}
        autoFocus
        onBack={onBack}
        value={controller.search}
        onChange={controller.handleSearchInputChange}
        placeholder="Search for a company..."
      />
      <Content className={styles.content}>
        <KeyboardList
          name="companies"
          controller={controller}
          renderItem={renderRow}
          className={styles.list}
        />
        {controller.data.length === 0 && (
          <EmptyListMessage
            text={
              controller.search
                ? 'Nothing found matching your search query.'
                : 'There are no contacts in your address book with a company set. When you do add company, you can come here to filter for certain companies.'
            }
          />
        )}
      </Content>
    </Command>
  )
}

export default observer(CompanyFilter)

const useStyles = makeStyles(
  (theme: Theme) => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      display: 'flex',
      flex: 1,
    },
  }),
  { name: CompanyFilter.name },
)
