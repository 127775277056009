declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    /**
     * @deprecated Use theme.palette.op.primary instead
     */
    primary: Palette['primary']
    op: OpenPhonePalette
  }

  interface PaletteOptions {
    op: OpenPhonePalette
  }
}

export type ThemeKey = 'light' | 'dark'
export type ThemeMatch = <T = unknown>(match: Record<ThemeKey, T>) => T

export const createThemeMatch =
  (key: ThemeKey): ThemeMatch =>
  (match) =>
    match[key]

export interface OpenPhonePalette {
  black: string
  white: string
  titleBar: {
    background: string
    closeHover: string
  }
  background: {
    primary: string
    popover: string
    deepPopover: string
    error: string
    highlight: (alpha?: number) => string
  }
  primary: {
    1: string
    2: string
    3: string
    4: string
  }
  secondary: {
    green1: string
    green2: string
    blue: string
    yellow: string
    orange: string
    red1: string
    red2: string
  }
  gray: {
    1: string
    2: string
    3: string
    4: string
    5: string
    6: string
  }
  mention: {
    theirName: string
    theirBg: string
    myName: string
    myBg: string
    chatBubbleBg: string
  }
  tag: {
    purple: {
      bg: string
      bgHover: string
      text: string
    }
    green: {
      bg: string
      bgHover: string
      text: string
    }
    blue: {
      bg: string
      bgHover: string
      text: string
    }
    yellow: {
      bg: string
      bgHover: string
      text: string
    }
    orange: {
      bg: string
      bgHover: string
      text: string
    }
    red: {
      bg: string
      bgHover: string
      text: string
    }
  }
  note: {
    text: string
    label: string
    highlight: string
    bg: string
  }
  text: {
    primary: string
    secondary: string
    tertiary: string
    placeholder: string
    subtitle: string
  }
  hover: {
    primary: string
    darker: string
    thread: string
    selected: string
  }
  border: {
    common: string
    focus: string
  }
  shadow: {
    tooltip: string
    popup: string
    modal: string
    textField: {
      inactive: string
      hoverPurple: string
      hoverNoteThread: string
    }
    dropdown: {
      inactive: string
      hover: string
      active: string
      disabled: string
    }
    comments: string
    card: string
  }
  /**
   * @deprecated
   */
  borderRadius: {
    tooltip: number
    dropdown: number
    popup: number
    modal: number
    comments: {
      closed: number
      open: number
    }
    card: number
  }
  contactTags: {
    green1: string
    red1: string
    blue1: string
    orange1: string
    cherokee1: string
    turquoise1: string
    mauve1: string
    purple1: string
    monaLisa1: string
    gray1: string
  }
  legacy: {
    dateEditor: {
      weekDayGray: string
      todayButton: string
      weekNumberBorder: string
      disabledText: string
    }
    chart: {
      green1: string
      green2: string
      blue1: string
      blue2: string
      yellow1: string
      red1: string
    }
    heatmap: {
      blue1: string
    }
    commandItem: {
      purple1: string
    }
    draftGreen: string
    ongoingCall: {
      active: string
      record: string
    }
    buttonOutlined: string
  }

  match: ThemeMatch
}
