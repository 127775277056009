// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import useTransition from '../lib/use-transition'
import { global } from '../theme'

const duration = 200

export interface PopoverProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * Whether or not the popover is open
   */
  open: boolean

  /**
   * Delay opening the popover (ms)
   */
  openDelay?: boolean | number

  /**
   * Delay closing the popover (ms)
   */
  closeDelay?: boolean | number
}

const Popover: React.FC<PopoverProps> = React.forwardRef(function (
  { open, openDelay, closeDelay, children, ...props },
  ref,
) {
  const styles = useStyles({})
  const state = useTransition(open, {
    enterDelay: openDelay,
    enterDuration: duration,
    exitDelay: closeDelay,
    exitDuration: duration,
  })

  return state === 'exited' ? null : (
    <div
      ref={ref}
      {...props}
      className={cx(
        styles.root,
        styles[state],
        state === 'entering' || state === 'entered' || state === 'exit'
          ? styles.in
          : null,
        props.className,
      )}
    >
      {children}
    </div>
  )
})

export default Popover

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...global.popover(theme),
      display: 'flex',
      padding: 4,
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        duration,
        easing: 'ease',
      }),
    },
    in: {
      opacity: 1,
    },
  }),
  { name: Popover.name },
)
