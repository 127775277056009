import React from 'react'
import useInputState from '../../../lib/use-input-state'
import { useEditor, useBackdropClick } from '.'
import keyboard from '../../../lib/keyboard'
import Input from './input'

export interface EditStringItemProps {
  defaultValue: string
  placeholder: string
  onSave: (name: string) => void
}

const EditStringItem: React.FC<EditStringItemProps> = function ({
  defaultValue,
  placeholder,
  onSave,
}) {
  const [, hide] = useEditor()
  const [input, setInput] = useInputState(defaultValue || '')

  const handleSave = () => {
    onSave(input)
    hide()
  }

  useBackdropClick(handleSave, [input])

  return (
    <Input
      autoFocus
      type="text"
      value={input}
      placeholder={placeholder}
      onChange={setInput}
      onKeyDown={keyboard.onEnterOrTab(handleSave)}
    />
  )
}

export default EditStringItem
