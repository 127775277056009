import React from 'react'
import { observer } from 'mobx-react-lite'
import { PropsWithChildren } from 'react'
import { useAppStore } from '@src/app/context'
import { CommandModal } from '../command'

const ModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const app = useAppStore()

  return (
    <>
      {children}
      {app.command.open && <CommandModal />}
    </>
  )
}

export default observer(ModalProvider)
