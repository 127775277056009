// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { Identity } from '../../../service/model'
import ContactNumbers from './numbers'
import PhoneNumberSearchCommand from './search'

interface PhoneNumberSelectorProps {
  onSelect: (phoneNumber: string) => void
  actionIcon: React.ReactNode
  actionTitle: string
  actionColor?: 'primary' | 'secondary'
  defaultValue?: string
}

const PhoneNumberSelector: React.FC<PhoneNumberSelectorProps> = function ({
  actionIcon,
  actionTitle,
  defaultValue,
  actionColor = 'primary',
  onSelect,
}) {
  const styles = useStyles({})
  const [identity, setIdentity] = useState<Identity>(null)

  const handleSearchSelect = (result: string | Identity) => {
    if (typeof result === 'string') {
      onSelect(result)
    } else {
      setIdentity(result)
    }
  }

  return identity ? (
    <ContactNumbers identity={identity} onSelect={onSelect} />
  ) : (
    <PhoneNumberSearchCommand
      actionIcon={actionIcon}
      actionTitle={actionTitle}
      actionColor={actionColor}
      defaultValue={defaultValue}
      onSelect={handleSearchSelect}
    />
  )
}

export default PhoneNumberSelector

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
