import React from 'react'
import cx from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
interface DisclaimerProps extends React.HTMLProps<HTMLDivElement> {
  emoji?: string
  text: React.ReactNode
  transparent?: boolean
}

export const Disclaimer: React.FC<DisclaimerProps> = function ({
  transparent,
  emoji,
  text,
  className,
  ...props
}) {
  const styles = useStyles({ transparent })
  return (
    <div {...props} className={cx(styles.root, className)}>
      {emoji && (
        <Typography variant="emoji" style={{ flex: '0 0 auto', marginRight: 10 }}>
          {emoji}
        </Typography>
      )}
      <Typography
        variant="footnote"
        color={transparent ? 'textTertiary' : 'textSecondary'}
      >
        {text}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles<Theme, Partial<DisclaimerProps>>((theme) => ({
  root: ({ transparent }) => ({
    marginTop: 24,
    display: 'flex',
    borderRadius: 6,
    padding: '14px 16px',
    textAlign: 'left',
    background: transparent ? 'none' : theme.palette.op.hover.primary,

    '& a': {
      color: theme.palette.op.primary[2],
    },
  }),
}))
