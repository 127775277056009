// @ts-strict-ignore
import { TableRow, makeStyles, Theme } from '@material-ui/core'
import React, { MouseEventHandler, useState } from 'react'
import Typography from '@ui/Typography'
import { DeleteIcon } from '../../../component/icons/Tint/16/General'
import { MoreIcon } from '../../../component/icons/Tint/20/General'
import IconButton from '../../../component/icon-button'
import { MenuItem } from '../../../component/menu-v2'
import PopoverMenu from '../../../component/popover-menu'
import { Member } from '../../../service/model'
import GroupMemberCell from './GroupMemberCell'
import { AppAvatar } from '@src/app/components'

interface GroupMemberRowProps {
  member: Member
  deleteMember?: (id: string) => void
}

const GroupMemberRow = ({ member, deleteMember }: GroupMemberRowProps) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleTogglePopover: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleDeleteMember = () => {
    deleteMember(member.id)
    setAnchorEl(null)
  }
  return (
    <TableRow key={member.id}>
      <GroupMemberCell width="300px" className={styles.memberNameCell}>
        <AppAvatar size={22} identity={member} />
        <Typography variant="footnote" color="textPrimary" className={styles.memberName}>
          {member.name}
        </Typography>
      </GroupMemberCell>
      <GroupMemberCell width="300px">
        <Typography variant="footnote" color="textSecondary">
          {member.email}
        </Typography>
      </GroupMemberCell>
      {deleteMember && (
        <GroupMemberCell>
          <span>
            <IconButton
              onClick={handleTogglePopover}
              icon={<MoreIcon />}
              title="Actions"
            />
          </span>
          <PopoverMenu
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: -8, horizontal: 'right' }}
            elevation={2}
          >
            <MenuItem
              icon={<DeleteIcon className={styles.deleteIcon} />}
              onClick={handleDeleteMember}
            >
              <Typography color="error">Remove member</Typography>
            </MenuItem>
          </PopoverMenu>
        </GroupMemberCell>
      )}
    </TableRow>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    memberNameCell: {
      display: 'flex',
      alignItems: 'center',
    },
    memberName: {
      marginLeft: 12,
    },
    deleteIcon: {
      color: theme.palette.op.secondary.red2,
    },
  }),
  { name: GroupMemberRow.name },
)

export default GroupMemberRow
