import { Theme, makeStyles } from '@material-ui/core/styles'
import { Descendant, Element } from 'slate'
import { RenderElementProps } from 'slate-react'
import React from 'react'
import MentionElement from './mention'
import type { MentionSlateElement } from './mention'

export { MentionElement, MentionSlateElement }

export interface ParagraphSlateElement {
  type: 'paragraph'
  children: Descendant[]
}

export type CustomSlateElement = ParagraphSlateElement | MentionSlateElement

export interface EmptyText {
  text: ''
}

export interface PlainText {
  text: string
}

export type CustomSlateText = EmptyText | PlainText

export interface ElementProps<T extends Element> extends RenderElementProps {
  element: T
}

const DefaultElement: React.FC<ElementProps<ParagraphSlateElement>> = ({
  attributes,
  children,
}) => {
  const styles = useStyles({})

  return (
    <p {...attributes} className={styles.root}>
      {children}
    </p>
  )
}

export default DefaultElement

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: 0,
    },
  }),
  { name: DefaultElement.name },
)
