// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import useKeyboardShortcuts, {
  defaultWithInputOnScreen,
} from '../../lib/use-keyboard-shortcuts'
import VirtualList from '../virtual-list'
import { VirtualListItem } from '../virtual-list/store'
import KeyboardListController from './controller'

interface KeyboardListProps<T extends VirtualListItem> {
  name?: string
  node?: Element | Document
  controller: KeyboardListController<T>
  className?: string
  renderItem(item: T, index: number): React.ReactNode
}

const KeyboardList: <T extends VirtualListItem>(
  props: KeyboardListProps<T>,
) => React.ReactElement<KeyboardListProps<T>> = function ({
  name,
  node = document,
  controller,
  className,
  renderItem,
}) {
  const styles = useStyles({})
  const listRef = useRef<HTMLDivElement>(null)
  const highlighterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let el: HTMLDivElement = highlighterRef.current

    if (!el) {
      el = document.createElement('div')
      listRef.current.prepend(el)
      highlighterRef.current = el
    }

    el.classList.add(styles.highlighter)
    controller.highlighterRef.current = el

    return () => {
      el.classList.remove(styles.highlighter)
      controller.highlighterRef.current = null
    }
  }, [controller, styles.highlighter])

  useKeyboardShortcuts({
    name: 'keyboard-list',
    node,
    filter: defaultWithInputOnScreen({
      allowedShortcutsAlways: ['Enter', 'Escape'],
      allowedShortcutsWhenEmpty: [
        'ArrowDown',
        'ArrowUp',
        'Meta+ArrowUp',
        'Meta+ArrowDown',
      ],
    }),
    handler: (shortcut, event) => {
      const index = controller.stepper.index

      if (controller.data.length === 0) return

      if (shortcut === 'Enter') {
        return controller.handleEnter?.(controller.data.list[index], index)
      }

      if (shortcut === 'ArrowUp') {
        controller.stepper.prev()
        event.preventDefault()
      } else if (shortcut === 'Meta+ArrowUp') {
        controller.stepper.start()
        event.preventDefault()
      } else if (shortcut === 'ArrowDown') {
        controller.stepper.next()
        event.preventDefault()
      } else if (shortcut === 'Meta+ArrowDown') {
        controller.stepper.end()
        event.preventDefault()
      }
    },
    dep: [controller],
  })

  useEffect(() => {
    controller.updateHighlighter(false)
  })

  return (
    <div
      tabIndex={-1}
      className={cx(styles.root, className)}
      onMouseMove={controller.handleMouseMove}
    >
      <VirtualList
        hideScroll
        name={name}
        ref={controller.virtualListRef}
        listRef={listRef}
        data={controller.data}
        pinToIndex={controller.pinToIndex}
        itemContent={renderItem}
        itemSpacing={controller.itemSpacing}
        listPadding={controller.listPadding}
        itemEstimatedHeight={controller.itemHeight}
        onBottomReached={controller.handleBottomReached}
        onItemMouseEnter={
          controller.itemHoverMode === 'select' ? controller.handleItemMouseEnter : null
        }
      />
    </div>
  )
}

export default observer(KeyboardList)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    outline: 'none',
    overflowY: 'auto',
  },
  highlighter: {
    position: 'absolute',
    top: 0,
    borderRadius: 5,
    background: theme.palette.op.hover.primary,
    transformOrigin: 'top left',
    pointerEvents: 'none',
    opacity: 0,
  },
}))
