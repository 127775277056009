import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '../../../../button-v2'
import { SnippetsIcon } from '../../../../icons/Tint/20/General'
import { MenuItem, MenuItemProps } from '../../../../menu-v2'
import Typography from '@ui/Typography'
import { Snippet } from '../../../../../service/model'
import Controller from '../../../controller'

export interface SnippetsProps extends MenuItemProps {
  controller: Controller
  snippet: Snippet
}

const SnippetComponent: React.FC<SnippetsProps> = function ({
  controller,
  snippet,
  ...props
}) {
  const styles = useStyles({})

  const createEditSnippetHandler = useCallback(
    (snippet: Snippet) => (event: React.MouseEvent) => {
      event.stopPropagation()
      controller.handleEditSnippet(snippet)
    },
    [controller],
  )

  return (
    <MenuItem key={snippet.id} icon={<SnippetsIcon />} height="large" {...props}>
      <div className={styles.root}>
        <div className={styles.text}>
          <Typography color="textPrimary">{snippet.name}</Typography>
          <Typography
            nowrap
            color="textSecondary"
            variant="caption1"
            fontWeight="regular"
          >
            {snippet.text}
          </Typography>
        </div>
        {props.highlighted ? (
          <Button
            variant="text"
            color="textSecondary"
            height={30}
            onClick={createEditSnippetHandler(snippet)}
          >
            Edit
          </Button>
        ) : null}
      </div>
    </MenuItem>
  )
}

export default observer(SnippetComponent)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginRight: 5,
    },
  }),
  { name: SnippetComponent.name },
)
