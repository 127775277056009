// @ts-strict-ignore
import FormControl from '@material-ui/core/FormControl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import PlayArrow from '@material-ui/icons/PlayArrow'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button'
import { MenuItem, SelectSmall as Select } from '../../component/menu'
import { Input, Label } from '../../component/textfield'

interface AudioSettingsModalProps extends React.HTMLProps<HTMLDivElement> {
  allowTesting?: boolean
}

const AudioSettingsModal: React.FC<AudioSettingsModalProps> = function ({
  allowTesting = true,
  className,
  ...props
}) {
  const styles = useStyles({})
  const { voice, toast } = useAppStore()
  const [, setForceRefresh] = useState(0)
  const currentInputDevice = voice.device.audio.inputDevice

  const currentSpeakerDevice: MediaDeviceInfo = Array.from(
    voice.device.audio.speakerDevices.get().values(),
  )[0] as any

  const currentRingtoneDevice: MediaDeviceInfo = Array.from(
    voice.device.audio.ringtoneDevices.get().values(),
  )[0] as any

  const handleMicSelect = (event) => {
    voice.device.audio.setInputDevice(event.target.value).then(() => {
      setForceRefresh((i) => i + 1)
    })
  }

  const handleSpeakerSelect = (event) => {
    voice.device.audio.speakerDevices
      .set(event.target.value)
      .then(() => setForceRefresh((i) => i + 1))
      .catch(toast.showError)
  }

  const handleSpeakerTest = () => {
    voice.device.audio.ringtoneDevices.test()
  }

  const handleRingtoneSelect = (event) => {
    voice.device.audio.ringtoneDevices
      .set(event.target.value)
      .then(() => setForceRefresh((i) => i + 1))
      .catch(toast.showError)
  }

  const handleRingtoneTest = () => {
    voice.device.audio.ringtoneDevices.test()
  }

  return (
    <div {...props} className={cx(styles.root, className)}>
      <FormControl className={styles.formControl}>
        <Label id="microphone">Microphone</Label>
        <Select
          id="microphone"
          labelId="microphone"
          input={<Input />}
          value={currentInputDevice?.deviceId ?? 'default'}
          onChange={handleMicSelect}
          className={styles.select}
        >
          {Array.from(voice.inputDevices.values()).map((device) => (
            <MenuItem
              key={device.deviceId}
              className={styles.select}
              value={device.deviceId}
            >
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={styles.audio}>
        <FormControl className={styles.formControl}>
          <Label id="speaker">Speaker</Label>
          <Select
            id="speaker"
            labelId="speaker"
            input={<Input />}
            value={currentSpeakerDevice?.deviceId ?? 'default'}
            onChange={handleSpeakerSelect}
            className={styles.select}
          >
            {Array.from(voice.audioDevices.values()).map((device) => (
              <MenuItem
                key={device.deviceId}
                className={styles.select}
                value={device.deviceId}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {allowTesting && (
          <Button
            color="default"
            size="small"
            startIcon={<PlayArrow />}
            onClick={handleSpeakerTest}
            className={styles.accessory}
          >
            Test
          </Button>
        )}
      </div>
      <div className={styles.audio}>
        <FormControl className={styles.formControl}>
          <Label id="ringtone">Ringtone</Label>
          <Select
            labelId="ringtone"
            id="ringtone"
            input={<Input />}
            value={currentRingtoneDevice?.deviceId ?? 'default'}
            onChange={handleRingtoneSelect}
            className={styles.select}
          >
            {Array.from(voice.audioDevices.values()).map((device) => (
              <MenuItem
                key={device.deviceId}
                className={styles.select}
                value={device.deviceId}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {allowTesting && (
          <Button
            color="default"
            size="small"
            startIcon={<PlayArrow />}
            onClick={handleRingtoneTest}
            className={styles.accessory}
          >
            Test
          </Button>
        )}
      </div>
    </div>
  )
}

export default observer(AudioSettingsModal)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 350,
    display: 'flex',
    padding: '1.5rem',
    flexDirection: 'column',
    background: theme.palette.op.background.popover,
  },
  formControl: {
    flex: 1,
    paddingTop: 20,
  },
  audio: {
    marginTop: 13,
    display: 'flex',
    alignItems: 'flex-end',

    '&:first-child': {
      marginTop: 0,
    },
  },
  select: {
    fontSize: '0.85rem',
  },
  accessory: {
    marginLeft: 13,
    marginBottom: 4,
  },
}))
