// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '..'
import { getInitials, parseDate } from '../../lib'
import ObjectID from '../../lib/object-id'
import { isTruthy } from '../../lib/rx-operators'
import { formatted, toE164 } from '../../lib/phone-number'
import { VisibilityType } from '../../types'
import { Identity, IdentityPhone, Model } from './base'
import { ContactItem } from './contact-item'
import { ContactTemplateItem } from './contact-template-item'
import { Note } from './note'

export interface IContact extends Identity, Model {
  id: string
  visibility: VisibilityType
  source: string
  sourceName: string
  userId: string
  firstName: string
  lastName: string
  company: string
  role: string
  location: string
  pictureUrl: string
  items: ContactItem[]
  sharedWith: string[]
  deletedAt: number
  createdAt: number
  updatedAt: number
  local: boolean
  notes: Note[]
}

export class Contact implements IContact {
  id: string = ObjectID()
  visibility: VisibilityType = null
  source: string = null
  sourceName: string = null
  userId: string = null
  firstName: string = null
  lastName: string = null
  company: string = null
  role: string = null
  location: string = null
  pictureUrl: string = null
  items: ContactItem[] = []
  sharedWith: string[] = []
  deletedAt: number = null
  createdAt: number = Date.now()
  updatedAt: number = Date.now()
  local: boolean = null
  notes: Note[] = []

  constructor(private service: Service, attrs: Partial<IContact> = {}) {
    this.deserialize(attrs)
    makeAutoObservable(this, {
      hasPhoneNumber: false,
    })
  }

  get name(): string {
    if (!this.firstName && !this.lastName) {
      if (this.company) return this.company
      if (this.phoneNumbers.length > 0) return formatted(this.phoneNumbers[0].value)
      if (this.emails.length > 0) return this.emails[0].value
      return 'Unnamed'
    }
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get fullName(): string {
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.firstName, this.lastName].find(isTruthy)
  }

  get initials(): string {
    if (this.fullName) {
      return getInitials(this.fullName)
    } else if (this.company) {
      return getInitials(this.company)
    }
    return null
  }

  get phoneNumbers() {
    return this.items.filter((p) => p.type === 'phone-number' && p.value)
  }

  get emails() {
    return this.items.filter((p) => p.type === 'email' && p.value)
  }

  get phones(): IdentityPhone[] {
    return this.phoneNumbers.map((p) => ({
      id: null,
      name: p.name,
      symbol: null,
      number: String(p.value),
    }))
  }

  get emailAddresses(): string[] {
    return this.emails.map((i) => i.value)
  }

  get job() {
    return [this.role, this.company].filter((x) => x).join(' at ')
  }

  get sortName() {
    return [
      this.firstName,
      this.lastName,
      this.company,
      ...this.phoneNumbers.map((i) => `~${i.value}`),
    ]
      .join('')
      .toLowerCase()
      .trim()
  }

  get isEmpty() {
    return (
      !this.firstName &&
      !this.lastName &&
      !this.company &&
      !this.role &&
      this.items.every((i) => i.isEmpty) &&
      this.notes.length === 0
    )
  }

  get sortedNotes() {
    return this.notes?.slice().sort((a, b) => a.createdAt - b.createdAt) ?? []
  }

  get isAnonymous() {
    return this.local || !this.fullName
  }

  hasPhoneNumber(number: string) {
    return Boolean(
      this.items.find((i) => i.type === 'phone-number' && i.value === number),
    )
  }

  addItem(item: Partial<ContactItem>): ContactItem {
    const ci = new ContactItem(this.service, this, item)
    this.items.push(ci)
    return ci
  }

  addNote(text: string) {
    const userId = this.service.user.current.id
    const note = new Note(this.service, this, { text, userId })
    this.notes.push(note)
    return this.service.contact.putNote(note)
  }

  update(attrs: Partial<this> = {}) {
    Object.assign(this, attrs)
    return this.service.contact.update(this)
  }

  saveLocal() {
    this.service.contact.collection.put(this)
  }

  delete() {
    return this.service.contact.delete(this)
  }

  getOrCreateItemForTemplate(template: ContactTemplateItem) {
    let item = this.items.find((i) => i.templateKey === template.key && !i.deletedAt)
    if (!item) {
      item = new ContactItem(this.service, this, {
        type: template.type,
        name: template.name,
        templateKey: template.key,
        isNew: true,
      })
      this.items.push(item)
    }
    return item
  }

  deserialize(attrs: any) {
    if (attrs) {
      const { sortName, ...json } = attrs
      Object.assign(this, json)
      this.notes =
        json.notes
          ?.filter((note) => !note.deletedAt)
          .map((note) => new Note(this.service, this).deserialize(note)) ?? []
      this.items =
        json.items
          ?.filter((item) => !item.deletedAt)
          .map((item) => new ContactItem(this.service, this).deserialize(item)) ?? []
      this.deletedAt = parseDate(json.deletedAt)
      this.updatedAt = parseDate(json.updatedAt)
      this.createdAt = parseDate(json.createdAt)
    }
    return this
  }

  serialize(): any {
    return {
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      id: this.id,
      items: this.items.map((i) => i.serialize()),
      lastName: this.lastName,
      local: this.local,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      source: this.source,
      sourceName: this.sourceName,
      updatedAt: this.updatedAt,
      userId: this.userId,
      visibility: this.visibility,
      sortName: this.sortName,
      _phoneNumbers: this.phoneNumbers.map((i) => toE164(i.value)).filter(isTruthy),
    }
  }

  toJSON(): any {
    return {
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      id: this.id,
      items: this.items.filter((i) => !i.isNew || i.value).map((i) => i.serialize()),
      lastName: this.lastName,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      source: this.source,
      sourceName: this.sourceName,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  tearDown() {
    this.items = []
  }
}

export const isContact = (a: any): a is Contact => {
  return a instanceof Contact
}

export { ContactItem, Note }
