import { CircularProgress } from '@src/component/progress'
import { PromiseStateStatus } from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import WebhookDetails from './WebhookDetails'
import { Redirect } from '@src/app/router'

const WebhookDetailsRoute = ({ match }: RouteComponentProps<{ id: string }>) => {
  const id = match.params.id
  const { workspace } = useAppStore()

  const webhook = workspace.webhooks.find((wh) => wh.id === id)

  if (
    !webhook &&
    (workspace.webhooksNetworkStatusState === PromiseStateStatus.Idle ||
      workspace.webhooksNetworkStatusState === PromiseStateStatus.Loading)
  ) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  if (!webhook) {
    return <Redirect to="/settings/webhooks" />
  }

  return <WebhookDetails webhook={webhook} />
}

export default observer(WebhookDetailsRoute)
