import { TableCell, withStyles } from '@material-ui/core'

const WebhookEventsTableCell = withStyles((theme) => ({
  root: {
    padding: '5px 0',
    border: 'none',
    height: 40,
  },
  head: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  footer: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))(TableCell)

export default WebhookEventsTableCell
