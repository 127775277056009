// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import Mention from '../../mention'
import useKeyboardShortcuts, {
  defaultWithInputOnScreen,
} from '../../../lib/use-keyboard-shortcuts'
import { SendMessageExternalIcon } from '../../../component/icons/Tint/20/General'
import InputBar from '../../../component/input-bar'
import Participant from '../conversations/participant'
import { Conversation } from '../../../service/model'
import { ConversationInputBarController } from './input-bar'

export interface ComposeProps {
  controller: ConversationInputBarController
  conversation: Conversation
}

const Compose: React.FC<ComposeProps> = function ({ controller, conversation }) {
  const styles = useStyles({})
  const windowRef = useRef(window)

  useLayoutEffect(() => {
    if (conversation.id && (!conversation.isNew || conversation.phoneNumber)) {
      handleReply()
    }
  }, [conversation.id])

  useKeyboardShortcuts({
    name: 'Compose',
    filter: defaultWithInputOnScreen({
      allowedShortcutsAlways: ['Escape', 'Meta+Shift+Comma', 'Meta+Shift+Period'],
    }),
    node: controller.focused ? controller.editor.ref.current : document,
    handler: (shortcut, event) => {
      if (!controller.focused && (shortcut === 'Enter' || shortcut === 'KeyR')) {
        handleReply()
      } else if (shortcut === 'Meta+Shift+Comma' || shortcut === 'Meta+Shift+Period') {
        controller.clear()
        controller.editor.focus()
      }
    },
    dep: [conversation, controller],
  })

  const handleReply = useCallback(
    () => setTimeout(() => controller.editor.focus(), 0),
    [controller],
  )

  const renderMention = useCallback((mention: string) => <Mention id={mention} />, [])

  return (
    <div className={styles.root}>
      <div className={styles.presence}>
        {conversation.presence.length > 0 && <Participant conversation={conversation} />}
      </div>
      <InputBar
        attachmentDropTargetRef={windowRef}
        controller={controller}
        sendIcon={<SendMessageExternalIcon />}
        renderMention={conversation.isDirect ? renderMention : null}
      />
    </div>
  )
}

export default observer(Compose)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      position: 'relative',
      marginBottom: '1rem',
      alignSelf: 'center',
      width: 'calc(100% - 4rem)',
      maxWidth: 600,

      '@media (max-width: 1500px)': {
        width: 'calc(100% - 3rem)',
      },

      '@media (max-width: 1400px)': {
        width: 'calc(100% - 4rem)',
      },
    },
    presence: {
      display: 'flex',
      flexDirection: 'row-reverse',
      position: 'absolute',
      left: 0,
      right: 0,
      top: -25,
    },
  }),
  { name: Compose.name },
)
