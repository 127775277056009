import { alpha, makeStyles, Theme } from '@material-ui/core'
import React, { DetailsHTMLAttributes, PropsWithChildren } from 'react'
import { ChevronDownIcon } from '../icons/Tint/20/General'
import Typography from '@ui/Typography'
import cx from 'classnames'
import Badge from '@ui/Badge'

export interface CollapsibleProps {
  title: string | React.FC
  actionText?: string
  rootClassName?: string
  open?: boolean
  onToggle?: DetailsHTMLAttributes<HTMLElement>['onToggle']
}

const Collapsible = ({
  title,
  actionText,
  children,
  rootClassName,
  open,
  onToggle,
}: PropsWithChildren<CollapsibleProps>) => {
  const styles = useStyles()

  const TitleComponent =
    typeof title === 'string'
      ? () => (
          <Typography variant="callout" color="textPrimary">
            {title}
          </Typography>
        )
      : title

  return (
    <details className={cx(rootClassName, styles.root)} open={open} onToggle={onToggle}>
      <summary className={styles.header}>
        <div className={styles.headerLeftColumn}>
          <TitleComponent />
        </div>
        <div className={styles.headerRightColumn}>
          {actionText && (
            <Badge label={actionText} variant="purple-light" className={styles.badge} />
          )}
          <ChevronDownIcon className={styles.chevron} />
        </div>
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.op.match({
          light: theme.palette.op.gray[5],
          dark: alpha(theme.palette.op.gray[4], 0.3),
        })}`,
        paddingBottom: 16,
        marginBottom: 16,
      },
    },
    header: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      outlineOffset: 6,
      outlineColor: theme.palette.op.gray[4],
      '&:hover': {
        cursor: 'pointer',
      },
      '&::-webkit-details-marker': {
        display: 'none',
      },
    }),
    chevron: {
      color: theme.palette.op.gray[3],
      '[open] &': {
        transform: `rotate(180deg)`,
      },
    },
    badge: { marginInlineEnd: 16, flexShrink: 0 },
    headerLeftColumn: {
      flex: 1,
    },
    headerRightColumn: { display: 'flex', alignItems: 'center' },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      padding: '20px 0 6px 4px',
    },
  }),
  { name: Collapsible.name },
)

export default Collapsible
