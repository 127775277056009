// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import { fullName, Model } from '.'
import Service from '..'
import { parseDate } from '../../lib'
import { logError } from '../../lib/log'
import { isNonNull } from '../../lib/rx-operators'
import {
  AvailabilityHours,
  IvrSettings,
  PhoneNumberSettings,
  SharedUserRole,
  UserPhoneNumber,
} from '../../types'
import { Integration } from './integration'
import { Member } from './member'

export interface IEntityPhoneNumber {
  availabilityHours: AvailabilityHours
  awayVoicemailId: string
  awayVoicemailUrl: string
  color: string
  createdAt: number
  entityId: string
  entityName: string
  entityType: string
  formattedNumber: string
  forward: string
  groupId: string
  id: string
  ivr: IvrSettings
  mutedUntil: number
  name: string
  number: string
  orgId: string
  ownerId: string
  primary: boolean
  releasedAt: number
  role: SharedUserRole
  settings: PhoneNumberSettings
  sid: string
  status: string
  symbol: string
  timeout: number
  updatedAt: number
  userId: string
  users: UserPhoneNumber[]
  voicemailId: string
  voicemailUrl: string
}

export class EntityPhoneNumber implements IEntityPhoneNumber, Model {
  availabilityHours: AvailabilityHours = null
  awayVoicemailId: string = null
  awayVoicemailUrl: string = null
  color: string = null
  createdAt: number = null
  entityId: string = null
  entityName: string = null
  entityType: string = null
  formattedNumber: string = null
  forward: string = null
  groupId: string = null
  id: string = null
  ivr: IvrSettings = null
  mutedUntil: number = null
  name: string = null
  number: string = null
  orgId: string = null
  ownerId: string = null
  primary: boolean = null
  releasedAt: number = null
  role: SharedUserRole = null
  settings: PhoneNumberSettings = {}
  sid: string = null
  status: string = null
  symbol: string = null
  timeout: number = null
  updatedAt: number = null
  userId: string = null
  users: UserPhoneNumber[] = []
  voicemailId: string = null
  voicemailUrl: string = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get members() {
    return this.users
      .map((u) => this.service.member.collection.get(u.id))
      .filter(isNonNull)
  }

  get userNames(): string {
    if (!this.users) {
      return null
    } else if (this.users.length === 1) {
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  get isForwarding() {
    return Boolean(this.forward)
  }

  get integrations(): Integration[] {
    return this.service.integration.collection.list.filter(
      (i) => (i.type === 'slack' || i.type === 'email') && i.phoneNumberId === this.id,
    )
  }

  update = (attributes: Partial<EntityPhoneNumber>) => {
    Object.assign(this, attributes)
    return this.service.organization.phoneNumber.update(this)
  }

  updateSettings = (attributes: Partial<PhoneNumberSettings>) => {
    Object.assign(this.settings, attributes)
    return this.service.organization.phoneNumber.updateSettings(this)
  }

  makeOwner = (member: Member) => {
    this.entityId = member.id
    this.ownerId = member.id
    this.users = this.users.map((u) => {
      if (u.id !== member.id && u.role == 'owner') {
        return { ...u, role: 'member' }
      } else if (u.id === member.id) {
        return { ...u, role: 'owner' }
      }
      return u
    })
    return this.service.organization.phoneNumber.reassign(this, member.id)
  }

  addMember = (member: Member, role: SharedUserRole) => {
    this.users = this.users.filter((u) => u.id !== member.id)
    this.users.push({
      id: member.id,
      role,
      email: member.email,
      firstName: member.firstName,
      lastName: member.lastName,
    })
    return this.service.organization.phoneNumber.addUser(this, member.id)
  }

  removeMember = (member: Member) => {
    this.users = this.users.filter((u) => u.id !== member.id)
    return this.service.organization.phoneNumber.removeUser(this, member.id)
  }

  toggleInternational = (enabled: boolean) => {
    this.settings.international = { ...this.settings.international, enabled }
    return this.service.organization.phoneNumber.toggleInternational(this)
  }

  fetchIvr = () => {
    return this.service.organization.phoneNumber.fetchIvr(this)
  }

  updateIvr = (ivr: IvrSettings) => {
    this.ivr = ivr
    return this.service.organization.phoneNumber.setIvr(this)
  }

  deleteIvr = () => {
    this.ivr = null
    return this.service.organization.phoneNumber.deleteIvr(this)
  }

  fetchIntegrations = () => {
    return this.service.integration.fetchForPhoneNumber(this.id)
  }

  delete = () => {
    return this.service.organization.phoneNumber.delete(this)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.settings ??= {}
    this.createdAt = parseDate(json.createdAt)
    this.mutedUntil = parseDate(json.mutedUntil)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  setVoicemailUrl = (url: string) => {
    this.voicemailUrl = url
    return this.service.organization.phoneNumber.setVoicemail(this)
  }

  setAwayVoicemailUrl = (url: string) => {
    this.awayVoicemailUrl = url
    return this.service.organization.phoneNumber.setAwayVoicemail(this)
  }

  serialize = (): IEntityPhoneNumber => {
    return {
      availabilityHours: toJS(this.availabilityHours),
      awayVoicemailId: this.awayVoicemailId,
      awayVoicemailUrl: this.awayVoicemailUrl,
      color: this.color,
      createdAt: this.createdAt,
      entityId: this.entityId,
      entityName: this.entityName,
      entityType: this.entityType,
      formattedNumber: this.formattedNumber,
      forward: this.forward,
      groupId: this.groupId,
      id: this.id,
      ivr: toJS(this.ivr),
      mutedUntil: this.mutedUntil,
      name: this.name,
      number: this.number,
      orgId: this.orgId,
      ownerId: this.ownerId,
      primary: this.primary,
      releasedAt: this.releasedAt,
      role: this.role,
      settings: toJS(this.settings),
      sid: this.sid,
      status: this.status,
      symbol: this.symbol,
      timeout: this.timeout,
      updatedAt: this.updatedAt,
      userId: this.userId,
      users: toJS(this.users),
      voicemailId: this.voicemailId,
      voicemailUrl: this.voicemailUrl,
    }
  }
}
