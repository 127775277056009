// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import FileIcon from '../../../file-icon'
import { fileSize, fileType, FileType } from '../../../../lib/file'
import { DecodableMessageMedia } from '../../../../service/model'

export function borderColor(iconType: FileType): string {
  switch (iconType) {
    case 'pdf':
      return '#F86767'
    case 'audio':
      return '#F1B300'
    case 'doc':
      return '#759ED5'
    case 'presentation':
      return '#E46E59'
    case 'csv':
    case 'spreadsheet':
      return '#4DAD76'
    case 'zip':
      return '#8D9063'
    default:
      return '#8FA0B1'
  }
}

export interface FileMediaPreviewProps {
  media: DecodableMessageMedia
}

const FileMediaPreview: React.FC<FileMediaPreviewProps> = function ({ media }) {
  const styles = useStyles({})
  const icon = fileType(media.type, media.name)
  const [size, setSize] = useState<number>(0)

  useEffect(() => {
    if (media.file) {
      setSize(media.file.size)
    }
  }, [media])

  return (
    <div className={styles.root}>
      <FileIcon className={styles.icon} type={icon} />
      <div className={styles.body}>
        <div className={styles.name}>{media.name}</div>
        <div className={styles.size}>{fileSize(size, 0)}</div>
      </div>
    </div>
  )
}

export default FileMediaPreview

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: theme.palette.op.background.highlight(0.07),
      padding: 3,
      borderRadius: 5,
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      margin: '0 7px',
    },
    body: {
      maxWidth: 150,
      marginRight: 20,
    },
    name: {
      fontSize: '0.80rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    size: {
      fontSize: '0.6rem',
      marginTop: 2,
      color: theme.palette.text.secondary,
    },
  }),
  { name: FileMediaPreview.name },
)
