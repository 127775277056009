// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactNode } from 'react'
import cx from 'classnames'

interface FooterProps {
  size?: 'small' | 'medium'
  actions?: ReactNode
  rootClassName?: string
}

export const Footer: React.FC<FooterProps> = function ({
  size,
  actions,
  rootClassName,
  children,
}) {
  const styles = useStyles({ size })
  return (
    <div className={cx(styles.footer, rootClassName)} id="Footer">
      <div className={styles.main}>{children}</div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}

const useStyles = makeStyles<Theme, FooterProps>((theme: Theme) => ({
  footer: ({ size }) => ({
    flex: `0 0 ${getSize(size)}px`,
    height: getSize(size),
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
  }),
  main: {
    flex: '1 0 auto',
  },
  actions: {
    marginLeft: 15,
  },
}))

const getSize = (size: 'small' | 'medium') => {
  switch (size) {
    case 'small':
      return 46
    case 'medium':
      return 60
    default:
      return 60
  }
}
