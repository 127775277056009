import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import IconButton from '../icon-button'
import { ClearTextIcon } from '../icons/Tint/16/General'
import { SearchIcon } from '../icons/Tint/20/General'
import Typography from '@ui/Typography'
import Controller from './controller'

export interface SearchBarProps {
  controller: Controller
}

const SearchBar: React.FC<SearchBarProps> = ({ controller }) => {
  const styles = useStyles({})

  return (
    <div className={styles.root}>
      <Typography color="textSecondary" className={styles.icon}>
        <SearchIcon />
      </Typography>
      <input
        ref={controller.searchRef}
        autoFocus
        data-allow-keyboard-when-empty
        className={styles.input}
        placeholder="Enter a name or phone number…"
        onChange={controller.handleSearchChange}
        onFocus={controller.handleSearchFocus}
        onBlur={controller.handleSearchBlur}
      />
      {controller.search ? (
        <IconButton
          tabIndex={-1}
          title="Clear search"
          icon={<ClearTextIcon />}
          size="small"
          className={styles.clear}
          onClick={controller.handleSearchClear}
        />
      ) : null}
    </div>
  )
}

export default observer(SearchBar, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      margin: '12px 0 0',
    },
    icon: {
      width: 24,
      height: 20,
      marginLeft: 14,
      marginRight: 6,
    },
    input: {
      fontSize: 13,
      lineHeight: '24px',
      color: theme.palette.op.text.primary,
      background: 'none',
      border: 'none',
      outline: 'none',
      flex: 1,
      padding: 0,
      margin: 0,

      '&::placeholder': {
        color: alpha(theme.palette.op.text.primary, 0.4),
      },
    },
    clear: {
      marginRight: 10,
    },
  }),
  { name: SearchBar.name },
)
