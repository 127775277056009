// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { useAppStore } from '@src/app/context'
import {
  ActivityIcon,
  ContactsIcon,
  GiftIcon,
  SearchIcon,
  SettingsIcon,
  AnalyticsIcon,
} from '../../component/icons/Tint/20/General'
import Unread from '../../component/unread'
import { ScrollView } from '../../component/scrollview'
import Alerts from '../alerts'
import Header from '../header'
import AccountMenuButton from './account-menu'
import DirectMessages from './dms'
import PhoneNumbers from './phone-numbers'
import SideMenuRow from './row'
import { isMac } from '../..'

interface SideMenuProps {
  compressed: boolean
}

const SideMenu: React.FC<SideMenuProps> = function ({ compressed }) {
  const styles = useStyles({ compressed })
  const activityButtonRef = useRef<HTMLDivElement>(null)
  const app = useAppStore()
  const { sideMenu, inboxes, alerts } = app

  return (
    <div className={styles.root}>
      {app.is('mac') && <div className={styles.desktopPadding} />}
      <Header className={styles.header}>
        <AccountMenuButton />
      </Header>
      <ScrollView className={styles.scrollView}>
        <div className={styles.top}>
          <SideMenuRow
            title="Go to search"
            shortcut="Meta+/"
            icon={<SearchIcon />}
            onClick={sideMenu.handleSearch}
          >
            Search
          </SideMenuRow>

          <SideMenuRow
            ref={activityButtonRef}
            icon={<ActivityIcon />}
            onClick={sideMenu.handleAlerts}
            highlighted={sideMenu.isAlerts}
            accessory={<Unread count={alerts.unread} />}
          >
            Activity
          </SideMenuRow>

          <Alerts anchorRef={activityButtonRef} />

          <SideMenuRow
            icon={<ContactsIcon />}
            onClick={sideMenu.handleContacts}
            highlighted={sideMenu.isContacts}
          >
            Contacts
          </SideMenuRow>

          <SideMenuRow
            icon={<AnalyticsIcon />}
            onClick={sideMenu.handleAnalytics}
            highlighted={sideMenu.isAnalytics}
          >
            Analytics
          </SideMenuRow>
          <SideMenuRow
            icon={<SettingsIcon />}
            onClick={sideMenu.handleSettings}
            highlighted={sideMenu.isSettings}
          >
            Settings
          </SideMenuRow>

          <div className={styles.divider} />
          <PhoneNumbers
            selectedId={sideMenu.isInbox && inboxes.selected?.id}
            onClick={sideMenu.handleInboxSelection}
          />
          <DirectMessages />
        </div>

        <div className={styles.bottom}>
          <SideMenuRow
            className={styles.bottomRow}
            onClick={sideMenu.handleReferFriend}
            icon={<GiftIcon />}
          >
            Refer a friend
            {!compressed && <span className={styles.earnTwenty}>Earn $20</span>}
          </SideMenuRow>
        </div>
      </ScrollView>
    </div>
  )
}

export default observer(SideMenu)

const useStyles = makeStyles<Theme, Partial<SideMenuProps>>((theme: Theme) => ({
  root: ({ compressed }) => ({
    flex: `0 1 ${compressed ? 192 : 248}px`,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.op.gray[6],
    zIndex: 2,
    borderRight: `1.5px solid ${theme.palette.op.border.common}`,

    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  }),
  scrollView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    flex: 1,
    paddingBottom: 25,
  },
  bottom: {
    padding: '25px 0 10px',
  },
  bottomRow: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  header: {
    padding: '0 10px 0 0',
    marginTop: isMac ? 25 : 0,
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  desktopPadding: {
    height: 25,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    '-webkit-app-region': isMac ? 'drag' : undefined,
  },
  divider: {
    margin: '10px 0',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  earnTwenty: {
    borderRadius: '11px',
    padding: '4px 9px',
    backgroundColor: theme.palette.op.hover.primary,
    marginLeft: '1rem',
  },
}))
