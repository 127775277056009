// @ts-strict-ignore
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { searchIndex } from '../../lib/collections'
import { DisposeBag } from '../../lib/dispose'
import shortId from '../../lib/short-id'
import { throttle } from '../../lib/throttle'
import { Collection, ImmutableCollection } from '../../service/model/base'

type ScrollAlignment = 'nearest' | 'top' | 'center'

export interface VirtualListItem {
  id: string
}

export interface VirtualListItemView {
  id: string
  index: number
  offset: number
  height: number
}

export interface VirtualListStoreOptions<T extends VirtualListItem> {
  name?: string
  pinToIndex: number
  pinToBottom: boolean
  listPadding?: { top: number; bottom: number }
  itemSpacing?: { top?: number; bottom?: number }
  itemEstimatedHeight?: ((item: T, index: number) => number) | number
  viewportHeight?: number
}

export class VirtualListStore<T extends VirtualListItem> {
  readonly id = shortId()

  /**
   * List of items to render. This would be a subset of the overall list that's
   * needed to poplate the visible vewport with items
   */
  items: T[] = []

  /**
   * Map of Item IDs to each item's corresponding view data.
   */
  views: { [key: string]: VirtualListItemView } = {}

  /**
   * Observe to be notified when scroll is about to reach the top
   */
  topReached: boolean = null

  /**
   * Observe to be notified when scroll is about to reach the bottom
   */
  bottomReached: boolean = null

  /**
   * Observe to set the scroll top of the list view
   */
  scrollTo: { top: number; left: number } = { top: 0, left: 0 }

  /**
   * Points to the first visible item on the screen. This is used to keep scroll
   * position in tact and heights change or new items are added
   */
  protected topVisibleItem: T = null

  /**
   * Holds a reference to the item the list was scrolled to. This is used to keep
   * scroll position static relative to this item as surrounding elements report
   * their updated heights.
   */
  protected scrolledToItem: T = null

  /**
   * Number of pixels to render before and after the visible viewport
   */
  protected overscan: number = 750

  /**
   * The scroll position of the list view
   */
  protected offsetTop: number = null

  /**
   * The height of the visible part of the list view
   */
  protected viewportHeight: number = null

  /**
   * Is true if the scroll is all the way at the bottom and the user wants it
   * pinned there.
   */
  protected scrollPinned: boolean = null

  protected debug: any

  protected disposeBag = new DisposeBag()

  constructor(
    protected data: Collection<T> | ImmutableCollection<T>,
    protected options: VirtualListStoreOptions<T>,
  ) {
    this.scrollPinned = this.options.pinToBottom
    this.viewportHeight = this.options.viewportHeight
    this.debug = Debug(`op:vlist:store:@${this.options.name ?? this.id}`)

    this.debug(`VirtualListStore instantiated`)
    this.calculateOffsets()

    if (options.pinToIndex) {
      this.scrollToIndex(options.pinToIndex)
    }

    this.disposeBag.add(
      this.data instanceof Collection
        ? this.data.observe((changes) => {
            this.debug(`Observe: data. Recalculate offsets and set visible items.`, {
              changes,
            })
            this.calculateOffsetsAndAdjustScrollPosition()
            this.setVisibleItems()
          })
        : null,
      reaction(
        () => [this.height, this.viewportHeight],
        () => {
          this.debug(
            `Reaction: height, viewportHeight changed. Scroll to bottom if necessary..`,
          )
          this.scrollToBottomIfNecessary()
        },
        { name: 'AdjustScrollPosition', fireImmediately: true },
      ),
      reaction(
        () => [this.height, this.viewportHeight, this.overscan, this.offsetTop],
        throttle(() => {
          this.debug(
            `Reaction: height, viewportHeight, overscan, offsetTop. Set visible items.`,
          )
          this.setVisibleItems()
        }, 200),
        { name: 'AdjustViewportItems', fireImmediately: true },
      ),
    )

    makeObservable<
      this,
      | 'calculateOffsets'
      | 'topVisibleItem'
      | 'scrolledToItem'
      | 'overscan'
      | 'offsetTop'
      | 'viewportHeight'
      | 'scrollPinned'
      | 'scrollToBottomIfNecessary'
      | 'setVisibleItems'
      | 'calculateOffsets'
      | 'calculateOffsetsAndAdjustScrollPosition'
      | 'calculateViewHeight'
    >(this, {
      items: observable.ref,
      views: observable.shallow,
      topReached: observable.ref,
      bottomReached: observable.ref,
      scrollTo: observable.ref,
      topVisibleItem: observable.ref,
      scrolledToItem: observable.ref,
      overscan: observable.ref,
      offsetTop: observable.ref,
      viewportHeight: observable.ref,
      scrollPinned: observable.ref,

      height: computed,

      scrollToIndex: action,
      scrollIntoView: action,
      scrollToBottom: action,
      setOffsetTop: action,
      setViewportHeight: action,
      scrollToBottomIfNecessary: action,
      setHeights: action,
      setVisibleItems: action,
      calculateOffsetsAndAdjustScrollPosition: action,
      calculateOffsets: action,
      calculateViewHeight: action,
      setScrollTo: action,
    })
  }

  get height() {
    if (this.data.length === 0) return 0
    // The height of the virtual list can be calculated by adding the last
    // item's (pre)calculated offset and its height
    const view = this.views[this.data.list[this.data.length - 1].id]
    const viewHeight = this.calculateViewHeight(view)
    const height =
      view.offset +
      viewHeight +
      (this.options.listPadding?.top + this.options.listPadding?.bottom || 0)
    this.debug('calculated height: %O', height)
    return height
  }

  scrollToIndex(index: number, alignment: ScrollAlignment = 'top') {
    this.debug(`scrollToIndex(${index}, ${alignment})`)
    const item = this.data.list[index]
    if (!item) {
      this.debug(`scrollToIndex item not found. Returning.`)
      return
    }

    const view = this.views[item.id]
    this.scrolledToItem = item
    this.scrollPinned = false

    if (alignment === 'top') {
      this.setScrollTo(view.offset - 100)
    } else if (alignment === 'center') {
      const offset = this.viewportHeight / 2 - view.height / 2
      this.setScrollTo(view.offset - offset)
    } else if (alignment === 'nearest') {
      const itemTop = view.offset
      const itemBottom = view.offset + view.height
      const cutoffTop = Math.max(0, this.offsetTop)
      if (itemTop < cutoffTop) {
        this.debug(`scrollToIndex align top.`)
        this.setScrollTo(view.offset)
      } else {
        this.debug(`scrollToIndex align bottom.`)
        this.setScrollTo(itemBottom - this.viewportHeight)
      }
    }
  }

  /**
   * Brings an item into the view. If the item is already fully visible, then
   * this does nothing. Otherwise it will center the item in the list.
   */
  scrollIntoView(index: number, alignment: ScrollAlignment = 'center') {
    this.debug(`scrollIntoView(${index})`)
    const { data, height, offsetTop, viewportHeight } = this
    const item = data.list[index]
    if (!item) {
      this.debug(`scrollIntoView item not found. Returning.`)
      return
    }

    const view = this.views[item.id]
    const cutoffTop = Math.max(0, this.offsetTop)
    const cutoffBottom = Math.min(height, offsetTop + viewportHeight)
    const itemTop = view.offset
    const itemBottom = view.offset + view.height
    const isVisible = cutoffTop <= itemTop && itemBottom <= cutoffBottom

    if (!isVisible) {
      this.debug(`scrollIntoView item not visible. Scrolling to ${alignment}.`)
      this.scrollToIndex(index, alignment)
    } else {
      this.debug('scrollIntoView view already visible. Doing nothing.')
    }
  }

  scrollToBottom() {
    if (this.height > 0) {
      this.debug(`scrollToBottom()`)
      this.setScrollTo(this.height - this.viewportHeight)
      this.scrolledToItem = null
    }
  }

  /**
   * Called by the view when the list is scrolled
   * @param offsetTop
   */
  setOffsetTop(offsetTop: number) {
    const prevScrollPinned = this.scrollPinned
    if (this.options.pinToBottom) {
      if (offsetTop < this.offsetTop) {
        this.scrollPinned = false
      } else if (offsetTop >= this.height - this.viewportHeight - 20) {
        this.scrollPinned = true
      }
    }

    this.debug(`setOffsetTop(${offsetTop})`, {
      scrollPinned: this.scrollPinned,
      prevScrollPinned,
      scrollTop: this.scrollTo.top,
    })

    if (this.scrollTo.top !== offsetTop) {
      this.scrolledToItem = null
    }

    if (this.offsetTop !== null) {
      this.topReached = this.offsetTop < this.viewportHeight / 2
      this.bottomReached = this.height - this.offsetTop < this.viewportHeight * 1.5
    }

    this.offsetTop = offsetTop
  }

  setViewportHeight(viewportHeight: number) {
    this.debug('setViewportHeight (viewportHeight: %O)', viewportHeight)
    this.viewportHeight = viewportHeight
  }

  /**
   * Call this function when you know the heights of visible items on the screen. Pass
   * an object of item ids to their heights. this will update the items and keep the scroll
   * position in place as new heights change the offsets.
   * @param heights
   */
  setHeights(heights: { [key: string]: number }) {
    let changed = false
    Object.keys(heights).forEach((id) => {
      const view = this.views[id]
      const height = heights[id]
      if (view.height !== height) {
        changed = true
        this.views[id] = { ...view, id, height }
      }
    })
    this.debug(`setHeights for ${Object.keys(heights).length} items.`, { changed })
    this.setScrollTo(this.offsetTop)
    if (changed) {
      this.calculateOffsetsAndAdjustScrollPosition()
    }
  }

  getOffsetForRow(index: number) {
    const item = this.data.list[index]
    return item ? this.views[item.id].offset : undefined
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  /**
   * Computes the start and end index for visible items
   */
  private setVisibleItems() {
    const { height, viewportHeight, overscan, scrollPinned } = this
    const offsetTop = this.offsetTop ?? (scrollPinned ? height - viewportHeight : 0)

    if (viewportHeight === 0 || height === 0) {
      this.items = []
      this.debug(
        `setVisibleItems(): viewportHeight or height are zero. Setting items to []`,
        { height, viewportHeight, offsetTop },
      )
      return
    }

    const cutoffTop = Math.max(0, offsetTop - overscan)
    const cutoffBottom = Math.min(height, offsetTop + viewportHeight + overscan)
    const indexTopVisible = this.getIntersectingItemIndex(offsetTop)
    const indexTop = this.getIntersectingItemIndex(cutoffTop)
    const indexBottom = this.getIntersectingItemIndex(cutoffBottom)
    const newItems = this.data.list.slice(indexTop, indexBottom + 1)
    this.topVisibleItem = this.data.list[indexTopVisible]

    if (this.itemsChanged(this.items, newItems)) {
      this.debug(
        `setVisibleItems: changing visible slice to (${indexTop}, ${indexBottom + 1})`,
        { height, viewportHeight, offsetTop },
      )
      this.items = newItems
    } else {
      this.debug(`setVisibleItems: visible slice is unchanged.`, {
        height,
        viewportHeight,
        offsetTop,
      })
    }
  }

  /**
   * Updates the offsets of each element and at the same time, makes sure
   * the visible elements on the screen don't jump around or if the scroll
   * is pinned, it will remain pinned
   */
  private calculateOffsetsAndAdjustScrollPosition() {
    if (this.options.pinToBottom && !this.scrollPinned) {
      const offsetItem = this.scrolledToItem ?? this.topVisibleItem
      const offsetBefore = this.views[offsetItem?.id]?.offset
      this.debug(`calculateOffsetsAndAdjustScrollPosition: preserve item offset.`, {
        pinnedItem: this.scrolledToItem,
      })
      this.calculateOffsets()
      const offsetDiff = this.views[offsetItem?.id]?.offset - offsetBefore
      if (offsetItem && offsetDiff != 0) {
        this.setScrollTo(this.offsetTop + offsetDiff)
      }
    } else {
      this.debug(`calculateOffsetsAndAdjustScrollPosition.`, {
        scrollPinned: this.scrollPinned,
      })
      this.calculateOffsets()
    }
  }

  /**
   * Scrolls the list to the bottom if the configirations allow it
   */
  private scrollToBottomIfNecessary() {
    if (!this.scrolledToItem && this.scrollPinned) {
      this.scrollToBottom()
    }
  }

  /**
   * Get the intersecting item at a given cutoff point.
   */
  private getIntersectingItemIndex(cutoff: number): number {
    const index = searchIndex(this.data.list, (item) => {
      const view = this.views[item.id]
      const top = view.offset
      const bottom = view.offset + this.calculateViewHeight(view)
      if (top > cutoff) return 1
      if (bottom < cutoff) return -1
      return 0
    })

    return Math.min(this.data.length - 1, Math.max(0, index))
  }

  /**
   * Reclaculates offsets everytime there's a change to the list.
   * TODO: Think through how this can be optimized to not require a full scan
   * on every change.
   */
  private calculateOffsets() {
    this.debug(`calculateOffsets for ${this.data?.length ?? 0} items.`)
    this.data?.list.forEach((item, index) => {
      const previousView = this.views[this.data.list[index - 1]?.id]
      const view = this.views[item.id]
      const offset = previousView
        ? previousView.offset + this.calculateViewHeight(previousView)
        : this.options.listPadding?.top ?? 0
      this.views[item.id] = {
        id: item.id,
        index,
        offset,
        height: view?.height ?? this.estimatedItemHeight(item, index),
      }
    })
  }

  private calculateViewHeight(view: VirtualListItemView): number {
    return (
      (this.options.itemSpacing?.top ?? 0) +
      view.height +
      (this.options.itemSpacing?.bottom ?? 0)
    )
  }

  private estimatedItemHeight(item: T, index: number): number {
    if (typeof this.options.itemEstimatedHeight === 'number') {
      return this.options.itemEstimatedHeight
    }
    return this.options.itemEstimatedHeight?.(item, index)
  }

  setScrollTo(value: number) {
    if (this.height === 0 || this.viewportHeight === 0) {
      this.offsetTop = 0
    } else {
      const lowerbound = Math.max(value, 0)
      const upperbound = Math.max(this.height - this.viewportHeight, 0)
      this.offsetTop = Math.min(lowerbound, upperbound)
    }
    this.scrollTo = { top: this.offsetTop, left: 0 }
    this.debug(`setScrollTo ${this.scrollTo.top}`, { value })
  }

  /**
   * Check to see if the new list of visible items is similar to the old list. Two lists are
   * similar if:
   *    1. They contain identical items.
   *    2. The new list is the subset of the old list AND the elements in the old list that
   *       are not in the new list are still in the original data source.
   */
  private itemsChanged(oldItems: T[], newItems: T[]): boolean {
    // Do some basic checks
    if (newItems.length === 0 && oldItems.length === 0) return false
    if (newItems.length === 0) return true
    if (oldItems.length < newItems.length) return true

    // See if we can find the first and last items in both lists
    const startIndex = oldItems.indexOf(newItems[0])
    if (startIndex === -1) return true

    const endIndex = oldItems.indexOf(newItems[newItems.length - 1])
    if (endIndex === -1) return true

    // Check of newItems is a subset of oldItems
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i] !== oldItems[i + startIndex]) return true
    }

    // Check if the elements at the start of oldItems that are missing from
    // new items are still in the original data source
    for (let i = 0; i < startIndex; i++) {
      if (!this.data.has(oldItems[i].id)) return true
    }

    // Check if the elements at the end of oldItems that are missing from
    // new items are still in the original data source
    for (let i = endIndex; i < oldItems.length; i++) {
      if (!this.data.has(oldItems[i].id)) return true
    }

    // Seems like they are similar
    return false
  }
}
