// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '../..'
import { parseDate } from '../../../lib'
import { IntegrationEvent } from '../../../types'
import { Model } from '../base'

export class EmailIntegration implements Model {
  id: string = null
  type: 'email' = 'email'
  events: IntegrationEvent = null
  phoneNumberId: string = null
  createdAt: number = null
  to: string[] = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    this.service.integration.updateLegacy(this)
  }

  delete() {
    this.service.integration.deleteLegacy(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      events: toJS(this.events),
      phoneNumberId: this.phoneNumberId,
      createdAt: this.createdAt,
      to: toJS(this.to),
    }
  }
}
