// @ts-strict-ignore
import Debug, { Debugger } from 'debug'
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  ObservableMap,
  remove,
} from 'mobx'
import { Subject, Subscription } from 'rxjs'
import {
  insertAtIndex,
  isArrayOfStrings,
  partition,
  removeAtIndex,
  runInBatches,
  sortedIndex,
} from '../../lib'
import { isNonNull } from '../../lib/rx-operators'
import shortId from '../../lib/short-id'
import Repository from '../worker/repository/base'

/**
 * The base class for any object that has a displayable profile
 * i.e. contacts, members, orgs, groups, etc
 */
export interface Identity {
  id: string
  name: string
  shortName: string
  initials: string
  pictureUrl?: string
  pictureSymbol?: string
  phones: IdentityPhone[]
  emailAddresses: string[]
  isAnonymous: boolean
  source?: string
}

export function isIdentity(obj: any): obj is Identity {
  return (
    obj &&
    typeof obj.id === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.shortName === 'string' &&
    typeof obj.initials === 'string' &&
    Array.isArray(obj.phones) &&
    Array.isArray(obj.emailAddresses) &&
    typeof obj.isAnonymous === 'boolean'
  )
}

export interface IdentityPhone {
  id: string | null
  name: string | null
  symbol: string | null
  number: string
}

/**
 * Model class acting as base for every domain object
 */
export interface Model {
  id: string
  deserialize(json: any): this
  serialize(): any
  tearDown?(): void
}

export interface ImmutableCollectionOptions<T extends { [key: string]: any }> {
  /**
   * Specify the unique identifier of the elements.
   *
   * @default id
   */
  readonly idKey?: string

  /**
   * Set a filter function for this collection.
   *
   * If set, the collection will filter its elements.
   */
  filter?: (item: T) => boolean

  /**
   * Set a comparator function for this collection.
   *
   * If set, the collection will sort its elements.
   */
  compare?: (a: T, b: T) => number
}

export class ImmutableCollection<T extends { [key: string]: any }> {
  protected elements: Map<string, T> = new Map()
  protected elementIds: string[] = []

  protected debug: Debugger
  protected idKey: string
  protected filter: (item: T) => boolean = null
  protected compare: (a: T, b: T) => number = null

  constructor(
    items: readonly T[] | undefined,
    { idKey = 'id', filter, compare }: ImmutableCollectionOptions<T> = {},
  ) {
    this.idKey = idKey
    this.filter = filter
    this.compare = compare
    this.debug = Debug(`op:collection:${shortId()}`)
    if (items) this._putBulk(items)

    makeObservable<this, '_insertId' | '_put' | '_putBulk'>(this, {
      keys: computed,
      list: computed,
      length: computed,
      _insertId: action,
      _put: action,
      _putBulk: action,
    })
  }

  get list(): T[] {
    return this.elementIds.map((id) => this.elements.get(id))
  }

  get keys(): readonly string[] {
    return this.elementIds
  }

  get length(): number {
    return this.elements.size
  }

  clone(items?: readonly T[]) {
    const options: Required<ImmutableCollectionOptions<T>> = {
      idKey: this.idKey,
      filter: this.filter,
      compare: this.compare,
    }

    return new ImmutableCollection(items ?? this.list, options)
  }

  indexOf(item: T | string | null): number {
    if (!item) return -1
    const id = typeof item === 'string' ? item : item.id
    return this.elementIds.indexOf(id)
  }

  find(handler: (item: T) => boolean): T {
    return this.list.find(handler)
  }

  get(id: string): T | null {
    return this.elements.get(id) ?? null
  }

  has(id: string): boolean {
    return this.elements.has(id)
  }

  protected _put(obj: T) {
    if (this.filter && !this.filter(obj)) return
    this.elements.set(obj[this.idKey], obj)
    this._insertId(obj[this.idKey])
  }

  protected _putBulk(objs: readonly T[]) {
    objs = objs.filter((obj: T) => this.filter?.(obj) ?? true)

    const newIds = objs
      .filter((obj) => !this.elements.has(obj[this.idKey]))
      .map((obj) => obj[this.idKey])

    this.elementIds.push(...newIds)

    objs.forEach((obj) => this.elements.set(obj[this.idKey], obj))

    if (this.compare) {
      this.elementIds.sort((a, b) => this.compare(this.get(a), this.get(b)))
    }
  }

  protected _insertId(id: string) {
    const oldIndex = this.elementIds.indexOf(id)

    if (this.compare) {
      if (oldIndex >= 0) this.elementIds = removeAtIndex(this.elementIds, oldIndex)
      const index = sortedIndex(this.elementIds, id, (a, b) =>
        this.compare(this.get(a), this.get(b)),
      )
      this.elementIds = insertAtIndex(this.elementIds, id, index)
    } else {
      if (oldIndex < 0) this.elementIds.push(id)
    }
  }
}

export type CollectionChange<T> =
  | { type: 'put'; objects: readonly T[] }
  | { type: 'delete'; objects: readonly T[] }

export interface CollectionOptions<T extends { [key: string]: any }>
  extends ImmutableCollectionOptions<T> {
  /**
   * Bind elements to this collection.
   *
   * If true, setup and teardown logic on the models will run when they are
   * added or removed from the collection.
   *
   * @default false
   */
  readonly bindElements?: boolean
}

type Status = 'idle' | 'loading' | 'success' | 'error'

/**
 * Observable collection of items
 */
export class Collection<T extends { [key: string]: any }> extends ImmutableCollection<T> {
  protected override elements: ObservableMap<string, T> = observable.map({})
  protected override elementIds: IObservableArray<string> = observable.array([])
  protected change$ = new Subject<CollectionChange<T>>()

  protected bindElements: boolean

  constructor({
    idKey,
    bindElements = false,
    filter,
    compare,
  }: CollectionOptions<T> = {}) {
    super(undefined, { idKey, filter, compare })
    this.bindElements = bindElements

    makeObservable<this, 'elements' | 'elementIds'>(this, {
      elements: observable,
      elementIds: observable,
      put: action,
      putBulk: action,
      delete: action,
      deleteBulk: action,
      clear: action,
      replace: action,
    })
  }

  put(obj: T) {
    if (!obj) return
    this._put(obj)
    this.change$.next({ type: 'put', objects: [obj] })
  }

  putBulk(objs: readonly T[]) {
    if (!objs || objs.length === 0) return
    objs = objs.filter(isNonNull)
    this._putBulk(objs)
    this.change$.next({ type: 'put', objects: objs })
  }

  delete(o: string | T) {
    const id = typeof o === 'string' ? o : o[this.idKey]
    this.deleteByIds([id])
  }

  deleteBulk(objects: string[] | readonly T[]) {
    const ids = isArrayOfStrings(objects)
      ? objects
      : objects.filter(isNonNull).map((o) => o[this.idKey])
    this.deleteByIds(ids)
  }

  clear() {
    /**
     * Mark all properties as null before deleting
     * so that all relationships get unwound
     */
    const toDelete = [...this.elements.values()].filter(isNonNull)
    if (toDelete.length === 0) return
    if (this.bindElements) this.elements.forEach((el) => el?.tearDown?.())
    this.elements.replace({})
    this.elementIds.replace([])
    this.change$.next({ type: 'delete', objects: toDelete })
  }

  replace(items: readonly T[]) {
    this.clear()
    this.putBulk(items)
  }

  bind(collection: Collection<T>): Subscription {
    this.clear()

    const filteredItems = this.filter
      ? collection.list.filter(this.filter)
      : collection.list
    const items = this.compare ? filteredItems.sort(this.compare) : filteredItems

    this.elements.replace(items.map((item) => [item[this.idKey], item]))
    this.elementIds.replace(items.map((item) => item[this.idKey]))
    this.change$.next({ type: 'put', objects: items })

    return collection.observe((change) => {
      this.applyChange(change)
    })
  }

  observe(handler: (changes: CollectionChange<T>) => void) {
    return this.change$.asObservable().subscribe(handler)
  }

  protected deleteByIds(ids: string[]) {
    const objects = ids.map((id) => this.get(id)).filter(isNonNull)
    if (objects.length === 0) return
    for (const object of objects) {
      const id = object[this.idKey]
      if (this.bindElements) object.tearDown?.()
      remove(this.elements, id)
      const index = this.elementIds.indexOf(id)
      this.elementIds.replace(removeAtIndex(this.elementIds, index))
    }
    this.change$.next({ type: 'delete', objects })
  }

  protected applyChange(change: CollectionChange<T>) {
    if (change.type === 'put') {
      this.applyPutChange(change.objects)
    } else if (change.type === 'delete') {
      this.applyDeleteChange(change.objects)
    }
  }

  protected applyPutChange(objects: readonly T[]) {
    const [toInsert, toDelete] = this.filter
      ? partition(objects, this.filter)
      : [objects, []]

    this.deleteBulk(toDelete.map((o) => o.id))
    this.putBulk(toInsert)
  }

  protected applyDeleteChange(objects: readonly T[]) {
    this.deleteBulk(objects.map((o) => o[this.idKey]))
  }
}

export interface PersistedCollectionOptions<T extends Model, Repo extends Repository<T>> {
  readonly table: Repo
  readonly classConstructor: (json: any) => T
  readonly idKey?: string
  filter?: (item: T) => boolean
  compare?: (a: T, b: T) => number
}

export class PersistedCollection<
  T extends Model,
  Repo extends Repository<T>,
> extends Collection<T> {
  private lazyIds = new Set<string>()
  private lazyTimeout: any

  protected table: Repo
  protected classConstructor: (json: any) => T

  constructor({
    table,
    classConstructor,
    idKey = 'id',
    filter,
    compare,
  }: PersistedCollectionOptions<T, Repo>) {
    super({ idKey, bindElements: true, filter, compare })
    this.table = table
    this.classConstructor = classConstructor
    makeObservable<this, 'lazyIds'>(this, {
      load: action,
      lazyIds: false,
    })
  }

  override get(
    id: string,
    opts: { skipStorage: boolean } = { skipStorage: false },
  ): T | null {
    if (!id) return null
    const element = this.elements.get(id) ?? null
    if (!element && !opts.skipStorage) {
      this.getLazily(id)
    }
    return element
  }

  override has(id: string): boolean {
    if (!id) return false
    const element = super.has(id)
    if (!element) {
      this.getLazily(id)
    }
    return element
  }

  isInMemory(id: string) {
    return super.has(id)
  }

  override put(obj: T) {
    this.table.put(obj.serialize())
    super.put(obj)
  }

  override putBulk(objs: readonly T[]) {
    const data = objs.map((o) => o.serialize())
    this.table.putBulk(data)
    super.putBulk(objs)
  }

  override delete(o: string | T) {
    if (typeof o === 'string') {
      this.table.delete(o)
    } else {
      this.table.delete(o[this.idKey] as any)
    }
    super.delete(o)
  }

  override deleteBulk(o: string[] | readonly T[]) {
    if (isArrayOfStrings(o)) {
      this.table.deleteBulk(o)
    } else {
      this.table.deleteBulk(o.map((o) => o[this.idKey]))
    }
    super.deleteBulk(o)
  }

  performQuery<R>(query: (repo: Repo) => Promise<R>): Promise<R> {
    return query(this.table).then((data) => {
      if (Array.isArray(data)) {
        return this.load(data, { skipPersisting: true }) as any
      } else {
        return this.load(data, { skipPersisting: true })[0]
      }
    })
  }

  deleteQuery(query: (repo: Repo) => Promise<T[]>): Promise<void> {
    return query(this.table).then((data) => this.deleteBulk(data))
  }

  /**
   * Updates the memory objects or creates new ones based on the passed in data.
   * If the save options is true, saves objects to persistent DB.
   * Note: Consider automating the save step to store back to DB when the object has
   * changed compared to the memory version.
   */
  load = async (
    data: any,
    opts: { skipPersisting?: boolean; deleteOthers?: boolean } = {},
  ): Promise<T[]> => {
    const { skipPersisting, deleteOthers } = opts

    if (!data) return []

    if (!Array.isArray(data)) {
      data = [data]
    }

    const objects = await runInBatches(data, 1000, (batch) =>
      batch
        .map((json) => {
          if (!json) return
          const id = json[this.idKey]
          let object = this.elements.get(id)
          if (object) {
            return object.deserialize(json)
          } else {
            return this.classConstructor(json)?.deserialize(json)
          }
        })
        .filter(isNonNull),
    )

    /**
     * Turns json into model instances. The conversion is done in batches to
     * not clog up the runtime with massive data sets.
     */

    super.putBulk(objects)

    if (!skipPersisting) {
      const objs = objects.map((o) => o.serialize())
      this.table.putBulk(objs)
    }

    if (deleteOthers) {
      const keys: string[] = objects.map((o) => o[this.idKey] as any)
      this.table
        .getOthers(keys)
        .then((others) => this.deleteBulk(others.map((o) => o.id)))
    }

    return objects
  }

  private getLazily(id: string) {
    this.lazyIds.add(id)
    clearTimeout(this.lazyTimeout)
    this.lazyTimeout = setTimeout(() => {
      const ids = [...this.lazyIds]
      this.lazyIds.clear()
      this.table.getBulk(ids).then((data) => this.load(data, { skipPersisting: true }))
    }, 0)
  }
}
