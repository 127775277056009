// @ts-strict-ignore
import { makeAutoObservable, toJS } from 'mobx'
import Service from '..'
import { parseDate } from '../../lib'
import ObjectID from '../../lib/object-id'
import { Enrichment } from './activity'
import { Model } from './base'
import { Contact } from './contact'

export interface INote {
  createdAt: number
  deletedAt: number
  id: string
  private: boolean
  text: string
  updatedAt: number
  userId: string
}

export class Note implements INote, Model {
  createdAt: number = Date.now()
  deletedAt: number = null
  id: string = ObjectID()
  private: boolean = null
  text: string = null
  updatedAt: number = Date.now()
  userId: string = null
  enrichment: Enrichment = null

  constructor(
    private service: Service,
    public contact: Contact,
    attrs: Partial<Note> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get canEdit() {
    return this.userId === this.service.user.current.id
  }

  get user() {
    return this.service.member.collection.get(this.userId)
  }

  update(text: string) {
    this.text = text
    return this.service.contact.putNote(this)
  }

  delete() {
    this.contact.notes = this.contact.notes.filter((n) => n !== this)
    return this.service.contact.deleteNote(this)
  }

  deserialize(json: any) {
    if (json) {
      Object.assign(this, json)
      this.updatedAt = parseDate(json.updatedAt || this.updatedAt)
      this.createdAt = parseDate(json.createdAt || this.createdAt)
      this.deletedAt = parseDate(json.deletedAt || this.deletedAt)
    }
    return this
  }

  serialize() {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      enrichment: toJS(this.enrichment),
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  toJSON(): INote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}
