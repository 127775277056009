import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'
import Avatar, { getAvatarCssFontSize, type AvatarSize } from '@ui/Avatar'

export interface AvatarGroupProps {
  size?: AvatarSize
  className?: string
  children: React.ReactNode
}

export const AvatarGroup = ({ children, className, size = 40 }: AvatarGroupProps) => {
  const styles = useStyles({ size })

  const childrenLength = React.Children.count(children)
  const childrenArray = React.Children.toArray(children)

  const count = Math.min(childrenLength, 4)
  const avatarsCount = childrenLength > 4 ? 3 : childrenLength
  const extra = childrenLength - avatarsCount
  const avatars = childrenArray.slice(0, avatarsCount)

  return (
    <div className={cx(styles.root, styles[`participant${count}`], className)}>
      {avatars.length === 0 ? (
        <Avatar size={size} className={styles.avatar} />
      ) : (
        avatars.map((element) => {
          if (!React.isValidElement(element)) {
            throw new Error('AvatarGroup children must be valid Avatar elements')
          }

          return React.cloneElement(element, {
            className: cx(styles.avatar, element.props.className),
            size,
          })
        })
      )}
      {extra > 0 && <div className={styles.more}>{extra}</div>}
    </div>
  )
}

export default AvatarGroup

const mask = {
  2: {
    p1: require('./p2-mask-1.svg').default,
  },
  3: {
    p1: require('./p3-mask-1.svg').default,
    p2: require('./p3-mask-2.svg').default,
  },
}

const useStyles = makeStyles<Theme, Required<Pick<AvatarGroupProps, 'size'>>>(
  (theme) => ({
    root: ({ size }) => {
      return {
        minWidth: size,
        height: size,
        position: 'relative',
      }
    },
    participant0: {},
    participant1: {},
    participant2: {
      '& $avatar:nth-child(1)': {
        position: 'absolute',
        transform: 'scale(0.65)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        maskImage: `url('${mask[2].p1}')`,
      },
      '& $avatar:nth-child(2)': {
        position: 'absolute',
        transform: 'scale(0.65)',
        transformOrigin: 'bottom right',
        bottom: 0,
        right: 0,
      },
    },
    participant3: {
      '& $avatar:nth-child(1)': {
        position: 'absolute',
        transform: 'scale(0.55)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        maskImage: `url('${mask[3].p1}')`,
      },
      '& $avatar:nth-child(2)': {
        position: 'absolute',
        transform: 'scale(0.55)',
        transformOrigin: 'top right',
        top: 0,
        right: 0,
        maskImage: `url('${mask[3].p2}')`,
      },
      '& $avatar:nth-child(3)': {
        position: 'absolute',
        transform: 'scale(0.55)',
        transformOrigin: 'bottom center',
        bottom: 0,
        right: 0,
      },
    },
    participant4: {
      '& $avatar:nth-child(1)': {
        position: 'absolute',
        transform: 'scale(0.475)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
      },
      '& $avatar:nth-child(2)': {
        position: 'absolute',
        transform: 'scale(0.475)',
        transformOrigin: 'top right',
        top: 0,
        right: 0,
      },
      '& $avatar:nth-child(3)': {
        position: 'absolute',
        transform: 'scale(0.475)',
        transformOrigin: 'bottom left',
        bottom: 0,
        left: 0,
      },
      '& $avatar:nth-child(4)': {
        position: 'absolute',
        transform: 'scale(0.475)',
        transformOrigin: 'bottom right',
        bottom: 0,
        right: 0,
      },
    },
    avatar: {
      maskSize: 'contain',
    },
    more: ({ size }) => {
      const fontSize = getAvatarCssFontSize(size)

      return {
        width: size,
        height: size,
        bottom: 0,
        right: 0,
        position: 'absolute',
        borderRadius: '50%',
        background: theme.palette.op.primary[2],
        fontSize,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.op.text.primary,
        transform: 'scale(0.475)',
        transformOrigin: 'bottom right',
      }
    },
  }),
)
