// @ts-strict-ignore
import {
  action,
  computed,
  makeAutoObservable,
  makeObservable,
  reaction,
  when,
} from 'mobx'
import { Subscription } from 'rxjs'
import KeyboardListController from '../../../component/keyboard-list/controller'
import { logError } from '../../../lib/log'
import Stepper from '../../../lib/stepper'
import { ConversationsQuery } from '../../../service/conversation-store'
import { Collection, Conversation, Member, Participant } from '../../../service/model'
import AppStore from '../../store'

export type InboxState = 'open' | 'done'
export type FilterType = 'is:unread'

export type BaseSetSelectedConversationOptions =
  | { conversation: Conversation }
  | { index: number }

export type SetSelectedConversationOptions = BaseSetSelectedConversationOptions & {
  anchorActivityId?: string
  markAsRead?: boolean
}

export default class InboxUiStore {
  directNumberId: string = null
  phoneNumberId: string = null
  state: InboxState = 'open'
  filters: FilterType[] = []
  details: Participant | Member = null
  readonly conversations = new Collection<Conversation>({
    filter: this.isConversationInInbox.bind(this),
  })
  readonly unreadConversations = new Collection<Conversation>({
    filter: this.isConversationUnread.bind(this),
  })

  protected readonly filteredConversations = new Collection<Conversation>({
    filter: this.isConversationFiltered.bind(this),
    compare: (a, b) => b.lastActivityAt - a.lastActivityAt,
  })

  readonly keyboardList = new InboxKeyboardListController(this.app, this)

  private subscription: Subscription
  private filteredSubscription: Subscription
  private unreadSubscription: Subscription

  constructor(private app: AppStore, attributes: Partial<InboxUiStore>) {
    Object.assign(this, attributes)

    makeAutoObservable(this, {})

    this.bindCollections()

    this.app.onDataNeedsRefresh.subscribe(() => {
      this.fetchRemote()
    })

    reaction(
      () => this.state,
      () => this.fetchRemote(),
      { name: 'Inbox.fetchRemote', fireImmediately: true },
    )

    reaction(
      () => this.state,
      () => {
        this.bindFilteredCollection()
        this.setSelectedConversation({ index: 0 })
      },
    )

    reaction(
      () => this.filters,
      () => {
        this.bindFilteredCollection()
      },
    )

    reaction(
      () => this.isSelected,
      (selected) => {
        if (!selected) return
        // Without this setTimeout we've observed the conversation list
        // incorrectly showing the conversations from the other filter.
        setTimeout(() => {
          this.bindFilteredCollection()
          if (this.keyboardList.stepper.index === -1) {
            this.setSelectedConversation({ index: 0 })
          }
        }, 0)
      },
      { name: 'Inbox.SelectedChanged' },
    )

    reaction(
      () => this.selectedConversation,
      (conversation) => {
        this.details =
          conversation?.participants.length === 1 ? conversation.participants[0] : null
      },
      { name: 'Inbox.ConversationChanged' },
    )
  }

  get data() {
    return this.filteredConversations
  }

  get id(): string {
    return this.phoneNumberId || this.directNumberId
  }

  get isSelected() {
    return this.app.inboxes.selected === this
  }

  get isDirectMessage() {
    return Boolean(this.directNumberId)
  }

  get directNumber() {
    if (!this.directNumberId) return null
    return this.app.service.user.current.directNumbers.get(this.directNumberId)
  }

  get phoneNumber() {
    if (!this.phoneNumberId) return null
    return this.app.service.phoneNumber.collection.get(this.phoneNumberId)
  }

  get unread(): number {
    return this.app.service.conversation.unreadCount[this.phoneNumberId] ?? 0
  }

  get muted(): boolean {
    return this.phoneNumber?.muted
  }

  get hasMore(): boolean {
    return this.app.service.conversation.hasMore(this.currentQueryParams())
  }

  get selectedConversation(): Conversation | null {
    return this.data.list[this.keyboardList.stepper.index] ?? null
  }

  set selectedConversation(conversation: Conversation | null) {
    this.keyboardList.stepper.select(conversation)
  }

  loadConversations() {
    return this.app.service.conversation
      .findList({
        directNumberId: this.directNumberId,
        phoneNumberId: this.phoneNumberId,
      })
      .then(() => this.setSelectedConversationFromURL())
  }

  /**
   * If we're in an inbox (i.e. /inbox/:inboxId/c/:conversationId?at=:anchorActivityId),
   * set the selected conversation based on the URL parts.
   */
  protected setSelectedConversationFromURL() {
    if (!this.isSelected) return
    const parts = this.app.history.location.pathname.split('/')
    if (parts[1] === 'inbox') {
      if (parts[3] === 'c' && parts[4]) {
        const anchorActivityId = this.app.history.query.at
        this.app.service.conversation.getById(parts[4]).then((conversation) => {
          this.setSelectedConversation({
            conversation,
            anchorActivityId,
          })
        })
      } else if (
        parts[2]?.startsWith('PN') &&
        !parts[3] &&
        !parts[4] &&
        this.data.length > 0
      ) {
        this.setSelectedConversation({
          conversation: this.data.list[0],
        })
      }
    }
  }

  newConversation = (
    phoneNumber?: string,
    save: boolean = true,
    initialMessage?: string,
  ) => {
    if (this.state !== 'open') {
      this.setState('open')
    }

    const conversation =
      this.data.find((c) => c.isNew && c.participants.length === 0) ||
      new Conversation(this.app.service, {
        phoneNumber,
        directNumberId: this.directNumberId,
        phoneNumberId: this.phoneNumberId,
        isNew: true,
      })

    conversation.lastActivityAt = Date.now()
    conversation.createdAt = Date.now()
    save && conversation.save()

    if (initialMessage) {
      when(
        () => this.app.conversation.ready,
        () => this.app.conversation.type(initialMessage),
      )
    }

    this.setSelectedConversation({ conversation })
  }

  setState = (state: InboxState) => {
    this.state = state
  }

  setSelectedConversation = ({
    markAsRead,
    anchorActivityId,
    ...options
  }: SetSelectedConversationOptions): void => {
    const conversation =
      'conversation' in options ? options.conversation : this.data.list[options.index]

    // select a conversation only if it's in a selected inbox
    if (conversation && this.app.inboxes.selected.id === this.id) {
      this.setState(conversation.isDone ? 'done' : 'open')
      if (markAsRead && conversation.isUnread) {
        conversation.markAsRead()
      }
      this.app.conversation.setSelected(conversation, anchorActivityId)
    }
  }

  toggleUnreadFilter = () => {
    const hasUnread = this.filters.includes('is:unread')
    if (hasUnread) {
      this.filters = this.filters.filter((f) => f !== 'is:unread')
    } else {
      this.filters = [...this.filters, 'is:unread']
    }
  }

  toggleRead = (conversation: Conversation) => {
    conversation.toggleRead().catch(this.app.toast.showError)
  }

  toggleDone = (conversation: Conversation) => {
    conversation.toggleDone().catch(this.app.toast.showError)
    this.moveToNextIfSelected(conversation)
  }

  /**
   * Adds the participants to the blocklist and moves to the next available conversation if this one
   * was selected
   */
  toggleBlock = (conversation: Conversation) => {
    conversation.toggleBlock().catch(this.app.toast.showError)
    conversation.markAsDone().catch(this.app.toast.showError)
    this.moveToNextIfSelected(conversation)
  }

  /**
   * Deletes a conversation and selects the next available conversation if this one
   * was selected
   */

  delete = (conversation: Conversation) => {
    conversation.archive().catch(this.app.toast.showError)
    this.moveToNextIfSelected(conversation)
  }

  fetchRemote = () => {
    this.app.service.conversation.fetchRecent(this.currentQueryParams()).catch(logError)
  }

  loadMore = () => {
    this.app.service.conversation.fetchMore(this.currentQueryParams()).catch(logError)
  }

  setDetails = (details: Participant | Member) => {
    this.details = details
    this.app.inboxes.openDetails()
  }

  setDetailsById = (id: string) => {
    let identity: Member | Participant
    if ((identity = this.app.service.participant.get(id))) {
      this.setDetails(identity)
    } else if ((identity = this.app.service.member.collection.get(id))) {
      this.setDetails(identity)
    }
  }

  private isConversationInInbox(convo: Conversation): boolean {
    if (convo.isArchived) return false
    if (this.phoneNumberId && convo.phoneNumberId === this.phoneNumberId) {
      return true
    }
    if (this.directNumberId && convo.directNumberId === this.directNumberId) {
      return true
    }
    return false
  }

  private isConversationFiltered(convo: Conversation): boolean {
    const isFilteredByState =
      (this.state === 'open' && !convo.isDone) || (this.state === 'done' && convo.isDone)
    return (
      isFilteredByState &&
      this.filters.every((filter) => {
        switch (filter) {
          case 'is:unread':
            // FIXME: This condition relies on an implementation detail within
            // the conversation collection. The filter will run only during a
            // 'put' event, which runs when we are looking at the current
            // conversation.
            return convo.isUnread || this.app.conversation?.current?.id === convo.id
        }
      })
    )
  }

  private isConversationUnread(convo: Conversation): boolean {
    return convo.isUnread && !convo.isDone
  }

  private currentQueryParams = (): ConversationsQuery => {
    const unreadOnly = this.filters.includes('is:unread')
    return {
      phoneNumberId: this.phoneNumber?.id,
      directNumberId: this.directNumberId,
      snoozed: this.state === 'done',
      read: unreadOnly ? false : undefined,
    }
  }

  private bindBaseCollection() {
    this.subscription?.unsubscribe()
    this.subscription = this.conversations.bind(this.app.service.conversation.collection)
  }

  private bindFilteredCollection() {
    this.filteredSubscription?.unsubscribe()
    this.filteredSubscription = this.filteredConversations.bind(this.conversations)
  }

  private bindUnreadCollection() {
    this.unreadSubscription?.unsubscribe()
    this.unreadSubscription = this.unreadConversations.bind(this.conversations)
  }

  private bindCollections() {
    this.bindBaseCollection()
    this.bindFilteredCollection()
    this.bindUnreadCollection()
  }

  /**
   * Moves to the next item in the list if the conversation passed in is the selected
   * conversation
   */
  private moveToNextIfSelected(conversation: Conversation) {
    if (this.app.conversation.current === conversation) {
      this.keyboardList.stepper.selectIndex(
        Math.min(this.keyboardList.stepper.index, this.data.length - 1),
      )
    }
  }

  tearDown() {
    this.subscription?.unsubscribe()
    this.filteredSubscription?.unsubscribe()
    this.unreadSubscription?.unsubscribe()
  }
}

export class InboxKeyboardListController extends KeyboardListController<Conversation> {
  readonly itemHeight = 60
  readonly itemHoverMode = 'independent'
  override readonly itemSpacing = { bottom: 2 }
  override readonly listPadding = { top: 10, bottom: 20 }

  readonly stepper = new Stepper(this.inbox, {
    name: this.inbox.id,
  })

  constructor(protected app: AppStore, protected inbox: InboxUiStore) {
    super()

    makeObservable(this, {
      data: computed,
      handleBottomReached: action.bound,
    })

    reaction(
      () => this.stepper.index,
      (index) => {
        this.inbox.setSelectedConversation({ index, markAsRead: true })
      },
      { name: 'InboxKeyboardListController.StepperIndexChanged' },
    )
  }

  get data() {
    return this.inbox.data
  }

  override handleBottomReached() {
    if (this.inbox.hasMore) {
      this.inbox.loadMore()
    }
  }
}

export { InboxUiStore }
