import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import { NativeSelect } from '../../component/select'
import { isStorageThemeKey, StorageThemeKey } from '@src/theme'
import { Header, Item, Page } from './common'
import EmojiSkinTonePicker from './preferences/emoji-skin-tone-picker'
import Switch from '@src/component/switch'
import Button from '@src/component/button-v2'
import ReactionEmojisPicker from './preferences/reaction-emojis-picker'
import { defaultReactionEmojis, ReactionEmojis } from '@src/service/model'
import { action } from 'mobx'
import { useAsyncState } from '@src/lib/use-async-state'
import Tooltip from '@src/component/tooltip'
import { getBrowserName, isBrave, isChrome, isChromium, isFirefox } from '@src/lib/device'
import Callout from '@src/component/callout'

interface ThemeOption {
  key: StorageThemeKey
  label: string
}

const availableThemes: ThemeOption[] = [
  { key: 'system', label: 'System' },
  { key: 'light', label: 'Light' },
  { key: 'dark', label: 'Dark' },
]

const PreferencesSettings: React.FC = function ({}) {
  const app = useAppStore()
  const currentUserSettings = app.service.user.current.settings

  const handleThemeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value

    if (!isStorageThemeKey(value)) {
      throw new Error('Invalid theme key')
    }

    app.setThemeKey(value)
  }

  const handleSkinToneChange = action((modifier: string | undefined) => {
    if (!currentUserSettings) {
      return
    }

    currentUserSettings.preferences.defaultSkinTone = modifier
  })

  const handleReactionEmojisChange = action((emojis: ReactionEmojis) => {
    if (!currentUserSettings) {
      return
    }

    currentUserSettings.preferences.reactionEmojis = emojis
  })

  if (!currentUserSettings) {
    return null
  }

  return (
    <Page>
      <Header title="Preferences" subtitle="Manage your account preferences" />
      <Item
        title="Theme"
        description="Select your app color theme"
        input={
          <NativeSelect value={app.themeKey} onChange={handleThemeChange}>
            {availableThemes.map(({ key, label }) => (
              <option value={key} key={key}>
                {label}
              </option>
            ))}
          </NativeSelect>
        }
      />
      <Item
        title="Emoji skin tone"
        description="Choose the default emoji skin tone"
        input={
          <EmojiSkinTonePicker
            emoji="👋"
            defaultSkinTone={currentUserSettings.preferences.defaultSkinTone}
            onSelect={handleSkinToneChange}
          />
        }
      />
      <Item
        title="Reaction shortcuts"
        description="Customise your reaction shortcuts"
        input={
          <ReactionEmojisPicker
            skinTone={currentUserSettings.preferences.defaultSkinTone}
            defaultEmojis={
              currentUserSettings.preferences.reactionEmojis ?? defaultReactionEmojis
            }
            onChange={handleReactionEmojisChange}
          />
        }
      />
      {app.isElectron ? (
        <>
          <LaunchAtStartupItem />
          <SetAsDefaultDesktopApplicationItem />
        </>
      ) : (
        <>
          <SetAsDefaultWebApplicationItem />
        </>
      )}
    </Page>
  )
}

export default observer(PreferencesSettings)

const LaunchAtStartupItem = observer(() => {
  const app = useAppStore()
  const electronApp = app.electron?.app
  const { value, mutate, isLoading } = useAsyncState({
    fetcher: async () => electronApp?.isAutoStartEnabled(),
    enabled: !!electronApp,
  })

  const handleLaunchAtStartupChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await mutate(async () => {
      const newValue = event.target.checked
      await electronApp?.setAutoStartEnabled(newValue)
    })
  }

  return (
    <Item
      title="Launch at startup"
      description="Automatically launch OpenPhone when you turn on your computer"
      input={
        value !== undefined ? (
          <Switch
            defaultChecked={value}
            onChange={handleLaunchAtStartupChange}
            disabled={isLoading}
          />
        ) : null
      }
    />
  )
})

const SetAsDefaultDesktopApplicationItem = observer(() => {
  const app = useAppStore()
  const electronApp = app.electron?.app
  const { value, mutate, isLoading } = useAsyncState({
    fetcher: async () => electronApp?.isTelProtocolRegistered(),
    enabled: !!electronApp,
  })

  const handleSetAsDefaultChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    async function performMutation() {
      const newValue = event.target.checked

      if (newValue) {
        await electronApp?.registerTelProtocol()
      } else {
        await electronApp?.unregisterTelProtocol()
      }
    }

    await mutate(performMutation)
  }

  return (
    <Item
      title="Set as default"
      description="Set OpenPhone as the default application for calling"
      input={
        value !== undefined ? (
          <Switch
            defaultChecked={value}
            onChange={handleSetAsDefaultChange}
            disabled={isLoading}
          />
        ) : null
      }
    />
  )
})

const SetAsDefaultWebApplicationItem = observer(() => {
  const { isElectron, url } = useAppStore()
  const [clicked, setClicked] = useState(false)

  const handleSetAsDefaultChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isElectron) return
    setClicked(true)
    url.registerWebProtocols()
  }

  const closeInfoMessage = () => setClicked(false)

  const isFeatureSupported = 'registerProtocolHandler' in navigator

  if (isElectron) return null

  return (
    <Item
      title="Set as default"
      description="Set OpenPhone as the default browser application for calling"
      input={
        <Tooltip
          title={!isFeatureSupported ? "Your browser doesn't support this feature" : ''}
        >
          {/** A disabled button doesn't fire events, so we have to add a wrapper */}
          <div>
            <Button
              variant="outlined"
              color="textPrimary"
              disabled={!isFeatureSupported}
              onClick={handleSetAsDefaultChange}
            >
              Set in the browser
            </Button>
          </div>
        </Tooltip>
      }
    >
      {clicked ? (
        <Callout
          icon="👉"
          title={`To manage ${getBrowserName() ?? 'your browser'} settings`}
          body={getProtocolHandlerInstructions()}
          onDismiss={closeInfoMessage}
        />
      ) : null}
    </Item>
  )
})

function getProtocolHandlerInstructions() {
  if (isChrome() || isBrave() || isChromium()) {
    return `Head to browser settings -> Security and privacy -> Site settings -> Protocol Handlers`
  }

  if (isFirefox()) {
    return `Head to browser settings -> General -> Files and Applications -> Applications`
  }

  return `Head to browser settings -> Search for protocol handlers`
}
