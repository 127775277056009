// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import useInputState from '../../../lib/use-input-state'
import { fuzzySearch } from '../../../lib/search'
import { EntityPhoneNumber } from '../../../service/model/entity-phone-number'
import { AvailablePhoneNumber } from '../../../types'
import { Header, Page } from '../common'
import PickNumber from './pick'
import GroupAvatar from '../../../component/group-avatar'
import { AppAvatarRow } from '@src/app/components'

const PhoneNumbers = () => {
  const styles = useStyles()
  const { service, showAlert, history, toast } = useAppStore()
  const user = service.user.current.asMember
  const subscription = service.billing.subscription
  const phoneNumbers = service.organization.phoneNumber.collection.list
  const [search, setSearch] = useInputState('')
  const [pickNumberModal, setPickNumberModal] = useState(false)

  const filteredPhoneNumbers = useMemo(() => {
    if (!search || !phoneNumbers) return phoneNumbers
    return fuzzySearch(phoneNumbers, search, ['name', 'formattedNumber', 'userNames'])
  }, [phoneNumbers, search])

  const handleSelectNewNumber = (item: AvailablePhoneNumber) => {
    toast.showLoading('Purchasing the number...')
    service.organization.phoneNumber
      .buy(item.phoneNumber)
      .then((phoneNumber) => {
        toast.hide()
        history.push(`/settings/phone-numbers/${phoneNumber.id}`)
      })
      .catch(toast.showError)
  }

  const handleSelect = (phoneNumber: EntityPhoneNumber) => {
    history.push(`/settings/phone-numbers/${phoneNumber.id}`)
  }

  const handleAdd = () => {
    if (subscription.store !== 'stripe') {
      return showAlert({
        title: 'Switch to credit card',
        body: `To add additional phone numbers to your workspace, we need a credit card on your account. Right now your subscription is managed by ${
          subscription.store === 'android' ? 'Play Store' : 'Apple Store'
        }.`,
        actions: [
          {
            title: 'Add a credit card',
            type: 'primary',
            onClick: () => {
              history.push('/settings/billing')
            },
          },
          {
            title: 'Cancel',
          },
        ],
      })
    }
    setPickNumberModal(true)
  }

  const handlePort = () => {
    const win = window.open('https://openphone.typeform.com/to/IVK4vZ', '_blank')
    win.focus()
  }

  return (
    <Page>
      <Header
        title="Phone numbers"
        subtitle="Manage all phone numbers in your workspace"
        actions={
          user.isAdmin && (
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleAdd}
              >
                Add a new number
              </Button>
              <Button variant="outlined" color="default" onClick={handlePort}>
                Port existing
              </Button>
            </>
          )
        }
      />
      <PickNumber
        confirm
        open={pickNumberModal}
        onClose={() => setPickNumberModal(false)}
        onSelect={handleSelectNewNumber}
      />
      <TextInput
        fullWidth
        size={35}
        value={search}
        onChange={setSearch}
        placeholder="Search by number, name, or members"
        style={{ marginTop: 16 }}
      />
      <div style={{ margin: '1rem 0' }}>
        {filteredPhoneNumbers.map((phoneNumber) => (
          <div
            key={phoneNumber.id}
            className={styles.row}
            onClick={() => handleSelect(phoneNumber)}
          >
            <GroupAvatar group={phoneNumber} size={34} />
            <div className={styles.name}>
              <Typography nowrap variant="body">
                {phoneNumber.name}
              </Typography>
              <Typography variant="caption2" color="textSecondary">
                {phoneNumber.formattedNumber}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: '0 0 275px',
                minWidth: 0,
              }}
            >
              <div className={styles.names}>{phoneNumber.userNames}</div>
              <div className={styles.avatars}>
                <AppAvatarRow identities={phoneNumber.members} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  )
}

export default observer(PhoneNumbers)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    fontSize: '0.9rem',
    cursor: 'pointer',
    position: 'relative',

    '&:before': {
      content: '""',
      borderRadius: 6,
      position: 'absolute',
      left: -14,
      right: -14,
      top: 0,
      bottom: 0,
    },

    '&:hover:before': {
      backgroundColor: theme.palette.op.hover.primary,
    },
  },
  name: {
    flex: 1,
    marginLeft: 12,
    paddingRight: '1rem',
  },
  names: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: 10,
    fontSize: 12,
    flex: 1,
  },
  searchBar: {
    padding: '2rem 2rem 0',
  },
  avatars: {
    flex: '0 0 100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))
