import { useAppStore } from '@src/app/context'
import { EntityPhoneNumber } from '@src/service/model'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@ui/Typography'
import Switch from '@src/component/switch'
import TextField from '@src/component/textfield-v2'
import { SearchIcon } from '@src/component/icons/Tint/16/General'
import useInputState from '@src/lib/use-input-state'
import { useInitialMinHeight } from '@src/lib/use-initial-min-height'
import { fuzzySearch } from '@src/lib/search'
import { Pagination } from '../pagination'

function PhoneNumbers({
  activePhoneNumbers,
  onActivate,
  onPause,
}: {
  activePhoneNumbers: string[]
  onActivate: (phoneNumber: EntityPhoneNumber) => void
  onPause: (phoneNumber: EntityPhoneNumber) => void
}) {
  const styles = useStyles()
  const { service, inboxes } = useAppStore()
  const [searchValue, setSearchValue] = useInputState('')
  const myPhoneNumberIds = inboxes.all.list.map((item) => item.phoneNumberId)
  const phoneNumbers = service.organization.phoneNumber.collection.list

  const filteredBySearch = useCallback(
    () => fuzzySearch(phoneNumbers, searchValue, ['number', 'name']),
    [phoneNumbers, searchValue],
  )

  const searchedPhoneNumbers = useMemo(
    () => (searchValue !== '' ? filteredBySearch() : phoneNumbers),
    [searchValue, filteredBySearch, phoneNumbers],
  )

  const byOwnership = useCallback(
    (phoneNumber: EntityPhoneNumber) => {
      return myPhoneNumberIds.includes(phoneNumber.id)
    },
    [myPhoneNumberIds],
  )

  const myPhoneNumbers = useMemo(
    () => searchedPhoneNumbers.filter(byOwnership),
    [searchedPhoneNumbers, byOwnership],
  )

  const restOfPhoneNumbers = useMemo(
    () => searchedPhoneNumbers.filter((item) => !byOwnership(item)),
    [searchedPhoneNumbers],
  )

  const { items: restOfPhoneNumbersPaginated, ...paginationProps } = usePagination({
    items: restOfPhoneNumbers,
  })

  const { ref } = useInitialMinHeight({
    enabled: phoneNumbers.length > 0,
  })

  return (
    <>
      <TextField
        size={35}
        rootClassName={styles.searchField}
        startAdornment={SearchIcon}
        placeholder="Search name or phone number"
        onChange={setSearchValue}
        value={searchValue}
      />
      <section className={styles.root} ref={ref}>
        <section className={styles.section}>
          <Typography variant="caption1" color="textTertiary">
            Your numbers
          </Typography>
          {myPhoneNumbers.map((phoneNumber) => (
            <PhoneNumber
              phoneNumber={phoneNumber}
              isActive={activePhoneNumbers.includes(phoneNumber.id)}
              onActivate={onActivate}
              onPause={onPause}
              key={phoneNumber.id}
            />
          ))}
        </section>
        <section className={styles.section}>
          <Typography variant="caption1" color="textTertiary">
            All other numbers
          </Typography>
          {restOfPhoneNumbersPaginated.map((phoneNumber) => (
            <PhoneNumber
              phoneNumber={phoneNumber}
              isActive={activePhoneNumbers.includes(phoneNumber.id)}
              onActivate={onActivate}
              onPause={onPause}
              key={phoneNumber.id}
            />
          ))}
          <Pagination {...paginationProps} />
        </section>
      </section>
    </>
  )
}

export default observer(PhoneNumbers)

function PhoneNumber({
  phoneNumber,
  isActive,
  onActivate,
  onPause,
}: {
  phoneNumber: EntityPhoneNumber
  isActive: boolean
  onActivate: (phoneNumber: EntityPhoneNumber) => void
  onPause: (phoneNumber: EntityPhoneNumber) => void
}) {
  const styles = useStyles()

  const handleChange = () => {
    if (isActive) {
      onPause(phoneNumber)
    } else {
      onActivate(phoneNumber)
    }
  }

  return (
    <div className={styles.row}>
      <div className={styles.symbol}>{phoneNumber.symbol}</div>
      <div className={styles.info}>
        <Typography variant="body" color="textPrimary" className={styles.names}>
          {phoneNumber.name}
        </Typography>
        <Typography variant="caption1" color="textSecondary">
          {phoneNumber.formattedNumber} - {phoneNumber.users.length}{' '}
          {phoneNumber.users.length === 1 ? 'member' : 'members'}
        </Typography>
      </div>
      <Switch checked={isActive} onChange={handleChange} />
    </div>
  )
}

function usePagination<T>({
  items,
  itemsPerPage = 10,
}: {
  items: T[]
  itemsPerPage?: number
}) {
  const minPage = 1
  const maxPage = Math.ceil(items.length / itemsPerPage)
  const [currentPage, setCurrentPage] = useState(minPage)
  const hasNextPage = currentPage < maxPage
  const hasPrevPage = currentPage > 1

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedItems = items.slice(startIndex, endIndex)

  const handlePrevPage = () => setCurrentPage((current) => Math.max(minPage, current - 1))
  const handleNextPage = () => setCurrentPage((current) => Math.min(maxPage, current + 1))

  useEffect(() => {
    setCurrentPage(minPage)
  }, [items.length])

  return {
    handlePrevPage,
    handleNextPage,
    hasNextPage,
    hasPrevPage,
    currentPage,
    itemsPerPage,
    items: paginatedItems,
    totalItems: items.length,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
  },

  searchField: {
    margin: '16px 0 24px 0',
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 6,
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
  },

  symbol: {
    width: 34,
    height: 34,
    borderRadius: 7,
    boxShadow: `inset 0 0 0 1.5px ${theme.palette.op.hover.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 17,
  },

  info: {
    flex: 1,
    minWidth: 0,
    margin: '0 15px',
  },

  names: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
