// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountIcon from '@material-ui/icons/BusinessCenter'
import ErrorIcon from '@material-ui/icons/Error'
import MessageIcon from '@material-ui/icons/Message'
import ContactIcon from '@material-ui/icons/PermContactCalendar'
import useStatefulPromise from '@src/lib/useStatefulPromise'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../component/button'
import { IntegrationsIcon } from '../component/icons/custom'
import Logo from '../component/logo'
import config from '../config'
import { unique } from '../lib'
import { Application } from '../types'
import Header, { HeaderSpacer } from './header'

interface ThirdpartyAuthorizeProps {}

function scopeList(s: string): { icon: React.FC<any>; desc: string }[] {
  const delimiter = s.indexOf(',') === -1 ? ' ' : ','
  const scopes = unique(
    s
      .split(delimiter)
      .map((s) => s.trim())
      .map((s) => (s === 'profile' ? 'read:account' : s)),
  )

  const conversationScopes = scopes.filter((s) => s.endsWith(':conversations'))
  const contactsScopes = scopes.filter((s) => s.endsWith(':contacts'))
  const accountScopes = scopes.filter((s) => s.endsWith(':account'))

  const list: { icon: React.FC; desc: string }[] = []

  if (conversationScopes.length > 0) {
    if (conversationScopes.length === 2) {
      list.push({
        icon: MessageIcon,
        desc: 'Read your conversations and send messages.',
      })
    } else {
      const perm = conversationScopes[0]
      list.push({
        icon: MessageIcon,
        desc: perm.startsWith('read:')
          ? 'Read your conversations'
          : 'Send messages on your behalf',
      })
    }
  }

  if (contactsScopes.length > 0) {
    if (contactsScopes.length === 2) {
      list.push({
        icon: ContactIcon,
        desc: 'See, edit, create and delete your contacts',
      })
    } else {
      const perm = contactsScopes[0]
      list.push({
        icon: ContactIcon,
        desc: perm.startsWith('read:')
          ? 'See your contacts'
          : 'Edit, create and delete your contacts',
      })
    }
  }

  if (accountScopes.length > 0) {
    if (accountScopes.length === 2) {
      list.push({
        icon: AccountIcon,
        desc: 'See, edit, create and delete your contacts',
      })
    } else {
      const perm = accountScopes[0]
      list.push({
        icon: AccountIcon,
        desc: perm.startsWith('read:')
          ? 'See your account and workspace information'
          : 'Edit your account and workspace information',
      })
    }
  }

  return list
}

interface OAuthParams {
  scope: string
  client_id: string
  redirect_uri: string
  response_type: string
  state: string
}

function isOAuthParams(params: any): params is OAuthParams {
  return (
    params &&
    typeof params === 'object' &&
    'scope' in params &&
    'client_id' in params &&
    'redirect_uri' in params &&
    'response_type' in params
  )
}

const ThirdpartyAuthorize: React.FC<ThirdpartyAuthorizeProps> = function ({}) {
  const styles = useStyles({})
  const { service, history } = useAppStore()
  const [oauthParams, setOAuthParams] = useState<OAuthParams>(null)
  const org = service.organization.current

  const [appResponse, findApp] = useStatefulPromise<Application>(() => {
    const params = history.query

    if (isOAuthParams(params)) {
      setOAuthParams({
        scope: params.scope,
        client_id: params.client_id,
        redirect_uri: params.redirect_uri,
        response_type: params.response_type,
        state: params.state,
      })
      return service.integration.getApplication(params.client_id, params.redirect_uri)
    }
  })

  useEffect(() => {
    findApp()
  }, [])

  const handleBack = () => {
    history.goBack()
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <Logo />
        <HeaderSpacer />
      </Header>
      <div className={styles.card}>
        {appResponse.error ? (
          <div style={{ textAlign: 'center' }}>
            <ErrorIcon className={styles.errorIcon} />
            <Typography variant="h6" color="textPrimary" style={{ marginBottom: 30 }}>
              {appResponse.error.message}
            </Typography>
            <Button variant="text" color="default" onClick={handleBack}>
              Go back
            </Button>
          </div>
        ) : appResponse.data ? (
          <form method="post" action={`${config.AUTH_SERVICE_URL}oauth`}>
            {Object.keys(oauthParams).map((key) => (
              <input type="hidden" name={key} value={oauthParams[key]} />
            ))}
            <input type="hidden" name="grant_type" value="authorization_code" />
            <input
              type="hidden"
              name="authorization"
              value={service.auth.session.idToken}
            />

            <Typography
              variant="h5"
              color="textPrimary"
              style={{ textAlign: 'center', fontWeight: 500 }}
            >
              {appResponse.data.name} is requesting permission to access
              {org.name ? ` the ${org.name} ` : ' your '}
              OpenPhone workspace
            </Typography>
            <div className={styles.logos}>
              <div className={styles.logo}>
                <img src={org.pictureUrl} />
              </div>
              <IntegrationsIcon style={{ margin: '0 30px', opacity: 0.4 }} />
              <div className={styles.logo}>
                <img src={appResponse.data.logoUrl} />
              </div>
            </div>
            <div className={styles.scopes}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: 10 }}
              >
                {appResponse.data.name} will be able to:
              </Typography>

              {scopeList(oauthParams.scope).map((scope, index) => (
                <div className={styles.scope} key={index}>
                  <div className={styles.scopeIcon}>
                    <scope.icon fontSize="small" />
                  </div>
                  <div className={styles.scopeDescription}>{scope.desc}</div>
                </div>
              ))}
            </div>
            <div className={styles.actions}>
              <Button
                type="button"
                variant="outlined"
                color="default"
                style={{ marginRight: 20 }}
                className={styles.action}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={styles.action}
                color="secondary"
              >
                Allow
              </Button>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  )
}

export default observer(ThirdpartyAuthorize)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'auto',
    height: '100%',
  },
  header: {
    position: 'static',
    top: 0,
    left: 0,
    right: 0,
    height: 80,
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  card: {
    margin: '70px auto 30px',
    width: 550,
  },
  errorIcon: {
    marginBottom: 30,
    fontSize: '4rem',
    color: theme.palette.error.light,
  },
  logos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0',
    color: theme.palette.text.primary,
  },
  logo: {
    width: 65,
    height: 65,
    borderRadius: 10,
    overflow: 'hidden',

    '& img': {
      maxWidth: 65,
      maxHeight: 65,
    },
  },
  actions: {
    margin: '30px 0 50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    minWidth: 100,
  },
  scopes: {
    width: 400,
    margin: '0 auto',

    '& $scopeDescription': {
      borderBottom: `1.5px solid ${theme.palette.op.background.highlight(0.15)}`,
    },
  },
  scope: {
    height: 55,
    opacity: 0.8,
    display: 'flex',
    alignItems: 'stretch',
    color: theme.palette.text.primary,
  },
  scopeDescription: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  scopeIcon: {
    width: 40,
    display: 'flex',
    alignItems: 'center',
  },
}))
