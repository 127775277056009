import { makeAutoObservable } from 'mobx'
import { Environment, ENV_OVERRIDE_KEY } from '../config'
import ActivityStore from './activity-store'
import AlertStore from './alert-store'
import AuthStore from './auth-store'
import BillingStore from './billing-store'
import BlocklistStore from './blocklist-store'
import ContactStore from './contact-store'
import ConversationStore from './conversation-store'
import IntegrationStore from './integration-store'
import MemberStore from './member-store'
import OrganizationStore from './organization-store'
import ParticipantStore from './participant-store'
import PhoneNumberStore from './phone-number-store'
import ReferralStore from './referral-store'
import SearchStore from './search-store'
import SnippetsStore from './snippet-store'
import Storage from './storage'
import Transport from './transport'
import UserStore from './user-store'
import VoiceStore from './voice-store'
import MainWorker from './worker/main'
import WorkspaceStore from './WorkspaceStore'
import EmojiService from './emoji-service'

export default class Service {
  readonly storage: Storage
  readonly transport: Transport
  readonly activity: ActivityStore
  readonly alert: AlertStore
  readonly auth: AuthStore
  readonly billing: BillingStore
  readonly blocklist: BlocklistStore
  readonly contact: ContactStore
  readonly conversation: ConversationStore
  readonly integration: IntegrationStore
  readonly member: MemberStore
  readonly organization: OrganizationStore
  readonly participant: ParticipantStore
  readonly phoneNumber: PhoneNumberStore
  readonly search: SearchStore
  readonly snippet: SnippetsStore
  readonly user: UserStore
  readonly voice: VoiceStore
  readonly referral: ReferralStore
  readonly workspace: WorkspaceStore
  readonly emoji: EmojiService

  constructor(private workerName: string) {
    makeAutoObservable(this, {})
    const worker = new MainWorker(this.workerName)
    this.storage = new Storage(worker)
    this.transport = new Transport(this)
    this.activity = new ActivityStore(this)
    this.alert = new AlertStore(this)
    this.auth = new AuthStore(this)
    this.billing = new BillingStore(this)
    this.blocklist = new BlocklistStore(this)
    this.contact = new ContactStore(this)
    this.conversation = new ConversationStore(this, worker)
    this.integration = new IntegrationStore(this)
    this.member = new MemberStore(this)
    this.organization = new OrganizationStore(this)
    this.participant = new ParticipantStore(this)
    this.phoneNumber = new PhoneNumberStore(this)
    this.search = new SearchStore(this, worker)
    this.snippet = new SnippetsStore(this)
    this.user = new UserStore(this)
    this.voice = new VoiceStore(this)
    this.referral = new ReferralStore(this)
    this.workspace = new WorkspaceStore(this)
    this.emoji = new EmojiService(this)
  }

  reset = () => {
    return this.storage.reset().then(() => window.location.reload())
  }

  clearAllAndRestart = (evnOverride?: Environment) => {
    return this.storage.clearAll().then(() => {
      if (evnOverride) {
        localStorage.setItem(ENV_OVERRIDE_KEY, evnOverride)
      }
      window.location.reload()
    })
  }
}

export * from './dto'
