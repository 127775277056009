import Debug, { Debugger } from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { DisposeBag } from '../../lib/dispose'
import { IUiStore } from '../../types'

interface Option {
  name?: string
  hideScroll?: boolean
  onResize?: (rect: DOMRect) => void
  onPassiveScroll?: (point: { left: number; top: number }) => void
}

export class ScrollViewStore implements IUiStore {
  /** Height of the scrollable viewport */
  private height = 0

  /** Height of the scroll content */
  private contentHeight = 0

  /** Holds the scroll offset of the element as reported by the element itself */
  private scrollOffset = 0

  /** Signals to the view to change its scroll offset */
  scrollTo = { top: 0 }

  /** The offset of the knob */
  knobOffset = 0

  /** Is the mouse currently inside the scrollable area */
  isMouseIn = false

  /** Is the knob being dragged by mouse */
  isDragging = false

  private debug: Debugger
  private disposeBag: DisposeBag

  /** Calculate the height of the knob */
  get knobHeight() {
    if (this.height >= this.contentHeight) return 0
    return Math.max(60, (this.height / this.contentHeight) * this.height - 6)
  }

  get knobHidden() {
    return !this.isDragging && !this.isMouseIn
  }

  constructor(private opt: Option) {
    makeObservable<this, 'scrollOffset' | 'height' | 'contentHeight'>(this, {
      height: observable.ref,
      contentHeight: observable.ref,
      scrollTo: observable.ref,
      scrollOffset: observable.ref,
      knobOffset: observable.ref,
      isMouseIn: observable.ref,
      isDragging: observable.ref,
      knobHeight: computed,
      knobHidden: computed,
      setSize: action.bound,
      setContentSize: action.bound,
      setScrollOffset: action.bound,
      onKnobDrag: action.bound,
      onKnobDragStart: action.bound,
      onKnobDragEnd: action.bound,
      onMouseEnter: action.bound,
      onMouseLeave: action.bound,
      onScroll: action.bound,
    })

    this.debug = Debug(`op:scrollview:store:@${opt.name}`)
    this.debug('instantiated')

    this.disposeBag = new DisposeBag(
      reaction(
        () => ({
          height: this.height,
          contentHeight: this.contentHeight,
          scrollOffset: this.scrollOffset,
        }),
        ({ height, contentHeight, scrollOffset }) => {
          if (this.isDragging) return
          const availableContentScroll = contentHeight - height
          const availableKnobScroll = height - this.knobHeight - 6
          this.knobOffset = (scrollOffset / availableContentScroll) * availableKnobScroll
        },
      ),
    )
  }

  setSize(size: DOMRect) {
    this.height = size.height
    this.opt.onResize?.(size)
  }

  setContentSize(size: DOMRect) {
    this.contentHeight = size.height
  }

  setScrollOffset(top: number) {
    this.scrollTo = { top }
    this.debug(`set offset to ${top}`)
  }

  onKnobDragStart() {
    this.isDragging = true
  }

  onKnobDragEnd() {
    this.isDragging = false
  }

  onKnobDrag(left: number, top: number) {
    if (top < 0) return
    if (top + this.knobHeight >= this.height - 6) return
    this.knobOffset = top
    this.scrollTo = { top: (top / this.height) * this.contentHeight }
  }

  onScroll(left: number, top: number) {
    // if (!this.isDragging) {
    //   this.scrollOffset = { top }
    // }
    this.debug(`onScroll ${top}px`)
    this.scrollOffset = top
    this.opt?.onPassiveScroll?.({ left, top })
  }

  onMouseEnter() {
    this.isMouseIn = true
  }

  onMouseLeave() {
    this.isMouseIn = false
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
