import React from 'react'
import Typography from '@ui/Typography'
import { makeStyles } from '@material-ui/core'

export default function ContactSupportMessage() {
  const styles = useStyles()

  return (
    <Typography
      variant="footnote"
      color="textSecondary"
      className={styles.contactSupport}
    >
      Having trouble? Reach out to us at{' '}
      <a href="mailto:support@openphone.co">support@openphone.co</a>
    </Typography>
  )
}

const useStyles = makeStyles(() => ({
  contactSupport: {
    marginTop: 10,
    textAlign: 'center',
  },
}))
