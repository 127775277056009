// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import Callout from '../../../component/callout'
import { XIcon } from '../../../component/icons/Tint/20/General'
import { NativeSelect } from '../../../component/select'
import Switch from '../../../component/switch'
import { default as TextField, default as TextInput } from '@ui/TextInput'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { formatted, isInternational } from '../../../lib/phone-number'
import useInputState from '../../../lib/use-input-state'
import { EntityPhoneNumber } from '../../../service/model'
import { Item, Section } from '../common'

interface GeneralProps {
  phoneNumber: EntityPhoneNumber
}

const General: React.FC<GeneralProps> = function ({ phoneNumber }) {
  const styles = useStyles({})
  const { command, service, showEmojiPicker, toast, showAlert, history } = useAppStore()
  const user = service.user.current.asMember
  const emojiRef = useRef<HTMLDivElement>(null)
  const [name, setName] = useInputState('')
  const [symbol, setSymbol] = useState('')
  const [ringTimeout, setRingTimeout] = useInputState('')
  const [roundRobin, setRoundRobin] = useInputState('10')
  const roundRobinEnabled = phoneNumber?.users.length > 1
  const autoRecordEnabled = phoneNumber?.settings?.autoRecord ?? false

  useEffect(() => {
    if (!phoneNumber) return
    setRingTimeout(`${phoneNumber.timeout}`)
    setName(phoneNumber.name)
    setSymbol(phoneNumber.symbol || '📱')
  }, [phoneNumber?.timeout, phoneNumber?.name, phoneNumber?.settings.maxDial])

  useEffect(() => {
    if (
      phoneNumber?.settings?.maxDial &&
      phoneNumber?.settings?.maxDial.toString() !== roundRobin
    ) {
      setRoundRobin(phoneNumber?.settings?.maxDial.toString())
    }
  }, [phoneNumber?.settings?.maxDial])

  const handleSave = () => {
    if (name !== phoneNumber.name) {
      phoneNumber.update({ name, symbol }).catch(toast.showError)
    }
  }

  const handlePickEmoji = () => {
    showEmojiPicker({
      anchorEl: emojiRef.current,
      onEmojiSelected: handleSelectSymbol,
    })
  }

  const handleSelectSymbol = (symbol: string) => {
    setSymbol(symbol)
    phoneNumber.update({ symbol }).catch(toast.showError)
  }

  const handleCallerID = () => {
    window.open('https://openphone.typeform.com/to/f3iRQt2o', '_blank')
  }

  const handleAutoRecordChange = (event) => {
    phoneNumber
      .updateSettings({ autoRecord: event.target.checked })
      .catch(toast.showError)
  }

  const handleInternational = (event) => {
    phoneNumber.toggleInternational(event.target.checked).catch(toast.showError)
  }

  const handleSaveTimeout = () => {
    phoneNumber.update({ timeout: parseInt(ringTimeout) || 30 }).catch(toast.showError)
  }

  const handleRoundRobinChange = (event) => {
    if (!service.billing.subscription.isPremium) {
      return showAlert({
        title: 'Upgrade to Premium',
        body: 'Round-robin is only available on the Premium plan. To use, upgrade your subscription and try again.',
        actions: [
          {
            type: 'primary',
            title: 'Compare Plans',
            onClick: () => history.push('/settings/billing/plans'),
          },
          { title: 'Cancel' },
        ],
      })
    }
    const maxDial = event.target.value
    setRoundRobin(maxDial)
    if (phoneNumber.settings?.maxDial !== maxDial) {
      phoneNumber.updateSettings({ maxDial }).catch(toast.showError)
    }
  }

  const handleForward = () => {
    if (!phoneNumber) return
    command.present({
      name: 'forward calls',
      defaultValue: phoneNumber.forward,
      onSelect: (forward) => {
        if (isInternational(forward)) {
          toast.showError(new Error('Cannot forward to international phone numbers'))
          return
        }
        phoneNumber.update({ forward }).catch(toast.showError)
        command.hide()
      },
    })
  }

  const handleStopForwarding = () => {
    phoneNumber.update({ forward: null }).catch(toast.showError)
  }

  return (
    <Section className={styles.root}>
      {phoneNumber?.forward && (
        <Callout
          style={{ marginTop: 20 }}
          color="purple"
          icon="↩️"
          title="Call forwarding enabled"
          body={`All your incoming calls are being forwarded to ${formatted(
            phoneNumber.forward,
          )}. This means your business hours, phone menu or custom voicemail is being skipped.`}
        />
      )}

      <Item
        title="Icon and name"
        description="Everyone in your workspace can see this"
        input={
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
            <div ref={emojiRef} className={styles.symbol} onClick={handlePickEmoji}>
              <Typography variant="emoji">{symbol}</Typography>
            </div>
            <TextField
              style={{ flex: 1 }}
              value={name}
              onChange={setName}
              onBlur={handleSave}
            />
          </div>
        }
      />

      <Item
        title="Caller ID"
        description={
          <span>
            Set the Caller ID you want others to see when you make calls.{' '}
            <a
              href="https://help.openphone.co/en/articles/4350500-how-to-set-up-an-outgoing-caller-id-for-your-openphone-number"
              target="_blank"
            >
              Read more.
            </a>
          </span>
        }
        input={
          <Button
            height={45}
            color="textPrimary"
            variant="outlined"
            onClick={handleCallerID}
          >
            Configure
          </Button>
        }
      />

      <Item
        title="Ring duration"
        description="The default number of seconds before calls are directed to voicemail"
        input={
          <div style={{ position: 'relative' }}>
            <TextInput
              size={45}
              value={ringTimeout}
              onChange={setRingTimeout}
              onBlur={handleSaveTimeout}
              style={{ width: 95, paddingRight: 45 }}
            />
            <Typography
              color="textSecondary"
              variant="footnote"
              style={{ position: 'absolute', right: 15, top: 15, pointerEvents: 'none' }}
            >
              SEC
            </Typography>
          </div>
        }
      />

      <Item
        title="Forward all incoming calls"
        description="Automatically forward all incoming calls to another number"
        input={
          phoneNumber?.forward ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Stop forwarding">
                <Button
                  height={45}
                  color="textPrimary"
                  variant="outlined"
                  onClick={handleStopForwarding}
                  startIcon={<XIcon />}
                >
                  {formatted(phoneNumber.forward)}
                </Button>
              </Tooltip>
            </div>
          ) : (
            <Button
              height={45}
              color="textPrimary"
              variant="outlined"
              onClick={handleForward}
            >
              Setup
            </Button>
          )
        }
      />

      <Item
        title="Round-robin batch size"
        description="OpenPhone can ring users on this phone number in batches, instead of all at once. When a batch doesn't answer, it goes to the next one. When it reaches the end and no one answers, the call goes to voicemail."
        input={
          <Tooltip
            title="You can only set this option on phone numbers that have more than one user assigned to them"
            disabled={roundRobinEnabled}
          >
            <div>
              <NativeSelect
                value={roundRobin}
                disabled={!roundRobinEnabled}
                onChange={handleRoundRobinChange}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => (
                  <option value={v} key={v}>
                    {v}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </Tooltip>
        }
      />

      <Item
        title="Auto record calls"
        description="Automatically record all calls to this number. Make sure you stay compliant with recording rules in your region."
        input={
          <Switch
            checked={autoRecordEnabled}
            onChange={handleAutoRecordChange}
            disabled={!user.isAdmin}
          />
        }
      />

      <Item
        title="International calling and messaging"
        description={
          <span>
            Allow international calling and messaging on this phone number. Extra charges
            will apply per message segment and call minute.{' '}
            <a href="https://openphone.co/rates" target="_blank">
              Check rates here.
            </a>
          </span>
        }
        input={
          <Tooltip
            title="Only workspace admins can enable international calling and messaging"
            placement="bottom"
            enterDelay={!user.isAdmin ? 100 : 100000000}
          >
            <span>
              <Switch
                checked={phoneNumber?.settings?.international?.enabled || false}
                onChange={handleInternational}
                disabled={!user.isAdmin}
              />
            </span>
          </Tooltip>
        }
      />
    </Section>
  )
}

export default observer(General)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& a': {
      color: theme.palette.primary.light,
      textDecoration: 'none',
    },
  },
  symbol: {
    alignSelf: 'stretch',
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.op.gray[6],
    border: `1.5px solid ${alpha(theme.palette.op.gray[1], 0.2)}`,
    borderRadius: 5,
    marginRight: 10,
    cursor: 'pointer',
    transition: theme.transitions.create(['border', 'background'], {
      easing: 'ease',
      duration: 160,
    }),

    '&:hover': {
      border: `1.5px solid ${alpha(theme.palette.op.gray[1], 0.4)}`,
    },
  },
  title: {
    fontWeight: 500,
    marginBottom: 6,
  },
  section: {
    padding: '20px 0',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0',
    borderTop: `1.5px solid ${theme.palette.op.border.common}`,
  },
  label: {
    marginRight: 20,
    flex: 1,
  },
}))
