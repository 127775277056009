// @ts-strict-ignore
import { makeAutoObservable } from 'mobx'
import Service from '..'
import { v4 as uuid } from 'uuid'
import { parseDate } from '../../lib'
import { Model } from './base'
import { Activity } from './activity'
import { Comment } from './comment'

export interface CodableReaction {
  id: string
  body: string
  createdAt: number
  updatedAt: number
  userId: string
}

export interface EncodableReaction {
  id: string
  activityId: string
  commentId?: string
  body: string
}

export interface DecodableReaction extends CodableReaction {
  conversationId?: string
  activityId?: string
  commentId?: string
}

export class Reaction implements Model, CodableReaction {
  id: string = `RA${uuid()}`.replace(/-/g, '')
  body: string = null
  createdAt: number = Date.now()
  updatedAt: number = Date.now()
  userId: string = null
  commentId: string = null

  constructor(
    private root: Service,
    readonly object: Activity | Comment,
    attrs: Partial<DecodableReaction> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get activity(): Activity {
    return this.object instanceof Comment ? this.object.activity : this.object
  }

  get comment(): Comment | null {
    return this.object instanceof Comment ? this.object : null
  }

  deserialize(json?: Partial<CodableReaction> | Partial<DecodableReaction>) {
    if (json) {
      Object.assign(this, json)
      this.createdAt = parseDate(json.createdAt || this.createdAt)
      this.updatedAt = parseDate(json.updatedAt || this.updatedAt)
    }
    return this
  }

  serialize(): CodableReaction {
    return {
      id: this.id,
      body: this.body,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  toJSON(): EncodableReaction {
    return {
      id: this.id,
      activityId: this.activity.id,
      commentId: this.comment?.id,
      body: this.body,
    }
  }
}
