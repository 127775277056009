import { AxiosRequestConfig } from 'axios'
import { v4 as uuid } from 'uuid'
import Transport from '.'
import config from '../../config'
import {
  ActivitySearchResult,
  ConversationParticipantStatus,
  IvrSettings,
  Paginated,
} from '../../types'
import {
  Conversation,
  IActivity,
  IConversation,
  EncodableComment,
  EncodableReaction,
  CodableMessageMedia,
} from '../model'
import { HttpTransaction } from './transaction'

export type ConversationListQueryParam = {
  before?: string
  last?: number
  since?: Date
  phoneNumber?: string
  phoneNumberId?: string
  directNumberId?: string
  snoozed?: boolean
  read?: boolean
  includeDeleted?: boolean
}

export type ActivitySearchParams = {
  query: string
  limit?: number
  fields?: string[]
  phoneNumbers?: string[]
  phoneNumberIds?: string[]
  conversationIds?: string[]
}

export default class CommunicationClient {
  constructor(private transport: Transport) {}

  activities = {
    list: (params: {
      id?: string
      phoneNumberId?: string
      directNumberId?: string
      phoneNumber?: string
      before?: string
      last?: number
      next?: number
      inclusive?: boolean
      since?: Date
    }): Promise<Paginated<IActivity> & { conversation: IConversation }> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'get',
          url: `${config.COMMUNICATION_SERVICE_URL}activity`,
          query: params,
        }),
      )
    },

    send: (params: {
      id?: string
      body?: string
      media?: CodableMessageMedia[]
      phoneNumberId?: string
      directNumberId?: string
      conversationId?: string
      to?: string
    }): Promise<{ activity: IActivity; conversation: IConversation }> => {
      const { id, body, media, conversationId, phoneNumberId, directNumberId, to } =
        params
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation`,
          body: {
            phoneNumberId: phoneNumberId || undefined,
            directNumberId: directNumberId || undefined,
            to,
            body,
            mediaUrl: media?.map((m) => ({
              id: m.id || uuid(),
              url: m.url,
              type: m.type,
              name: m.name,
            })),
            activityId: id,
            conversationId,
          },
        }),
      )
    },

    resolve: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}activity/${id}/resolve`,
          body: {},
        }),
      )
    },

    unresolve: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}activity/${id}/unresolve`,
          body: {},
        }),
      )
    },
  }

  conversations = {
    list: (params: ConversationListQueryParam): Promise<Paginated<Conversation>> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}conversations`,
          query: params,
        }),
      )
    },

    snooze: (id: string, duration: number = 525949200): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/snooze`,
          body: { duration },
        }),
      )
    },

    unsnooze: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/unsnooze`,
        }),
      )
    },

    markAsRead: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/markAsRead`,
        }),
      )
    },

    markAsUnread: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/markAsUnread`,
        }),
      )
    },

    archive: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}`,
        }),
      )
    },

    participantStatus: (
      conversationId: string,
      status: ConversationParticipantStatus,
    ): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${conversationId}/participant/status`,
          body: { name: status, effectiveAt: new Date() },
        }),
      )
    },

    search: (params: ActivitySearchParams): Promise<ActivitySearchResult[]> => {
      return this.transport
        .queue(
          new HttpTransaction({
            url: `${config.COMMUNICATION_SERVICE_URL}conversation/search`,
            query: {
              q: params.query,
              limit: params.limit ?? 50,
              fields: params.fields?.join(',') || undefined,
              phoneNumberIds: params.phoneNumberIds?.join(',') || undefined,
              phoneNumbers: params.phoneNumbers?.join(',') || undefined,
              conversationIds: params.conversationIds?.join(',') || undefined,
            },
          }),
        )
        .then((res) => res.hits.hits)
    },
  }

  ivr = {
    get: (phoneNumberId: string): Promise<IvrSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr/${phoneNumberId}`,
        }),
      )
    },

    set: (settings: IvrSettings): Promise<IvrSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr`,
          body: settings,
        }),
      )
    },

    delete: (phoneNumberId: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr/${phoneNumberId}`,
        }),
      )
    },
  }

  reactions = {
    post: (reaction: EncodableReaction): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}reaction`,
          body: reaction,
        }),
      )
    },

    delete: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}reaction/${id}`,
        }),
      )
    },
  }

  comments = {
    post: (comment: EncodableComment): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}comment`,
          body: comment,
        }),
      )
    },

    delete: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}comment/${id}`,
        }),
      )
    },
  }

  tts(text: string): Promise<string> {
    return this.transport
      .queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}tts`,
          body: { body: text },
        }),
      )
      .then((r) => r.url)
  }

  upload(
    file: File,
    onProgress?: (loaded: number, total: number) => void,
  ): Promise<string> {
    return this.transport.client
      .post(`${config.COMMUNICATION_SERVICE_URL}upload/url`, { type: file.type })
      .then((url) => {
        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progress: ProgressEvent) => {
            onProgress && onProgress(progress.loaded, progress.total)
          },
        }
        return this.transport.client.put(url, file, config).then(() => url.split('?')[0])
      })
  }
}
