import MuiSwitch from '@material-ui/core/Switch'
import { withStyles, createStyles } from '@material-ui/core/styles'

const Switch = withStyles((theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',

      '& $switchBase': {
        padding: 2,
        color: theme.palette.background.paper,

        '&$checked': {
          transform: 'translateX(12px)',
          color: theme.palette.background.paper,

          '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.light,
          },
        },

        '& $track': {
          border: `1.5px solid ${theme.palette.grey[500]}`,
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: theme.palette.common.white,
        },

        '& $thumb': {
          width: 12,
          height: 12,
          boxShadow: 'none',
          background: theme.palette.common.white,
        },
      },
    },
    switchBase: {},
    thumb: {},
    track: {},
    checked: {},
  }),
)(MuiSwitch)

export default Switch
