import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useCallback } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/button-v2'
import {
  HubspotIcon,
  SlackIcon,
  WebhooksIcon,
  ZapierIcon,
} from '@src/component/icons/custom'
import Typography from '@ui/Typography'
import { Header, Page } from '../common'
import Badge from '@ui/Badge'

interface IntegrationRow {
  icon: React.ReactNode
  title: React.ReactNode
  desc: string
  pathname: string
}

const IntegrationList = () => {
  const styles = useStyles({})
  const { history } = useAppStore()

  const integrations: IntegrationRow[] = [
    {
      icon: <HubspotIcon />,
      title: 'HubSpot',
      desc: 'Sync contact and activity data between OpenPhone and HubSpot',
      pathname: '/settings/integrations/hubspot',
    },
    {
      icon: <ZapierIcon />,
      title: 'Zapier',
      desc: 'Automate your workflows and integrate with other compatible apps',
      pathname: '/settings/integrations/zapier',
    },
    {
      icon: <SlackIcon width={30} height={30} />,
      title: 'Slack',
      desc: 'Send call and message logs to your Slack workspace',
      pathname: '/settings/integrations/slack',
    },
    {
      icon: <WebhooksIcon />,
      title: (
        <>
          Webhooks <Badge label="Beta" variant="primary" className={styles.badge} />
        </>
      ),
      desc: 'Setup endpoints to get notified of changes in your OpenPhone workspace',
      pathname: '/settings/webhooks',
    },
  ]

  const renderRow = useCallback(
    ({ icon, title, desc, pathname }: IntegrationRow) => {
      const handleClick = function () {
        history.push(pathname)
      }

      return (
        <Button
          fullWidth
          align="start"
          variant="ghost"
          color="textPrimary"
          key={pathname}
          className={styles.row}
          onClick={handleClick}
        >
          <div className={styles.content}>
            <div className={styles.icon}>{icon}</div>
            <Typography variant="body" color="textPrimary" className={styles.title}>
              {title}
            </Typography>
            <Typography variant="footnote" color="textTertiary">
              {desc}
            </Typography>
          </div>
        </Button>
      )
    },
    [styles],
  )

  return (
    <Page>
      <Header
        title="Integrations"
        subtitle="List of integrations available to your workspace."
      />
      {integrations.map(renderRow)}
    </Page>
  )
}

export default IntegrationList

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: 'unset',
    marginTop: 16,
    backgroundColor: theme.palette.op.match({
      light: theme.palette.op.white,
      dark: alpha(theme.palette.op.hover.primary, 0.11),
    }),
    boxShadow: theme.palette.op.match({
      light: `inset 0 0 0 1.5px ${alpha(
        theme.palette.op.gray[1],
        0.09,
      )}, 0 2px 6px ${alpha(theme.palette.op.black, 0.04)}`,
      dark: `inset 0 0 0 1px ${alpha(theme.palette.op.hover.primary, 0.11)}`,
    }),
    borderRadius: 10,
    padding: 20,
    '&:before': {
      display: 'none',
    },
    '&:hover, &:focus-visible': {
      backgroundColor: theme.palette.op.match({
        light: 'inherit',
        dark: alpha(theme.palette.op.hover.primary, 0.2),
      }),
      boxShadow: theme.palette.op.match({
        light: `inset 0 0 0 1.5px ${alpha(
          theme.palette.op.gray[1],
          0.14,
        )}, 0 2px 6px ${alpha(theme.palette.op.black, 0.06)}`,
        dark: `inset 0 0 0 1px ${alpha(theme.palette.op.hover.darker, 0.25)}`,
      }),
    },
  },
  content: {
    alignItems: 'center',
    columnGap: 16,
    display: 'grid',
    flex: 1,
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateAreas: `
      'icon title'
      'icon description'
    `,
    rowGap: 4,
  },
  icon: {
    alignItems: 'center',
    borderRadius: 6,
    boxShadow: `inset 0 0 0 1.5px ${theme.palette.op.hover.primary}`,
    display: 'flex',
    gridArea: 'icon',
    padding: 6,
    '& > svg': {
      width: 28,
      height: 28,
    },
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    gap: 6,
    gridArea: 'title',
    height: 20,
  },
  badge: {
    alignItems: 'center',
    display: 'inline-flex',
    height: 22,
  },
}))
