// @ts-strict-ignore
import { PopoverOrigin } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { IdentityItem, MenuItem, HeaderItem } from '../menu-v2'
import { CallIcon } from '../icons/Tint/20/General'
import PopoverMenu from '../popover-menu'
import Typography from '@ui/Typography'
import { formatted } from '../../lib/phone-number'
import Controller, { PhoneNumberRow } from './controller'

export interface PhoneNumberSelectorRowProps {
  controller: Controller
  row: PhoneNumberRow
  index: number
}

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }

const PhoneNumberSelectorRow: React.FC<PhoneNumberSelectorRowProps> = ({
  controller,
  row,
  index,
}) => {
  const styles = useStyles({})
  const rowRef = useRef<HTMLDivElement>(null)

  switch (row.type) {
    case 'header':
      return <HeaderItem className={styles.header}>{row.title}</HeaderItem>
    case 'member':
    case 'contact':
      return (
        <React.Fragment>
          {row.identity.phones.length > 1 ? (
            <PopoverMenu
              open={controller.menuIndex === index}
              onClose={() => controller.closeMenu()}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              anchorEl={rowRef.current}
            >
              {row.identity.phones.map((phone) => (
                <MenuItem
                  key={phone.number}
                  onClick={() =>
                    controller.handleSelect(
                      row.type === 'member'
                        ? { type: 'member-via-inbox', to: row.identity, via: phone.id }
                        : { type: 'contact', to: row.identity, via: phone.number },
                    )
                  }
                >
                  <div className={styles.menuItem}>
                    {phone.symbol && phone.name ? (
                      <>
                        <Typography
                          nowrap
                          variant="footnote"
                          color="textPrimary"
                          className={styles.menuItemPhoneName}
                        >
                          {phone.symbol || '📱'} {phone.name}&nbsp;
                        </Typography>
                        <Typography nowrap variant="footnote" color="textSecondary">
                          {formatted(phone.number)}
                        </Typography>
                      </>
                    ) : (
                      <Typography nowrap variant="footnote" color="textPrimary">
                        {formatted(phone.number)}
                      </Typography>
                    )}
                  </div>
                </MenuItem>
              ))}
            </PopoverMenu>
          ) : null}
          <IdentityItem
            ref={rowRef}
            identity={row.identity}
            height="small"
            applyHoverStyles={false}
            onClick={() => controller.handleSelectRow(row, index)}
          />
        </React.Fragment>
      )
    case 'phone-number-member':
    case 'phone-number':
      return (
        <IdentityItem
          identity={row.type === 'phone-number' ? row.phoneNumber.group : row.identity}
          showPhoneNumbers={false}
          height="small"
          applyHoverStyles={false}
          onClick={() => controller.handleSelectRow(row, index)}
        />
      )
    case 'number':
      return (
        <MenuItem
          icon={<CallIcon />}
          applyHoverStyles={false}
          onClick={() => controller.handleSelectRow(row, index)}
        >
          Transfer to <span className={styles.number}>{row.number}</span>
        </MenuItem>
      )
  }
}

export default observer(PhoneNumberSelectorRow)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    header: {
      padding: '20px 0 6px',
    },
    number: {
      fontWeight: 550,
    },
    menuItem: {
      display: 'flex',
    },
    menuItemPhoneName: {
      maxWidth: 146,
    },
  }),
  { name: PhoneNumberSelectorRow.name },
)
