// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
  EmojiAddIcon,
  ThreadCommentIcon,
  CopyIcon,
} from '../../../component/icons/Tint/20/General'
import { PadlockIcon } from '../../../component/icons/Tint/12/General'
import FloatingActionsMenu, { Action } from '../../../component/floating-actions-menu'
import { isTruthy } from '../../../lib'
import { ItemProps } from '.'
import { Item } from '../controller'
import { useAppStore } from '@src/app/context'
import { getEmojis, stripSkinVariation } from '@src/lib/emoji'
import { defaultReactionEmojis } from '@src/service/model'

export interface ActionsMenuProps<I extends Item>
  extends ItemProps<I>,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ActionsMenu = function <I extends Item>(
  { controller, item, ...props }: ActionsMenuProps<I>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const app = useAppStore()
  const styles = useStyles({})
  const [activeAction, setActiveAction] = useState<Action['id']>(null)

  const close = useCallback(() => {
    setActiveAction(null)
    item.menu.close()
  }, [item])

  const closeAfter = useCallback(
    <A extends any[]>(fn: (...args: A) => void) =>
      (...args: A) => {
        fn(...args)
        close()
      },
    [close],
  )

  const emojiService = app.service.emoji
  const reactionEmojis =
    app.service.user.current.settings?.preferences.reactionEmojis ?? defaultReactionEmojis

  const customEmojiActions = useMemo<Action[]>(() => {
    if (!reactionEmojis) return []

    const emojis = getEmojis()

    return reactionEmojis.map((emoji) => {
      const emojiWithoutSkinTone = stripSkinVariation(emoji)
      const emojiData = emojis.get(emojiWithoutSkinTone)
      emoji = emojiService.getEmojiWithSkinTone(emojiWithoutSkinTone)

      return {
        id: `add-${emoji}`,
        title: emojiData?.shortName ?? 'Emoji',
        icon: emoji,
        handler: closeAfter(() => item.handleReaction(emoji)),
      }
    })
  }, [reactionEmojis, item, closeAfter, emojiService])

  const actions = useMemo<Action[]>(
    () =>
      [
        ...customEmojiActions,
        {
          id: 'add-custom-emoji',
          title: 'Choose another emoji',
          icon: <EmojiAddIcon />,
          handler: closeAfter((event, buttonEl) => {
            setActiveAction('add-custom-emoji')
            controller.openEmojiPicker({
              anchorEl: buttonEl,
              anchorOrigin: { vertical: -10, horizontal: 'center' },
              transformOrigin: { vertical: 'bottom', horizontal: 'center' },
              onClose: close,
              onEmojiSelected: item.handleReaction,
            })
          }),
        },
        controller.features.threads
          ? {
              id: 'comment',
              title: (
                <span className={styles.threadAction}>
                  <PadlockIcon className={styles.threadActionIcon} /> Comment in internal
                  thread
                </span>
              ),
              icon: <ThreadCommentIcon />,
              handler: closeAfter(item.handleThreadOpen),
            }
          : null,
        item.copyableText
          ? {
              id: 'copy',
              title: 'Copy',
              icon: <CopyIcon />,
              handler: closeAfter(item.handleCopy),
            }
          : null,
      ].filter(isTruthy),
    [controller, item, closeAfter, customEmojiActions],
  )

  return (
    <FloatingActionsMenu
      ref={ref}
      {...props}
      className={cx(styles.root, props.className)}
      open={Boolean(activeAction) || item.menu.open}
      actions={actions}
      openDelay={item.menu.openDelay}
      closeDelay={item.menu.closeDelay}
    />
  )
}

export default observer(ActionsMenu, { forwardRef: true })

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    threadAction: {
      display: 'flex',
      alignItems: 'center',
    },
    threadActionIcon: {
      color: theme.palette.op.note.highlight,
      marginRight: 4,
    },
  }),
  { name: ActionsMenu.name },
)
