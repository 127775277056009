import { computed, makeObservable, observable } from 'mobx'
import Service from '..'
import { Model } from '.'
import { Capability } from '../transport/billing'
import shortId from '../../lib/short-id'
import log from '@src/lib/log'

export class Capabilities implements Model {
  id = shortId()
  raw: Capability[] = []

  constructor(private root: Service) {
    makeObservable(this, {
      raw: observable.ref,
      isPremium: computed,
      showOnCallStatus: computed,
      maximumPhoneNumberUsers: computed,
    })
  }

  get isPremium() {
    return this.root.billing.subscription.isPremium
  }

  get showOnCallStatus(): boolean {
    const capability = this.raw?.find((c) => c.type === 'showOnCallStatus')
    return capability ? capability.enabled : false
  }

  get maximumPhoneNumberUsers() {
    const capability = this.raw?.find((c) => c.type === 'maxPhoneNumberUsers')

    if (capability) {
      return capability.included === -1 ? Infinity : capability.included
    }

    return this.isPremium ? Infinity : 10
  }

  serialize() {
    return {
      raw: this.raw,
    }
  }

  deserialize(json: any) {
    if (!Array.isArray(json)) {
      log.error('Capabilities.deserialize: json is not an array', { json })
      return this
    }

    this.raw = json
    return this
  }
}
