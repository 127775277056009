import { useCallback, useState } from 'react'
import { useAsyncAction } from '../use-async-action'

export function useAsyncState<T>({
  fetcher,
  enabled,
  defaultValue,
}: {
  fetcher: () => Promise<T>
  enabled: boolean
  defaultValue?: T
}) {
  const [isLoading, setLoading] = useState(true)
  const [value, setValue] = useState<T | undefined>(defaultValue)

  useAsyncAction(
    async () => {
      if (!enabled) return
      return await fetcher()
    },
    (value) => {
      setLoading(false)
      setValue(value)
    },
    [enabled],
  )

  const mutate = useCallback(
    async (callback: () => Promise<void>) => {
      if (!enabled) return

      setLoading(true)

      try {
        await callback()
      } finally {
        setLoading(false)
      }
    },
    [enabled],
  )

  return { value, mutate, isLoading }
}
