// @ts-strict-ignore
import { useTheme } from '@material-ui/core'
import { ThemeKey } from '@src/theme'
import fuzzysort from 'fuzzysort'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useAppStore } from '@src/app/context'
import {
  ActivityIcon,
  AddIcon,
  AnalyticsIcon,
  BlockIcon,
  CallIcon,
  ContactsIcon,
  DeleteIcon,
  DNDIcon,
  EmojiIcon,
  GiftIcon,
  HelpIcon,
  LogOutIcon,
  MarkDone1Icon,
  MarkUnreadIcon,
  MegaphoneIcon,
  MessageIcon,
  PresenceIcon,
  SearchIcon,
  SettingsIcon,
  ViewsIcon,
  PaletteIcon,
} from '../../../component/icons/Tint/20/General'
import Typography from '@ui/Typography'
import { isTruthy } from '../../../lib/rx-operators'
import useInputState from '../../../lib/use-input-state'
import useKeyStepper from '../../../lib/use-key-stepper'
import { Content, HeaderItem, IconItem, InputHeader } from '../common-v2'
import { Command } from '../common-v2'

export interface AppCommandProps {}

type ItemType =
  | { id?: string; type: 'header'; title: React.ReactNode }
  | {
      id?: string
      type: 'item'
      title: string
      icon: React.ReactNode
      aliases?: string
      onSelect: () => void
    }

const AppCommand: React.FC<AppCommandProps> = function ({}) {
  const {
    command,
    conversation: conversationStore,
    history,
    inboxes,
    service,
    signOut,
    voice,
    toast,
    setThemeKey,
  } = useAppStore()
  const [input, setInput] = useInputState('')
  const [filteredItems, setFilteredItems] = useState<ItemType[]>([])
  const theme = useTheme()

  const user = service.user.current.asMember
  const conversation = conversationStore.current
  const inbox = inboxes.selected

  const items: ItemType[] = useMemo(() => {
    let items: ItemType[] = []

    if (conversation) {
      const shortName = conversation.participants[0]?.shortName
      items.push(
        {
          type: 'header',
          title: `Conversation with ${shortName}`,
        },
        shortName &&
          !conversation.isDirect && {
            type: 'item',
            title: `Call ${shortName}`,
            icon: <CallIcon />,
            onSelect: () => {
              voice.startCall(
                inboxes.selectedPhoneNumber,
                conversation.participants[0].phoneNumber,
              )
              command.hide()
            },
          },
        !conversation.isDirect && {
          type: 'item',
          icon: <MarkDone1Icon />,
          title: `Mark as ${conversation.isDone ? 'undone' : 'done'}`,
          onSelect: () => {
            inbox.toggleDone(conversation)
            command.hide()
          },
        },
        {
          type: 'item',
          icon: <MarkUnreadIcon />,
          title: `Mark as ${conversation.isUnread ? 'read' : 'unread'}`,
          onSelect: () => {
            inbox.toggleRead(conversation)
            command.hide()
          },
        },
        {
          type: 'item',
          icon: <DeleteIcon />,
          title: 'Delete conversation',
          aliases: 'archive',
          onSelect: () => {
            inbox.delete(conversation)
            command.hide()
          },
        },
        shortName &&
          !conversation.isDirect && {
            type: 'item',
            icon: <BlockIcon />,
            title: conversation.isBlocked ? `Unblock ${shortName}` : `Block ${shortName}`,
            onSelect: () => {
              const isBlocked = !conversation.isBlocked
              inbox.toggleBlock(conversation)
              toast.show({
                message: `${shortName} ${isBlocked ? 'blocked.' : 'unblocked.'}`,
              })
              command.hide()
            },
          },
      )

      if (!conversation.isDirect) {
        items.push(
          { type: 'header', title: 'Start a conversation' },
          {
            type: 'item',
            icon: <CallIcon />,
            title: 'Make a call',
            onSelect: () => {
              command.present({ name: 'dialer' })
            },
          },
          {
            type: 'item',
            icon: <MessageIcon />,
            title: 'Send a message',
            onSelect: () => {
              if (!inbox) {
                inboxes.setSelected(inboxes.all.list.find((i) => !i.isDirectMessage))
              } else if (inboxes.selected.isDirectMessage) {
                inboxes.setSelected(inboxes.all.list.find((i) => !i.isDirectMessage))
              }
              inboxes.selected.newConversation()
              command.hide()
            },
          },
        )
      }
    }

    items.push(
      { type: 'header', title: 'Your account' },
      {
        type: 'item',
        icon: <PresenceIcon />,
        title: user.presence?.isOnline ? 'Set yourself as away' : 'Go online',
        onSelect: () => {
          user.presence.setOnline(!user.presence.isOnline).catch(toast.showError)
          command.hide()
        },
      },
      {
        type: 'item',
        icon: <DNDIcon />,
        title: user.presence?.isSnoozed ? 'Turn off "Do not Disturb"' : 'Do not disturb',
        aliases: 'snooze notifications',
        onSelect: () => {
          if (user.presence.isSnoozed) {
            command.hide()
            user.presence.unsnooze().catch(toast.showError)
            toast.show({ message: 'Do not disturb turned off.' })
          } else {
            command.present({ name: 'do not disturb' })
          }
        },
      },
      {
        type: 'item',
        icon: <EmojiIcon />,
        title: 'Set your status',
        onSelect: () => {
          command.present({ name: 'member status', presence: user.presence })
        },
      },
      {
        type: 'item',
        icon: <SettingsIcon />,
        title: 'Settings',
        onSelect: () => {
          command.hide()
          history.push('/settings')
        },
      },
      {
        type: 'item',
        icon: <GiftIcon />,
        title: 'Referral',
        onSelect: () => {
          command.present({ name: 'referral' })
        },
      },
    )

    items.push({ type: 'header', title: 'Inboxes' })

    inboxes.all.list.forEach((inbox) => {
      if (inbox.phoneNumber) {
        items.push({
          type: 'item',
          icon: <Typography variant="emoji">{inbox.phoneNumber.symbol}</Typography>,
          title: `Go to ${inbox.phoneNumber.name}`,
          onSelect: () => {
            command.hide()
            inboxes.setSelected(inbox)
          },
        })
      }
    })

    items.push(
      { type: 'header', title: 'Workspace' },
      {
        type: 'item',
        icon: <SearchIcon />,
        title: 'Search',
        onSelect: () => {
          command.present({ name: 'search' })
        },
      },
      {
        type: 'item',
        icon: <AnalyticsIcon />,
        title: 'Go to Analytics',
        onSelect: () => {
          command.hide()
          history.push('/analytics')
        },
      },
      {
        type: 'item',
        icon: <ActivityIcon />,
        title: 'Go to Activity',
        onSelect: () => {
          command.hide()
          history.push('/activities')
        },
      },
      {
        type: 'item',
        icon: <ContactsIcon />,
        title: 'Go to Contacts',
        onSelect: () => {
          command.hide()
          history.push('/contacts')
        },
      },
      {
        type: 'item',
        icon: <AddIcon />,
        title: 'Invite your team',
        onSelect: () => {
          command.present({ name: 'invite member' })
        },
      },
      {
        type: 'item',
        icon: <MessageIcon />,
        title: 'Submit feedback',
        onSelect: async () => {
          command.hide()
          await inboxes.submitFeedback()
        },
      },
      {
        type: 'item',
        icon: <HelpIcon />,
        title: 'Help and support',
        onSelect: () => {
          command.hide()
          window.open('https://help.openphone.co/')
        },
      },
      {
        type: 'item',
        icon: <MegaphoneIcon />,
        title: "What's new",
        onSelect: () => {
          command.hide()
          window.open('https://updates.openphone.co/')
        },
      },
      {
        type: 'item',
        icon: <LogOutIcon />,
        title: 'Log out',
        onSelect: () => {
          command.hide()
          signOut()
        },
      },
    )

    const switchThemeTitleMap: Record<ThemeKey, string> = {
      light: 'Switch to Dark mode',
      dark: 'Switch to Light mode',
    }

    const themeKey = theme.palette.type

    items.push(
      { type: 'header', title: 'Preferences' },
      {
        type: 'item',
        icon: <PaletteIcon />,
        title: switchThemeTitleMap[themeKey],
        onSelect: () => {
          command.hide()

          switch (themeKey) {
            case 'dark':
              setThemeKey('light')
              break
            case 'light':
              setThemeKey('dark')
              break
          }
        },
      },
    )

    return items.filter(isTruthy).map((a) => ({ ...a, id: nanoid() }))
  }, [theme.palette.type])

  useLayoutEffect(() => {
    if (!input) {
      setFilteredItems(items)
      return
    }
    fuzzysort
      .goAsync(
        input,
        items.filter((i) => i.type === 'item'),
        {
          limit: 10,
          keys: ['title', 'aliases'],
          threshold: -10000,
        },
      )
      .then((result) => {
        const items: ItemType[] = result.map((r) => r.obj)
        setFilteredItems(items)
      })
  }, [input])

  const { selectedIndex, getItemProps } = useKeyStepper({
    name: 'command/app',
    items: filteredItems,
    defaultSelectedIndex: 1,
    skip: (item) => item?.type === 'header',
    handleSelect: (item) => {
      if (item?.type === 'item') {
        item.onSelect()
      }
    },
  })

  return (
    <Command>
      <InputHeader
        autoFocus
        value={input}
        onChange={setInput}
        placeholder="Search for a command"
        {...getItemProps(0)}
      />
      <Content>
        {filteredItems.map((item, index) =>
          item.type === 'header' ? (
            <HeaderItem {...getItemProps(index)} key={item.id}>
              {item.title}
            </HeaderItem>
          ) : (
            <IconItem
              {...getItemProps(index)}
              key={item.id}
              selected={index === selectedIndex}
              icon={item.icon}
            >
              {item.title}
            </IconItem>
          ),
        )}
      </Content>
    </Command>
  )
}

export default observer(AppCommand)
