import React from 'react'
import Data from './data'
import Paywall from './paywall'
import { useAppStore } from '@src/app/context'
import { observer } from 'mobx-react-lite'
import useTitle from '../../lib/use-title'

const Analytics = function ({}) {
  const { service } = useAppStore()
  const subscription = service.billing.subscription

  useTitle('Analytics')

  return subscription.isPremium ? <Data /> : <Paywall />
}

export default observer(Analytics)
