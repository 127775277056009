import { useEffect, useState } from 'react'

export default function useInstance<T>(
  fn: (previousInstance: T | null) => T,
  deps: any[] = [],
): T {
  const [instance, setInstance] = useState<T>(() => fn(null))

  useEffect(() => setInstance(fn(instance)), deps)

  return instance
}
