import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Activity } from '../../../service/model'

interface PreviewIconProps {
  activity: Activity
}

const PreviewIcon: React.FC<PreviewIconProps> = function ({ activity }) {
  const styles = useStyles({})
  const isOutgoing = activity?.direction === 'outgoing'
  const type = (() => {
    if (activity?.type === 'call') {
      if (['failed', 'ringing', 'in-progress'].indexOf(activity?.status) !== -1) {
        return isOutgoing ? 'call-outgoing' : 'call-incoming-answered'
      } else if (!activity?.answeredAt) {
        return isOutgoing ? 'call-outgoing-missed' : 'call-incoming-missed'
      }
      return isOutgoing ? 'call-outgoing' : 'call-incoming-answered'
    } else if (activity?.type === 'message' && activity?.media?.length > 0) {
      return 'attachment'
    } else if (activity?.type === 'voicemail') {
      return 'attachment'
    }
    return null
  })()

  switch (type) {
    case 'attachment':
      return (
        <svg
          className={styles.root}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="9"
          viewBox="0 0 14 9"
        >
          <path d="M4.5,0 L11,0 C12.67226,0 14,1.43436 14,3.15 C14,4.86564 12.67226,6.3 11,6.3 L4.5,6.3 C3.51327,6.3 2.7,5.48673 2.7,4.5 C2.7,3.51327 3.51327,2.7 4.5,2.7 L10.7,2.7 C10.860548,2.6976564 11.01524,2.759766 11.13008,2.872266 C11.243753,2.984766 11.308205,3.139452 11.308205,3.300006 C11.308205,3.46056 11.243753,3.614076 11.13008,3.726576 C11.015237,3.839076 10.860548,3.902358 10.7,3.900012 L4.5,3.900012 C4.15665,3.900012 3.9,4.156653 3.9,4.500012 C3.9,4.843371 4.156641,5.100012 4.5,5.100012 L11,5.100012 C11.9879,5.100012 12.8,4.259772 12.8,3.150012 C12.8,2.040252 11.9879,1.200012 11,1.200012 L4.5,1.200012 C2.66133,1.200012 1.2,2.661342 1.2,4.500012 C1.2,6.338682 2.66133,7.800012 4.5,7.800012 L9.5,7.800012 C9.660548,7.7976684 9.81524,7.859778 9.93008,7.972278 C10.043753,8.084778 10.108205,8.239464 10.108205,8.400018 C10.108205,8.560572 10.043753,8.714088 9.93008,8.826588 C9.815237,8.940261 9.660548,9.00237 9.5,9.00008812 L4.5,9.00008812 C2.01681,9.00008812 0,6.983214 0,4.500024 C0,2.01681 2.01681,0 4.5,0 Z" />
        </svg>
      )
    case 'call-incoming-answered':
      return (
        <svg
          className={styles.root}
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
        >
          <polygon
            fillRule="evenodd"
            points="1 0 1 6.292 7 .293 7.707 1 1.706 7 8 7 8 8 0 8 0 0"
          />
        </svg>
      )
    case 'call-incoming-missed':
      return (
        <svg
          className={cx(styles.root, styles.missed)}
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
        >
          <polygon
            fillRule="evenodd"
            points="1 0 1 6.292 7 .293 7.707 1 1.706 7 8 7 8 8 0 8 0 0"
          />
        </svg>
      )
    case 'call-outgoing':
    case 'call-outgoing-missed':
      return (
        <svg
          className={cx(styles.root, type === 'call-outgoing-missed' && styles.missed)}
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
        >
          <polygon
            fillRule="evenodd"
            points="1 0 1 6.292 7 .293 7.707 1 1.706 7 8 7 8 8 0 8 0 0"
            transform="rotate(180 4 4)"
          />
        </svg>
      )
    default:
      return null
  }
}

export default observer(PreviewIcon)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '0 0 auto',
    marginRight: 8,
    fill: theme.palette.op.gray[3],
  },
  missed: {
    fill: theme.palette.op.secondary.red1,
  },
}))
