import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Item, VideosItemPart } from '../controller'
import { ItemPartProps } from '.'
import Bubble from './bubble'
import Row from './row'

export type VideoProps<I extends Item> = ItemPartProps<I, VideosItemPart>

const Video = function <I extends Item>({
  controller,
  item,
  videos,
  ...props
}: VideoProps<I>) {
  const styles = useStyles({})

  return (
    <Row controller={controller} item={item} {...props}>
      <Bubble controller={controller} item={item} {...props} transparent>
        <div className={styles.root}>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.playIcon}
          >
            <circle cx="30" cy="30" r="30" fill="white" fillOpacity="0.2" />
            <circle
              cx="30"
              cy="30"
              r="28.5"
              stroke="white"
              strokeOpacity="0.8"
              strokeWidth="2"
            />
            <path
              d="M38.8138 28.2476C40.1953 29.0074 40.1953 30.9926 38.8138 31.7524L24.9638 39.3699C23.6309 40.103 22 39.1387 22 37.6175L22 22.3825C22 20.8613 23.6309 19.897 24.9638 20.6301L38.8138 28.2476Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>

          {videos.map((video) => (
            <div
              key={video.url}
              className={styles.media}
              onClick={() => controller.openMedia(video)}
            >
              <video src={video.url} />
            </div>
          ))}
        </div>
      </Bubble>
    </Row>
  )
}

export default observer(Video)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      borderRadius: 'inherit',
      overflow: 'hidden',

      '&:hover': {
        '& $playIcon': {
          opacity: 1,
        },
      },
    },
    media: {
      cursor: 'pointer',
      lineHeight: 0,

      '& video': {
        width: 150,
        height: 150,
        objectFit: 'cover',
      },
    },
    playIcon: {
      opacity: 0.7,
      position: 'absolute',
      top: 'calc(50% - 30px)',
      left: 'calc(50% - 30px)',
      transition: theme.transitions.create(['opacity']),
    },
  }),
  { name: Video.name },
)
