// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import Checkbox from '../../../component/checkbox-v2'
import IconButton from '../../../component/icon-button'
import {
  CallIcon,
  CopyIcon,
  EnvelopeIcon,
  MessageIcon,
  NewWindowIcon,
} from '../../../component/icons/Tint/16/General'
import { isNonNull } from '@src/lib/rx-operators'
import { formatDate } from '../../../lib'
import { formatted } from '../../../lib/phone-number'
import { colorForOption } from '../../../service/model/utils'
import { Contact, ContactItem } from '../../../service/model'
import { TemplateTagItemOption } from '../../../types'
import { useEditor } from '../editor'
import Item, { ContactItemMethod, isValueChanged } from './index'
import Tag from './tag'
import { action } from 'mobx'

interface CustomItemProps {
  contact: Contact
  contactItem: ContactItem
  defaultName: string
  placeholder: string
  editingId: string
}

export const placeholders = {
  address: 'e.g. 1 Market St, SF, CA',
  role: 'e.g. Sales',
  email: 'example@openphone.co',
  string: 'Enter a value',
  url: 'e.g. https://openphone.co',
  number: 'e.g. 123.45',
}

const CustomItem: React.FC<CustomItemProps> = function ({
  contact,
  contactItem,
  defaultName,
  placeholder,
  editingId,
}) {
  const styles = useStyles({})
  const itemRef = useRef<ContactItemMethod>(null)
  const valueRef = useRef<HTMLDivElement>(null)
  const [editor] = useEditor()
  const { voice, inboxes, toast, showAlert } = useAppStore()
  const templateItem = contactItem.template

  useEffect(() => {
    if (editingId === contactItem.id) {
      if (templateItem && !templateItem.name) {
        itemRef.current.changeName()
      } else {
        handleEditValue()
      }
    }
  }, [])

  const handleSave = (value: any) => {
    const newValue = typeof value === 'string' ? value.trim() : value
    if (isValueChanged(contactItem.value, newValue)) {
      contactItem.update({ value: newValue }).catch(toast.show)
    }
  }

  const handleSaveTag = (value: any, newOption?: TemplateTagItemOption) => {
    if (newOption) {
      templateItem.update({ options: [...templateItem.options, newOption] })
    }
    handleSave(value)
  }

  const handleEditValue = () => {
    if (contactItem.type === 'phone-number') {
      editor(valueRef.current, {
        name: 'edit phone number',
        defaultValue: contactItem.value,
        onSave: handleSave,
      })
    } else if (Object.keys(placeholders).includes(contactItem.type)) {
      editor(valueRef.current, {
        name: 'edit string',
        defaultValue: contactItem.value,
        placeholder: placeholders[contactItem.type],
        onSave: handleSave,
      })
    } else if (contactItem.type === 'date') {
      editor(valueRef.current, {
        name: 'edit date',
        defaultValue: isNonNull(contactItem.value) ? new Date(contactItem.value) : null,
        clear: Boolean(contactItem.value),
        onSave: (date) => {
          if (date) {
            handleSave(date.toISOString())
          } else {
            handleSave(null)
          }
        },
      })
    } else if (contactItem.type === 'boolean') {
      contactItem.update({ value: !contactItem.value }).catch(toast.showError)
    } else if (contactItem.type === 'multi-select') {
      editor(valueRef.current, {
        name: 'edit tags',
        defaultValue: contactItem.value ?? [],
        templateItem,
        onSave: handleSaveTag,
      })
    }
  }

  const handleCall = (event) => {
    voice.startCall(inboxes.selectedPhoneNumber, contactItem.value)
    event.stopPropagation()
  }

  const handleMessage = (event) => {
    const inbox = inboxes.getOrSetPhoneNumberInbox()
    inbox.newConversation(contactItem.value)
    event.stopPropagation()
  }

  const handleNameChange = (name: string) => {
    if (contactItem.name != name) {
      contactItem.update({ name, isNew: false }).catch(toast.showError)
    }
    if (templateItem && templateItem.name != name) {
      templateItem.update({ name })
    }
  }

  const handleEmail = (event) => {
    event.stopPropagation()
    window.open(`mailto:${contactItem.value}`)
  }

  const handleUrl = (event) => {
    event.stopPropagation()
    window.open(contactItem.value)
  }

  const handleCopy = (event) => {
    let val: string = contactItem.value
    if (contactItem.type === 'phone-number') {
      val = formatted(contactItem.value)
    }
    navigator.clipboard.writeText(val)
    toast.show({ message: `Copied to clipboard.` })
    event.stopPropagation()
  }

  const handleDelete = () => {
    showAlert({
      title: 'Delete contact property',
      body: 'Deleting this contact property will remove it for your entire workspace and remove it from all contacts. Are you sure you want to continue?',
      actions: [
        {
          title: 'Delete',
          type: 'destructive',
          onClick: action(() => {
            if (templateItem) {
              templateItem.delete().catch(toast.showError)
            }

            contactItem.delete().catch(toast.showError)
          }),
        },
        {
          title: 'Cancel',
          type: 'secondary',
        },
      ],
    })
  }

  const renderActions = () => {
    const actions: React.ReactNode[] = []
    if (contactItem.type === 'email') {
      actions.push(
        <IconButton
          key="email"
          title="Email"
          size="small"
          icon={<EnvelopeIcon />}
          onClick={handleEmail}
        />,
      )
    } else if (contactItem.type === 'url') {
      actions.push(
        <IconButton
          key="link"
          size="small"
          title="Open in new window"
          icon={<NewWindowIcon />}
          onClick={handleUrl}
        />,
      )
    } else if (contactItem.type === 'phone-number') {
      actions.push(
        <IconButton
          key="call"
          size="small"
          title="Call"
          icon={<CallIcon />}
          onClick={handleCall}
        />,
        <IconButton
          key="message"
          size="small"
          title="Message"
          icon={<MessageIcon />}
          onClick={handleMessage}
        />,
      )
    }
    if (typeof contactItem.value === 'string') {
      actions.push(
        <IconButton
          key="copy"
          title="Copy"
          size="small"
          icon={<CopyIcon />}
          onClick={handleCopy}
        />,
      )
    }
    return actions
  }

  const renderValue = () => {
    if (contactItem.type === 'phone-number') {
      return formatted(contactItem.value)
    } else if (Object.keys(placeholders).includes(contactItem.type)) {
      return contactItem.value
    } else if (contactItem.type === 'date') {
      return contactItem.value ? formatDate(contactItem.value) : null
    } else if (contactItem.type === 'boolean') {
      return <Checkbox checked={contactItem.value} />
    } else if (contactItem.type === 'multi-select') {
      return contactItem.value?.length === 0 || !contactItem.value ? null : (
        <div className={styles.tags}>
          {contactItem.value.map ? (
            contactItem.value.map((item) => (
              <Tag key={item} name={item} color={colorForOption(item, templateItem)} />
            ))
          ) : (
            <Tag
              key={contactItem.value}
              name={contactItem.value}
              color={colorForOption(contactItem.value, templateItem)}
            />
          )}
        </div>
      )
    }
  }

  const name = contactItem.template?.name || contactItem.name || defaultName
  const isDraggable = contactItem.type !== 'phone-number' && contactItem.type !== 'email'

  return (
    <Item
      ref={itemRef}
      type={contactItem.type}
      name={name}
      draggable={isDraggable}
      placeholder={placeholder}
      valueRef={valueRef}
      value={renderValue()}
      onValueClick={handleEditValue}
      onNameChange={handleNameChange}
      onDelete={handleDelete}
      actions={renderActions()}
    />
  )
}

export default observer(CustomItem)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))
