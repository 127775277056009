import FormControl from '@material-ui/core/FormControl'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Typography from '@ui/Typography'
import Button from '../../component/button'
import TextField from '../../component/textfield-v2'
import useInputState from '../../lib/use-input-state'
import { Header, Page } from './common'
import { AppAvatar } from '@src/app/components'

interface AccountProps {}

const Account: React.FC<AccountProps> = function ({}) {
  const styles = useStyles({})
  const { service, toast, command } = useAppStore()
  const user = service.user.current
  const [firstName, setFirstName] = useInputState(user.firstName ?? '')
  const [lastName, setLastName] = useInputState(user.lastName ?? '')
  const isEnabled = firstName !== user.firstName || lastName !== user.lastName

  const handleOpenUpload = () => {
    command.present({
      name: 'upload',
      accept: ['image/jpeg', 'image/png', 'image/gif'],
      title: 'Upload a new photo',
      description: 'Drag and drop an image here, or click to select a file.',
      onComplete: onPhotoUploaded,
    })
  }

  const handleChangeEmail = () => {
    command.present({ name: 'change email' })
  }

  const onPhotoUploaded = (url: string) => {
    command.hide()
    user.update({ pictureUrl: url })
  }

  const handleSave = () => {
    user.update({ firstName, lastName })
    toast.show({ message: 'Profile updated!' })
  }

  return (
    <Page>
      <Header title="Account" subtitle="Manage your OpenPhone profile" />
      <div className={styles.row}>
        <Button variant="text" onClick={handleOpenUpload}>
          <AppAvatar size={80} hideStatus identity={user.asMember} />
        </Button>
      </div>
      <div className={styles.row}>
        <TextField
          label="First Name"
          value={firstName}
          onChange={setFirstName}
          style={{ marginRight: 16 }}
        />
        <TextField label="Last Name" value={lastName} onChange={setLastName} />
      </div>
      <div className={styles.row}>
        <FormControl className={styles.cell}>
          <Typography variant="caption1" color="textSecondary">
            Email
          </Typography>
          <div className={styles.emailField}>
            <Typography>{user.email}</Typography>
            <Button
              variant="text"
              color="primary"
              className={styles.changeEmail}
              onClick={handleChangeEmail}
            >
              Change
            </Button>
          </div>
        </FormControl>
      </div>
      <div className={styles.footer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isEnabled}
        >
          Save
        </Button>
      </div>
    </Page>
  )
}

export default observer(Account)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    display: 'flex',
    marginTop: 20,
  },
  cell: {
    marginRight: 15,
  },
  emailField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  changeEmail: {
    marginLeft: 10,
  },
  footer: {
    marginTop: 40,
  },
}))
