// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppStore } from '@src/app/context'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { ContactView } from '../../../service/model'
import CompanyFilter from './companies'
import Filters from './filters'
import ContactSources from './sources'

export interface ContactFilterCommandProps {
  view: ContactView
}

export type FilterType = 'source' | 'company'

const ContactFilterCommand: React.FC<ContactFilterCommandProps> = function ({ view }) {
  const styles = useStyles({})
  const [filterType, setFilterType] = useState<FilterType>(null)
  const { contacts } = useAppStore()

  const handleBack = () => {
    setFilterType(null)
  }

  switch (filterType) {
    case 'source':
      return <ContactSources view={view} onBack={handleBack} />
    case 'company':
      return (
        <CompanyFilter
          controller={contacts.companiesFilterController}
          onBack={handleBack}
        />
      )
    default:
      return <Filters setFilterType={setFilterType} />
  }
}

export default observer(ContactFilterCommand)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
