import React from 'react'
import GetApp from '@material-ui/icons/GetApp'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Header from '../common/header'
import TextField from '../common/textfield'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'

interface ImportNameProps {
  count: number
  name: string
  onImport: (name: string) => void
}

const ImportName: React.FC<ImportNameProps> = function ({ count, name, onImport }) {
  const styles = useStyles({})
  const [input, setInput] = useInputState(name)

  const handleImport = () => {
    onImport(input)
  }

  return (
    <Command>
      <Header title="Name the import" subtitle="Give this import a name." />
      <TextField value={input} onChange={setInput} />
      <Footer
        actions={
          <FooterButton
            onClick={handleImport}
            startIcon={<GetApp />}
            variant="contained"
            color="primary"
          >
            Import {count.toLocaleString('en')} contacts
          </FooterButton>
        }
      />
    </Command>
  )
}

export default ImportName

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
