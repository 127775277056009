import { PopoverOrigin } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { last } from '../../lib/collections'
import { friendlyDateTime } from '../../lib/date'
import { ThreadResolveIcon as ThreadResolveIcon16 } from '../icons/Tint/16/General'
import {
  CommentsHideIcon,
  CommentsShowIcon,
  HideIcon,
  MoreIcon,
  ThreadResolveIcon as ThreadResolveIcon20,
} from '../icons/Tint/20/General'
import { ThreadResolvedIcon } from '../icons/NoTint/16/General'
import IconButton from '../icon-button'
import Typography from '@ui/Typography'
import PopoverMenu, { MenuItem } from '../popover-menu'
import Controller, { CommentFeedItem } from './controller'
import { AppAvatarRow } from '@src/app/components'

export { Controller }

export interface CommentsHeaderProps<I extends CommentFeedItem> {
  controller: Controller<I>
  className: string
}

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

const CommentsHeader = function <I extends CommentFeedItem>({
  controller,
  className,
}: CommentsHeaderProps<I>) {
  const styles = useStyles({})
  const theme = useTheme()
  const menuOpenRef = useRef<HTMLButtonElement>(null)

  const lastComment = last(controller.items.list)
  const participants = controller.participants.slice(0, 3)

  return (
    <>
      <PopoverMenu
        open={Boolean(controller.menu.open)}
        onClose={controller.menu.handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={menuOpenRef.current}
      >
        {controller.resolved ? (
          <MenuItem
            icon={<ThreadResolveIcon20 />}
            onClick={controller.handleUnresolveClick}
          >
            Mark as unresolved
          </MenuItem>
        ) : (
          <MenuItem
            icon={<ThreadResolveIcon20 />}
            onClick={controller.handleResolveClick}
          >
            Mark as resolved
          </MenuItem>
        )}
        {controller.collapsed || controller.previousCommentCount > 0 ? (
          <MenuItem
            icon={<CommentsShowIcon />}
            onClick={controller.handleShowAllCommentsClick}
          >
            Show all comments
          </MenuItem>
        ) : (
          <MenuItem
            icon={<CommentsHideIcon />}
            onClick={controller.handleCollapseOlderCommentsClick}
          >
            Collapse older comments
          </MenuItem>
        )}
        {controller.collapsed ? (
          <MenuItem icon={<CommentsShowIcon />} onClick={controller.handleExpandClick}>
            View all comments
          </MenuItem>
        ) : (
          <MenuItem icon={<HideIcon />} onClick={controller.handleCollapseClick}>
            Hide all comments
          </MenuItem>
        )}
      </PopoverMenu>
      <div className={cx(styles.root, className)} onClick={controller.handleHeaderClick}>
        <div className={styles.status}>
          {controller.resolved ? (
            <>
              <ThreadResolvedIcon className={styles.icon} />
              <Typography variant="caption3" color="textTertiary" fontWeight="regular">
                Thread resolved{' '}
                <span className={styles.time}>
                  {friendlyDateTime(controller.resolvedAt, { upperFirst: false })}
                </span>
              </Typography>
            </>
          ) : (
            <>
              <AppAvatarRow size={16} identities={participants} />
              <Typography variant="caption3" color="textSecondary" fontWeight="regular">
                {controller.items.length} comment
                {controller.items.length === 1 ? '' : 's'}
              </Typography>
              <Typography variant="caption3" color="textTertiary" fontWeight="regular">
                Last comment{' '}
                <span className={styles.time}>
                  {friendlyDateTime(lastComment.source.createdAt, { upperFirst: false })}
                </span>
              </Typography>
            </>
          )}
        </div>
        <div className={styles.actions}>
          <IconButton
            ref={menuOpenRef}
            icon={<MoreIcon />}
            size="small"
            title="More actions"
            tooltipPlacement="top"
            onClick={controller.handleMenuOpenClick}
          />
          {controller.resolved ? null : (
            <IconButton
              icon={<ThreadResolveIcon16 />}
              size="small"
              color={theme.palette.op.secondary.green2}
              title="Mark as resolved"
              tooltipPlacement="top"
              onClick={controller.handleResolveClick}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default observer(CommentsHeader)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    status: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',

      '& > *:not(:last-child)': {
        marginRight: 5,
      },
    },
    actions: {
      display: 'flex',

      '& > *:not(:last-child)': {
        marginRight: 2,
      },
    },
    icon: {
      width: 16,
      height: 16,
    },
    time: {},
  }),
  { name: CommentsHeader.name },
)
