// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckedIcon from '@material-ui/icons/CheckCircle'
import NextIcon from '@material-ui/icons/NavigateNext'
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { isValidEmail } from '@openphone/lib'
import React, { useEffect, useState } from 'react'
import { useAppStore } from '@src/app/context'
import { MenuItem, Select } from '../../../component/menu'
import { Input } from '../../../component/textfield'
import { omit } from '../../../lib/collections'
import { parse } from '../../../lib/date'
import { isValid as isValidPhoneNumber } from '../../../lib/phone-number'
import { isTruthy } from '../../../lib/string'
import { Contact } from '../../../service/model'
import { Command } from '../common-v2'
import Content from '../common/content'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Header from '../common/header'

export interface FieldsProps {
  data: any[]
  fields: string[]
  onComplete: (contacts: Contact[]) => void
}

const Fields: React.FC<FieldsProps> = function ({ data, fields, onComplete }) {
  const styles = useStyles({})
  const { service } = useAppStore()
  const [mapping, setMapping] = useState<{ [key: string]: string }>({})
  const templateItems = service.contact.template.list

  const availableFields = [
    { name: 'No match', value: '' },
    { name: 'First name', value: 'firstName', single: true },
    { name: 'Last name', value: 'lastName', single: true },
    { name: 'Company', value: 'company', single: true },
    { name: 'Role', value: 'role', single: true },
    { name: 'Picture URL', value: 'pictureUrl', single: true },
    { name: 'Phone Number', value: 'phone-number' },
    { name: 'Email', value: 'email' },
    ...templateItems.map((item) => ({
      name: item.name,
      value: item.key,
      type: 'template',
      single: true,
    })),
  ]

  useEffect(() => {
    if (!data || !fields) return

    fields.map((field) => {
      if (!field) return
      if (['first', 'given'].some((t) => field.toLowerCase().includes(t))) {
        safeSetMapping(field, 'firstName')
      } else if (['last', 'family'].some((t) => field.toLowerCase().includes(t))) {
        safeSetMapping(field, 'lastName')
      } else if (['company'].some((t) => field.toLowerCase().includes(t))) {
        safeSetMapping(field, 'company')
      } else {
        for (const row of data) {
          if (!row) continue
          if (isValidEmail(row[field])) {
            safeSetMapping(field, 'email')
            break
          }
          if (isValidPhoneNumber(row[field])) {
            safeSetMapping(field, 'phone-number')
            break
          }
        }
      }
    })
  }, [data, fields])

  const safeSetMapping = (field: string, value: string) => {
    const fieldInfo = availableFields.find((a) => a.value === value)

    setMapping((mapping) => {
      let newMapping = mapping
      if ('single' in fieldInfo) {
        const exitingKey = Object.keys(mapping).find((key) => mapping[key] === value)
        if (exitingKey) {
          newMapping = omit(mapping, exitingKey)
        }
      }
      return { ...newMapping, [field]: value }
    })
  }

  const handleSet = (field: string) => (event) => {
    safeSetMapping(field, event.target.value)
  }

  const handleNext = () => {
    const contacts: Contact[] = data.map((row) => {
      const contact = new Contact(service)
      for (const csvField of Object.keys(mapping)) {
        const opField = mapping[csvField]
        const value = row[csvField]
        if (!value) continue

        if (opField === 'firstName') {
          contact.firstName = value
        } else if (opField === 'lastName') {
          contact.lastName = value
        } else if (opField === 'company') {
          contact.company = value
        } else if (opField === 'role') {
          contact.role = value
        } else if (opField === 'pictureUrl') {
          contact.pictureUrl = value
        } else if (['phone-number', 'email'].includes(opField)) {
          contact.addItem({ type: opField as any, value: value })
        } else {
          const template = templateItems.find((t) => t.key === opField)
          if (!template) continue

          if (template.type === 'multi-select') {
            contact.addItem({
              type: template.type,
              name: template.name,
              value: [value],
              templateKey: template.key,
            })
          } else if (template.type === 'boolean') {
            contact.addItem({
              type: template.type,
              name: template.name,
              value: isTruthy(value),
              templateKey: template.key,
            })
          } else if (template.type === 'date') {
            contact.addItem({
              type: template.type,
              name: template.name,
              value: parse(value),
              templateKey: template.key,
            })
          } else if (
            template.type === 'string' ||
            template.type === 'number' ||
            template.type === 'url' ||
            template.type === 'address'
          ) {
            contact.addItem({
              type: template.type,
              name: template.name,
              value: value,
              templateKey: template.key,
            })
          }
        }
      }
      return contact
    })
    onComplete(contacts)
  }

  return (
    <Command>
      <Header
        title={`Importing ${data.length} contacts`}
        subtitle="Map fields in your CSV to OpenPhone."
      />
      <Content>
        <div className={styles.list}>
          <div className={styles.item}>
            <Typography variant="caption" color="textSecondary" className={styles.label}>
              CSV
            </Typography>
            <div className={styles.inputWrapper}>
              <Typography variant="caption" color="textSecondary">
                OpenPhone
              </Typography>
            </div>
          </div>
          {fields?.map((field) => (
            <div key={field} className={styles.item}>
              <Typography variant="body2" color="textPrimary" className={styles.label}>
                {field}
              </Typography>
              <div className={styles.inputWrapper}>
                <Select
                  fullWidth
                  inputProps={{ className: styles.input }}
                  input={<Input />}
                  value={mapping[field] ?? ''}
                  onChange={handleSet(field)}
                >
                  {availableFields.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <span className={styles.status}>
                  {mapping[field] ? (
                    <CheckedIcon color="secondary" fontSize="small" />
                  ) : (
                    <UncheckedIcon fontSize="small" />
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Content>
      <Footer
        actions={
          <FooterButton
            variant="contained"
            startIcon={<NextIcon />}
            color="primary"
            onClick={handleNext}
          >
            Next
          </FooterButton>
        }
      />
    </Command>
  )
}

export default Fields

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  list: {
    padding: '1rem 2rem',
  },
  label: {
    flex: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 25,
    textAlign: 'right',
  },
  inputWrapper: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    padding: '8px 16px',
    fontSize: 14,
  },
  status: {
    marginLeft: 15,
    color: theme.palette.op.background.highlight(0.1),
  },
}))
