import { makeStyles, Theme } from '@material-ui/core/styles'
import RecordIcon from '@material-ui/icons/Mic'
import UploadIcon from '@material-ui/icons/Backup'
import TTSIcon from '@material-ui/icons/Receipt'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import React from 'react'
import CommandItem from '../common/item'
import useKeyStepper from '../../../lib/use-key-stepper'
import Content from '../common/content'

export type GreetingOption = 'record' | 'upload' | 'text-to-speach'

export interface GreetingOptionsCommandProps {
  onSelect: (option: GreetingOption) => void
}

const items: GreetingOption[] = ['upload', 'record', 'text-to-speach']

const GreetingOptionsCommand: React.FC<GreetingOptionsCommandProps> = function ({
  onSelect,
}) {
  const styles = useStyles({})

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'command/set-greeting/options',
    handleSelect: (item) => onSelect(item),
  })

  return (
    <Content>
      {items.map((item, index) => (
        <CommandItem
          key={item}
          {...getItemProps(index)}
          highlighted={selectedIndex === index}
        >
          {item === 'record' ? (
            <>
              <RecordIcon color="inherit" fontSize="small" className={styles.icon} />
              <Typography variant="body1" color="textPrimary">
                Record
              </Typography>
            </>
          ) : item === 'upload' ? (
            <>
              <UploadIcon color="inherit" fontSize="small" className={styles.icon} />
              <Typography variant="body1" color="textPrimary">
                Upload a file
              </Typography>
            </>
          ) : (
            <>
              <TTSIcon color="inherit" fontSize="small" className={styles.icon} />
              <Typography variant="body1" color="textPrimary">
                Text to speech
              </Typography>
            </>
          )}
        </CommandItem>
      ))}
    </Content>
  )
}

export default observer(GreetingOptionsCommand)

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.primary,
    marginRight: 16,
  },
}))
