import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { DangerButton } from '../../component/button'
import TextareaAutosize from '../../component/textarea-autoresize'
import Header from './common/header'
import Footer from './common/footer'
import Button from './common/footer-button'
import { MinimalTextField } from './common/textfield'
import { Command } from './common-v2'
import { Snippet } from '../../service/model'
import useInputState from '../../lib/use-input-state'

export interface EditSnippetProps {
  snippet: Snippet
  onSave: (snippet: Snippet) => void
  onDelete: (snippet: Snippet) => void
  onShare: (snippet: Snippet) => void
}

const EditSnippet: React.FC<EditSnippetProps> = function ({
  snippet,
  onShare,
  onSave,
  onDelete,
}) {
  const styles = useStyles({})
  const [name, setName] = useInputState(snippet?.name ?? '')
  const [text, setText] = useInputState(snippet?.text ?? '')

  const handleSave = () => {
    snippet.name = name
    snippet.text = text
    onSave(snippet)
  }

  const handleShare = () => {
    snippet.name = name
    snippet.text = text
    onShare(snippet)
  }

  const handleDelete = () => {
    onDelete(snippet)
  }

  return (
    <Command>
      <Header
        title={snippet.isNew ? 'Create a snippet' : 'Edit snippet'}
        subtitle="Save a message you send often for later."
      />
      <div className={styles.root}>
        <MinimalTextField
          autoFocus
          value={name}
          onChange={setName}
          placeholder="Give it a name"
          style={{ marginBottom: 10 }}
        />
        <TextareaAutosize
          value={text}
          onChange={setText}
          className={styles.input}
          placeholder="Write the message you want to send..."
          style={{ overflow: 'auto' }}
        />
      </div>
      <Footer
        actions={
          <>
            <Button style={{ marginLeft: 30 }} variant="text" onClick={handleShare}>
              Share
            </Button>
            <Button style={{ marginLeft: 10 }} variant="contained" onClick={handleSave}>
              Save
            </Button>
          </>
        }
      >
        {!snippet.isNew && (
          <DangerButton variant="text" onClick={handleDelete}>
            Delete
          </DangerButton>
        )}
      </Footer>
    </Command>
  )
}

export default EditSnippet

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '1rem 2rem',
    flexDirection: 'column',
    overflow: 'auto',
  },
  input: {
    resize: 'none',
    fontSize: '0.9rem',
    minHeight: 70,
  },
}))
