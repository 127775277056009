import { makeStyles, Theme } from '@material-ui/core'
import { RadioButton } from '@src/component'
import Typography from '@ui/Typography'
import cx from 'classnames'
import React from 'react'

interface EventRowProps {
  event: { name: string; description: string }
  checkedValue: string
  setCheckedValue: React.Dispatch<React.SetStateAction<string>>
  disabled: boolean
}

const EventRow = ({ event, checkedValue, setCheckedValue, disabled }: EventRowProps) => {
  const checked = checkedValue === event.name
  const styles = useStyles({ disabled: disabled && !checked })

  const handleOnChange = () => {
    setCheckedValue(event.name)
  }

  return (
    <label className={cx(styles.root)}>
      <RadioButton
        name="event"
        value={event.name}
        checked={checked}
        className={styles.radio}
        onChange={handleOnChange}
      />
      <div className={styles.infoContainer}>
        <Typography variant="code" color={'textPrimary'}>
          {event.name}
        </Typography>
        <Typography variant="caption2" color={'textTertiary'} style={{ marginTop: 4 }}>
          {event.description}
        </Typography>
      </div>
    </label>
  )
}

const useStyles = makeStyles<Theme, { disabled: boolean }>(
  (theme: Theme) => ({
    root: ({ disabled }) => ({
      display: 'flex',
      alignItems: 'center',
      height: 56,
      padding: '11px 20px',
      borderRadius: 8,
      opacity: disabled ? 0.45 : 'inherit',
    }),
    radio: {
      padding: 0,
      alignSelf: 'start',
    },
    infoContainer: {
      marginLeft: 21,
      cursor: 'pointer',
      flex: 1,
    },
  }),
  { name: EventRow.name },
)

export default EventRow
