import React, { useRef, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '../../component/button'
import PopoverMenu, {
  MenuItem,
  HeaderItem,
  SeparatorItem,
} from '../../component/popover-menu'
import {
  AtIcon,
  PhoneIcon,
  AddressIcon,
  ChecboxIcon,
  DateIcon,
  MultiSelectIcon,
  NumberIcon,
  TextIcon,
  URLIcon,
  AddIcon,
} from '../../component/icons/Tint/16/ContactInfo'
import { ContactItemType } from '../../types'

interface AddNewItemProps {
  onSelect: (type: ContactItemType) => void
}

const AddNewItem: React.FC<AddNewItemProps> = function ({ onSelect }) {
  const styles = useStyles({})
  const ref = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const handleSelect = (type: ContactItemType) => {
    onSelect(type)
    handleClose()
  }

  const handleAdd = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        ref={ref}
        startIcon={<AddIcon />}
        size="small"
        variant="text"
        color="primary"
        onClick={handleAdd}
        className={styles.button}
      >
        Add a property
      </Button>
      <PopoverMenu anchorEl={ref.current} open={open} onClose={handleClose}>
        <MenuItem icon={<PhoneIcon />} onClick={() => handleSelect('phone-number')}>
          Phone Number
        </MenuItem>
        <MenuItem icon={<AtIcon />} onClick={() => handleSelect('email')}>
          Email
        </MenuItem>
        <SeparatorItem />
        <HeaderItem>Custom Properties</HeaderItem>
        <MenuItem icon={<AddressIcon />} onClick={() => handleSelect('address')}>
          Address
        </MenuItem>
        <MenuItem icon={<URLIcon />} onClick={() => handleSelect('url')}>
          URL
        </MenuItem>
        <MenuItem icon={<MultiSelectIcon />} onClick={() => handleSelect('multi-select')}>
          Tags
        </MenuItem>
        <MenuItem icon={<DateIcon />} onClick={() => handleSelect('date')}>
          Date
        </MenuItem>
        <MenuItem icon={<ChecboxIcon />} onClick={() => handleSelect('boolean')}>
          Checkbox
        </MenuItem>
        <MenuItem icon={<TextIcon />} onClick={() => handleSelect('string')}>
          Text
        </MenuItem>
        <MenuItem icon={<NumberIcon />} onClick={() => handleSelect('number')}>
          Number
        </MenuItem>
      </PopoverMenu>
    </>
  )
}

export default AddNewItem

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    padding: '4px 11px',
    marginTop: 10,
  },
}))
