// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { isElement } from '../../lib/dom'
import { VirtualListItem, VirtualListStore } from './store'

interface ItemGroupProps<T extends VirtualListItem>
  extends React.HTMLProps<HTMLDivElement> {
  store: VirtualListStore<T>
}

const ItemGroup: <T extends VirtualListItem>(
  p: ItemGroupProps<T>,
) => React.ReactElement<ItemGroupProps<T>> = function ({ store, ...props }) {
  const ref = useRef<HTMLDivElement>(null)

  const callback = () => {
    store.setHeights(getNodeHeights(Array.from(ref.current.childNodes)))
  }

  useEffect(() => {
    store.setHeights(getNodeHeights(Array.from(ref.current.childNodes)))
    const mutationObserver = new MutationObserver(callback)
    const resizeObserver = new ResizeObserver(callback)
    mutationObserver.observe(ref.current, { childList: true })
    resizeObserver.observe(ref.current)
    return () => {
      mutationObserver.disconnect()
      resizeObserver.disconnect()
    }
  }, [store])

  return <div ref={ref} {...props} />
}

export default ItemGroup

const getNodeHeights = (nodes: readonly Node[]): { [key: string]: number } => {
  return nodes.reduce((acc, node) => {
    if (isElement(node)) {
      const id = node.getAttribute('data-item-id')
      if (id) acc[id] = node.getBoundingClientRect().height
    }

    return acc
  }, {})
}
