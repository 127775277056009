import {
  Action,
  createBrowserHistory as createBrowser,
  createMemoryHistory as createMemory,
  Location,
} from 'history'
import { Observable } from 'rxjs'
import { ExtendedHistory } from '../types'
import analytics from './analytics'

const createBrowserHistory = (): ExtendedHistory => {
  const history: ExtendedHistory = createBrowser()

  history.listen(({ pathname }) => {
    analytics.page(pathname)
  })

  history.observe = () => {
    return new Observable((observer) => {
      observer.next({ location: history.location, action: history.action })
      const unsubscribe = history.listen((location: Location, action: Action) => {
        observer.next({ location, action })
      })

      return unsubscribe
    })
  }

  return history
}

const createMemoryHistory = (): ExtendedHistory => {
  const history: ExtendedHistory = createMemory()

  history.listen(({ pathname }) => {
    analytics.page(pathname)
  })

  history.observe = () => {
    return new Observable((observer) => {
      observer.next({ location: history.location, action: history.action })
      const unsubscribe = history.listen((location: Location, action: Action) => {
        observer.next({ location, action })
      })

      return unsubscribe
    })
  }

  return history
}

export { createBrowserHistory, createMemoryHistory }
