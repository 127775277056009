import React, { useEffect } from 'react'
import { logError } from '../../../lib/log'
import { ItemProps } from '.'
import { Item } from '../controller'

export type LoadMoreProps<I extends Item> = ItemProps<I>

const LoadMore = function <I extends Item>({ controller, item }: LoadMoreProps<I>) {
  useEffect(() => {
    if (item.source.beforeId) {
      controller.fetchBefore(item).catch(logError)
    } else if (item.source.afterId) {
      controller.fetchAfter(item).catch(logError)
    }
  }, [])

  return <div style={{ height: 1 }} />
}

export default LoadMore
