// @ts-strict-ignore
import Popper from '@material-ui/core/Popper'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { fromEvent } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { useAppStore } from '@src/app/context'
import ClickAwayListener from '@ui/ClickAwayListener'
import input from '../../../../component/input'
import { highlight } from '../../../../lib'
import { useObservable } from '../../../../lib/hooks'
import { formatted, toE164 } from '../../../../lib/phone-number'
import { usePhoneNumberInputState } from '../../../../lib/use-input-state'
import useKeyStepper from '../../../../lib/use-key-stepper'
import { Identity, IdentityPhone } from '../../../../service/model'
import { SearchResult } from '../../../../service/search-store'
interface ParticipantInputProps {
  defaultValue?: string
  onSet: (phoneNumber: string, defaultValue?: string, type?: 'click' | 'enter') => void
  onDeleteLast: () => void
}

export interface ParticipantInputMethods {
  focus: () => void
}

const ParticipantInput: React.ForwardRefRenderFunction<
  ParticipantInputMethods,
  ParticipantInputProps
> = ({ defaultValue, onSet, onDeleteLast }, outerRef) => {
  const styles = useStyles({})
  const { service } = useAppStore()
  const shadowRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = usePhoneNumberInputState(formatted(defaultValue) || '')
  const [openSuggestions, setOpenSuggestions] = useState(false)
  const [width, setWidth] = useState<number>(2)
  const [items, setItems] = useState<SearchResult[]>([])

  useObservable(
    () =>
      fromEvent(inputRef.current, 'input').pipe(
        debounceTime(50),
        map((e) => (e.target as HTMLInputElement).value),
      ),
    function (value) {
      service.search.identities(value, 5).then((items) => {
        setItems(items)
        setOpenSuggestions(items.length > 0)
      })
    },
  )

  const handleClickAway = () => {
    if (value && value !== defaultValue) {
      onSet(toE164(inputRef.current.value), defaultValue, 'click')
    }
    setValue('')
    setOpenSuggestions(false)
  }

  const handleSelect = (phoneNumber: string) => {
    onSet(phoneNumber, defaultValue, 'enter')
    setValue('')
    setOpenSuggestions(false)
  }

  useImperativeHandle(outerRef, () => ({
    focus: () => {
      inputRef.current.focus()
    },
  }))

  useLayoutEffect(() => {
    setWidth(shadowRef.current.getBoundingClientRect().width + 2 || 2)
  }, [value])

  useLayoutEffect(() => {
    if (defaultValue) {
      inputRef.current.select()
    }
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      ((event.key === 'Enter' && !openSuggestions) || event.key === 'Tab') &&
      inputRef.current.value
    ) {
      handleClickAway()
    } else if (
      event.key === 'Backspace' &&
      inputRef.current.selectionStart === 0 &&
      inputRef.current.selectionEnd === 0
    ) {
      onDeleteLast()
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.root}>
        <input
          tabIndex={0}
          aria-describedby="input-participant"
          ref={inputRef}
          className={styles.input}
          value={value}
          onChange={setValue}
          style={{ width }}
          onKeyDown={handleKeyDown}
        />
        <div ref={shadowRef} className={styles.shadow}>
          {value}
        </div>
        <Popper
          id="input-participant"
          anchorEl={inputRef.current}
          open={openSuggestions}
          placement="bottom-start"
          className={styles.suggestions}
        >
          <ParticipantSuggestions query={value} items={items} onSelect={handleSelect} />
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

const ParticipantSuggestions: React.FC<{
  query: string
  items: SearchResult[]
  onSelect: (phoneNumber: string) => void
}> = function ({ query, items, onSelect }) {
  const [selectedParticipant, setSelectedParticipant] = useState<Identity>(null)

  return selectedParticipant ? (
    <ParticipantPhoneNumberList
      identity={selectedParticipant}
      items={selectedParticipant.phones}
      onSelect={onSelect}
    />
  ) : (
    <ParticipantSuggestionList
      items={items}
      onSelect={onSelect}
      query={query}
      onParticipantSelected={setSelectedParticipant}
    />
  )
}

const ParticipantSuggestionList: React.FC<{
  query: string
  items: SearchResult[]
  onSelect: (phoneNumber: string) => void
  onParticipantSelected: (identity: Identity) => void
}> = observer(function ({ query, items, onSelect, onParticipantSelected }) {
  const styles = useStyles({})
  const { toast } = useAppStore()

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    defaultSelectedIndex: 0,
    deps: [input],
    name: 'add-participant/input',
    handleSelect: (result) => {
      let phone: string
      if (!result) {
        phone = toE164(input)
      } else if (result.matchedPhoneNumber) {
        phone = result.matchedPhoneNumber
      } else if (result.identity.phones.length > 1) {
        onParticipantSelected(result.identity)
        return
      } else if (result.identity.phones.length === 1) {
        phone = result.identity.phones[0].number
      } else {
        toast.show({
          message: `${result.identity.name} has no phone numbers`,
        })
        return
      }
      onSelect(toE164(phone))
    },
  })

  const getValue = (item: SearchResult): string => {
    if (item.matchedPhoneNumber) {
      return highlight(item.matchedPhoneNumber, query)
    } else if (item.identity.phones.length === 1) {
      const phone = item.identity.phones[0].number
      return highlight(formatted(phone), query)
    } else {
      const phones = item.identity.phones
      return `${phones.length} numbers`
    }
  }

  return (
    <>
      {items.map((item, index) => (
        <div
          {...getItemProps(index)}
          key={item.identity.id}
          className={cx(styles.row, selectedIndex === index && styles.selected)}
        >
          <div className={styles.name}>
            <AppAvatar identity={item.identity} size={20} className={styles.avatar} />
            <span
              dangerouslySetInnerHTML={{
                __html: highlight(item.identity.name, query),
              }}
            />
          </div>
          {item && (
            <span
              className={styles.matchedNumber}
              dangerouslySetInnerHTML={{
                __html: getValue(item),
              }}
            />
          )}
        </div>
      ))}
    </>
  )
})

const ParticipantPhoneNumberList: React.FC<{
  identity: Identity
  items: IdentityPhone[]
  onSelect: (phoneNumber: string) => void
}> = observer(({ identity, onSelect, items }) => {
  const styles = useStyles({})

  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    defaultSelectedIndex: 0,
    name: 'add-participant/input/phone-numbers',
    handleSelect: (item) => {
      onSelect(toE164(item.number))
    },
  })

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '7px 15px 13px' }}>
        <AppAvatar identity={identity} size={20} className={styles.avatar} />
        <span>{identity.name}</span>
      </div>
      {items.map((item, index) => (
        <div
          {...getItemProps(index)}
          key={item.number}
          className={cx(styles.row, selectedIndex === index && styles.selected)}
        >
          <div className={styles.name}>{formatted(item.number)}</div>
          {item.name && <span>{item.name}</span>}
        </div>
      ))}
    </div>
  )
})

export default observer(ParticipantInput, { forwardRef: true })

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 0,
    display: 'flex',
    margin: '1px 0',
    alignItems: 'center',
  },
  input: {
    outline: 'none',
    border: 'none',
    background: 'none',
    color: theme.palette.text.primary,
    fontSize: 14,
    padding: '4px 10px',
    boxSizing: 'content-box',
    margin: '1px 0',

    '&::placeholder': {
      fontSize: 14,
      color: alpha(theme.palette.text.secondary, 0.6),
    },
  },
  suggestions: {
    marginTop: 10,
    width: 330,
    fontSize: 13,
    background: theme.palette.op.background.popover,
    padding: '5px 0',
    borderRadius: 4,
    boxShadow: theme.shadows[10],
    border: `1.5px solid ${theme.palette.op.border.common}`,
    zIndex: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',

    '& em': {
      color: theme.palette.op.match({
        dark: theme.palette.op.primary[3],
        light: theme.palette.op.primary[1],
      }),
      fontStyle: 'normal',
    },
  },
  selected: {
    background: theme.palette.op.background.highlight(0.07),
  },
  name: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatar: {
    marginRight: 15,
  },
  matchedNumber: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  shadow: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: 14,
    whiteSpace: 'pre',
  },
}))
