// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import TextField from '../../../component/textfield-v2'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { Header } from '../../settings/common'
import { Command, Footer } from '../common-v2'
import ContactEvents from './ContactEvents'
import CallsAndMessagesEvents from './CallsAndMessagesEvents'
import { ScrollView } from '@src/component/scrollview'
import usePreventKeyboardShortcut from '@src/lib/usePreventKeyboardShortcut'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useTrackableState from '@src/lib/useTrackableState'
import { ContactEventTypes, CallsAndMessagesEventTypes, EventTypes } from '@src/data'
import cx from 'classnames'

interface CreateWebhookCommandProps {}

export const getTooltipErrorMsg = ({
  contactEventTypes,
  phoneNumbers,
  arePhoneNumbersPristine,
  contactsAndGroups,
  areContactsPristine,
  callsAndMessagesEventTypes,
}: {
  contactEventTypes: string[]
  phoneNumbers: string[]
  arePhoneNumbersPristine: boolean
  contactsAndGroups: string[]
  areContactsPristine: boolean
  callsAndMessagesEventTypes: string[]
}) => {
  if (contactEventTypes.length !== 0 && contactsAndGroups.length === 0) {
    return 'Select users and groups for contacts...'
  }

  if (
    contactsAndGroups.length !== 0 &&
    contactEventTypes.length === 0 &&
    !areContactsPristine
  ) {
    return 'Select events for contacts...'
  }

  if (callsAndMessagesEventTypes.length !== 0 && phoneNumbers.length === 0) {
    return 'Select phone numbers for calls & messages...'
  }

  if (
    phoneNumbers.length !== 0 &&
    callsAndMessagesEventTypes.length === 0 &&
    !arePhoneNumbersPristine
  ) {
    return 'Select events for calls & messages...'
  }

  if (callsAndMessagesEventTypes.length === 0 && contactEventTypes.length === 0) {
    return 'Select events for the webhook...'
  }

  return null
}

const schema = yup
  .object({
    url: yup.string().required('The URL is required').url('Please enter a valid URL'),
  })
  .required()

const CreateWebhookCommand = (props: CreateWebhookCommandProps) => {
  const styles = useStyles()
  const { service, toast, command, workspace } = useAppStore()
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      url: '',
      label: '',
    },
  })
  const [callsAndMessagesEvents, setCallsAndMessagesEvents] = useState<EventTypes[]>([])
  const [contactEvents, setContactEvents] = useState<EventTypes[]>([])

  const [phoneNumbers, setPhoneNumbers, arePhoneNumbersPristine] = useTrackableState(
    service.phoneNumber.collection.list.map((phoneNumber) => phoneNumber.id),
  )

  const [contactsAndGroups, setContactsAndGroups, areContactsPristine] =
    useTrackableState([
      ...service.member.collection.list.map((member) => member.id),
      ...workspace.groups.map((group) => group.id),
    ])

  const [showSubmitErrorTooltip, setShowSubmitErrorTooltip] = useState(false)

  const rootRef = usePreventKeyboardShortcut<HTMLDivElement>()

  const handleCreateWebhook = handleSubmit(() => {
    const triggers = []
    if (callsAndMessagesEvents.length !== 0) {
      triggers.push({
        category: 'activities',
        events:
          CallsAndMessagesEventTypes.length === callsAndMessagesEvents.length
            ? ['*']
            : callsAndMessagesEvents,
        resourceIds:
          service.phoneNumber.collection.list.length === phoneNumbers.length
            ? ['*']
            : phoneNumbers,
      })
    }

    if (contactEvents.length !== 0) {
      triggers.push({
        category: 'contacts',
        events: ContactEventTypes.length === contactEvents.length ? ['*'] : contactEvents,
        resourceIds:
          workspace.groups.length + service.member.collection.list.length ===
          contactsAndGroups.length
            ? ['*']
            : contactsAndGroups,
      })
    }
    workspace.createWebhook({
      label: getValues('label'),
      url: getValues('url'),
      triggers,
      enabled: true,
    })
    toast.show({ message: 'The webhook has been created!' })
    command.hide()
  })

  const tooltipMsg = getTooltipErrorMsg({
    callsAndMessagesEventTypes: callsAndMessagesEvents,
    contactEventTypes: contactEvents,
    areContactsPristine,
    phoneNumbers,
    arePhoneNumbersPristine,
    contactsAndGroups,
  })

  const isDisabled = !!tooltipMsg || Object.entries(errors).length !== 0

  const SubmitButton = () => {
    if (isDisabled) {
      return (
        <Tooltip
          open={showSubmitErrorTooltip}
          onClose={() => setShowSubmitErrorTooltip(false)}
          onOpen={() => setShowSubmitErrorTooltip(true)}
          title={tooltipMsg}
        >
          <span>
            <Button disabled={true}>Create webhook</Button>
          </span>
        </Tooltip>
      )
    }
    return <Button onClick={handleCreateWebhook}>Create webhook</Button>
  }

  return (
    <Command width={672} height="large" ref={rootRef}>
      <ScrollView>
        <div className={styles.root}>
          <Header
            title="Create a webhook"
            subtitle="Set up your webhook endpoint to receive live events from your workspace"
          />
          <div className={styles.content}>
            <TextField
              label="URL"
              placeholder="Enter a valid URL..."
              autoFocus
              size={35}
              errorText={errors.url?.message}
              rootClassName={styles.textFieldLabel}
              {...register('url')}
            />
            <TextField
              label={{ text: 'Label', optional: true }}
              placeholder="Enter a label for this webhook..."
              rootClassName={cx(styles.labelField, styles.textFieldLabel)}
              size={35}
              {...register('label')}
            />
            <div className={styles.eventTypesRoot}>
              <Typography
                className={styles.eventTypesHeadline}
                variant="caption1"
                color="textSecondary"
              >
                Event types
              </Typography>
              <CallsAndMessagesEvents
                callsAndMessagesEventTypes={callsAndMessagesEvents}
                setCallsAndMessagesEventTypes={setCallsAndMessagesEvents}
                phoneNumbers={phoneNumbers}
                setPhoneNumbers={setPhoneNumbers}
              />
              <ContactEvents
                contactsAndGroups={contactsAndGroups}
                setContactsAndGroups={setContactsAndGroups}
                contactEventTypes={contactEvents}
                setContactEventTypes={setContactEvents}
              />
            </div>
          </div>
        </div>
      </ScrollView>
      <Footer>
        <div className={styles.footer}>
          <SubmitButton />
        </div>
      </Footer>
    </Command>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 24,
    },
    content: {
      paddingTop: 16,
    },
    labelField: {
      marginTop: 20,
    },
    textFieldLabel: {
      '& > label': {
        fontSize: 14,
        color: theme.palette.op.gray[1],
        paddingLeft: 0,
        '& span': {
          fontSize: 14,
        },
      },
    },
    eventTypesRoot: {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    eventTypesHeadline: {
      marginBottom: 24,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
  { name: CreateWebhookCommand.name },
)

export default CreateWebhookCommand
