import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export interface VisuallyHiddenProps {
  children: React.ReactNode
}

export default function VisuallyHidden({ children }: VisuallyHiddenProps) {
  const styles = useStyles()

  return <div className={styles.visuallyHidden}>{children}</div>
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    color: '#000',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
