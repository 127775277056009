// @ts-strict-ignore
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useAppStore } from '@src/app/context'
import {
  MicrophoneIcon,
  MicMuteIcon,
  NumberpadIcon,
  TransferIcon,
  SettingsIcon,
} from '../../../component/icons/Tint/20/General'
import {
  CallEndIcon,
  RecordCentreActiveIcon,
  RecordCentreDefaultIcon,
} from '../../../component/icons/Tint/20/Other'
import IconButton from '../../../component/icon-button'
import Timer from '../../../component/timer'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { useReaction } from '../../../lib/hooks'
import log from '../../../lib/log'
import { colors } from '../../../theme'
import AudioSettingsModal from '../audio-settings'
import CallHeader from './header'
import { Call } from '../store'
import KeypadPopover from './keypad'
import CallTransfer from './transfer'
import Collapse from '@ui/Collapse'
import { AppAvatar } from '@src/app/components'

export type OngoingCallMode = 'transfer' | 'settings' | 'keypad'

interface OngoingCallProps {
  call: Call
  rootRef: React.MutableRefObject<HTMLDivElement>
}

const OngoingCall: React.FC<OngoingCallProps> = function ({ call, rootRef }) {
  const theme = useTheme()
  const styles = useStyles({})
  const { electron, command, service, showAlert } = useAppStore()
  const subscription = service.billing.subscription
  const [mode, setMode] = useState<OngoingCallMode>(null)
  const callFromId = call.connection.customParameters.get('callFromId')
  const number = call.participants[0]
  const participant =
    callFromId && service.member.collection.list.some((m) => m.id === callFromId)
      ? service.member.collection.list.filter((m) => m.id === callFromId)[0]
      : service.participant.getOrCreate(call.participants[0])
  const keypadVisible = mode === 'keypad'
  const settingsVisible = mode === 'settings'

  useReaction(
    () => call.error,
    (error) => {
      log.error('Error connecting call', error)

      if (error.code === 31003 || error.code === 31000) {
        showAlert({
          title: 'Connection Failed',
          body: `OpenPhone was unable to establish a media connection. Check your device's network connection and try again. If the problem persists after a minute or two, contact support@openphone.co.`,
        })
      } else if (error.code === 31201 || error.code === 31208) {
        showAlert({
          title: 'Microphone Access',
          body: 'OpenPhone needs microphone access to start a call.',
          actions: [
            {
              title: 'Grant Microphone Access',
              type: 'primary',
              onClick: () => {
                // TODO: build a nicer workflow for giving mic access
                electron?.app.askForMediaAccess('microphone')
              },
            },
            {
              title: 'Cancel',
              type: 'secondary',
            },
          ],
        })
      } else {
        showAlert({ title: 'Error Connecting', body: error.message })
      }
    },
    {},
    [call],
  )

  const handleDisconnect = () => {
    call.disconnect()
  }

  const toggleMute = () => {
    call.toggleMute()
  }

  const handleKeypadOpen = () => {
    if (keypadVisible) {
      setMode(null)
    } else {
      setMode('keypad')
    }
  }

  const handleSettingsOpen = () => {
    if (settingsVisible) {
      setMode(null)
    } else {
      setMode('settings')
    }
  }

  const handleDTMF = (digit: string) => {
    call.sendDigits(digit)
  }

  const handleRecording = () => {
    call.toggleRecord()
  }

  const handleCallTransfer = () => {
    if (!subscription.isPremium) {
      return command.present({ name: 'requires premium' })
    }
    if (call.direction === 'outgoing') {
      return showAlert({
        title: 'Unavailable on outgoing calls...',
        body: 'Call transfer is only available on incoming calls.',
      })
    }
    setMode('transfer')
  }

  const handleBack = () => {
    call.transferController.handleBack() // TODO
    setMode(null)
  }

  return (
    <div>
      <CallHeader mode={mode} call={call} handleBack={handleBack} />
      <div>
        {mode === 'transfer' ? (
          <CallTransfer call={call} onBack={handleBack} />
        ) : (
          <div style={{ padding: 20 }}>
            <div className={styles.name}>
              <AppAvatar identity={participant} size={40} />
              <div style={{ marginLeft: 12 }}>
                <Typography variant="callout">{participant.name}</Typography>
                <Typography
                  variant="caption1"
                  color="textSecondary"
                  fontWeight="regular"
                  className={styles.timer}
                >
                  <Timer startTimestamp={call.startedAt} />
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 18,
                marginBottom: -2,
              }}
            >
              <IconButton
                icon={call.isMuted ? <MicMuteIcon /> : <MicrophoneIcon />}
                title={call.isMuted ? 'Unmute' : 'Mute'}
                color={call.isMuted ? theme.palette.op.white : null}
                backgroundColor={
                  call.isMuted
                    ? theme.palette.op.secondary.red1
                    : theme.palette.op.hover.primary
                }
                size={36}
                rounded
                tooltipPlacement="top"
                onClick={toggleMute}
              />
              <IconButton
                icon={
                  call.isRecording ? (
                    <RecordCentreActiveIcon />
                  ) : (
                    <RecordCentreDefaultIcon />
                  )
                }
                title={call.isRecording ? 'Stop Recording' : 'Start Recording'}
                color={call.isRecording ? theme.palette.op.white : null}
                backgroundColor={
                  call.isRecording
                    ? theme.palette.op.secondary.red1
                    : theme.palette.op.hover.primary
                }
                size={36}
                rounded
                tooltipPlacement="top"
                onClick={handleRecording}
              />
              <IconButton
                icon={<TransferIcon />}
                title="Transfer Call"
                backgroundColor={theme.palette.op.hover.primary}
                size={36}
                rounded
                tooltipPlacement="top"
                onClick={handleCallTransfer}
              />
              <IconButton
                icon={<NumberpadIcon />}
                title={keypadVisible ? 'Hide Keypad' : 'Show Keypad'}
                color={keypadVisible ? theme.palette.op.white : null}
                backgroundColor={
                  keypadVisible
                    ? theme.palette.op.primary[1]
                    : theme.palette.op.hover.primary
                }
                size={36}
                rounded
                tooltipPlacement="top"
                onClick={handleKeypadOpen}
              />
              <IconButton
                icon={<SettingsIcon />}
                title={settingsVisible ? 'Hide Audio Settings' : 'Show Audio Settings'}
                color={keypadVisible ? theme.palette.op.white : null}
                backgroundColor={
                  settingsVisible
                    ? theme.palette.op.primary[1]
                    : theme.palette.op.hover.primary
                }
                size={36}
                rounded
                tooltipPlacement="top"
                onClick={handleSettingsOpen}
              />
              <IconButton
                icon={<CallEndIcon />}
                title="End Call"
                color={theme.palette.op.white}
                backgroundColor={theme.palette.op.secondary.red1}
                size={40}
                rounded
                tooltipPlacement="top"
                onClick={handleDisconnect}
              />
            </div>
            <Collapse collapsed={!keypadVisible}>
              <KeypadPopover rootRef={rootRef} onKeyPressed={handleDTMF} />
            </Collapse>
            <Collapse collapsed={!settingsVisible}>
              <AudioSettingsModal
                style={{ width: 'auto', padding: 0 }}
                allowTesting={false}
                className={styles.settings}
              />
            </Collapse>
            <Collapse collapsed={call.warnings.size === 0}>
              <Tooltip title={call.friendlyWarnings}>
                <div className={styles.warning}>⚠ Call quality issues detected</div>
              </Tooltip>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(OngoingCall)

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  timer: {
    marginTop: 3,
  },
  drag: {
    cursor: 'grab',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.op.background.highlight(0.3),
  },
  actionButton: {
    marginLeft: 10,
    background: theme.palette.op.background.highlight(0.2),
    color: 'white',
    width: 36,
    height: 36,

    '&:last-child': {
      marginLeft: 0,
    },
  },
  active: {
    background: theme.palette.op.legacy.ongoingCall.active,
  },
  record: {
    color: theme.palette.op.legacy.ongoingCall.record,
  },
  activeBlue: {
    background: 'white',
    color: colors.blue,
  },
  settings: {
    padding: 0,
    marginTop: 20,
    width: 250,
    background: 'none',
  },
  '@keyframes slide': {
    from: {
      transform: 'translateX(30px)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  incomingActions: {},
  acceptButton: {},
  hangUp: {
    width: 46,
    height: 46,
    marginLeft: 15,
  },
  warning: {
    cursor: 'pointer',
    fontSize: 12,
    padding: '6px',
    textAlign: 'center',
    color: theme.palette.op.note.bg,
    background: theme.palette.op.secondary.yellow,
  },
}))
