import { DependencyList } from 'react'
import { useAsyncEffect } from '../use-async-effect'

export function useAsyncAction<T>(
  action: () => Promise<T>,
  callback: (value: T) => void,
  dependencies: DependencyList,
) {
  useAsyncEffect(async (signal) => {
    const value = await action()
    if (signal.aborted) return
    callback(value)
  }, dependencies)
}
