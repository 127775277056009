import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import PhoneNumberSelector from '../../../../component/phone-number-selector'
import useKeyboardShortcuts from '../../../../lib/use-keyboard-shortcuts'
import { Call } from '../../store'
import ConfirmTransfer from './confirm'

export interface CallTransferProps {
  call: Call
  onBack(): void
}

const CallTransfer: React.FC<CallTransferProps> = function ({ call, onBack }) {
  const styles = useStyles({})
  const controller = call.transferController

  useKeyboardShortcuts({
    name: 'CallTransfer',
    node: controller.searchFocused ? controller.searchRef.current : document,
    filter: (_, e) => !e.defaultPrevented,
    handler: (shortcut, event) => {
      if (shortcut === 'Escape') {
        if (controller.confirmDialog.isOpen) {
          controller.confirmDialog.close()
        } else {
          onBack()
        }

        event.preventDefault()
      }
    },
    dep: [controller],
  })

  return (
    <>
      <ConfirmTransfer controller={controller.confirmDialog} />
      <div className={styles.root}>
        <PhoneNumberSelector controller={controller} />
      </div>
    </>
  )
}

export default observer(CallTransfer)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      height: 410,
      padding: 6,
    },
  }),
  { name: CallTransfer.name },
)
