import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useAppStore } from '@src/app/context'
import Account from './account'
import { BillingRoute } from './billing'
import Blocklist from './blocklist'
import Company from './company'
import Contacts from './contacts'
import { SettingsGroups } from './groups'
import Integrations from './integrations'
import Members from './members'
import NotificationSettings from './notifications'
import PhoneNumbers from './phone-numbers'
import Referrals from './referrals'
import SideMenu from './side-menu'
import Preferences from './preferences'
import { WebhooksRoute } from './webhooks'
import { Redirect } from '@src/app/router'

const SettingsRoute = () => {
  const styles = useStyles()
  const { service, toast } = useAppStore()
  const { url } = useRouteMatch()

  useEffect(() => {
    service.organization.phoneNumber.fetch().catch(toast.showError)
  }, [])

  return (
    <div className={styles.root}>
      <SideMenu />
      <Switch>
        <Route path={`${url}/profile`} component={Account} />
        <Route path={`${url}/preferences`} component={Preferences} />
        <Route path={`${url}/contacts`} component={Contacts} />
        <Route path={`${url}/blocklist`} component={Blocklist} />
        <Route path={`${url}/company`} component={Company} />
        <Route path={`${url}/members`} component={Members} />
        <Route path={`${url}/groups`} component={SettingsGroups} />
        <Route path={`${url}/referrals`} component={Referrals} />
        <Route path={`${url}/phone-numbers`} component={PhoneNumbers} />
        <Route path={`${url}/notifications`} component={NotificationSettings} />
        <Route path={`${url}/integrations`} component={Integrations} />
        <Route path={`${url}/billing`} component={BillingRoute} />
        <Route path={`${url}/webhooks`} component={WebhooksRoute} />
        <Redirect to={`${url}/profile`} />
      </Switch>
    </div>
  )
}

export default observer(SettingsRoute)

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      boxSizing: 'border-box',
      borderRadius: '8px 0 0 0',
      position: 'relative',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
  }),
  { name: SettingsRoute.name },
)
