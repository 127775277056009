import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
import React from 'react'

interface NoDataMessageProps {
  icon: React.ReactNode
  title: string
  description: string
  actions?: React.ReactNode
}

const NoDataMessage: React.FC<NoDataMessageProps> = ({
  icon,
  title,
  description,
  actions,
}) => {
  const styles = useStyles({})

  return (
    <div className={styles.root}>
      <span className={styles.icon}>{icon}</span>
      <Typography variant="title3" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body" color="textSecondary" className={styles.description}>
        {description}
      </Typography>
      {actions}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 440,
  },
  icon: {
    fontSize: 50,
    marginBottom: 8,
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 24,
  },
}))

export default NoDataMessage
