import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { IntegrationContact, isIntegrationContact, Member } from '../../service/model'
import { ContactItemType } from '../../types'
import ReadonlyItem from './item/readonly-item'

interface ReadonlyItemsProps {
  identity: Member | IntegrationContact
}

interface ItemType {
  type: ContactItemType
  name: string
  value: string
}

const ReadonlyItems: React.FC<ReadonlyItemsProps> = function ({ identity }) {
  const styles = useStyles({})
  const items: ItemType[] = useMemo(() => {
    const items = [
      ...identity.phones.map(
        (p): ItemType => ({ type: 'phone-number', name: 'Phone', value: p.number }),
      ),
      ...identity.emailAddresses.map(
        (value): ItemType => ({ type: 'email', name: 'Phone', value }),
      ),
    ]
    if (isIntegrationContact(identity)) {
      items.push({
        type: 'url',
        name: 'HubSpot',
        value: identity.url,
      })
    }
    return items
  }, [identity.phoneNumbers, identity.emailAddresses])

  return (
    <div className={styles.root}>
      {items.map((item) => (
        <ReadonlyItem key={item.value} {...item} />
      ))}
    </div>
  )
}

export default observer(ReadonlyItems)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 14px 0',
  },
}))
