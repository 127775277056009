import React, { useState } from 'react'
import { unique } from '../../../lib/collections'
import { Command } from '../common-v2'
import Header from '../common/header'
import InviteContactUserCommand from './invite'
import ListSharedUsers from './list'

export interface SharedWithCommandProps {
  ids: string[]
  title: string
  subtitle: string
  onSave: (ids: string[]) => void
}

const SharedWithCommand: React.FC<SharedWithCommandProps> = function ({
  ids = [],
  title,
  subtitle,
  onSave,
}) {
  const [invite, setInvite] = useState(false)
  const [sharedIds, setSharedIds] = useState<string[]>(ids)

  const handleInvite = () => {
    setInvite(true)
  }

  const handleChange = (ids: string[]) => {
    setSharedIds(ids)
  }

  const handleInviteSave = (ids: string[]) => {
    onSave(unique([...sharedIds, ...ids]))
  }

  const handleSave = () => {
    onSave(sharedIds)
  }

  return (
    <Command>
      <Header title={title} subtitle={subtitle} />
      {invite ? (
        <InviteContactUserCommand onSave={handleInviteSave} />
      ) : (
        <ListSharedUsers
          ids={ids}
          onInvite={handleInvite}
          onChange={handleChange}
          onClose={handleSave}
        />
      )}
    </Command>
  )
}

export default SharedWithCommand
