import config from '@src/config'

interface AnalyticsData {
  orgId: string | undefined
  orgAutoCharge: boolean
  orgPlan: string
  orgIndustry: string | undefined
  orgSubIndustry: string | undefined
  orgSize: string | undefined
  email: string | undefined
  name: string
  selfIndustry: string | undefined
  role: string | undefined
  subRole: string | undefined
}

class Analytics {
  private userId?: string
  private segmentIo: any
  private analytics?: AnalyticsData

  constructor() {
    window.dataLayer ??= []
    this.segmentIo = (window as any).analytics
  }

  identify(userId: string, params: AnalyticsData) {
    this.userId = userId
    this.analytics = params
    this.segmentIo?.identify(userId, {
      ...params,
    })
    window.dataLayer.push({
      userId,
      orgId: params.orgId,
    })
  }

  page(path: string) {
    this.googlePageView(path)
    this.segmentIo?.page()
  }

  addedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_added',
    })
  }

  updatedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_updated',
    })
  }

  invited() {
    window.dataLayer.push({
      event: 'invite',
    })
  }

  cancellation() {
    window.dataLayer.push({
      event: 'cancellation',
    })
  }

  opened() {
    this.segmentIo?.track('App Opened')
  }

  referred() {
    this.segmentIo?.track('Contact Referred')
  }

  reset() {
    this.segmentIo?.reset()
  }

  private googlePageView(path: string) {
    window.dataLayer.push({
      event: 'pageview',
      page: path,
      title: window.document.title,
      environment: config.ENV,
      ...this.analytics,
    })
  }

  login(method: 'password' | 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'login',
      method,
    })
  }

  signup(method: 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'signup',
      method,
    })
  }

  /**
   * Pushes to the data layer click events
   * @param analyticsId - Describes the goal of the clickable element
   * @param redirect - Full URL (if external) or path (if internal) to which the clickable element redirects the user
   */
  click(analyticsId: string, redirect?: URL | string) {
    const url: URL | undefined =
      typeof redirect === 'string' ? new URL(redirect, window.location.origin) : redirect
    window.dataLayer.push({
      event: 'click',
      analyticsId,
      url: url?.toString(),
    })
  }
}

const analytics = new Analytics()

export default analytics
