import { useCallback, useState } from 'react'

export default function useTemporaryState<T>(
  value: T,
  timeout = 1500,
): [T | undefined, () => void] {
  const [state, setState] = useState<T | undefined>(undefined)

  const trigger = useCallback(() => {
    setState(value)
    setTimeout(() => {
      setState(undefined)
    }, timeout)
  }, [value])

  return [state, trigger]
}
