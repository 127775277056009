// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { nanoid } from 'nanoid'
import React, { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react'
import useCombinedRefs from '../lib/use-combined-ref'
import TextInput, { TextInputProps } from '@ui/TextInput'
import Typography from '@ui/Typography'

interface TextFieldProps extends TextInputProps {
  label?: string | { text: string; optional?: boolean }
  rootClassName?: string
  rootStyle?: React.CSSProperties
  helperText?: string
  errorText?: string
  endAdornment?: React.ElementType
  startAdornment?: React.ElementType
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      rootClassName,
      rootStyle,
      label,
      fullWidth,
      id,
      helperText,
      errorText,
      endAdornment: EndAdornment,
      startAdornment: StartAdornment,
      ...props
    },
    outerRef,
  ) => {
    const styles = useStyles({})
    const elementId = useMemo(() => (id ? id : nanoid()), [id])
    const endAdornmentRef = useRef<HTMLDivElement>()
    const [endAdornmentWidth, setEndAdornmentWidth] = useState(null)
    const startAdornmentRef = useRef<HTMLDivElement>()
    const [startAdornmentWidth, setStartAdornmentWidth] = useState(null)

    // TODO: fix it somehow
    const ref = useCombinedRefs<HTMLInputElement>(outerRef)

    useLayoutEffect(() => {
      if (ref?.current && endAdornmentRef?.current) {
        setEndAdornmentWidth(endAdornmentRef.current.clientWidth)
      }

      if (ref?.current && startAdornmentRef?.current) {
        setStartAdornmentWidth(startAdornmentRef.current.clientWidth)
      }
    }, [])

    return (
      <div style={rootStyle} className={cx(styles.root, rootClassName)}>
        {label && (
          <Typography
            variant="caption1"
            color="textSecondary"
            fontWeight="regular"
            component="label"
            htmlFor={elementId}
            className={cx(styles.label, fullWidth && styles.fullWidth)}
          >
            {typeof label === 'string' ? (
              label
            ) : (
              <>
                {label.text}
                {label.optional ? (
                  <Typography variant="caption1" color="placeholder" component="span">
                    &nbsp;(optional)
                  </Typography>
                ) : null}
              </>
            )}
          </Typography>
        )}
        {StartAdornment && (
          <div
            className={cx(styles.adornment, styles.startAdornment)}
            ref={startAdornmentRef}
          >
            <StartAdornment />
          </div>
        )}
        <TextInput
          id={elementId}
          fullWidth={fullWidth}
          style={{
            ...(endAdornmentWidth && { paddingRight: endAdornmentWidth }),
            ...(startAdornmentWidth && { paddingLeft: startAdornmentWidth }),
          }}
          ref={ref}
          {...props}
        />
        {EndAdornment && (
          <div
            className={cx(styles.adornment, styles.endAdornment)}
            ref={endAdornmentRef}
          >
            <EndAdornment />
          </div>
        )}
        {(errorText || helperText) && (
          <Typography
            variant="footnote"
            fontWeight="regular"
            component="span"
            className={cx(
              errorText ? styles.error : styles.helperText,
              fullWidth && styles.fullWidth,
            )}
          >
            {errorText || helperText}
          </Typography>
        )}
      </div>
    )
  },
)

export default TextField

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  label: {
    padding: '0 0 10px 3px',
  },
  adornment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    boxSizing: 'content-box',
  },
  endAdornment: {
    right: 0,
    padding: '0px 16px 0px 10px',
  },
  startAdornment: {
    left: 0,
    padding: '0px 10px 0px 16px',
  },
  fullWidth: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.op.gray[3],
    padding: '10px 0 0 3px',
  },
  error: {
    color: theme.palette.op.secondary.red2,
    padding: '10px 0 0 3px',
  },
}))
