// @ts-strict-ignore
import { action, computed, makeObservable, observable, override } from 'mobx'
import { EmojiPickerProps } from '../../../component/emoji-picker'
import {
  CallItemPart,
  Controller,
  FeedItemIconType,
  Item,
  ItemDisplay,
  ItemStatus,
} from '../../../component/feed'
import { friendlyTime, toHHMMSS } from '../../../lib/date'
import { Activity, MessageMedia } from '../../../service/model'
import { canHandleMedia } from '../../media-viewer'
import AppStore from '../../store'
export class FeedItemDisplay extends ItemDisplay {
  constructor(protected app: AppStore, item: FeedItem) {
    super(item)
    makeObservable(this, {
      highlighted: override,
    })
  }

  override get highlighted() {
    return this.app.conversation.anchorId == this.item.id
  }
}

export class FeedItem extends Item {
  readonly isInternal = false
  readonly source = this.activity
  readonly thread = new Thread(this.app, this)

  constructor(protected app: AppStore, readonly activity: Activity) {
    super()
    makeObservable(this, {
      display: computed,
      currentUser: computed,
      id: computed,
      isOutgoing: computed,
      senderId: computed,
      sender: computed,
      member: computed,
      status: computed,
      hasThread: override,
      voicemailActivity: computed,
      handleThreadOpen: action.bound,
      handleReaction: action.bound,
      handleRetrySend: action.bound,
      handleCopy: action.bound,
      handleDelete: action.bound,
      handleDeleteCallRecording: action.bound,
      handleSenderSelected: action.bound,
    })
  }

  get display() {
    return new FeedItemDisplay(this.app, this)
  }

  get currentUser() {
    return this.app.service.user.current
  }

  override get id() {
    return this.activity.id
  }

  get isOutgoing() {
    return this.activity.isOutgoing
  }

  get senderId() {
    return this.activity.isOutgoing
      ? this.activity.createdBy || this.activity.answeredBy
      : this.activity.from
  }

  get sender() {
    const sender = this.isOutgoing
      ? this.app.service.member.collection.get(this.senderId)
      : this.activity.conversation.participants.find(
          (p) => p.phoneNumber === this.senderId,
        )

    return sender ?? null
  }

  get member() {
    const id = this.activity.createdBy || this.activity.answeredBy
    return this.app.service.member.collection.get(id)
  }

  get status(): ItemStatus | null {
    const secondaryMessage = this.activity.error?.secondaryMessage
    if (this.activity.status === 'failed') {
      return {
        type: 'error',
        text: 'Failed',
        details: secondaryMessage || 'Message failed to send',
        canRetry: true,
      }
    } else if (this.activity.status === 'undelivered') {
      return {
        type: 'error',
        text: 'Undelivered',
        details: secondaryMessage || 'Destination not found',
      }
    }

    // Don't report any informational status unless this is the last activity
    if (this.next) return null

    const uploads = this.activity.media.filter((m) => !m.url)
    const progress =
      uploads.reduce((final, m) => final + m.uploadProgress, 0) / uploads.length

    if (uploads.length > 0) {
      return { type: 'info', text: `Uploading: ${Math.ceil(progress * 100)}%` }
    } else if (this.activity.status === 'queued') {
      return { type: 'info', text: 'Sending...' }
    }

    return { type: 'info', text: friendlyTime(this.activity.createdAt) }
  }

  override get hasThread() {
    return this.thread.exists
  }

  get voicemailActivity() {
    return this.app.conversation.voicemailActivities[this.activity.sid]
  }

  handleThreadOpen() {
    this.thread.open = true
    this.thread.controller.expand()
    setTimeout(() => this.thread.controller.inputBarController.editor.focus(), 0)
  }

  handleReaction(body: string) {
    if (!this.activity.getReaction(body)) {
      this.app.sound.play('reaction')
    }
    this.activity.toggleReaction(body).catch(this.app.toast.showError)
  }

  handleRetrySend() {
    this.activity.send()
  }

  handleCopy() {
    if (this.copyableText) {
      navigator.clipboard.writeText(this.copyableText)
      this.app.toast.show({ message: 'Copied!' })
    }
  }

  handleDelete() {
    this.activity.delete()
  }

  handleDeleteCallRecording(media: MessageMedia) {
    this.app.showAlert({
      title: 'Delete recording',
      body: 'Deleting this call recording will remove it for everyone in your workspace, and cannot be undone. Are you sure you want to proceed?',
      actions: [
        {
          title: 'Delete recording',
          type: 'destructive',
          onClick: () => {
            this.activity.deleteRecordings([media.id])
          },
        },
        {
          title: 'Cancel',
        },
      ],
    })
  }

  handleSenderSelected() {
    this.app.inboxes.selected.setDetailsById(this.senderId)
  }

  protected createCallParts(): CallItemPart[] {
    const parts: CallItemPart[] = []

    if (this.activity.type === 'call') {
      const duration =
        typeof this.activity.duration === 'number'
          ? ` · ${toHHMMSS(this.activity.duration)}`
          : ''

      let iconType: FeedItemIconType,
        title: string,
        subtitle: string,
        subj: string,
        object: string
      if (!this.member || this.memberIsMe) {
        subj = 'You'
        object = 'you'
      } else {
        subj = this.member.shortName
        object = subj
      }

      if (this.activity.status === 'failed') {
        iconType = 'call-missed'
        title = 'Call failed'
      } else if (this.activity.status === 'ringing') {
        iconType = 'call-default'
        title = this.isOutgoing ? 'Call started...' : 'Incoming call...'
        subtitle = 'Not answered yet'
      } else if (this.activity.status === 'in-progress') {
        iconType = 'call-default'
        title = 'Call in progress...'
        const subtitleSuffix = this.isOutgoing ? 'called' : 'answered'
        subtitle =
          (this.memberIsMe ? `You ${subtitleSuffix}` : `${subj} ${subtitleSuffix}`) +
          duration
      } else if (!this.activity.answeredAt) {
        title = 'Missed call'
        iconType = 'call-missed'
        subtitle = !this.isOutgoing
          ? 'No one answered'
          : this.memberIsMe
          ? 'You called'
          : `${subj} called`
      } else {
        title = 'Call ended'
        subtitle =
          (this.isOutgoing ? `${subj} called` : `Answered by ${object}`) + duration
        iconType = 'call-answered'
      }

      const transcript =
        this.activity.transcript ?? this.voicemailActivity?.transcript ?? null
      const voicemail = this.voicemailActivity?.media[0]
      const recordings = this.activity.recordings
      parts.push({
        type: this.activity.type,
        iconType,
        title,
        subtitle,
        duration:
          this.activity.status === 'completed' && this.activity.answeredAt
            ? this.activity.duration
            : null,
        transcript,
        recordings,
        voicemail,
      })
    }

    return parts
  }
}

export class Thread {
  /**
   * If `true`, the thread has been opened to send a comment.
   */
  open = false

  constructor(protected app: AppStore, readonly item: FeedItem) {
    makeObservable(this, {
      open: observable.ref,
      controller: computed,
      exists: computed,
    })
  }

  get controller() {
    return this.app.conversation.threadRegistry.get(this.item)
  }

  get exists() {
    return this.open || this.item.activity.comments.length > 0
  }
}

export class ConversationFeedController extends Controller<FeedItem> {
  readonly strategy = 'virtual'

  constructor(protected app: AppStore) {
    super()

    makeObservable(this, {
      features: computed,
      items: computed,
      openMedia: action.bound,
      fetchBefore: action.bound,
      fetchAfter: action.bound,
      loadMore: action.bound,
    })
  }

  get features() {
    return { threads: this.app.inboxes.selected !== this.app.inboxes.dm }
  }

  get items() {
    return this.app.conversation.feed
  }

  openMedia(media: MessageMedia) {
    if (canHandleMedia(media.type, media.url)) {
      this.app.conversation.openMedia(media)
    } else {
      window.open(media.url, '_blank')
    }
  }

  openEmojiPicker(props: Omit<EmojiPickerProps, 'open'>) {
    this.app.showEmojiPicker(props)
  }

  fetchBefore(item: FeedItem) {
    return item.activity.fetchBefore()
  }

  fetchAfter(item: FeedItem) {
    return item.activity.fetchAfter()
  }

  loadMore() {
    this.app.conversation.loadMore()
  }
}
