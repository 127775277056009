// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useAppStore } from '@src/app/context'
import IconButton from '../../../component/icon-button'
import { ClearTextIcon } from '../../../component/icons/Tint/16/General'
import Tooltip from '../../../component/tooltip'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import { Identity, PhoneNumber } from '../../../service/model'
import { InputHeader } from '../common-v2'
import { FromTag, InboxTag } from './tag'

interface SearchInputProps {}

const SearchInput: React.FC<SearchInputProps> = function ({}) {
  const styles = useStyles({})
  const inputRef = useRef<HTMLInputElement>(null)
  const { search } = useAppStore()
  const hasTags = search.query.in.length > 0 || search.query.from.length > 0

  useEffect(() => {
    inputRef.current.select()
  }, [])

  useKeyboardShortcuts({
    node: document,
    name: 'SeachClear',
    handler: (shortcut, event) => {
      if (shortcut === 'Escape' && !search.isEmptyQuery) {
        search.clearSearch()
        event.preventDefault()
      }
    },
  })

  const placeholder = (function () {
    if (search.query.in.length > 0) {
      return search.query.in.length === 1
        ? `Search in ${search.query.in[0].name}...`
        : `Search...`
    }
    return 'What are you looking for?...'
  })()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === 'Tab' || event.key === 'ArrowRight') &&
      inputRef.current.selectionEnd === inputRef.current.selectionStart &&
      inputRef.current.selectionEnd === search.query.term.length
    ) {
      search.autoFillInput()
      event.preventDefault()
    }
    if (
      event.key === 'Backspace' &&
      inputRef.current.selectionStart === 0 &&
      inputRef.current.selectionEnd === 0
    ) {
      if (search.query.from.length > 0) {
        search.removeFrom(search.query.from[search.query.from.length - 1])
      } else {
        search.removeInbox(search.query.in[search.query.in.length - 1])
      }
    }
  }

  const handleChange = (e) => {
    search.setTerm(e.target.value)
    search.clearResults()
  }

  const handleDeleteInbox = (phoneNumber: PhoneNumber) => {
    inputRef.current.focus()
    search.removeInbox(phoneNumber)
  }

  const handleDeleteFrom = (identity: Identity) => {
    inputRef.current.focus()
    search.removeFrom(identity)
  }

  const buildTokenSuggestion = () => {
    if (!search.inputToken) return
    return <span className={styles.token}>{search.inputToken}</span>
  }

  const handleClear = () => {
    inputRef.current.focus()
    search.clearSearch()
  }

  const renderClear = () => {
    if (search.isEmptyQuery) {
      return null
    } else {
      return (
        <IconButton
          tabIndex={-1}
          title="Clear search"
          shortcut="Esc"
          icon={<ClearTextIcon />}
          onClick={handleClear}
        />
      )
    }
  }

  return (
    <InputHeader
      autoFocus
      ref={inputRef}
      placeholder={placeholder}
      value={search.query?.term ?? ''}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      loading={search.loading}
      accessory={renderClear()}
      inputShadow={buildTokenSuggestion()}
    >
      {hasTags && (
        <div className={styles.tags}>
          {search.query.in.map((phoneNumber) => (
            <Tooltip title="Click to delete">
              <InboxTag
                key={phoneNumber.id}
                className={styles.tag}
                phoneNumber={phoneNumber}
                onClick={() => handleDeleteInbox(phoneNumber)}
              />
            </Tooltip>
          ))}
          {search.query.from.map((identity) => (
            <Tooltip title="Click to delete">
              <FromTag
                key={identity.id}
                className={styles.tag}
                identity={identity}
                onClick={() => handleDeleteFrom(identity)}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </InputHeader>
  )
}

export default observer(SearchInput)

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    marginRight: 8,
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
  },
  token: {
    color: theme.palette.op.text.tertiary,
  },
}))
