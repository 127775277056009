import React, { useState } from 'react'
import { makeStyles, Theme, alpha } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import cx from 'classnames'
import { useOnboardingState } from '.'
import Button from '../../component/button-v2'
import Typography from '@ui/Typography'
import SelectToggle from '../../component/select-toggle'
import { Card } from './common'
import { fonts } from '@src/theme'
import features from '@src/data/plans'
import {
  FeatureGroupTitle,
  FeatureTitle,
  FeatureValueItem,
} from '../settings/billing/plans-common'
import Badge from '@ui/Badge'

interface PlansProps {}

const Plans: React.FC<PlansProps> = function ({}) {
  const styles = useStyles({})
  const store = useOnboardingState()
  const [monthly, setMonthly] = useState(false)
  const [comparison, setComparison] = useState(false)
  const priceStandard = 13
  const pricePremium = 25
  const discount = monthly ? 0 : 20
  const handleSelect = (plan: 'standard' | 'premium') => {
    store.setPlanAndAdvance(plan, monthly ? 'monthly' : 'yearly')
  }
  const selectToggleOptions = [
    { value: 'monthly', title: 'Monthly' },
    { value: 'yearly', title: 'Yearly' },
  ]

  return (
    <Card
      emoji="👀"
      title="Choose a plan"
      contentClassName={styles.body}
      description={
        <div>
          <span className={styles.headerDescription}>
            Start with a{' '}
            <Typography component="span" variant="inherit" color="textPrimary">
              7-day free trial
            </Typography>
            {store?.coupon
              ? ` and get ${store.coupon.percent_off}% off for ${store.coupon.duration_in_months} months using the ${store.coupon.name} offer.`
              : `. You can change or cancel your plan at anytime.`}
          </span>
          <div className={styles.intervalSelector}>
            <SelectToggle
              options={selectToggleOptions}
              value={monthly ? 'monthly' : 'yearly'}
              onToggle={(selectedPlan: string) => {
                setMonthly(selectedPlan === 'monthly')
              }}
            />
          </div>
        </div>
      }
    >
      <div className={styles.plansContainer}>
        <Plan
          name="Standard"
          price={priceStandard}
          discountPercent={discount}
          monthly={monthly}
          description="The business phone essentials to get you up and running."
          captionIncludes="All Standard plans include:"
          includes={[
            'Calling and messaging to US and Canadian numbers',
            'Group messaging',
            'Call recording',
            'Basic phone IVR',
          ]}
          onSelect={() => handleSelect('standard')}
        />
        <Plan
          name="Premium"
          price={pricePremium}
          discountPercent={discount}
          monthly={monthly}
          description="Do more with powerful integrations and collaboration features."
          captionIncludes="Everything from Standard, plus:"
          includes={[
            <>
              Group calling <Badge label="Coming soon" variant="warning" />
            </>,
            'Call transfers',
            'Advanced phone IVR',
            'HubSpot integration',
            'Analytics and reporting',
            'Priority support',
          ]}
          onSelect={() => handleSelect('premium')}
        />
      </div>
      {comparison ? (
        <PlansComparison />
      ) : (
        <div className={styles.comparePlansButtonContainer}>
          <Button
            fullWidth
            variant="outlined"
            color="textPrimary"
            onClick={() => setComparison(true)}
          >
            Compare plans
          </Button>
        </div>
      )}
      <span className={styles.disclaimer}>
        {comparison && (
          <span>
            * Subject to{' '}
            <a href="https://www.openphone.co/fair-use" target="_blank">
              Fair Usage Policy
            </a>
            .
          </span>
        )}
        &nbsp;All prices in USD
      </span>
    </Card>
  )
}

export default Plans

interface PlanProps extends React.HTMLProps<HTMLDivElement> {
  name: string
  price: number
  discountPercent?: number
  discountMonths?: number
  monthly: boolean
  description: React.ReactNode
  captionIncludes: string
  includes: Array<React.ReactNode>
  onSelect: () => void
}

const Plan: React.FC<PlanProps> = function ({
  name,
  price,
  discountMonths,
  discountPercent,
  description,
  captionIncludes,
  includes,
  monthly,
  className,
  onSelect,
  ...props
}) {
  const styles = usePlanStyles({})
  const amount = discountPercent ? Math.floor(price * (1 - discountPercent / 100)) : price

  return (
    <div {...props} className={cx(styles.plan, className)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="largeTitle" className={styles.planTitle}>
          {name}
        </Typography>
        {discountPercent ? (
          <Badge
            label={`${discountPercent}% off`}
            variant="success"
            className={styles.badgeDiscount}
          />
        ) : null}
      </div>
      <Typography variant="body" color="textPrimary" className={styles.planDescription}>
        {description}
      </Typography>

      <div className={styles.priceContainer}>
        <span className={styles.currency}>$</span>
        <span className={styles.price}>{amount}</span>
        <div className={styles.priceInterval}>
          <Typography className={styles.priceIntervalText}>Per user/per month</Typography>
          <Typography className={styles.priceIntervalText}>
            Billed {monthly ? 'monthly' : 'annually'}
          </Typography>
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={onSelect}
        className={styles.selectPlanButton}
      >
        Select {name}
      </Button>
      <span className={styles.planIncludesTitle}>{captionIncludes}</span>
      <ul className={styles.planFeatureList}>
        {includes.map((itm, idx) => (
          <li key={idx} className={styles.planFeatureItem}>
            <CheckIcon className={styles.check} />
            <span className={styles.planFeatureItemName}>{itm}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

const usePlanStyles = makeStyles((theme: Theme) => ({
  plan: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 416,
    width: '100%',
    minHeight: '475px',
    padding: '32px',
    borderRadius: 12,
    backgroundColor: theme.palette.op.hover.primary,
  },
  planTitle: {
    marginBottom: 4,
    fontSize: 24,
    fontWeight: 650,
    lineHeight: '36px',
  },
  planDescription: {
    color: theme.palette.op.gray[2],
    fontSize: 17,
    fontWeight: 450,
    lineHeight: '24px',
    height: 48,
    marginBottom: 8,
  },
  priceContainer: {
    display: 'flex',
    height: 68,
  },
  currency: {
    fontWeight: 650,
    fontSize: 17,
    height: 24,
    color: theme.palette.op.gray[1],
    marginRight: 5,
    marginTop: 14,
  },
  price: {
    fontFamily: fonts.header,
    fontWeight: 600,
    fontSize: 60,
    lineHeight: '68px',
    letterSpacing: '-0.5px',
    color: theme.palette.op.gray[1],
  },
  priceInterval: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    marginTop: 14,
  },
  priceIntervalText: {
    fontWeight: 450,
    fontSize: 14,
    height: 18,
    color: theme.palette.op.gray[2],

    '&:not(:first-child)': {
      marginTop: 3,
    },
  },
  selectPlanButton: {
    height: 48,
    width: 'fit-content',
    marginTop: '16px',
    padding: '14px 18px',
    backgroundColor: theme.palette.op.primary[2],
    borderRadius: 8,
    fontWeight: 650,
    fontSize: 15,
    color: theme.palette.op.white,
  },
  planIncludesTitle: {
    marginTop: 24,
    marginBottom: 4,
    fontSize: 17,
    fontWeight: 550,
    lineHeight: '24px',
    color: theme.palette.op.gray[1],
  },
  planFeatureList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  planFeatureItem: {
    display: 'flex',
    marginTop: 8,
  },
  check: {
    color: theme.palette.op.primary[2],
    height: 20,
    width: 20,
    marginTop: 2,
    marginRight: 12,
  },
  planFeatureItemName: {
    fontWeight: 450,
    fontSize: 17,
    color: theme.palette.op.gray[2],
    lineHeight: '24px',
  },
  badgeDiscount: {
    marginLeft: 10,
  },
}))

function PlansComparison() {
  const styles = usePlanComparisonStyles()

  return (
    <div className={styles.planComparison}>
      {features.map((group) => (
        <table key={group.title} className={styles.table}>
          <thead>
            <tr>
              <th scope="col" className={styles.th}>
                <FeatureGroupTitle>{group.title}</FeatureGroupTitle>
              </th>

              <th scope="col" className={styles.td}>
                <FeatureGroupTitle variant="plan-title">Standard</FeatureGroupTitle>
              </th>

              <th scope="col" className={styles.td}>
                <FeatureGroupTitle variant="plan-title">Premium</FeatureGroupTitle>
              </th>
            </tr>
          </thead>

          <tbody>
            {group.features.map(({ title, values, ...feature }) => {
              const { standard, premium } = values
              const isEnterpriseOnly = !standard && !premium && values.enterprise

              return (
                <tr key={title}>
                  <th scope="row" className={styles.th}>
                    <FeatureTitle {...feature}>{title}</FeatureTitle>
                  </th>

                  {isEnterpriseOnly ? (
                    <td colSpan={2}>
                      <div className={styles.enterprisePlanOnlyItem}>
                        Enterprise plan only
                      </div>
                    </td>
                  ) : (
                    <>
                      <td className={styles.td}>
                        <FeatureValueItem value={standard} />
                      </td>

                      <td className={styles.td}>
                        <FeatureValueItem value={premium} />
                      </td>
                    </>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      ))}
    </div>
  )
}

const usePlanComparisonStyles = makeStyles((theme) => ({
  planComparison: {
    marginTop: 16,

    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '0 24px',
      overflowX: 'auto',
    },
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    borderSpacing: 0,

    [theme.breakpoints.down('sm')]: {
      width: 600,
      margin: '0 auto',

      '& tbody, thead': {
        width: '100%',
      },
    },

    '& td, & th': {
      textAlign: 'left',
      verticalAlign: 'top',
      fontWeight: 'normal',
      padding: 0,
    },
  },

  th: {
    width: 196,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 40,
    },
  },

  td: {
    width: 128,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 24,
    },
  },

  enterprisePlanOnlyItem: {
    fontWeight: 450,
    fontSize: 13,
    height: 40,
    color: theme.palette.op.gray[4],
    boxShadow: `0 1.5px 0 0 ${alpha(theme.palette.op.gray[1], 0.06)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  headerDescription: {
    fontSize: '15px',
    fontWeight: 450,
    color: theme.palette.op.gray[2],
    lineHeight: '20px',
  },
  plansContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& > div + div': {
        marginTop: 24,
      },
    },
  },
  disclaimer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 450,
    fontSize: 13,
    height: 16,
    color: theme.palette.op.gray[3],

    '& a': {
      color: theme.palette.op.gray[3],
    },
  },
  body: {
    width: '864px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  comparePlansButtonContainer: {
    marginTop: 24,
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'center',
  },

  intervalSelector: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
}))
