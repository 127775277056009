// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { AppAvatar } from '@src/app/components'
import { AppAvatarGroup } from '@src/app/components'
import React from 'react'
import { useAppStore } from '@src/app/context'
import FormattedText from '../../../component/formatted-text'
import { SearchIcon } from '../../../component/icons/Tint/20/General'
import Tooltip from '../../../component/tooltip'
import Typography from '@ui/Typography'
import { friendlyDateTime, parseDate } from '../../../lib'
import { formatted } from '../../../lib/phone-number'
import { Identity, PhoneNumber } from '../../../service/model'
import { ActivitySearchResult } from '../../../types'
import Mention from '../../mention'
import { IconItem } from '../common-v2'
import { FromTag, InboxTag } from './tag'

interface RowProps {
  selected: boolean
  index: number
  getItemProps: (index: number) => any
}

export const InboxRow: React.FC<{ phoneNumber: PhoneNumber } & RowProps> = function ({
  index,
  selected,
  phoneNumber,
  getItemProps,
}) {
  const styles = useStyles({})
  return (
    <IconItem
      {...getItemProps(index)}
      selected={selected}
      icon={<SearchIcon />}
      className={styles.root}
    >
      <InboxTag phoneNumber={phoneNumber} />
      {selected && (
        <Typography nowrap variant="body" color="textTertiary" className={styles.tip}>
          Search in this inbox
        </Typography>
      )}
    </IconItem>
  )
}

export const FromRow: React.FC<{ identity: Identity } & RowProps> = function ({
  index,
  selected,
  identity,
  getItemProps,
}) {
  const styles = useStyles({})
  return (
    <IconItem
      {...getItemProps(index)}
      selected={selected}
      icon={<SearchIcon />}
      className={styles.root}
    >
      <FromTag identity={identity} />
      {selected && (
        <Typography nowrap variant="body" color="textTertiary" className={styles.tip}>
          Search messages from {identity.name}
        </Typography>
      )}
    </IconItem>
  )
}

export const SearchRow: React.FC<{ term: string } & RowProps> = function ({
  index,
  selected,
  term,
  getItemProps,
}) {
  const styles = useStyles({})
  return (
    <IconItem
      {...getItemProps(index)}
      selected={selected}
      icon={<SearchIcon />}
      className={styles.root}
    >
      <Typography nowrap variant="body" color="textPrimary">
        {term}
      </Typography>
      {selected && (
        <Typography nowrap variant="body" color="textTertiary" className={styles.tip}>
          Search this term
        </Typography>
      )}
    </IconItem>
  )
}

export const IdentityRow: React.FC<{ identity: Identity } & RowProps> = function ({
  index,
  selected,
  identity,
  getItemProps,
}) {
  const styles = useStyles({})
  return (
    <IconItem
      {...getItemProps(index)}
      selected={selected}
      icon={<SearchIcon />}
      className={styles.root}
    >
      <AppAvatar identity={identity} size={20} className={styles.avatar} />
      <Typography nowrap variant="body" color="textPrimary">
        {identity.name}
      </Typography>
      {selected && (
        <Typography nowrap variant="body" color="textTertiary" className={styles.tip}>
          Go to conversation
        </Typography>
      )}
    </IconItem>
  )
}

export const ResultRow: React.FC<{ result: ActivitySearchResult } & RowProps> =
  function ({ index, selected, result, getItemProps }) {
    const styles = useStyles({})
    const { service } = useAppStore()
    const hasMultipleNumbers = service.user.phoneNumbers.length > 1
    const phoneNumber = service.user.phoneNumbers.get(result._source.phoneNumberId)
    const fromParticipant = service.participant.getOrCreate(result._source.from)
    const participants = result._source.phoneNumber
      .split(',')
      .map((phoneNumber) => service.participant.getOrCreate(phoneNumber))

    const name = participants
      .map((participant) => {
        const name = participants.length === 1 ? participant.name : participant.shortName
        return name || formatted(participant.phoneNumber)
      })
      .join(', ')

    const from =
      result._source.direction === 'outgoing' ? 'You' : fromParticipant.shortName
    const body = `${from}: ${result.highlight.body[0]}`

    const renderMention = (mention: string, offset: number) => (
      <Mention key={offset} id={mention} />
    )

    return (
      <IconItem
        {...getItemProps(index)}
        selected={selected}
        icon={
          <AppAvatarGroup identities={participants} size={40} className={styles.avatar} />
        }
        rootClassName={styles.resultRow}
      >
        <div>
          <div className={styles.root}>
            <Typography nowrap variant="callout" fontWeight="medium" color="textPrimary">
              {name}
            </Typography>
            <Typography variant="caption2" color="textTertiary" className={styles.date}>
              {friendlyDateTime(parseDate(result._source.createdAt))}
            </Typography>
          </div>
          <div className={styles.body}>
            {hasMultipleNumbers && phoneNumber && (
              <Tooltip title={`${phoneNumber.symbol} ${phoneNumber.name}`}>
                <div className={styles.phoneSymbol}>{phoneNumber.symbol}</div>
              </Tooltip>
            )}
            <Typography
              nowrap
              variant="footnote"
              color="textSecondary"
              className={styles.messageBody}
            >
              <FormattedText
                inline
                value={body}
                dangerouslySetInnerHTML
                renderMention={renderMention}
              />
            </Typography>
          </div>
        </div>
      </IconItem>
    )
  }

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  resultRow: {
    height: 58,
    display: 'flex',
  },
  tip: {
    marginLeft: 16,
  },
  avatar: {
    marginRight: 10,
  },
  date: {
    marginLeft: 6,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
  },
  messageBody: {
    marginTop: 2,

    '& em': {
      fontStyle: 'normal',
      color: theme.palette.op.mention.theirName,
      padding: '0 1px',
      borderRadius: 2,
      background: theme.palette.op.mention.theirBg,
    },
  },
  phoneSymbol: {
    background: theme.palette.op.hover.primary,
    borderRadius: 4,
    height: 18,
    width: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    marginRight: 7,
  },
}))
