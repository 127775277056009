import Typography from '@ui/Typography'
import React from 'react'
import Switch from '@src/component/switch'
import { makeStyles } from '@material-ui/core'

function SettingRow({
  title,
  subtitle,
  checked,
  onChange,
}: {
  title: string
  subtitle: string
  checked: boolean
  onChange: () => void
}) {
  const styles = useStyles()
  return (
    <label className={styles.settingRow}>
      <div className={styles.settingRowDescription}>
        <Typography variant="callout" color="textPrimary">
          {title}
        </Typography>
        <Typography variant="footnote" color="textTertiary">
          {subtitle}
        </Typography>
      </div>
      <Switch checked={checked} onChange={onChange} />
    </label>
  )
}

export default SettingRow

const useStyles = makeStyles((theme) => ({
  settingRow: {
    display: 'flex',
    padding: '25px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  settingRowDescription: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 4,
  },
}))
