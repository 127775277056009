// @ts-strict-ignore
import Service from '..'
import { parseDate } from '../../lib'
import { Conversation } from '../conversation-store'
import { Collection, Model } from './base'

export class DirectNumber implements Model {
  createdAt: number
  id: string
  number: string
  orgId: string
  updatedAt: number
  userId: string

  conversations = new Collection<Conversation>()

  constructor(private service: Service) {}

  deserialize = (json) => {
    Object.assign(this, json)
    this.createdAt = parseDate(json.createdAt)
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      number: this.number,
      orgId: this.orgId,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}
