import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@ui/Typography'
interface EmptyListMessageProps {
  text: string
}

export const EmptyListMessage: React.FC<EmptyListMessageProps> = function ({ text }) {
  const styles = useStyles({})
  return (
    <Typography variant="footnote" color="textTertiary" className={styles.root}>
      {text}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem 24px',
  },
}))
