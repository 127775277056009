// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles'
import cx from 'classnames'
import React from 'react'
import { typography } from '../theme'
import { TypographyWeight, getWeight } from '@ui/Typography'

type ButtonVariant = 'contained' | 'outlined' | 'ghost' | 'text'
type ButtonColor =
  | 'primary'
  | 'danger'
  | 'secondaryGreen'
  | 'textPrimary'
  | 'textSecondary'
type ButtonHeight = 48 | 45 | 40 | 36 | 35 | 30

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean
  align?: 'start' | 'center' | 'end'
  variant?: ButtonVariant
  color?: ButtonColor
  height?: ButtonHeight
  fullWidth?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  fontWeight?: TypographyWeight
}

const Button: React.FC<ButtonProps> = React.forwardRef(function (
  {
    variant = 'contained',
    color = 'primary',
    align = 'center',
    loading = false,
    disabled,
    height = 40,
    fullWidth,
    children,
    startIcon,
    endIcon,
    className,
    fontWeight,
    ...props
  },
  ref,
) {
  const styles = useStyles({
    variant,
    color,
    loading,
    disabled,
    height,
    fullWidth,
    fontWeight,
    align,
  })
  return (
    <button
      ref={ref}
      disabled={disabled || loading}
      className={cx(styles.root, loading && styles.loading, className)}
      {...props}
    >
      <div className={styles.content}>
        {startIcon && <div className={styles.startIcon}>{startIcon}</div>} {children}
        {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
      </div>
      {loading && <Loading />}
    </button>
  )
})

const Loading: React.FC<React.HTMLProps<HTMLDivElement>> = React.forwardRef(function (
  { className, ...props },
  ref,
) {
  const styles = useStyles({})
  return (
    <div {...props} ref={ref} className={styles.loadingIndicator}>
      <span />
      <span />
      <span />
    </div>
  )
})

export default Button

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  root: (props) => ({
    ...typography.callout,
    fontWeight: getFontWeight(props),
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    borderRadius: 7,
    padding: '0 16px',
    position: 'relative',
    height: props.height,
    width: props.fullWidth ? '100%' : 'auto',
    transition: theme.transitions.create(['background'], { duration: 210 }),
    ...getColors(theme, props),

    '&:before': {
      content: '""',
      position: 'absolute',
      borderRadius: 'inherit',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transition: theme.transitions.create(['background'], { duration: 200 }),
    },

    '&:hover:not(:disabled):before': {
      background: getHoverColor(theme, props),
    },

    '&:focus-visible': getFocusStyles(theme, props),

    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none',
      ...getDisabledColors(theme, props),
    },
  }),
  loading: {
    '& $content': {
      visibility: 'hidden',
    },
  },
  content: (props) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    ...getAlign(props),
  }),
  startIcon: {
    lineHeight: 0,
    marginRight: 12,
  },
  endIcon: {
    lineHeight: 0,
    marginLeft: 12,
  },
  loadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    '& span': {
      height: 8,
      width: 8,
      float: 'left',
      margin: '0 2px',
      background: 'currentColor',
      display: 'block',
      borderRadius: '50%',
      opacity: 0.4,

      '&:nth-of-type(1)': {
        animation: '1s $blink infinite 0.3333s',
      },
      '&:nth-of-type(2)': {
        animation: '1s $blink infinite 0.6666s',
      },
      '&:nth-of-type(3)': {
        animation: '1s $blink infinite 1s',
      },
    },
  },
  '@keyframes blink': {
    '50%': { opacity: 1 },
  },
}))

function getAlign({ align }: ButtonProps): CSSProperties {
  switch (align) {
    case 'center':
      return { justifyContent: 'center', textAlign: align }
    case 'start':
      return { justifyContent: 'flex-start', textAlign: 'left' }
    case 'end':
      return { justifyContent: 'flex-end', textAlign: 'right' }
  }
}

function getColors(theme: Theme, { variant, color: clr }: ButtonProps): CSSProperties {
  if (variant === 'contained') {
    if (clr === 'primary') {
      return {
        background: theme.palette.op.primary[1],
        color: theme.palette.op.white,
      }
    } else if (clr === 'danger') {
      return {
        background: theme.palette.op.secondary.red1,
        color: theme.palette.op.white,
      }
    } else if (clr === 'secondaryGreen') {
      return {
        background: theme.palette.op.secondary.green1,
        color: theme.palette.op.white,
      }
    } else if (clr === 'textPrimary') {
      return {
        background: theme.palette.op.text.primary,
        color: theme.palette.op.gray[6],
      }
    } else if (clr === 'textSecondary') {
      return {
        background: theme.palette.op.text.secondary,
        color: theme.palette.op.gray[6],
      }
    }
  }

  if (variant === 'outlined') {
    if (clr === 'primary') {
      return {
        color: theme.palette.op.primary[2],
        border: `1.5px solid ${theme.palette.op.primary[1]}`,
      }
    } else if (clr === 'danger') {
      return {
        color: theme.palette.op.secondary.red2,
        border: `1.5px solid ${theme.palette.op.secondary.red1}`,
      }
    } else if (clr === 'secondaryGreen') {
      return {
        color: theme.palette.op.secondary.green2,
        border: `1.5px solid ${theme.palette.op.secondary.green1}`,
      }
    } else if (clr === 'textPrimary') {
      return {
        color: theme.palette.op.text.primary,
        border: `1.5px solid ${alpha(theme.palette.op.text.primary, 0.18)}`,
        boxShadow: theme.palette.op.match({
          light: '0 1.5px 4px 0 rgba(0, 0, 0, 0.06)',
          dark: 'unset',
        }),
      }
    } else if (clr === 'textSecondary') {
      return {
        color: theme.palette.op.text.secondary,
        border: `1.5px solid ${alpha(theme.palette.op.text.primary, 0.18)}`,
      }
    }
  }

  if (variant === 'ghost') {
    if (clr === 'primary') {
      return {
        background: theme.palette.op.tag.purple.bg,
        color: theme.palette.op.tag.purple.text,
      }
    } else if (clr === 'danger') {
      return {
        background: theme.palette.op.tag.red.bg,
        color: theme.palette.op.tag.red.text,
      }
    } else if (clr === 'secondaryGreen') {
      return {
        background: theme.palette.op.tag.green.bg,
        color: theme.palette.op.tag.green.text,
      }
    } else if (clr === 'textPrimary') {
      return {
        background: theme.palette.op.hover.primary,
        color: theme.palette.op.text.primary,
      }
    } else if (clr === 'textSecondary') {
      return {
        background: theme.palette.op.hover.primary,
        color: theme.palette.op.text.secondary,
      }
    }
  }

  if (variant === 'text') {
    if (clr === 'primary') {
      return {
        color: theme.palette.op.primary[2],
      }
    } else if (clr === 'danger') {
      return {
        color: theme.palette.op.secondary.red2,
      }
    } else if (clr === 'secondaryGreen') {
      return {
        color: theme.palette.op.secondary.green2,
      }
    } else if (clr === 'textPrimary') {
      return {
        color: theme.palette.op.text.primary,
      }
    } else if (clr === 'textSecondary') {
      return {
        color: theme.palette.op.text.secondary,
      }
    }
  }
}

function getHoverColor(theme: Theme, { variant, color: clr }: ButtonProps): string {
  if (variant === 'contained') {
    return alpha(theme.palette.op.black, 0.15)
  }
  if (variant === 'ghost') {
    return alpha(theme.palette.op.black, 0.15)
  }
  if (variant === 'outlined' || variant === 'text') {
    if (clr === 'primary') {
      return alpha(theme.palette.op.primary[1], 0.08)
    } else if (clr === 'danger') {
      return alpha(theme.palette.op.secondary.red1, 0.08)
    } else if (clr === 'textPrimary') {
      return theme.palette.op.hover.primary
    } else if (clr === 'secondaryGreen') {
      return alpha(theme.palette.op.secondary.green1, 0.08)
    } else if (clr === 'textSecondary') {
      return theme.palette.op.hover.primary
    }
  }
}

function getFocusStyles(
  theme: Theme,
  { variant, color: clr }: ButtonProps,
): CSSProperties {
  if (variant === 'outlined') {
    if (clr === 'textSecondary') {
      return {
        transition: `box-shadow 200ms ease`,
        boxShadow: theme.palette.op.shadow.textField.hoverPurple,
      }
    }
  }

  if (variant === 'contained') {
    return {
      '&:not(:disabled):before': {
        background: getHoverColor(theme, { variant, color: clr }),
      },
    }
  }

  return {}
}

function getDisabledColors(theme: Theme, { variant }: ButtonProps): CSSProperties {
  switch (variant) {
    case 'contained':
      return {
        background: theme.palette.op.gray[5],
        color: theme.palette.op.gray[4],
      }
    case 'outlined':
      return {
        color: theme.palette.op.gray[4],
        border: `1.5px solid ${theme.palette.op.gray[4]}`,
      }
    case 'text':
      return {
        color: theme.palette.op.gray[4],
      }
  }
}

function getFontWeight({ variant, fontWeight }: ButtonProps): number {
  if (fontWeight) {
    return getWeight(fontWeight)
  }
  switch (variant) {
    case 'contained':
      return 650
    case 'outlined':
    case 'text':
      return 550
  }
}
