import { CircularProgress } from '@src/component/progress'
import { Typography } from '@ui/Typography'
import React from 'react'

interface LoadingProps {
  title: string
}

const Loading: React.FC<LoadingProps> = function ({ title }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress style={{ marginRight: 16 }} size={20} />
      <Typography variant="caption1" color="textSecondary" fontWeight="regular">
        {title}
      </Typography>
    </div>
  )
}

export default Loading
