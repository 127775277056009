import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { useAppStore } from '@src/app/context'
import keyboard from '../../../lib/keyboard'
import useInputState from '../../../lib/use-input-state'
import { Command } from '../common-v2'
import Footer from '../common/footer'
import FooterButton from '../common/footer-button'
import Header from '../common/header'
import Textfield from '../common/textfield'

export interface EmailCodeCommandProps {
  email: string
}

const EmailCodeCommand: React.FC<EmailCodeCommandProps> = function ({ email }) {
  const { command, service, toast } = useAppStore()
  const [code, setCode] = useInputState('')

  const handleUpdate = () => {
    if (!code || !email) return

    service.auth
      .verifyChangeEmail(email, code)
      .then(() => service.user.fetch())
      .then(() => {
        command.hide()
        toast.show({ message: 'Email updated!' })
      })
      .catch(toast.showError)
  }

  return (
    <Command>
      <Header title="Enter the code" subtitle={`Check your inbox on ${email}`} />
      <Textfield
        autoComplete="off"
        autoFocus
        placeholder="6-digit code"
        value={code}
        onChange={setCode}
        maxLength={6}
        onKeyDown={keyboard.onEnter(handleUpdate)}
      />
      <Footer
        actions={
          <FooterButton
            variant="contained"
            disabled={!Boolean(code)}
            onClick={handleUpdate}
          >
            Update Email
          </FooterButton>
        }
      />
    </Command>
  )
}

export default EmailCodeCommand
