import { alpha, makeStyles } from '@material-ui/core'
import Typography from '@ui/Typography'
import React, { ReactNode } from 'react'
import { Card } from '@src/app/settings/settings-card'
import { FeatureRow } from '../feature-row'
import IconButton from '@src/component/icon-button'
import { XIcon } from '@src/component/icons/Tint/16/General'

interface Feature {
  title: string
  description: string
  icon: ReactNode
}

export interface HowItWorksCardProps {
  description: string | ReactNode
  features?: Feature[]
  action?: ReactNode
  onClose?: () => void
}

function HowItWorksCard({ description, features, action, onClose }: HowItWorksCardProps) {
  const styles = useStyles()
  return (
    <Card elevation={3} className={styles.card}>
      {onClose ? (
        <IconButton
          size="small"
          onClick={onClose}
          icon={<XIcon />}
          className={styles.closeButton}
        />
      ) : null}
      <div className={styles.header}>
        <Typography variant="title3">How it works</Typography>
        <Typography variant="body" color="textSecondary">
          {description}
        </Typography>
      </div>
      {features && (
        <div className={styles.features}>
          {features.map((feature) => (
            <FeatureRow
              key={feature.title}
              title={feature.title}
              desc={feature.description}
              icon={feature.icon}
            />
          ))}
        </div>
      )}
      {action}
    </Card>
  )
}

export default HowItWorksCard

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '20px 24px 24px',
    rowGap: 24,
    boxShadow: theme.palette.op.match({
      light: `inset 0 0 0 1.5px ${alpha(
        theme.palette.op.gray[1],
        0.09,
      )}, 0 2px 6px ${alpha(theme.palette.op.black, 0.04)}`,
      dark: `inset 0 0 0 1px ${alpha(theme.palette.op.hover.primary, 0.11)}`,
    }),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 6,
  },
  features: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
}))
