import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { forwardRef } from 'react'
import type { AvatarSize } from '@ui/Avatar'

const mask = require('./mask.svg').default

export interface AvatarRowProps {
  size?: AvatarSize
  maxAvatars?: number
  className?: string
  children: React.ReactNode
}

export const AvatarRow = forwardRef<HTMLDivElement, AvatarRowProps>(function (
  { children, className, size = 26, maxAvatars = 4 },
  ref,
) {
  const styles = useStyles({ size })

  const childrenLength = React.Children.count(children)
  const childrenArray = React.Children.toArray(children)

  const avatarsCount = childrenLength > maxAvatars ? maxAvatars - 1 : childrenLength
  const extra = childrenLength - avatarsCount
  const avatars = childrenArray.slice(0, avatarsCount)

  return (
    <div ref={ref} className={cx(styles.root, className)}>
      {avatars.map((element) => {
        if (!React.isValidElement(element)) {
          throw new Error('AvatarRow children must be valid Avatar elements')
        }

        return React.cloneElement(element, {
          className: cx(styles.avatar, element.props.className),
          size,
        })
      })}
      {extra > 0 && <div className={cx(styles.avatar, styles.more)}>{extra}</div>}
    </div>
  )
})

export default AvatarRow

const masks = {
  default: `url('${mask}')`,
}

const useStyles = makeStyles<Theme, Required<Pick<AvatarRowProps, 'size'>>>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
  },
  avatar: ({ size }) => ({
    marginLeft: getMargin(size),
    maskImage: masks.default,
    maskSize: 'contain',

    '&:first-child': {
      marginLeft: 0,
    },

    '&:last-child': {
      maskImage: 'none',
    },
  }),
  more: ({ size }) => {
    return {
      width: size,
      height: size,
      bottom: 0,
      right: 0,
      borderRadius: '50%',
      background: theme.palette.op.primary[2],
      fontSize: 11,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.op.white,
    }
  },
}))

const getMargin = (size: AvatarSize): number => {
  switch (size) {
    case 80:
      return -24
    case 40:
    case 36:
    case 34:
    case 30:
      return -13
    case 26:
    case 24:
      return -9
    case 22:
    case 20:
      return -7
    case 16:
      return -5
  }
}
