// @ts-strict-ignore
import Debug from 'debug'
import { action, makeAutoObservable, reaction, when } from 'mobx'
import { matchPath } from 'react-router'
import { Subscription } from 'rxjs'
import log, { logError } from '../../../lib/log'
import { toQueryString } from '../../../lib/query-string'
import {
  Activity,
  Collection,
  Conversation,
  MessageMedia,
  Participant,
} from '../../../service/model'
import { ConversationParticipantStatus } from '../../../types'
import { canHandleMedia } from '../../media-viewer'
import AppStore from '../../store'
import { CommentsRegistry } from './activity/comments/controller'
import { FeedItem } from './feed'
import {
  CommentsInputBarRegistry,
  ConversationInputBarController,
  ConversationInputBarRegistry,
} from './input-bar'

export default class ConversationUiStore {
  readonly inputBarRegistry = new ConversationInputBarRegistry(this.app)
  readonly threadRegistry = new CommentsRegistry(this.app)
  readonly threadInputBarRegistry = new CommentsInputBarRegistry(this.app)

  /**
   * The shadow conversation is what drives the activity feed. In most cases, shadow and current
   * conversations are the same. The only case where they are different is when you are creating a new
   * conversation. In that case, `current` is a draft conversation and as participants are added to it,
   * the shadow conversation updates to reflect an existing conversation between the selected participants.
   * The moment a message is sent to a new conversation, shadow and current merge into one.
   */
  private shadow: Conversation = null

  /**
   * Whether the input bar is present so we can start to interact with it.
   */
  ready = false

  inputBarController: ConversationInputBarController = null

  /**
   * The object containing the activity feed.
   */
  feed: Collection<FeedItem> = null

  /**
   * Used for linking `call` to `voicemail` activities
   *
   * We attach the voicemail recordings to the `call` activities, so when we iterate
   * through the activities collection, we filter out `voicemail` activities and add
   * them to this map.
   */
  voicemailActivities: { [key: string]: Activity | undefined } = {}
  private feedSubscription: Subscription

  /**
   * These properties are responsible for broadcasting or rebroadcasting typing/enter/exit
   * statuses to other members of the org who have access to this conversation.
   */
  private typing: boolean = false
  private typingTimeout: any
  private resendInterval: any

  private debug = Debug('op:app:conversation')

  constructor(private app: AppStore) {
    /**
     * These listeners are for broadcasting user presence.
     */
    document.addEventListener('visibilitychange', this.visibilityChanged)
    window.onbeforeunload = () => {
      if (this.shadow?.openPhoneNumber?.isShared) {
        this.sendStatus(this.shadow.id, 'exited')
      }
    }

    makeAutoObservable<this, 'changeListener' | 'anchorLoading'>(
      this,
      {
        changeListener: false,
        anchorLoading: false,
        inputBarRegistry: false,
      },
      { autoBind: true },
    )

    /**
     * As a user goes between conversations, broadcast their enter/exit status to other members
     * in their org.
     */
    reaction(
      () => this.shadow,
      (conversation, oldConversaton) => {
        if (oldConversaton) {
          this.exited(oldConversaton)
        }
        if (conversation) {
          this.entered(conversation)
        }
      },
    )

    // Create a new controller for the InputBar associated to the new
    // conversation, saving any drafts as necessary.
    reaction(
      () => this.shadow,
      (current) => {
        this.inputBarController?.saveDraft()
        // TODO: resetting the selection appears to fix an error thrown by
        // Slate (see https://linear.app/openphone/issue/ENG-2468)
        this.inputBarController?.editor.resetSelection()
        this.inputBarController = this.inputBarRegistry.get(current)
      },
      { name: 'SetInputBarController' },
    )

    when(
      () => this.inputBarController?.editor.rendered,
      () => {
        this.ready = true
      },
    )

    /**
     * When the current conversation changes or participants change, find an existing conversation
     * between the same participants. If the current conversation is not a new converstion, the shadow and
     * current are the same. So essentially, the shadow conversation is only different when you are composing
     * a new conversation and adding participants. The activities that are displayed, are pulled from the
     * shadow conversation. This means when you are creating a new conversation and adding participants,
     * in the list view you can see if you have an existing conversation.
     */
    reaction(
      () => [this.current?.id, this.current?.phoneNumber],
      () => {
        const conv = this.current
        if (!conv?.isNew || !conv.phoneNumber) {
          this.shadow = this.current
        } else if (conv.phoneNumber) {
          app.service.conversation
            .findOne({
              phoneNumber: conv.phoneNumber,
              phoneNumberId: conv.phoneNumberId,
              directNumberId: conv.directNumberId,
            })
            .then(
              action((conv) => {
                this.shadow = conv ?? this.current
              }),
            )
            .catch((error) => {
              log.error(error)
              this.shadow = this.current
            })
        }

        // Change the URL to reflect the new conversation and anchored activity
        if (conv) {
          const inboxId = this.current.phoneNumberId || this.current.directNumberId
          this.updateUrl(inboxId, this.current.id)
        }
      },
      { fireImmediately: true },
    )

    /**
     * When shadow conversation is set, go and fetch it's activities
     */
    reaction(
      () => this.shadow,
      (conv) => conv && this.loadFeed(),
      { fireImmediately: true },
    )

    /**
     * Fetch changes when the internet comes back on
     */
    this.app.onDataNeedsRefresh.subscribe(() => {
      this.fetchRecent()
    })

    /**
     * When activities are loaded, check to see if any of them are the activity we should highlight and
     * scroll to.
     */
    reaction(
      () => this.shadow?.activities.length,
      () => this.fetchContextualActivities(),
      { fireImmediately: true },
    )
  }

  get id() {
    return this.shadow?.id ?? this.current?.id
  }

  get current(): Conversation | null {
    return this.app.inboxes.selected?.selectedConversation ?? null
  }

  set current(conversation: Conversation | null) {
    if (this.current === conversation) {
      this.fetchContextualActivities()
    }

    this.app.inboxes.selected.selectedConversation = conversation
  }

  get anchorId(): string | null {
    return this.app.history.query.at ?? null
  }

  set anchorId(at: string) {
    if (!this.current) return
    const inboxId = this.current.phoneNumberId || this.current.directNumberId
    this.updateUrl(inboxId, this.current.id, at)
  }

  get anchorIndex(): number {
    return this.feed.indexOf(this.anchorId)
  }

  setSelected = (conversation: Conversation, anchorActivityId?: string) => {
    this.current = conversation
    if (anchorActivityId) {
      this.anchorId = anchorActivityId
    }
    this.fetchContextualActivities()
    this.debug('selected conversation (current: %O)', this.current)
  }

  // FIXME: could be a computed property
  protected parseUrl() {
    return matchPath<{ inboxId: string; conversationId: string; at: string }>(
      this.app.history.location.pathname,
      { path: '/inbox/:inboxId/c/:conversationId' },
    )
  }

  protected updateUrl(inboxId: string, conversationId: string, anchorId?: string) {
    const match = this.parseUrl()
    if (match?.params.conversationId === conversationId && !anchorId) return
    const prefix = `/inbox/${inboxId}/c/`
    const qs = toQueryString({ at: anchorId })
    const path = prefix + conversationId + qs
    this.debug('push history (path: %O)', path)
    this.app.history.push(path, false)
  }

  /**
   * Sends a message with the given body and media objects
   * @param body
   * @param media
   */
  send = (body: string, media: MessageMedia[]) => {
    if (this.shadow.isUnread) {
      this.shadow.markAsRead()
    }

    if (this.shadow.id !== this.current.id) {
      this.shadow.snoozedUntil = null
      this.app.service.conversation.collection.delete(this.current)
      this.app.service.conversation.collection.put(this.shadow)
      this.current = this.shadow
    }
    return this.shadow.send(body, media).then(
      action((activity) => {
        if (this.current?.isNew && activity.conversationId !== this.current?.id) {
          this.app.service.conversation.collection.delete(this.current)
          this.current = activity.conversation
        }
      }),
    )
  }

  /**
   * Open the media viewer with the passed in media focused. The user can use arrow keys
   * to open next/prev items.
   * @param media
   */
  openMedia = (media: MessageMedia) => {
    const allMedia = this.shadow.activities.list
      .flatMap((item) => item.media || [])
      .filter((m) => canHandleMedia(m.type, m.name))
    const index = allMedia.findIndex((m) => m.id === media.id)
    this.app.openMediaViewer(allMedia, index)
  }

  private fetchContextualActivities() {
    if (!this.anchorId) return
    if (this.feed && !this.feed.has(this.anchorId)) {
      this.debug('fetching contextual activities (anchorId: %O)', this.anchorId)
      this.app.service.activity.fetchAround(this.shadow.id, this.anchorId).catch(logError)
    }
  }

  /**
   * Tells other people with access to this conversation that the user is viewing it
   * @param conversation
   */
  entered(conversation: Conversation) {
    if (conversation.presenceEnabled) {
      this.sendStatus(conversation.id, 'active')
    }
  }

  /**
   * Tells other people with access to this conversation that the user is typing or
   * stopped typing
   * @param typing
   */
  setTyping(typing: boolean) {
    clearTimeout(this.typingTimeout)
    if (this.shadow?.presenceEnabled) {
      if (this.typing !== typing) {
        this.typing = typing
        this.sendStatus(
          this.shadow.id,
          typing ? 'typing' : document.hidden ? 'away' : 'active',
        )
      }
      if (typing) {
        this.typingTimeout = setTimeout(() => {
          this.setTyping(false)
        }, 10000)
      }
    }
  }

  /**
   * Tells other people with access to this conversation that the user has stopped
   * viewing it
   * @param conversation
   */
  exited(conversation: Conversation) {
    clearTimeout(this.typingTimeout)
    clearInterval(this.resendInterval)
    if (conversation.presenceEnabled) {
      this.sendStatus(conversation.id, 'exited')
    }
  }

  /**
   * Loads more activities for the given conversation
   */
  loadMore = () => {
    this.app.service.activity.load(this.shadow, this.feed.list[0]?.id)
  }

  /**
   * Adds a participant to a conversation. If you pass a phone number for the second
   * argument, the new participant will replace the other one.
   * @param phoneNumber
   * @param replaceWithPhoneNumber
   */
  addParticipant = (phoneNumber: string, replaceWithPhoneNumber?: string) => {
    const conversation = this.current
    const exists = conversation.participants.find((p) => p.phoneNumber === phoneNumber)
    if (replaceWithPhoneNumber) {
      if (exists) {
        conversation.removeParticipant(replaceWithPhoneNumber)
      } else {
        conversation.replaceParticipant(phoneNumber, replaceWithPhoneNumber)
      }
    } else if (!exists) {
      conversation.addParticipant(phoneNumber)
    }

    if (this.current.isNew) {
      this.app.inboxes.selected.setDetails(
        this.current.participants.length === 1 ? this.current.participants[0] : null,
      )
    }
  }

  /**
   * Deletes the last particiapnt from the conversation
   */
  deleteLastParticipant = () => {
    const conversation = this.current
    const last = conversation.participants[conversation.participants.length - 1]
    conversation.removeParticipant(last.phoneNumber)
  }

  /**
   * Deletes a particiapnt
   */
  deleteParticipant = (participant: Participant) => {
    const conversation = this.current
    conversation.removeParticipant(participant.phoneNumber)
  }

  markRead = () => {
    if (this.shadow.isUnread) {
      this.shadow.markAsRead().catch(logError)
    }
  }

  type(message: string) {
    this.inputBarController.editor.insertText(message)
  }

  /**
   * Called when the browser has been sent to the background or the tab is not longer
   * focused
   */
  private visibilityChanged = () => {
    if (this.shadow?.presenceEnabled) {
      document.hidden
        ? this.sendStatus(this.shadow.id, 'away')
        : this.sendStatus(this.shadow.id, 'active')
    }
  }

  /**
   * Broadcasts the given status to all the people who have access to the conversation.
   * It keeps rebroadcasting every 60 seconds.
   * @param conversationId
   * @param status
   */
  private sendStatus(conversationId: string, status: ConversationParticipantStatus) {
    clearInterval(this.resendInterval)
    this.app.service.conversation
      .participantStatus(conversationId, status)
      .catch(logError)
    this.resendInterval = setInterval(() => {
      this.sendStatus(conversationId, status)
    }, 60000)
  }

  /**
   * Loads the activity feed and listens to changes, not much else to say here.
   */
  private loadFeed = () => {
    this.feedSubscription?.unsubscribe()
    this.app.service.activity.load(this.shadow, null, this.anchorId)
    this.fetchRecent()
    this.feed = new Collection<FeedItem>({
      bindElements: true,
      compare: (a, b) => a.sortWeight - b.sortWeight,
    })
    this.voicemailActivities = {}

    const feedItems = this.shadow.activities.list.reduce((acc, activity) => {
      if (activity.type === 'voicemail') {
        this.voicemailActivities[activity.sid] = activity
      } else {
        acc.push(new FeedItem(this.app, activity))
      }
      return acc
    }, [] as FeedItem[])
    this.feed.putBulk(feedItems)

    this.feedSubscription = this.shadow.activities.observe(
      action((change) => {
        if (change.type === 'put') {
          const changeObj = change.objects.map((activity) => {
            if (activity.type === 'voicemail') {
              this.voicemailActivities[activity.sid] = activity
            } else {
              return this.feed.get(activity.id) || new FeedItem(this.app, activity)
            }
          })
          this.feed.putBulk(changeObj)
        } else {
          this.feed.deleteBulk(
            change.objects.map((activity) => {
              this.voicemailActivities[activity.sid] = undefined
              return activity.id
            }),
          )
        }
      }),
    )
  }

  private fetchRecent = () => {
    this.app.service.activity.fetchRecent(this.shadow).catch(logError)
  }
}
