// @ts-strict-ignore
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../../../component/button-v2'
import Typography from '@ui/Typography'
import useKeyboardShortcuts from '../../../lib/use-keyboard-shortcuts'
import CallHeader from './header'
import { Call } from '../store'
import { AppAvatar } from '@src/app/components'

interface IncomingCallProps {
  call: Call
}

const IncomingCall: React.FC<IncomingCallProps> = function ({ call }) {
  const styles = useStyles({})
  const app = useAppStore()
  const { notification, command, service } = app
  const callFromId = call.connection.customParameters.get('callFromId')
  const contact =
    callFromId && service.member.collection.list.some((m) => m.id === callFromId)
      ? service.member.collection.list.filter((m) => m.id === callFromId)[0]
      : service.participant.getOrCreate(call.participants[0])

  useEffect(() => {
    const close = notification.show({
      title: contact.name,
      id: call.id,
      body: `📞 Incoming call to ${call.phoneNumber?.formattedName}...`,
      onClick: () => app.focus(),
      onAction: (index: number) => {
        if (index === 0) {
          handleAccept()
        } else if (index === 1) {
          handleIgnore()
        }
      },
    })
    return close
  }, [open, contact])

  useKeyboardShortcuts({
    name: 'dialer/incoming-call',
    node: document,
    handler: (shortcut, event) => {
      if (!open) return
      if (shortcut === 'Escape') {
        event.preventDefault()
        handleIgnore()
      }
    },
    dep: [open, call],
  })

  const handleAccept = () => {
    call.accept()
  }

  const handleIgnore = () => {
    call.reject()
  }

  const handleRejectWithMessage = () => {
    call.reject()

    command.present({
      name: 'reject call with message',
      onSelect: (body) => {
        command.hide()
        service.activity.sendRaw({
          phoneNumberId: call.phoneNumberId,
          to: call.participants[0],
          body,
        })
      },
    })
  }

  return (
    <div>
      <CallHeader phoneNumber={call.phoneNumber} />
      <div className={styles.user}>
        <AppAvatar identity={contact} size={40} />
        <div style={{ flex: 1, marginLeft: 12 }}>
          <Typography variant="callout">
            {contact.name} {call.isVerified && <span style={{ marginLeft: 5 }}>✅</span>}
          </Typography>
          <Typography
            variant="caption1"
            color="textSecondary"
            fontWeight="regular"
            className={styles.title}
          >
            Incoming call...
          </Typography>
        </div>
      </div>
      <div className={styles.actions}>
        <Button color="secondaryGreen" onClick={handleAccept}>
          Accept
        </Button>
        <Button color="danger" onClick={handleIgnore}>
          Reject
        </Button>
        <Button onClick={handleRejectWithMessage} className={styles.rejectWithMessage}>
          Reject with message
        </Button>
      </div>
    </div>
  )
}

export default observer(IncomingCall)

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: '0.85rem',
    padding: '10px 7px 10px 20px',
    background: theme.palette.op.background.highlight(0.03),
    cursor: 'grab',
    display: 'flex',
    justifyContent: 'space-between',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    marginTop: 3,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px',

    '& > *:not(:last-child)': {
      marginBottom: 12,
    },
  },
  rejectWithMessage: {
    color: theme.palette.op.secondary.red2,
    background: alpha(theme.palette.op.secondary.red1, 0.1),
    marginBottom: 0,

    '&:hover': {
      background: alpha(theme.palette.op.secondary.red1, 0.2),
    },
  },
}))
