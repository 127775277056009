import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Tooltip from '../../component/tooltip'
import { useStore } from '../../lib/hooks'
import AppStore from '../store'
import CloseIcon from './close.svg'
import { WindowsTitleBarController } from './controller'
import MaximizeIcon from './maximize.svg'
import MinimizeIcon from './minimize.svg'
import UnmaximizeIcon from './unmaximize.svg'

interface WindowsTitleBarProps extends React.HTMLProps<HTMLDivElement> {
  app: AppStore
}

const WindowsTitleBar: React.FC<WindowsTitleBarProps> = function ({
  app,
  className,
  ...props
}) {
  const styles = useStyles({})
  const controller = useStore(() => new WindowsTitleBarController(app), [])
  const maximized = controller.maximized

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.logo}></div>
      <div className={styles.buttons}>
        <Tooltip title="Minimize">
          <div className={styles.button} onClick={controller.minimize}>
            <MinimizeIcon />
          </div>
        </Tooltip>
        {maximized ? (
          <Tooltip title="Unmaximize">
            <div className={styles.button} onClick={controller.unmaximize}>
              <UnmaximizeIcon />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Maximize">
            <div className={styles.button} onClick={controller.maximize}>
              <MaximizeIcon />
            </div>
          </Tooltip>
        )}
        <Tooltip title="Close">
          <div className={cx(styles.button, styles.close)} onClick={controller.close}>
            <CloseIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default observer(WindowsTitleBar)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '0 0 37px',
    display: 'flex',
    justifyContent: 'space-between',
    '-webkit-app-region': 'drag',
    background: theme.palette.op.titleBar.background,
    borderBottom: `1.5px solid ${theme.palette.op.border.common}`,
  },
  logo: {
    marginLeft: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: 50,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '-webkit-app-region': 'no-drag',

    '&:hover': {
      background: theme.palette.op.hover.primary,
    },
  },
  close: {
    '&:hover': {
      background: theme.palette.op.titleBar.closeHover,
    },
  },
}))
