import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useLoginStore } from '.'
import { useAppStore } from '@src/app/context'
import Button from '../../component/button-v2'
import TextField from '../../component/textfield-v2'
import Typography from '@ui/Typography'
import useInputState from '../../lib/use-input-state'
import Header from '../onboarding/header'

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = function ({}) {
  const styles = useStyles({})
  const { history } = useAppStore()
  const loginStore = useLoginStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')
  const disabled = !email

  const handleResetPassword = () => {
    loginStore.resetPassword(email)
  }

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.card}>
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Reset your password
        </Typography>
        <Typography variant="body" color="textSecondary">
          We will send you an email with instructions on how to reset your password.
        </Typography>

        <div style={{ marginTop: 48, textAlign: 'left' }}>
          <TextField
            autoFocus={!email}
            type="email"
            fullWidth
            label="Email"
            size={45}
            value={email}
            onChange={setEmail}
          />
          <Button
            fullWidth
            height={40}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleResetPassword}
            disabled={disabled}
            loading={loginStore.loading}
          >
            Send Reset Email
          </Button>
          <Button
            fullWidth
            height={36}
            variant="text"
            color="textSecondary"
            fontWeight="regular"
            style={{ marginTop: 8 }}
            onClick={history.goBack}
          >
            Go back
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(ForgotPassword)

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 320,
    textAlign: 'center',
    margin: '128px auto 50px',
  },
}))
