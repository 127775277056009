import React from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'

export interface VerticalSortableProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The array of items to be rendered, every item should have an `id` property.
   *
   * @required
   */
  items: { id: string }[]

  /**
   * Reorder callback, called when an item is moved. You can use the `arrayMove` function to
   * update the original array.
   *
   * @param oldIndex The index of the item before the move.
   * @param newIndex The index of the item after the move.
   * @required
   */
  onReorder: (oldIndex: number, newIndex: number) => void

  /**
   * You should pass in children that are `VerticalSortableItem` components, or components
   * that use the primitive `useSortable` hook.
   */
  children: React.ReactNode
}

export const VerticalSortable = ({
  items,
  children,
  onReorder,
  ...props
}: VerticalSortableProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (over?.id && active.id !== over.id) {
      const oldIndex = items.findIndex((inbox) => inbox.id === active.id)
      const newIndex = items.findIndex((inbox) => inbox.id === over.id)

      onReorder(oldIndex, newIndex)
    }
  }

  return (
    <div {...props}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </DndContext>
    </div>
  )
}

export default VerticalSortable
