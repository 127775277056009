// @ts-strict-ignore
import { makeStyles, Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAppStore } from '@src/app/context'
import Button from '../component/button-v2'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../component/dialog'
import Typography from '@ui/Typography'
import { AlertAction, AlertState } from './store'

export interface AlertProps {
  alert: AlertState
  onClose: () => void
}

const AlertDialog: React.FC<{}> = function ({}) {
  const styles = useStyles({})
  const { alert, hideAlert } = useAppStore()

  const handleAlertAction = (action: AlertAction) => {
    action.onClick && action.onClick()
    hideAlert()
  }

  return (
    <Dialog open={alert.open} onClose={hideAlert}>
      <DialogTitle>
        <Typography fontWeight="medium" variant="headline" color="textPrimary">
          {alert?.title}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.dialogBody}>
        <Typography variant="callout" color="textSecondary">
          {alert?.body}
        </Typography>
      </DialogContent>
      <DialogActions>
        {alert?.actions?.length > 0 ? (
          alert?.actions
            .slice()
            .reverse()
            .map((action) =>
              action.type === 'destructive' ? (
                <Button
                  autoFocus
                  key={action.title}
                  height={36}
                  color="danger"
                  variant="contained"
                  onClick={() => handleAlertAction(action)}
                  style={{ marginLeft: 10 }}
                >
                  {action.title}
                </Button>
              ) : (
                <Button
                  key={action.title}
                  height={36}
                  autoFocus={action.type === 'primary'}
                  variant={action.type === 'primary' ? 'contained' : 'text'}
                  color={action.type === 'primary' ? 'primary' : 'textSecondary'}
                  onClick={() => handleAlertAction(action)}
                  style={{ marginLeft: 10 }}
                >
                  {action.title}
                </Button>
              ),
            )
        ) : (
          <Button variant="contained" height={36} color="primary" onClick={hideAlert}>
            Okay
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default observer(AlertDialog)

const useStyles = makeStyles((theme: Theme) => ({
  dialogBody: {
    minWidth: 300,
  },
}))
